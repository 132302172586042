import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useParams, useNavigate } from '@reach/router';

import { routes } from '@config/routes';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { PageStyled } from '@modules/layout/styled';
import { useCompanies, useCompany } from '@modules/company/hooks';
import { UpdateUserRequest } from '@modules/users/requests';
import { useUserRoles } from '@modules/users/hooks';
import { UserForm } from '@modules/users/organisms';
import { UpdateUserMutation } from '@modules/users/graphql';

import type { UpdateUserFormValues } from '@modules/users/requests';
import type { Page } from '@modules/types/page';
import type {
    UpdateOrgProfileMutationType,
    UpdateOrgProfileMutationVariables,
    UpdateUserMutationType,
    UpdateUserMutationVariables,
} from '@modules/types/graphql';
import { useUserDetail } from '@modules/users/hooks/use-user-detail';
import { Loader } from '@modules/layout/moleculas';
import { useCurrentUser } from '@modules/auth/hooks';
import { UpdateOrgProfileMutation } from '@modules/components/graphql/mutations';
import { OrgProfileForm } from '@modules/custom-components/organisms';
import { OrgProfileUpdateRequestValues } from '@modules/components/requests';
import { useOrganizationDetails, useOrganizationLocation } from '@modules/components/hooks';
import { GetOrganizationDetailsQuery } from '@modules/components/graphql/queries/get-organization-details';

const Main = styled(PageStyled.Main)`
    margin-top: 48px;
`;

const FormWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-width: 625px;
`;

const OrganizationProfileEditPage = (props: Page): React.ReactElement => {
    const { title = 'Edit Organization Profile' } = props;

    const { currentUser, loading: currentUserLoading } = useCurrentUser();

    const { company, loading: loadingCompany } = useCompany({
        skip: !currentUser?.companyId,
        variables: { id: currentUser?.companyId as number },
    });
    const navigate = useNavigate();
    const [orgLocationData, setOrgLocationData] = useState<any>([]);
    const params = useParams();
    const userId = Number(params.id);

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [updateOrganizationProfile, { loading: orgUpdateLoading }] = useMutation<
        UpdateOrgProfileMutationType,
        UpdateOrgProfileMutationVariables
    >(UpdateOrgProfileMutation);

    const { organizationDetails, loading: organizationDetailsLoading } = useOrganizationDetails({
        skip: !userId,
        variables: { user_id: userId as number },
    });
    const initialValues = {
        userId: organizationDetails?.user_id,
        numberOfEmployees: organizationDetails?.number_of_employees || 0,
        sector: organizationDetails?.sector || '',
        organizationType: organizationDetails?.organization_type || '',
        workforce: organizationDetails?.workforce ?? '',
    };

    const handleCancelUser = () => {
        navigate(routes.users.path);
    };
    var datas: any = [];

    const handleSubmit = async (values: any): Promise<void> => {
        try {
            values.orgLocation = orgLocationData?.map((ele: string) => ele);
            const variables = values;
            const { data: updateOrgProfileData } = await updateOrganizationProfile({
                variables,
                refetchQueries: [{ query: GetOrganizationDetailsQuery }],
                awaitRefetchQueries: true,
            });

            if (updateOrgProfileData?.updateOrganizationProfile?.user_id) {
                enqueueSuccess('Organization profile successfully updated!');
                navigate(routes.users.path);
            } else {
                enqueueError('An error occurred while updating the organization profile!');
            }
        } catch (e) {
            throw e;
        }
    };

    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>{title}</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>

            <Main>
                {organizationDetailsLoading || orgUpdateLoading ? (
                    <Loader />
                ) : (
                    <OrgProfileForm<'edit'>
                        type='edit'
                        initialValues={initialValues}
                        FormWrapComponent={FormWrap}
                        onSubmit={handleSubmit}
                        data={datas}
                        onCancel={handleCancelUser}
                        setOrgLocationData={setOrgLocationData}
                        companyName={company?.name}
                        orgLocationData={organizationDetails?.organization_location}
                    />
                )}
            </Main>
        </>
    );
};

export { OrganizationProfileEditPage };

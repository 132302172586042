import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { RiskFunction } from '@modules/types/graphql';

export type Entity = Partial<RiskFunction>;

@DecribeEntity('RiskFunctionEntity')
class RiskFunctionEntity extends BaseEntity {
    id: number;
    name: string;

    constructor(riskFunction: Entity) {
        super(riskFunction);

        this.id = riskFunction?.id ?? -1;
        this.name = riskFunction?.name ?? '';
    }
}

export { RiskFunctionEntity };

import React, { useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import { TextField, Button, Autocomplete } from '@modules/ui/core';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';
import { useUsers } from '@modules/users/hooks';
import { useTkoScope } from '@modules/company/hooks';
import { useAssessmentTypes } from '@modules/shared/hooks';

import type { UserListEntity } from '@modules/users/entities';
import type { ProjectCreateFormValues } from '@modules/projects/requests';
import { AssessmentTypeEntity } from '@modules/shared/entities';
import { TkoScopeEntity } from '@modules/company/entities/tko-scope-entity';
import { useListAssessmentCategories } from '@modules/custom-components/hooks';
import { ListAssessmentCategoriesEntity } from '@modules/custom-components/entities';

type ProjectFormProps<T = ProjectCreateFormValues> = FormProps<T> & {
    onCancel?: () => void;
    isPmRole: boolean;
    isAIAccess?: boolean;
    isSuperAdmin?: boolean;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const ProjectForm = (props: ProjectFormProps): React.ReactElement => {
    let {
        initialValues,
        isAIAccess,
        isSuperAdmin,
        FormWrapComponent,
        onSubmit,
        onCancel,
        isPmRole,
    } = props;
    const [assessmentTypeName, setAssessmentTypeName] = useState<any>([]);
    const { users, loading: usersLoading } = useUsers();
    const { tkoscopes, loading: tkoScopeLoading } = useTkoScope();
    const { assessmentTypes, loading: assessmentTypesLoading } = useAssessmentTypes();
    const { listAssessmentCategories, loading: categoryLoading } = useListAssessmentCategories();

    const getCategoryOptionLabel = React.useCallback(
        (option: ListAssessmentCategoriesEntity | null) => option?.name ?? '-',
        [],
    );

    // const getCompanyOptionLabel = React.useCallback(
    //     (option: CompanyEntity | null) => option?.name ?? '-',
    //     [],
    // );
    const getTkoScopeOptionLabel = React.useCallback(
        (option: TkoScopeEntity | null) => option?.name ?? '-',
        [],
    );

    const getUserOptionLabel = React.useCallback(
        (option: UserListEntity | null) => option?.getFullName() ?? '-',
        [],
    );

    const getAssessmentTypeOptionLabel = React.useCallback(
        (option: AssessmentTypeEntity | null) => option?.name ?? '-',
        [],
    );
    return (
        <ExtendedFormik
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                name: yup.string().required('Required field'),
                description: yup.string().required('Required field'),
                company: yup.mixed().required('Required field'),
                assessmentCategoriesId: yup.mixed().required('Required field'),
                // tkoscope: yup.mixed().required('Required field'),
                stakeholder: yup.mixed().required('Required field'),
                assessmentTypeId: yup.mixed().required('Required field'),
            })}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {formikProps => {
                const {
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    isSubmitting,
                } = formikProps;

                const handleChangeAutocomplete = (field: string) => (
                    _: React.ChangeEvent<{}>,
                    value: any | null,
                ) => {
                    if (field === 'assessmentCategoriesId') {
                        const newAssessment = assessmentTypes?.filter(
                            item => item?.assessmentCategoriesId === (value?.id as any) && item,
                        );
                        setAssessmentTypeName(newAssessment);
                    }
                    setFieldValue(field, value);
                };

                return (
                    <FormWrapComponent>
                        <StyledForm>
                            <TextField
                                fullWidth
                                id='name'
                                label='Project Name'
                                value={values.name}
                                error={!!errors.name}
                                helperText={errors.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <TextField
                                fullWidth
                                multiline
                                id='description'
                                label='Description'
                                rows='4'
                                value={values.description}
                                error={!!errors.description}
                                helperText={errors.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <TextField
                                fullWidth
                                id='company'
                                name='company'
                                label='Company'
                                value={values.company}
                                error={!!errors.company}
                                helperText={errors.company}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                style={{ display: 'none' }}
                            />

                            {/* <Autocomplete<CompanyEntity | null, false, false, false>
                                fullWidth
                                id='company'
                                label='Company'
                                placeholder='Choose Company'
                                options={companies}
                                value={values.company}
                                error={!!errors.company}
                                helperText={errors.company}
                                disabled={companiesLoading}
                                getOptionLabel={getCompanyOptionLabel}
                                onChange={handleChangeAutocomplete('company')}
                                style={!isPmRole ? { display: 'none' } : {}}
                            /> */}
                            <Autocomplete<TkoScopeEntity | null, false, false, false>
                                fullWidth
                                id='tkoscope'
                                label='TKO Scope'
                                placeholder='Choose the TKO Scope'
                                options={tkoscopes}
                                value={values.tkoscope}
                                error={!!errors.tkoscope}
                                helperText={errors.tkoscope}
                                disabled={!(isSuperAdmin || isAIAccess) || tkoScopeLoading}
                                getOptionLabel={getTkoScopeOptionLabel}
                                onChange={handleChangeAutocomplete('tkoscope')}
                            />

                            <Autocomplete<UserListEntity | null, false, false, false>
                                fullWidth
                                id='stakeholder'
                                label='Stakeholder'
                                placeholder='Choose the stakeholder'
                                options={users}
                                value={values.stakeholder}
                                error={!!errors.stakeholder}
                                helperText={errors.stakeholder}
                                disabled={usersLoading}
                                getOptionLabel={getUserOptionLabel}
                                onChange={handleChangeAutocomplete('stakeholder')}
                            />
                            <Autocomplete
                                fullWidth
                                id='assessmentCategoriesId'
                                label='Category Name'
                                placeholder='Choose the category name'
                                options={listAssessmentCategories?.filter(
                                    category => !category?.isRRType,
                                )}
                                // value={values.assessmentCategoriesId}
                                error={!!errors.assessmentCategoriesId}
                                disabled={categoryLoading}
                                helperText={errors?.assessmentCategoriesId}
                                getOptionLabel={getCategoryOptionLabel}
                                onChange={handleChangeAutocomplete('assessmentCategoriesId')}
                            />

                            <Autocomplete<AssessmentTypeEntity | null, false, false, false>
                                fullWidth
                                id='assessmentTypeId'
                                label='Assessment Type'
                                options={assessmentTypeName}
                                value={values.assessmentTypeId}
                                error={!!errors.assessmentTypeId}
                                helperText={errors.assessmentTypeId}
                                disabled={assessmentTypesLoading}
                                getOptionLabel={getAssessmentTypeOptionLabel}
                                onChange={handleChangeAutocomplete('assessmentTypeId')}
                            />

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    Create Project
                                </SubmitButton>

                                <SubmitButton
                                    variant='text'
                                    disabled={isSubmitting}
                                    onClick={onCancel}
                                >
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    </FormWrapComponent>
                );
            }}
        </ExtendedFormik>
    );
};

export { ProjectForm };

import { BaseEntity, Entity as DecribeEntity, RelationList } from '@lib/entity';

import type { CapabilityMaturityLevelEnum } from '@modules/capabilities/constants';
import type { ImpactCapabilityLevel } from '@modules/types/graphql';

export type Entity = Partial<ImpactCapabilityLevel> | null;

@DecribeEntity('ImpactCapabilityLevelEntity')
class ImpactCapabilityLevelEntity extends BaseEntity {
    id: number;
    name: string;
    description: string;
    level: number;

    constructor(capabilityLevel: Entity) {
        super(capabilityLevel);

        this.id = capabilityLevel?.id ?? -1;
        this.name = capabilityLevel?.name ?? '';
        this.description = capabilityLevel?.description ?? '';
        this.level = capabilityLevel?.level ?? 0;
    }

    getMaturityLevel() {
        const result = this.name as CapabilityMaturityLevelEnum;

        return result;
    }

    getCapabilityLevelDefinitionId() {
        // TODO: temp solution, while backend not send definition id
        const id = this.level + 1;

        return id;
    }
}

export { ImpactCapabilityLevelEntity };

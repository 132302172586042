import React from 'react';

import { Modal } from '@modules/ui/core';
import { RecommendationCreateForm } from './recommendation-create-form';

import type { ModalProps } from '@modules/ui/core';

type RecommendationCreateFormModalProps = Omit<ModalProps, 'title' | 'children'> & {
    gapId: number;
    scoredGapId?: number;
    companyId?: number;
    onSuccess?: () => Promise<void> | void;
};

const RecommendationCreateFormModal = (
    props: RecommendationCreateFormModalProps,
): React.ReactElement => {
    const { gapId, scoredGapId, companyId, onClose, onSuccess, ...otherProps } = props;

    return (
        <Modal title='Create Recommendation' onClose={onClose} {...otherProps}>
            <RecommendationCreateForm
                gapId={gapId}
                scoredGapId={scoredGapId}
                companyId={companyId}
                onSuccess={onSuccess}
                onCancel={onClose}
            />
        </Modal>
    );
};

export { RecommendationCreateFormModal };

import React from 'react';
import styled from 'styled-components';

import { white } from '@modules/ui/colors';

type RoundedProgressBarProps = {
    progress: number;
};

const Track = styled.path`
    fill: rgba(0, 0, 0, 0);
    stroke-width: 15;
    stroke: #c4c4c4;
`;

const RootSvg = styled.svg`
    width: 50px;
    height: 50px;
    margin-right: 10px;
`;

const DisplayText = styled.text`
    fill: ${white[100]};
    text-anchor: middle;
    fill: black;
    font-size: 1.8rem;
`;

const Fill = styled.path<{ value?: number }>`
    fill: rgba(0, 0, 0, 0);
    stroke-width: 15;
    stroke: ${white[100]};
    stroke-dasharray: 150.72259521484375;
    transition: stroke-dashoffset 1s;
    stroke: black;
    stroke-dashoffset: ${p => p.value};
`;

const RoundedProgressBar = (props: RoundedProgressBarProps): React.ReactElement => {
    const { progress } = props;

    const offset = 150.72259521484375;
    const value = offset - (offset / 100) * progress;

    return (
        <RootSvg
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            x='0px'
            y='0px'
            viewBox='0 0 80 80'
        >
            <Track
                transform='translate(-10 8) rotate(45 50 50)'
                d='M40,72C22.4,72,8,57.6,8,40C8,22.4,22.4,8,40,8c17.6,0,32,14.4,32,32'
            />

            <DisplayText x='50%' y='60%'>
                {progress}%
            </DisplayText>

            <Fill
                value={value}
                transform='translate(-10 8) rotate(45 50 50)'
                d='M40,72C22.4,72,8,57.6,8,40C8,22.4,22.4,8,40,8c17.6,0,32,14.4,32,32'
            />
        </RootSvg>
    );
};

export { RoundedProgressBar };

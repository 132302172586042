import { BaseEntity, Entity as DecribeEntity, RelationList } from '@lib/entity';
import { CapabilityEntityList } from '@modules/capabilities/entities';

import type { AllComponent } from '@modules/types/graphql';
import type { CapabilityEntityType } from '@modules/capabilities/entities';
import { CapabilityEntity } from '@modules/capabilities/entities';

export type AllComponentEntityType = Partial<
    Omit<AllComponent, 'capabilities'> & {
        capabilities: CapabilityEntityType[] | null;
    }
> | null;

@DecribeEntity('AllComponentEntity')
class AllComponentEntity extends BaseEntity {
    id: number;
    name: string;
    companyId: number | null;
    assessmentTypeId: number;

    @RelationList(() => CapabilityEntityList)
    capabilities: CapabilityEntity[];

    constructor(allComponent: AllComponentEntityType) {
        super(allComponent);

        this.id = allComponent?.id ?? -1;
        this.name = allComponent?.name ?? '';
        this.companyId = allComponent?.company_id ?? null;
        this.assessmentTypeId = allComponent?.assessment_type_id ?? -1;
    }

    isPredefined() {
        const result = this.companyId === null;

        return result;
    }
}

export { AllComponentEntity };

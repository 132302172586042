import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { Button } from '@modules/ui/core';
import { gray } from '@modules/ui/colors';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { TaskCreateRequest, TaskUpdateRequest } from '@modules/custom-components/requests';
import { DialogTaskDelete } from '@modules/custom-components/moleculas';
import { TaskForm } from '@modules/custom-components/organisms';
import {
    CreateTaskMutation,
    UpdateTaskMutation,
    DeleteTaskMutation,
} from '@modules/custom-components/graphql';

import type { TaskEntity } from '@modules/custom-components/entities';
import type {
    TaskCreateFormValues,
    TaskUpdateFormValues,
} from '@modules/custom-components/requests';
import type {
    CreateTaskMutationType,
    CreateTaskMutationVariables,
    UpdateTaskMutationType,
    UpdateTaskMutationVariables,
    DeleteTaskMutationType,
    DeleteTaskMutationVariables,
} from '@modules/types/graphql';

type TasksListProps = {
    tasks: TaskEntity[];
    requirementId: number;
    onCancel?: () => void;
};

const Root = styled.div`
    width: 100%;

    > * {
        margin-bottom: 16px;
    }
`;

const Toolbar = styled.div`
    width: 100%;
`;

const List = styled.div`
    width: 100%;

    > * {
        margin-bottom: 16px;
        border-bottom: 1px solid ${gray[50]};
    }
`;

const Item = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 8px 0;
`;

const Title = styled.div`
    font-size: 1.4rem;
`;

const Actions = styled.div`
    margin-left: auto;

    > * {
        margin-left: 8px;
    }
`;

const Empty = styled.div`
    font-size: 1.6rem;
    font-weight: 700;
`;

const FormWrap = styled.div``;

const TasksList = (props: TasksListProps): React.ReactElement => {
    const { tasks, requirementId } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [openCreateTask, setOpenCreateTask] = React.useState<boolean>(false);
    const [editableTask, setEditableTask] = React.useState<TaskEntity | null>(null);

    const handleToggleCreateTask = (): void => {
        setEditableTask(null);
        setOpenCreateTask(state => !state);
    };

    const handleSetEditableTask = (task: TaskEntity) => {
        setOpenCreateTask(false);
        setEditableTask(state => (state?.id === task.id ? null : task));
    };

    const [createTask] = useMutation<CreateTaskMutationType, CreateTaskMutationVariables>(
        CreateTaskMutation,
    );
    const [updateTask] = useMutation<UpdateTaskMutationType, UpdateTaskMutationVariables>(
        UpdateTaskMutation,
    );

    const [deleteTask, { loading: deleteTaskLoading }] = useMutation<
        DeleteTaskMutationType,
        DeleteTaskMutationVariables
    >(DeleteTaskMutation);

    const taskInitialValues = {
        requirementId,
        task: '',
        resources: '',
        duration: '',
    };

    const handleCreateTaskSubmit = async (values: TaskCreateFormValues) => {
        try {
            const variables = new TaskCreateRequest(values);

            const { data: createTaskData } = await createTask({
                variables,
            });

            const taskId = createTaskData?.createTask?.id;

            if (taskId) {
                enqueueSuccess('Task successfully created!');
            } else {
                enqueueError('An error occurred while creating task!');
            }
        } catch (e) {
            throw e;
        }
    };

    const handleUpdateTaskSubmit = async (values: TaskUpdateFormValues) => {
        try {
            const variables = new TaskUpdateRequest(values);

            const { data: updateTaskData } = await updateTask({
                variables,
            });

            const taskId = updateTaskData?.updateTask?.id;

            if (taskId) {
                enqueueSuccess('Task successfully updated!');
            } else {
                enqueueError('An error occurred while updatingcs task!');
            }
        } catch (e) {
            throw e;
        }
    };

    const handleDeleteTaskSubmit = (taskId: number) => async (): Promise<void> => {
        try {
            const { data: deleteTaskData } = await deleteTask({
                variables: { taskId },
            });

            if (deleteTaskData?.deleteTask) {
                enqueueSuccess('Task successfully deleted!');
            } else {
                enqueueError('An error occurred while deleting task!');
            }
        } catch (e) {
            throw e;
        }
    };

    return (
        <Root>
            <Toolbar>
                <Button size='extra-small' onClick={handleToggleCreateTask}>
                    {openCreateTask ? 'Close Adding Task Form' : 'Open Adding Task Form'}
                </Button>
            </Toolbar>

            {tasks.length === 0 ? (
                <Empty>No tasks</Empty>
            ) : (
                <List>
                    {tasks.map(task => (
                        <Item key={task.id}>
                            <Title>{task.task}</Title>

                            <Actions>
                                <Button
                                    size='extra-small'
                                    variant={
                                        editableTask?.id === task.id ? 'contained' : 'outlined'
                                    }
                                    onClick={() => handleSetEditableTask(task)}
                                >
                                    Edit
                                </Button>

                                <DialogTaskDelete
                                    disabled={deleteTaskLoading}
                                    onDelete={handleDeleteTaskSubmit(task.id)}
                                />
                            </Actions>
                        </Item>
                    ))}
                </List>
            )}

            {openCreateTask ? (
                <TaskForm<'create'>
                    type='create'
                    initialValues={taskInitialValues}
                    onSubmit={handleCreateTaskSubmit}
                    FormWrapComponent={FormWrap}
                />
            ) : null}

            {editableTask ? (
                <TaskForm<'edit'>
                    type='edit'
                    initialValues={{
                        taskId: editableTask.id,
                        task: editableTask.task,
                        resources: editableTask.resources,
                        duration: editableTask.duration,
                    }}
                    onSubmit={handleUpdateTaskSubmit}
                    FormWrapComponent={FormWrap}
                />
            ) : null}
        </Root>
    );
};

export { TasksList };

import gql from 'graphql-tag';

import { UserFragment } from '../fragments';

export const UpdateUserMutation = gql`
    mutation UpdateUser(
        $id: Int!
        $firstName: String
        $lastName: String
        $isAdmin: Boolean
        $companyId: Int
        $userRole: String
        $schemaName: String
    ) {
        updateUser(
            id: $id
            first_name: $firstName
            last_name: $lastName
            is_admin: $isAdmin
            company_id: $companyId
            user_role: $userRole
            schema_name: $schemaName
        ) {
            ...User
        }
    }
    ${UserFragment}
`;

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { getColorAndTitleByScoreForAligned } from '@modules/shared/constants/score-variant-colors';

const Root = styled.div`
    height: 100px;
    width: 134px;
    display: flex;
    border: 1px solid #000000;
    border-radius: 50%;
    padding: 37px 20px 37px 20px;
    color: black;
    justify-content:center;
    align-items:center;
    ${props => css`
        background: ${props.color};
    `}
`;

const Label = styled.div`
    font-weight: 600;
    text-transform: capitalize;
    ${props => css`
        color: ${props.color};
    `}    
`;

const RootAligned = styled.div`
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    background: #fff;

    > * {
        padding: 8px 16px;
    }
    ${props => css`
        background: ${props.color};
    `}
`;

const AlignedLabel = styled.div`
    font-size: 1.4rem;
    border-radius: 4px;
    text-align: center;
    text-transform: capitalize;
    ${props => css`
        color: ${props.color};
    `}
`;

const AssetRiskLabel = (props: any): React.ReactElement => {
    const { score, type } = props;
    const { color, title, textcolor } = getColorAndTitleByScoreForAligned(score, 'risk');

    return (
        type === 'riskAligned' ?
            < RootAligned color={color} >
                <AlignedLabel color={textcolor}>{title}</AlignedLabel>
            </RootAligned >
            :
            < Root color={color} >
                <Label color={textcolor}>{title}</Label>
            </Root >
    );
};

export { AssetRiskLabel };

import { QueryHookOptions, useQuery } from '@apollo/client';
import { GetLearnTkoQueryType, GetLearnTkoQueryVariable } from '@modules/types/graphql';

import { useEntity } from '@lib/entity';
import { LearnsTkoEntityList } from '../entities';
import { GetLearnTkoQuery } from '../graphql';

const useLearnTko = (option?: QueryHookOptions<GetLearnTkoQueryType>) => {
    const queryResult = useQuery<GetLearnTkoQueryType>(GetLearnTkoQuery, option);

    const { entities: learnTko, count } = useEntity(
        () => new LearnsTkoEntityList(queryResult.data?.getLearnTko ?? null),
        [queryResult.data],
    );

    const result = {
        learnTko,
        count,
        ...queryResult,
    };
    return result;
};

export { useLearnTko };

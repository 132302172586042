import React from 'react';
import styled from 'styled-components';

import { white, gray } from '@modules/ui/colors';
import { InnerListStyled } from '@modules/layout/styled';
import { ReportComponentCapabilitiesListItem } from './report-component-capabilities-list-item';

import type { CapabilityReportEntity } from '@modules/assessment/entities';

type ReportComponentCapabilitiesListProps = {
    capabilitiesReportList: CapabilityReportEntity[];
};

const List = styled(InnerListStyled.List)`
    background-color: ${white[100]};
    border: 1px solid ${gray[60]};
`;

const ReportComponentCapabilitiesList = (
    props: ReportComponentCapabilitiesListProps,
): React.ReactElement => {
    const { capabilitiesReportList } = props;

    return (
        <InnerListStyled.Root>
            <InnerListStyled.Header>
                <h2>Capabilities</h2>
            </InnerListStyled.Header>

            <List>
                {capabilitiesReportList.map((capabilityReport, index) => (
                    <ReportComponentCapabilitiesListItem
                        key={`${capabilityReport.capabilityAssessmentId}-${index}`}
                        capabilityReport={capabilityReport}
                    />
                ))}
            </List>
        </InnerListStyled.Root>
    );
};

export { ReportComponentCapabilitiesList };

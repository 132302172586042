import { EntityList } from '@lib/entity';
import { UserListEntity } from './user-list-entity';

import type { Entity } from './user-list-entity';

class UserListEntityList extends EntityList<UserListEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(UserListEntity, items, count);
    }
}

export { UserListEntityList };

import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from '@reach/router';
import { useMutation } from '@apollo/client';

import { routes } from '@config/routes';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { PageStyled } from '@modules/layout/styled';

import type { Page } from '@modules/types/page';
import { LearnTkoForm } from '@modules/learn-tko/organisms';
import {
    CreateLearnTkoDetailsMutationType,
    CreateLearnTkoDetailsMutationVariables,
} from '@modules/types/graphql';
import { CreateLearnTkoMutation } from '@modules/learn-tko/graphql/mutations';
import { GetAllLearnTkoDetailQuery } from '@modules/learn-tko/graphql';

const Main = styled(PageStyled.Main)`
    margin-top: 48px;
`;

const FormWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-width: 625px;
`;
const initialValues: any = {
    menu: '',
    title: '',
    description: '',
    // video_url: '',
};
const CreateLearnTko = (props: Page): React.ReactElement => {
    const { title = 'Create Learn Tko' } = props;

    const navigate = useNavigate();
    const [editorDescription, setEditorDescription] = useState<any>('');

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [createLearnTkoDetail] = useMutation<
        CreateLearnTkoDetailsMutationType,
        CreateLearnTkoDetailsMutationVariables
    >(CreateLearnTkoMutation);

    const handleSubmit = async (values: any): Promise<void> => {
        try {
            const { data: createLearnTkoData } = await createLearnTkoDetail({
                variables: {
                    menu: values.menu,
                    title: values.title,
                    description: editorDescription,
                    video_url: values.video_url,
                },
                refetchQueries: [{ query: GetAllLearnTkoDetailQuery }],
                awaitRefetchQueries: true,
            });
            let id: number | any = createLearnTkoData?.createLearnTkoDetail;
            if (id) {
                enqueueSuccess('Learn tko successfully created!');
                navigate(routes.viewLearnTko.path);
            } else {
                enqueueError('An error occurred while creating the learn tko!');
            }
        } catch (e) {
            throw e;
        }
    };

    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>{title}</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>

            <Main>
                <LearnTkoForm
                    type='create'
                    initialValues={initialValues}
                    FormWrapComponent={FormWrap}
                    onSubmit={handleSubmit}
                    editorDescription={editorDescription}
                    setEditorDescription={setEditorDescription}
                    loading={false}
                />
            </Main>
        </>
    );
};

export { CreateLearnTko };

import React, { ChangeEvent, useMemo } from 'react';
import styled from 'styled-components';

import { ListStyled } from '@modules/layout/styled';

import type { ProjectEntity } from '@modules/projects/entities';
import type { RoadmapReportEntity } from '@modules/roadmap/entities';
import { ProjectAssessmentComponentsFilter } from '@modules/projects/organisms';
import { UserEntity } from '@modules/users/entities';
import { ProjectAssessmentFilterValue } from '@modules/projects/organisms/project-assessment-components-filter/project-assessment-components-filter';
import {
    roadmapCollecCO,
    roadmapFilterHandler,
    assessmentCollectCO,
    assessmentFilterHandler,
} from '@modules/projects/organisms/project-assessment-components-filter/filter-handlers';
import { usePropState } from '@modules/shared/hooks/use-prop-state';
import { ReportGeneralInfo } from './report-general-info';
import { ReportBodyView } from './report-body-view';
import { ExportButton } from '@modules/ui/core/button/exportButton';
import { useGeneralRoles } from '@modules/shared/hooks/use-general-roles';
import { Tab, Tabs } from '@material-ui/core';
import { ComponentAssessmentEntity } from '@modules/assessment/entities';
import {
    RecommendationByRisk,
    RecommendationViewData,
} from '@modules/roadmap/recommendation-by-risk';
import { PrioritizedRoadmapEntity } from '@modules/roadmap/entities';
import {
    getPrioritizedRoadmapAssessmentMap,
    RecommendationAssessment,
} from '@modules/roadmap/recommendation-assessment-roadmap-report';

type ReportRoadmapProps = {
    project: ProjectEntity;
};

const ReportRoadmap = (props: ReportRoadmapProps): React.ReactElement => {
    const { project } = props;
    const prioritizedRoadmapRaw: PrioritizedRoadmapEntity = project.roadmap;

    // filter to be align with assessment report results
    const componentsAssessmentRawList: ComponentAssessmentEntity[] = useMemo(
        () => project.assessments.filter(value => value.maturityLevel),
        [project.assessments],
    );
    const recommendationViewDataRaw: RecommendationViewData = useMemo(
        () => RecommendationByRisk.recommendationAssessmentByRisk(componentsAssessmentRawList),
        [componentsAssessmentRawList],
    );
    const recommendationAssessmentMapRaw: Map<number, RecommendationAssessment> = useMemo(
        () => getPrioritizedRoadmapAssessmentMap(project),
        [project.assessments],
    );

    const componentOwnerMap = assessmentCollectCO(componentsAssessmentRawList);
    roadmapCollecCO(componentOwnerMap, prioritizedRoadmapRaw.dataSource);
    const componentOwnerList: UserEntity[] = Array.from(componentOwnerMap.values()).filter(
        user => user.id !== -1,
    );

    const [componentsAssessmentList, setComponentsAssessmentList] = usePropState(
        componentsAssessmentRawList,
    );
    const [prioritizedRoadmap, setPrioritizedRoadmap] = usePropState(prioritizedRoadmapRaw);
    function onFilterChange(filterValue: ProjectAssessmentFilterValue) {
        assessmentFilterHandler(
            setComponentsAssessmentList,
            componentsAssessmentRawList,
            filterValue,
        );
        roadmapFilterHandler(
            setPrioritizedRoadmap,
            prioritizedRoadmapRaw,
            recommendationAssessmentMapRaw,
            filterValue,
        );
    }

    const { isGeneralEditAllowed } = useGeneralRoles(project.id);

    const [activeTab, setActiveTab] = React.useState(0);
    const handleChangeTab = (e: ChangeEvent<{}>, newValue: any) => {
        setActiveTab(newValue);
    };

    return (
        <ListStyled.Root>
            <ListStyled.Header>
                <h2>Roadmap Report</h2>

                <ListStyled.HeaderInfo>
                    <ExportButton size='small' exportfilename='roadmap-report' />
                </ListStyled.HeaderInfo>
            </ListStyled.Header>

            {componentsAssessmentRawList && componentsAssessmentRawList?.length && (
                <ReportGeneralInfo
                    prioritizedRoadmap={prioritizedRoadmapRaw}
                    componentsAssessmentRawList={componentsAssessmentRawList}
                    recommendationViewDataRaw={recommendationViewDataRaw}
                    project={project}
                    activeTab={activeTab}
                    isGeneralEditAllowed={isGeneralEditAllowed}
                />
            )}

            <ListStyled.HeaderToolbar>
                <Tabs
                    value={activeTab}
                    onChange={handleChangeTab as any}
                    indicatorColor='primary'
                    textColor='primary'
                    className='tab-header'
                >
                    <Tab label='Components' value={0} />
                    <Tab label='Recommendations' value={1} />
                </Tabs>
                <ProjectAssessmentComponentsFilter
                    componentOwnerList={componentOwnerList}
                    hasRiskLevelFilter={true}
                    hasImplementationStatusFilter={true}
                    onFilterChange={onFilterChange}
                />
            </ListStyled.HeaderToolbar>

            <ReportBodyView
                prioritizedRoadmap={prioritizedRoadmap}
                componentsAssessmentList={componentsAssessmentList}
                recommendationViewDataRaw={recommendationViewDataRaw}
                project={project}
                activeTab={activeTab}
            />
        </ListStyled.Root>
    );
};

export { ReportRoadmap };

import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { Button } from '@modules/ui/core';
import { TableCellText } from '@modules/layout/atoms';
import { CompletionLevels } from '@modules/components/moleculas';
import { ComponentAssessmentEntity } from '@modules/assessment/entities';
import { ProjectMaturityLevelEnum } from '@modules/projects/constants';
import { ProjectMaturityLevel } from '@modules/projects/moleculas';

type RecommendationsProps = {
    projectId: number;
};

const useStyles = makeStyles({
    hide: {
        opacity: 0,
        transition: 'opacity 0.5s linear',
    },
    show: {
        opacity: 1,
        transition: 'opacity 0.5s linear',
    },
    accordionSummaryContent: {
        alignItems: 'center',
        padding: '10px',
        justifyContent: 'space-between',
    },
});

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
`;

const AccordionStyled = styled(Accordion)`
    background-color: #f2f2f2;
`;

const AccordionText = styled(Typography)`
    font-size: 14px;
`;

const TableCellTextRow = styled(TableCellText)`
    flex-basis: 30%;
    flex-shrink: 0;
`;

const TableCellTextTitle = styled(TableCellTextRow)`
    font-size: 24px;
    font-weight: 700;
`;

const AccordionDetailsStyled = styled(AccordionDetails)`
    flex-direction: column;
    justify-content: space-between;
    padding: 0 30px;
`;

const List = styled.div`
    width: 100%;
    margin: 32px 0;
`;

const Footer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-top: 60px;
`;

const HeaderTitle = styled.h2`
    font-size: 36px;
`;

const Recommendations = (props: RecommendationsProps): React.ReactElement => {
    const { projectId } = props;

    const [expanded, setExpanded] = React.useState<number | boolean>(false);
    const classes = useStyles();

    const handleExpandComponent = (panel: number) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean,
    ) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Root>
            <Header>
                <HeaderTitle>Recommendations</HeaderTitle>
            </Header>
            <List>
                <AccordionStyled expanded={expanded === 1} onChange={handleExpandComponent(1)}>
                    <AccordionSummary
                        classes={{
                            content: classes.accordionSummaryContent,
                        }}
                        expandIcon={<ExpandMore />}
                        aria-controls='panel1bh-content'
                        id='panel1bh-header'
                    >
                        <TableCellTextTitle variant='head'>
                            Governance and Strategy
                        </TableCellTextTitle>
                        <TableCellTextRow>
                            <ProjectMaturityLevel current={ProjectMaturityLevelEnum.Limited} />
                        </TableCellTextRow>
                    </AccordionSummary>
                    <AccordionDetailsStyled>
                        <CompletionLevels />
                    </AccordionDetailsStyled>
                </AccordionStyled>
            </List>

            <Footer>
                <Button size='small' variant='outlined'>
                    Send for Review
                </Button>
            </Footer>
        </Root>
    );
};

export { Recommendations };

import type { AddAssessmentCategoriesMutationVariables } from '@modules/types/graphql';

export type CustomCategoryCreateFormValues = {
    name: string;
    isRRType: boolean;
};

class CustomCategoryCreateRequest {
    name: AddAssessmentCategoriesMutationVariables['name'];

    isRRType: AddAssessmentCategoriesMutationVariables['isRRType'];

    constructor(values: CustomCategoryCreateFormValues) {
        this.name = values.name;
        this.isRRType = values.isRRType;
    }
}

export { CustomCategoryCreateRequest };

import React from 'react';

import { Button, Dialog } from '@modules/ui/core';

type DialogRecommendationDeleteProps = {
    disabled: boolean;
    onDelete: () => Promise<void>;
};

const DialogRecommendationDelete = (props: DialogRecommendationDeleteProps): React.ReactElement => {
    const { onDelete, ...otherProps } = props;

    return (
        <Dialog
            title='Are you sure you want to delete this recommendation?'
            acceptText='Delete'
            onAccept={onDelete}
            {...otherProps}
        >
            {dialogProps => {
                const { handleOpen } = dialogProps;

                return (
                    <Button size='extra-small' onClick={handleOpen}>
                        Delete
                    </Button>
                );
            }}
        </Dialog>
    );
};

export { DialogRecommendationDelete };

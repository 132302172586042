import React from 'react';
import { makeStyles, TableCell as MuiTableCell } from '@material-ui/core';

import { gray } from '@modules/ui/colors';
import { concatenateClasses } from '@modules/ui/utils';

import type { TableCellProps as MuiTableCellProps } from '@material-ui/core';

type TableCellProps = MuiTableCellProps;

const useTableCellStyles = makeStyles({
    root: {
        padding: '8px 16px',
        transition: 'background-color .2s',
    },
    head: {
        fontSize: '1.4rem',
        fontWeight: 400,
        lineHeight: '25px',
        border: 'none',
    },
    body: {
        backgroundColor: gray[60],
        border: 'none',
    },
});

const TableCell = (props: TableCellProps): React.ReactElement => {
    const { classes, ...otherProps } = props;

    const tableCellClasses = concatenateClasses(useTableCellStyles(), classes);

    return <MuiTableCell classes={tableCellClasses} {...otherProps} />;
};

export { TableCell };

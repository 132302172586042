// import { QueryHookOptions, useQuery } from '@apollo/client';
// import { GetWorkForceQuery } from '../graphql/queries';
// import { useEntity } from '@lib/entity';
// import { WorkForceEntityList } from '../entities';

// const useWorkForce = (options?: QueryHookOptions) => {
//     const { data, loading } = useQuery(GetWorkForceQuery, options);

//     const queryResult = useQuery(GetWorkForceQuery, options);
//     const { entities: workforces, count } = useEntity(
//         () => new WorkForceEntityList(queryResult.data?.listWorkforces ?? null),
//         [queryResult.data],
//     );

//     const result = {
//         workforces,
//         count,
//         queryResult,
//     };

//     return result;
// };

// export { useWorkForce };

import { QueryHookOptions, useQuery } from '@apollo/client';
import { GetWorkForceQuery } from '../graphql/queries';

const useWorkForce = (options?: QueryHookOptions) => {
    const { data, loading } = useQuery(GetWorkForceQuery, options);

    const result = {
        workforces: data?.listWorkforces,
        loading,
    };

    return result;
};

export { useWorkForce };

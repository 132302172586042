import { gql } from '@apollo/client';

import {CapabilityAssessmentFragment} from './capability-assessment';

export const ComponentAssessmentFragment = gql`
    fragment ComponentAssessment on ComponentAssessment {
        id
        status
        roadmap_status
        weight
        objectives
        maturity_level {
            id
            name
        }
        component {
            id
            name
            capabilities {
                id
                title
                key_factors
                impact
                security_controls
            }
        }
        component_owners {
            id
            first_name
            last_name
        }
        operators {
            id
            first_name
            last_name
        }
        capabilities {
            ...CapabilityAssessment
        }
    }
    ${CapabilityAssessmentFragment}
`;

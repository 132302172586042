import React from 'react';
import deepmerge from 'deepmerge';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import {
    StylesProvider,
    ThemeProvider as MuiThemeProvider,
    createMuiTheme,
} from '@material-ui/core/styles';

import { muiTheme as defaultMuiTheme } from './mui-theme';

type ThemeProviderProps = {
    children: React.ReactNode;
    theme?: Record<string, any>;
};

const ThemeProvider = (props: ThemeProviderProps): React.ReactElement => {
    const { theme, children } = props;

    const mergedTheme = theme ? deepmerge(defaultMuiTheme, theme) : defaultMuiTheme;

    const muiTheme = createMuiTheme(mergedTheme);

    return (
        <StylesProvider injectFirst>
            <StyledThemeProvider theme={muiTheme}>
                <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
            </StyledThemeProvider>
        </StylesProvider>
    );
};

export { ThemeProvider };

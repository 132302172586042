import React from 'react';
import styled from 'styled-components';

import { gray } from '@modules/ui/colors';
import { LogoFooter } from '@modules/layout/icons';

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    border-top: 1px solid ${gray[50]};
`;

const Logotype = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > * {
        display: inline-flex;
    }
`;

const Footer = (): React.ReactElement => {
    return (
        <Root>
            <Logotype>
                <LogoFooter />
            </Logotype>
        </Root>
    );
};

export { Footer };

import { gql } from '@apollo/client';

export const GetAllAssessmentTypeQuery = gql`
    query GetAllComponents($filter: String, $companyId: Int) {
        listAllComponents(filter: $filter, company_id: $companyId)
            @connection(key: "listAllComponents") {
            id
            name
            company_id
            assessment_details {
                id
                name
                company_id
                component {
                    id
                    name
                    company_id
                    assessment_type_id
                    capabilities {
                        id
                        title
                        key_factors
                    }
                }
            }
        }
    }
`;

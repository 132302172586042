import React, { useMemo } from 'react';
import { format } from 'date-fns';

import {TextCommendLog, User} from '@modules/types/graphql';
import {RoadmapRecommendationEntity} from '@modules/roadmap/entities';
import {AddNotesForm} from '@modules/roadmap/moleculas';
import {ProjectEntity} from '@modules/projects/entities';
import styled from 'styled-components';
import {usePropState} from '@modules/shared/hooks/use-prop-state';
import {useCurrentUser} from '@modules/auth/hooks';

type RoadmapNotesProps = {
  notes: (TextCommendLog | null)[];
  showNotes: boolean;
  hasStRole?: boolean;
  hasPmRole?: boolean;
  hasCoRole?: boolean;
  project?: ProjectEntity;
  roadmapRecommendation?: RoadmapRecommendationEntity;
};

const StyledNote = styled.div`
    padding: 10px 10px 15px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 6px;

    .is-username {
        color: #727272;
        font-size: 14px;
    }

    .is-time {
        color: #727272;
        font-size: 14px;
        display: inline-block;
        padding-left: 10px;
    }

    .is-note {
        font-size: 14px;
        padding-top: 10px;
    }
`;

const NotesBlock = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;

    a {
        text-decoration: underline;
        font-size: 14px;
        display: block;
        padding: 5px 0 10px;
    }
`;

const AdditionalHiddenBlockSection = styled.div`
    padding: 10px 0;

    h4 {
        font-size: 18px;
        font-weight: bold;
    }
`;

export const RoadmapNotes = (
  props: RoadmapNotesProps,
): React.ReactElement => {
  const { notes: notesRaw, showNotes, hasStRole, hasPmRole, hasCoRole, project, roadmapRecommendation } = props;

  const [notes, setNotes] = usePropState(notesRaw);
  const { currentUser, loading: currentUserLoading } = useCurrentUser();
  function onAddNote(note: string) {
    setNotes([...notes, {
      comment: note,
      created_at: (new Date()).toISOString(),
      created_by: {
        id: currentUser?.id ?? 0,
        first_name: currentUser?.firstName ?? '',
        last_name: currentUser?.lastName ?? ''
      } as User,
      id: (notes[notes.length - 1]?.id ?? 10) + 1,
    }]);
  }

  return (
    <NotesBlock>
      {showNotes ? (
        <AdditionalHiddenBlockSection>
          <h4>Notes/comments:</h4>
          {notes?.map((note: any, i: number) => (
            <StyledNote key={i}>
                                <span className='is-username'>
                                    {note.created_by?.first_name} {note.created_by?.last_name}
                                </span>
              <span className='is-time'>
                                    {format(new Date(note.created_at), 'dd.MM.yyyy hh:mm')}
                                </span>
              <p className='is-note'>{note.comment}</p>
            </StyledNote>
          ))}
          {hasStRole || hasPmRole || hasCoRole ? (
            <AddNotesForm
              project={project}
              roadmapRecommendation={roadmapRecommendation}
              onAddNote={onAddNote}
            />
          ) : null}
        </AdditionalHiddenBlockSection>
      ) : (
        ''
      )}
    </NotesBlock>
  );
};

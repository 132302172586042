import { EntityList } from '@lib/entity';

import type { AssessmentDetailsEntityType as Entity } from './assessment-details-entity';
import { AssessmentDetailsEntity } from './assessment-details-entity';

class AssessmentDetailsEntityList extends EntityList<AssessmentDetailsEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(AssessmentDetailsEntity, items, count);
    }
}

export { AssessmentDetailsEntityList };

import React from 'react';
import { Form } from 'formik';
import styled from 'styled-components';
import { MenuItem, FormControlLabel, makeStyles } from '@material-ui/core';

import { Autocomplete, TextField, Button, Modal } from '@modules/ui/core';
import { ComponentAssessmentEntity } from '@modules/assessment/entities';
import { FormStyled } from '@modules/layout/styled';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { TableCellText } from '@modules/layout/atoms';
import { ExtendedFormik } from '@modules/layout/organisms';
import { Loader, TableRow, TableCell } from '@modules/layout/moleculas';
import { TableCheckbox } from '@modules/layout/atoms';
import { Table } from '@modules/layout/organisms';

import type { RecommendationUpdateFormValues } from '@modules/components/requests';
import type { ModalProps } from '@modules/ui/core';

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const FormWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-width: 625px;
    width: 100%;
`;

const emptyInitialValues = {
    weight: 1,
    componentOwner: null,
    objectives: '',
    capabilities: [],
};

const recommendations = [
    'Define and capture how the Program will be managed',
    'Molestie vitae in in nunc sagittis faucibus condimentum. Pellentesque proin velit est turpis elementum amet. Potenti tempus semper.',
    'Volutpat facilisis eu, nisi tempus volutpat urna, bibendum nascetur volutpat. Fermentum semper consectetur habitant porttitor tortor. Nibh ut vivamus.',
    'Adipiscing elit nisi, mattis erat elementum. Ac lacus, faucibus tempor velit. Porttitor nibh tellus blandit fames. In molestie facilisis orci sed turpis rhoncus mauris. Montes.',
];

const RecommendationFormModal = (
    props: Omit<ModalProps, 'title' | 'children'>,
): React.ReactElement => {
    const { onClose, ...otherProps } = props;

    const { enqueueSuccess } = useEnqueueStacks();

    const initialValues: RecommendationUpdateFormValues = {
        recommendations: [],
    };

    // const initialValues = {
    //     weight: choosenComponent?.weight ?? 0,
    //     componentOwner: choosenComponent?.componentOwners[0],
    //     objectives: '', //TODO: choosenComponent.objectives
    //     capabilities: choosenComponent?.capabilities.map(c => {
    //         return {
    //             capability_id: c.capability.id,
    //             weight: 0,
    //         };
    //     }),
    // };

    return (
        <Modal
            title='No insider threat/risk management structure (Gap #1)'
            onClose={onClose}
            {...otherProps}
        >
            <FormWrap>
                <ExtendedFormik<RecommendationUpdateFormValues>
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={initialValues}
                    onSubmit={async values => {
                        enqueueSuccess('Saved');
                        onClose();
                    }}
                >
                    {formikProps => {
                        const { values, setFieldValue, isSubmitting } = formikProps;

                        const handleCheckRecommendation = (
                            event: React.ChangeEvent<{ name: string }>,
                            id: string,
                        ) => {
                            const recommendations = [...values!.recommendations!];
                            const index = recommendations.findIndex(c => c === id);
                            if (index === -1) {
                                recommendations.push(id);
                            } else {
                                recommendations.splice(index, 1);
                            }
                            setFieldValue('recommendations', recommendations);
                        };

                        return (
                            <StyledForm>
                                <Table>
                                    {recommendations.map(recommendation => {
                                        return (
                                            <TableRow key={recommendation}>
                                                <TableCell>
                                                    <FormControlLabel
                                                        control={
                                                            <TableCheckbox
                                                                name='id'
                                                                checked={values.recommendations.includes(
                                                                    recommendation,
                                                                )}
                                                                onChange={e =>
                                                                    handleCheckRecommendation(
                                                                        e,
                                                                        recommendation,
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label={
                                                            <TableCellText>
                                                                {recommendation}
                                                            </TableCellText>
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </Table>

                                <FormStyled.FormRowSubmit>
                                    <SubmitButton type='submit' loading={isSubmitting}>
                                        Save
                                    </SubmitButton>

                                    <SubmitButton
                                        variant='text'
                                        disabled={isSubmitting}
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </SubmitButton>
                                </FormStyled.FormRowSubmit>
                            </StyledForm>
                        );
                    }}
                </ExtendedFormik>
            </FormWrap>
        </Modal>
    );
};

export { RecommendationFormModal };

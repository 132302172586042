import type { AddCustomRecommendationMutationVariables } from '@modules/types/graphql';

export type Values = {
    scoredGapId: number;
    recommendation: string;
};

class AddCustomRecommendationRequest {
    scoredGapId: AddCustomRecommendationMutationVariables['scoredGapId'];
    recommendation: AddCustomRecommendationMutationVariables['recommendation'];

    constructor(values: Values) {
        this.scoredGapId = values.scoredGapId;
        this.recommendation = values.recommendation;
    }
}

export { AddCustomRecommendationRequest };

import styled from 'styled-components';

const FormRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
`;

export const FormStyled = {
    FormColumn: styled.div`
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        > * {
            &:not(:last-of-type) {
                margin-bottom: 24px;
            }
        }
    `,

    FormRow,

    FormRowSubmit: styled(FormRow)`
        margin-top: 10px;
        > * {
            &:not(:last-of-type) {
                margin-right: 16px;
            }
        }
    `,
};

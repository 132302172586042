import { EntityList } from '@lib/entity';

import type { Entity } from './sub-task-entity';
import { SubTaskEntity } from './sub-task-entity';

class SubTaskEntityList extends EntityList<SubTaskEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(SubTaskEntity, items, count);
    }
}

export { SubTaskEntityList };

import React from 'react';
import { Redirect } from '@reach/router';

import { routes } from '@config/routes';

const redirectByRoles = () => {
    return <Redirect noThrow to={routes.welcome.path} />;
};

export { redirectByRoles };

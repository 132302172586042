import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import { PageStyled } from '@modules/layout/styled';
import { TableCellText } from '@modules/layout/atoms';
import { Loader } from '@modules/layout/moleculas';
import { AccordionListItem } from '@modules/layout/organisms';
import { useCurrentUser } from '@modules/auth/hooks';
import { gray } from '@modules/ui/colors';

import { CustomComponentsListToolbar } from '@modules/custom-components/moleculas';
import {
    DeleteAssessmentCategoriesMutation,
} from '@modules/custom-components/graphql';
import type { TableColumn } from '@modules/layout/organisms/table';
import type { Page } from '@modules/types/page';
import type {
    DeleteAssessmentCategoriesMutationType,
    DeleteAssessmentCategoriesMutationVariables,
} from '@modules/types/graphql';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { CategoryActions } from '@modules/custom-components/moleculas/component-actions/category';
import { useEnqueueStacks } from '@modules/layout/hooks';
import AssessmentTypeList from './assessment-type-list';
import {
    CustomCategoryEditModal,
} from '@modules/custom-components/moleculas/component-page-modal';
import { useAllAssessmentType } from '@modules/components/hooks/use-all-assessment-type';
import { GetAllAssessmentTypeQuery } from '@modules/components/graphql';
import { MoreVert } from '@material-ui/icons';

const columns: TableColumn[] = [
    // {
    //     id: 'name',
    //     label: 'Name',
    // },
    {
        id: 'assessmentTypeName',
        label: 'Assessment Type Name',
    },
];
const ThreeDots = styled(IconButton)`
    position: absolute;
    right: 5px;
    top: 6px;
    z-index: 999;
    svg {
        font-size: 25px;
    }
`;
const TableCellTextStyle = styled(TableCellText)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
    font-weight: 600;
    svg {
        font-size: 28px;
        color: #757575;
        /* color: #eff6fc; */
    }
`;

const Root = styled.div`
    width: 100%;
    border-bottom: 1px solid #d3cece;
    margin-top: 20px;
    position: relative;
`;
const DeleteSpan = styled.div`
    color: crimson;
`;

const List = styled.div`
    width: 100%;
    padding: 16px;

    > * {
        border-bottom: 1px solid ${gray[60]};

        &:first-child {
            border-top: 1px solid ${gray[60]};
        }
    }
`;

const ComponentsPage = (props: Page): React.ReactElement => {
    const { title = 'Assessment Category' } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const { currentUser, loading: userLoading } = useCurrentUser();
    const [componentSearch, setComponentSearch] = useState<string>('');
    const [componentFilterData, setComponentFilterData] = useState<any[]>([]);

    const [openEditModal, setOpenEditModal] = useState<Boolean | any>(false);
    const [openItemDetailsModal, setOpenItemDetailsModal] = useState<Boolean | any>(false);
    const [categoryEditId, setCategoryEditId] = useState<number | any>(null);
    const [selectedId, setSelectedId] = useState<number | any>(null);

    const componentActionStatus = true;

    const handleOpenEditModal = () => setOpenEditModal(true);
    const handleCloseEditModal = () => setOpenEditModal(false);

    const handleEditCategory = (id: number) => {
        setCategoryEditId(id);
        handleOpenEditModal();
    };

    const { allComponents, loading: allAssessmentLoading } = useAllAssessmentType();
    
    const [deleteAssessmentCategories, { loading: deleteCategoryLoading }] = useMutation<
        DeleteAssessmentCategoriesMutationType,
        DeleteAssessmentCategoriesMutationVariables
    >(DeleteAssessmentCategoriesMutation);

    const handleDeleteCategory = async (id: number): Promise<void> => {
        setSelectedId(id);
        try {
            const { data: deleteCategoryData } = await deleteAssessmentCategories({
                variables: { id: id },
                refetchQueries: [{ query: GetAllAssessmentTypeQuery }],
                awaitRefetchQueries: true,
            });

            if (deleteCategoryData?.deleteAssessmentCategories) {
                enqueueSuccess('Category successfully deleted!');
            } else {
                enqueueError('An error occurred while deleting the category!');
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const filterComponents = allComponents?.filter((ele: any) => {
            if (typeof ele?.name === 'string') {
                return ele?.name.toLowerCase().includes(componentSearch.toLocaleLowerCase());
            }
            return false;
        });
        setComponentFilterData(filterComponents);
    }, [componentSearch, allComponents]);

    if (allAssessmentLoading || userLoading) {
        return <Loader />;
    }

    return (
        <>
            {currentUser.hasSuperAdminPermission() ?
                <PageStyled.Header>
                    <PageStyled.HeaderTitle>
                        <h3>Category</h3>
                    </PageStyled.HeaderTitle>
                    <PageStyled.HeaderTitle style={{ marginLeft: '20px' }}>
                        <a
                            href='/itemDetails'
                        >
                            <b style={{ fontSize: '2.4rem', fontWeight: 700, color: '#0057ff' }}><u>Item Details</u></b>
                        </a>
                    </PageStyled.HeaderTitle>
                </PageStyled.Header>
                :
                <PageStyled.Header>
                    <PageStyled.HeaderTitle>
                        <h3>Category</h3>
                    </PageStyled.HeaderTitle>
                </PageStyled.Header>
            }
            <PageStyled.Bridge>
                <CustomComponentsListToolbar
                    setComponentSearch={setComponentSearch}
                    componentSearch={componentSearch}
                    ButtonTitleName={'Create New Category'}
                />
            </PageStyled.Bridge>
            <Root>
                {componentFilterData?.map(ele => {
                    const canEditCategory = ele.isPredefined()
                        ? currentUser.hasSuperAdminPermission() || currentUser.hasAdminPermission()
                        : true;
                    return (
                        <React.Fragment key={ele.id}>
                            <AccordionListItem
                                title={
                                    ele.id === selectedId ? (
                                        deleteCategoryLoading ? (
                                            <DeleteSpan>Deleting...</DeleteSpan>
                                        ) : (
                                            ele.name
                                        )
                                    ) : (
                                        ele.name
                                    )
                                }
                                componentActionStatus={componentActionStatus}
                                subtitle=''
                                componentSearch={componentSearch}
                                SummaryInfoComponents={
                                    <>
                                        {canEditCategory ? (
                                            <CategoryActions
                                                id={ele.id}
                                                onDelete={handleDeleteCategory}
                                                onUpdate={handleEditCategory}
                                            />
                                        ) : (
                                            <MoreVert style={{ fontSize: '24px', color: 'gray' }} />
                                        )}
                                    </>
                                }
                            >
                                <AssessmentTypeList assessmentDetails={ele?.assessmentDetails} />
                            </AccordionListItem>
                        </React.Fragment>
                    );
                })}

                <CustomCategoryEditModal
                    type='edit'
                    open={openEditModal}
                    categoryId={categoryEditId}
                    onClose={handleCloseEditModal}
                />
            </Root>

            {/* <Table columns={columns}>
                    {assessmentName.map((assessmentTypeName, id) => (
                        <React.Fragment key={id}>
                            <TableRow size='m' onClick={() => toggleAccordion(assessmentTypeName)}>
                                <TableCell>
                                    <TableCellTextStyle>
                                        {assessmentTypeName}
                                        {activeIndex === assessmentTypeName ? (
                                            <ExpandMoreIcon />
                                        ) : (
                                            <KeyboardArrowLeftIcon />
                                        )}
                                    </TableCellTextStyle>
                                </TableCell>
                            </TableRow> */}
            {/* {activeIndex === assessmentTypeName && (
                                <div>
                                    {componentFilterData
                                        .filter(
                                            component =>
                                                component?.assessmentTypeName ===
                                                assessmentTypeName,
                                        )
                                        .map((component, componentId) => (
                                            <ComponentEditPage
                                                key={componentId}
                                                componentId={component?.id}
                                                AssessmentTypeName={component}
                                            />
                                        ))}
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </Table> */}
        </>
    );
};

export { ComponentsPage };

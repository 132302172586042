import { gql } from '@apollo/client';

import { AddAssessmentTypeFragment } from '../fragments';

export const GetAssessmentTypeByIdQuery = gql`
    query GetAssessmentType($id: Int!) {
        getAssessmentType(id: $id) {
            ...AssessmentType
        }
    }
    ${AddAssessmentTypeFragment}
`;

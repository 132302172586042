import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetAssessmentCategoriesByIdQueryType,
    GetAssessmentCategoriesByIdVariables,
} from '@modules/types/graphql';
import { GetAssessmentCategoriesByIdQuery } from '../graphql/queries';
import { ListAssessmentCategoriesEntity } from '../entities';

const useGetAssessmentCategoryById = (
    options?: QueryHookOptions<
        GetAssessmentCategoriesByIdQueryType,
        GetAssessmentCategoriesByIdVariables
    >,
) => {
    const queryResult = useQuery<
        GetAssessmentCategoriesByIdQueryType,
        GetAssessmentCategoriesByIdVariables
    >(GetAssessmentCategoriesByIdQuery, {
        ...options,
    });

    const getAsseessmentCategoryById = useEntity(
        () => new ListAssessmentCategoriesEntity(queryResult.data?.getAssessmentCategories ?? null),
        [queryResult.data],
    );

    const result = {
        getAsseessmentCategoryById,
        ...queryResult,
    };

    return result;
};

export { useGetAssessmentCategoryById };

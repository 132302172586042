import { gql } from '@apollo/client';

export const GetImpactLevelsQuery = gql`
    query getImpactLevels {
        getImpactLevels {
            id
            name
        }
    }
`;

import { EntityList } from '@lib/entity';
import { ItemTypeEntity } from './item-type-entity';

import type { Entity } from './item-type-entity';


class ItemTypeEntityList extends EntityList<ItemTypeEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(ItemTypeEntity, items, count);
    }
}

export { ItemTypeEntityList };

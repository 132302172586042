import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, Modal, TextField } from '@modules/ui/core';
import type { ModalProps } from '@modules/ui/core';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { Radio, RadioGroup, FormControlLabel, FormLabel, FormControl, Grid, MenuItem, Menu } from '@material-ui/core';
import { Asset, DeleteAssetMutationType, DeleteAssetMutationVariables } from '@modules/types/graphql';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { useMutation } from '@apollo/client';
import { CreateAsset, UpdateAsset } from '@modules/registry/graphql';
import { useImpactLevels } from '@modules/shared/hooks';
import { tolerances } from '@modules/components/constants';
import { useAssetCategory, useAssetType } from '@modules/registry/hooks';
import { Field } from 'formik';
import { AssetTypeEntity } from '@modules/registry/entities';
import { DeleteAssetMutation } from '@modules/custom-components/graphql';
import { AssetListItemActions } from '@modules/projects/moleculas/asset-list-item-actions';
import { MoreVert } from '@material-ui/icons';
import { AuthContext } from '@modules/auth/context';
import { useUserHighRole } from '@modules/assessment/hooks/use-user-high-role';
import { AlignedScoreLabel, AssetRiskLabel } from '../asset-score-label';
import { gray } from '@modules/ui/colors';

const CustomField = styled.input`
    color: black;
    border: 1px solid #8c8c8c61;
    background: #eaeaea;
    height: 48px;
    padding: 6px 10px;
    border-radius: 5px;
    width:100%;
    font-size: 15px;
    font-weight: 500;
`;
const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-input': {
        color: '#000 !important'
    },
    '& svg': {
        display: 'none'
    }
}));
const CustomHeading = styled.h2`
    background: #2f4f6f;
    text-align: center;
    padding: 8px 10px;
    color: white;
    border-radius: 6px 6px 0 0;
`;
const StyledModal = styled(Modal)`
    min-width: 1024px;
`;

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const FieldDiv = styled.div`
    flex: 5;
    margin-bottom: 5px !important;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  th, td {
    border: 1px solid #d1d2d2;
    padding: 8px;
    text-align: left;
  }
  th {
    background-color: #2f4f6f;
    color: white;
  }

    .MuiSelect-selectMenu{
        white-space: wrap;
    }
`;

const ActionButton = styled(Button)`
    min-width: 60px;
    height:32px;
    font-size: 12px;
    line-height:12px;
    svg {
        font-size: 2rem;
        bottom: -5px;
        position: relative;
    }
`;

const Empty = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    min-height: 56px;
    padding: 12px 0;

    > p {
        color: ${gray[20]};
        font-size: 1.4rem;
        font-weight: 400;
    }
`;

type RowType = {
    riskScore: number | any;
    item_id: number;
    dataType: string;
    name: string;
    category_id: string | number;
    type_id: string | number;
};

const defaultInitialValues = {
    id: '',
    name: '',
    incidents_creation_process: '',
    incidents_storage_retention_and_retrieval: '',
    incidents_usage_and_processing: '',
    incidents_is_classified_or_labeled: 1,
    incidents_notes: '',
    risk_appetite_desc: '',
    risk_appetite_level: null,
    risk_tolerance_desc: '',
    risk_tolerance_level: null,
    risk_capacity_desc: '',
    risk_capacity_level: null,
    unacceptable_risk_desc: '',
    unacceptable_risk_level: null,
    owner: null,
    pm: null,
    stakeholders: [],
    itemType: null,
    itemClass: null,
    riskGroup: null,
    riskFunction: null,
};

const AssetManagementModal = (
    props: ModalProps & { activeAsset: null | Asset, data: null | Asset[], setActiveRow: (id: any) => void; },
): React.ReactElement => {
    const { onClose, activeAsset, data, refetchQuery, setActiveRow, ...otherProps } = props;
    const [initialValues, setInitialValues] = useState(defaultInitialValues);
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const { assetCategory, loading: assetCategoryLoading } = useAssetCategory();
    console.log(assetCategory, 'assetCategoru')
    const { assetTypeData, loading: assetTypeLoading } = useAssetType();
    const [itemTypeData, setItemTypeData] = useState<number | any>(0);
    const { ImpactLevels, loading: isImpactLoading } = useImpactLevels();
    const [riskLevelId, setRiskLevelId] = useState<number>(0);
    const [riskToleranceId, setRiskToleranceId] = useState<number>(-1);
    const [riskCapacityCal, setRiskCapacityCal] = useState<number | any>(0);
    const [assetPrevTypeDetails, setAssetPrevTypeDetails] = useState<{ [key: number]: AssetTypeEntity[] }>({});
    const [unacceptableRiskCal, setUnacceptableRiskCal] = useState<number | any>(0);
    const [rows, setRows] = useState<RowType[]>([]);
    const addRow = () => {
        setRows([...rows, { riskScore: '', item_id: 0, dataType: 'new', name: '', category_id: '', type_id: '' }]);
    };

    const { user, loading: userLoading } = React.useContext(AuthContext) || {};

    const { userHighRolesEntityList, loading: userRoleLoading } = useUserHighRole();
    const userHighRole: string = userHighRolesEntityList[0]?.welcomeRole;

    const [deleteAsset, { loading: deleteAssetLoading }] = useMutation<
        DeleteAssetMutationType,
        DeleteAssetMutationVariables
    >(DeleteAssetMutation);

    const [createAsset] = useMutation(CreateAsset);
    const [updateAsset] = useMutation(UpdateAsset);

    const handleDeleteAssetRow = async (assetId: any): Promise<void> => {
        try {
            const { data: deleteAssetData } = await deleteAsset({
                variables: { assetId: assetId },
                refetchQueries: [{ query: refetchQuery }],
                awaitRefetchQueries: true,
            });

            if (deleteAssetData?.deleteAsset) {
                enqueueSuccess('Asset successfully deleted!');
                handleClose();
            } else {
                enqueueError('An error occurred while deleting the asset!');
            }
        } catch (e) {
            console.log(e);
        }
    };

    const isRMAndPMRole = (role: string) => {
        return (role === 'RM' || role === 'RA' || user?.roles[0] === 'risk_admin' || user?.roles[0] === 'risk_manager') ? true : false;
    };

    useEffect(() => {
        if (!activeAsset) {
            return;
        }
        const values: any = { ...activeAsset };
        delete values.__typename;
        values.owner = activeAsset?.owner?.id;
        values.pm = activeAsset?.pm?.id;
        values.stakeholders = activeAsset?.stakeholders?.map((ele: any) => ele.id);
        values.itemType = activeAsset?.itemType?.id;
        values.itemClass = activeAsset?.itemClass?.id;
        values.riskGroup = activeAsset?.riskGroup?.id;
        values.riskFunction = activeAsset?.riskFunction?.id;
        values.risk_capacity_level = activeAsset?.risk_capacity_level && activeAsset?.risk_capacity_level >= 10 ? 10 : activeAsset?.risk_capacity_level;
        values.unacceptable_risk_level = activeAsset?.unacceptable_risk_level && activeAsset?.unacceptable_risk_level >= 10 ? 10 : activeAsset?.unacceptable_risk_level;
        let datas: Asset[] | any = null;
        const alignedAssetIds = values.alignedAssets.id;
        if (alignedAssetIds !== null) {
            datas = data?.filter((ele: any) => alignedAssetIds.includes(ele.id)) || [];
        }
        const prevData: RowType[] = [];
        const newAssetTypeDetails: { [key: number]: AssetTypeEntity[] } = {};
        if (datas !== null) {
            datas?.forEach((data: any, index: any) => {
                data.alignment_type === 'alignment' ?
                    data.itemType.id === 1 ? setItemTypeData(2) : setItemTypeData(1) : setItemTypeData(data.itemType.id);
                const assetTypeDataValue: AssetTypeEntity[] = assetTypeData.filter(asset => asset.categoryId === data.assetType?.category_id);
                prevData.push({ riskScore: data.riskScore, item_id: data?.id, dataType: 'prev', name: data.name, category_id: data.assetCategory.id, type_id: data.assetType.id });
                newAssetTypeDetails[index] = assetTypeDataValue;
            })
        } else {
            activeAsset?.itemType?.id === 1 ? setItemTypeData(2) : setItemTypeData(1);
        }
        setRows(prevData);
        setAssetPrevTypeDetails(newAssetTypeDetails);
        setInitialValues(values);
        setRiskCapacityCal(activeAsset?.risk_capacity_level);
        setUnacceptableRiskCal(activeAsset?.unacceptable_risk_level);
    }, [activeAsset]);

    useEffect(() => {
        const unacceptable = riskCapacityCal + 1;
        setUnacceptableRiskCal(unacceptable);
    }, [riskCapacityCal]);

    useEffect(() => {
        setRiskCapacityCal(0);
        setUnacceptableRiskCal(0);
    }, [onClose]);

    const handleAssetsModal = (rowId: number) => {
        const rowIndex = data?.findIndex((ele: any) => ele.id === rowId);
        setActiveRow(rowIndex);
        onClose();
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <StyledModal title={'"' + activeAsset?.name + '" Management Card'} onClose={onClose} {...otherProps}>
            <ExtendedFormik
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={initialValues}
                onSubmit={async values => {
                    const {
                        id,
                        incidents_creation_process,
                        incidents_storage_retention_and_retrieval,
                        incidents_usage_and_processing,
                        incidents_is_classified_or_labeled,
                        incidents_notes,
                        risk_appetite_desc,
                        risk_appetite_level,
                        risk_tolerance_desc,
                        risk_tolerance_level,
                        risk_capacity_desc,
                        unacceptable_risk_desc,
                    } = values;
                    console.log(values, 'values')
                    try {
                        const { data: updateAssetData } = await updateAsset({
                            variables: {
                                input: {
                                    id,
                                    incidents_creation_process,
                                    incidents_storage_retention_and_retrieval,
                                    incidents_usage_and_processing,
                                    incidents_is_classified_or_labeled: Boolean(
                                        incidents_is_classified_or_labeled,
                                    ),
                                    incidents_notes,
                                    risk_appetite_desc,
                                    risk_appetite_level,
                                    risk_tolerance_desc,
                                    risk_tolerance_level,
                                    risk_capacity_desc,
                                    risk_capacity_level: riskCapacityCal,
                                    unacceptable_risk_desc,
                                    unacceptable_risk_level: unacceptableRiskCal,
                                },
                            },
                            refetchQueries: [{ query: refetchQuery }],
                            awaitRefetchQueries: true,
                        });

                        if (updateAssetData) {
                            enqueueSuccess('Asset successfully updated!');
                            onClose();
                        } else {
                            enqueueError('An error occurred while updating asset!');
                            onClose();
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }}
            >
                {formikProps => {
                    const {
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        setFieldValue,
                    } = formikProps;

                    const handleSave = async (index: number) => {
                        try {
                            const row = rows[index];
                            const itemTypeDetails: any = assetTypeData.filter(item => item.id === row.type_id).map(ele => ele.name);
                            const { data: createAssetData } = await createAsset({
                                variables: {
                                    name: row.name + ' - ' + itemTypeDetails,
                                    owner: values.owner,
                                    pm: values.pm,
                                    sh: values.stakeholders,
                                    alignmentType: 'alignment',
                                    itemType: values.itemType,
                                    itemClass: values.itemClass,
                                    riskGroup: values.riskGroup,
                                    riskFunction: values.riskFunction,
                                    assetCategory: row.category_id,
                                    assetType: row.type_id,
                                    prevItemId: values.id
                                },
                                // refetchQueries: [{ query: refetchQuery }],
                                // awaitRefetchQueries: true,
                            });

                            if (createAssetData) {
                                enqueueSuccess(createAssetData?.createAsset?.name + ' Asset Alignment successfully created!');
                            } else {
                                enqueueError('An error occurred while creating Asset Alignment!');
                            }

                        } catch (e) {
                            console.log(e);
                        }
                    };

                    const handleRiskAppetiteChange = (event: any) => {
                        handleChange(event);
                        setRiskLevelId(event.target.value);
                        if (riskToleranceId >= 0) {
                            setRiskCapacityCal(event.target.value + riskToleranceId);
                        }
                    }

                    const handleRiskToleranceChange = (event: any) => {
                        handleChange(event);
                        if (event.target.value >= 0) {
                            setRiskToleranceId(event.target.value);
                            setRiskCapacityCal(event.target.value + riskLevelId);
                        }
                    }

                    const handleNameChange = (index: number, value: string) => {
                        const newRows = [...rows];
                        newRows[index] = { ...newRows[index], name: value, dataType: 'new' };
                        setRows(newRows);
                    };

                    const handleCategoryChange = (index: number, value: string) => {
                        const newRows = [...rows];
                        newRows[index] = { ...newRows[index], category_id: Number(value), type_id: '' };
                        const assetTypeDataValue: AssetTypeEntity[] = assetTypeData.filter(asset => asset.categoryId === Number(value));
                        setAssetPrevTypeDetails({ ...assetPrevTypeDetails, [index]: assetTypeDataValue });
                        setRows(newRows);
                    };

                    const handleTypeChange = (index: number, value: (string | number)) => {
                        const newRows = [...rows];
                        newRows[index] = { ...newRows[index], type_id: value };
                        setRows(newRows);
                    };

                    return (
                        <StyledForm>
                            <FieldDiv style={{ border: " 1px solid #ededed", padding: "10px" }}>
                                <CustomHeading style={{ marginBottom: "10px", borderRadius: 0, marginTop: "-10px", marginLeft: "-10px", marginRight: "-10px" }}>Risk Posture​ </CustomHeading>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <CustomField
                                            id='riskAppetite'
                                            value={'Risk Appetite'}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            fullWidth
                                            id='risk_appetite_desc'
                                            label='Description'
                                            placeholder='Add Description Here..'
                                            value={values.risk_appetite_desc}
                                            error={!!errors.risk_appetite_desc}
                                            helperText={errors.risk_appetite_desc}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            select
                                            fullWidth
                                            id='risk_appetite_level'
                                            name='risk_appetite_level'
                                            label='Risk Level'
                                            error={!!errors.risk_appetite_level}
                                            helperText={errors.risk_appetite_level}
                                            value={values?.risk_appetite_level}
                                            disabled={isImpactLoading}
                                            onChange={handleRiskAppetiteChange}
                                        >
                                            {ImpactLevels ? (
                                                ImpactLevels.map(impact => {
                                                    const { id, name } = impact;
                                                    return (
                                                        <MenuItem key={id} value={id}>
                                                            {name}
                                                        </MenuItem>
                                                    );
                                                })
                                            ) : (
                                                <MenuItem key={0} value={''}>
                                                    Select Impact
                                                </MenuItem>
                                            )}
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <CustomField
                                            id='riskTolerance'
                                            value={'Risk Tolerance'}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            fullWidth
                                            id='risk_tolerance_desc'
                                            label='Description'
                                            placeholder='Add Description Here..'
                                            value={values.risk_tolerance_desc}
                                            error={!!errors.risk_tolerance_desc}
                                            helperText={errors.risk_tolerance_desc}
                                            onChange={handleChange}
                                            onBlur={handleBlur} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            select
                                            fullWidth
                                            id='risk_tolerance_level'
                                            name='risk_tolerance_level'
                                            label='Tolerance Level'
                                            error={!!errors.risk_tolerance_level}
                                            helperText={errors.risk_tolerance_level}
                                            value={values?.risk_tolerance_level}
                                            onChange={handleRiskToleranceChange}
                                        >
                                            {tolerances.map(tolerance => (
                                                <MenuItem key={tolerance} value={tolerance}>
                                                    {tolerance}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <CustomField
                                            id='riskCapacity'
                                            value={'Risk Capacity'}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            fullWidth
                                            id='risk_capacity_desc'
                                            label='Description'
                                            placeholder='Add Description Here..'
                                            value={values.risk_capacity_desc}
                                            error={!!errors.risk_capacity_desc}
                                            helperText={errors.risk_capacity_desc}
                                            onChange={handleChange}
                                            onBlur={handleBlur} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CustomTextField
                                            select
                                            fullWidth
                                            id='risk_capacity_level'
                                            name='risk_capacity_level'
                                            value={riskCapacityCal === 0 ? values?.risk_capacity_level : riskCapacityCal >= 10 ? 10 : riskCapacityCal}
                                            error={!!errors.risk_capacity_level}
                                            helperText={errors.risk_capacity_level}
                                            disabled
                                            onChange={handleChange}
                                        >
                                            {ImpactLevels ? (
                                                ImpactLevels.map(impact => {
                                                    const { id, name } = impact;
                                                    return (
                                                        <MenuItem key={id} value={id}>
                                                            {name}
                                                        </MenuItem>
                                                    );
                                                })
                                            ) : (
                                                <MenuItem key={0} value={''}>
                                                    Select Impact
                                                </MenuItem>
                                            )}
                                        </CustomTextField>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <CustomField
                                            id='unacceptableRisk​'
                                            value={'Unacceptable Risk'}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            fullWidth
                                            id='unacceptable_risk_desc'
                                            label='Description'
                                            placeholder='Add Description Here..'
                                            value={values.unacceptable_risk_desc}
                                            error={!!errors.unacceptable_risk_desc}
                                            helperText={errors.unacceptable_risk_desc}
                                            onChange={handleChange}
                                            onBlur={handleBlur} />

                                    </Grid>
                                    <Grid item xs={4}>
                                        <CustomTextField
                                            select
                                            fullWidth
                                            id='unacceptable_risk_level'
                                            name='unacceptable_risk_level'
                                            value={unacceptableRiskCal === 0 ? values?.unacceptable_risk_level : unacceptableRiskCal >= 10 ? 10 : unacceptableRiskCal}
                                            error={!!errors.unacceptable_risk_level}
                                            helperText={errors.unacceptable_risk_level}
                                            disabled
                                            onChange={handleChange}
                                        >
                                            {ImpactLevels ? (
                                                ImpactLevels.map(impact => {
                                                    const { id, name } = impact;
                                                    return (
                                                        <MenuItem key={id} value={id}>
                                                            {name}
                                                        </MenuItem>
                                                    );
                                                })
                                            ) : (
                                                <MenuItem key={0} value={''}>
                                                    Select Impact
                                                </MenuItem>
                                            )}
                                        </CustomTextField>
                                    </Grid>
                                </Grid>
                            </FieldDiv>

                            <FieldDiv style={{ marginTop: '20px' }}>
                                <CustomHeading>Aligned {activeAsset?.alignment_type === 'item' ? 'Risks' : 'Assets'}</CustomHeading>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '160px', fontSize: "14px", textTransform: "capitalize", fontWeight: 600, background: "#eaeaea", color: "black" }}>name</th>
                                                    <th style={{ fontSize: "14px", textTransform: "capitalize", fontWeight: 600, background: "#eaeaea", color: "black" }}>Category</th>
                                                    <th style={{ fontSize: "14px", textTransform: "capitalize", fontWeight: 600, background: "#eaeaea", color: "black" }}>Type</th>
                                                    <th style={{ width: '120px', fontSize: "14px", textTransform: "capitalize", fontWeight: 600, background: "#eaeaea", color: "black" }}>Risk Rating​</th>
                                                    <th style={{ width: '120px', fontSize: "14px", textTransform: "capitalize", fontWeight: 600, background: "#eaeaea", color: "black" }}>Risk Posture</th>
                                                    {activeAsset?.alignment_type === 'item' &&
                                                        <>
                                                            <th style={{ width: '90px', fontSize: "14px", textTransform: "capitalize", fontWeight: 600, background: "#eaeaea", color: "black" }}>Add to RR</th>
                                                            <th style={{ width: '70px', fontSize: "14px", textTransform: "capitalize", fontWeight: 600, background: "#eaeaea", color: "black" }}>Action</th>
                                                        </>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rows.length > 0 ? (
                                                    rows.map((row, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                {row.dataType === 'new' ?
                                                                    <TextField
                                                                        required
                                                                        fullWidth
                                                                        id={`name-${index}`}
                                                                        label='Item name'
                                                                        placeholder='Item name'
                                                                        value={row.name}
                                                                        error={false}
                                                                        helperText={''}
                                                                        onChange={(e) => handleNameChange(index, e.target.value)}
                                                                    />
                                                                    :
                                                                    <h3 style={{ cursor: 'pointer' }} onClick={() => handleAssetsModal(row.item_id)}><u>{row.name}</u></h3>
                                                                }
                                                            </td>
                                                            <td>
                                                                <Field
                                                                    as={TextField}
                                                                    select
                                                                    fullWidth
                                                                    name={`rows[${index}].category`}
                                                                    label={'Asset Category'}
                                                                    value={row.category_id}
                                                                    onChange={(e: any) => {
                                                                        handleChange(e);
                                                                        handleCategoryChange(index, e.target.value);
                                                                    }}
                                                                >
                                                                    {assetCategory &&
                                                                        assetCategory
                                                                            .filter(risk => risk.itemId === itemTypeData)
                                                                            .map((item: { id: number; name: string }) => (
                                                                                <MenuItem key={item.id} value={item.id}>
                                                                                    {item.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                </Field>
                                                            </td>
                                                            <td>
                                                                <Field
                                                                    as={TextField}
                                                                    select
                                                                    fullWidth
                                                                    name={`rows[${index}].type`}
                                                                    label={'Item Type'}
                                                                    value={row.type_id}
                                                                    onChange={(e: any) => {
                                                                        handleChange(e);
                                                                        handleTypeChange(index, e.target.value);
                                                                    }}
                                                                >
                                                                    {assetPrevTypeDetails[index] &&
                                                                        assetPrevTypeDetails[index].map((item: { id: number; name: string }) => (
                                                                            <MenuItem key={item.id} value={item.id}>
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                </Field>
                                                            </td>
                                                            <td>
                                                                {!row.riskScore ? (
                                                                    <div className='is-status' style={{ textAlign: 'center' }}>Pending</div>
                                                                ) : (
                                                                    <>
                                                                        <div className='is-status-donut'>
                                                                            <AlignedScoreLabel score={row.riskScore} type='risk' />
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {!row.riskScore ? (
                                                                    <div className='is-status' style={{ textAlign: 'center' }}>Pending</div>
                                                                ) : (
                                                                    <>
                                                                        <div className='is-status-donut'>
                                                                            <AssetRiskLabel score={row.riskScore} type='riskAligned' />
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </td>
                                                            {activeAsset?.alignment_type === 'item' &&
                                                                <>
                                                                    <td style={{ textAlign: 'center' }}>
                                                                        <ActionButton type="button" onClick={() => handleSave(index)}>Add</ActionButton>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            {isRMAndPMRole(userHighRole) ? (
                                                                                <AssetListItemActions onDelete={handleDeleteAssetRow} assetId={row.item_id} />
                                                                            ) : (
                                                                                <MoreVert />
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                </>
                                                            }
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <Empty>
                                                        <p>Aligned Item not found</p>
                                                    </Empty>
                                                )
                                                }
                                            </tbody>
                                        </Table>
                                        {activeAsset?.alignment_type === 'item' &&
                                            <Button type="button" onClick={addRow} style={{ marginTop: 15 }}>
                                                + Category
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </FieldDiv>
                            <FieldDiv style={{ marginTop: "20px", border: " 1px solid #ededed", padding: "10px" }}>
                                <CustomHeading style={{ marginBottom: "10px", borderRadius: 0, marginTop: "-10px", marginLeft: "-10px", marginRight: "-10px" }}>{activeAsset?.alignment_type === 'item' ? 'Asset' : 'Risk'} Attributes</CustomHeading>
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id='incidents_creation_process'
                                                label={activeAsset?.alignment_type === 'item' ? 'Creation Process' : 'Unwanted events'}
                                                value={values.incidents_creation_process}
                                                error={!!errors.incidents_creation_process}
                                                helperText={errors.incidents_creation_process}
                                                onChange={handleChange}
                                                onBlur={handleBlur} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id='incidents_storage_retention_and_retrieval'
                                                label={activeAsset?.alignment_type === 'item' ? 'Storage, Retention, and Retrieval' : 'Current controls'}
                                                value={values.incidents_storage_retention_and_retrieval}
                                                error={!!errors.incidents_storage_retention_and_retrieval}
                                                helperText={errors.incidents_storage_retention_and_retrieval}
                                                onChange={handleChange}
                                                onBlur={handleBlur} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id='incidents_usage_and_processing'
                                                label={activeAsset?.alignment_type === 'item' ? 'Usage and Processing' : 'Control gaps'}
                                                value={values.incidents_usage_and_processing}
                                                error={!!errors.incidents_usage_and_processing}
                                                helperText={errors.incidents_usage_and_processing}
                                                onChange={handleChange}
                                                onBlur={handleBlur} />
                                        </Grid>
                                    </Grid>
                                    {activeAsset?.alignment_type === 'item' &&
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <FormControl style={{ flexDirection: "row", alignItems: "Center", gap: "20px" }}>
                                                    <FormLabel style={{ fontSize: "12px", color: "black" }}>Classified/Labeled</FormLabel>
                                                    <RadioGroup
                                                        style={{ flexDirection: "row", gap: "12px" }}
                                                        id='incidents_is_classified_or_labeled'
                                                        name='incidents_is_classified_or_labeled'
                                                        value={Number(values.incidents_is_classified_or_labeled)}
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'incidents_is_classified_or_labeled',
                                                                Number(e.target.value)
                                                            );
                                                        }}
                                                    >
                                                        <FormControlLabel value={1} control={<Radio />} label='Yes' />
                                                        <FormControlLabel value={0} control={<Radio />} label='No' />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                id='incidents_notes'
                                                label='Notes'
                                                value={values.incidents_notes}
                                                error={!!errors.incidents_notes}
                                                helperText={errors.incidents_notes}
                                                onChange={handleChange}
                                                onBlur={handleBlur} />
                                        </Grid>
                                    </Grid>
                                </>
                            </FieldDiv>
                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    Save
                                </SubmitButton>

                                <SubmitButton
                                    variant='text'
                                    disabled={isSubmitting}
                                    onClick={onClose}
                                >
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    );
                }
                }
            </ExtendedFormik>
        </StyledModal>
    );
};

export { AssetManagementModal };

import { gql } from '@apollo/client';

export const AddConstraintsMutation = gql`
    mutation AddConstraints($roadmapRecommendationId: Int!, $constraints: String) {
        addConstraints(
            roadmap_recommendation_id: $roadmapRecommendationId
            constraints: $constraints
        ) {
            status
        }
    }
`;

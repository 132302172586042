import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Button, Modal, TextField, Autocomplete } from '@modules/ui/core';
import type { ModalProps } from '@modules/ui/core';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { Grid, MenuItem, Select, Checkbox, ListItemText, InputAdornment } from '@material-ui/core';
import { gray } from '@modules/ui/colors';
import { Asset } from '@modules/types/graphql';
import { useUsers } from '@modules/users/hooks';
import { useMutation } from '@apollo/client';
import { UpdateAsset, CreateAsset } from '@modules/registry/graphql';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { useAssetCategory, useAssetType, useItemClass, useItemType, useRiskFunction, useGetRiskGroup, usePrevTypeDetails } from '@modules/registry/hooks';
import { AssetTypeEntity } from '@modules/registry/entities';
import { Field } from 'formik';
import {
    searchIcon,
    useBackgroundStyles,
} from '@modules/projects/moleculas/project-list-filters/input-box-css';

const CustomHeading = styled.h2`
    background: #2f4f6f;
    padding: 8px 10px;
    color: white;
    margin: -10px -10px 10px;
`;
const StyledModal = styled(Modal)`
    // max-width: 800px;
    min-width: 1024px;
`;

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
    
    // label {
    //     color: #20262e;
    //     font-size: 20px;
    //     font-weight: 500;
    // }

    .is-select {
        width: 360px;
        background-color: ${gray[90]};
        margin: 5px;

        .MuiInputBase-root > div {
            display: initial;
        }
    }

    .MuiFormControl-fullWidth {
        margin: 10px;
        padding-right: 18px;
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const FieldDiv = styled.div`
    flex: 5;
    margin-bottom: 5px !important;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  th, td {
    border: 1px solid #d1d2d2;
    padding: 8px;
    text-align: left;
  }
  th {
    background-color: #2f4f6f;
    color: white;
  }

    .MuiSelect-selectMenu{
        white-space: wrap;
    }
`;

const ActionButton = styled(Button)`
    min-width: 60px;
    height:32px;
    svg {
        font-size: 2rem;
        bottom: -5px;
        position: relative;
    }
`;

type RowType = {
    category_id: string | number;
    type_ids: (string | number)[];
};

type RiskName = {
    category_id: string | number;
    name: (string | number)[];
}

interface DefaultInitialValues {
    id?: string;
    __typename?: string;
    name?: string;
    owner?: null | number;
    pm?: null | number;
    stakeholders?: number[] | [];
    itemType?: null | number;
    itemClass?: null | number;
    riskGroup?: null | number;
    riskFunction?: null | number;
    assetCategory?: null | number;
    assetType?: null | number;
    inputData?: RowType[] | [];
    notes?: string;
}

const defaultInitialValues: DefaultInitialValues = {
    name: '',
    owner: null,
    pm: null,
    stakeholders: [],
    itemType: null,
    itemClass: null,
    riskGroup: null,
    riskFunction: null,
    assetCategory: null,
    assetType: null,
    inputData: [],
    notes: '',
};

const EditAssetModal = (
    props: ModalProps & { type: 'edit' | 'new'; activeAsset: null | Asset; assetData: null | Asset[] },
): React.ReactElement => {
    const { onClose, type, activeAsset, refetchQuery, assetData, ...otherProps } = props;
    const { users, loading: usersLoading } = useUsers();
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [initialValues, setInitialValues] = useState<Asset | DefaultInitialValues>(
        defaultInitialValues,
    );
    const { itemType, loading: itemTypeLoading } = useItemType();
    const { itemClass, loading: itemClassLoading } = useItemClass();
    const { riskData, loading: riskDataLoading } = useGetRiskGroup();
    const { riskFunction, loading: riskFunctionLoading } = useRiskFunction();
    const { assetCategory, loading: assetCategoryLoading } = useAssetCategory();
    const { assetTypeData, loading: assetTypeLoading } = useAssetType();
    const { prevType, loading: prevTypeLoading } = usePrevTypeDetails();
    const [riskFunctionData, setRiskFunctionData] = useState<string | any>('');
    const [itemTypeData, setItemTypeData] = useState<number | any>(0);
    const [itemData, setItemData] = useState<number>(0);
    const [itemTypeName, setItemTypeName] = useState<string | any>('');
    const [assetTypeDetails, setAssetTypeDetails] = useState<AssetTypeEntity[]>([]);
    const [alignedAssets, setAlignedAssets] = useState<number[]>([]);
    const [filter, setFilter] = useState<string>('');
    const [assetTypeName, setAssetTypeName] = useState<string>('');
    const [selectedType, setSelectedType] = useState<any>({});
    const [assetPrevTypeDetails, setAssetPrevTypeDetails] = useState<{ [key: number]: AssetTypeEntity[] }>({});
    const [openRiskAlignmentModal, setOpenRiskAlignmentModal] = useState<boolean>(false);
    const [rows, setRows] = useState<RowType[]>([]);
    const addRow = () => {
        setRows([...rows, { category_id: '', type_ids: [] }]);
    };

    useEffect(() => {
        itemTypeData === 1 ?
            setItemData(2) : itemTypeData === 2 ?
                setItemData(1) : setItemData(0)
    }, [itemTypeData])
    useEffect(() => {
        if (!activeAsset) {
            setInitialValues(defaultInitialValues);
            return;
        }
        const values: any = { ...activeAsset };
        values.pm = values.pm?.id || null;
        values.owner = values.owner?.id || null;
        values.stakeholders = values.stakeholders.map((sh: any) => sh?.id);
        values.itemType = values.itemType?.id || null;
        values.itemClass = values.itemClass?.id || null;
        values.riskGroup = values.riskGroup?.id || null;
        values.riskFunction = values.riskFunction?.id || null;
        values.assetCategory = values.assetCategory?.id || null;
        values.assetType = values.assetType?.id || null;
        values.inputData = values.itemAlignment || null;
        delete values.__typename;
        setInitialValues(values);
    }, [activeAsset]);
    console.log(openRiskAlignmentModal, 'openRiskAlignmentModal')
    useEffect(() => {
        setRiskFunctionData(riskFunction.find(risk => risk.id === initialValues?.riskFunction)?.name);
        if (initialValues?.inputData?.length && assetTypeData) {
            activeAsset?.alignment_type === 'alignment' ? initialValues?.itemType === 1 ? setItemTypeData(2) : setItemTypeData(1) : setItemTypeData(initialValues?.itemType);
            setAssetTypeDetails(assetTypeData.filter(asset => asset.categoryId === initialValues?.assetCategory));
            setItemTypeName(itemType.find(item => item.id === initialValues?.itemType)?.name);
            const prevData: RowType[] = [];
            const newAssetTypeDetails: { [key: number]: AssetTypeEntity[] } = {};
            initialValues?.inputData.forEach((data: any, index: any) => {
                const assetTypeDataValue: AssetTypeEntity[] = assetTypeData.filter(asset => asset.categoryId === data?.category_id);
                prevData.push({ category_id: data.category_id, type_ids: data.type_ids });
                newAssetTypeDetails[index] = assetTypeDataValue;
            });
            setRows(prevData);
            setAssetPrevTypeDetails(newAssetTypeDetails);
        } else {
            activeAsset?.alignment_type === 'alignment' ? initialValues?.itemType === 1 ? setItemTypeData(2) : setItemTypeData(1) : setItemTypeData(initialValues?.itemType);
            setAssetTypeDetails(assetTypeData.filter(asset => asset.categoryId === initialValues?.assetCategory));
            setItemTypeName(itemType.find(item => item.id === initialValues?.itemType)?.name);
            const newAssetTypeDetails: { [key: number]: AssetTypeEntity[] } = {};
            const prevData = prevType.filter(ele => ele.itemCategoryTypeId === initialValues?.assetType);
            prevData.forEach((data, index) => {
                if (data.itemCategoryTypeId === initialValues?.assetType) {
                    const assetTypeDataValue: AssetTypeEntity[] = assetTypeData.filter(asset => asset.categoryId === data.categoryId);
                    newAssetTypeDetails[index] = assetTypeDataValue;
                }
            });
            setRows(prevData.map(ele => ({ category_id: ele.categoryId, type_ids: ele.typeIds })));
            setAssetPrevTypeDetails(newAssetTypeDetails);
        }
    }, [initialValues]);

    const [updateAsset] = useMutation(UpdateAsset);
    const [createAsset] = useMutation(CreateAsset);

    useEffect(() => {
        setRiskFunctionData('');
        setItemTypeData(0);
        setOpenRiskAlignmentModal(false);
        setRows([]);
    }, [onClose])

    const getOptionNameLabel = React.useCallback(
        (option: RiskName | null) => option?.name ?? '-',
        [],
    );
    const selectedCategory: any = {};
    rows?.map((ele: any) => (!selectedCategory[ele?.category_id]) ? selectedCategory[ele?.category_id] = ele?.category_id : null);

    useEffect(() => {
        if (assetData && rows) {
            const newSelectedType: any = {};
            let hasChanges = false;

            const updatedRows = rows.map(row => {
                const filteredTypeIds = row.type_ids?.filter(typeId => {
                    let filterStatus = true;
                    assetData.forEach(ele => {
                        if (ele.itemType?.id === 2 && ele.assetType?.id === typeId) {
                            filterStatus = false;
                            if (!newSelectedType[typeId]) {
                                newSelectedType[typeId] = typeId;
                            }
                        }
                    });
                    return filterStatus;
                });

                if (filteredTypeIds.length !== row.type_ids.length) {
                    hasChanges = true;
                }

                return { ...row, type_ids: filteredTypeIds };
            });

            if (hasChanges) {
                setSelectedType(newSelectedType);
                setRows(updatedRows);
            }
        }
    }, [assetData, rows]);

    return (
        <>
            <StyledModal
                title={type === 'edit' ? 'Edit Item' : 'New Item'}
                onClose={onClose}
                {...otherProps}
            >
                <ExtendedFormik
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={initialValues}
                    onSubmit={async (values) => {
                        delete values.__typename;
                        values.inputData = rows;
                        const { name, owner, pm, stakeholders, id, itemType, itemClass, riskGroup, riskFunction, assetCategory, assetType, inputData, notes } = values;
                        try {
                            if (type === 'edit') {
                                const { data: updateAssetData } = await updateAsset({
                                    variables: {
                                        input: {
                                            id,
                                            name,
                                            owner,
                                            pm,
                                            sh: stakeholders,
                                            risk_group_id: riskGroup,
                                            risk_function_id: riskFunction,
                                            asset_category_id: assetCategory,
                                            asset_type_id: assetType,
                                            notes: notes,
                                        },
                                    },
                                    refetchQueries: [{ query: refetchQuery }],
                                    awaitRefetchQueries: true,
                                });

                                if (updateAssetData) {
                                    enqueueSuccess('Asset successfully updated!');
                                    onClose();
                                } else {
                                    enqueueError('An error occurred while updating asset!');
                                    onClose();
                                }
                            }

                            if (type === 'new') {
                                const { data: createAssetData } = await createAsset({
                                    variables: {
                                        name: name + '-' + assetTypeName,
                                        owner,
                                        pm,
                                        sh: stakeholders,
                                        alignmentType: 'item',
                                        itemType,
                                        itemClass,
                                        riskGroup,
                                        riskFunction,
                                        assetCategory,
                                        assetType,
                                        alignedAssets: alignedAssets,
                                        input: inputData
                                    },
                                    refetchQueries: [{ query: refetchQuery }],
                                    awaitRefetchQueries: true,
                                });
                                if (createAssetData) {
                                    enqueueSuccess(itemTypeName + ' successfully created!');
                                    onClose();
                                } else {
                                    enqueueError('An error occurred while creating asset!');
                                    onClose();
                                }
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }}
                >
                    {formikProps => {
                        const { values, errors, handleChange, handleBlur, isSubmitting } = formikProps;

                        const handleSave = async (index: number) => {
                            try {
                                const row = rows[index];
                                row.type_ids.forEach(async function (items: any) {
                                    const itemTypeDetails: any = assetTypeData.filter(item => item.id === items).map(ele => ele.name);
                                    const { data: createAssetData } = await createAsset({
                                        variables: {
                                            name: values.name + ' - ' + assetTypeName + ' - ' + itemTypeDetails,
                                            owner: values.owner,
                                            pm: values.pm,
                                            sh: values.stakeholders,
                                            alignmentType: 'alignment',
                                            itemType: values.itemType,
                                            itemClass: values.itemClass,
                                            riskGroup: values.riskGroup,
                                            riskFunction: values.riskFunction,
                                            assetCategory: row.category_id,
                                            assetType: items,
                                        },
                                        // refetchQueries: [{ query: refetchQuery }],
                                        // awaitRefetchQueries: true,                                          
                                    });
                                    if (createAssetData) {
                                        setAlignedAssets(prevAlignedAssets => [...prevAlignedAssets, createAssetData?.createAsset?.id]);
                                        enqueueSuccess(createAssetData?.createAsset?.name + ' Asset Alignment successfully created!');
                                    } else {
                                        enqueueError('An error occurred while creating Asset Alignment!');
                                    }
                                })
                            } catch (e) {
                                console.log(e);
                            }
                        };

                        const handleRiskGroupChange = (event: any) => {
                            const selectedRiskGroupId = event.target.value;
                            const selectedRiskGroup = riskData.find(item => item.id === selectedRiskGroupId);
                            values.riskFunction = selectedRiskGroup?.risk_function_id;
                            handleChange(event);
                            if (selectedRiskGroup) {
                                const selectRiskFunction: any = riskFunction.find(risk => risk.id === selectedRiskGroup?.risk_function_id);
                                setRiskFunctionData(selectRiskFunction?.name);
                            }
                        };
                        const handleAssetDetailsChange = (event: any) => {
                            handleChange(event);
                            const assetCategoryId = event.target.value;
                            if (assetCategoryId) {
                                const assetTypeDataValue: any = assetTypeData.filter(asset => asset.categoryId === assetCategoryId);
                                setAssetTypeDetails(assetTypeDataValue);
                            }
                        };
                        const handleAssetTypeDetailsChange = (event: any) => {
                            handleChange(event);
                            const AssetTypeDetails: any = assetTypeData.filter(item => item.id === event.target.value);
                            setAssetTypeName(AssetTypeDetails[0].name);
                            const newAssetTypeDetails: { [key: number]: AssetTypeEntity[] } = {};
                            const prevData = prevType.filter(ele => ele.itemCategoryTypeId === event.target.value);
                            prevData.forEach((data, index) => {
                                if (data.itemCategoryTypeId === event.target.value) {
                                    const assetTypeDataValue: AssetTypeEntity[] = assetTypeData.filter(asset => asset.categoryId === data.categoryId);
                                    newAssetTypeDetails[index] = assetTypeDataValue;
                                }
                            });
                            setRows(prevData.map(ele => ({ category_id: ele.categoryId, type_ids: ele.typeIds })));
                            setAssetPrevTypeDetails(newAssetTypeDetails);
                            setOpenRiskAlignmentModal(true);
                        };

                        const handleItemTypeChange = (event: any) => {
                            handleChange(event);
                            setItemTypeData(event.target.value);
                            const itemName: any = itemType.find(item => item.id === event.target.value)?.name;
                            setItemTypeName(itemName);
                            setOpenRiskAlignmentModal(false);
                            setRows([]);
                        };
                        const handleCategoryChange = (index: number, value: string) => {
                            const newRows = [...rows];
                            newRows[index] = { ...newRows[index], category_id: Number(value), type_ids: [] };
                            const assetTypeDataValue: AssetTypeEntity[] = assetTypeData.filter(asset => asset.categoryId === Number(value));
                            setAssetPrevTypeDetails({ ...assetPrevTypeDetails, [index]: assetTypeDataValue });
                            setRows(newRows);
                        };
                        const handleTypeChange = (index: number, value: (string | number)[]) => {
                            const newRows = [...rows];
                            newRows[index] = { ...newRows[index], type_ids: value };
                            setRows(newRows);
                        };

                        const filterName = (event: any) => {
                            console.log(event, 'event');
                        }

                        // const setFilter = (value: any) => {
                        //     console.log(value, 'value');
                        // }
                        return (
                            <StyledForm>
                                <FieldDiv style={{ padding: "10px", border: "1px solid #ededed" }}>
                                    <CustomHeading>Item details <span style={{ color: "red" }}>*</span></CustomHeading>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <TextField
                                                select
                                                fullWidth
                                                name='itemType'
                                                label='Itam type'
                                                error={!!errors.itemType}
                                                helperText={errors.itemType}
                                                value={values?.itemType}
                                                disabled={itemTypeLoading || type === 'edit'}
                                                onChange={handleItemTypeChange}
                                            >
                                                {itemType &&
                                                    itemType?.map((item: { id: number; name: string; }) => (
                                                        <MenuItem key={item?.id} value={item?.id}>
                                                            {item?.name}
                                                        </MenuItem>
                                                    ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                select
                                                fullWidth
                                                name='itemClass'
                                                label='Item classification'
                                                error={!!errors.itemClass}
                                                helperText={errors.itemClass}
                                                value={values?.itemClass}
                                                disabled={itemClassLoading || type === 'edit'}
                                                onChange={handleChange}
                                            >
                                                {itemClass &&
                                                    itemClass?.map((item: { id: number; name: string; }) => (
                                                        <MenuItem key={item?.id} value={item?.id}>
                                                            {item?.name}
                                                        </MenuItem>
                                                    ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                required
                                                fullWidth
                                                id='name'
                                                label='Item name'
                                                placeholder='Item name'
                                                value={values.name}
                                                error={!!errors.name}
                                                helperText={errors.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur} />
                                        </Grid>
                                    </Grid>
                                    {itemTypeData !== 0 &&
                                        <FieldDiv>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        name='assetCategory'
                                                        label={itemTypeName + ' Category'}
                                                        error={!!errors.assetCategory}
                                                        helperText={errors.assetCategory}
                                                        value={values?.assetCategory}
                                                        disabled={assetCategoryLoading || type === 'edit'}
                                                        onChange={handleAssetDetailsChange}
                                                    >
                                                        {assetCategory &&
                                                            assetCategory.filter(risk => risk.itemId === itemTypeData)
                                                                .map((item: { id: number; name: string; }) => (
                                                                    <MenuItem key={item?.id} value={item?.id}>
                                                                        {item?.name}
                                                                    </MenuItem>
                                                                )
                                                                )
                                                        }
                                                    </TextField>

                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        name='assetType'
                                                        label={itemTypeName + ' Type'}
                                                        error={!!errors.assetType}
                                                        helperText={errors.assetType}
                                                        value={values?.assetType}
                                                        disabled={!assetTypeDetails.length || type === 'edit'}
                                                        onChange={handleAssetTypeDetailsChange}
                                                    >
                                                        {assetTypeDetails &&
                                                            assetTypeDetails?.map((item: { id: number; name: string; }) => (
                                                                <MenuItem key={item?.id} value={item?.id}>
                                                                    {item?.name}
                                                                </MenuItem>
                                                            ))}
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </FieldDiv>
                                    }
                                </FieldDiv>
                                <FieldDiv style={{ padding: "10px", border: "1px solid #ededed", marginTop: "20px" }}>
                                    <CustomHeading>Risk details <span style={{ color: "red" }}>*</span></CustomHeading>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                select
                                                fullWidth
                                                name='riskGroup'
                                                label='Risk group'
                                                error={!!errors.riskGroup}
                                                helperText={errors.riskGroup}
                                                value={values?.riskGroup}
                                                disabled={riskDataLoading}
                                                onChange={handleRiskGroupChange}
                                            >
                                                {riskData &&
                                                    riskData?.map((item: { id: number; name: string; }) => (
                                                        <MenuItem key={item?.id} value={item?.id}>
                                                            {item?.name}
                                                        </MenuItem>
                                                    ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                required
                                                fullWidth
                                                name='riskFunction'
                                                label='Risk function'
                                                value={riskFunctionData}
                                                error={!!errors.riskFunction}
                                                helperText={errors.riskFunction}
                                                onChange={handleChange}
                                                onBlur={handleBlur} />
                                        </Grid>
                                    </Grid>
                                </FieldDiv>
                                {(openRiskAlignmentModal && type === 'new') &&
                                    <FieldDiv style={{ padding: "10px", border: "1px solid #ededed", marginTop: "20px" }}>
                                        <CustomHeading> {itemTypeName === 'Risk' ? 'Asset Alignment' : itemTypeName === 'Asset' ? 'Risk Alignment' : ''} <span style={{ color: "red" }}>*</span></CustomHeading>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            {itemTypeName === 'Risk' && <th style={{ fontSize: "14px", textTransform: "capitalize", fontWeight: 600, background: "#eaeaea", color: "black" }}>name</th>}
                                                            <th style={{ fontSize: "14px", textTransform: "capitalize", fontWeight: 600, background: "#eaeaea", color: "black" }}>Category</th>
                                                            <th style={{ fontSize: "14px", textTransform: "capitalize", fontWeight: 600, background: "#eaeaea", color: "black" }}>Type</th>
                                                            <th style={{ width: "90px", fontSize: "14px", textTransform: "capitalize", fontWeight: 600, background: "#eaeaea", color: "black" }}>Add to RR</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {rows &&
                                                            rows.map((row, index) => (
                                                                <tr key={index}>
                                                                    {itemTypeName === 'Risk' && (
                                                                        <td>
                                                                            <Autocomplete
                                                                                options={
                                                                                    assetData
                                                                                        ? assetData
                                                                                            .filter(ele => ele.itemType?.id === 2 && ele.assetCategory?.id === row.category_id)
                                                                                            .map(ele => ele.name)
                                                                                        : []
                                                                                }
                                                                                renderInput={(params) => <TextField {...params} label="Name" />}
                                                                                label="Choose Name"
                                                                                onChange={(e: any) => {
                                                                                    handleChange(e);
                                                                                    filterName(e);
                                                                                }}
                                                                            />
                                                                        </td>
                                                                    )}
                                                                    <td>
                                                                        <Field
                                                                            as={TextField}
                                                                            select
                                                                            fullWidth
                                                                            name={`rows[${index}].category`}
                                                                            label={itemTypeName === 'Risk' ? 'Asset Category' : itemTypeName === 'Asset' ? 'Risk Category' : ''}
                                                                            value={row.category_id}
                                                                            onChange={(e: any) => {
                                                                                handleChange(e);
                                                                                handleCategoryChange(index, e.target.value);
                                                                            }}
                                                                        >
                                                                            {assetCategory &&
                                                                                assetCategory
                                                                                    .filter(risk => risk.itemId === itemData)
                                                                                    .map((item: { id: number; name: string }) => (
                                                                                        <MenuItem key={item.id} value={item.id} disabled={item?.id === selectedCategory[item?.id]} >
                                                                                            {item.name}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                        </Field>
                                                                    </td>
                                                                    <td>
                                                                        <Field
                                                                            as={Select}
                                                                            multiple
                                                                            fullWidth
                                                                            name={`rows[${index}].type`}
                                                                            label={'Item Type'}
                                                                            value={row.type_ids}
                                                                            onChange={(e: any) => {
                                                                                handleChange(e);
                                                                                handleTypeChange(index, e.target.value);
                                                                            }}
                                                                            renderValue={(selected: any) =>
                                                                                selected.map((id: number) => assetPrevTypeDetails[index]?.find(item => item.id === id)?.name).join(', ')
                                                                            }
                                                                        >
                                                                            {

                                                                                assetPrevTypeDetails[index] &&
                                                                                assetPrevTypeDetails[index].map((item: { id: number; name: string }) => (
                                                                                    <MenuItem key={item.id} value={item.id} disabled={item.id === selectedType[item?.id]}>
                                                                                        <Checkbox checked={row.type_ids.indexOf(item.id) > -1 && item.id !== selectedType[item?.id]} />
                                                                                        <ListItemText primary={item.name} />
                                                                                    </MenuItem>
                                                                                ))}
                                                                        </Field>
                                                                    </td>
                                                                    <td style={{ textAlign: 'center' }}>
                                                                        {/* <ActionButton type="button" disabled={type !== 'edit'} onClick={() => setIndexValue(index)} onClick={() => handleSave(index)}>Add</ActionButton> */}
                                                                        <ActionButton type="button" onClick={() => handleSave(index)} >Add</ActionButton>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </Table>
                                                <Button type="button" onClick={addRow} style={{ marginTop: 15 }}>
                                                    + Category
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </FieldDiv>
                                }
                                <FieldDiv style={{ padding: "10px", border: "1px solid #ededed", marginTop: "20px" }}>
                                    <CustomHeading>Assign Asset Users <span style={{ color: "red" }}>*</span></CustomHeading>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth
                                                select
                                                name='owner'
                                                label='Asset owner'
                                                // className='is-select'
                                                value={values.owner}
                                                // InputLabelProps={{ shrink: true }}
                                                // SelectProps={{
                                                //     multiple: true,
                                                // }}
                                                disabled={usersLoading}
                                                onChange={handleChange}
                                            >
                                                {users ? (
                                                    users.map(user => {
                                                        const { firstName, id, lastName } = user;
                                                        return (
                                                            <MenuItem key={id} value={id}>
                                                                {firstName} {lastName}
                                                            </MenuItem>
                                                        );
                                                    })
                                                ) : (
                                                    <MenuItem key={0} value={''}>
                                                        Select user(s)
                                                    </MenuItem>
                                                )}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth
                                                select
                                                name='pm'
                                                label='Project manager'
                                                // className='is-select'
                                                value={values.pm}
                                                // InputLabelProps={{ shrink: true }}
                                                // SelectProps={{
                                                //     multiple: true,
                                                // }}
                                                disabled={usersLoading}
                                                onChange={handleChange}
                                            >
                                                {users ? (
                                                    users.map(user => {
                                                        const { firstName, id, lastName } = user;
                                                        return (
                                                            <MenuItem key={id} value={id}>
                                                                {firstName} {lastName}
                                                            </MenuItem>
                                                        );
                                                    })
                                                ) : (
                                                    <MenuItem key={0} value={''}>
                                                        Select users(s)
                                                    </MenuItem>
                                                )}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={4}>

                                            <TextField
                                                fullWidth
                                                select
                                                name='stakeholders'
                                                label='Stakeholders'
                                                // className='is-select'
                                                value={values.stakeholders}
                                                // InputLabelProps={{ shrink: true }}
                                                disabled={usersLoading}
                                                SelectProps={{
                                                    multiple: true,
                                                    renderValue: (value: any) => {
                                                        const values = users.filter(user => value.includes(user?.id)
                                                        );
                                                        return values
                                                            .map(user => user?.firstName + ' ' + user?.lastName)
                                                            .join(', ');
                                                    },
                                                }}
                                                onChange={handleChange}
                                            >
                                                {users ? (
                                                    users.map(user => {
                                                        const { firstName, id, lastName } = user;
                                                        return (
                                                            <MenuItem key={id} value={id}>
                                                                {firstName} {lastName}
                                                            </MenuItem>
                                                        );
                                                    })
                                                ) : (
                                                    <MenuItem key={0} value={''}>
                                                        Select users(s)
                                                    </MenuItem>
                                                )}
                                            </TextField>

                                        </Grid>
                                    </Grid>
                                    {(type === 'edit') &&
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    id='notes'
                                                    name='notes'
                                                    label='Notes'
                                                    error={!!errors.notes}
                                                    helperText={errors.notes}
                                                    value={values.notes}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                </FieldDiv>

                                <FormStyled.FormRowSubmit>
                                    <SubmitButton
                                        type='submit'
                                        loading={isSubmitting}
                                    >
                                        {type === 'edit' ? 'Update' : 'Create'}
                                    </SubmitButton>

                                    <SubmitButton
                                        variant='text'
                                        disabled={isSubmitting}
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </SubmitButton>
                                </FormStyled.FormRowSubmit>
                            </StyledForm>
                        );
                    }}
                </ExtendedFormik>
            </StyledModal></>
    );
};

export { EditAssetModal };

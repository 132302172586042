import { gql } from '@apollo/client';

import { GapSingleFragment } from '@modules/custom-components/graphql';
import { RoadmapRecommendationFragment } from '@modules/roadmap/graphql';
import { UserFragment } from '@modules/users/graphql';

export const CapabilityAssessmentFragment = gql`
    fragment CapabilityAssessment on CapabilityAssessment {
        id
        weight
        notes
        gaps
        recommendations
        assessed_at
        assessed_by {
            ...User
        }
        operators {
            id
            first_name
            last_name
        }
        capability {
            id
            title
            key_factors
            security_controls
        }
        completion_level {
            id
            name
            description
            level
            gaps {
                ...GapSingle
            }
        }
        capability_levels {
            id
            name
            description
            level
        }
        impact_capability_levels {
            id
            name
            description
            level
        }
        scored_gaps {
            id
            score
            risk_level
            gap {
                ...GapSingle
            }
            roadmap_recommendations {
                ...RoadmapRecommendation
            }
        }
        log {
            id
            action
            action_timestamp
            user {
                email
                first_name
                last_name
            }
            notes
            gaps
            recommendations
        }
    }
    ${UserFragment}
    ${GapSingleFragment}
    ${RoadmapRecommendationFragment}
`;

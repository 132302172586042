import { gql } from '@apollo/client';

export const TaskSingleFragment = gql`
    fragment TaskSingle on Task {
        id
        requirement_id
        task
        resources
        duration
    }
`;

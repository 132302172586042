import { useMutation } from '@apollo/client';
import { DeleteAssessmentTypeMutation } from '@modules/custom-components/graphql';
import { CategoryActions } from '@modules/custom-components/moleculas/component-actions/category';
import { CustomAssessmentTypeEditModal } from '@modules/custom-components/moleculas/component-page-modal';
import { CustomAssessmentListToolbar } from '@modules/custom-components/moleculas/custom-components-list-toolbar';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { Loader } from '@modules/layout/moleculas';
import { AccordionListItem } from '@modules/layout/organisms';
import { PageStyled } from '@modules/layout/styled';
import { GetAssessmentTypesQuery } from '@modules/shared/graphql';
import { useAssessmentTypes } from '@modules/shared/hooks';
import {
    DeleteAssessmentTypeMutationType,
    DeleteAssessmentTypeMutationVariables,
} from '@modules/types/graphql';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AllComponent } from './all-component';
import { GetAllAssessmentTypeQuery } from '@modules/components/graphql';
import { useCurrentUser } from '@modules/auth/hooks';
import { MoreVert } from '@material-ui/icons';

type AssessmentTypeProps = {
    title?: string;
    assessmentDetails?: any;
    assessmentId?: any;
};
const DeleteSpan = styled.div`
    color: crimson;
`;

const Root = styled.div`
    width: 100%;
    border-bottom: 1px solid #d1d2d2;
    margin-top: 20px;
    position: relative;
`;

const assessmentType = [
    {
        type: 'Insider Risk',
    },
    {
        type: 'Cyber Risk',
    },
    {
        type: 'Data Protection',
    },
];

const AssessmentTypeList = (props: AssessmentTypeProps): React.ReactElement => {
    const { title = 'Assessment', assessmentDetails, assessmentId } = props;
    const [componentSearch, setComponentSearch] = useState<string>('');
    const [assessmentFilterData, setAssessmentFilterData] = useState<any[]>();
    const [openEditModal, setOpenEditModal] = useState<Boolean | any>(false);
    const [assessmentEditId, setAssessmentEditId] = useState<number | any>(null);
    const [selectedId, setSelectedId] = useState<number | any>(null);
    const componentActionStatus = true;
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [deleteAssessmentType, { loading: deleteAssessmentLoading }] = useMutation<
        DeleteAssessmentTypeMutationType,
        DeleteAssessmentTypeMutationVariables
    >(DeleteAssessmentTypeMutation);

    const handleOpenEditModal = () => setOpenEditModal(true);
    const handleCloseEditModal = () => setOpenEditModal(false);
    const { currentUser } = useCurrentUser();

    const handleDeleteAssessmentType = async (id: number): Promise<void> => {
        setSelectedId(id);
        try {
            const { data: deleteAssessmentTypeData } = await deleteAssessmentType({
                variables: { id: id },
                refetchQueries: [{ query: GetAllAssessmentTypeQuery }],
                awaitRefetchQueries: true,
            });
            if (deleteAssessmentTypeData?.deleteAssessmentType) {
                enqueueSuccess('Assessment type successfully deleted!');
            } else {
                enqueueError('An error occurred while deleting the Assessment type!');
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleEditAssessmentType = (id: number) => {
        setAssessmentEditId(id);
        handleOpenEditModal();
    };

    useEffect(() => {
        const filterAssessment = assessmentDetails?.filter((ele: any) => {
            if (typeof ele?.name === 'string') {
                return ele?.name.toLowerCase().includes(componentSearch.toLocaleLowerCase());
            }
            return false;
        });
        setAssessmentFilterData(filterAssessment);
    }, [componentSearch, assessmentDetails]);

    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>{title}</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>

            <PageStyled.Bridge>
                <CustomAssessmentListToolbar
                    ButtonTitleName={'Create New Assessment'}
                    setComponentSearch={setComponentSearch}
                    componentSearch={componentSearch}
                    assessmentId={assessmentId}
                />
            </PageStyled.Bridge>
            <Root>
                {assessmentFilterData?.length ? (
                    assessmentFilterData?.map((assessments: any) => {
                        const canEditAssessment = assessments.isPredefined()
                            ? currentUser.hasSuperAdminPermission() ||
                              currentUser.hasAdminPermission()
                            : true;
                        return (
                            <React.Fragment key={assessments?.id}>
                                <AccordionListItem
                                    title={
                                        selectedId === assessments?.id ? (
                                            deleteAssessmentLoading ? (
                                                <DeleteSpan>Deleting...</DeleteSpan>
                                            ) : (
                                                assessments?.name
                                            )
                                        ) : (
                                            assessments?.name
                                        )
                                    }
                                    componentActionStatus={componentActionStatus}
                                    subtitle=''
                                    componentSearch={componentSearch}
                                    SummaryInfoComponents={
                                        <>
                                            {canEditAssessment ? (
                                                <CategoryActions
                                                    id={assessments?.id}
                                                    onDelete={handleDeleteAssessmentType}
                                                    onUpdate={handleEditAssessmentType}
                                                />
                                            ) : (
                                                <MoreVert
                                                    style={{ fontSize: '24px', color: 'gray' }}
                                                />
                                            )}
                                        </>
                                    }
                                >
                                    <AllComponent
                                        assessmentId={assessments?.id}
                                        allComponent={assessments?.component}
                                    />
                                </AccordionListItem>
                            </React.Fragment>
                        );
                    })
                ) : (
                    <h2 className='py-2'>No Assessment found</h2>
                )}

                <CustomAssessmentTypeEditModal
                    type='edit'
                    open={openEditModal}
                    GetAssessmentTypesQuery={GetAssessmentTypesQuery}
                    assessmentId={assessmentEditId}
                    onClose={handleCloseEditModal}
                />
            </Root>
        </>
    );
};

export default AssessmentTypeList;

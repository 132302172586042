import { EntityList } from '@lib/entity';
import type { Entity } from './all-item-types-entity';
import { AllItemTypesEntity } from './all-item-types-entity';


class AllItemTypesEntityList extends EntityList<AllItemTypesEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(AllItemTypesEntity, items, count);
    }
}

export { AllItemTypesEntityList };

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { CapabilityLevelEntity } from '@modules/capabilities/entities';
import { GetCapabilityLevelQuery } from '@modules/custom-components/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetCapabilityLevelQueryType,
    GetCapabilityLevelQueryVariables,
} from '@modules/types/graphql';

const useCapabilityLevel = (
    options?: QueryHookOptions<GetCapabilityLevelQueryType, GetCapabilityLevelQueryVariables>,
) => {
    const queryResult = useQuery<GetCapabilityLevelQueryType, GetCapabilityLevelQueryVariables>(
        GetCapabilityLevelQuery,
        options,
    );

    const capabilityLevel = useEntity(
        () => new CapabilityLevelEntity(queryResult.data?.getCapabilityLevel ?? null),
        [queryResult.data],
    );

    const result = {
        capabilityLevel,
        ...queryResult,
    };

    return result;
};

export { useCapabilityLevel };

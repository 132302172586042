import React, { useState } from 'react';
import styled from 'styled-components';

import { ImplementationRecommendationsListItem } from './implementation-recommendations-list-item';
import type { ProjectEntity } from '@modules/projects/entities';
import type { RecommendationImplementationEntity } from '@modules/implementation/entities';
import { gray } from '@modules/ui/colors';
import { AccordionInnerListItem, TableColumn } from '@modules/layout/organisms';
import { ListStyled } from '@modules/layout/styled';

type ImplementationRecommendationsStatusSummaryProps = {
    title: string;
    project: ProjectEntity;
    recommendationsImplementation: (RecommendationImplementationEntity | never)[];
    riskLevelKey: string;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 16px;
    margin: 5px;
    background-color: ${gray[60]};
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);
`;

const List = styled.div`
    width: 100%;
    margin-top: 24px;

    > * {
        margin-bottom: 12px;
    }
`;

const ImplementationRecommendationsStatusSummary = (
    props: ImplementationRecommendationsStatusSummaryProps,
): React.ReactElement => {
    const { project, recommendationsImplementation } = props;
    const [expandedRow, setExpandedRow] = useState<string | null>(null);

    const onExpand = (riskLevel: string) => {
        if (expandedRow === riskLevel) {
            setExpandedRow(null);
        } else {
            setExpandedRow(riskLevel);
        }
    };
    const accordionTitle: TableColumn[] = [
        {
            id: 'pending',
            label: 'Pending',
        },
        {
            id: 'inProgress',
            label: 'In progress',
        },
        {
            id: 'done',
            label: 'Done',
        },
    ];

    return (
        <React.Fragment>
            {accordionTitle.map((title: any) => (
                <Root key={title.id}>
                    <AccordionInnerListItem
                        title={title.label}
                        subtitle={''}
                        expanded={expandedRow === title.id}
                        onChange={() => onExpand(title.id)}
                        SummaryInfoComponents={<></>}
                    >
                        <List>
                            {recommendationsImplementation.filter(recommendationImplementation => recommendationImplementation.status === title.label).length > 0 ? (
                                recommendationsImplementation.filter(recommendationImplementation => recommendationImplementation.status === title.label)
                                    .map(recommendationImplementation => (
                                        <ImplementationRecommendationsListItem
                                            key={recommendationImplementation.roadmapRecommendationId}
                                            project={project}
                                            recommendationImplementation={recommendationImplementation}
                                        />
                                    ))
                            ) : (
                                <ListStyled.Empty>Recommendations not found</ListStyled.Empty>
                            )}
                        </List>
                    </AccordionInnerListItem>
                </Root>
            ))}
        </React.Fragment>
    );
};

export { ImplementationRecommendationsStatusSummary };

import type { Route, CreateRoutePayload } from '@config/routes';

export const createRouteUrl = (route: Route, payload?: CreateRoutePayload) => {
    let url = route.path;

    if (!payload) {
        return url;
    }

    const payloadEntries = Object.entries(payload);

    payloadEntries.forEach(([key, item]) => {
        const regExp = new RegExp(`:${key}`, 'g');

        url = url.replace(regExp, item);
    });

    return url;
};

import { EntityList } from '@lib/entity';
import { CapabilityAssessmentEntity } from './capability-assessment-entity';

import type { Entity } from './capability-assessment-entity';

class CapabilityAssessmentEntityList extends EntityList<CapabilityAssessmentEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(CapabilityAssessmentEntity, items, count);
    }
}

export { CapabilityAssessmentEntityList };

import { gql } from '@apollo/client';

export const GetListItemClassQuery = gql`
    query listItemClass {
        listItemClass {
            id
            name
        }
    }
`;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { FormControlLabel, TextField } from '@material-ui/core';

import { Button, Modal } from '@modules/ui/core';
import { FormStyled } from '@modules/layout/styled';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { TableCellText, TableCheckbox } from '@modules/layout/atoms';
import { Loader, TableRow, TableCell } from '@modules/layout/moleculas';
import { ExtendedFormik, Form, Table } from '@modules/layout/organisms';
import type { ModalProps } from '@modules/ui/core';

import type {
    SetRecommendationImpSubTaskMutationType,
    SetRecommendationImpSubTaskMutationVariables,
    UpdateImpSubTaskMutationType,
    UpdateImpSubTaskMutationVariables,
} from '@modules/types/graphql';

import { ImpSubTaskUpdateRequest } from '@modules/custom-components/requests';
import {
    SetRecommendationImpSubTaskMutation,
    UpdateImplementationSubTaskMutation,
} from '@modules/implementation/graphql/mutations';
import { GetSubTaskQuery } from '@modules/implementation/graphql';
import { GetRecommendationImpSubTasksQuery } from '@modules/implementation/graphql/queries';
import { useRecommendationImpSubTasks } from '@modules/implementation/hooks';
type ImplementationTasksEditFormModalProps = Omit<ModalProps, 'title' | 'children'> & {
    impSubTask: any;
    recommendationId: number;
    companyId?: number;
    taskId: number;
    RecImpSubTask?: any;
    subTaskLoading: boolean;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const ImplementationSubTasksEditFormModal = (
    props: ImplementationTasksEditFormModalProps,
): React.ReactElement => {
    const {
        recommendationId,
        impSubTask,
        RecImpSubTask,
        companyId,
        taskId,
        subTaskLoading,
        onClose,
        ...otherProps
    } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [subTaskData, setSubTaskData] = useState<any>([]);

    useEffect(() => {
        setSubTaskData([...impSubTask]);
    }, [impSubTask]);

    const [setRecommendationImpSubTask] = useMutation<
        SetRecommendationImpSubTaskMutationType,
        SetRecommendationImpSubTaskMutationVariables
    >(SetRecommendationImpSubTaskMutation);

    // const initialValues = {
    //     subTasks: impSubTask?.map((subtask: any) => subtask && subtask?.id).filter(Boolean),
    // };
    const initialValues = {
        subTasks:
            RecImpSubTask?.map((subtask: any) => subtask && subtask?.id).filter(Boolean) || [],
    };

    const [updateSubTask] = useMutation<
        UpdateImpSubTaskMutationType,
        UpdateImpSubTaskMutationVariables
    >(UpdateImplementationSubTaskMutation);

    if (subTaskLoading) {
        return <Loader />;
    }
    return (
        <Modal title='Edit Sub Tasks' onClose={onClose} {...otherProps}>
            <ExtendedFormik
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={yup.object({
                    subTasks: yup.array().min(1, 'Required field'),
                })}
                initialValues={initialValues}
                onSubmit={async values => {
                    try {
                        subTaskData?.forEach(async function (items: any) {
                            items.subTaskId = items.id;
                            const variables = new ImpSubTaskUpdateRequest(items);
                            const { data: updateSubTaskData } = await updateSubTask({
                                variables,
                                refetchQueries: [
                                    {
                                        query: GetSubTaskQuery,
                                        variables: {
                                            recommendationId,
                                            taskId,
                                            companyId: companyId,
                                        },
                                    },
                                ],
                                awaitRefetchQueries: true,
                            });
                        });
                        const {
                            data: setRecommendationImpSubTaskData,
                        } = await setRecommendationImpSubTask({
                            variables: {
                                subTaskId: values.subTasks as number[],
                                recommendationId: recommendationId,
                                taskId: taskId,
                            },
                            refetchQueries: [
                                {
                                    query: GetRecommendationImpSubTasksQuery,
                                    variables: { taskId: taskId },
                                },
                            ],
                            awaitRefetchQueries: true,
                        });
                        if (
                            setRecommendationImpSubTaskData?.setRecommendationImpSubTask?.length !==
                            -1
                        ) {
                            enqueueSuccess('Sub Task successfully updated!');
                            onClose();
                        } else {
                            enqueueError('An error occurred while saving Sub Task!');
                        }
                    } catch (e) {
                        console.log(e);
                        throw e;
                    }
                }}
            >
                {formikProps => {
                    const { values, setFieldValue, isSubmitting } = formikProps;

                    const handleCheckGap = (subTaskId: number) => (_: React.ChangeEvent<{}>) => {
                        const subTasksToUpdate = [...values.subTasks];
                        const idx = subTasksToUpdate.indexOf(subTaskId);

                        if (idx > -1) {
                            subTasksToUpdate.splice(idx, 1);
                        } else {
                            subTasksToUpdate.push(subTaskId);
                        }
                        setFieldValue('subTasks', subTasksToUpdate);
                    };

                    const handleSubTaskText = (e: any, subTaskId: number) => {
                        let newSubTaskData = impSubTask?.map((ele: any) => {
                            if (ele.id === subTaskId) {
                                ele.subTask = e.target.value;
                            }
                            return ele;
                        });
                        setSubTaskData([...newSubTaskData]);
                    };

                    return (
                        <StyledForm>
                            <Table>
                                {subTaskData?.map((subtask: any) => {
                                    return (
                                        <TableRow key={subtask.id}>
                                            <TableCell>
                                                <FormControlLabel
                                                    control={
                                                        <TableCheckbox
                                                            checked={
                                                                values.subTasks &&
                                                                values.subTasks.includes(subtask.id)
                                                            }
                                                            onChange={handleCheckGap(subtask.id)}
                                                        />
                                                    }
                                                    label={
                                                        <TableCellText>
                                                            <TextField
                                                                multiline
                                                                fullWidth
                                                                name='subTaskText'
                                                                value={subtask?.subTask}
                                                                onChange={e =>
                                                                    handleSubTaskText(
                                                                        e,
                                                                        subtask?.id,
                                                                    )
                                                                }
                                                                style={{
                                                                    minWidth: '400px',
                                                                }}
                                                            />
                                                        </TableCellText>
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </Table>

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    Save
                                </SubmitButton>

                                <SubmitButton
                                    variant='text'
                                    disabled={isSubmitting}
                                    onClick={onClose}
                                >
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    );
                }}
            </ExtendedFormik>
        </Modal>
    );
};

export { ImplementationSubTasksEditFormModal };

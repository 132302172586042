import React from 'react';
import styled, { css } from 'styled-components';

import { gray } from '@modules/ui/colors';

type BarProps = {
    width?: number;
};

type LevelBarProps = {
    value: string | number;
    levels: (string | number)[];
    withPercent?: boolean;
    BarProps?: BarProps;
    className?: string;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > * {
        display: inline-flex;
    }
`;

const Label = styled.div`
    margin-right: 10px;
    font-size: 1.4rem;
    line-height: 16px;
`;

const List = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > * {
        &:not(:last-of-type) {
            margin-right: 2px;
        }
    }
`;

const Bar = styled.div<{ filled: boolean; width?: number }>`
    width: ${p => (!!p.width ? p.width : 28)}px;
    height: 12px;
    background-color: ${gray[50]};
    border-radius: 4px;

    ${p =>
        p.filled &&
        css`
            background-color: ${gray[30]};
        `}
`;

const LevelBar = (props: LevelBarProps): React.ReactElement => {
    const { value, levels, withPercent, BarProps, ...otherProps } = props;

    const currentIdx = levels.findIndex(level => level === value);
    const percent = (((currentIdx + 1) * 100) / levels.length).toFixed(0);

    const barWidth = BarProps?.width;

    return (
        <Root {...otherProps}>
            {withPercent ? <Label>{`${percent}%`}</Label> : null}

            <List>
                {levels.map((_, index) => (
                    <Bar key={index} width={barWidth} filled={index <= currentIdx} />
                ))}
            </List>
        </Root>
    );
};

export { LevelBar };

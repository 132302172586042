import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { AllAssessmentTypeEntityList } from '@modules/components/entities';
import { GetAllAssessmentTypeQuery } from '@modules/components/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetAllAssessmentTypeQueryType,
    GetAllAssessmentTypeQueryVariables,
} from '@modules/types/graphql';

const useAllAssessmentType = (
    options?: QueryHookOptions<GetAllAssessmentTypeQueryType, GetAllAssessmentTypeQueryVariables>,
) => {
    const queryResult = useQuery<GetAllAssessmentTypeQueryType, GetAllAssessmentTypeQueryVariables>(
        GetAllAssessmentTypeQuery,
        options,
    );

    const { entities: allComponents, count } = useEntity(
        () => new AllAssessmentTypeEntityList(queryResult.data?.listAllComponents ?? null),
        [queryResult.data],
    );

    const result = {
        allComponents,
        count,
        ...queryResult,
    };
    return result;
};

export { useAllAssessmentType };

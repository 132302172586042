import React from 'react';

import { Modal } from '@modules/ui/core';

import type { ModalProps } from '@modules/ui/core';

import { CustomComponentCreateForm } from '@modules/custom-components/organisms';
import { CustomAssessmentTypeCreateForm } from '@modules/custom-components/organisms/custom-assessment-type-create-form';

type CustomComponentCreateModalProps = Omit<ModalProps, 'title' | 'children'> & {
    companyId?: number;
    assessmentId?: number;
    type: string;
};

const CustomAssessmentTypeCreateModal = (
    props: CustomComponentCreateModalProps,
): React.ReactElement => {
    const { companyId, type, assessmentId, onClose, ...otherProps } = props;

    return (
        <Modal
            title={type === 'create' ? 'Create Component Type' : 'Edit Component Type'}
            onClose={onClose}
            {...otherProps}
        >
            <CustomAssessmentTypeCreateForm assessmentId={assessmentId} onCancel={onClose} />
        </Modal>
    );
};

export { CustomAssessmentTypeCreateModal };

import React, { MouseEventHandler } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

import { Button, ButtonProps } from './button';

export const ExportButton = (props: ButtonProps): React.ReactElement => {
    const outputProps: ButtonProps = { ...props };
    delete outputProps.onClick;
    function fitImgTOPdfPageOutput(canvas: HTMLCanvasElement, input: HTMLElement) {
        // document.body.appendChild(canvas)

        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({ unit: 'px', compress: true });
        const width = input.offsetWidth;
        const height = input.offsetHeight;
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        let pdfRatio = pdfWidth / width;
        let calcHeight = pdfRatio * height;

        if (calcHeight <= pdfHeight) {
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, 0);
        } else {
            pdf.addImage(imgData, 'PNG', 0, 0, 0, pdfHeight);
        }

        pdf.output('dataurlnewwindow');
        // pdf.save(`assessment-report-${(new Date()).toISOString().replaceAll(':', '-')}.pdf`);
    }

    function printDocument(event: any) {
        const input = document.getElementById('app-page-container') as HTMLElement;
        html2canvas(input).then((canvas: HTMLCanvasElement) => {
            // document.body.appendChild(canvas)

            let imgData = canvas.toDataURL('image/png');
            let imgWidth = 210;
            let pageHeight = 295;
            let imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let doc = new jsPDF({ unit: 'mm', compress: true });
            // give some top padding to first page
            let topOffset = 0; //(window as any).offset;

            doc.addImage(imgData, 'PNG', 0, topOffset, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                // top padding for other pages
                topOffset += heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 0, topOffset, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            // doc.output('dataurlnewwindow');
            doc.save(`${props.exportfilename}-${new Date().toISOString().replaceAll(':', '-')}.pdf`);

            props.onClick && props.onClick(event);
        });
    }

    return (
        <Button {...outputProps} onClick={printDocument}>
            Export
        </Button>
    );
};

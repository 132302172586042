import {useProjectRoles} from '@modules/projects/hooks';
import {useCurrentUser} from '@modules/auth/hooks';

export function useGeneralRoles(projectId: number) {
  const { projectRolesEntityList } = useProjectRoles({ variables: { projectId: projectId } });
  const { currentUser } = useCurrentUser();

  const isAdmin = currentUser.hasSuperAdminPermission();
  const isPm = projectRolesEntityList.hasProgramManager();
  const isSt = projectRolesEntityList.hasStakeholder();
  const isGeneralEditAllowed = isAdmin || isPm || isSt;

  return {
    isAdmin,
    isPm,
    isSt,
    isGeneralEditAllowed
  };
}

export class ParseError {
    message: string;
    errorData?: any;

    constructor(message: string, data?: any) {
        this.message = message;
        this.errorData = data;
    }
}

export class AssetCreateInput {
    name: string = '';
    owner: string = '';
    pm: string = '';
    stakeholders: string[] = [];
}

export class CsvReader {
    lineSeparator = '\n';
    fieldSeparator = ',';
    allowedFieldSet = new Set<string>(['name', 'owner', 'pm', 'stakeholder']);

    async readFile(fileInput: HTMLInputElement): Promise<AssetCreateInput[] | ParseError> {
        if (!fileInput.files || !fileInput.files[0]) {
            return Promise.resolve(new ParseError('File is not selected'));
        }

        let csvFile = fileInput.files[0];
        if (!csvFile.name.includes('.csv')) {
            return Promise.resolve(new ParseError('File is not csv format'));
        }

        let reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.addEventListener('load', event => {
                this.onReadComplete(event, resolve);
            });

            reader.readAsBinaryString(csvFile);
        });
    }

    onReadComplete(event: ProgressEvent<FileReader>, resolve: Function): void {
        let csvdata = event?.target?.result?.toString();
        if (!csvdata) {
            return resolve(new ParseError('Error during file read', event?.target?.result));
        }

        let rowList = this.parseText(csvdata as string);
        if (!rowList.length) {
            return resolve(new ParseError('File is empty'));
        }

        let result = this.toAssetData(rowList);
        if (!result || !result.length) {
            return resolve(
                new ParseError(
                    'Format is not supported.\n' +
                        'Data rows should be separated by comma and should follow after the table header.\n' +
                        'Header should contain labels: name, owner, pm, stakeholder 1, stakeholder 2, ..., stakeholder N.\n' +
                        'Name, Owner and PM are required fields.\n' +
                        'At least one stakeholder should be present.\n',
                ),
            );
        }

        resolve(result);
    }

    parseText(data: string): string[][] {
        let parsedata = [];
        let rowList = data.split(this.lineSeparator);
        for (let index = 0; index < rowList.length; index += 1) {
            let row = rowList[index].trim();
            if (!row) {
                continue;
            }

            parsedata.push(
                row.split(this.fieldSeparator).map((cell: string) => {
                    let str = cell.trim();
                    if (
                        (str[0] === "'" && str[str.length - 1] === "'") ||
                        (str[0] === '"' && str[str.length - 1] === '"')
                    ) {
                        return str.slice(1, str.length - 1);
                    }

                    return str;
                }),
            );
        }

        return parsedata;
    }

    toAssetData(rowList: string[][]): AssetCreateInput[] | undefined {
        if (rowList.length < 2) {
            return;
        }

        let header = rowList[0];
        let fieldToDataMap = new Map<string, number[]>(
            Array.from(this.allowedFieldSet).map(value => [value, []]),
        );

        header.forEach((label: string, index: number) => {
            let field = String(label).split(' ')[0].toLowerCase();
            if (fieldToDataMap.has(field)) {
                fieldToDataMap.get(field)?.push(index);
            }
        });

        let isHeaderInvalid = Array.from(fieldToDataMap.values()).some(
            (fieldIndexList: number[]) => !fieldIndexList.length,
        );
        if (isHeaderInvalid) {
            return;
        }

        let resultList: AssetCreateInput[] = [];
        for (let index = 1; index < rowList.length; index += 1) {
            let assetInput = new AssetCreateInput();
            resultList.push(assetInput);

            fieldToDataMap.forEach((fieldIndexList: number[], field: string) => {
                if (field !== 'stakeholder') {
                    assetInput[field] = rowList[index][fieldIndexList[0]];
                    return;
                }

                assetInput.stakeholders = fieldIndexList
                    .map((stIndex: number) => {
                        return rowList[index][stIndex];
                    })
                    .filter(value => Boolean(value));
            });
        }

        return resultList;
    }
}

import React from 'react';
import { useParams, Redirect } from '@reach/router';

import { routes } from '@config/routes';
import { Loader } from '@modules/layout/moleculas';
import { PageStyled } from '@modules/layout/styled';
import { ProjectHeader } from '@modules/projects/moleculas';
import { useImplementationReport } from '@modules/implementation/hooks';
import { ReportImplementation } from '@modules/implementation/organisms';

const ProjectImplementationReportPage = (): React.ReactElement => {
    const params = useParams();

    const projectId = params.id ? Number(params.id) : null;

    const { project, implementationReport, loading: projectLoading } = useImplementationReport({
        fetchPolicy: 'network-only',
        skip: !projectId,
        variables: { id: projectId as number },
    });

    if (!projectId) {
        return <Redirect to={routes.projects.path} />;
    }

    if (projectLoading) {
        return <Loader />;
    }

    if (!project.isImplementation()) {
        return <Redirect noThrow to={routes.projects.path} />;
    }

    return (
        <>
            <ProjectHeader
                name={project.name}
                description={project.description}
                projectStatus={project.getStatus()}
                assessmentTypeId={project.assessmentTypeId}
                isRiskRegistry={project.isRiskRegistry}
            />

            <PageStyled.Main>
                <ReportImplementation
                    project={project}
                    implementationReport={implementationReport}
                />
            </PageStyled.Main>
        </>
    );
};

export { ProjectImplementationReportPage };

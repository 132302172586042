import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ListStyled } from '@modules/layout/styled';
import { ProjectMaturityLevel } from '@modules/projects/moleculas';
import { ReportPrioritizedRoadmapList } from '@modules/roadmap/organisms';

import type { ProjectEntity } from '@modules/projects/entities';
import type { ImplementationReportEntity } from '@modules/implementation/entities';
import { ProjectAssessmentComponentsFilter } from '@modules/projects/organisms';
import { UserEntity } from '@modules/users/entities';
import { ProjectAssessmentFilterValue } from '@modules/projects/organisms/project-assessment-components-filter/project-assessment-components-filter';
import {
    roadmapCollecCO,
    roadmapFilterHandler,
} from '@modules/projects/organisms/project-assessment-components-filter/filter-handlers';
import { usePropState } from '@modules/shared/hooks/use-prop-state';
import {
    getPrioritizedRoadmapAssessmentMap,
    RecommendationAssessment,
} from '@modules/roadmap/recommendation-assessment-roadmap-report';
import { ExportButton } from '@modules/ui/core/button/exportButton';

type ReportImplementationProps = {
    project: ProjectEntity;
    implementationReport: ImplementationReportEntity;
};

const SectionList = styled.div`
    width: 100%;
    margin-top: 32px;

    > *:not(:last-child) {
        margin-bottom: 32px;
    }
`;

const Section = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

const FilterSection = styled.div`
    margin-top: 20px;
    width: 100%;
`;

const ReportImplementation = (props: ReportImplementationProps): React.ReactElement => {
    const { project, implementationReport } = props;

    const maturityLevel = project.getMaturityLevel();
    const roadmapReport = implementationReport;
    const recommendationAssessmentMapRaw: Map<number, RecommendationAssessment> = useMemo(
        () => getPrioritizedRoadmapAssessmentMap(project),
        [project.assessments],
    );

    const componentOwnerMap = new Map();
    roadmapCollecCO(componentOwnerMap, roadmapReport.prioritizedRoadmap.dataSource);

    const componentOwnerList: UserEntity[] = Array.from(componentOwnerMap.values()).filter(
        user => user.id !== -1,
    );

    const [prioritizedRoadmap, setPrioritizedRoadmap] = usePropState(
        roadmapReport.prioritizedRoadmap,
    );
    function onFilterChange(filterValue: ProjectAssessmentFilterValue) {
        roadmapFilterHandler(
            setPrioritizedRoadmap,
            roadmapReport.prioritizedRoadmap,
            recommendationAssessmentMapRaw,
            filterValue,
        );
    }

    return (
        <ListStyled.Root>
            <ListStyled.Header>
                <h2>Implementation Report</h2>

                {project.stakeholder.exists() ? (
                    <ListStyled.HeaderInfo>
                        <p>Stakeholder:</p>
                        <p>{project.stakeholder.getFullName()}</p>
                    </ListStyled.HeaderInfo>
                ) : null}

                {implementationReport.hasProgramManager() ? (
                    <ListStyled.HeaderInfo>
                        <p>Project Manager:</p>
                        <p>{implementationReport.programManagerToString()}</p>
                    </ListStyled.HeaderInfo>
                ) : null}

                <ListStyled.HeaderInfo>
                    <p>Maturity level:</p>
                    {maturityLevel ? <ProjectMaturityLevel current={maturityLevel} /> : null}
                </ListStyled.HeaderInfo>
            </ListStyled.Header>

            <FilterSection>
                <ListStyled.HeaderInfo>
                    <ExportButton size='small' exportfilename='implementation-report' />
                </ListStyled.HeaderInfo>
                <ProjectAssessmentComponentsFilter
                    componentOwnerList={componentOwnerList}
                    hasRiskLevelFilter={true}
                    hasImplementationStatusFilter={true}
                    onFilterChange={onFilterChange}
                />
            </FilterSection>

            <SectionList>
                <Section>
                    {prioritizedRoadmap.hasAnyRecommendations() ? (
                        <ReportPrioritizedRoadmapList
                            withStatus
                            prioritizedRoadmap={prioritizedRoadmap}
                            project={project}
                        />
                    ) : (
                        <ListStyled.Empty>
                            Not found prioritized roadmap recommendations
                        </ListStyled.Empty>
                    )}
                </Section>
            </SectionList>
        </ListStyled.Root>
    );
};

export { ReportImplementation };

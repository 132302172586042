import { useQuery } from '@apollo/client';
import { GetListProjectsByCompanyQuery } from '@modules/projects/graphql';
import { useEntity } from '@lib/entity';
import { ProjectEntityList } from '@modules/projects/entities';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetListProjectsByCompanyQueryType,
    GetListProjectsByCompanyQueryVariables,
} from '@modules/types/graphql';

const useActiveProjectsByCompany = (
    options?: QueryHookOptions<
        GetListProjectsByCompanyQueryType,
        GetListProjectsByCompanyQueryVariables
    >,
) => {
    const queryResult = useQuery<
        GetListProjectsByCompanyQueryType,
        GetListProjectsByCompanyQueryVariables
    >(GetListProjectsByCompanyQuery, {
        ...options,
    });

    const { entities: projects, count } = useEntity(
        () => new ProjectEntityList(queryResult.data?.listProjectsByCompany ?? null),
        [queryResult.data],
    );

    const result = {
        projects,
        count,
        ...queryResult,
    };

    return result;
};

export { useActiveProjectsByCompany };

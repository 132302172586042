import { EntityList } from '@lib/entity';
import { ItemClassEntity } from './item-class-entity';

import type { Entity } from './item-class-entity';


class ItemClassEntityList extends EntityList<ItemClassEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(ItemClassEntity, items, count);
    }
}

export { ItemClassEntityList };

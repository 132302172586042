import React from 'react';
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';

import { Dialog } from '@modules/ui/core';
import { AlertCompanyRunningProjects } from '@modules/company/moleculas';

type DialogCompanyDeleteProps = {
    onDelete: () => void;
    companyId: number;
};

const StyledDialog = styled(Dialog)`
    min-height: initial;
`;

const DialogCompanyDelete = (props: DialogCompanyDeleteProps): React.ReactElement => {
    const { onDelete, companyId } = props;

    return (
        <StyledDialog
            title='Are you sure you want to delete this company?'
            content={
                <>
                    <AlertCompanyRunningProjects companyId={companyId} />
                </>
            }
            acceptText='Delete'
            onAccept={onDelete}
        >
            {dialogProps => {
                const { handleOpen } = dialogProps;

                return <MenuItem onClick={handleOpen}>Delete</MenuItem>;
            }}
        </StyledDialog>
    );
};

export { DialogCompanyDelete };

import { gql } from '@apollo/client';

export const RearrangeRecommendationMutation = gql`
    mutation RearrangeRecommendation(
        $roadmapRecommendationId: Int!
        $newIndex: Int!
        $newRiskLevel: String!
    ) {
        rearrangeRecommendation(
            roadmap_recommendation_id: $roadmapRecommendationId
            new_index: $newIndex
            new_risk_level: $newRiskLevel
        ) {
            id
        }
    }
`;

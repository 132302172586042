class CommonHelper {
    static fillDefaultValues<T extends {}>(values: Record<string, any>, initials: T): T {
        let shallowInitials = { ...initials };

        for (const key in shallowInitials) {
            if (typeof values[key] !== 'undefined') {
                shallowInitials[key] = values[key];
            }
        }

        return shallowInitials;
    }

    static multipleValuesFormatter(value: string) {
        if (typeof value === 'undefined') {
            return [];
        }

        if (typeof value === 'string') {
            return value.split(',');
        }

        return value;
    }

    static multipleQueryFormatter(value: string) {
        if (typeof value === 'undefined') {
            return undefined;
        }

        if (typeof value === 'string') {
            return value.split(',');
        }

        return value;
    }
}

export { CommonHelper };

import React from 'react';
import { useMutation } from '@apollo/client';

import { Modal } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { Loader } from '@modules/layout/moleculas';
import { useRecommendations } from '@modules/custom-components/hooks';
import {
    RecommendationList,
    EditRecommendationStepper,
} from '@modules/custom-components/organisms';
import {
    GetRecommendationsQuery,
    DeleteRecommendationMutation,
} from '@modules/custom-components/graphql';

import type { ModalProps } from '@modules/ui/core';
import type { RecommendationEntity } from '@modules/custom-components/entities';
import type {
    DeleteRecommendationMutationType,
    DeleteRecommendationMutationVariables,
} from '@modules/types/graphql';

type RecommendationEditModalProps = Omit<ModalProps, 'title' | 'children'> & {
    gapId: number;
};

const RecommendationEditModal = (props: RecommendationEditModalProps): React.ReactElement => {
    const { gapId, onClose, ...otherProps } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { recommendations, loading: recommendationsLoading } = useRecommendations({
        skip: !gapId,
        variables: { gapId },
    });

    const [deleteRecommendation, { loading: deleteRecommendationLoading }] = useMutation<
        DeleteRecommendationMutationType,
        DeleteRecommendationMutationVariables
    >(DeleteRecommendationMutation);

    const handleDeleteRecommendation = async (recommendationId: number): Promise<void> => {
        try {
            const { data: deleteRecommendationData } = await deleteRecommendation({
                variables: { recommendationId },
                refetchQueries: [{ query: GetRecommendationsQuery, variables: { gapId } }],
                awaitRefetchQueries: true,
            });

            if (deleteRecommendationData?.deleteRecommendation) {
                enqueueSuccess('Recommendation successfully deleted!');
            } else {
                enqueueError('An error occurred while deleting the recommendation!');
            }
        } catch (e) {
            console.log(e);
        }
    };

    const [
        recommendationForEdit,
        setRecommendationForEdit,
    ] = React.useState<RecommendationEntity | null>(null);

    const handleSetRecommendationForEdit = (recommendation: RecommendationEntity) =>
        setRecommendationForEdit(recommendation);

    return (
        <Modal title='Edit Recommendation' onClose={onClose} {...otherProps}>
            {recommendationsLoading ? (
                <Loader />
            ) : (
                <>
                    <RecommendationList
                        recommendations={recommendations}
                        loadingDelete={deleteRecommendationLoading}
                        onEdit={handleSetRecommendationForEdit}
                        onDelete={handleDeleteRecommendation}
                    />

                    {recommendationForEdit ? (
                        <EditRecommendationStepper
                            recommendation={recommendationForEdit}
                            gapId={gapId}
                            onCancel={onClose}
                        />
                    ) : null}
                </>
            )}
        </Modal>
    );
};

export { RecommendationEditModal };

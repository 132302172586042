import { gql } from '@apollo/client';

export const AddAssessmentCategoriesFragment = gql`
    fragment AssessmentCategories on AssessmentCategories {
        id
        name
        company_id
        is_rr_type
    }
`;

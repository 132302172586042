import { gql } from '@apollo/client';

export const AskTkoDetailFragment = gql`
    fragment AskTkoDetails on AskTkoDetails {
        id
        title
        description
        user_id
        parent_id
        query_id
        is_parent
        created_at
    }
`;

import { gql } from '@apollo/client';

export const GetOrganizationTypeQuery = gql`
    query listOrganizationTypes {
        listOrganizationTypes {
            id
            name
        }
    }
`;

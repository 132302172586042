import { EntityList } from '@lib/entity';
import { CapabilityLevelEntity } from './capability-level-entity';

import type { Entity } from './capability-level-entity';

class CapabilityLevelEntityList extends EntityList<CapabilityLevelEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(CapabilityLevelEntity, items, count);
    }
}

export { CapabilityLevelEntityList };

import { EntityList } from '@lib/entity';
import { ComponentAssessmentEntity } from './component-assessment-entity';

import type { Entity } from './component-assessment-entity';

class ComponentAssessmentEntityList extends EntityList<ComponentAssessmentEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(ComponentAssessmentEntity, items, count);
    }
}

export { ComponentAssessmentEntityList };

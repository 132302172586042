import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';

import type { QueryHookOptions } from '@apollo/client';
import type { GetProjectListNameQueryType } from '@modules/types/graphql';
import { GetProjectListQuery } from '../graphql/queries';
import { ProjectListNameEntityList } from '../entities';

const useProjectList = (options?: QueryHookOptions<GetProjectListNameQueryType>) => {
    const queryResult = useQuery<GetProjectListNameQueryType>(GetProjectListQuery, options);

    const { entities: projectListName, count } = useEntity(
        () => new ProjectListNameEntityList(queryResult.data?.getProjectAssignedUsers ?? null),
        [queryResult.data],
    );

    const result = {
        projectListName,
        count,
        ...queryResult,
    };

    return result;
};

export { useProjectList };

import React, { useState } from 'react';

import { ListStyled } from '@modules/layout/styled';
import { ReportRiskVIew } from '@modules/assessment/organisms/report-components-list/report-risk-view';
import { GapCapabilityAssessment } from '@modules/assessment/gap-by-risk';
import { ProjectEntity } from '@modules/projects/entities';
import { Loader } from '@modules/layout/moleculas';
import { AccordionInnerListItem, TableColumn } from '@modules/layout/organisms';
import styled from 'styled-components';
import { gray } from '@modules/ui/colors';

type ReportRiskVIewProps = {
    gapList: GapCapabilityAssessment[];
    project?: ProjectEntity;
    projectLoading?: boolean;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 16px;
    margin: 5px;
    background-color: ${gray[60]};
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);
`;


export const ReportRiskStatusVIew = (props: ReportRiskVIewProps): React.ReactElement => {
    const [expandedRow, setExpandedRow] = useState<string | null>(null);

    const onExpand = (riskLevel: string) => {
        if (expandedRow === riskLevel) {
            setExpandedRow(null);
        } else {
            setExpandedRow(riskLevel);
        }
    };

    const accordionTitle: TableColumn[] = [
        {
            id: 'pending',
            label: 'Pending',
        },
        {
            id: 'inProgress',
            label: 'In progress',
        },
        {
            id: 'done',
            label: 'Done',
        },
    ];

    function getGapList(items: GapCapabilityAssessment[], title: string) {
        return items.filter((ele: any) => ele.implementationStatus === title)
    }

    if (props.projectLoading) {
        return <Loader />;
    }

    return (
        <ListStyled.Container>
            {
                accordionTitle.map((title: any) =>
                (
                    <React.Fragment key={title.id}>
                        <Root>
                            <AccordionInnerListItem
                                title={title.label}
                                subtitle={''}
                                expanded={expandedRow === title.id}
                                onChange={() => onExpand(title.id)}
                                SummaryInfoComponents={<></>}
                            >
                                <ReportRiskVIew project={props?.project} gapList={getGapList(props.gapList, title.label)} />
                            </AccordionInnerListItem>
                        </Root>
                    </React.Fragment>
                )
                )
            }
        </ListStyled.Container>
    )
}
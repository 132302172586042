import { gql } from '@apollo/client';

export const AllComponentFragment = gql`
    fragment AllComponent on AllComponent {
        id
        name
        company_id
        assessment_type_id
    }
`;

import React from 'react';
import styled from 'styled-components';

import { Button, Dialog } from '@modules/ui/core';

type DialogRequirementDeleteProps = {
    disabled: boolean;
    onDelete: () => Promise<void>;
};

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const DialogRequirementDelete = (props: DialogRequirementDeleteProps): React.ReactElement => {
    const { onDelete, ...otherProps } = props;

    return (
        <Dialog
            title='Are you sure you want to delete this requirement?'
            acceptText='Delete'
            onAccept={onDelete}
            {...otherProps}
        >
            {dialogProps => {
                const { handleOpen } = dialogProps;

                return <SubmitButton onClick={handleOpen}>Delete</SubmitButton>;
            }}
        </Dialog>
    );
};

export { DialogRequirementDelete };

import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { ItemType } from '@modules/types/graphql';

export type Entity = Partial<ItemType>;

@DecribeEntity('ItemTypeEntity')
class ItemTypeEntity extends BaseEntity {
    id: number;
    name: string;

    constructor(itemType: Entity) {
        super(itemType);

        this.id = itemType?.id ?? -1;
        this.name = itemType?.name ?? '';
    }
}

export { ItemTypeEntity };

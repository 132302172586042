import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { Modal } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { Loader } from '@modules/layout/moleculas';
import { useCapabilityLevel } from '@modules/custom-components/hooks';
import { CapabilityLevelUpdateRequest } from '@modules/custom-components/requests';
import { CapabilityLevelForm } from '@modules/custom-components/organisms';
import {
    GetCapabilityLevelsQuery,
    UpdateCapabilityLevelMutation,
} from '@modules/custom-components/graphql';

import type { ModalProps } from '@modules/ui/core';
import type { CapabilityLevelUpdateFormValues } from '@modules/custom-components/requests';
import type {
    UpdateCapabilityLevelMutationType,
    UpdateCapabilityLevelMutationVariables,
} from '@modules/types/graphql';

type CapabilityLevelFormModalProps = Omit<ModalProps, 'title' | 'children'> & {
    capabilityLevelId: number;
    capabilityId: number;
};

const FormWrap = styled.div`
    width: 100%;
`;

const CapabilityLevelEditModal = (props: CapabilityLevelFormModalProps) => {
    const { capabilityLevelId, capabilityId, onClose, ...otherProps } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { capabilityLevel, loading: capabilityLevelLoading } = useCapabilityLevel({
        variables: { capabilityLevelId },
    });

    const [updateCapabilityLevel] = useMutation<
        UpdateCapabilityLevelMutationType,
        UpdateCapabilityLevelMutationVariables
    >(UpdateCapabilityLevelMutation, {
        ignoreResults: true,
    });

    const initialValues = {
        capabilityId,
        capabilityLevelId: capabilityLevel.id,
        capabilityLevelDefinitionId: capabilityLevel.getCapabilityLevelDefinitionId(),
        description: capabilityLevel.description,
    };

    const handleSubmit = async (values: CapabilityLevelUpdateFormValues) => {
        try {
            const variables = new CapabilityLevelUpdateRequest(values);

            const { data: updateCapabilityLevelData } = await updateCapabilityLevel({
                variables,
                refetchQueries: [{ query: GetCapabilityLevelsQuery, variables: { capabilityId } }],
                awaitRefetchQueries: true,
            });

            if (updateCapabilityLevelData?.updateCapabilityLevel?.id) {
                enqueueSuccess('Capability level successfully updated!');
                onClose();
            } else {
                enqueueError('An error occurred while updating capability level!');
            }
        } catch (e) {
            throw e;
        }
    };

    return (
        <Modal title='Capability Level' onClose={onClose} {...otherProps}>
            {capabilityLevelLoading ? (
                <Loader />
            ) : (
                <CapabilityLevelForm<'edit'>
                    type='edit'
                    initialValues={initialValues}
                    FormWrapComponent={FormWrap}
                    onSubmit={handleSubmit}
                    onCancel={onClose}
                />
            )}
        </Modal>
    );
};

export { CapabilityLevelEditModal };

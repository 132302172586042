import React from 'react';
import styled from 'styled-components';

import { Button } from '@modules/ui/core';
import { useCurrentUser } from '@modules/auth/hooks';
import { DialogCapabilityLevelDelete } from '@modules/custom-components/moleculas';

import type { CapabilityLevelEntity } from '@modules/custom-components/entities';

type CapabilityLevelListItemProps = {
    capabilityLevel: CapabilityLevelEntity;
    choosen: boolean;
    deleteLoading: boolean;
    onClickDetails: (id: number) => void;
    onClickEdit: (id: number) => void;
    onDelete: (id: number) => Promise<void>;
    withoutDetails?: boolean;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-height: 175px;

    > * {
        width: 100%;

        &:not(:last-of-type) {
            margin-bottom: 12px;
        }
    }
`;

const Title = styled.div`
    font-size: 1.6rem;
    font-weight: 700;
`;

const Content = styled.div`
    padding-right: 16px;
    font-size: 1.4rem;
    line-height: 22px;
`;

const Actions = styled.div`
    flex: 1;
    margin-top: auto;

    > * {
        &:not(:last-of-type) {
            margin-right: 8px;
        }
    }
`;

const CapabilityLevelListItem = (props: CapabilityLevelListItemProps): React.ReactElement => {
    const {
        capabilityLevel,
        choosen,
        deleteLoading,
        onClickDetails,
        onClickEdit,
        onDelete,
        withoutDetails,
    } = props;

    const { currentUser } = useCurrentUser();

    const handleClickDetails = (): void => onClickDetails(capabilityLevel.id);
    const handleClickEdit = (): void => onClickEdit(capabilityLevel.id);
    const handleClickDelete = async (): Promise<void> => onDelete(capabilityLevel.id);

    const canEditCapabilityLevel = capabilityLevel.isPredefined()
        ? currentUser.hasSuperAdminPermission() || currentUser.hasAdminPermission()
        : true;

    return (
        <Root>
            <Title>{capabilityLevel.name}</Title>
            <Content>{capabilityLevel.description}</Content>

            <Actions>
                {/* {withoutDetails ? null : (
                    <Button
                        size='small'
                        variant={choosen ? 'contained' : 'outlined'}
                        onClick={handleClickDetails}
                    >
                        Details
                    </Button>
                )} */}

                {canEditCapabilityLevel ? (
                    <>
                        <Button size='small' onClick={handleClickEdit}>
                            Edit
                        </Button>

                        {/* <DialogCapabilityLevelDelete
                            disabled={deleteLoading}
                            onDelete={handleClickDelete}
                        /> */}
                    </>
                ) : null}
            </Actions>
        </Root>
    );
};

export { CapabilityLevelListItem };

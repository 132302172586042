import { gql } from '@apollo/client';

import { RoadmapRecommendationRefinementFragment } from '../fragments';

export const RejectRecommendationMutation = gql`
    mutation RejectRecommendation($roadmapRecommendationId: Int!, $notes: String) {
        rejectRecommendation(roadmap_recommendation_id: $roadmapRecommendationId, notes: $notes) {
            ...RoadmapRecommendationRefinement
        }
    }
    ${RoadmapRecommendationRefinementFragment}
`;

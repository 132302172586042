import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { ListAssessmentCategoriesEntityList } from '@modules/custom-components/entities';

import type { QueryHookOptions } from '@apollo/client';
import type { ListAssessmentCategoriesQueryType } from '@modules/types/graphql';
import { ListAssessmentCategoriesQuery } from '../graphql/queries';

const useListAssessmentCategories = (
    options?: QueryHookOptions<ListAssessmentCategoriesQueryType>,
) => {
    const queryResult = useQuery<ListAssessmentCategoriesQueryType>(
        ListAssessmentCategoriesQuery,
        options,
    );

    const { entities: listAssessmentCategories, count } = useEntity(
        () =>
            new ListAssessmentCategoriesEntityList(
                queryResult.data?.listAssessmentCategories ?? null,
            ),
        [queryResult.data],
    );

    const result = {
        listAssessmentCategories,
        count,
        ...queryResult,
    };

    return result;
};

export { useListAssessmentCategories };

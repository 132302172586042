import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import type { QueryHookOptions } from '@apollo/client';
import type {
    GetImplementationTaskQueryType,
    GetImplementationTaskQueryVariables,
} from '@modules/types/graphql';
import { ImplementationTaskEntityList } from '../entities/implementation-task-entity-list';
import { GetImplementationTaskQuery } from '../graphql';

const useImplementationTasks = (
    options?: QueryHookOptions<GetImplementationTaskQueryType, GetImplementationTaskQueryVariables>,
) => {
    const queryResult = useQuery<
        GetImplementationTaskQueryType,
        GetImplementationTaskQueryVariables
    >(GetImplementationTaskQuery, options);

    const { entities: implementationTask, count } = useEntity(
        () => new ImplementationTaskEntityList(queryResult.data?.listImplementationTask ?? null),
        [queryResult.data],
    );

    const result = {
        implementationTask,
        count,
        ...queryResult,
    };

    return result;
};

export { useImplementationTasks };

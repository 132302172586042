import React from 'react';
import { withStyles, MenuItem, FormControlLabel, IconButton, Collapse } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import { TextField } from '@modules/ui/core';
import { white } from '@modules/ui/colors';
import { TableCheckbox, TableCellText } from '@modules/layout/atoms';
import { TableRow, TableCell } from '@modules/layout/organisms';
import { weights } from '@modules/components/constants';
import { CapabilityLevelList } from '@modules/custom-components/organisms';

import type { CapabilityEntity } from '@modules/capabilities/entities';
import type { CapabilityInput } from '@modules/types/graphql';

type CapabilitiesListFormGroupItemProps = {
    capabilitiesAssessmentInput: (CapabilityInput | null)[];
    componentCapability: CapabilityEntity;
    onChangeCheckbox: (event: React.ChangeEvent<{ name: string; value: string }>) => void;
    onChangeWeight: (event: React.ChangeEvent<{ name: string; value: string }>) => void;
};

const StyledCollapse = withStyles({
    container: {
        padding: '8px 16px',
    },
})(Collapse);

const TextFieldWeight = withStyles({
    root: {
        backgroundColor: white[100],
    },
})(TextField);

const arrowDownIcon = <KeyboardArrowDown style={{ fontSize: 24 }} />;
const arrowUpIcon = <KeyboardArrowUp style={{ fontSize: 24 }} />;

const CapabilitiesListFormGroupItem = (
    props: CapabilitiesListFormGroupItemProps,
): React.ReactElement => {
    const {
        capabilitiesAssessmentInput,
        componentCapability,
        onChangeCheckbox,
        onChangeWeight,
    } = props;

    const [open, setOpen] = React.useState(false);

    const handleToggleOpen = (): void => setOpen(state => !state);

    const foundCapabilityAssessmentInput =
        capabilitiesAssessmentInput.find(
            capabilityAssessmentInput =>
                componentCapability.id === capabilityAssessmentInput?.capability_id,
        ) ?? null;

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton aria-label='expand row' onClick={handleToggleOpen}>
                        {!open ? arrowDownIcon : arrowUpIcon}
                    </IconButton>
                </TableCell>

                <TableCell width={70}>
                    <FormControlLabel
                        control={
                            <TableCheckbox
                                name='id'
                                checked={!!foundCapabilityAssessmentInput}
                                onChange={onChangeCheckbox}
                            />
                        }
                        label={<TableCellText>{componentCapability.title}</TableCellText>}
                    />
                </TableCell>

                <TableCell>
                    <TableCellText>{componentCapability.keyFactors}</TableCellText>
                </TableCell>

                <TableCell width={100}>
                    <TextFieldWeight
                        fullWidth
                        select
                        id='weight'
                        name='weight'
                        label='Weight'
                        value={foundCapabilityAssessmentInput?.weight ?? 1}
                        onChange={onChangeWeight}
                    >
                        <MenuItem disabled>Pick</MenuItem>

                        {weights.map(weight => (
                            <MenuItem key={weight} value={weight}>
                                {weight}
                            </MenuItem>
                        ))}
                    </TextFieldWeight>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ padding: 0, height: 'initial' }} colSpan={4}>
                    <StyledCollapse unmountOnExit timeout='auto' in={open}>
                        <CapabilityLevelList
                            withoutDetails
                            capability={componentCapability}
                            capabilityId={componentCapability.id}
                        />
                    </StyledCollapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export { CapabilitiesListFormGroupItem };

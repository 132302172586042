import { useQuery } from '@apollo/client';
import type { QueryHookOptions } from '@apollo/client';
import { GetListAssetTypeQuery } from '../graphql';
import { AssetTypeEntityList } from '../entities';
import { useEntity } from '@lib/entity';

const useAssetType = (options?: QueryHookOptions) => {
    const queryResult = useQuery(GetListAssetTypeQuery, options);

    const { entities: assetTypeData, count } = useEntity(
        () => new AssetTypeEntityList(queryResult.data?.listAssetType ?? null),
        [queryResult.data],
    );

    const result = {
        assetTypeData,
        count,
        ...queryResult,
    };

    return result;
};

export { useAssetType };

import { EntityList } from '@lib/entity';
import type { Entity } from './learns-tko-entity';
import { LearnsTkoEntity } from './learns-tko-entity';

class LearnsTkoEntityList extends EntityList<LearnsTkoEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(LearnsTkoEntity, items, count);
    }
}

export { LearnsTkoEntityList };

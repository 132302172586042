import { createCustomError } from '@lib/errors/create-custom-error';

import type { CreateComponentMutationVariables } from '@modules/types/graphql';

export type CustomComponentCreateFormValues = {
    name: string;
    assessmentTypeId: number;
    companyId?: number;
};

class CustomComponentCreateRequest {
    name: CreateComponentMutationVariables['name'];
    assessmentTypeId: CreateComponentMutationVariables['assessmentTypeId'];
    companyId: CreateComponentMutationVariables['companyId'];

    constructor(values: CustomComponentCreateFormValues) {
        if (!values.assessmentTypeId) {
            throw createCustomError('bad assessment type id');
        }

        this.name = values.name;
        this.assessmentTypeId = values.assessmentTypeId;
        this.companyId = values.companyId;
    }
}

export { CustomComponentCreateRequest };

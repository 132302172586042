import { EntityList } from '@lib/entity';

import type { UserDetEntity } from './user-details-entity';
import { UserDetailsEntity } from './user-details-entity';

class UserDetailsEntityList extends EntityList<UserDetailsEntity> {
    constructor(items: UserDetEntity[] | null, count?: number | null) {
        super(UserDetailsEntity, items, count);
    }
}

export { UserDetailsEntityList };

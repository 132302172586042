import { useState } from 'react';
import { ChatGptInstance } from './chat-gpt-instance';

const useChatTaskSubTask = () => {
    const [response, setResponse] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const fetchResponse = async (prompt: string) => {
        setLoading(true);
        try {
            let result = undefined;
            const completion: any = await ChatGptInstance.createChatCompletion({
                model: 'gpt-4o',
                messages: [{ role: 'user', content: prompt }],
                temperature: 0.7,
                max_tokens: 3097,
            });
            const dataArray: any = completion?.data?.choices[0]?.message?.content;
            const startIndex = dataArray.indexOf("1.");
            const lastIndex = dataArray.lastIndexOf("\n\n");
            result = dataArray.substring(startIndex, lastIndex);
            const itemsArray = result.split(/\n\n+/);
            if (itemsArray) {
                const newData = itemsArray?.map((ele: any) => ele?.replace(/^\d+\.\s*|\n/gm, ''));
                setResponse(newData || []);
                setLoading(false);
            }
        } catch (err) {
            setError(err);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };
    return [response, loading, error, fetchResponse];
};

export { useChatTaskSubTask };

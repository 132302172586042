import { gql } from '@apollo/client';

import { ImplementationTaskSingleFragment } from '../fragments';

export const GetRecommendationImpTasksQuery = gql`
    query GetRecommendationImpTasks($recommendationId: Int!) {
        getRecommendationImpTasks(recommendation_id: $recommendationId) {
            ...ImplementationTaskSingle
        }
    }
    ${ImplementationTaskSingleFragment}
`;

import { gql } from '@apollo/client';

import { UserFragment } from '@modules/users/graphql';
import { ComponentRecommendationFragment } from '@modules/roadmap/graphql';

export const GetImplementationReportQuery = gql`
    query GetImplementationReport($id: Int!) {
        getAssignedProject(id: $id) {
            id
            name
            description
            status
            company_id
            assessment_type_id
            is_risk_registry
            roadmap_status
            maturity_level {
                id
                name
            }
            stakeholder {
                ...User
            }
            program_manager {
                ...User
            }
            assessments {
                id
                status
                roadmap_status
                weight
                objectives
                maturity_level {
                    id
                    name
                }
                component {
                    id
                    name
                }
                component_owners {
                    id
                    first_name
                    last_name
                }
                operators {
                    id
                    first_name
                    last_name
                }
                capabilities {
                    id
                    capability {
                        id
                        title
                        security_controls
                    }
                    scored_gaps {
                        id
                        roadmap_recommendations {
                            id
                        }
                    }
                }
            }
        }

        getAssessmentReport(project_id: $id) {
            program_manager {
                id
                first_name
                last_name
            }
            prioritized_roadmap {
                project_id
                super_critical_recommendations {
                    ...ComponentRecommendation
                }
                critical_recommendations {
                    ...ComponentRecommendation
                }
                high_critical_recommendations {
                    ...ComponentRecommendation
                }
                high_recommendations {
                    ...ComponentRecommendation
                }
                medium_high_recommendations {
                    ...ComponentRecommendation
                }
                medium_recommendations {
                    ...ComponentRecommendation
                }
                low_medium_recommendations {
                    ...ComponentRecommendation
                }                
                low_recommendations {
                    ...ComponentRecommendation
                }
                minimal_low_recommendations {
                    ...ComponentRecommendation
                }
                minimal_recommendations {
                    ...ComponentRecommendation
                }                              
            }
        }
    }
    ${UserFragment}
    ${ComponentRecommendationFragment}
`;

import { gql } from '@apollo/client';

import { CompanyFragment } from '../fragments';

export const CreateCompanyMutation = gql`
    mutation CreateCompany($name: String!) {
        createCompany(name: $name) {
            ...Company
        }
    }
    ${CompanyFragment}
`;

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { getColorAndTitleByScore } from '@modules/shared/constants/score-variant-colors';

const Root = styled.div`
    display: flex;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    background: #fff;

    > * {
        padding: 8px 16px;
    }
`;

const Progress = styled.div<{ color?: string }>`
    font-size: 1.4rem;
    border-radius: 4px 0 0 4px;

    ${props => css`
        background: ${props.color};
    `}
`;

const Label = styled.div`
    text-transform: capitalize;
`;

const AssetScoreLabel = (props: any): React.ReactElement => {
    const { score, type } = props;
    const { color, title } = getColorAndTitleByScore(score, type);

    return (
        <Root>
            <Progress color={color} />
            <Label>{title}</Label>
        </Root>
    );
};

export { AssetScoreLabel };

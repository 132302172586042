import { gql } from '@apollo/client';

export const LearnCardMenuFragment = gql`
    fragment MenuCards on MenuCards {
        id
        menu_title
        description
        image_url
        created_at
    }
`;

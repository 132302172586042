import { gql } from '@apollo/client';

import { AddAssessmentCategoriesFragment } from '../fragments';

export const GetAssessmentCategoriesByIdQuery = gql`
    query GetAssessmentCategories($id: Int!) {
        getAssessmentCategories(id: $id) {
            ...AssessmentCategories
        }
    }
    ${AddAssessmentCategoriesFragment}
`;

import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Button, Modal, TextField } from '@modules/ui/core';
import type { ModalProps } from '@modules/ui/core';
import * as yup from 'yup';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { MenuItem, makeStyles } from '@material-ui/core';
import { gray } from '@modules/ui/colors';
import { useUsers } from '@modules/users/hooks';
import { useMutation } from '@apollo/client';
import { AssignRiskExecutives, GetRiskRegistryUsersQuery } from '@modules/registry/graphql';
import { useEnqueueStacks } from '@modules/layout/hooks';

const StyledModal = styled(Modal)`
    max-width: 600px;
`;

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }

    .is-select {
        width: 536px;
        background-color: ${gray[90]};

        .MuiInputBase-root > div {
            display: initial;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const EditRiskExecutivesModal = (
    props: ModalProps & { initialValues: Record<string, any> },
): React.ReactElement => {
    const { onClose, initialValues, ...otherProps } = props;
    const { users, loading: usersLoading } = useUsers();
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [assignRiskExecutives] = useMutation(AssignRiskExecutives);

    return (
        <StyledModal title='Risk executives' onClose={onClose} {...otherProps}>
            <ExtendedFormik
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={yup.object({
                    riskExecutives: yup.array().required('Required field'),
                })}
                initialValues={initialValues}
                onSubmit={async values => {
                    try {
                        const { data: assignRiskExecutivesData } = await assignRiskExecutives({
                            variables: { riskExecutives: values.riskExecutives },
                            refetchQueries: [{ query: GetRiskRegistryUsersQuery }],
                            awaitRefetchQueries: true,
                        });

                        if (assignRiskExecutivesData) {
                            enqueueSuccess('Risk managers successfully updated!');
                            onClose();
                        } else {
                            enqueueError('An error occurred while updating risk managers!');
                            onClose();
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }}
            >
                {formikProps => {
                    const { values, errors, handleChange, handleBlur, isSubmitting } = formikProps;

                    return (
                        <StyledForm>
                            <TextField
                                select
                                name='riskExecutives'
                                label='Risk executives'
                                className='is-select'
                                value={values.riskExecutives}
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{
                                    multiple: true,
                                    renderValue: (value: any) => {
                                        const values = users.filter(user =>
                                            value.includes(user?.id),
                                        );
                                        return values
                                            .map(user => user?.firstName + ' ' + user?.lastName)
                                            .join(', ');
                                    },
                                }}
                                onChange={handleChange}
                            >
                                {users ? (
                                    users.map(user => {
                                        const { firstName, id, lastName } = user;
                                        return (
                                            <MenuItem key={id} value={id}>
                                                {firstName} {lastName}
                                            </MenuItem>
                                        );
                                    })
                                ) : (
                                    <MenuItem key={0} value={''}>
                                        Select executive(s)
                                    </MenuItem>
                                )}
                            </TextField>

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    Save
                                </SubmitButton>

                                <SubmitButton
                                    variant='text'
                                    disabled={isSubmitting}
                                    onClick={onClose}
                                >
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    );
                }}
            </ExtendedFormik>
        </StyledModal>
    );
};

export { EditRiskExecutivesModal };

import { gql } from '@apollo/client';

export const SetRecommendationImpSubTaskMutation = gql`
    mutation SetRecommendationImpSubTask(
        $recommendationId: Int!
        $taskId: Int!
        $subTaskId: [Int!]!
    ) {
        setRecommendationImpSubTask(
            recommendation_id: $recommendationId
            task_id: $taskId
            sub_task_id: $subTaskId
        ) {
            id
        }
    }
`;

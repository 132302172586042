import { gql } from '@apollo/client';
import { LearnTkoDetailsFragment } from '../fragments';

export const GetAllLearnTkoDetailQuery = gql`
    query GetAllLearnTkoDetail {
        getAllLearnTkoDetail {
            ...LearnTkoDetails
        }
    }
    ${LearnTkoDetailsFragment}
`;

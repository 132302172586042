import { EntityList } from '@lib/entity';
import { UserEntity } from './user-entity';

import type { Entity } from './user-entity';

class UserEntityList extends EntityList<UserEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(UserEntity, items, count);
    }
}

export { UserEntityList };

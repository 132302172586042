import { useState } from 'react';

const useAccordion = (initialIndex: any) => {
    const [activeIndex, setActiveIndex] = useState<undefined | any>(initialIndex);

    const toggleAccordion = (index: any) =>
        setActiveIndex((prevIndex: any) => (prevIndex === index ? null : index));

    return { activeIndex, toggleAccordion };
};

export { useAccordion };

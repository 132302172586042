import { gql } from '@apollo/client';

import { ImplementationSubTaskSingleFragment } from '../fragments';

export const GetRecommendationImpSubTasksQuery = gql`
    query GetRecommendationImpSubTasks($taskId: Int!) {
        getRecommendationImpSubTasks(task_id: $taskId) {
            ...SubTaskSingle
        }
    }
    ${ImplementationSubTaskSingleFragment}
`;

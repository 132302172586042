import { useQuery } from '@apollo/client';
import { GetDistinctRolesQuery } from '@modules/shared/graphql';
import type { QueryHookOptions } from '@apollo/client';

const useDistinctRoles = (options?: QueryHookOptions) => {
    const { data, loading } = useQuery(GetDistinctRolesQuery, options);

    const result = {
        roles: data?.getDistinctRoles,
        loading,
    };

    return result;
};

export { useDistinctRoles };

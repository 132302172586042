import { EntityList } from '@lib/entity';
import { AssetCategoryEntity } from './asset-category-entity';

import type { Entity } from './asset-category-entity';


class AssetCategoryEntityList extends EntityList<AssetCategoryEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(AssetCategoryEntity, items, count);
    }
}

export { AssetCategoryEntityList };

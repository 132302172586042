import { EntityList } from '@lib/entity';

import type { Entity } from './company-entity';
import { CompanyDetailsEntity } from './company-details-entity';

class CompanyDetailsEntityList extends EntityList<CompanyDetailsEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(CompanyDetailsEntity, items, count);
    }
}

export { CompanyDetailsEntityList };

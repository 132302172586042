import React from 'react';

import { Modal } from '@modules/ui/core';

import type { ModalProps } from '@modules/ui/core';
import { CustomAssessmentTypeEditForm } from '@modules/custom-components/organisms/custom-assessment-type-create-form';
import { GetAssessmentTypesQuery } from '@modules/shared/graphql';

type CustomComponentCreateModalProps = Omit<ModalProps, 'title' | 'children'> & {
    companyId?: number;
    type: string;
    assessmentId?: number;
    GetAssessmentTypesQuery?: any;
};

const CustomAssessmentTypeEditModal = (
    props: CustomComponentCreateModalProps,
): React.ReactElement => {
    const { companyId, type, assessmentId, onClose, ...otherProps } = props;

    return (
        <Modal title={'Edit Assessment Type Name'} onClose={onClose} {...otherProps}>
            <CustomAssessmentTypeEditForm
                GetAssessmentTypesQuery={GetAssessmentTypesQuery}
                assessmentId={assessmentId}
                type={type}
                companyId={companyId}
                onCancel={onClose}
            />
        </Modal>
    );
};

export { CustomAssessmentTypeEditModal };

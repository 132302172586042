import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useParams, useNavigate } from '@reach/router';

import { routes } from '@config/routes';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { PageStyled } from '@modules/layout/styled';

import type { Page } from '@modules/types/page';
import type {
    UpdateLearnTkoDetailsMutationType,
    UpdateLearnTkoDetailsMutationVariables,
} from '@modules/types/graphql';
import { LearnTkoForm } from '@modules/learn-tko/organisms';
import { useSingleLearnTko } from '@modules/learn-tko/hooks';
import {
    GetAllLearnTkoDetailQuery,
    UpdateLearnTkoDetailsMutation,
} from '@modules/learn-tko/graphql';

const Main = styled(PageStyled.Main)`
    margin-top: 48px;
`;

const FormWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-width: 625px;
`;

const EditLearnTko = (props: Page): React.ReactElement => {
    const { title = 'Edit Learn Tko' } = props;

    const navigate = useNavigate();
    const params = useParams();
    const learnTkoId = Number(params.id);
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [editorDescription, setEditorDescription] = useState<string | any>('');

    const { singleLearnTko, loading } = useSingleLearnTko({
        variables: { learnTkoId: learnTkoId },
    });

    const [updateLearnTkoDetail] = useMutation<
        UpdateLearnTkoDetailsMutationType,
        UpdateLearnTkoDetailsMutationVariables
    >(UpdateLearnTkoDetailsMutation);

    const initialValues: any = {
        menu: singleLearnTko?.menu,
        title: singleLearnTko?.title,
        description: editorDescription,
        video_url: singleLearnTko?.videoUrl,
    };

    const handleSubmit = async (values: any): Promise<void> => {
        try {
            const { data: updateLearnTkoDetailData } = await updateLearnTkoDetail({
                variables: {
                    learnTkoId: learnTkoId,
                    menu: values.menu,
                    title: values.title,
                    description: values.description,
                    videoUrl: 'testt',
                },
                refetchQueries: [{ query: GetAllLearnTkoDetailQuery }],
                awaitRefetchQueries: true,
            });
            if (updateLearnTkoDetailData?.updateLearnTkoDetail?.id) {
                enqueueSuccess('Learn tko successfully updated!');
                navigate(routes.viewLearnTko.path);
            } else {
                enqueueError('An error occurred while updating the learn tko!');
            }
        } catch (e) {
            throw e;
        }
    };

    useEffect(() => {
        setEditorDescription(singleLearnTko?.description);
    }, [singleLearnTko]);

    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>{title}</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>

            <Main>
                <LearnTkoForm
                    type='edit'
                    initialValues={initialValues}
                    FormWrapComponent={FormWrap}
                    onSubmit={handleSubmit}
                    editorDescription={editorDescription}
                    setEditorDescription={setEditorDescription}
                    loading={loading}
                />
            </Main>
        </>
    );
};

export { EditLearnTko };

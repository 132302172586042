import React from 'react';
import { Form } from 'formik';
import styled from 'styled-components';

import { TextField, Button, Modal } from '@modules/ui/core';
import { FormStyled } from '@modules/layout/styled';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { ExtendedFormik } from '@modules/layout/organisms';

import type { RecommendationModifyFormValues } from '@modules/components/requests';
import type { ModalProps } from '@modules/ui/core';

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const FormWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-width: 625px;
    width: 100%;
`;

const RecommendationModifyFormModal = (
    props: Omit<ModalProps, 'title' | 'children'>,
): React.ReactElement => {
    const { onClose, ...otherProps } = props;

    const { enqueueSuccess } = useEnqueueStacks();

    const initialValues: RecommendationModifyFormValues = {
        constraints: '',
        dependencies: '',
        notes: '',
        recommendation: 'Define and capture how the Program will be managed',
    };

    return (
        <Modal title='Modify Recommendation' onClose={onClose} {...otherProps}>
            <FormWrap>
                <ExtendedFormik<RecommendationModifyFormValues>
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={initialValues}
                    onSubmit={async values => {
                        enqueueSuccess('Saved');
                        onClose();
                    }}
                >
                    {formikProps => {
                        const { values, errors, handleChange, isSubmitting } = formikProps;

                        return (
                            <StyledForm>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    id='recommendation'
                                    name='recommendation'
                                    label='Recommendation'
                                    error={!!errors.recommendation}
                                    helperText={errors.recommendation}
                                    value={values.recommendation}
                                    onChange={handleChange}
                                />

                                <TextField
                                    fullWidth
                                    id='constraints'
                                    name='constraints'
                                    label='Constraints'
                                    error={!!errors.constraints}
                                    helperText={errors.constraints}
                                    value={values.constraints}
                                    onChange={handleChange}
                                />

                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    id='dependencies'
                                    name='dependencies'
                                    label='Dependencies'
                                    error={!!errors.dependencies}
                                    helperText={errors.dependencies}
                                    value={values.dependencies}
                                    onChange={handleChange}
                                />

                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    id='notes'
                                    name='notes'
                                    label='Notes'
                                    error={!!errors.notes}
                                    helperText={errors.notes}
                                    value={values.notes}
                                    onChange={handleChange}
                                />

                                <FormStyled.FormRowSubmit>
                                    <SubmitButton type='submit' loading={isSubmitting}>
                                        Modify
                                    </SubmitButton>

                                    <SubmitButton
                                        variant='text'
                                        disabled={isSubmitting}
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </SubmitButton>
                                </FormStyled.FormRowSubmit>
                            </StyledForm>
                        );
                    }}
                </ExtendedFormik>
            </FormWrap>
        </Modal>
    );
};

export { RecommendationModifyFormModal };

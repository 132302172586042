import { gql } from '@apollo/client';

import { AddAssessmentTypeFragment } from '../fragments';

export const AddAssessmentTypeMutation = gql`
    mutation AddAssessmentType($name: String!, $isRRType: Boolean!, $assessmentCategoriesId: Int!) {
        addAssessmentType(
            name: $name
            is_rr_type: $isRRType
            assessment_categories_id: $assessmentCategoriesId
        ) {
            ...AssessmentType
        }
    }
    ${AddAssessmentTypeFragment}
`;

import { createCustomError } from '@lib/errors';

import type { CompanyEntity } from '@modules/company/entities';
import type { UpdateUserMutationVariables } from '@modules/types/graphql';

export type UpdateUserFormValues = {
    id: number;
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    isAdmin: boolean;
    company: CompanyEntity | null;
    userRole: any;
    schemaName: string;
};

class UpdateUserRequest {
    id: UpdateUserMutationVariables['id'];
    firstName: UpdateUserMutationVariables['firstName'];
    lastName: UpdateUserMutationVariables['lastName'];
    isAdmin: UpdateUserMutationVariables['isAdmin'];
    companyId: UpdateUserMutationVariables['companyId'];
    userRole: UpdateUserMutationVariables['userRole'];
    schemaName: UpdateUserMutationVariables['schemaName'];

    constructor(values: UpdateUserFormValues) {
        if (!values.company) {
            throw createCustomError('Incorrect company');
        }

        this.id = values.id;
        this.firstName = values.firstName;
        this.lastName = values.lastName;
        this.isAdmin = values.isAdmin;
        this.companyId = values.company.id;
        this.userRole = values.userRole?.name;
        this.schemaName = values.schemaName;
    }
}

export { UpdateUserRequest };

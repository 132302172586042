import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useNavigate } from '@reach/router';

import { black, gray, blue } from '@modules/ui/colors';
import { getMenuRoutes } from '@lib/routing';
import { RouteMenuLocationEnum } from '@config/routes';
import { useCurrentUser } from '@modules/auth/hooks';
import type { LinkGetProps } from '@reach/router';
import { Button, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { useUserHighRole } from '@modules/assessment/hooks/use-user-high-role';
import { Loader } from '../loader';
import { Skeleton } from '@material-ui/lab';
import { useCompanyAccess } from '@modules/company/hooks';

const itemActiveStyles = {
    backgroundColor: '#fff',
    pointerEvents: 'none',
    cursor: 'default',
    borderBottom: `4px solid ${blue[100]}`,
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > *:not(:last-of-type) {
        margin-right: 8px;
    }
`;

const Item = styled(Link)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    height: 60px;
    /* color: ${black[100]}; */
    font-size: 1.4rem;
    font-weight: bold;
    background-color: transparent;
    transition: background-color 0.2s;
    border-bottom: 4px solid transparent;

    &:hover {
        background-color: ${gray[50]};
    }
`;

const useStyles = makeStyles({
    resourcesButton: {
        background: 'none',
        color: '#000',
        padding: '18px 24px',
        borderRadius: '0px',
        '&:hover': {
            background: '#E0E0E0',
        },
    },
    resourcesMenu: {
        position: 'absolute',
        top: '52px !important',
        padding: '0px !important',
    },
    resourcesMenuItem: {
        padding: '15px 26px',
        fontSize: '1.5rem',
    },
    resourcesMenuItemDisable: {
        padding: '15px 26px',
        fontSize: '1.5rem',
        color: 'gray',
        cursor: 'not-allowed !important',
    },
    disableRR: {
        pointerEvents: 'none',
        cursor: 'not-allowed !important',
        color: 'gray !important',
    },
});

const HeaderMenu = (): React.ReactElement | null => {
    const { currentUser } = useCurrentUser();
    const isSuperAdmin: boolean = currentUser.isSuperAdmin;
    const { userHighRolesEntityList, loading: userLoding } = useUserHighRole();
    const { companyAccess, loading: companyLoading } = useCompanyAccess();

    const location = useLocation();
    const userRole = React.useMemo(() => {
        const role = userHighRolesEntityList[0]?.welcomeRole;
        const roledata = role === 'OP' || role === 'CO';

        return roledata;
    }, [userHighRolesEntityList]);

    const classes = useStyles();
    const navigate = useNavigate();

    const menu = React.useMemo(
        () =>
            getMenuRoutes(RouteMenuLocationEnum.header, {
                isAdmin: currentUser.hasAdminPermission(),
                isSuperAdmin: currentUser.hasSuperAdminPermission(),
                CO: userRole,
                OP: userRole,
            }),
        [currentUser, userRole],
    );
    const role = userHighRolesEntityList[0]?.welcomeRole;
    const roledata = role === 'SA';
    let isFreeze: boolean = companyAccess?.isFreeze;
    const isRRAccess = () => {
        let rrAccess = companyAccess?.isRRAccess;
        let isRR = menu?.find((ele: any) => ele?.path === '/registry')?.path;
        if (rrAccess && isRR) {
            return (rrAccess = true);
        } else {
            return (rrAccess = false);
        }
    };

    const isRR = isRRAccess();

    const isCurrent = React.useCallback(
        (linkProps: LinkGetProps) => ({
            style: linkProps.isCurrent ? itemActiveStyles : {},
        }),
        [],
    );

    const [toggleSubMenu, setToggleSubMenu] = React.useState<null | HTMLElement>(null);
    const open = Boolean(toggleSubMenu);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setToggleSubMenu(event.currentTarget);
    };
    const handleClose = () => {
        setToggleSubMenu(null);
    };

    const handleAskTko = () => {
        handleClose();
        navigate('/resources/ask-tko');
    };
    const handleTutorials = () => {
        handleClose();
        navigate('/resources/learn-tko');
    };

    const menuLength = menu.length - 1;
    const skeletons = Array.from({ length: menuLength }, (_, index) => (
        <Skeleton
            key={index}
            style={{ background: 'grey.900', marginRight: '8px', borderRadius: '5px' }}
            variant={'rect'}
            width={130}
            height={30}
            animation='wave'
        />
    ));
    const isRegistry: any = location?.pathname === '/registry';

    if (menu?.length === 0) {
        return null;
    }

    if (userLoding || companyLoading) {
        return <>{skeletons}</>;
    }

    return (
        <Root>
            {/* {menu.map(item => (
                <Item key={item.name} to={item.path} getProps={isCurrent}>
                    {item.title}
                </Item>
            ))} */}

            {menu?.map(item => {
                const rr = item?.path === '/registry';
                return (
                    <React.Fragment key={item.name}>
                        {item.title !== 'Resources' ? (
                            <Item
                                to={item.path}
                                getProps={isCurrent}
                                className={
                                    rr && !isSuperAdmin ? (isRR ? '' : classes.disableRR) : ''
                                }
                            >
                                {item.title}
                            </Item>
                        ) : (
                            <div style={{ position: 'relative' }}>
                                <Button
                                    id='resources-button'
                                    aria-controls={open ? 'resources-menu' : undefined}
                                    aria-haspopup='true'
                                    aria-expanded={!open ? 'true' : undefined}
                                    onClick={handleClick}
                                    disableElevation
                                    disableRipple
                                    className={classes.resourcesButton}
                                >
                                    {item.title}
                                </Button>
                                <Menu
                                    id='resources-menu'
                                    anchorEl={toggleSubMenu}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'resources-button',
                                    }}
                                    classes={{ paper: classes.resourcesMenu }}
                                >
                                    <MenuItem
                                        onClick={() =>
                                            companyAccess?.isLearnTko || roledata
                                                ? handleTutorials()
                                                : undefined
                                        }
                                        className={
                                            !isFreeze
                                                ? companyAccess?.isLearnTko || roledata
                                                    ? classes.resourcesMenuItem
                                                    : classes.resourcesMenuItemDisable
                                                : classes.resourcesMenuItemDisable
                                        }
                                    >
                                        Learn TKO
                                    </MenuItem>

                                    <MenuItem
                                        onClick={() =>
                                            companyAccess?.isAskTko || roledata
                                                ? handleAskTko()
                                                : undefined
                                        }
                                        className={
                                            !isFreeze
                                                ? companyAccess?.isAskTko || roledata
                                                    ? classes.resourcesMenuItem
                                                    : classes.resourcesMenuItemDisable
                                                : classes.resourcesMenuItemDisable
                                        }
                                    >
                                        Ask TKO
                                    </MenuItem>
                                </Menu>
                            </div>
                        )}
                    </React.Fragment>
                );
            })}
        </Root>
    );
};

export { HeaderMenu };

import React from 'react';
import { useParams, Redirect } from '@reach/router';

import { createRouteUrl } from '@lib/routing';
import { routes } from '@config/routes';
import { PageStyled } from '@modules/layout/styled';
import { Loader } from '@modules/layout/moleculas';
import { ProjectHeader } from '@modules/projects/moleculas';
import { useProjectImplementation } from '@modules/implementation/hooks';
import { ImplementationRecommendationsList } from '@modules/implementation/organisms';
import { useLocationQuery } from '@modules/shared/hooks';

const ProjectImplementationPage = (): React.ReactElement => {
    const params = useParams();
    const [query] = useLocationQuery();

    const projectId = params.id ? +params.id : null;
    let variables: any = {};

    variables.id = projectId as number;

    if (query?.componentOwner) {
        variables.componentOwnerId = Number(query?.componentOwner);
    }

    if (query?.maturityLevel) {
        variables.maturityLevelId = Number(query?.maturityLevel);
    }

    const { project, loading: projectLoading } = useProjectImplementation({
        skip: !projectId,
        variables,
    });
    if (!projectId) {
        return <Redirect to={routes.projects.path} />;
    }

    if (projectLoading) {
        return <Loader />;
    }

    if (project.isNew() || project.isAssessment()) {
        return (
            <Redirect noThrow to={createRouteUrl(routes.projectAssessment, { id: projectId })} />
        );
    }

    if (project.isRoadMap()) {
        return <Redirect noThrow to={createRouteUrl(routes.projectRoadmap, { id: projectId })} />;
    }

    return (
        <>
            <ProjectHeader
                name={project.name}
                description={project.description}
                projectStatus={project.getStatus()}
            />

            <PageStyled.Main>
                <ImplementationRecommendationsList
                    project={project}
                    implementation={project.implementation}
                />
            </PageStyled.Main>
        </>
    );
};

export { ProjectImplementationPage };

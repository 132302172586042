import React, { useEffect, useRef } from 'react';
import Quill, { QuillOptionsStatic } from 'quill';
import styled from 'styled-components';
import 'quill/dist/quill.snow.css';
import './editor.css';
import { Button } from '@material-ui/core';
import { ImageResize } from 'quill-image-resize-module-ts';
Quill.register('modules/imageResize', ImageResize);

type TextEditorProps = {
    handleClose: () => void;
    editorDescription: string;
    setEditorDescription: React.Dispatch<React.SetStateAction<string>>;
};

const EditorWrapper = styled.div`
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 20px;
    overflow-y: scroll;
`;
const ButtonStyle = styled.div`
    display: flex;
    justify-content: end;
    position: absolute;
    top: 16px;
    right: 45px;
    button:last-child {
        margin-left: 10px;
    }
    button:first-child {
        background-color: #d30b0b;
    }
`;

const TextEditor = (props: TextEditorProps): React.ReactElement => {
    const { handleClose, setEditorDescription, editorDescription } = props;
    const quillRef = useRef<HTMLDivElement>(null);
    const editorRef = useRef<Quill | null>(null);

    useEffect(() => {
        if (quillRef.current) {
            const options: QuillOptionsStatic = {
                theme: 'snow',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],

                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ script: 'sub' }, { script: 'super' }],
                        [{ indent: '-1' }, { indent: '+1' }],
                        [{ direction: 'rtl' }],

                        [{ size: ['small', false, 'large', 'huge'] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],

                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],

                        ['image', 'video'],
                        ['clean'],
                    ],
                    imageResize: {
                        parchment: Quill.import('parchment'),
                        modules: ['Resize', 'DisplaySize'],
                    },
                },
            };

            editorRef.current = new Quill(quillRef.current, options);
            if (editorDescription) {
                editorRef?.current?.clipboard?.dangerouslyPasteHTML(editorDescription);
            }
        }
    }, [editorDescription]);

    const getAllCodeFromEditor = () => {
        if (editorRef?.current) {
            const htmlContent = quillRef.current?.querySelector('.ql-editor')?.innerHTML;
            setEditorDescription(htmlContent || '');
            handleClose();
        }
    };

    return (
        <EditorWrapper>
            <div ref={quillRef} />
            <ButtonStyle>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={getAllCodeFromEditor}>Save</Button>
            </ButtonStyle>
        </EditorWrapper>
    );
};

export { TextEditor };

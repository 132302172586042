import { RecommendationCreateRequest } from './recommendation-create-request';

import type { UpdateRecommendationMutationVariables } from '@modules/types/graphql';
import type {
    RecommendationCreateFormValues,
    RecommendationCreateFormPayload,
} from './recommendation-create-request';

export type RecommendationUpdateFormValues = RecommendationCreateFormValues & {
    recommendationId: number;
};

class RecommendationUpdateRequest extends RecommendationCreateRequest {
    recommendationId: UpdateRecommendationMutationVariables['recommendationId'];

    constructor(values: RecommendationUpdateFormValues, payload: RecommendationCreateFormPayload) {
        super(values, payload);

        this.recommendationId = values.recommendationId;
    }
}

export { RecommendationUpdateRequest };

import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { Maybe, Task } from '@modules/types/graphql';

export type Entity = Maybe<Partial<Task>>;

@DecribeEntity('TaskEntity')
class TaskEntity extends BaseEntity {
    id: number;
    task: string;
    duration: string;
    resources: string;
    requirementId: number;

    constructor(task: Entity) {
        super(task);

        this.id = task?.id ?? -1;
        this.task = task?.task ?? '';
        this.duration = task?.duration ?? '';
        this.resources = task?.resources ?? '';
        this.requirementId = task?.requirement_id ?? -1;
    }
}

export { TaskEntity };

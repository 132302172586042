import { EntityList } from '@lib/entity';

import type { TkoEntity } from './tko-scope-entity';
import { TkoScopeEntity } from './tko-scope-entity';

class TkoScopeEntityList extends EntityList<TkoScopeEntity> {
    constructor(items: TkoEntity[] | null, count?: number | null) {
        super(TkoScopeEntity, items, count);
    }
}

export { TkoScopeEntityList };

import type { CreateCapabilityLevelMutationVariables } from '@modules/types/graphql';

export type CapabilityLevelCreateFormValues = {
    capabilityId: number;
    capabilityLevelDefinitionId: number;
    description: string;
};

class CapabilityLevelCreateRequest {
    capabilityId: CreateCapabilityLevelMutationVariables['capabilityId'];
    capabilityLevelDefinitionId: CreateCapabilityLevelMutationVariables['capabilityLevelDefinitionId'];
    description: CreateCapabilityLevelMutationVariables['description'];

    constructor(values: CapabilityLevelCreateFormValues) {
        this.capabilityId = values.capabilityId;
        this.capabilityLevelDefinitionId = values.capabilityLevelDefinitionId;
        this.description = values.description;
    }
}

export { CapabilityLevelCreateRequest };

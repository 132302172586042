import { gql } from '@apollo/client';

export const GetListProjectsByCompanyQuery = gql`
    query GetListProjectsByCompany($companyId: Int!) {
        listProjectsByCompany(companyId: $companyId) {
            id
            name
        }
    }
`;

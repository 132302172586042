import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { Maybe, MaturityLevel } from '@modules/types/graphql';

export type Entity = Maybe<Partial<MaturityLevel>>;

@DecribeEntity('MaturityLevelEntity')
class MaturityLevelEntity extends BaseEntity {
    id: number;
    name: string;

    constructor(level: Entity) {
        super(level);

        this.id = level?.id ?? -1;
        this.name = level?.name ?? '';
    }
}

export { MaturityLevelEntity };

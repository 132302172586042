import React, { useEffect, useState } from 'react';
import { LearnTkoTutorialCard } from '@components/learn-tko-card';
import ProjectImg from '../../modules/layout/icons/images/project1.jpg';

import LearnLogo from '../../modules/layout/icons/images/learn-tko.png';
import styled from 'styled-components';
import { useNavigate } from '@reach/router';
import { useDebounce } from '@helpers';
import { Box, Button, IconButton, TextField } from '@material-ui/core';
import { useCurrentUser } from '@modules/auth/hooks';
import { useAllMenuCard, useS3VideoLink } from '@modules/learn-tko/hooks';
import { routes } from '@config/routes';
import { Loader } from '@modules/layout/moleculas';
import LearnTkoActionButtons from './learn-tko-action-buttons';
import { MoreVert } from '@material-ui/icons';
import { Modal } from '@material-ui/core';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { UpdateCaptionModal } from './update-caption-modal';
import { useLearnTkoCaption } from '@modules/learn-tko/hooks/use-learn-tko-caption';
import { ThreeDotLoader } from '@components/three-dot-loader';
export const LearnTKOContainerStyle = styled.div`
    width: 100%;
`;
const LearnHeaderStyle = styled.div`
    background: #e5e2db;
    height: 400px;
    padding: 4rem 6rem;
`;
const ImageLearnLogoStyle = styled.img`
    width: 250px;
`;
const DescriptionLearnStyle = styled.div`
    max-width: 600px;
    width: 100%;
    padding: 10px;
    font-size: 20px;
    line-height: 30px;
    position: relative;
    .three-dots {
        position: absolute;
        top: 5px;
        left: -25px;
        svg {
            font-size: 20px;
        }
    }
`;
const InputFieldLearnStyle = styled.input`
    margin-top: 20px;
    max-width: 600px;
    font-size: 18px;
    width: 100%;
    padding: 15px;
    border-radius: 12px;
    outline: none;
    border: 1px solid #b3b3b3;
    box-shadow: 3px 2px 5px 3px rgba(0, 0, 0, 0.2);
`;
const LearnMainContainerStyle = styled.div`
    padding: 6rem;
`;

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;
const initialValues = {
    caption: '',
};

const LearnTKO = (): React.ReactElement => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [filterData, setFilterData] = useState<any>([]);
    const { currentUser } = useCurrentUser();
    const [captionInput, setCaptionInput] = useState(initialValues);

    const { allMenuCards, loading: menuLoading } = useAllMenuCard();
    const { s3data, loading: s3DataLoading } = useS3VideoLink();
    const debouncedValue = useDebounce(searchQuery, 500);

    const [open, setOpen] = React.useState<boolean>(false);
    const handleOpenCaption = () => setOpen(true);
    const handleCloseCaption = () => setOpen(false);

    const { caption, loading: captionLoading } = useLearnTkoCaption({
        variables: { id: 1 as number },
    });

    // let caption = `Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque maxime sit
    // earum praesentium adipisci dolores unde veniam corporis molestias quidem
    // corporis molestias quidem.`
    const handleExplore = (query: string) => {
        navigate(`/resources/learn-tko/${query}`, { state: { query } });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchQuery(query);
    };

    useEffect(() => {
        const newCardData: any = allMenuCards.filter((ele: any) => {
            if (typeof ele.menuTitle === 'string') {
                return (
                    ele?.menuTitle.toLowerCase().includes(debouncedValue.toLowerCase()) ||
                    ele?.description.toLowerCase().includes(debouncedValue.toLowerCase())
                );
            }
            return false;
        });
        setFilterData(newCardData);
    }, [debouncedValue, allMenuCards]);

    function handleNavigateCreateLearn(query: string) {
        switch (query) {
            case 'card':
                navigate(routes.viewLearnMenuCards.path);
                break;
            case 'tko':
                navigate(routes.viewLearnTko.path);
                break;
            case 'videoLink':
                navigate(routes.viewVideoLink.path);
                break;
            default:
                break;
        }
    }
    if (menuLoading || captionLoading) {
        return <Loader />;
    }
    return (
        <>
            <LearnTKOContainerStyle>
                <LearnHeaderStyle>
                    <ImageLearnLogoStyle src={LearnLogo || ''} alt='learn tko image' />
                    <DescriptionLearnStyle>
                        {caption?.caption}
                        {currentUser?.isSuperAdmin && (
                            <div className='three-dots'>
                                <IconButton onClick={handleOpenCaption}>
                                    <MoreVert />
                                </IconButton>
                            </div>
                        )}
                    </DescriptionLearnStyle>
                    <InputFieldLearnStyle
                        type='text'
                        placeholder='Search'
                        onChange={handleSearch}
                    />
                    {currentUser.isSuperAdmin && (
                        <LearnTkoActionButtons handleNavigate={handleNavigateCreateLearn} />
                    )}
                </LearnHeaderStyle>
                <LearnMainContainerStyle>
                    <LearnTkoTutorialCard
                        learnTkoCardData={filterData}
                        imageData={s3data}
                        menuLoading={s3DataLoading}
                        handleExplore={handleExplore}
                        ProjectImg={ProjectImg}
                    />
                </LearnMainContainerStyle>
            </LearnTKOContainerStyle>
            <UpdateCaptionModal caption={caption} onClose={handleCloseCaption} open={open} />
        </>
    );
};

export { LearnTKO };

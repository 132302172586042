import { gql } from '@apollo/client';

import { RecommendationImplementationFragment } from '../fragments';

export const CompleteImplementationMutation = gql`
    mutation CompleteImplementation($roadmapRecommendationId: Int!) {
        completeImplementation(roadmap_recommendation_id: $roadmapRecommendationId) {
            ...RecommendationImplementation
        }
    }
    ${RecommendationImplementationFragment}
`;

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { GetImpactLevelsQuery } from '@modules/shared/graphql';
import { ImpactLevelsEntityList } from '@modules/shared/entities/impact-levels-entity-list';

import type { QueryHookOptions } from '@apollo/client';

const useImpactLevels = (options?: QueryHookOptions) => {
    const queryResult = useQuery(GetImpactLevelsQuery, options);

    const { entities: ImpactLevels, count } = useEntity(
        () => new ImpactLevelsEntityList(queryResult.data?.getImpactLevels ?? null),
        [queryResult.data],
    );

    const result = {
        ImpactLevels,
        count,
        ...queryResult,
    };

    return result;
};

export { useImpactLevels };

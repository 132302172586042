import React from 'react';
import styled from 'styled-components';
import { MenuItem, InputAdornment } from '@material-ui/core';
import { useDebounce } from 'react-use';

import { TextField } from '@modules/ui/core';
import { StringHelper, CommonHelper } from '@helpers';
import { ProjectStatusEnum } from '@modules/projects/constants';
import { useProjectsTableFiltersQuery } from '@modules/projects/hooks';
import { useAssessmentTypes, useMaturityLevels } from '@modules/shared/hooks';
import {
    searchIcon,
    useBackgroundStyles,
} from '@modules/projects/moleculas/project-list-filters/input-box-css';

type ProjectListFiltersValues = {
    search: string;
    status: string;
    assessmentTypes?: string[];
    maturityLevelId?: string[];
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > * {
        width: 250px;

        &:not(:last-of-type) {
            margin-right: 32px;
        }
    }
`;

const initialFilters = {
    status: '',
    assessmentTypes: [],
    maturityLevelId: [],
    search: '',
};

const projectStatutes = Object.values(ProjectStatusEnum).map(status =>
    StringHelper.capitalizeLetter(status),
);

const ProjectListFilters = (): React.ReactElement => {
    const { filters, setQuery } = useProjectsTableFiltersQuery();
    const { assessmentTypes } = useAssessmentTypes();
    const { maturityLevels } = useMaturityLevels();
    const backgroundClasses = useBackgroundStyles();

    const defaultFilters = CommonHelper.fillDefaultValues<ProjectListFiltersValues>(
        filters,
        initialFilters,
    );

    const [values, setValues] = React.useState<ProjectListFiltersValues>(defaultFilters);

    const handleChangeField = (event: React.ChangeEvent<{ name: string; value: string }>) => {
        const name = event.target.name;
        const value = event.target.value;

        setValues(state => ({ ...state, [name]: value }));
    };

    useDebounce(
        () => {
            const updatedQuery = { ...filters, ...values };

            setQuery(updatedQuery);
        },
        500,
        [values],
    );

    return (
        <Root>
            <TextField
                select
                name='status'
                label='Status'
                classes={backgroundClasses}
                value={values.status}
                InputLabelProps={{ shrink: true }}
                onChange={handleChangeField}
            >
                <MenuItem value=''>Choose the status</MenuItem>

                {projectStatutes.map(status => (
                    <MenuItem key={status} value={status}>
                        {status}
                    </MenuItem>
                ))}
            </TextField>

            <TextField
                select
                name='assessmentTypes'
                label='Assessment Type'
                classes={backgroundClasses}
                value={values.assessmentTypes}
                InputLabelProps={{ shrink: true }}
                SelectProps={{
                    multiple: true,
                }}
                onChange={handleChangeField}
            >
                {assessmentTypes ? (
                    assessmentTypes.map(type => (
                        <MenuItem key={type?.id} value={type?.id.toString()}>
                            {type?.name}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem key={0} value={''}>
                        Select assessment type
                    </MenuItem>
                )}
            </TextField>

            <TextField
                select
                name='maturityLevelId'
                label='Maturity Level'
                classes={backgroundClasses}
                value={values.maturityLevelId}
                InputLabelProps={{ shrink: true }}
                SelectProps={{
                    multiple: true,
                }}
                onChange={handleChangeField}
            >
                {maturityLevels ? (
                    maturityLevels.map(level => (
                        <MenuItem key={level?.id} value={level?.id.toString()}>
                            {level?.name}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem key={0} value={''}>
                        Select maturity level
                    </MenuItem>
                )}
            </TextField>

            <TextField
                corner='round'
                name='search'
                label='Search'
                value={values.search}
                InputProps={{
                    classes: backgroundClasses,
                    endAdornment: <InputAdornment position='end'>{searchIcon}</InputAdornment>,
                }}
                onChange={handleChangeField}
            />
        </Root>
    );
};

export { ProjectListFilters };

import * as React from 'react';
import {
    TableCell,
    TableBody,
    TableHead,
    TableContainer,
    TableSortLabel,
    Table as MuiTable,
    makeStyles,
} from '@material-ui/core';
import styled from 'styled-components';
import { TableRow } from './table-row';
import { TableCellText } from '@modules/layout/atoms';
import { Loader } from '@modules/layout/moleculas';
import { addElipsisText, useDebounce } from '@helpers';
import { LearnTkoItemActions } from '@modules/learn-tko/organisms';

const useTableStyles = makeStyles({
    root: {
        borderCollapse: 'separate',
        borderSpacing: '0 4px',
        width: '100%',
    },
});

const TextFieldStyle = styled.div`
    font-size: 13px;
    margin: 10px -6px -10px -6px;
    input {
        padding: 0px 4px;
        border-right: transparent !important;
        border-top: transparent !important;
        border-left: transparent !important;
        border: 0.3px solid #a7a7a7;
    }

    input:focus {
        outline: none !important;
        border-bottom: 1px solid #605c5c;
    }
`;

interface Data {
    menu: string;
    title: string;
    description: string;
    video_url: string;
}

type Order = 'asc' | 'desc';

export type TableColumn = {
    id: string;
    label?: string;
    align?: 'left' | 'center' | 'right';
    width?: number;
};

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    columns: TableColumn[];
    onChangeHandle: (e: React.ChangeEvent<HTMLInputElement>, columnId: any) => void;
}

interface handleClickProps {
    learnTkoAllDetails: any;
    learnTkoLoading: boolean;
    handleClickRedirectEdit: (learnTkoId: number) => void;
    canDeleteUser: boolean;
    openModal: () => void;
    columns: TableColumn[];
    handleDeleteLearnTko: (learnTkoId: number) => Promise<void>;
}

export function EnhancedTableHead(props: EnhancedTableProps) {
    let { order, orderBy, columns, onRequestSort, onChangeHandle } = props;
    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {columns?.map((headCell, index) => (
                    <TableCell
                        style={{ textAlign: index === columns.length - 1 ? 'right' : 'left' }}
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {index !== columns.length - 1 ? (
                            <>
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                </TableSortLabel>
                                <TextFieldStyle>
                                    <input
                                        type='text'
                                        placeholder={headCell.label}
                                        onChange={e => onChangeHandle(e, headCell.id)}
                                    />
                                </TextFieldStyle>
                            </>
                        ) : (
                            'Actions'
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function TableSortLearnTko(props: handleClickProps) {
    const {
        handleClickRedirectEdit,
        learnTkoLoading,
        openModal,
        canDeleteUser,
        columns,
        handleDeleteLearnTko,
        learnTkoAllDetails,
        ...otherProps
    } = props;

    const [order, setOrder] = React.useState<Order>('asc');
    const [query, setQuery] = React.useState<string>('');

    const [orderBy, setOrderBy] = React.useState<keyof Data>('menu');
    const tableClasses = useTableStyles();
    const [filteredData, setFilteredData] = React.useState<any>([]);
    const debouncedQuery = useDebounce(query, 500);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    React.useEffect(() => {
        const filteredResults = learnTkoAllDetails?.filter((item: any) => {
            return Object.entries(debouncedQuery).every(([key, searchValue]) => {
                if (searchValue !== '') {
                    return (
                        item[key]?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                        item[key]?.toString().toUpperCase().includes(searchValue.toUpperCase())
                    );
                }
                return true;
            });
        });
        setFilteredData(filteredResults);
    }, [debouncedQuery, query, learnTkoAllDetails]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>, columnId: string) => {
        const searchValue = (e.target as HTMLInputElement).value.toLowerCase();
        setQuery((prevSearchQueries: any) => ({
            ...prevSearchQueries,
            [columnId]: searchValue,
        }));
    };

    if (learnTkoLoading) {
        return <Loader />;
    }
    return (
        <div>
            <TableContainer style={{ marginTop: ' 20px' }}>
                <MuiTable classes={tableClasses} {...otherProps}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        columns={columns}
                        onChangeHandle={handleSearch}
                    />
                    <TableBody style={{ background: '#e7e7e7' }}>
                        {filteredData
                            ?.sort((a: any, b: any) => {
                                if (orderBy === undefined) {
                                    return 0;
                                }
                                const aValue = a[orderBy];
                                const bValue = b[orderBy];

                                if (aValue < bValue) {
                                    return order === 'asc' ? -1 : 1;
                                }
                                if (aValue > bValue) {
                                    return order === 'asc' ? 1 : -1;
                                }
                                return 0;
                            })
                            .map((ele: any) => (
                                <TableRow
                                    size='m'
                                    key={ele?.id}
                                    style={{
                                        maxWidth: '230px',
                                        overflow: 'hidden',
                                        whiteSpace: 'normal',
                                        textOverflow: 'ellipsis',
                                    }}
                                    onClick={() => handleClickRedirectEdit(ele?.id)}
                                >
                                    <TableCell>
                                        <TableCellText>{ele?.menu}</TableCellText>
                                    </TableCell>
                                    <TableCell>
                                        <TableCellText>{ele?.title}</TableCellText>
                                    </TableCell>
                                    {/* <TableCell>
                                        <TableCellText>
                                            {addElipsisText(ele?.description, 70)}
                                        </TableCellText>
                                    </TableCell> */}
                                    <TableCell>
                                        <TableCellText>{ele?.video_url}</TableCellText>
                                    </TableCell>
                                    <TableCell align='right' onClick={e => e.stopPropagation()}>
                                        {canDeleteUser ? (
                                            <LearnTkoItemActions
                                                onUpdate={handleClickRedirectEdit}
                                                learnTkoId={ele?.id}
                                                onDelete={handleDeleteLearnTko}
                                            />
                                        ) : null}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </MuiTable>
            </TableContainer>
        </div>
    );
}
export { TableSortLearnTko };

import { gql } from '@apollo/client';

import { CompanyAccessFragment } from '../fragments';

export const GetCompanyAccessQuery = gql`
    query GetCompanyAccess {
        getCompanyAccess {
            ...CompanyAccess
        }
    }
    ${CompanyAccessFragment}
`;

import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

import { Modal } from '@modules/ui/core';
import { Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core';
import type { ModalProps } from '@modules/ui/core';
import { CapabilityAssessmentEntity } from '@modules/assessment/entities';

type AssessmentCapabilityHistoryModalProps = Omit<ModalProps, 'title' | 'children'> & {
    capabilityAssessment: CapabilityAssessmentEntity;
};

const StyledModal = styled(Modal)`
    width: 1200px;

    .is-empty-results {
        font-size: 14px;
        text-align: center;

        td {
            padding: 20px;
        }
    }
`;

const AssessmentCapabilityHistoryModal = (
    props: AssessmentCapabilityHistoryModalProps,
): React.ReactElement => {
    const { capabilityAssessment, onClose, ...otherProps } = props;

    return (
        <StyledModal title='Assessment history' onClose={onClose} {...otherProps}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>Gaps</TableCell>
                        <TableCell>Recommendations</TableCell>
                        <TableCell>Notes</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {capabilityAssessment.log.length ? (
                        capabilityAssessment.log.map((log, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    {format(new Date(log.action_timestamp), 'dd.MM.yyyy hh:mm')}
                                </TableCell>
                                <TableCell>
                                    {log.user?.first_name} {log.user?.last_name}
                                </TableCell>
                                <TableCell>{log.gaps}</TableCell>
                                <TableCell>{log.recommendations}</TableCell>
                                <TableCell>{log.notes}</TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <tr className='is-empty-results'>
                            <td colSpan={5}>No history</td>
                        </tr>
                    )}
                </TableBody>
            </Table>
        </StyledModal>
    );
};

export { AssessmentCapabilityHistoryModal };

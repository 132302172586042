import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { RoleEntityList } from '@modules/assessment/entities';
import { GetRolesQuery } from '@modules/assessment/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetRolesQueryType, GetRolesQueryVariables } from '@modules/types/graphql';

const useRoles = (options?: QueryHookOptions<GetRolesQueryType, GetRolesQueryVariables>) => {
    const queryResult = useQuery<GetRolesQueryType, GetRolesQueryVariables>(GetRolesQuery, options);

    const rolesEntityList = useEntity(
        () => new RoleEntityList(queryResult.data?.getRoles ?? null),
        [queryResult.data],
    );

    const result = { rolesEntityList, ...queryResult };

    return result;
};

export { useRoles };

import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { gray } from '@modules/ui/colors';
import { ListStyled } from '@modules/layout/styled';
import { TableCell, TableRow } from '@material-ui/core';
import { GapCapabilityAssessment } from '@modules/assessment/gap-by-risk';
import { scoreVariantColorsMap } from '@modules/shared/constants/score-variant-colors';
import { GapRiskLevel, RiskLevel10Point } from '@modules/shared/constants/risk-level';
import { ReportRiskInnerItem } from '@modules/assessment/organisms/report-components-list/report-risk-inner-item';
import { ProjectEntity } from '@modules/projects/entities';

type ReportRiskRowProps = {
    gapAssessment: GapCapabilityAssessment;
    project?: ProjectEntity;
};

// Pick<ReportRiskRowProps, 'gapAssessment'>
const ItemTitle = styled.div<{ riskLevelKey: RiskLevel10Point | string }>`
    width: 100%;

    & > h3 {
        font-size: 2.2rem;
    }

    & > h3,
    & > p {
        display: inline-flex;
        padding: 4px;
        font-weight: 700;
        border-radius: 4px;

        ${p => css`
            color: ${scoreVariantColorsMap[p.riskLevelKey].color};
            background-color: ${scoreVariantColorsMap[p.riskLevelKey].background};
        `}
    }
`;

export const ReportRiskRow = (props: ReportRiskRowProps): React.ReactElement => {
    const { gapAssessment, project } = props;
    const [openComponent, setOpenComponent] = React.useState(false);

    function onRowToggle() {
        setOpenComponent(!openComponent);
    }
    return (
        <>
            {project?.isrrimpact ? (
                <>
                    <TableRow onClick={onRowToggle} className={'row-expand'}>
                        <TableCell component='th' scope='row'>
                            {gapAssessment.scoredGap.gap.gap}
                        </TableCell>
                        <TableCell>
                            <ItemTitle riskLevelKey={gapAssessment.scoredGap.riskLevel}>
                                <p>{gapAssessment.scoredGap.riskLevel}</p>
                            </ItemTitle>
                        </TableCell>
                        <TableCell>{gapAssessment.componentAssessment.component.name}</TableCell>
                    </TableRow>
                </>
            ) : (
                <>
                    <TableRow onClick={onRowToggle} className={'row-expand'}>
                        <TableCell component='th' scope='row'>
                            {gapAssessment.scoredGap.gap.gap}
                        </TableCell>
                        <TableCell>
                            <ItemTitle riskLevelKey={gapAssessment.scoredGap.riskLevel}>
                                <p>{gapAssessment.scoredGap.riskLevel}</p>
                            </ItemTitle>
                        </TableCell>
                        <TableCell>{gapAssessment.componentAssessment.component.name}</TableCell>
                        <TableCell>{gapAssessment.capabilityAssessment.capability.title}</TableCell>
                        <TableCell>{gapAssessment.implementationStatus}</TableCell>
                    </TableRow>
                </>
            )}
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                    <ReportRiskInnerItem
                        gapAssessment={gapAssessment}
                        openComponent={openComponent}
                        projectStatus={project?.isrrimpact}
                    />
                </TableCell>
            </TableRow>
        </>
    );
};

import React from 'react';
import styled, { keyframes } from 'styled-components';

const LoadingDots = styled.span`
    position: absolute;
    top: 24px;
    right: 4px;
    transform: translate(-50%, -50%);
`;

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

const Dot = styled.div`
    width: 8px;
    height: 8px;
    background-color: #2657e2;
    border-radius: 50%;
    display: inline-block;
    margin: 0 4px;
    animation: ${bounce} 1.4s infinite ease-in-out both;
    &:nth-child(2) {
        animation-delay: 0.2s;
    }
    &:nth-child(3) {
        animation-delay: 0.4s;
    }
`;

// type ThreeDotProps = {
//     loading: boolean;

// }

const ThreeDotLoader = () => {
    return (
        <LoadingDots>
            <Dot />
            <Dot />
            <Dot />
        </LoadingDots>
    );
};

export { ThreeDotLoader };

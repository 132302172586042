import { gql } from '@apollo/client';

export const GetPrevTypeDetailsQuery = gql`
    query getPrevTypeDetails {
        getPrevTypeDetails {
            category_id
            type_ids
            item_category_type_id
        }
    }
`;

import React from 'react';
import styled from 'styled-components';
import { Redirect, useNavigate } from '@reach/router';

import { routes } from '@config/routes';
import { useCurrentUser } from '@modules/auth/hooks';
import { ForgotPasswordForm } from '@modules/auth/organisms';

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const ForgotPasswordPage = (): React.ReactElement => {
    const { currentUser } = useCurrentUser();
    const navigate = useNavigate();

    if (currentUser.exists()) {
        return <Redirect noThrow to={routes.index.path} />;
    }

    return (
        <Root>
            <ForgotPasswordForm
                onHide={({ email }) => navigate(routes.setPassword.path + `?email=${email}`)}
            />
        </Root>
    );
};

export { ForgotPasswordPage };

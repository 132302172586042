import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { Subscriptions } from '@modules/types/graphql';

export type SubscriptionsEntity = Partial<Subscriptions> | null;

@DecribeEntity('SubscriptionTypeEntity')
class SubscriptionTypeEntity extends BaseEntity {
    id: number;
    name: string;

    constructor(subscription: SubscriptionsEntity) {
        super(subscription);

        this.id = subscription?.id ?? -1;
        this.name = subscription?.name ?? '';
    }
}

export { SubscriptionTypeEntity };

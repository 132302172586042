import gql from 'graphql-tag';

export const SignUpUserMutation = gql`
    mutation SignUpUser(
        $username: String!
        $email: String!
        $firstName: String
        $lastName: String
        $isAdmin: Boolean
        $userRole: String
        $companyId: Int
        $schemaName: String
    ) {
        signUpUser(
            username: $username
            email: $email
            firstName: $firstName
            lastName: $lastName
            isAdmin: $isAdmin
            userRole: $userRole
            companyId: $companyId
            schemaName: $schemaName
        )
    }
`;

import { EntityList } from '@lib/entity';
import { CapabilityImpactEntity } from './capability-impact-entity';

import type { Entity } from './capability-impact-entity';

class CapabilityImpactEntityList extends EntityList<CapabilityImpactEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(CapabilityImpactEntity, items, count);
    }
}

export { CapabilityImpactEntityList };

import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { Company } from '@modules/types/graphql';

export type Entity = Partial<Company> | null;

@DecribeEntity('CompanyEntity')
class CompanyEntity extends BaseEntity {
    id: number;
    name: string;
    schema_name: string;

    constructor(company: Entity) {
        super(company);

        this.id = company?.id ?? -1;
        this.name = company?.name ?? '';
        this.schema_name = company?.schema_name ?? '';
    }
}

export { CompanyEntity };

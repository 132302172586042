import { gql } from '@apollo/client';

import { ComponentAssessmentFragment } from '../fragments';

export const GetComponentAssessmentsQuery = gql`
    query GetComponentAssessments($projectId: Int!) {
        listComponentAssessments(project_id: $projectId) {
            ...ComponentAssessment
        }
    }
    ${ComponentAssessmentFragment}
`;

import { useQuery } from '@apollo/client';
import { GetRiskAssignUserQuery } from '@modules/registry/graphql';
import {RiskAssignUser} from '@modules/types/graphql';

const useRiskAssignUser = (options?: any) => {
    const queryResult = useQuery(GetRiskAssignUserQuery, options);

    const riskGroups: {listRiskAssignUser: RiskAssignUser[]} = queryResult?.data;

    return { riskGroups, ...queryResult };
};

export { useRiskAssignUser };
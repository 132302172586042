import React from 'react';
import styled from 'styled-components';

type CapabilityCheckboxLabelProps = {
    title: string;
    subtitle: string;
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
`;

const Title = styled.div`
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 22px;
`;

const Subtitle = styled.div`
    font-size: 1.4rem;
    line-height: 21px;
`;

const CapabilityCheckboxLabel = (props: CapabilityCheckboxLabelProps): React.ReactElement => {
    const { title, subtitle } = props;

    return (
        <Root>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
        </Root>
    );
};

export { CapabilityCheckboxLabel };

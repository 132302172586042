import { BaseEntity, Entity as DecribeEntity, RelationList } from '@lib/entity';

import { ComponentRecommendationEntityList } from './component-recommendation-entity-list';

import type { PrioritizedRoadmap } from '@modules/types/graphql';
import type {
    ComponentRecommendationEntity,
    Entity as ComponentRecommendationEntityType,
} from './component-recommendation-entity';
import { recommendationLevelFieldList } from '@modules/shared/constants/risk-level';

export type Entity = Partial<
    Omit<PrioritizedRoadmap, 'critical_recommendations'> & {
        super_critical_recommendations: ComponentRecommendationEntityType[] | null;
        critical_recommendations: ComponentRecommendationEntityType[] | null;
        high_critical_recommendations: ComponentRecommendationEntityType[] | null;
        high_recommendations: ComponentRecommendationEntityType[] | null;
        medium_high_recommendations: ComponentRecommendationEntityType[] | null;
        medium_recommendations: ComponentRecommendationEntityType[] | null;
        low_medium_recommendations: ComponentRecommendationEntityType[] | null;
        low_recommendations: ComponentRecommendationEntityType[] | null;
        minimal_low_recommendations: ComponentRecommendationEntityType[] | null;
        minimal_recommendations: ComponentRecommendationEntityType[] | null;
    }
> | null;

@DecribeEntity('PrioritizedRoadmapEntity')
class PrioritizedRoadmapEntity extends BaseEntity {
    dataSource: Entity;

    projectId: number;

    @RelationList(() => ComponentRecommendationEntityList)
    superCriticalRecommendations: (ComponentRecommendationEntity | never)[];

    @RelationList(() => ComponentRecommendationEntityList)
    criticalRecommendations: (ComponentRecommendationEntity | never)[];

    @RelationList(() => ComponentRecommendationEntityList)
    highCriticalRecommendations: (ComponentRecommendationEntity | never)[];

    @RelationList(() => ComponentRecommendationEntityList)
    highRecommendations: (ComponentRecommendationEntity | never)[];

    @RelationList(() => ComponentRecommendationEntityList)
    mediumHighRecommendations: (ComponentRecommendationEntity | never)[];

    @RelationList(() => ComponentRecommendationEntityList)
    mediumRecommendations: (ComponentRecommendationEntity | never)[];

    @RelationList(() => ComponentRecommendationEntityList)
    lowMediumRecommendations: (ComponentRecommendationEntity | never)[];

    @RelationList(() => ComponentRecommendationEntityList)
    lowRecommendations: (ComponentRecommendationEntity | never)[];

    @RelationList(() => ComponentRecommendationEntityList)
    minimalLowRecommendations: (ComponentRecommendationEntity | never)[];

    @RelationList(() => ComponentRecommendationEntityList)
    minimalRecommendations: (ComponentRecommendationEntity | never)[];

    constructor(prioritizedRoadmap: Entity) {
        super(prioritizedRoadmap);

        this.projectId = prioritizedRoadmap?.project_id ?? 0;
        this.dataSource = prioritizedRoadmap;
    }

    clone(): PrioritizedRoadmapEntity {
        return new PrioritizedRoadmapEntity(this.dataSource);
    }

    hasAnyRecommendations() {
        let result = recommendationLevelFieldList.some(key => Boolean(this[key]?.length));
        return result;
    }
}

export { PrioritizedRoadmapEntity };

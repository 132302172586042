import { gql } from '@apollo/client';

import { UserFragment } from '@modules/users/graphql';
import { ComponentAssessmentFragment } from '@modules/assessment/graphql';

export const GetAssessmentReportQuery = gql`
    query GetAssessmentReport($id: Int!, $componentOwnerId: Int, $maturityLevelId: Int) {
        getAssignedProject(
            id: $id
            componentOwnerId: $componentOwnerId
            maturityLevelId: $maturityLevelId
        ) {
            id
            name
            description
            status
            company_id
            assessment_type_id
            is_risk_registry
            is_rr_impact
            rr
            roadmap_status
            maturity_level {
                id
                name
            }
            stakeholder {
                ...User
            }
            program_manager {
                ...User
            }
            assessments {
                ...ComponentAssessment
            }
        }
    }
    ${UserFragment}
    ${ComponentAssessmentFragment}
`;

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { ProjectEntity } from '@modules/projects/entities';
import { GetProjectImplementationQuery } from '@modules/implementation/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetProjectImplementationQueryType,
    GetProjectImplementationQueryVariables,
} from '@modules/types/graphql';

const useProjectImplementation = (
    options?: QueryHookOptions<
        GetProjectImplementationQueryType,
        GetProjectImplementationQueryVariables
    >,
) => {
    const queryResult = useQuery<
        GetProjectImplementationQueryType,
        GetProjectImplementationQueryVariables
    >(GetProjectImplementationQuery, options);

    const project = useEntity(() => new ProjectEntity(queryResult.data?.getProject ?? null), [
        queryResult.data,
    ]);

    const result = {
        project,
        ...queryResult,
    };

    return result;
};

export { useProjectImplementation };

import { useQuery } from '@apollo/client';
import type { QueryHookOptions } from '@apollo/client';
import { GetListRiskFunctionQuery } from '../graphql';
import { useEntity } from '@lib/entity';
import { RiskFunctionEntityList } from '../entities';

const useRiskFunction = (options?: QueryHookOptions) => {
    const queryResult = useQuery(GetListRiskFunctionQuery, options);

    const { entities: riskFunction, count } = useEntity(
        () => new RiskFunctionEntityList(queryResult.data?.listRiskFunction ?? null),
        [queryResult.data],
    );

    const result = {
        riskFunction,
        count,
        ...queryResult,
    };

    return result;
};

export { useRiskFunction };

import React from 'react';

import { InnerListItemStyled } from '@modules/layout/styled';

import type { RoadmapRecommendationEntity } from '@modules/roadmap/entities';
import {AccordionInnerListItem} from '@modules/layout/organisms';
import styled from 'styled-components';
import {format} from "date-fns";
import {RoadmapRecommendationRefinementEntity} from '@modules/roadmap/entities';
import {RoadmapHistory} from '@modules/roadmap/organisms/roadmap-recommendations-list/roadmap-history';
import {roadmapRecommendationTitle} from '@helpers';
import {RecommendationAssessment} from '@modules/roadmap/recommendation-assessment-roadmap-report';

type ReportRoadmapTopRecommendationsListItemProps = {
  recommendation: RoadmapRecommendationEntity;
  topRecommendation: RecommendationAssessment;
};

const HistorySection = styled.div`
    width: 100%;

    > * {
        font-size: 1.4rem;
    }
`;

const ReportRoadmapTopRecommendationsListItem = (
    props: ReportRoadmapTopRecommendationsListItemProps,
): React.ReactElement => {
    const { recommendation, topRecommendation } = props;
    const history: RoadmapRecommendationRefinementEntity = recommendation?.refinement;
    const displayRecommendation = roadmapRecommendationTitle(recommendation);

    const [expanded, setExpanded] = React.useState<boolean>(false);
    const handleExpandComponent = (
      event: React.ChangeEvent<{}>,
      isExpanded: boolean,
    ) => setExpanded(isExpanded);

    return (
        <InnerListItemStyled.Root>
            <InnerListItemStyled.Title className={'text-offset'}>{displayRecommendation}</InnerListItemStyled.Title>
            <InnerListItemStyled.TextSection className={'text-offset'}><b>Capability:</b> {topRecommendation.capabilityAssessment.capability.title}</InnerListItemStyled.TextSection>
            <InnerListItemStyled.TextSection className={'text-offset'}><b>Gap:</b> {recommendation.gap.gap}</InnerListItemStyled.TextSection>
            <InnerListItemStyled.TextSection className={'text-offset'}><b>Security Controls:</b> {topRecommendation.capabilityAssessment.capability.securityControls}</InnerListItemStyled.TextSection>
            {history ? (
              <>
                  <InnerListItemStyled.TextSection className={'text-offset'}><b>{history.status} at {format(new Date(history.refined_at), 'dd.MM.yyyy hh:mm')} by {history?.refined_by?.first_name} {history?.refined_by?.last_name}</b></InnerListItemStyled.TextSection>
                  <InnerListItemStyled.TextSection>Dependencies: {history.dependencies}</InnerListItemStyled.TextSection>
                  <InnerListItemStyled.TextSection>Constraints: {history.constraints}</InnerListItemStyled.TextSection>
                  <InnerListItemStyled.TextSection>Notes: {history.notes}</InnerListItemStyled.TextSection>

                  <HistorySection>
                      <AccordionInnerListItem
                        title={'History'}
                        subtitle={''}
                        expanded={expanded}
                        onChange={handleExpandComponent}
                        SummaryInfoComponents={
                            <></>
                        }
                      >
                          <RoadmapHistory roadmapRecommendation={recommendation} />
                      </AccordionInnerListItem>
                  </HistorySection>
              </>
            ) : (
              <InnerListItemStyled.TextSection>No history exist yet</InnerListItemStyled.TextSection>
            )}
        </InnerListItemStyled.Root>
    );
};

export { ReportRoadmapTopRecommendationsListItem };

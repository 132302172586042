import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { gray } from '@modules/ui/colors';

import type { ProjectEntity } from '@modules/projects/entities';
import { AccordionListItem } from '@modules/layout/organisms';
import { RemoveRedEyeRounded } from '@material-ui/icons';
import { ImplementationSubTaskList } from './implementation-sub-task-list';
import { useChatTaskSubTask } from '@helpers';

import { CreateImplementationSubTaskMutation } from '@modules/implementation/graphql/mutations';
import { CreateSubTaskMutationType, CreateSubTaskMutationVariables } from '@modules/types/graphql';
import { useMutation } from '@apollo/client';

import { ImpSubTaskCreateRequest } from '@modules/custom-components/requests';
import { LinearProgress } from '@material-ui/core';
import { VisibilityOff } from '@material-ui/icons';
import { Loader } from '@modules/layout/moleculas';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { GetRecommendationImpSubTasksQuery } from '@modules/implementation/graphql/queries';
import { AuthContext } from '@modules/auth/context';

type ImplementationTaskListItemProps = {
    project: ProjectEntity;
    recommendationId: number;
    impTask: any;
    RecImpTaskLoading: boolean;
};

const Root = styled.div`
    width: 100%;
    border-bottom: 1px solid #d1d2d2;
`;

const Header = styled.div`
    width: 100%;

    > h4 {
        font-size: 1.8rem;
        font-weight: 700;
    }
`;

const List = styled.div`
    width: 100%;
    padding: 16px;

    > * {
        border-bottom: 1px solid ${gray[60]};

        &:first-child {
            border-top: 1px solid ${gray[60]};
        }
    }
`;

const EyeInfo = styled.div`
    > svg {
        font-size: 4rem;
        text-align: center;
        cursor: pointer;
        margin-right: 35px;
    }
    &:hover {
        color: #5a5a5a;
    }
`;

const ImplementationTaskListItem = (props: ImplementationTaskListItemProps): React.ReactElement => {
    const { project, recommendationId, impTask, RecImpTaskLoading } = props;
    const [taskId, setTaskId] = useState<any>(null);
    const [response, loading, error, fetchResponse] = useChatTaskSubTask();

    const [createSubTask] = useMutation<CreateSubTaskMutationType, CreateSubTaskMutationVariables>(
        CreateImplementationSubTaskMutation,
    );
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const context = useContext(AuthContext);

    // const { RecImpTask, loading: RecImpTaskLoadings } = useRecommendationImpTasks({
    //     variables: { recommendationId: recommendationId },
    // });

    const ImplementationTaskId: number = impTask?.id;
    const DisplayImplementationTask: string = impTask?.task;

    async function findChatGptResponseSubTask(subTaskData: any, taskId: number) {
        if (context?.isAiAccess) {
            setTaskId(taskId);
            await fetchResponse(subTaskData);
        } else {
            return;
        }
    }

    useEffect(() => {
        let result = undefined;
        if(response) { 
            response?.forEach(async function (items: string) {
                const variables = new ImpSubTaskCreateRequest({
                    recommendationId: recommendationId,
                    subTask: items,
                    companyId: project?.companyId,
                    taskId: taskId,
                    isAiSubTasks: true,
                });

                const { data: createImpSubTaskData } = await createSubTask({
                    variables,
                    refetchQueries: [
                        {
                            query: GetRecommendationImpSubTasksQuery,
                            variables: { recommendationId, taskId, companyId: project?.companyId },
                        },
                    ],
                    awaitRefetchQueries: true,
                });
                result = createImpSubTaskData?.createSubTask?.id;
                if (!result) {
                    enqueueError(error);
                }
            });
        }
    }, [response]);

    if (RecImpTaskLoading) {
        return <Loader />;
    }
    return (
        <>
            {impTask?.task ? (
                <Root>
                    <AccordionListItem
                        title={DisplayImplementationTask}
                        subtitle=''
                        SummaryInfoComponents={
                            <>
                                <EyeInfo
                                    onClick={() => {
                                        if (!loading) {
                                            findChatGptResponseSubTask(
                                                DisplayImplementationTask,
                                                ImplementationTaskId,
                                            );
                                        }
                                    }}
                                >
                                    {context?.isAiAccess ? (
                                        <RemoveRedEyeRounded />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </EyeInfo>
                                {/* <Button variant='outlined'>Start</Button> */}
                            </>
                        }
                    >
                        {loading ? (
                            <LinearProgress />
                        ) : (
                            <List>
                                <ImplementationSubTaskList
                                    key={taskId}
                                    project={project}
                                    recommendationId={recommendationId}
                                    isGenerate={loading}
                                    taskId={ImplementationTaskId}
                                />
                            </List>
                        )}
                    </AccordionListItem>
                </Root>
            ) : null}
        </>
    );
};

export { ImplementationTaskListItem };

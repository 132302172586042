import { EntityList } from '@lib/entity';
import type { CategoryTypeDetailsEntityType as Entity } from './category-type-details-entity';
import { CategoryTypeDetailsEntity } from './category-type-details-entity';


class CategoryTypeDetailsEntityList extends EntityList<CategoryTypeDetailsEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(CategoryTypeDetailsEntity, items, count);
    }
}

export { CategoryTypeDetailsEntityList };

import { gql } from '@apollo/client';

import { CompanyDetailsFragment } from '../fragments';

export const CreateCompanyDetailsMutation = gql`
    mutation CreateCompanyDetails(
        $userId: Int!
        $name: String!
        $address: String
        $contactName: String!
        $email: String!
        $phone: String!
        $packageType: String!
        $subscriptionType: String!
        $isAskTko: Boolean!
        $isLearnTko: Boolean!
        $isSchema: Boolean
    ) {
        createCompanyDetails(
            user_id: $userId
            name: $name
            address: $address
            contact_name: $contactName
            email: $email
            phone: $phone
            package_type: $packageType
            subscription_type: $subscriptionType
            is_ask_tko: $isAskTko
            is_learn_tko: $isLearnTko
            is_schema: $isSchema
        ) {
            ...CompanyDetails
        }
    }
    ${CompanyDetailsFragment}
`;

import { gql } from '@apollo/client';

import { ImplementationSubTaskSingleFragment } from '../fragments';

export const GetSubTaskQuery = gql`
    query GetSubTasks($taskId: Int, $recommendationId: Int, $companyId: Int) {
        listSubTask(
            task_id: $taskId
            recommendation_id: $recommendationId
            company_id: $companyId
        ) {
            ...SubTaskSingle
        }
    }
    ${ImplementationSubTaskSingleFragment}
`;

import { gql } from '@apollo/client';

export const GetUserHighRoleQuery = gql`
    query GetUserHighestRole {
        getUserHighestRole {
            id
            title
            description
            icon_link
            url_link
            welcome_role
            welcome_order
        }
    }
`;

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';

import type { QueryHookOptions } from '@apollo/client';
import type { GetSubTaskQueryType, GetSubTaskQueryVariables } from '@modules/types/graphql';
import { GetSubTaskQuery } from '../graphql';
import { SubTaskEntityList } from '../entities';

const useSubTasks = (options?: QueryHookOptions<GetSubTaskQueryType, GetSubTaskQueryVariables>) => {
    const queryResult = useQuery<GetSubTaskQueryType, GetSubTaskQueryVariables>(
        GetSubTaskQuery,
        options,
    );

    const { entities: subTask, count } = useEntity(
        () => new SubTaskEntityList(queryResult.data?.listSubTask ?? null),
        [queryResult.data],
    );

    const result = {
        subTask,
        count,
        ...queryResult,
    };

    return result;
};

export { useSubTasks };

import React from 'react';
import styled, { css } from 'styled-components';
import { scoreVariantColorsMap } from '@modules/shared/constants';
import { AccordionInnerListItem } from '@modules/layout/organisms';
import { gray } from '@modules/ui/colors';
import { RecommendationAssessmentRoadmapReport } from '@modules/roadmap/recommendation-assessment-roadmap-report';
import { ProjectEntity } from '@modules/projects/entities';
import { ReportPrioritizedRoadmapStatusListSummary } from './report-prioritized-roadmap-status-list-summary';

type ReportPrioritizedRoadmapListSummaryProps = {
    recommendations: RecommendationAssessmentRoadmapReport[];
    project: ProjectEntity;
    withStatus?: boolean;
    riskLevelKey: string;
    label: string;
    open: boolean;
    onExpand?: (panel: string) => (event: React.ChangeEvent<{}>, expanded: boolean) => void;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 16px;
    background-color: ${gray[60]};
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;

    > * {
        width: 20%;
        font-size: 1.4rem;
    }
`;

const InnerHeaderExtra = styled.div`
    > h3 {
        margin-bottom: 6px;
        font-weight: 700;
    }
`;

const ItemTitle = styled.div<Pick<ReportPrioritizedRoadmapListSummaryProps, 'riskLevelKey'>>`
    width: 100%;

    > h3 {
        display: inline-flex;
        padding: 4px;
        font-size: 2.2rem;
        font-weight: 700;
        border-radius: 4px;

        ${p => css`
            color: ${scoreVariantColorsMap[p.riskLevelKey].color};
            background-color: ${scoreVariantColorsMap[p.riskLevelKey].background};
        `}
    }
`;

export const ReportPrioritizedRoadmapListSummary = (
    props: ReportPrioritizedRoadmapListSummaryProps,
): React.ReactElement => {
    const { riskLevelKey, label, recommendations, project, withStatus, open } = props;
    const [expanded, setExpanded] = React.useState<boolean>(open);
    let onExpand = (event: React.ChangeEvent<{}>, isExpanded: boolean) => setExpanded(!expanded);
    return (
        <Root>
            <AccordionInnerListItem
                title={''}
                subtitle={''}
                expanded={expanded}
                onChange={onExpand}
                SummaryInfoComponents={
                    <Header>
                        <InnerHeaderExtra>
                            <ItemTitle riskLevelKey={riskLevelKey}>
                                <h3>{label}</h3>
                            </ItemTitle>
                        </InnerHeaderExtra>
                    </Header>
                }
            >
                <ReportPrioritizedRoadmapStatusListSummary
                    key={riskLevelKey}
                    riskLevelKey={riskLevelKey}
                    label={label}
                    open={open}
                    withStatus={withStatus}
                    recommendations={recommendations}
                    project={project}
                />
            </AccordionInnerListItem>
        </Root>
    );
};

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Auth from '@aws-amplify/auth';
import * as yup from 'yup';
import QRCode from 'qrcode.react';
import { Formik, Form } from 'formik';
import { FormControl } from '@material-ui/core';
import { useMountedState } from 'react-use';
import { useNavigate } from '@reach/router';
import { routes } from '@config/routes';

import { Button } from '@modules/ui/core';
import { white } from '@modules/ui/colors';
import { Hint } from '@modules/layout/atoms';
import { TextField } from '@modules/layout/moleculas';
import { useEnqueueStacks } from '@modules/layout/hooks';

import type { CognitoUser } from 'amazon-cognito-identity-js';

type SetupMFAFormProps = {
    user: CognitoUser | any;
    className?: string;
    onSubmit: (user: CognitoUser | any) => void;
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 450px;
    width: 100%;
    padding: 24px;
    background-color: ${white[100]};
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    > * {
        width: 100%;
        letter-spacing: 0.5px;
        text-align: center;
    }

    > h3 {
        font-size: 3rem;
        text-transform: uppercase;
    }

    > p {
        margin-top: 8px;
        font-size: 1.4rem;
    }
`;

const Main = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 24px;
`;

const StyledFrom = styled(Form)`
    width: 100%;

    > * {
        margin-bottom: 18px;
    }
`;

const CodeWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 0 24px;
`;

const SetupMFAForm = (props: SetupMFAFormProps): React.ReactElement => {
    const { user, onSubmit, ...otherProps } = props;
    const [code, setCode] = useState<string>('');

    const isMounted = useMountedState();
    const navigate = useNavigate();
    const { enqueueError } = useEnqueueStacks();

    useEffect(() => {
        async function setup() {
            try {
                const setupTOTPCode = await Auth.setupTOTP(user);

                if (setupTOTPCode) {
                    const urlForQR = `otpauth://totp/AWSCognito:${user?.username}?secret=${setupTOTPCode}&issuer=RiskTKO`;
                    setCode(urlForQR);
                }
            } catch (e: any) {
                enqueueError(e.message || 'Unexepected error');
            }
        }

        setup();
    }, []);

    return (
        <Root {...otherProps}>
            <Header>
                <h3>Setup MFA</h3>
                <p>
                    Scan the QR code below in The Google Authenticator app for{' '}
                    <a
                        href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Android
                    </a>
                    ,
                    <a
                        href='https://apps.apple.com/en/app/google-authenticator/id388497605'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        iPhone
                    </a>
                    , or BlackBerry to generate verification codes. It even works when your device
                    has no phone or data connectivity.
                </p>
            </Header>

            <Main>
                {code ? (
                    <CodeWrapper>
                        <QRCode value={code} />
                    </CodeWrapper>
                ) : (
                    ''
                )}

                <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={yup.object({
                        code: yup.string().required('Required field'),
                    })}
                    initialValues={{
                        code: '',
                    }}
                    initialStatus={{
                        error: '',
                    }}
                    onSubmit={async (values, helpers) => {
                        const { setStatus, setSubmitting } = helpers;

                        setSubmitting(true);

                        try {
                            await Auth.verifyTotpToken(user, values.code);

                            await Auth.setPreferredMFA(user, 'TOTP');
                            onSubmit(user);
                            await navigate(routes.welcome.path);
                        } catch (e: any) {
                            setStatus({ error: 'message' in e ? e.message : 'Unexepected error' });
                        } finally {
                            if (isMounted()) {
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {formikProps => {
                        const {
                            status,
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            isSubmitting,
                        } = formikProps;

                        return (
                            <StyledFrom>
                                <TextField
                                    fullWidth
                                    id='code'
                                    label='Verification code'
                                    placeholder='Verification code'
                                    value={values.code}
                                    error={!!errors.code}
                                    helperText={errors.code}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <FormControl fullWidth>
                                    <Button type='submit' loading={isSubmitting}>
                                        Submit
                                    </Button>

                                    {!!status.error ? <Hint error text={status.error} /> : null}
                                </FormControl>
                            </StyledFrom>
                        );
                    }}
                </Formik>
            </Main>
        </Root>
    );
};

export { SetupMFAForm };

import React from 'react';
import styled, { css } from 'styled-components';
import { useParams, Link } from '@reach/router';

import { white, gray } from '@modules/ui/colors';
import { getMenuRoutes, createRouteUrl } from '@lib/routing';
import { RouteMenuLocationEnum } from '@config/routes';
import { useCurrentUser } from '@modules/auth/hooks';
import { useProjectRoles } from '@modules/projects/hooks';
import { ProjectStatusEnum } from '@modules/projects/constants';

type AssessmentFlowMenuProps = {
    status: ProjectStatusEnum;
    assessmentTypeId?: number;
    isRiskRegistry?: boolean;
};

const Root = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;

    @media print {
        display: none;
    }
`;

const flowColor = '#4472C4';

const Step = styled.div<{
    zIndex: number;
    active?: boolean;
    last?: boolean;
    first?: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 220px;
    min-width: 200px;
    height: 88px;
    position: relative;
    color: white;
    background-color: ${flowColor};
    opacity: ${p => (!!p.active ? 1 : 0.5)};
    z-index: ${p => p.zIndex};

    > *:first-child {
        margin-left: 24px;
    }

    ${p =>
        !p.first
            ? css`
                  margin-left: 10px;

                  &:after {
                      content: '';
                      position: absolute;
                      left: 0;
                      bottom: 0;
                      width: 0;
                      height: 0;
                      border-left: 20px solid ${white[100]};
                      border-top: 44px solid transparent;
                      border-bottom: 44px solid transparent;
                  }
              `
            : css`
                  border-radius: 8px 0 0 8px;

                  > *:first-child {
                      margin-left: 0;
                  }
              `}

    ${p =>
        !p.last
            ? css`
                  &:before {
                      content: '';
                      position: absolute;
                      right: -20px;
                      bottom: 0;
                      width: 0;
                      height: 0;
                      border-left: 20px solid ${flowColor};
                      border-top: 44px solid transparent;
                      border-bottom: 44px solid transparent;
                  }
              `
            : css`
                  border-radius: 0 8px 8px 0;
              `}
`;

const StepTitle = styled.h4`
    width: 100%;
    font-size: 1.8rem;
    font-weight: 400;
`;

const StepSubtitle = styled.p`
    width: 100%;
    font-size: 1.4rem;
`;

const statuses = Object.values(ProjectStatusEnum);

const AssessmentFlowMenu = (props: AssessmentFlowMenuProps): React.ReactElement => {
    const { status, assessmentTypeId, isRiskRegistry } = props;

    const params = useParams();
    const projectId = params.id;

    const { currentUser, loading: currentUserLoading } = useCurrentUser();
    const { projectRolesEntityList, loading: projectRolesEntityListLoading } = useProjectRoles({
        skip: !projectId,
        variables: { projectId: projectId as number },
    });

    const hasStRole = projectRolesEntityList.hasStakeholder();

    let menu = getMenuRoutes(RouteMenuLocationEnum.assessment, {
        isAdmin: currentUser.hasAdminPermission() || hasStRole,
    });

    // The Impact workflow is limited to Assessment. The Vulnerability and Threat workflows include: Assessment, Roadmap, and Implementation.
    if (isRiskRegistry && assessmentTypeId === 1) {
        menu = [menu[0]]
    }

    if (currentUserLoading || projectRolesEntityListLoading) {
        return <Root />;
    }

    return (
        <Root>
            {menu.map((menuItem, index) => {
                const [, currentType] = menuItem.name.split('.');

                const url = createRouteUrl(menuItem, { id: projectId });

                const currentStatusIdx = statuses.findIndex(item => item === status);
                const currentTypeIdx = statuses.findIndex(
                    item => item.replace(' ', '') === currentType,
                );

                const first = index === 0;
                const last = index === menu.length - 1;
                const zIndex = menu.length - index;

                const isDisabled = currentStatusIdx < currentTypeIdx;
                const hasFinishPhase = currentStatusIdx > 1;

                if (isDisabled) {
                    return (
                        <Step key={currentType} first={first} last={last} zIndex={zIndex}>
                            <div>
                                <StepTitle>{menuItem.title}</StepTitle>
                            </div>
                        </Step>
                    );
                }

                return (
                    <Link key={currentType} to={url}>
                        <Step active key={currentType} first={first} last={last} zIndex={zIndex}>
                            <div>
                                <StepTitle>{menuItem.title}</StepTitle>

                                {hasFinishPhase && currentStatusIdx > currentTypeIdx ? (
                                    <StepSubtitle>Report</StepSubtitle>
                                ) : null}
                            </div>
                        </Step>
                    </Link>
                );
            })}
        </Root>
    );
};

export { AssessmentFlowMenu };

import qs from 'query-string';
import { useNavigate, useLocation } from '@reach/router';

import type { ParsedQuery } from 'query-string';

export type LocationQuery = [ParsedQuery<string>, (query?: Record<string, any>) => void];

const useLocationQuery = (): LocationQuery => {
    const location = useLocation();
    const navigate = useNavigate();

    const initialQuery = qs.parse(location.search);

    const setQuery = (query?: Record<string, any>) => {
        if (!query) {
            navigate(location.pathname);
            return;
        }

        const result = qs.stringify(query, { skipEmptyString: true });

        navigate(`${location.pathname}?${result}`);
    };

    return [initialQuery, setQuery];
};

export { useLocationQuery };

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';

import type { QueryHookOptions } from '@apollo/client';
import type { GetListS3DataQueryType } from '@modules/types/graphql';
import { GetS3DataQuery } from '../graphql/queries';
import { S3DataEntityList } from '../entities';

const useS3VideoLink = (options?: QueryHookOptions<GetListS3DataQueryType>) => {
    const queryResult = useQuery<GetListS3DataQueryType>(GetS3DataQuery, options);

    const { entities: s3data, count } = useEntity(
        () => new S3DataEntityList(queryResult.data?.listS3Data ?? null),
        [queryResult.data],
    );

    const result = {
        s3data,
        count,
        ...queryResult,
    };

    return result;
};

export { useS3VideoLink };

import React from 'react';
import styled from 'styled-components';
import { useNavigate } from '@reach/router';

import { Button } from '@modules/ui/core';
import { routes } from '@config/routes';

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
`;

const Actions = styled.div``;

const ButtonCreateLink = styled(Button)`
    min-width: 205px;
`;

const CompaniesListToolbar = (): React.ReactElement => {
    const navigate = useNavigate();

    const handleClickRedirect = (): void => {
        navigate(routes.companyCreate.path);
    };

    return (
        <Root>
            <Actions>
                <ButtonCreateLink onClick={handleClickRedirect}>
                    Create new company
                </ButtonCreateLink>
            </Actions>
        </Root>
    );
};

export { CompaniesListToolbar };

import { gql } from '@apollo/client';

import { CapabilitySingleFragment } from '../fragments';

export const GetCapabilityQuery = gql`
    query GetCapability($capabilityId: Int!) {
        getCapability(capability_id: $capabilityId) {
            ...CapabilitySingle
        }
    }
    ${CapabilitySingleFragment}
`;

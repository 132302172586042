import { EntityList } from '@lib/entity';
import { OrgLocationEntity } from './org-location-entity';

class OrgLocationEntityList extends EntityList<OrgLocationEntity> {
    constructor(items: any[] | null, count?: number | null) {
        super(OrgLocationEntity, items, count);
    }
}

export { OrgLocationEntityList };

import { EntityList } from '@lib/entity';
import { RiskFunctionEntity } from './risk-function-entity';

import type { Entity } from './risk-function-entity';


class RiskFunctionEntityList extends EntityList<RiskFunctionEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(RiskFunctionEntity, items, count);
    }
}

export { RiskFunctionEntityList };

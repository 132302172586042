import { useQuery } from '@apollo/client';
import { GetRiskRegistryUsersQuery } from '@modules/registry/graphql';
import {RiskRegistryUser} from '@modules/types/graphql';

const useRiskRegistryUsers = (options?: any) => {
    const queryResult = useQuery(GetRiskRegistryUsersQuery, options);

    const users: {listRiskRegistryUsers: RiskRegistryUser[]} = queryResult?.data;

    return { users, ...queryResult };
};

export { useRiskRegistryUsers };

import { useQuery } from '@apollo/client';
import type { QueryHookOptions } from '@apollo/client';
import { GetRiskGroupQuery } from '../graphql';
import { useEntity } from '@lib/entity';
import { RiskGroupEntityList } from '../entities';

const useGetRiskGroup = (options?: QueryHookOptions) => {
    const queryResult = useQuery(GetRiskGroupQuery, options);
    const { entities: riskData, count } = useEntity(
        () => new RiskGroupEntityList(queryResult.data?.getRiskGroup ?? null),
        [queryResult.data],
    );

    const result = {
        riskData,
        count,
        ...queryResult,
    };

    return result;
};

export { useGetRiskGroup };
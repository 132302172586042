export {
    LearnTkoMenuFragment,
    LearnTkoFragment,
    LearnCardMenuFragment,
    LearnTkoDetailsFragment,
} from './fragments';
export {
    GetLearnTkoMenuQuery,
    GetLearnTkoMenuListQuery,
    GetLearnTkoByIdQuery,
    GetAllLearnTkoDetailQuery,
    GetLearnTkoQuery,
    GetAllMenuCardQuery,
    GetSingleMenuCardQuery,
} from './queries';
export {
    CreateMenuCardMutation,
    CreateLearnTkoMutation,
    DeleteLearnTkoDetailsMutation,
    UpdateLearnTkoDetailsMutation,
    UpdateMenuCardMutation,
} from './mutations';

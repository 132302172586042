import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';

import { ListStyled } from '@modules/layout/styled';
import { ReportPrioritizedRoadmapListSummary } from './report-prioritized-roadmap-list-summary';

import type { PrioritizedRoadmapEntity } from '@modules/roadmap/entities';
import { useMutation } from '@apollo/client';
import { RearrangeRecommendationMutation } from '@modules/roadmap/graphql';
import { ProjectEntity } from '@modules/projects/entities';
import { GetAssignedProjectQuery } from '@modules/projects/graphql';
import { Loader } from '@modules/layout/moleculas';
import {
    gapLevelFieldLabelList,
    gapLevelFieldList,
    recommendationLevelFieldLabelList,
    recommendationLevelFieldList,
} from '@modules/assessment/entities/capability-report-entity';
import {
    GapRiskLevel,
    GapRiskLevelList,
    RiskLevel10PointKeyList,
} from '@modules/shared/constants/risk-level';
import { ComponentRecommendationEntity } from '@modules/roadmap/entities';
import {
    getPrioritizedRoadmapAssessmentMap,
    RecommendationAssessment,
    RecommendationAssessmentRoadmapReport,
} from '@modules/roadmap/recommendation-assessment-roadmap-report';
import { usePropState } from '@modules/shared/hooks/use-prop-state';

type ReportPrioritizedRoadmapListProps = {
    prioritizedRoadmap: PrioritizedRoadmapEntity;
    project: ProjectEntity;
    // assessmentMap?: Map<number, ComponentAssessmentEntity>;
    withStatus?: boolean;
};

const Root = styled(ListStyled.List)`
    margin-top: 0;

    > *:not(:last-of-type) {
        margin-bottom: 24px;
    }
`;

const ReportPrioritizedRoadmapList = (
    props: ReportPrioritizedRoadmapListProps,
): React.ReactElement => {
    const { prioritizedRoadmap, withStatus, project } = props;
    const [rearrangeRecommendation, { loading: rearrangeRecommendationLoading }] = useMutation(
        RearrangeRecommendationMutation,
    );

    const riskLevelList: string[] = Object.values(GapRiskLevel);
    riskLevelList.reverse();

    const roadmapRecommendationMap: Map<number, RecommendationAssessment> = useMemo(() => {
        return getPrioritizedRoadmapAssessmentMap(project);
    }, [project.assessments]);

    function recommendationExtendMap(
        componentRecommendation: ComponentRecommendationEntity,
        index: number,
    ): RecommendationAssessmentRoadmapReport {
        let recommendationExtra = roadmapRecommendationMap.get(
            componentRecommendation.recommendation.id,
        ) as RecommendationAssessmentRoadmapReport;
        recommendationExtra.componentRecommendation = componentRecommendation;

        return recommendationExtra;
    }

    function buildPrioritizedMap() {
        return riskLevelList.reduce(
            (
                data: Record<string, RecommendationAssessmentRoadmapReport[]>,
                key: string,
                index: number,
            ) => {
                data[key] = prioritizedRoadmap[recommendationLevelFieldList[index]].map(
                    recommendationExtendMap,
                );
                return data;
            },
            {},
        );
    }

    const [prioritizedRecommendations, setPrioritizedRecommendations] = useState(
        buildPrioritizedMap(),
    );

    useEffect(() => {
        setPrioritizedRecommendations(buildPrioritizedMap());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prioritizedRoadmap]);

    const availableRecommendationLevelFields = riskLevelList.filter(
        riskLevelKey => prioritizedRecommendations[riskLevelKey],
    );

    // const availableRecommendationLevelFieldLabels = recommendationLevelFieldLabels.filter((riskLevelKey, index) => prioritizedRecommendations[riskLevelList[index]]);
    // const availableRecommendationLevelFields = riskLevelList;
    const availableRecommendationLevelFieldLabels = recommendationLevelFieldLabelList;

    // if (rearrangeRecommendationLoading) {
    //     return <Loader />;
    // }

    return (
        <Root>
            <DragDropContext
                onDragEnd={async result => {
                    const { draggableId, destination, source } = result;

                    let srcIndex = source?.droppableId as string;
                    let destIndex = destination?.droppableId as string;
                    if (
                        !srcIndex ||
                        !destIndex ||
                        !prioritizedRecommendations[srcIndex] ||
                        !prioritizedRecommendations[destIndex]
                    ) {
                        console.warn(
                            'D&D params invalid, see details: srcIndex, destIndex, prioritizedRecommendations',
                            srcIndex,
                            destIndex,
                            prioritizedRecommendations,
                        );
                        return;
                    }

                    setPrioritizedRecommendations(recommendations => {
                        const [removed] = recommendations[srcIndex].splice(source.index, 1);
                        recommendations[destIndex].splice(destination?.index ?? 0, 0, removed);
                        return recommendations;
                    });

                    const { data } = await rearrangeRecommendation({
                        variables: {
                            roadmapRecommendationId: Number(draggableId),
                            newIndex: destination?.index,
                            newRiskLevel: destination?.droppableId,
                        },
                        refetchQueries: [
                            { query: GetAssignedProjectQuery, variables: { id: project.id } },
                        ],
                        awaitRefetchQueries: true,
                    });
                }}
            >
                {availableRecommendationLevelFields.length ? (
                    availableRecommendationLevelFields.map((riskLevelKey, index) => {
                        let openIfEmpty = !prioritizedRecommendations[riskLevelKey].length;
                        const recommendations: any =
                            prioritizedRecommendations[riskLevelKey].length &&
                            prioritizedRecommendations[riskLevelKey];

                        return recommendations?.length ? (
                            <ReportPrioritizedRoadmapListSummary
                                key={riskLevelKey}
                                riskLevelKey={riskLevelKey}
                                label={availableRecommendationLevelFieldLabels[index]}
                                open={openIfEmpty}
                                withStatus={withStatus}
                                recommendations={recommendations}
                                project={project}
                            />
                        ) : (
                            ''
                        );
                    })
                ) : (
                    <ListStyled.Empty>Recommendations not found</ListStyled.Empty>
                )}
            </DragDropContext>
        </Root>
    );
};

export { ReportPrioritizedRoadmapList };

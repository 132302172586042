import { gql } from '@apollo/client';
import { AddAssessmentCategoriesFragment } from '../fragments';

export const ListAssessmentCategoriesQuery = gql`
    query ListAssessmentCategories {
        listAssessmentCategories {
            ...AssessmentCategories
        }
    }
    ${AddAssessmentCategoriesFragment}
`;

import {
    CapabilityAssessmentEntity,
    ComponentAssessmentEntity,
} from '@modules/assessment/entities';
import {RoadmapRecommendationEntity, ScoredGapEntity} from '@modules/roadmap/entities';
import {RecommendationAssessment} from '@modules/roadmap/recommendation-assessment-roadmap-report';
import {ImplementationStatus, ImplementationStatusList} from '@modules/shared/constants/implementation-status';
import {isRoadmapStatusAllowed} from '@modules/shared/constants/recommendation-status';
import {GapByRisk, GapCapabilityAssessment} from '@modules/assessment/gap-by-risk';
import {recommendationLevelFieldList} from '@modules/assessment/entities/capability-report-entity';
import {RecommendationRiskLevelField, RiskLevel10PointKeyMap} from '@modules/shared/constants/risk-level';

type RecommendationAssessmentMapComposite = {
    componentAssessmentMap: Map<number, RecommendationComponentAssessment>;
    componentAssessmentListMap: Map<number, RecommendationCapabilityAssessment[]>;
}

export type RecommendationComponentAssessment = {
    recommendationCapabilityList: RecommendationCapabilityAssessment[];
    statusCountMap: Map<string, number>;
}

export type RecommendationCapabilityAssessment = {
    canShowCapability?: boolean;
    isEmptyRecord?: boolean;
} & RecommendationAssessment;

export type RecommendationViewData = {
    recommendationByRisk: RecommendationByRisk;
    scoreList: RecommendationCapabilityAssessment[];
    // recommendationRiskList: RecommendationCapabilityAssessment[];
    componentAssessmentMap: Map<number, RecommendationComponentAssessment>
    componentAssessmentListMap: Map<number, RecommendationCapabilityAssessment[]>
};

export class RecommendationByRisk {
    superCriticalRecommendations: RecommendationCapabilityAssessment[] = [];
    criticalRecommendations: RecommendationCapabilityAssessment[] = [];
    highCriticalRecommendations: RecommendationCapabilityAssessment[] = [];
    highRecommendations: RecommendationCapabilityAssessment[] = [];
    mediumHighRecommendations: RecommendationCapabilityAssessment[] = [];
    mediumRecommendations: RecommendationCapabilityAssessment[] = [];
    lowMediumRecommendations: RecommendationCapabilityAssessment[] = [];
    lowRecommendations: RecommendationCapabilityAssessment[] = [];
    minimalLowRecommendations: RecommendationCapabilityAssessment[] = [];
    minimalRecommendations: RecommendationCapabilityAssessment[] = [];

    static assessmentRecommendationList(componentAssessment: ComponentAssessmentEntity): RecommendationComponentAssessment {
        let statusCountMap: Map<string, number> = new Map(
          ImplementationStatusList.map((key: string, index: number) => [key, 0])
        );

        let capabilityAssessmentPrev: CapabilityAssessmentEntity|null = null;
        let canShowCapability = true;
        let recommendationCapabilityList: RecommendationCapabilityAssessment[] = componentAssessment?.capabilities.map((capabilityAssessment: CapabilityAssessmentEntity) => {
            return (capabilityAssessment?.scoredGaps
                .filter(value => value) as ScoredGapEntity[])
                .map((scoredGap: ScoredGapEntity) => {
                    return (scoredGap.roadmapRecommendations
                        // filter to be align with assessment report roadmap results
                        .filter(value => isRoadmapStatusAllowed(value?.refinement?.status)) as RoadmapRecommendationEntity[])
                        .map((roadmapRecommendation: RoadmapRecommendationEntity) => {
                            canShowCapability = capabilityAssessmentPrev !== capabilityAssessment;
                            capabilityAssessmentPrev = capabilityAssessment;

                            statusCountMap.set(roadmapRecommendation.status, (statusCountMap.get(roadmapRecommendation.status) ?? 0) + 1)

                            return {
                                componentAssessment,
                                capabilityAssessment,
                                scoredGap,
                                roadmapRecommendation,
                                canShowCapability,
                            };
                        }) ?? [];
            }) ?? [];
        }).flat(2) ?? [];

        if (statusCountMap.size > ImplementationStatusList.length) {
            statusCountMap = new Map<string, number>(
              Array.from(statusCountMap).filter((data: [string, number]) => Boolean(ImplementationStatus[data[0]]))
            );
        }

        return {
            recommendationCapabilityList,
            statusCountMap
        }
    }

    static getRecommendationEmptyAssessmentList(componentAssessment: ComponentAssessmentEntity): RecommendationCapabilityAssessment[] {
        let recommendationCapabilityList: RecommendationCapabilityAssessment[] = [];

        let capabilityAssessmentPrev: CapabilityAssessmentEntity|null = null;
        let canShowCapability = true;
        componentAssessment.capabilities?.forEach((capabilityAssessment: CapabilityAssessmentEntity) => {
            let scoredGapList = capabilityAssessment?.scoredGaps?.length ? capabilityAssessment?.scoredGaps : [null];
            scoredGapList.forEach((scoredGap: ScoredGapEntity|null) => {
                if (Boolean(scoredGap?.roadmapRecommendations?.length)) {
                    return ;
                }

                canShowCapability = capabilityAssessmentPrev !== capabilityAssessment;
                capabilityAssessmentPrev = capabilityAssessment;

                recommendationCapabilityList.push({
                    componentAssessment,
                    capabilityAssessment,
                    scoredGap: scoredGap as any as ScoredGapEntity,
                    roadmapRecommendation: null as unknown as RoadmapRecommendationEntity,
                    canShowCapability: canShowCapability,
                    isEmptyRecord: true
                });
            });
        });

        return recommendationCapabilityList;
    }

    static createRecommendationByRiskFromList(riskList: RecommendationCapabilityAssessment[]) {
        const recommendationByRisk = new RecommendationByRisk();
        recommendationLevelFieldList.forEach((riskLevelKey) => {
            riskList.filter((recommendationData: RecommendationCapabilityAssessment) => {
                return RecommendationRiskLevelField[RiskLevel10PointKeyMap[recommendationData.scoredGap.riskLevel]] === riskLevelKey;
            })
            .forEach((recommendationData: RecommendationCapabilityAssessment) => {
                recommendationByRisk[riskLevelKey].push(recommendationData);
            });
        });
        return recommendationByRisk;
    }

    static recommendationAssessmentByRisk(assessments: ComponentAssessmentEntity[]): RecommendationViewData {
        const componentAssessmentMap = RecommendationByRisk.recommendationAssessmentByComponentMap(assessments);
        return RecommendationByRisk.recommendationAssessmentFromPreparedMap(componentAssessmentMap);
    }

    static recommendationAssessmentByComponentMap(assessments: ComponentAssessmentEntity[]): RecommendationAssessmentMapComposite {
        const componentAssessmentMap = new Map<number, RecommendationComponentAssessment>();
        const componentAssessmentListMap = new Map<number, RecommendationCapabilityAssessment[]>();
        assessments.forEach((componentAssessment: ComponentAssessmentEntity) => {
            let resultData = RecommendationByRisk.assessmentRecommendationList(componentAssessment);
            componentAssessmentMap.set(componentAssessment.id, resultData);
            componentAssessmentListMap.set(componentAssessment.id, resultData.recommendationCapabilityList);
        });

        return {
            componentAssessmentMap,
            componentAssessmentListMap
        };
    }

    static recommendationAssessmentFromPreparedMap(recommendationMapComposite: RecommendationAssessmentMapComposite): RecommendationViewData {
        const {componentAssessmentMap, componentAssessmentListMap} = recommendationMapComposite;
        const scoreList: RecommendationCapabilityAssessment[] = Array.from(componentAssessmentListMap.values()).flat();
        GapByRisk.sortGapListByScore(scoreList as unknown as GapCapabilityAssessment[]);

        // const recommendationRiskList: RecommendationCapabilityAssessment[] = [...recommendationScoreList];
        // GapByRisk.sortGapListByRiskLevel(recommendationRiskList as GapCapabilityAssessment[]);
        // const recommendationByRisk = RecommendationByRisk.createRecommendationByRiskFromList(recommendationRiskList);

        const recommendationByRisk = RecommendationByRisk.createRecommendationByRiskFromList(scoreList);
        return {recommendationByRisk, scoreList, componentAssessmentMap, componentAssessmentListMap};
    }
}

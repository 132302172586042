import { EntityList } from '@lib/entity';
import { RoleEntity } from './role-entity';

import type { Entity } from './role-entity';

class RoleEntityList extends EntityList<RoleEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(RoleEntity, items, count);
    }

    getRoles() {
        return this.entities;
    }

    hasRoles() {
        const result = this.count !== 0;

        return result;
    }

    hasOperator() {
        const result = !!this.entities.find(entity => entity.id === 'OP');

        return result;
    }

    hasComponentOwner() {
        const result = !!this.entities.find(entity => entity.id === 'CO');

        return result;
    }

    hasStakeholder() {
        const result = !!this.entities.find(entity => entity.id === 'ST');

        return result;
    }

    hasProgramManager() {
        const result = !!this.entities.find(entity => entity.id === 'PM');

        return result;
    }
}

export { RoleEntityList };

import React, { FormEvent, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { useMutation } from '@apollo/client';

import { Autocomplete, Button, Modal } from '@modules/ui/core';
import { gray } from '@modules/ui/colors';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { useProjectRoles } from '@modules/projects/hooks';
import {
    GetProjectImplementationQuery,
    StartImplementationMutation,
    CompleteImplementationMutation,
    AssignRecommendationToOperatorMutation,
    GetRecommendationImpTasksQuery,
} from '@modules/implementation/graphql';

import type { ProjectEntity } from '@modules/projects/entities';
import type { RecommendationImplementationEntity } from '@modules/implementation/entities';
import type {
    StartImplementationMutationType,
    StartImplementationMutationVariables,
    CompleteImplementationMutationType,
    CompleteImplementationMutationVariables,
    CreateImplementationTaskMutationType,
    CreateImplementationTaskMutationVariables,
} from '@modules/types/graphql';
import { UserListEntity } from '@modules/users/entities';
import { useUsers } from '@modules/users/hooks';
import { useCurrentUser } from '@modules/auth/hooks';
import { AddNotesForm } from '@modules/implementation/moleculas/implementation-recommendations-list/implementation-recommendations-summary';
import { RemoveRedEyeRounded, VisibilityOff } from '@material-ui/icons';
import { useChatTaskSubTask } from '@helpers';
import { CreateImplementationTaskMutation } from '@modules/implementation/graphql/mutations';
import { ImpTaskCreateRequest } from '@modules/custom-components/requests';
import { ImplementationTaskList } from '../implementation-task-list';
import { useImplementationTasks } from '@modules/implementation/hooks/use-implementation-task';
import { AccordionListItem } from '@modules/layout/organisms';
import { ListItemStyled } from '@modules/layout/styled';
import { GetImplementationTaskQuery } from '@modules/implementation/graphql/queries/get-implementation-task';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import { Loader } from '@modules/layout/moleculas';
import { AuthContext } from '@modules/auth/context';

type ImplementationRecommendationsListItemProps = {
    project: ProjectEntity;
    recommendationImplementation: RecommendationImplementationEntity;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 16px;
    background-color: ${gray[60]};
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
`;

const Title = styled.div`
    flex-basis: 60%;

    > h4 {
        width: 100%;
        font-size: 1.8rem;
        font-weight: 700;
    }

    > p {
        width: 100%;
        margin-top: 8px;
        color: ${gray[30]};
        font-size: 1.4rem;
    }
`;

const Info = styled.div`
    font-size: 1.6rem;
    text-align: center;
`;
const InfoDone = styled.div`
    display: flex;
    align-items: center;
`;
const SummaryInfoStatus = styled(ListItemStyled.SummaryInfo)`
    margin-left: 32px;
`;

const EyeInfo = styled.div`
    > svg {
        font-size: 4rem;
        text-align: center;
        cursor: pointer;
        margin-right: 35px;
    }
    &:hover {
        color: #5a5a5a;
    }
`;

const Additional = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;

    h4 {
        font-size: 16px;
    }
`;

const AdditionalActions = styled.div`
    display: flex;
    align-items: center;
    padding: 20px 0 14px;
`;

const AdditionalHiddenBlock = styled.div`
    border-top: 1px solid #ccc;
    padding: 15px 0 0;
    margin: 15px 0 0;
`;

const AdditionalHiddenBlockSection = styled.div`
    padding: 0 0 10px;
`;

const AdditionalAssigned = styled.div`
    font-size: 14px;
    padding: 10px 0 0;

    strong {
        display: inline-block;
        padding-right: 5px;
    }
`;
const StyledNote = styled.div`
    padding: 10px 10px 15px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 6px;

    .is-username {
        color: #727272;
        font-size: 14px;
    }

    .is-time {
        color: #727272;
        font-size: 14px;
        display: inline-block;
        padding-left: 10px;
    }

    .is-note {
        font-size: 14px;
        padding-top: 10px;
    }
`;
const List = styled.div`
    width: 100%;
    margin-top: 24px;

    > * {
        margin-bottom: 12px;
    }
`;

const TaskItem = styled.div`
    width: 100%;
    padding: 12px;
    font-size: 1.7rem;
`;

const EmptyGaps = styled(TaskItem)`
    height: initial;
    padding: 0 4px;
    font-size: 1.6rem;
    font-weight: 700;

    && {
        border: 0;
    }
`;
const ImplementationRecommendationsListItem = (
    props: ImplementationRecommendationsListItemProps,
): React.ReactElement => {
    const { project, recommendationImplementation } = props;
    const { currentUser } = useCurrentUser();
    const {
        notesLog,
        constraintsLog,
        dependenciesLog,
    } = recommendationImplementation?.recommendationRefinement;
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [chatGptQuery, setChatGptQuery] = useState<any>('');
    const [showNotes, setShowNotes] = useState(false);
    const [operator, setOperator] = useState<UserListEntity | null>();
    const { projectRolesEntityList } = useProjectRoles({ variables: { projectId: project.id } });
    const { users, loading: usersLoading } = useUsers();
    const [response, chatloading, error, fetchResponse] = useChatTaskSubTask();
    const { operators } = recommendationImplementation;
    const hasPmRole = projectRolesEntityList.hasProgramManager();
    const hasCORole = projectRolesEntityList.hasComponentOwner();
    const hasOPRole = projectRolesEntityList.hasOperator();

    const isPending = recommendationImplementation.isPending();
    const inProgress = recommendationImplementation.inProgress();
    const isDone = recommendationImplementation.isDone();
    const isUserHasOpRole = !!operators?.filter(operator => operator?.id === currentUser.id).length;

    const [expanded, setExpanded] = React.useState<number | boolean>(false);

    const handleExpandComponent = (panel: number) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean,
    ) => setExpanded(isExpanded ? panel : false);

    // const isActionAvailable = hasPmRole || hasCoRole || hasOpRole || isUserHasOpRole;
    const isActionAvailable = true;

    const [startImplementation, { loading: startImplementationLoading }] = useMutation<
        StartImplementationMutationType,
        StartImplementationMutationVariables
    >(StartImplementationMutation);

    const [completeImplementation, { loading: completeImplementationLoading }] = useMutation<
        CompleteImplementationMutationType,
        CompleteImplementationMutationVariables
    >(CompleteImplementationMutation);

    const queryStart = `What are the tasks needed to complete the following recommendation: `;

    const handleSubmitAction = async (): Promise<void> => {
        if (isPending) {
            const { data: startImplementationData } = await startImplementation({
                variables: {
                    roadmapRecommendationId: recommendationImplementation.roadmapRecommendationId,
                },
                refetchQueries: [
                    { query: GetProjectImplementationQuery, variables: { id: project.id } },
                ],
                awaitRefetchQueries: true,
            });
            if (!startImplementationData?.startImplementation?.roadmap_recommendation_id) {
                enqueueSuccess('Recommendation implementation successfully started!');
            } else {
                enqueueError('An error occurred while starting recommendation implementation!');
                // enqueueError('Last http errors object\n\n' + JSON.stringify((window as any).apolloLastErrors ?? ''));
            }
        } else if (inProgress) {
            const { data: completeImplementationData } = await completeImplementation({
                variables: {
                    roadmapRecommendationId: recommendationImplementation.roadmapRecommendationId,
                },
                refetchQueries: [
                    { query: GetProjectImplementationQuery, variables: { id: project.id } },
                ],
                awaitRefetchQueries: true,
            });

            if (!completeImplementationData?.completeImplementation?.roadmap_recommendation_id) {
                enqueueSuccess('Recommendation implementation successfully completed!');
            } else {
                enqueueError('An error occurred while completing recommendation implementation!');
                // enqueueError('Last http errors object\n\n' + JSON.stringify((window as any).apolloLastErrors ?? ''));
            }
        }
    };

    const displayTitle =
        recommendationImplementation.recommendationRefinement.refinedRecommendation;
    const displayId = recommendationImplementation?.recommendationRefinement?.id;
    const recommendationId = recommendationImplementation?.recommendationId;

    const displaySubtitle = `${recommendationImplementation.component.name} / ${recommendationImplementation.gap.gap}`;

    // prettier-ignore
    const displayActionLabel =
        isPending ? 'Start' :
            inProgress ? 'Done' :
                '';

    const isLoading = startImplementationLoading || completeImplementationLoading;
    const completedInDays = recommendationImplementation.completedInDays;
    const context = useContext(AuthContext);
    const startedAt = recommendationImplementation.getFormattedDate('startedAt');
    const completedAt = recommendationImplementation.getFormattedDate('completedAt');

    const [isAssignOperatorModalOpen, setIsAssignOperatorModalOpen] = useState(false);
    const [assignOperator, { loading: assignOperatorLoading }] = useMutation(
        AssignRecommendationToOperatorMutation,
    );

    const [createImplementationTask] = useMutation<
        CreateImplementationTaskMutationType,
        CreateImplementationTaskMutationVariables
    >(CreateImplementationTaskMutation);
    const { implementationTask, loading: implementationTaskLoading } = useImplementationTasks({
        variables: { recommendationId: recommendationId, companyId: project?.companyId },
    });

    const handleAssignOperator = async (e: FormEvent) => {
        e.preventDefault();

        const { data: completeAssign } = await assignOperator({
            variables: {
                recommendationId: recommendationImplementation.roadmapRecommendationId,
                userId: Number(operator?.id),
            },
            refetchQueries: [
                { query: GetProjectImplementationQuery, variables: { id: project.id } },
            ],
            awaitRefetchQueries: true,
        });

        if (completeAssign) {
            enqueueSuccess('Operator successfully assigned!');
            setIsAssignOperatorModalOpen(false);
        } else {
            enqueueError('An error occurred!');
        }
    };

    const getOperatorOptionLabel = React.useCallback(option => option?.getFullName() ?? '-', []);
    const handleChangeOperator = (_: React.ChangeEvent<{}>, value: UserListEntity | null) =>
        setOperator(value);

    const concatQueryAndTitle = (queryStart: string) => {
        let title;
        if (displayTitle?.match(/^\d+\.\s/)) {
            title = displayTitle?.replace(/^\d+\.\s/, '');
        } else {
            title = displayTitle;
        }
        const query = `${queryStart}${title}`;
        setChatGptQuery(query);
    };

    useEffect(() => {
        concatQueryAndTitle(queryStart);
    }, [queryStart]);

    async function findChatGptResponse(chatGptQuery: string) {
        await fetchResponse(chatGptQuery);
    }

    useEffect(() => {
        let result = undefined;
            response?.forEach(async function (items: any) {
                const variables = new ImpTaskCreateRequest({
                    recommendationId: recommendationId,
                    task: items,
                    companyId: project?.companyId,
                    isAiTasks: true,
                });

                const { data: createImpTaskData } = await createImplementationTask({
                    variables,
                    refetchQueries: [
                        {
                            query: GetRecommendationImpTasksQuery,
                            variables: { recommendationId },
                        },
                    ],
                    awaitRefetchQueries: true,
                });
                result = createImpTaskData?.createImplementationTask?.id;
                if (!result) {
                    enqueueError(error);
                }
            });
    }, [response]);

    if (implementationTaskLoading || usersLoading) {
        return <Loader />;
    }

    return (
        <>
            <AccordionListItem
                title={displayTitle}
                subtitle={displaySubtitle}
                SummaryInfoComponents={
                    <>
                        <SummaryInfoStatus>
                            <b>Status:</b> {recommendationImplementation.status}
                        </SummaryInfoStatus>

                        <SummaryInfoStatus>
                            {startedAt ? (
                                <>
                                    <b>Started at</b> {startedAt}
                                </>
                            ) : null}
                        </SummaryInfoStatus>

                        {isDone && completedInDays ? (
                            <Info>
                                Completed in <b>{completedInDays}</b> days
                            </Info>
                        ) : null}

                        <SummaryInfoStatus>
                            {completedAt ? (
                                <>
                                    <b>Finished at</b> {completedAt}
                                </>
                            ) : null}
                        </SummaryInfoStatus>

                        {isActionAvailable ? (
                            <InfoDone>
                                {!isDone ? (
                                    <>
                                        <EyeInfo
                                            onClick={() =>
                                                context?.isAiAccess
                                                    ? findChatGptResponse(chatGptQuery)
                                                    : undefined
                                            }
                                        >
                                            {context?.isAiAccess ? (
                                                <RemoveRedEyeRounded />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </EyeInfo>
                                        <Button
                                            variant='outlined'
                                            loading={isLoading}
                                            disabled={!context?.isAiAccess}
                                            onClick={handleSubmitAction}
                                        >
                                            {displayActionLabel}
                                        </Button>
                                    </>
                                ) : null}
                            </InfoDone>
                        ) : null}
                    </>
                }
            >
                {implementationTaskLoading || chatloading ? (
                    <LinearProgress />
                ) : (
                    <ImplementationTaskList
                        canEdit={hasPmRole ? hasPmRole : hasCORole ? hasCORole : hasOPRole}
                        project={project}
                        loadingImp={implementationTaskLoading}
                        recommendationId={recommendationId}
                        impTask={implementationTask}
                    />
                )}
                <Additional>
                    <AdditionalAssigned>
                        <strong>Operator:</strong>
                        {operators?.length ? (
                            <span>
                                {operators
                                    .map(
                                        (operator, i) =>
                                            operator?.first_name + ' ' + operator?.last_name,
                                    )
                                    .join(', ')}
                            </span>
                        ) : (
                            <span>-</span>
                        )}
                    </AdditionalAssigned>
                    <AdditionalActions>
                        <Button
                            variant='outlined'
                            onClick={() => setIsAssignOperatorModalOpen(true)}
                            style={{ marginRight: '10px' }}
                        >
                            Assign operator
                        </Button>

                        {/*{hasOpRole ? (*/}
                        <Button variant='outlined' onClick={() => setShowNotes(state => !state)}>
                            Constraints / dependencies / notes
                        </Button>
                        {/*) : null}*/}
                    </AdditionalActions>

                    {showNotes ? (
                        <AdditionalHiddenBlock>
                            <AdditionalHiddenBlockSection>
                                <h4>Constraints:</h4>
                                {constraintsLog.map((note: any, i: number) => (
                                    <StyledNote key={i}>
                                        <span className='is-username'>
                                            {note.created_by?.first_name}{' '}
                                            {note.created_by?.last_name}
                                        </span>
                                        <span className='is-time'>
                                            {format(new Date(note.created_at), 'dd.MM.yyyy hh:mm')}
                                        </span>
                                        <p className='is-note'>{note.comment}</p>
                                    </StyledNote>
                                ))}
                                <AddNotesForm
                                    type='constraints'
                                    recommendationImplementation={recommendationImplementation}
                                    project={project}
                                />
                            </AdditionalHiddenBlockSection>

                            <AdditionalHiddenBlockSection>
                                <h4>Dependencies:</h4>
                                {dependenciesLog.map((note: any, i: number) => (
                                    <StyledNote key={i}>
                                        <span className='is-username'>
                                            {note.created_by?.first_name}{' '}
                                            {note.created_by?.last_name}
                                        </span>
                                        <span className='is-time'>
                                            {format(new Date(note.created_at), 'dd.MM.yyyy hh:mm')}
                                        </span>
                                        <p className='is-note'>{note.comment}</p>
                                    </StyledNote>
                                ))}
                                <AddNotesForm
                                    type='dependencies'
                                    recommendationImplementation={recommendationImplementation}
                                    project={project}
                                />
                            </AdditionalHiddenBlockSection>

                            <AdditionalHiddenBlockSection>
                                <h4>Notes:</h4>
                                {notesLog.map((note: any, i: number) => (
                                    <StyledNote key={i}>
                                        <span className='is-username'>
                                            {note.created_by?.first_name}{' '}
                                            {note.created_by?.last_name}
                                        </span>
                                        <span className='is-time'>
                                            {format(new Date(note.created_at), 'dd.MM.yyyy hh:mm')}
                                        </span>
                                        <p className='is-note'>{note.comment}</p>
                                    </StyledNote>
                                ))}
                                <AddNotesForm
                                    type='notes'
                                    recommendationImplementation={recommendationImplementation}
                                    project={project}
                                />
                            </AdditionalHiddenBlockSection>
                        </AdditionalHiddenBlock>
                    ) : (
                        ''
                    )}
                </Additional>
            </AccordionListItem>
            <Modal
                title='Assign operator'
                open={isAssignOperatorModalOpen}
                onClose={() => setIsAssignOperatorModalOpen(false)}
            >
                <form method='post' onSubmit={handleAssignOperator} style={{ width: '100%' }}>
                    <Autocomplete<UserListEntity | null, false, false, false>
                        fullWidth
                        id='operator'
                        label='Operator'
                        placeholder='Choose the operator'
                        options={users}
                        value={operator}
                        getOptionLabel={getOperatorOptionLabel}
                        onChange={handleChangeOperator}
                    />

                    <div style={{ marginTop: '16px' }}>
                        <Button
                            type='submit'
                            onSubmit={handleAssignOperator}
                            loading={assignOperatorLoading}
                        >
                            Assign operator
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export { ImplementationRecommendationsListItem };

import { gql } from '@apollo/client';

export const GetDistinctRolesQuery = gql`
    query getDistinctRoles {
        getDistinctRoles {
            id
            name
        }
    }
`;

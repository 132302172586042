import { gql } from '@apollo/client';

export const ImplementationTaskSingleFragment = gql`
    fragment ImplementationTaskSingle on ImplementationTask {
        id
        recommendation_id
        task
        company_id
        t_flag
    }
`;

import { EntityList } from '@lib/entity';
import { RiskGroupEntity } from './risk-group-entity';

import type { Entity } from './risk-group-entity';


class RiskGroupEntityList extends EntityList<RiskGroupEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(RiskGroupEntity, items, count);
    }
}

export { RiskGroupEntityList };

import { EntityList } from '@lib/entity';
import { CapabilityEntity } from './capability-entity';

import type { Entity } from './capability-entity';

class CapabilityEntityList extends EntityList<CapabilityEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(CapabilityEntity, items, count);
    }
}

export { CapabilityEntityList };

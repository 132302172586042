import type { AddAssessmentTypeMutationVariables } from '@modules/types/graphql';
import { ListAssessmentCategoriesEntity } from '../entities';
import { AssessmentTypeEntity } from '@modules/shared/entities';

export type CustomTypeCreateFormValues = {
    name: string;
    isRRType: boolean;
    assessmentCategoriesId: AssessmentTypeEntity | any;
};

class CustomAssessmentTypeCreateRequest {
    name: AddAssessmentTypeMutationVariables['name'];
    isRRType: AddAssessmentTypeMutationVariables['isRRType'];
    assessmentCategoriesId: AddAssessmentTypeMutationVariables['assessmentCategoriesId'];

    constructor(values: CustomTypeCreateFormValues) {
        this.name = values.name;
        this.isRRType = values.isRRType;
        this.assessmentCategoriesId = values.assessmentCategoriesId?.id;
    }
}

export { CustomAssessmentTypeCreateRequest };

import { gql } from '@apollo/client';

import { GapSingleFragment } from '../fragments';

export const AddCustomGapMutation = gql`
    mutation AddCustomGap(
        $capabilityLevelId: Int!
        $gap: String!
        $surveyId: Int!
        $isAiGaps: Boolean!
        $projectId: Int!
    ) {
        addCustomGap(
            capability_level_id: $capabilityLevelId
            gap: $gap
            survey_id: $surveyId
            project_id: $projectId
            is_ai_gaps: $isAiGaps
        ) {
            ...GapSingle
        }
    }
    ${GapSingleFragment}
`;

import {
  Entity as ComponentRecommendationEntityType
} from '@modules/roadmap/entities/component-recommendation-entity';
import {PrioritizedRoadmapEntity, PrioritizedRoadmapEntityType} from '@modules/roadmap/entities';
import {ProjectAssessmentFilterValue} from './project-assessment-components-filter';
import {Dispatch, SetStateAction} from 'react';
import {UserEntity} from '@modules/users/entities';
import {ComponentAssessmentEntity, ComponentReportEntity} from '@modules/assessment/entities';
import {ImplementationEntity} from '@modules/implementation/entities';
import {Entity as RecommendationImplementationEntityType} from '@modules/implementation/entities/recommendation-implementation-entity';
import {GapAssessment} from '@modules/assessment/gap-assessment';
import {GapCapabilityAssessment} from '@modules/assessment/gap-by-risk';
import {RecommendationAssessment} from '@modules/roadmap/recommendation-assessment-roadmap-report';

export function roadmapCollecCO(componentOwnerMap: Map<number, UserEntity>, roadmap: PrioritizedRoadmapEntityType): void {
  let roadmapData = roadmap as Object;
  Object.keys(roadmapData).forEach((reportPriority) => {
    if (!Array.isArray(roadmapData[reportPriority])) {
      return;
    }

    (roadmapData[reportPriority] as ComponentRecommendationEntityType[]).forEach((prioritizedReport) => {
      componentOwnerMap.set(prioritizedReport?.component_owner?.id ?? -1, new UserEntity(prioritizedReport?.component_owner as any as UserEntity));
    })
  });
}

export function assessmentCollectCO(componentsAssessmentRawList: ComponentAssessmentEntity[]): Map<number, UserEntity> {
  return new Map(
    componentsAssessmentRawList.reduce((result, assessmnent) => {
      return result.concat(
        assessmnent.componentOwners.map((co) => [co.id, co])
      );
    }, [] as Array<[number, UserEntity]>)
  );
}

export function assessmentReportCollectCO(componentReportList: (ComponentReportEntity|null)[]): Map<number, UserEntity> {
  return new Map(
    componentReportList.reduce((result, report) => {
      result.push([report?.componentOwner.id ?? -1, report?.componentOwner ?? {id: -1} as UserEntity]);
      return result;
    }, [] as Array<[number, UserEntity]>)
  );
}

export function roadmapFilterHandler(
  setPrioritizedRoadmap: Dispatch<SetStateAction<PrioritizedRoadmapEntity>>,
  prioritizedRoadmap: PrioritizedRoadmapEntity,
  recommendationAssessmentMapRaw: Map<number, RecommendationAssessment>,
  filterValue: ProjectAssessmentFilterValue
): void {
  setPrioritizedRoadmap((state: PrioritizedRoadmapEntity) => {
    const filteredData = {...prioritizedRoadmap.dataSource};

    Object.keys(filteredData).forEach((reportPriority) => {
      if (!Array.isArray(filteredData[reportPriority])) {
        return;
      }

      filteredData[reportPriority] = filteredData[reportPriority].filter((report: ComponentRecommendationEntityType) => {
        let condition = true;
        let recommendationAssessment = recommendationAssessmentMapRaw.get(report?.recommendation?.id as number);
        let assessment = recommendationAssessment?.componentAssessment;

        if (filterValue.componentName) {
          condition = condition && (report?.component?.name?.toLowerCase() ?? '').includes(filterValue.componentName.toLowerCase());
        }
        if (filterValue.componentOwnerId.length) {
          // condition = condition && filterValue.componentOwnerId.includes(report?.component_owner?.id as number);
          condition = condition && Boolean(assessment?.componentOwners.some((componentOwner) => filterValue.componentOwnerId.includes(componentOwner.id)));
        }
        if (filterValue.maturityLevelId.length) {
          // condition = condition && filterValue.maturityLevelId.includes(report?.maturity_level?.id as number)
          condition = condition && filterValue.maturityLevelId.includes(assessment?.maturityLevel.id as number)
        }
        if (filterValue.riskLevelId.length) {
          condition = condition && filterValue.riskLevelId.includes(report?.recommendation?.gap?.risk_level as string)
          // console.log('filter riskLevelId', reportPriority, filterValue.riskLevelId, report?.recommendation?.gap?.risk_level, filterValue.riskLevelId.includes(report?.recommendation?.gap?.risk_level as string))
        }
        if (filterValue.implementationStatus.length) {
          condition = condition && Boolean(
            filterValue.implementationStatus.includes(report?.recommendation?.status?.status as string)
          );
        }
        return condition;
      });
    });

    return new PrioritizedRoadmapEntity(filteredData);
  });
}

// export function assessmentReportFilterHandler(
//   setComponentReports: Dispatch<SetStateAction<(ComponentReportEntity | null | never)[]>>,
//   componentReports: (ComponentReportEntity | null | never)[],
//   filterValue: ProjectAssessmentFilterValue
// ): void {
//   setComponentReports(state => {
//     return componentReports.filter((report) => {
//       let condition = true;
//       if (filterValue.componentName) {
//         condition = condition && (report?.component.name.toLowerCase() ?? '').includes(filterValue.componentName.toLowerCase());
//       }
//       if (filterValue.componentOwnerId.length) {
//         condition = condition && filterValue.componentOwnerId.includes(report?.componentOwner.id as number);
//       }
//       if (filterValue.maturityLevelId.length) {
//         condition = condition && filterValue.maturityLevelId.includes(report?.maturityLevel.id as number)
//       }
//       if (filterValue.riskLevelId.length) {
//         condition = condition && Boolean(report?.capabilities.some((capability) => {
//           return filterValue.riskLevelId
//             .map((riskLevelId) => GapRiskLevelField[riskLevelId])
//             .some((riskLevelField) => {
//               return capability[riskLevelField]?.length;
//             })
//         }))
//       }
//       if (filterValue.implementationStatus.length) {
//         console.warn('implementationStatus filter not supported in assessmentReportFilterHandler')
//       }
//
//       return condition;
//     });
//   });
// }

export function assessmentFilterHandler(
  setComponentsAssessmentList: Dispatch<SetStateAction<ComponentAssessmentEntity[]>>,
  componentsAssessmentRawList: ComponentAssessmentEntity[],
  filterValue: ProjectAssessmentFilterValue
): void {
  setComponentsAssessmentList(state => {
    const result = componentsAssessmentRawList.filter((assessment) => {
      let condition = true;
      if (filterValue.componentId.length) {
        condition = condition && filterValue.componentId.includes(assessment?.component.id as number);
      }
      if (filterValue.componentName) {
        condition = condition && (assessment?.component.name.toLowerCase() ?? '').includes(filterValue.componentName.toLowerCase());
      }
      if (filterValue.componentOwnerId.length) {
        condition = condition && Boolean(assessment?.componentOwners.find((componentOwner) => filterValue.componentOwnerId.includes(componentOwner.id)));
      }
      if (filterValue.maturityLevelId.length) {
        condition = condition && filterValue.maturityLevelId.includes(assessment?.maturityLevel.id as number)
      }
      if (filterValue.riskLevelId.length) {
        condition = condition && Boolean(
          assessment?.capabilities.some((capability) => {
            return capability?.scoredGaps?.some((scoredGap) => {
              return filterValue.riskLevelId.includes(scoredGap?.riskLevel as string)
            });
          })
        );
      }
      if (filterValue.implementationStatus.length) {
        condition = condition && Boolean(
          assessment?.capabilities.some((capability) => {
            return capability?.scoredGaps?.some((scoredGap) => {
              return scoredGap?.roadmapRecommendations?.some((recommendation) => {
                return filterValue.implementationStatus.includes(recommendation?.status as string)
              })
            });
          })
        );
      }

      return condition;
    });

    return result;
  });
}

export function componentAssessmentMapFilterHandeler(
  setComponentAssessmentMap: Dispatch<SetStateAction<Map<number, GapCapabilityAssessment[]>>>,
  componentAssessmentRawMap: Map<number, GapCapabilityAssessment[]>,
  filterValue: ProjectAssessmentFilterValue
): void {
  setComponentAssessmentMap(state => {
    const componentAssessmentMap = new Map<number, GapCapabilityAssessment[]>();
    componentAssessmentRawMap.forEach((gapList: GapCapabilityAssessment[], key: number) => {
      let adjustedList: GapCapabilityAssessment[] = gapList.filter((gap: GapCapabilityAssessment) => {
        let condition = true;
        let assessment = gap.componentAssessment;

        if (filterValue.componentId.length) {
          condition = condition && filterValue.componentId.includes(assessment?.component.id as number);
        }
        if (filterValue.componentName) {
          condition = condition && (assessment?.component.name.toLowerCase() ?? '').includes(filterValue.componentName.toLowerCase());
        }
        if (filterValue.componentOwnerId.length) {
          condition = condition && Boolean(assessment?.componentOwners.find((componentOwner) => filterValue.componentOwnerId.includes(componentOwner.id)));
        }
        if (filterValue.maturityLevelId.length) {
          condition = condition && filterValue.maturityLevelId.includes(assessment?.maturityLevel.id as number)
        }
        if (filterValue.riskLevelId.length) {
          condition = condition && Boolean(
            filterValue.riskLevelId.includes(gap?.scoredGap.riskLevel as string)
          );
        }
        if (filterValue.implementationStatus.length) {
          condition = condition && Boolean(
            gap?.scoredGap?.roadmapRecommendations?.some((recommendation) => {
              return filterValue.implementationStatus.includes(recommendation?.status as string)
            })
          );
        }

        return condition;
      });

      componentAssessmentMap.set(key, adjustedList);
    });

    return componentAssessmentMap;
  });
}

export function assessmentImplementationFilterHandeler(
  setImplementation: Dispatch<SetStateAction<ImplementationEntity>>,
  implementationRaw: ImplementationEntity,
  gapByAssessment: Map<number, GapAssessment>,
  filterValue: ProjectAssessmentFilterValue
): void {
  setImplementation(state => {
    const filteredData = {...implementationRaw.dataSource};

    Object.keys(filteredData).forEach((reportPriority) => {
      if (!Array.isArray(filteredData[reportPriority])) {
        return;
      }

      filteredData[reportPriority] = filteredData[reportPriority].filter((report: RecommendationImplementationEntityType) => {
        let condition = true;
        let gapAssessment = gapByAssessment.get(report?.gap_id as number);
        let assessment = gapAssessment?.componentAssessment;

        if (filterValue.componentName) {
          condition = condition && (report?.component?.name?.toLowerCase() ?? '').includes(filterValue.componentName.toLowerCase());
        }
        if (filterValue.componentOwnerId.length) {
          condition = condition && Boolean(assessment?.componentOwners.find((componentOwner) => filterValue.componentOwnerId.includes(componentOwner.id)));
        }
        if (filterValue.maturityLevelId.length) {
          condition = condition && filterValue.maturityLevelId.includes(assessment?.maturityLevel.id as number)
        }
        if (filterValue.riskLevelId.length) {
          condition = condition && Boolean(
            filterValue.riskLevelId.includes(gapAssessment?.scoredGap?.riskLevel as string)
          );
        }
        if (filterValue.implementationStatus.length) {
          condition = condition && filterValue.implementationStatus.includes(report?.status as string)
        }

        return condition;
      });
    });

    return new ImplementationEntity(filteredData);
  });
}

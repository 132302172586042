import React from 'react';
import styled from 'styled-components';

import { gray } from '@modules/ui/colors';
import { ProjectMaturityLevel } from '@modules/projects/moleculas';

import type { ComponentReportEntity } from '@modules/assessment/entities';
import {ComponentAssessmentEntity} from '@modules/assessment/entities';
import {TableCell, TableRow} from '@material-ui/core';
import {RecommendationComponentAssessment} from '@modules/roadmap/recommendation-by-risk';
import {calcRecommendationStatusScore} from '@modules/shared/constants/implementation-status';
import {ReportRoadmapComponentInnerItem} from '@modules/roadmap/organisms/report-roadmap/report-roadmap-component-inner-item';

type ReportRoadmapComponentsListItemProps = {
    // componentReport?: ComponentReportEntity;
    assessment: ComponentAssessmentEntity;
    componentAssessmentMap: Map<number, RecommendationComponentAssessment>;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 16px;
    background-color: ${gray[60]};
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;

    > * {
        width: 20%;
        font-size: 1.4rem;
    }
`;

const InnerHeaderExtra = styled.div`
    > h3 {
        margin-bottom: 6px;
        font-weight: 700;
    }
`;

const Title = styled.div`
    font-size: 1.8rem;
    font-weight: 700;
`;

const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-top: 24px;

    > * {
        margin-bottom: 24px;
    }
`;

const ReportRoadmapComponentsListItem = (
    props: ReportRoadmapComponentsListItemProps,
): React.ReactElement => {
    const { assessment, componentAssessmentMap } = props;
    const maturityLevel = assessment.getMaturityLevel();
    const [openComponent, setOpenComponent] = React.useState(false);

    const recommendationCount = componentAssessmentMap.get(assessment.id)?.recommendationCapabilityList?.length ?? 0;
    const scoreList: number[] = calcRecommendationStatusScore(recommendationCount, componentAssessmentMap.get(assessment.id)?.statusCountMap)

    function onRowToggle() {
        setOpenComponent(!openComponent);
    }

    return (
      <>
        <TableRow onClick={onRowToggle} className={'row-expand'}>
          <TableCell component="th" scope="row">
            {assessment.component.name}
            <br/>
            {assessment.objectives && (
              <sup>{assessment.objectives}</sup>
            )}
          </TableCell>
          <TableCell>
            {maturityLevel ? (
              <ProjectMaturityLevel current={maturityLevel} />
            ) : 'N/A'}
          </TableCell>
          <TableCell>{assessment?.capabilities?.length ?? 0}</TableCell>
          <TableCell>{recommendationCount}</TableCell>
          <TableCell>{scoreList[2]}%</TableCell>
          <TableCell>{scoreList[1]}%</TableCell>
          <TableCell>{scoreList[0]}%</TableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <ReportRoadmapComponentInnerItem
              assessment={assessment}
              openComponent={openComponent}
              componentAssessmentMap={componentAssessmentMap}
              recordCount={recommendationCount}
            />
          </TableCell>
        </TableRow>
      </>
    );
};

export { ReportRoadmapComponentsListItem };

import React, { ChangeEvent, useContext, useEffect } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Autocomplete, Button, Modal, TextField } from '@modules/ui/core';
import type { ModalProps } from '@modules/ui/core';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { gray } from '@modules/ui/colors';
import { MenuItem, Tabs, Tab } from '@material-ui/core';
import { CompanyEntity } from '@modules/company/entities';
import { UserListEntity } from '@modules/users/entities';
import { AssessmentTypeEntity } from '@modules/shared/entities';
import { useUsers } from '@modules/users/hooks';
import { useCompanies, useTkoScope } from '@modules/company/hooks';
import { useAssessmentTypes } from '@modules/shared/hooks';
import { useMutation } from '@apollo/client';
import { CreateProjectMutation } from '@modules/projects/graphql';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { GetAssetsQuery } from '@modules/registry/graphql';
import { Asset, Program } from '@modules/types/graphql';
import { EditAssessmentInfo } from '@modules/registry/organisms/view-assessment-modal/edit-assessment-info';
import { RiskProgramTypeMap } from '@modules/shared/constants/risk-assessment-type';
import { ProjectCreateModal } from '@modules/projects/organisms';
import { TkoScopeEntity } from '@modules/company/entities/tko-scope-entity';
import { AuthContext } from '@modules/auth/context';

const StyledModal = styled(Modal)`
    max-width: 850px;
    width: 100%;

    .tab-header {
        width: 100%;
        margin-bottom: 20px;

        button {
            flex-grow: 1;

            span {
                font-size: 1rem;
                font-weight: bold;
                // text-transform: none;
            }
        }
    }
`;
const ImpactStyle = styled.div`
    position: relative;
    span {
        position: absolute;
        right: 76px;
        font-size: 16px;
        top: 8px;
        font-weight: 500;
        padding: 7px;
        z-index: 999;
    }
`;
const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }

    .is-select {
        /* width: 536px; */
        width: 100%;
        background-color: ${gray[90]};

        .MuiInputBase-root > div {
            display: initial;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const defaultInitialValues = {
    assessment: '',
    name: '',
    description: '',
    company: null,
    stakeholder: null,
    assessmentTypeId: null,
    tkoscope: null,
};

const mockedAssessments = [
    {
        id: 1,
        name: 'Test assessment',
    },
    {
        id: 2,
        name: 'Test assessment 2',
    },
];

const NewAssessmentModal = (
    props: ModalProps & { activeAsset: Asset; activeAssessment: any; activityType: any },
): React.ReactElement => {
    const {
        onClose,
        activeAsset,
        activeAssessment,
        refetchQuery,
        activityType,
        ...otherProps
    } = props;
    const [activeTab, setActiveTab] = React.useState(0);
    const { users, loading: usersLoading } = useUsers();
    const { companies, loading: companiesLoading } = useCompanies();
    const { assessmentTypes, loading: assessmentTypesLoading } = useAssessmentTypes();
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const { tkoscopes, loading: tkoScopeLoading } = useTkoScope();

    const [createProject] = useMutation(CreateProjectMutation);
    const context = useContext(AuthContext);

    const newName: any =
        activityType === 'impact'
            ? ' - Impact'
            : activityType === 'vulnerability'
            ? ' - Vulnerability'
            : ' - Threat';

    const getCompanyOptionLabel = React.useCallback(
        (option: CompanyEntity | null) => option?.name ?? '-',
        [],
    );

    const getTkoScopeOptionLabel = React.useCallback(
        (option: TkoScopeEntity | null) => option?.name ?? '-',
        [],
    );

    const getUserOptionLabel = React.useCallback(
        (option: UserListEntity | null) => option?.getFullName() ?? '-',
        [],
    );

    const getAssessmentTypeOptionLabel = React.useCallback(
        (option: AssessmentTypeEntity | null) => option?.name ?? '-',
        [],
    );

    const handleChangeTab = (e: ChangeEvent<{}>, newValue: any) => {
        setActiveTab(newValue);
    };

    const [openCreateModal, setOpenCreateModal] = React.useState<Program | undefined>(undefined);

    function handleOpenCreateModal(savedProject: Program) {
        setOpenCreateModal(savedProject);
    }
    function handleCloseCreateModal() {
        setOpenCreateModal(undefined);
        onClose();
    }
    return (
        <StyledModal title='New assessment' onClose={onClose} {...otherProps}>
            <Tabs
                value={activeTab}
                onChange={handleChangeTab}
                indicatorColor='primary'
                textColor='primary'
                className='tab-header'
            >
                <Tab label='Choose from the existing' value={0} />
                <Tab label='Create new assessment' value={1} />
                <Tab label='Edit score' value={2} />
            </Tabs>

            {activeTab === 2 ? (
                <EditAssessmentInfo
                    activeAsset={activeAsset}
                    refetchQuery={GetAssetsQuery}
                    activeAssessment={activeAssessment}
                    activityType={activityType}
                    onClose={onClose}
                />
            ) : (
                <>
                    <ProjectCreateModal
                        refetchQuery={GetAssetsQuery}
                        project={openCreateModal}
                        open={Boolean(openCreateModal)}
                        onClose={handleCloseCreateModal}
                    />
                    <ExtendedFormik
                        enableReinitialize
                        validateOnChange={false}
                        validateOnBlur={false}
                        validationSchema={yup.object({
                            name: yup.string().required('Required field'),
                            description: yup.string().required('Required field'),
                            company: yup.mixed().required('Required field'),
                            tkoscope:
                                activityType === 'impact'
                                    ? yup.mixed()
                                    : yup.mixed().required('Required field'),
                            stakeholder: yup.mixed().required('Required field'),
                            assessmentTypeId: yup.mixed().required('Required field'),
                        })}
                        initialValues={
                            activeAsset
                                ? {
                                      ...defaultInitialValues,
                                      company: activeAsset?.company,
                                      assessmentTypeId: RiskProgramTypeMap[activityType],
                                  }
                                : defaultInitialValues
                        }
                        onSubmit={async values => {
                            const { name, description, company, stakeholder, tkoscope } = values;
                            const variables = {
                                name: name + newName,
                                description,
                                companyId: Number(company?.id),
                                stakeholderId: Number((stakeholder as any)?.id),
                                assessmentTypeId: RiskProgramTypeMap[activityType],
                                assetId: activeAsset?.id,
                                tkoscopeId: tkoscope === null ? 1 : Number((tkoscope as any)?.id),
                            };
                            try {
                                const { data: createProjectData } = await createProject({
                                    variables,
                                    refetchQueries: [{ query: GetAssetsQuery }],
                                    awaitRefetchQueries: true,
                                });

                                let savedProject = createProjectData?.createProgram;
                                context?.handleSelectedProjectId(savedProject?.id);
                                if (!savedProject?.id) {
                                    onClose();
                                    throw new Error(
                                        'An error occurred while creating the project!',
                                    );
                                }

                                enqueueSuccess('Assessment was successfully created!');
                                handleOpenCreateModal(savedProject);
                            } catch (e) {
                                onClose();
                                throw e;
                            }
                        }}
                    >
                        {formikProps => {
                            const {
                                values,
                                errors,
                                handleChange,
                                handleBlur,
                                isSubmitting,
                                setFieldValue,
                            } = formikProps;

                            const handleChangeAutocomplete = (field: string) => (
                                _: React.ChangeEvent<{}>,
                                value: any | null,
                            ) => setFieldValue(field, value);

                            return (
                                <StyledForm>
                                    {activeTab === 0 ? (
                                        <TextField
                                            select
                                            name='assessment'
                                            label='Assessment'
                                            className='is-select'
                                            value={values.assessment}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={handleChange}
                                        >
                                            {mockedAssessments ? (
                                                mockedAssessments.map((assessment: any) => {
                                                    const { name, id } = assessment;
                                                    return (
                                                        <MenuItem key={id} value={id}>
                                                            {name}
                                                        </MenuItem>
                                                    );
                                                })
                                            ) : (
                                                <MenuItem key={0} value={''}>
                                                    Select assessment(s)
                                                </MenuItem>
                                            )}
                                        </TextField>
                                    ) : (
                                        ''
                                    )}

                                    {activeTab === 1 ? (
                                        <>
                                            <ImpactStyle>
                                                <TextField
                                                    fullWidth
                                                    id='name'
                                                    label='Project Name'
                                                    value={values.name}
                                                    error={!!errors.name}
                                                    helperText={errors.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                <span>
                                                    {activityType === 'impact'
                                                        ? '- Impact'
                                                        : activityType === 'vulnerability'
                                                        ? '- Vulnerability'
                                                        : '- Threat'}
                                                </span>
                                            </ImpactStyle>

                                            <TextField
                                                fullWidth
                                                multiline
                                                id='description'
                                                label='Description'
                                                rows='4'
                                                value={values.description}
                                                error={!!errors.description}
                                                helperText={errors.description}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                            <Autocomplete<
                                                TkoScopeEntity | null,
                                                false,
                                                false,
                                                false
                                            >
                                                fullWidth
                                                id='tkoscope'
                                                label='TKO Scope'
                                                placeholder='Choose the TKO Scope'
                                                options={tkoscopes}
                                                // value={values.tkoscope}
                                                // error={!!errors.tkoscope}
                                                // helperText={errors.tkoscope}
                                                value={values.tkoscope}
                                                error={!!errors.tkoscope}
                                                helperText={errors.tkoscope}
                                                disabled={
                                                    tkoScopeLoading || activityType === 'impact'
                                                        ? true
                                                        : false
                                                }
                                                getOptionLabel={getTkoScopeOptionLabel}
                                                onChange={handleChangeAutocomplete('tkoscope')}
                                            />

                                            <Autocomplete<CompanyEntity | null, false, false, false>
                                                fullWidth
                                                hidden
                                                id='company'
                                                label='Company'
                                                placeholder='Choose the company'
                                                options={companies}
                                                value={values.company as CompanyEntity}
                                                error={!!errors.company}
                                                disabled={companiesLoading}
                                                getOptionLabel={getCompanyOptionLabel}
                                                onChange={handleChangeAutocomplete('company')}
                                            />

                                            <Autocomplete<
                                                UserListEntity | null,
                                                false,
                                                false,
                                                false
                                            >
                                                fullWidth
                                                id='stakeholder'
                                                label='Stakeholder'
                                                placeholder='Choose the stakeholder'
                                                options={users}
                                                value={values.stakeholder}
                                                error={!!errors.stakeholder}
                                                helperText={errors.stakeholder}
                                                disabled={usersLoading}
                                                getOptionLabel={getUserOptionLabel}
                                                onChange={handleChangeAutocomplete('stakeholder')}
                                            />

                                            <Autocomplete<
                                                AssessmentTypeEntity | null,
                                                false,
                                                false,
                                                false
                                            >
                                                fullWidth
                                                hidden
                                                id='assessmentTypeId'
                                                label='Assessment Type'
                                                options={assessmentTypes}
                                                value={
                                                    ((values.assessmentTypeId ?? {
                                                        id: RiskProgramTypeMap[activityType],
                                                        name: activityType,
                                                    }) as unknown) as AssessmentTypeEntity
                                                }
                                                error={!!errors.assessmentTypeId}
                                                helperText={errors.assessmentTypeId}
                                                disabled={assessmentTypesLoading}
                                                getOptionLabel={getAssessmentTypeOptionLabel}
                                                onChange={handleChangeAutocomplete(
                                                    'assessmentTypeId',
                                                )}
                                            />
                                        </>
                                    ) : (
                                        ''
                                    )}

                                    <FormStyled.FormRowSubmit>
                                        <SubmitButton type='submit' loading={isSubmitting}>
                                            Save
                                        </SubmitButton>

                                        <SubmitButton
                                            variant='text'
                                            disabled={isSubmitting}
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </SubmitButton>
                                    </FormStyled.FormRowSubmit>
                                </StyledForm>
                            );
                        }}
                    </ExtendedFormik>
                </>
            )}
        </StyledModal>
    );
};

export { NewAssessmentModal };

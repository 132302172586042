import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useMutation } from '@apollo/client';

import { TextField, Button } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { AddNotesMutation, GetProjectImplementationQuery } from '@modules/implementation/graphql';
import { Loader } from '@modules/layout/moleculas';

const AddNotesForm = (props: any): React.ReactElement => {
    const { project, roadmapRecommendation, onAddNote } = props;
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [note, setNote] = useState('');
    const [showForm, setShowForm] = useState(false);

    const [addNotes, { loading: addNotesLoading }] = useMutation(AddNotesMutation);

    const reset = () => {
        setShowForm(false);
        setNote('');
    };

    const handleSubmit = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        e?.stopPropagation();

        const { data } = await addNotes({
            variables: {
                roadmapRecommendationId: roadmapRecommendation.id,
                notes: note,
            },
            refetchQueries: [
                { query: GetProjectImplementationQuery, variables: { id: project.id } },
            ],
            awaitRefetchQueries: true,
        });

        reset();

        if (data) {
            enqueueSuccess('Successfully completed!');
            onAddNote && onAddNote(note);
        } else {
            enqueueError('An error occurred!');
        }
    };

    const handleChangeNote = (event: ChangeEvent<HTMLInputElement>) => {
        event?.stopPropagation();
        setNote(event.target.value as any)
    }

    return (
        <>
            {showForm ? (
                <form method='post' onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <TextField
                        style={{ marginTop: '16px' }}
                        fullWidth
                        multiline
                        rows={2}
                        id='note'
                        name='note'
                        value={note}
                        onChange={handleChangeNote}
                        onClick={(event) => {
                            event?.stopPropagation();
                        }}
                    />

                    <div style={{ marginTop: '16px' }}>
                        <Button
                            type='submit'
                            onSubmit={handleSubmit}
                            onClick={(event) => {
                                event?.stopPropagation();
                            }}
                            style={{ marginRight: '10px' }}
                            loading={addNotesLoading}
                        >
                            Save note
                        </Button>
                        <Button variant='outlined' onClick={(event) => {
                            event?.stopPropagation();
                            setShowForm(false)
                        }}>
                            Cancel
                        </Button>
                    </div>
                </form>
            ) : (
                <div style={{ margin: '16px 0' }}>
                    <Button variant='outlined' onClick={(event) => {
                        event?.stopPropagation();
                        setShowForm(true)
                    }}>
                        Add note
                    </Button>
                </div>
            )}
        </>
    );
};

export { AddNotesForm };

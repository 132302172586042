import React from 'react';
import styled from 'styled-components';

import { Button } from '@modules/ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { HistoryOutlined, Info } from '@material-ui/icons';
import { InnerListItemStyled } from '@modules/layout/styled';
import { CounterHint } from '@modules/layout/atoms';
import { AccordionInnerListItem } from '@modules/layout/organisms';
import { useProjectRoles } from '@modules/projects/hooks';
import { useRoles } from '@modules/assessment/hooks';
import {
    CapabilityMaturityLevel,
    ImpactCapabilityMaturityLevel,
} from '@modules/capabilities/moleculas';
import {
    AssessmentCapabilityGapsList,
    AssessmentSurveyFormModal,
    AssignAssessmentCapabilityToOperatorFormModal,
    AssessmentCapabilityHistoryModal,
} from '@modules/assessment/organisms';

import type { ProjectEntity } from '@modules/projects/entities';
import type {
    ComponentAssessmentEntity,
    CapabilityAssessmentEntity,
} from '@modules/assessment/entities';

type AssessmentCapabilitiesItemProps = {
    isAIAccess?: boolean;
    project: ProjectEntity;
    componentAssessment: ComponentAssessmentEntity;
    capabilityAssessment: CapabilityAssessmentEntity;
};

const SummaryInfoActions = styled(InnerListItemStyled.SummaryInfo)`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: auto;

    > *:not(:last-child) {
        margin-right: 24px;
    }
`;

const StyledCapabilityGapsList = styled(AssessmentCapabilityGapsList)`
    margin-top: 32px;
`;

const StyledHistoryBlock = styled.div`
    margin-right: 30px;

    span {
        line-height: 1;
    }
`;

const HistoryIcon = <HistoryOutlined style={{ fontSize: 24 }} />;

const AssessmentCapabilitiesListItem = (props: AssessmentCapabilitiesItemProps) => {
    const { project, componentAssessment, capabilityAssessment, isAIAccess } = props;
    console.log(project, 'project')
    console.log(componentAssessment, 'componentAssessment')
    const { projectRolesEntityList } = useProjectRoles({ variables: { projectId: project.id } });
    const { rolesEntityList } = useRoles({ variables: { assessmentId: componentAssessment.id } });
    console.log(projectRolesEntityList, 'projectRolesEntityList')
    console.log(rolesEntityList, 'rolesEntityList')
    const hasPmRole = projectRolesEntityList.hasProgramManager();
    const hasStRole = projectRolesEntityList.hasStakeholder();
    const hasCORole = projectRolesEntityList.hasComponentOwner();
    const hasOPRole = projectRolesEntityList.hasOperator();

    console.log(hasPmRole, 'hasPmRole')
    console.log(hasStRole, 'hasStRole')
    console.log(hasCORole, 'hasCORole')
    console.log(hasOPRole, 'hasOPRole')

    const [openSurveyModal, setOpenSurveyModal] = React.useState<boolean>(false);
    const [openAssignForReviewModal, setOpenAssignForReviewModal] = React.useState<boolean>(false);
    const [openHistoryModal, setOpenHistoryModal] = React.useState<boolean>(false);

    const handleOpenSurveyModal = (): void => setOpenSurveyModal(true);
    const handleCloseSurveyModal = (): void => setOpenSurveyModal(false);

    const handleOpenAssignForReviewModal = (): void => setOpenAssignForReviewModal(true);
    const handleCloseAssignForReviewModal = (): void => setOpenAssignForReviewModal(false);

    const isAssessmentApproved = componentAssessment.isApproved();
    const isCapabilityAssessmentAssessed = capabilityAssessment.isAssess();
    console.log(capabilityAssessment, 'capabilityAssessment')
    console.log(isCapabilityAssessmentAssessed, 'isCapabilityAssessmentAssessed')
    const gaps = capabilityAssessment.scoredGaps;
    const gapsCount = gaps.length;
    const operators = capabilityAssessment.operatorsToString();

    return (
        <>
            <AccordionInnerListItem
                title={capabilityAssessment.capability.title}
                subtitle={capabilityAssessment.capability.keyFactors}
                SummaryInfoComponents={
                    <>
                        <InnerListItemStyled.SummaryInfo>
                            <StyledHistoryBlock>
                                <Tooltip title='Show history'>
                                    <Button
                                        variant='outlined'
                                        size='small'
                                        onClick={() => setOpenHistoryModal(true)}
                                    >
                                        {HistoryIcon}
                                    </Button>
                                </Tooltip>
                            </StyledHistoryBlock>
                        </InnerListItemStyled.SummaryInfo>

                        <InnerListItemStyled.SummaryInfo>
                            {isCapabilityAssessmentAssessed ? (
                                project?.isrrimpact ? (
                                    <ImpactCapabilityMaturityLevel
                                        current={capabilityAssessment.completionLevel.getMaturityLevel()}
                                    />
                                ) : (
                                    <CapabilityMaturityLevel
                                        current={capabilityAssessment.completionLevel.getMaturityLevel()}
                                    />
                                )
                            ) : (
                                `Weight: ${capabilityAssessment.weight}`
                            )}
                        </InnerListItemStyled.SummaryInfo>

                        {(rolesEntityList.hasProgramManager() || rolesEntityList.hasComponentOwner() || rolesEntityList.hasOperator()) ? (
                            <SummaryInfoActions>
                                {(rolesEntityList.hasProgramManager() || rolesEntityList.hasComponentOwner()) ? (
                                    <>
                                        {capabilityAssessment.hasOperators() ? (
                                            <>
                                                <span>
                                                    <strong>Assigned for review:</strong>{' '}
                                                    {operators}
                                                </span>
                                                <Button
                                                    size='small'
                                                    variant='outlined'
                                                    onClick={handleOpenAssignForReviewModal}
                                                >
                                                    Reassign
                                                </Button>
                                            </>
                                        ) : (
                                            project.isAssessment() && (
                                                <Button
                                                    size='small'
                                                    variant='outlined'
                                                    onClick={handleOpenAssignForReviewModal}
                                                >
                                                    Assign for review
                                                </Button>
                                            )
                                        )}
                                    </>
                                ) : null}

                                {project.isAssessment() ? (
                                    <>
                                        {(hasPmRole && hasStRole) ?

                                            <Button
                                                size='small'
                                                variant='outlined'
                                                onClick={handleOpenSurveyModal}
                                            >
                                                {isCapabilityAssessmentAssessed
                                                    ? 'Change assessment'
                                                    : 'Assess'}
                                            </Button>
                                            : (hasStRole || isAssessmentApproved) ? null : (
                                                <Button
                                                    size='small'
                                                    variant='outlined'
                                                    onClick={handleOpenSurveyModal}
                                                >
                                                    {isCapabilityAssessmentAssessed
                                                        ? 'Change assessment'
                                                        : 'Assess'}
                                                </Button>
                                            )}

                                        {project?.isrrimpact ? (
                                            ''
                                        ) : isCapabilityAssessmentAssessed ? (
                                            <CounterHint label='Gaps' count={gapsCount} />
                                        ) : null}
                                    </>
                                ) : null}
                            </SummaryInfoActions>
                        ) : null}
                    </>
                }
            >
                {isCapabilityAssessmentAssessed ? (
                    <>
                        <InnerListItemStyled.Details>
                            {capabilityAssessment.gaps ? (
                                <InnerListItemStyled.DetailInfo>
                                    <p>{project?.isrrimpact ? 'Evidence' : 'Gaps'}</p>
                                    <p>{capabilityAssessment.gaps}</p>
                                </InnerListItemStyled.DetailInfo>
                            ) : null}

                            {capabilityAssessment.recommendations ? (
                                <InnerListItemStyled.DetailInfo>
                                    <p>{project?.isrrimpact ? 'Assumptions' : 'Recommendations'}</p>
                                    <p>{capabilityAssessment.recommendations}</p>
                                </InnerListItemStyled.DetailInfo>
                            ) : null}

                            {capabilityAssessment.notes ? (
                                <InnerListItemStyled.DetailInfo>
                                    <p>{project?.isrrimpact ? 'Unwanted events' : 'Measures'}</p>
                                    <p>{capabilityAssessment.notes}</p>
                                </InnerListItemStyled.DetailInfo>
                            ) : null}
                        </InnerListItemStyled.Details>
                        {!project?.isrrimpact ? (
                            <>
                                <StyledCapabilityGapsList
                                    //canEdit={hasPmRole}
                                    canEdit={
                                        hasPmRole ? hasPmRole : hasCORole ? hasCORole : hasOPRole
                                    }
                                    capabilityAssessmentId={capabilityAssessment.id}
                                    capabilityLevelId={capabilityAssessment.completionLevel.id}
                                    project={project}
                                    componentAssessment={componentAssessment}
                                    capabilityLevelGaps={gaps}
                                />
                            </>
                        ) : (
                            ''
                        )}
                    </>
                ) : null}
            </AccordionInnerListItem>

            <AssessmentSurveyFormModal
                isAIAccess={isAIAccess}
                open={openSurveyModal}
                capabilityAssessmentId={capabilityAssessment.id}
                onClose={handleCloseSurveyModal}
            />

            <AssignAssessmentCapabilityToOperatorFormModal
                open={openAssignForReviewModal}
                capabilityAssessmentId={capabilityAssessment.id}
                componentAssessmentId={componentAssessment.id}
                onClose={handleCloseAssignForReviewModal}
            />

            <AssessmentCapabilityHistoryModal
                open={openHistoryModal}
                capabilityAssessment={capabilityAssessment}
                onClose={() => setOpenHistoryModal(false)}
            />
        </>
    );
};

export { AssessmentCapabilitiesListItem };

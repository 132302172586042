import React from 'react';

import { ListItemActions } from '@modules/layout/moleculas';
import { DialogCompanyDelete } from '@modules/company/moleculas';

type CompaniesListItemActionsProps = {
    companyId: number;
    onDelete: (companyId: number) => Promise<void>;
};

const CompaniesListItemActions = (props: CompaniesListItemActionsProps): React.ReactElement => {
    const { companyId, onDelete } = props;

    const handleDelete = () => onDelete(companyId);

    return (
        <ListItemActions>
            {actionsProps => {
                const { onClose } = actionsProps;

                const handleClick = (fn?: any) => (): void => {
                    if (typeof fn === 'function') {
                        fn();
                    }

                    onClose();
                };

                return (
                    <>
                        <DialogCompanyDelete
                            onDelete={handleClick(handleDelete)}
                            companyId={companyId}
                        />
                    </>
                );
            }}
        </ListItemActions>
    );
};

export { CompaniesListItemActions };

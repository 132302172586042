import { gql } from '@apollo/client';

import { AddAssessmentCategoriesFragment } from '../fragments';

export const UpdateAssessmentCategoriesMutation = gql`
    mutation UpdateAssessmentCategories($id: Int!, $name: String!, $isRRType: Boolean!) {
        updateAssessmentCategories(id: $id, name: $name, is_rr_type: $isRRType) {
            ...AssessmentCategories
        }
    }
    ${AddAssessmentCategoriesFragment}
`;

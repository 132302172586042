import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';

import { ComponentEntity } from '@modules/components/entities';
import { RoadmapRecommendationEntity } from './roadmap-recommendation-entity';

import type { ComponentEntityType } from '@modules/components/entities';
import type { ComponentRecommendation } from '@modules/types/graphql';
import type { Entity as RoadmapRecommendationEntityType } from './roadmap-recommendation-entity';

export type Entity = Partial<
    Omit<ComponentRecommendation, 'component' | 'recommendation'> & {
        component: ComponentEntityType;
        recommendation: RoadmapRecommendationEntityType;
    }
> | null;

@DecribeEntity('ComponentRecommendationEntity')
class ComponentRecommendationEntity extends BaseEntity {
    roadmapRecommendationId: number;
    assessmentId: number;
    componentId: number;
    // maturityLevel: MaturityLevel;

    @Relation(() => ComponentEntity)
    component: ComponentEntity;

    @Relation(() => RoadmapRecommendationEntity)
    recommendation: RoadmapRecommendationEntity;

    // @Relation(() => UserEntity)
    // componentOwner: UserEntity;

    constructor(componentRecommendation: Entity) {
        super(componentRecommendation);

        this.roadmapRecommendationId = componentRecommendation?.roadmap_recommendation_id ?? 0;
        this.assessmentId = componentRecommendation?.assessment_id ?? 0;
        this.componentId = componentRecommendation?.component_id ?? 0;
    }
}

export { ComponentRecommendationEntity };

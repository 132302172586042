import React, { useEffect, useMemo } from 'react';

import type { AssessmentReportEntity, ComponentReportEntity } from '@modules/assessment/entities';
import {
    gapLevelFieldLabelList,
    gapLevelFieldList,
} from '@modules/assessment/entities/capability-report-entity';
import { MaturityLevelEntity, MaturityLevelsEntityList } from '@modules/shared/entities';
import { MaturityLevel } from '@modules/types/graphql';
import {
    CapabilityLevelList,
    ImpactMaturityLevelList,
    MaturityLevelList,
} from '@modules/shared/constants/risk-level';
import {
    CapabilityAssessmentEntity,
    ComponentAssessmentEntity,
} from '@modules/assessment/entities';
import { ScoredGapEntity } from '@modules/roadmap/entities';
import { GapByRisk, GapCapabilityAssessment, GapViewData } from '@modules/assessment/gap-by-risk';
import {
    calcGapMitigationLevel,
    ImplementationStatusList,
} from '@modules/shared/constants/implementation-status';
import { GapAssessment } from '@modules/assessment/gap-assessment';
import { projectLevelColors } from '@modules/projects/constants';
import { projectImpactReportLevelColors } from '@modules/shared/constants';

export function getTopList(
    gapViewData: GapViewData,
): { allList: GapAssessment[]; topList: GapAssessment[] } {
    return {
        allList: gapViewData.scoreList,
        topList: gapViewData.scoreList.slice(0, 5),
    };
}

export function getCountByComponentSingle(
    componentReport: ComponentAssessmentEntity | null,
): [number, string] {
    let gapCount = componentReport?.capabilities.reduce((count, entity) => {
        return count + (entity?.scoredGaps?.filter(value => Boolean(value)).length ?? 0);
    }, 0);

    return [gapCount ?? 0, componentReport?.component.name ?? ''];
}

export function getCapabilityLevelByComponent(
    componentReport: ComponentReportEntity | null,
): [number, string] {
    if (!componentReport?.capabilities.length) {
        return [0, CapabilityLevelList[0]];
    }

    let levelCount = componentReport?.capabilities.reduce((count, entity) => {
        return count + (entity?.completionLevel?.level ?? 0);
    }, 0);

    let levelAverage = Math.round(levelCount / (componentReport?.capabilities.length ?? 1));
    if (levelAverage >= CapabilityLevelList.length) {
        levelAverage = 0;
    }

    return [levelAverage, CapabilityLevelList[levelAverage]];
}

export function getCountByComponent(
    componentsAssessmentList: ComponentAssessmentEntity[],
    componentAssessmentRawMap: Map<number, GapCapabilityAssessment[]>,
): Array<[number, string]> {
    let countByComponent: Array<[number, string]> = componentsAssessmentList.map(
        componentReport => {
            return [
                componentAssessmentRawMap.get(componentReport.id)?.length ?? 0,
                componentReport?.component.name ?? '',
            ];
        },
    );

    return countByComponent;
}

export function getCountByRisk(gapByRisk: GapByRisk): Array<[number, string]> {
    let countByRisk: Array<[number, string]> = gapLevelFieldList.map((field, fieldIndex) => {
        return [gapByRisk[field]?.length ?? 0, gapLevelFieldLabelList[fieldIndex]];
    });

    return countByRisk;
}

export function getComponentMaturityData(
    maturityLevels: MaturityLevelEntity[],
    componentsAssessmentList: ComponentAssessmentEntity[] | ComponentReportEntity[],
) {
    let componentByMaturity: Array<[number, string]> = (componentsAssessmentList as any)
        .filter((value: any) => Boolean(value))
        .map((componentReport: ComponentAssessmentEntity | ComponentReportEntity) => {
            return [componentReport?.maturityLevel.id ?? 0, componentReport?.component.name ?? ''];
        });

    let maturityMap: Map<number, string> = maturityLevels
        ? new Map(
              maturityLevels.map(maturityLevel => {
                  return [maturityLevel.id ?? 0, maturityLevel.name ?? ''];
              }),
          )
        : new Map<number, string>();

    return {
        componentByMaturity,
        maturityMap,
    };
}

export type StatusGraphDataset = {
    labelList: string[];
    datasetList: Array<{ label: string; data: number[] }>;
};

export function useStatusByComponentConfig(datasetMapList: StatusGraphDataset) {
    let backgroundColor = Object.values(projectLevelColors);

    const barChartData = useMemo(
        () => ({
            labels: datasetMapList.labelList,
            datasets: datasetMapList.datasetList.slice(1).map((dataset, index) => {
                let statusIndex = 2 + (index % 3) * 2;

                return {
                    label: dataset.label,
                    data: dataset.data, //.map((value, index) => index + 1),
                    hoverOffset: 4,
                    backgroundColor: backgroundColor[statusIndex],
                };
            }),
        }),
        undefined,
    );

    const barChartOptions: any = useMemo(
        () => ({
            responsive: true,
            scales: {
                y: {
                    stacked: true,
                    beginAtZero: true,
                },
                x: {
                    stacked: true,
                    ticks: {
                        autoSkip: false,
                    },
                },
            },
            plugins: {
                legend: {
                    position: 'top',
                    display: true,
                },
                datalabels: {
                    display: function (context: any) {
                        let dataset = context.dataset;
                        let index = context.dataIndex;

                        // display condition
                        return dataset.data[index];
                    },
                    // formatter: (value: any, context: any) => {
                    //   let dataset = context.dataset;
                    //   let index = context.dataIndex;
                    //
                    //   return dataset.data[index] ? dataset.labels[index] + ': ' + dataset.data[index] : '';
                    // }
                },
            },
        }),
        undefined,
    );

    return {
        barChartData,
        barChartOptions,
    };
}

export function useComponentMaturityChartConfig(
    componentByMaturity: Array<[number, string]>,
    maturityMap: Map<number, string>,
) {
    const barChartData = useMemo(
        () => ({
            labels: componentByMaturity.map(([maturityId, label]) => label),
            datasets: [
                {
                    hoverOffset: 4,
                    data: componentByMaturity.map(([maturityId, label], index) => maturityId),
                },
            ],
        }),
        undefined,
    );

    const barChartOptions: any = useMemo(
        () => ({
            responsive: true,
            scales: {
                y: {
                    beginAtZero: false,
                    min: 1,
                    max: maturityMap.size,
                    ticks: {
                        callback: function (value: any, index: any, ticks: any) {
                            return maturityMap.get(value) ? `${maturityMap.get(value)}` : value;
                        },
                    },
                },
                x: {
                    ticks: {
                        autoSkip: false,
                    },
                },
            },
            plugins: {
                legend: {
                    position: 'top',
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label: function (context: any) {
                            let dataset = context.dataset;
                            let index = context.dataIndex;
                            return (
                                maturityMap.get(dataset.data[index]) + ' - ' + dataset.data[index]
                            );
                        },
                    },
                },
                autocolors: {
                    enabled: true,
                    mode: 'data',
                    offset: 20,
                },
                datalabels: {
                    display: false,
                },
            },
        }),
        undefined,
    );

    return {
        barChartData,
        barChartOptions,
    };
}

export function useGapPieOptions() {
    const pieChartOptions: any = useMemo(
        () => ({
            responsive: true,
            // maintainAspectRatio: false,
            layout: {
                padding: {
                    left: 50,
                    right: 50,
                    top: 15,
                    bottom: 50,
                },
            },
            // Multi Series Pie
            // https://www.chartjs.org/docs/latest/samples/other-charts/multi-series-pie.html
            plugins: {
                legend: {
                    position: 'top',
                    display: false,
                    // labels: {
                    //   generateLabels: function(chart: any) {
                    //     // Get the default label list
                    //     const original = Chart.overrides.pie.plugins.legend.labels.generateLabels;
                    //     const labelsOriginal = original.call(this, chart);
                    //
                    //     // Build an array of colors used in the datasets of the chart
                    //     let datasetColors = chart.data.datasets.map(function(e: any) {
                    //       return e.backgroundColor;
                    //     });
                    //     datasetColors = datasetColors.flat();
                    //
                    //     // Modify the color and hide state of each label
                    //     labelsOriginal.forEach(label => {
                    //       // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index
                    //       let index = label.index ?? 0;
                    //       // label.datasetIndex = (index - index % gapCountByRisk.length) / gapCountByRisk.length;
                    //       label.datasetIndex = index;
                    //
                    //       // The hidden state must match the dataset's hidden state
                    //       // label.hidden = !chart.isDatasetVisible(label.datasetIndex);
                    //
                    //       // Change the color to match the dataset
                    //       index = label.index ?? 0;
                    //       label.fillStyle = datasetColors[index];
                    //     });
                    //     console.error(labelsOriginal)
                    //     return labelsOriginal;
                    //   }
                    // },
                },
                // tooltip: {
                //   callbacks: {
                //     label: function(context: any) {
                //       const labelIndex = context.datasetIndex;
                //       return context.chart.data.labels[labelIndex] + ': ' + context.formattedValue;
                //     }
                //   }
                // },
                // onClick: function(mouseEvent: any, legendItem: any, legend: any) {
                //   // toggle the visibility of the dataset from what it currently is
                //   legend.chart.getDatasetMeta(
                //     legendItem.datasetIndex
                //   ).hidden = legend.chart.isDatasetVisible(legendItem.datasetIndex);
                //   legend.chart.update();
                // },
                tooltip: {
                    callbacks: {
                        label: function (context: any) {
                            let dataset = context.dataset;
                            let index = context.dataIndex;
                            return dataset.labels[index] + ': ' + dataset.data[index];
                        },
                    },
                },
                datalabels: {
                    color: 'black',
                    backgroundColor: 'rgba(255,255,255,0.85)',
                    // textShadowColor: 'white',
                    // textShadowBlur: 20,
                    // font: {
                    //   weight: 600
                    // },
                    anchor: 'end',
                    align: 'start',
                    display: function (context: any) {
                        let dataset = context.dataset;
                        let index = context.dataIndex;

                        // display condition
                        return dataset.data[index];
                    },
                    formatter: (value: any, context: any) => {
                        let dataset = context.dataset;
                        let index = context.dataIndex;

                        return dataset.data[index]
                            ? dataset.labels[index] + ': ' + dataset.data[index]
                            : '';
                    },
                },
                autocolors: {
                    enabled: true,
                    mode: 'data',
                    offset: 20,
                },
            },
        }),
        undefined,
    );

    return pieChartOptions;
}

export function useComponentGapPieChartOptions() {
    const commonOptions = useGapPieOptions();
    const pieChartOptions: any = useMemo(
        () => ({
            ...commonOptions,
            layout: {
                padding: {
                    left: 10,
                    right: 10,
                    top: 10,
                    bottom: 10,
                },
            },
        }),
        undefined,
    );

    delete pieChartOptions?.plugins?.autocolors;
    return pieChartOptions;
}

export function useCountByRiskConfig(countByRisk: Array<[number, string]>) {
    let backgroundColor = Object.values(projectLevelColors);

    const pieChartData = useMemo(
        () => ({
            labels: ([] as Array<[number, string]>)
                .concat(countByRisk)
                .map(([gapCount, label]) => label),
            datasets: [
                {
                    hoverOffset: 4,
                    labels: countByRisk.map(([gapCount, label], index) => label),
                    data: countByRisk.map(([gapCount, label], index) => gapCount),
                    backgroundColor: backgroundColor,
                    borderColor: backgroundColor,
                },
            ],
        }),
        undefined,
    );

    const pieChartOptions = useGapPieOptions();
    delete pieChartOptions?.plugins?.autocolors;

    return {
        pieChartData,
        pieChartOptions,
    };
}

export function useCountByComponentConfig(gapCountByComponent: Array<[number, string]>) {
    const pieChartData = useMemo(
        () => ({
            labels: ([] as Array<[number, string]>)
                .concat(gapCountByComponent)
                .map(([gapCount, label]) => label),
            datasets: [
                {
                    hoverOffset: 4,
                    labels: gapCountByComponent.map(([gapCount, label], index) => label),
                    data: gapCountByComponent.map(([gapCount, label], index) => gapCount),
                },
            ],
        }),
        undefined,
    );

    return {
        pieChartData,
        pieChartOptions: useGapPieOptions(),
    };
}

export function useRecommendationStatusConfig(recommendationStatus: {
    labelList: string[];
    datasetList: number[];
}) {
    let backgroundColor = Object.values(projectLevelColors);
    let impColor = ImplementationStatusList.slice(1).map((value, index) => {
        let statusIndex = 2 + (index % 3) * 2;
        return backgroundColor[statusIndex];
    });

    const pieChartData = useMemo(
        () => ({
            labels: recommendationStatus.labelList,
            datasets: [
                {
                    hoverOffset: 4,
                    labels: recommendationStatus.labelList,
                    data: recommendationStatus.datasetList, //.map((value, index) => index+1),
                    backgroundColor: impColor,
                    borderColor: impColor,
                },
            ],
        }),
        undefined,
    );

    const pieChartOptions = useGapPieOptions();
    delete pieChartOptions?.plugins?.autocolors;

    return {
        pieChartData,
        pieChartOptions,
    };
}

export function useScoreArcOptions() {
    const pieChartOptions: any = useMemo(
        () => ({
            responsive: true,
            circumference: 180,
            rotation: -90,
            cutout: '90%',
            layout: {
                padding: {
                    left: 50,
                    right: 50,
                    top: 0,
                    bottom: 0,
                },
            },
            plugins: {
                legend: {
                    position: 'top',
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label: function (context: any) {
                            let dataset = context.dataset;
                            let index = context.dataIndex;
                            return dataset?.labels[index] ?? '';
                        },
                    },
                },
                datalabels: {
                    color: 'black',
                    backgroundColor: 'rgba(255,255,255,0.85)',
                    anchor: 'end',
                    align: 'end',
                    display: function (context: any) {
                        let dataset = context.dataset;
                        let index = context.dataIndex;

                        // display condition
                        return dataset.data[index];
                    },
                    formatter: (value: any, context: any) => {
                        let dataset = context.dataset;
                        let index = context.dataIndex;

                        return dataset?.labels[index] ?? '';
                    },
                },
            },
        }),
        undefined,
    );

    return pieChartOptions;
}

export function useScoreArcData(labels: string[]) {
    const pieChartData = useMemo(
        () => ({
            labels: labels,
            datasets: [
                {
                    hoverOffset: 4,
                    labels: labels,
                    // data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    data: [20, 20, 20, 20, 20],
                    backgroundColor: Object.values(projectLevelColors).filter(
                        (value, index) => index % 2 !== 0,
                    ),
                },
            ],
        }),
        undefined,
    );

    return pieChartData;
}

export function useScoreArcDataImpact(labels: string[]) {
    const pieChartData = useMemo(
        () => ({
            labels: labels,
            datasets: [
                {
                    hoverOffset: 4,
                    labels: labels,
                    // data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    data: [20, 20, 20, 20, 20],
                    backgroundColor: Object.values(projectImpactReportLevelColors).filter(
                        (value, index) => index % 2 !== 0,
                    ),
                },
            ],
        }),
        undefined,
    );

    return pieChartData;
}

export function useMaturityLevelConfig(maturityLevel: MaturityLevel) {
    // const degree = +((180/(10-1)) * (maturityLevel.id - 1) - 90);
    // const pieChartData = useScoreArcData(MaturityLevelList.filter(value => value !== 'N/A' && Number.isNaN(Number(value))));

    // let score5 = Math.floor((maturityLevel.id - 1) / 2);
    // const degree = +((180 / (5 - 1)) * score5 - 90);

    let score5 = Math.floor((maturityLevel.id - 1) / 2); //8-1/2  === 3
    let degree;
    if (maturityLevel.id % 2 === 0) {
        degree = +((180 / (5 - 1)) * score5 - 90);
    } else {
        degree = +((180 / (5 - 1)) * score5 - 110);
    }
    const pieChartOptions = useScoreArcOptions();
    const pieChartData = useScoreArcData(
        MaturityLevelList.filter(value => value !== 'N/A' && Number.isNaN(Number(value))).filter(
            (value, index) => index % 2 !== 0,
        ),
    );

    return {
        degree,
        pieChartData,
        pieChartOptions,
    };
}

export function useMaturityImpactLevel(impactLevel: any) {
    let impactmaturitylevel: any;
    switch (impactLevel) {
        case 'Pre-Nascent':
            impactmaturitylevel = 'Minimal';
            break;
        case 'Nascent':
            impactmaturitylevel = 'Minimal to Low';
            break;
        case 'Nascent to Limited':
            impactmaturitylevel = 'Low';
            break;
        case 'Limited':
            impactmaturitylevel = 'Low to Medium';
            break;
        case 'Limited to Functional':
            impactmaturitylevel = 'Medium';
            break;
        case 'Functional':
            impactmaturitylevel = 'Medium to High';
            break;
        case 'Functional to Operational':
            impactmaturitylevel = 'High';
            break;
        case 'Operational':
            impactmaturitylevel = 'High to Critical';
            break;
        case 'Operational to Mature':
            impactmaturitylevel = 'Critical';
            break;
        case 'Mature':
            impactmaturitylevel = 'Super Critical';
            break;
        default:
            impactmaturitylevel = 'Minimal';
    }
    return impactmaturitylevel;
}

export function useMitigationStatusConfig(gapScoreList: GapCapabilityAssessment[]) {
    // const degreeScore = score / (100 / (10-1));
    // const degree = +((180/(10-1)) * (degreeScore) - 90);
    // const pieChartData = useScoreArcData(['10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%']);

    const score = calcGapMitigationLevel(gapScoreList.length, gapScoreList);
    const degreeScore = score / 25;
    const degree = +((180 / (5 - 1)) * degreeScore - 90);

    const pieChartOptions = useScoreArcOptions();
    const pieChartData = useScoreArcData(['0%', '25%', '50%', '75%', '100%']);
    return {
        score,
        degree,
        pieChartData,
        pieChartOptions,
    };
}

export function useRecommendationCompletionConfig(recommendationCompletion: {
    labelList: string[];
    datasetList: number[];
}) {
    const score =
        (recommendationCompletion.datasetList[2] * 100) /
        recommendationCompletion.datasetList.reduce((acc, prev) => acc + prev, 0);
    const degreeScore = score / 25;
    const degree = (180 / (5 - 1)) * degreeScore - 90;

    const pieChartOptions = useScoreArcOptions();
    const pieChartData = useScoreArcData(['0%', '25%', '50%', '75%', '100%']);
    return {
        score,
        degree,
        pieChartData,
        pieChartOptions,
    };
}

export function useMaturityImpactLevelConfig(maturityLevel: MaturityLevel) {
    let score5 = Math.floor((maturityLevel.id - 1) / 2);
    const degree = +((180 / (5 - 1)) * score5 - 90);

    const pieChartOptions = useImpactScoreArcOptions();

    const pieChartData = useScoreArcDataImpact(
        ImpactMaturityLevelList.filter(
            value => value !== 'N/A' && Number.isNaN(Number(value)),
        ).filter((value, index) => index % 2 === 0),
    );

    return {
        degree,
        pieChartData,
        pieChartOptions,
    };
}

export function useImpactScoreArcOptions() {
    const pieChartOptions: any = useMemo(
        () => ({
            responsive: true,
            circumference: 180,
            rotation: -90,
            cutout: '90%',
            layout: {
                padding: {
                    left: 80,
                    right: 80,
                    top: 0,
                    bottom: 0,
                },
            },
            plugins: {
                legend: {
                    position: 'top',
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label: function (context: any) {
                            let dataset = context.dataset;
                            let index = context.dataIndex;
                            return dataset?.labels[index] ?? '';
                        },
                    },
                },
                datalabels: {
                    color: 'black',
                    backgroundColor: 'rgba(255,255,255,0.85)',
                    anchor: 'end',
                    align: 'end',
                    display: function (context: any) {
                        let dataset = context.dataset;
                        let index = context.dataIndex;

                        // display condition
                        return dataset.data[index];
                    },
                    formatter: (value: any, context: any) => {
                        let dataset = context.dataset;
                        let index = context.dataIndex;

                        return dataset?.labels[index] ?? '';
                    },
                },
            },
        }),
        undefined,
    );

    return pieChartOptions;
}

import React, { useMemo } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

import { Modal } from '@modules/ui/core';
import { Table, TableHead, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import type { ModalProps } from '@modules/ui/core';
import {
    getRiskLevelLabelByScore,
    getRiskAssessmentLevelLabel,
    ratingNum,
    getVulThreatAssessmentLevelLabel,
} from '@modules/shared/constants/risk-level';
import { Asset } from '@modules/types/graphql';
import { EditAssessmentInfo } from '@modules/registry/organisms/view-assessment-modal/edit-assessment-info';

const StyledModal = styled(Modal)`
    width: 1200px;

    .is-empty-results {
        font-size: 14px;
        text-align: center;

        td {
            padding: 20px;
        }
    }
`;

export const ViewAssessmentModal = (
    props: ModalProps & {
        activityType?: string;
        activeAssessment?: any;
        activeAsset?: Asset;
        refetchQuery?: any;
    },
): React.ReactElement => {
    const {
        onClose,
        activityType,
        activeAsset,
        activeAssessment,
        refetchQuery,
        ...otherProps
    } = props;
    const assessmentsHistory = useMemo(() => {
        if (!activeAsset || !activityType) {
            return [];
        }

        return activeAsset[`${activityType}Assessments`];
    }, [activeAsset, activityType]);

    return (
        <StyledModal title='Edit Assessment' onClose={onClose} {...otherProps}>
            <EditAssessmentInfo
                refetchQuery={refetchQuery}
                activeAsset={activeAsset}
                activeAssessment={activeAssessment}
                activityType={activityType}
                onClose={onClose}
            />

            <Typography variant='h4' style={{ fontWeight: 'bold', margin: '20px 0 10px' }}>
                Assessment history
            </Typography>
            <Table>
                <TableHead>
                    {activityType === 'impact' ? (
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell>Rating</TableCell>
                            <TableCell>Source</TableCell>
                            <TableCell>Evidence</TableCell>
                            <TableCell>Unwanted events</TableCell>
                            <TableCell>Assumptions</TableCell>
                        </TableRow>
                    ) : (
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell>Rating</TableCell>
                            <TableCell>Source</TableCell>
                            <TableCell>Gaps</TableCell>
                            <TableCell>Recommendations</TableCell>
                            <TableCell>Measures</TableCell>
                        </TableRow>
                    )}
                </TableHead>
                <TableBody>
                    {assessmentsHistory.length ? (
                        [...assessmentsHistory]
                            .sort(
                                (a: any, b: any) =>
                                    new Date(b.created_at).getTime() -
                                    new Date(a.created_at).getTime(),
                            )
                            .map((assessment: any, i: number) => {
                                const {
                                    created_at,
                                    score,
                                    gaps,
                                    notes,
                                    recommendations,
                                    created_by,
                                    // in some cases null in DB
                                    source,
                                } = assessment;
                                const calcStore = ratingNum(score, activityType);

                                return (
                                    <React.Fragment key={i}>
                                        {activityType === 'impact' ? (
                                            <TableRow>
                                                <TableCell>
                                                    {format(
                                                        new Date(assessment?.created_at),
                                                        'yyyy-MM-dd',
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {created_by?.first_name} {created_by?.last_name}
                                                </TableCell>
                                                <TableCell>
                                                    {getRiskAssessmentLevelLabel(
                                                        getRiskLevelLabelByScore(
                                                            score,
                                                            activityType,
                                                        ),
                                                        activityType,
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {source === null
                                                        ? 'Manual'
                                                        : 'Program assessment'}
                                                </TableCell>
                                                <TableCell>{gaps}</TableCell>
                                                <TableCell>{recommendations}</TableCell>
                                                <TableCell>{notes}</TableCell>
                                            </TableRow>
                                        ) : (
                                            <TableRow>
                                                <TableCell>
                                                    {format(new Date(created_at), 'yyyy-MM-dd')}
                                                </TableCell>
                                                <TableCell>
                                                    {created_by?.first_name} {created_by?.last_name}
                                                </TableCell>
                                                <TableCell>
                                                    {getVulThreatAssessmentLevelLabel(
                                                        getRiskLevelLabelByScore(
                                                            score,
                                                            activityType,
                                                        ),
                                                        activityType,
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {source === null
                                                        ? 'Manual'
                                                        : 'Program assessment'}
                                                </TableCell>
                                                <TableCell>{gaps}</TableCell>
                                                <TableCell>{recommendations}</TableCell>
                                                <TableCell>{notes}</TableCell>
                                            </TableRow>
                                        )}
                                    </React.Fragment>
                                );
                            })
                    ) : (
                        <tr className='is-empty-results'>
                            <td colSpan={6}>No history</td>
                        </tr>
                    )}
                </TableBody>
            </Table>
        </StyledModal>
    );
};

import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { UserDetails } from '@modules/types/graphql';

export type Entity = Partial<UserDetails> | null;

@DecribeEntity('UserDetailEntity')
class UserDetailEntity extends BaseEntity {
    id: number;
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    cognitoId: string;
    isAdmin: boolean;
    isSuperAdmin: boolean;
    companyId: number;
    userRoles: string;

    constructor(UserDetails: Entity) {
        super(UserDetails);

        this.id = UserDetails?.id ?? -1;
        this.username = UserDetails?.username ?? '';
        this.email = UserDetails?.email ?? '';
        this.firstName = UserDetails?.first_name ?? '';
        this.lastName = UserDetails?.last_name ?? '';
        this.cognitoId = UserDetails?.cognito_id ?? '';
        this.isAdmin = UserDetails?.is_admin ?? false;
        this.isSuperAdmin = UserDetails?.is_super_admin ?? false;
        this.companyId = UserDetails?.company_id ?? -1;
        this.userRoles = UserDetails?.user_role ?? '';
    }

    exists() {
        const isAuth = this.id > 0;

        return isAuth;
    }

    hasAdminPermission() {
        const result = this.isAdmin === true;

        return result;
    }

    hasSuperAdminPermission() {
        const result = this.isSuperAdmin === true;

        return result;
    }

    getFullName() {
        const fullName = (this.firstName + ' ' + this.lastName).trim();

        return fullName;
    }
}

export { UserDetailEntity };

import { gql } from '@apollo/client';
import { AskTkoDetailFragment } from '../fragments';

export const GetAllAskTkoDetailsQuery = gql`
    query GetAllAskTkoDetailsQuery {
        getAllAskTkoDetails {
            ...AskTkoDetails
        }
    }
    ${AskTkoDetailFragment}
`;

import React from 'react';

import { ListItemActions } from '@modules/layout/moleculas';
import { DialogUserDelete } from '@modules/users/moleculas';
import { DialogUserEdit } from '../dialog-user-edit';

type UsersListItemActionsProps = {
    userId: number;
    onDelete: (userId: number) => Promise<void>;
    onUpdate: (userId: any) => void;
};

const UsersListItemActions = (props: UsersListItemActionsProps): React.ReactElement => {
    const { userId, onDelete, onUpdate } = props;

    const handleDelete = () => onDelete(userId);
    const handleUpdate = () => onUpdate(userId);

    return (
        <ListItemActions>
            {actionsProps => {
                const { onClose } = actionsProps;

                const handleClick = (fn?: any) => (): void => {
                    if (typeof fn === 'function') {
                        fn();
                    }

                    onClose();
                };

                return (
                    <>
                        <DialogUserDelete onDelete={handleClick(handleDelete)} />
                        <DialogUserEdit userId={userId} onUpdate={handleClick(handleUpdate)} />
                    </>
                );
            }}
        </ListItemActions>
    );
};

export { UsersListItemActions };

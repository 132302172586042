import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

const StyledRatingCursor = styled.div`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: 0 46px;
    transition: transform 0.2s ease-in-out;
`;

export const RatingPlateCursor: FC<{
    rotateDeg?: number;
    transformationStr?: string;
    className?: string;
}> = ({ rotateDeg, transformationStr, className }) => {
    const transformStyles =
        rotateDeg !== undefined ? `rotate(${rotateDeg}deg) translateX(-50%)` : transformationStr;

    return (
        <StyledRatingCursor style={{ transform: transformStyles }} className={className}>
            <svg
                width='59'
                height='60'
                viewBox='0 0 59 60'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M37.7564 46.2488L30.0502 0.356619L21.2004 46.0275C21.1065 46.5146 21.0467 47.014 21.0403 47.5283C20.9819 52.1814 24.7044 56.0032 29.3542 56.065C34.0035 56.1273 37.8204 52.4066 37.8786 47.7546C37.8851 47.2403 37.8388 46.7389 37.7564 46.2488Z'
                    fill='#656D78'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M29.3544 56.0655C34.0037 56.1278 37.8201 52.4064 37.8783 47.7545C37.8847 47.2402 37.8384 46.7388 37.7566 46.2493L30.0504 0.356911L29.3544 56.0655Z'
                    fill='#494E57'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M32.7252 47.6842C32.7028 49.4881 31.223 50.9317 29.4196 50.9071C27.6167 50.8831 26.1738 49.4012 26.1957 47.5967C26.2188 45.7923 27.698 44.3492 29.5014 44.3738C31.3049 44.3974 32.7488 45.8804 32.7252 47.6842Z'
                    fill='white'
                />
            </svg>
        </StyledRatingCursor>
    );
};

import React from 'react';
import styled from 'styled-components';
import { Redirect } from '@reach/router';

import { routes } from '@config/routes';
import { useCurrentUser } from '@modules/auth/hooks';
import {
    ChangePasswordForm,
    SigninForm,
    SetupMFAForm,
    ConfirmSigninForm,
} from '@modules/auth/organisms';

import type { CognitoUser } from 'amazon-cognito-identity-js';

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const LoginPage = (): React.ReactElement => {
    const { currentUser } = useCurrentUser();

    const [userForChangePassword, setUserForChangePassword] = React.useState<CognitoUser | any>(
        null,
    );

    const [userForMFASetup, setUserForMFASetup] = React.useState<CognitoUser | any>(null);

    const [userForConfirmSignin, setUserForConfirmSignin] = React.useState<CognitoUser | any>(null);

    const handleSetUserForChangePassword = (user: CognitoUser | any): void =>
        setUserForChangePassword(user);
    const handleSetUserForMFASetup = (user: CognitoUser | any): void => setUserForMFASetup(user);
    const handleResetUser = (): void => setUserForChangePassword(null);
    const handleShowConfirmSigninForm = (user: CognitoUser | any): void =>
        setUserForConfirmSignin(user);

    const handleSignInSubmit = (user: CognitoUser | any): void => {
        console.log(user, 'user');
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            handleSetUserForChangePassword(user);
        }

        if (user.challengeName === 'MFA_SETUP') {
            handleSetUserForMFASetup(user);
        }

        if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
            handleShowConfirmSigninForm(user);
        }
    };

    if (currentUser.exists()) {
        return <Redirect noThrow to={routes.index.path} />;
    }

    return (
        <Root>
            {!!userForChangePassword ? (
                <ChangePasswordForm user={userForChangePassword} onHide={handleResetUser} />
            ) : !!userForMFASetup ? (
                <SetupMFAForm user={userForMFASetup} onSubmit={handleShowConfirmSigninForm} />
            ) : !!userForConfirmSignin ? (
                <ConfirmSigninForm user={userForConfirmSignin} />
            ) : (
                <SigninForm onSubmit={handleSignInSubmit} />
            )}
        </Root>
    );
};

export { LoginPage };

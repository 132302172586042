import { gql } from '@apollo/client';

export const ListImplementationStatusesQuery = gql`
    query listImplementationStatuses {
        listImplementationStatuses {
            row_number
            status
        }
    }
`;

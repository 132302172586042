import React from 'react';
import { makeStyles, Chip as MuiChip } from '@material-ui/core';

import { orange } from '@modules/ui/colors';

import type { ChipProps } from '@material-ui/core';

const useChipStyles = makeStyles({
    root: {
        fontSize: '1.2rem',
    },
    colorSecondary: {
        backgroundColor: orange[100],
    },
});

const Chip = (props: ChipProps): React.ReactElement => {
    const { classes, ...otherProps } = props;

    const chipClasses = useChipStyles();

    return (
        <MuiChip
            classes={{
                ...chipClasses,
                ...classes,
            }}
            {...otherProps}
        />
    );
};

export { Chip };

import React from 'react';
import styled from 'styled-components';
import { Info } from '@material-ui/icons';

export type AlertProps = {
    title: string;
    content?: React.ReactNode;
    className?: string;
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 0.25rem;
    border: 1px solid #ffecb5;
    background: #fff3cd;
    color: #664d03;
`;

const Icon = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    color: #664d03;
`;

const Header = styled.div`
    display: flex;
    flex: 1;
`;

const Title = styled.h4`
    margin: 0;
    padding: 0 0 0 10px;
    font-size: 1.6rem;
    font-weight: normal;
`;

const Content = styled.div`
    padding-left: 34px;
    font-size: 1.4rem;
    line-height: 22px;
`;

const infoIcon = <Info style={{ fontSize: 24 }} />;

const Alert = (props: AlertProps): React.ReactElement => {
    const { title, content, ...otherProps } = props;

    return (
        <>
            <Wrapper {...otherProps}>
                <Header>
                    <Icon>{infoIcon}</Icon>
                    {title ? <Title>{title}</Title> : <Content>{content}</Content>}
                </Header>

                {content && title ? <Content>{content}</Content> : null}
            </Wrapper>
        </>
    );
};

export { Alert };

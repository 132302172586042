import React from 'react';
import styled, { css } from 'styled-components';

import { white } from '@modules/ui/colors';
import { ProjectMaturityLevelEnum, projectLevelColors } from '@modules/projects/constants';

type MaturityLevelProps = {
    current: ProjectMaturityLevelEnum;
};

const levelProgress = {
    [ProjectMaturityLevelEnum.Nascent]: '<25%',
    [ProjectMaturityLevelEnum.Limited]: '25%',
    [ProjectMaturityLevelEnum.Functional]: '50%',
    [ProjectMaturityLevelEnum.Operational]: '75%',
    [ProjectMaturityLevelEnum.Mature]: '>75%',
};

const Root = styled.div`
    display: flex;
    border: 1px solid #f2f2f2;
    border-radius: 4px;

    > * {
        padding: 8px 16px;
    }
`;

const Progress = styled.div<{ color?: string }>`
    color: ${white[100]};
    font-size: 1.4rem;
    border-radius: 4px 0 0 4px;

    ${p => css`
        background-color: ${p.color};
    `}
`;

const MaturityLabel = styled.div`
    text-transform: capitalize;
`;

const MaturityLevel = (props: MaturityLevelProps): React.ReactElement => {
    const { current } = props;

    return (
        <Root>
            <Progress color={projectLevelColors[current]}>{levelProgress[current]}</Progress>
            <MaturityLabel>{current}</MaturityLabel>
        </Root>
    );
};

export { MaturityLevel };

import { EntityList } from '@lib/entity';
import { ProjectEntity } from './project-entity';

import type { Entity } from './project-entity';

class ProjectEntityList extends EntityList<ProjectEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(ProjectEntity, items, count);
    }
}

export { ProjectEntityList };

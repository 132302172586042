import { useQuery } from '@apollo/client';
import type { QueryHookOptions } from '@apollo/client';
import { GetCompanyDetailsQuery } from '../graphql/queries';

const useCompanyDetails = (options?: QueryHookOptions) => {
    const { data, loading } = useQuery(GetCompanyDetailsQuery, options);

    const result = {
        CompanyDetails: data?.listCompaniesDetails,
        loading,
    };

    return result;
};

export { useCompanyDetails };

import { gql } from '@apollo/client';

import { CompanyDetailsFragment } from '../fragments';

export const GetCompanyDetailQuery = gql`
    query GetCompanyDetails($id: Int!) {
        getCompanyDetails(id: $id) {
            ...CompanyDetails
        }
    }
    ${CompanyDetailsFragment}
`;

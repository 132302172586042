import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { ComponentAssessmentEntity } from '@modules/assessment/entities';
import { GetComponentAssessmentQuery } from '@modules/assessment/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetComponentAssessmentQueryType,
    GetComponentAssessmentQueryVariables,
} from '@modules/types/graphql';

const useComponentAssessment = (
    options?: QueryHookOptions<
        GetComponentAssessmentQueryType,
        GetComponentAssessmentQueryVariables
    >,
) => {
    const queryResult = useQuery<
        GetComponentAssessmentQueryType,
        GetComponentAssessmentQueryVariables
    >(GetComponentAssessmentQuery, options);

    const componentAssessment = useEntity(
        () => new ComponentAssessmentEntity(queryResult.data?.getComponentAssessment ?? null),
        [queryResult.data],
    );

    const result = {
        componentAssessment,
        ...queryResult,
    };

    return result;
};

export { useComponentAssessment };

import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { Role } from '@modules/types/graphql';

export type Entity = Partial<Role> | null;

@DecribeEntity('UserRolesEntity')
class UserRolesEntity extends BaseEntity {
    id: string;
    name: string;

    constructor(Role: Entity) {
        super(Role);

        this.id = Role?.id ?? '';
        this.name = Role?.name ?? '';
    }
}

export { UserRolesEntity };

import { gql } from '@apollo/client';
import { CompanyDetailsFragment } from '../fragments';

export const GetCompanyDetailsQuery = gql`
    query listCompaniesDetails {
        listCompaniesDetails {
            ...CompanyDetails
        }
    }
    ${CompanyDetailsFragment}
`;

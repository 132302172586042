import { EntityList } from '@lib/entity';
import type { Entity } from './all-learn-tko-detials-entity';
import { AllLearnTkoDetailsEntity } from './all-learn-tko-detials-entity';

class AllLearnTkoDetailsEntityList extends EntityList<AllLearnTkoDetailsEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(AllLearnTkoDetailsEntity, items, count);
    }
}

export { AllLearnTkoDetailsEntityList };

import { gql } from '@apollo/client';

export const GetRiskAssignUserQuery = gql`
    query listRiskAssignUser {
        listRiskAssignUser {
            user_id
            riskGroups{
                id
                name
                risk_function_id
            }
        }
    }
`;

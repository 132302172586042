import { gql } from '@apollo/client';

import { RecommendationSingleFragment } from '@modules/custom-components/graphql';
import { RoadmapRecommendationRefinementFragment } from './roadmap-recommendation-refinement';

export const RoadmapRecommendationFragment = gql`
    fragment RoadmapRecommendation on RoadmapRecommendation {
        id
        status {
            status
        }
        gap {
            id
            capability_level_id
            gap
            score
            risk_level
        }
        recommendation {
            ...RecommendationSingle
        }
        refinement {
            ...RoadmapRecommendationRefinement
        }
    }
    ${RecommendationSingleFragment}
    ${RoadmapRecommendationRefinementFragment}
`;

import { gql } from '@apollo/client';

import { GapSingleFragment } from '../fragments';

export const UpdateGapMutation = gql`
    mutation UpdateGap($gapId: Int!, $capabilityLevelId: Int!, $gap: String!) {
        updateGap(gap_id: $gapId, capability_level_id: $capabilityLevelId, gap: $gap) {
            ...GapSingle
        }
    }
    ${GapSingleFragment}
`;

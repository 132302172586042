import { gql } from '@apollo/client';
import { LearnCardMenuFragment } from '../fragments';

export const DeleteMenuCardMutation = gql`
    mutation DeleteMenuCard($menuId: Int!) {
        deleteMenuCard(menu_id: $menuId) {
            ...MenuCards
        }
    }
    ${LearnCardMenuFragment}
`;

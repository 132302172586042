import { EntityList } from '@lib/entity';
import type { ListAssessmentCategoriesEntityType } from './list-assessment-categories-entity';
import { ListAssessmentCategoriesEntity } from './list-assessment-categories-entity';

class ListAssessmentCategoriesEntityList extends EntityList<ListAssessmentCategoriesEntity> {
    constructor(items: ListAssessmentCategoriesEntityType[] | null, count?: number | null) {
        super(ListAssessmentCategoriesEntity, items, count);
    }
}

export { ListAssessmentCategoriesEntityList };

import { gql } from '@apollo/client';

import { OrgFormFragment } from '../fragments';

export const CreateOrgFormMutation = gql`
    mutation SubmitOrganizationForm(
        $userId: Int!
        $username: String!
        $numberOfEmployees: Int!
        $sector: String!
        $organizationType: String!
        $orgLocation: String!
        $workforce: String!
        $userRoleId: String!
    ) {
        submitOrganizationForm(
            user_id: $userId
            username: $username
            number_of_employees: $numberOfEmployees
            sector: $sector
            organization_type: $organizationType
            organization_location: $orgLocation
            workforce: $workforce
            user_role_id: $userRoleId
        ) {
            ...OrgSingle
        }
    }
    ${OrgFormFragment}
`;

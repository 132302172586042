import React from 'react';
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';

import { Dialog } from '@modules/ui/core';

type DialogProjectDeleteProps = {
    onDelete: () => void;
};

const StyledDialog = styled(Dialog)`
    min-height: initial;
`;

const DialogProjectDelete = (props: DialogProjectDeleteProps): React.ReactElement => {
    const { onDelete } = props;

    return (
        <StyledDialog
            title='Are you sure you want to delete this project?'
            acceptText='Delete'
            onAccept={onDelete}
        >
            {dialogProps => {
                const { handleOpen } = dialogProps;

                return <MenuItem onClick={handleOpen}>Delete</MenuItem>;
            }}
        </StyledDialog>
    );
};

export { DialogProjectDelete };

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { ProjectEntity } from '@modules/projects/entities';
import { AssessmentReportEntity } from '@modules/assessment/entities';
import { GetAssessmentReportQuery } from '@modules/assessment/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetAssessmentReportQueryType,
    GetAssessmentReportQueryVariables,
} from '@modules/types/graphql';

const useAsessmentReport = (
    options?: QueryHookOptions<GetAssessmentReportQueryType, GetAssessmentReportQueryVariables>,
) => {
    const queryResult = useQuery<GetAssessmentReportQueryType, GetAssessmentReportQueryVariables>(
        GetAssessmentReportQuery,
        options,
    );

    const { project, assessmentReport } = useEntity(() => {
        const project = new ProjectEntity(queryResult.data?.getAssignedProject ?? null);
        const assessmentReport = new AssessmentReportEntity(
            null, // queryResult.data?.getAssessmentReport
        );

        return {
            project,
            assessmentReport,
        };
    }, [queryResult.data]);

    const result = {
        project,
        assessmentReport,
        ...queryResult,
    };

    return result;
};

export { useAsessmentReport };

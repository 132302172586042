import { gql } from '@apollo/client';
import { LearnCardMenuFragment } from '../fragments';

export const CreateMenuCardMutation = gql`
    mutation CreateMenuCard($menuTitle: String!, $description: String, $imageUrl: String) {
        createMenuCard(menu_title: $menuTitle, description: $description, image_url: $imageUrl) {
            ...MenuCards
        }
    }
    ${LearnCardMenuFragment}
`;

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';

import type { QueryHookOptions } from '@apollo/client';
import type { GetProjectDetailsQueryType } from '@modules/types/graphql';
import { ProjectDetailQueryEntity } from '../entities';
import { GetProjectDetailsQuery } from '../graphql/queries';

const useProjectDetailsQuery = (options?: QueryHookOptions<GetProjectDetailsQueryType>) => {
    const queryResult = useQuery<GetProjectDetailsQueryType>(GetProjectDetailsQuery, {
        ...options,
    });

    const projectDetails = useEntity(
        () => new ProjectDetailQueryEntity(queryResult.data?.getProjectDetailsForQuery ?? null),
        [queryResult.data],
    );

    const result = {
        projectDetails,
        ...queryResult,
    };

    return result;
};

export { useProjectDetailsQuery };

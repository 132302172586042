import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { Modal } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { CapabilityLevelCreateRequest } from '@modules/custom-components/requests';
import { CapabilityLevelForm } from '@modules/custom-components/organisms';
import {
    GetCapabilityLevelsQuery,
    CreateCapabilityLevelMutation,
} from '@modules/custom-components/graphql';

import type { ModalProps } from '@modules/ui/core';
import type { CapabilityLevelCreateFormValues } from '@modules/custom-components/requests';
import type {
    CreateCapabilityLevelMutationType,
    CreateCapabilityLevelMutationVariables,
} from '@modules/types/graphql';

type CapabilityLevelFormModalProps = Omit<ModalProps, 'title' | 'children'> & {
    capabilityId: number;
};

const FormWrap = styled.div`
    width: 100%;
`;

const CapabilityLevelCreateModal = (props: CapabilityLevelFormModalProps) => {
    const { capabilityId, onClose, ...otherProps } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [createCapabilityLevel] = useMutation<
        CreateCapabilityLevelMutationType,
        CreateCapabilityLevelMutationVariables
    >(CreateCapabilityLevelMutation);

    const initialValues = {
        capabilityId,
        capabilityLevelDefinitionId: -1,
        description: '',
    };

    const handleSubmit = async (values: CapabilityLevelCreateFormValues) => {
        try {
            const variables = new CapabilityLevelCreateRequest(values);

            const { data: createCapabilityData } = await createCapabilityLevel({
                variables,
                refetchQueries: [{ query: GetCapabilityLevelsQuery, variables: { capabilityId } }],
                awaitRefetchQueries: true,
            });

            if (createCapabilityData?.createCapabilityLevel?.id) {
                enqueueSuccess('Capability level successfully created!');
                onClose();
            } else {
                enqueueError('An error occurred while creating capability level!');
            }
        } catch (e) {
            throw e;
        }
    };

    return (
        <Modal title='Create New Capability Level' onClose={onClose} {...otherProps}>
            <CapabilityLevelForm<'create'>
                type='create'
                initialValues={initialValues}
                FormWrapComponent={FormWrap}
                onSubmit={handleSubmit}
                onCancel={onClose}
            />
        </Modal>
    );
};

export { CapabilityLevelCreateModal };

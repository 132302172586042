import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import {
    getRiskLevelLabelByScore,
    getRiskAssessmentLevelLabel,
    getScoreMap,
    ratingNum,
} from '@modules/shared/constants/risk-level';
import { RatingPlateCursor } from '@modules/registry/organisms/rating-card/rating-plate-cursor';

const StyledRating = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0 20px;

    .is-rating-chart {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .is-rating-cursor {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        transform-origin: 0 46px;
        transition: transform 0.2s ease-in-out;
    }

    .is-rating-score {
        font-size: 16px;
        font-weight: bold;
        padding: 20px 0;
    }
`;

const rotateValue = (riskLabelKey: string, type: string) => {
    let levels = getScoreMap(type);
    let scoreList = Object.keys(levels);

    let levelScoreIndex = scoreList.findIndex((levelNum: string) => {
        return levels[levelNum] === riskLabelKey;
    });
    if (levelScoreIndex === -1) {
        return 0;
    }

    let degree: any;
    switch (levelScoreIndex) {
        case 0:
            degree = 80;
            break;
        case 1:
            degree = 100;
            break;
        case 2:
            degree = 121;
            break;
        case 3:
            degree = 143;
            break;
        case 4:
            degree = 168;
            break;
        case 5:
            degree = 190;
            break;
        case 6:
            degree = 215;
            break;
        case 7:
            degree = 235;
            break;
        case 8:
            degree = 255;
            break;
        case 9:
            degree = 280;
            break;
        default:
            degree = 80;
    }

    // let degree = -((180/(10-1)) * (levelScoreIndex) - 90);

    return degree - 180;

    // if (type === RiskProgramType.impact || type === RiskProgramType.risk) {
    //     return degree;
    // } else {
    //     return -degree;
    // }
};

export const RatingPlate: FC<{ score: string | number; type: string }> = ({ score, type }) => {
    let calcScore = ratingNum(score, type);
    let riskLabelKey = getRiskLevelLabelByScore(Number(score), type);
    // let riskLabel = getRiskAssessmentLevelLabel(riskLabelKey, type);
    let riskLabel;
    switch (type) {
        case 'risk':
            riskLabel = getRiskAssessmentLevelLabel(riskLabelKey, type);
            break;
        case 'impact':
            riskLabel = getRiskAssessmentLevelLabel(riskLabelKey, type);
            break;
        case 'vulnerability':
            // riskLabel = getRiskAssessmentRatingLabel(riskLabelKey);
            riskLabel = riskLabelKey;
            break;
        case 'threat':
            // riskLabel = getRiskAssessmentRatingLabel(riskLabelKey);
            riskLabel = riskLabelKey;
            break;
    }

    const transformStyles = useMemo(() => {
        return `rotate(${rotateValue(riskLabelKey, type)}deg) translateX(-50%)`;
    }, [riskLabelKey, type]);

    return (
        <StyledRating>
            <div className='is-rating-chart'>
                <svg
                    width='170'
                    height='85'
                    viewBox='0 0 170 85'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <g id='score'>
                        <g>
                            <path
                                d='M153.243 37.3883L143.309 44.6194C146.611 49.4646 149.293 54.705 151.291 60.2177L162.976 56.4259C160.578 49.682 157.305 43.2816 153.243 37.3883Z'
                                fill='#F33D3F'
                            />
                            <g>
                                <g mask='url(#mask0)'>
                                    <path
                                        id='excellent-back'
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M164.404 60.8008L152.721 64.591C154.359 70.2305 155.278 76.0548 155.454 81.9249H167.751C167.562 74.7681 166.437 67.6669 164.404 60.8023'
                                        fill='#F33D3F'
                                    />
                                </g>
                            </g>
                            <g>
                                <mask
                                    id='mask1'
                                    mask-type='alpha'
                                    maskUnits='userSpaceOnUse'
                                    x='2'
                                    y='60'
                                    width='16'
                                    height='22'
                                >
                                    <g id='d'>
                                        <path
                                            id='Vector_3'
                                            d='M2 60.8008H17.0288V81.9249H2V60.8008Z'
                                            fill='white'
                                        />
                                    </g>
                                </mask>
                                <g mask='url(#mask1)'>
                                    <path
                                        id='deficient-back'
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M5.34833 60.8008C3.3141 67.6656 2.1884 74.7675 2 81.9249H14.297C14.4733 76.0548 15.3917 70.2305 17.0303 64.591L5.34681 60.8023L5.34833 60.8008Z'
                                        fill='#2B884B' //Red
                                    />
                                </g>
                            </g>
                            <path
                                d='M135.432 18.5647L128.203 28.5125C132.838 32.1042 137 36.2676 140.591 40.9036L150.54 33.6724C149.995 32.964 149.438 32.2648 148.871 31.575C148.374 30.9715 147.87 30.3752 147.357 29.7863C143.765 25.6641 139.772 21.9041 135.432 18.5647Z'
                                fill='#F78402'
                            />
                            <g>
                                <mask
                                    id='mask2'
                                    mask-type='alpha'
                                    maskUnits='userSpaceOnUse'
                                    x='87'
                                    y='1'
                                    width='22'
                                    height='16'
                                >
                                    <g id='f'>
                                        <path
                                            d='M87.1782 1.35381H108.287V16.3856H87.1782V1.35381Z'
                                            fill='white'
                                        />
                                    </g>
                                </mask>
                                <g mask='url(#mask2)'>
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M87.1782 1.35381V13.6538C93.1642 13.836 98.968 14.773 104.495 16.3856L108.287 4.68544C101.425 2.66595 94.3291 1.54604 87.1782 1.35381Z'
                                        fill='#FCD500'
                                    />
                                </g>
                            </g>
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M57.0718 6.11285C50.3366 8.52192 43.942 11.7937 38.0479 15.8466L45.2654 25.7959C50.1145 22.4907 55.3602 19.8087 60.8788 17.813L57.0718 6.11285Z'
                                fill='#81B533'
                            />
                            <path
                                id='fair-back'
                                d='M61.4619 4.68544L65.2552 16.3856C70.8887 14.7454 76.7076 13.827 82.5724 13.6523V1.35381C75.421 1.54591 68.3249 2.66582 61.4619 4.68544Z'
                                fill='#FCD500'
                            />
                            <path
                                id='unfavorable-back'
                                d='M34.3187 18.5647C28.6507 22.9266 23.5719 28.0048 19.2095 33.6724L29.1588 40.9036C32.7503 36.2674 36.9137 32.104 41.5499 28.5125L34.3187 18.5647Z'
                                fill='#81B533'
                            />
                            <path
                                id='Vector_8'
                                d='M6.77441 56.4259L18.4579 60.2177C20.4579 54.7051 23.1408 49.4649 26.4438 44.6194L16.5081 37.3867C12.4456 43.2806 9.17314 49.6815 6.77441 56.4259Z'
                                fill='#2B884B' //Red
                            />
                            <path
                                id='good-back'
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M112.929 6.0511C119.673 8.4484 126.021 11.6933 131.834 15.6661L124.662 25.4934C119.871 22.2484 114.669 19.5949 109.146 17.6085L112.929 6.0511Z'
                                fill='#F78402'
                            />
                        </g>
                    </g>
                </svg>
                <RatingPlateCursor transformationStr={transformStyles} />
            </div>

            <div className='is-rating-score'>{riskLabel}</div>
        </StyledRating>
    );
};

import React from 'react';
import { useFormikContext } from 'formik';
import _differenceWith from 'lodash/differenceWith';

import { ArrayHelper } from '@helpers';
import { Loader } from '@modules/layout/moleculas';
import { Table } from '@modules/layout/organisms';
import { useCapabilities } from '@modules/custom-components/hooks';
import { CapabilitiesListFormGroupItem } from './capabilities-list-form-group-item';

import type { TableColumn } from '@modules/layout/organisms';
import type { AssessmentComponentChangesFormValues } from '@modules/assessment/requests';
import type { ProjectEntity } from '@modules/projects/entities';

type CapabilitiesListFormGroupProps = {
    project: ProjectEntity;
};

const columns: TableColumn[] = [
    {
        id: 'toggleCollapse',
        label: '',
        width: 70,
    },
    {
        id: 'name',
        label: 'Capability Name',
    },
    {
        id: 'keyFactors',
        label: 'Key factors',
    },
    {
        id: 'weight',
        label: 'Weight',
    },
];

const CapabilitiesListFormGroup = (
    props: CapabilitiesListFormGroupProps,
): React.ReactElement | null => {
    const { project } = props;

    const { values, setFieldValue } = useFormikContext<AssessmentComponentChangesFormValues>();

    const { capabilities, loading: capabilitiesLoading } = useCapabilities({
        skip: !values.component,
        variables: { componentId: values.component?.id as number, companyId: project.companyId },
    });

    const capabilitiesAssessmentInput = values.capabilities ?? [];
    const capabilitiesAssessmentInputLength = capabilitiesAssessmentInput.length;
    const componentCapabilitiesLength = capabilities.length;
    const hasComponentCapabilities = componentCapabilitiesLength !== 0;

    React.useEffect(() => {
        const predefinedCapablities = capabilities.filter(capability => capability.isPredefined());

        if (predefinedCapablities.length !== 0 && capabilitiesAssessmentInput.length === 0) {
            const capabilitiesInput = predefinedCapablities.map(capability => ({
                capability_id: capability.id,
                weight: 1,
            }));

            setFieldValue('capabilities', capabilitiesInput);
        }
    }, [capabilities]);

    if (capabilitiesLoading) {
        return <Loader />;
    }

    if (!hasComponentCapabilities) {
        return null;
    }

    const handleChangeCheckbox = (componentCapabilityId: number) => (
        event: React.ChangeEvent<{ name: string }>,
    ) => {
        const idx = capabilitiesAssessmentInput.findIndex(
            capabilityInput => capabilityInput?.capability_id === componentCapabilityId,
        );

        if (idx === -1) {
            setFieldValue(
                'capabilities',
                ArrayHelper.add(capabilitiesAssessmentInput, {
                    capability_id: componentCapabilityId,
                    weight: 1,
                }),
            );
        } else {
            setFieldValue('capabilities', ArrayHelper.remove(capabilitiesAssessmentInput, idx));
        }
    };

    const handleChangeCheckboxAll = (): void => {
        const isIndeterminate = capabilitiesAssessmentInputLength !== componentCapabilitiesLength;
        const isSelectedAll = capabilitiesAssessmentInputLength === componentCapabilitiesLength;

        if (isSelectedAll) {
            setFieldValue('capabilities', []);
            return;
        }

        if (isIndeterminate) {
            const diffCapabilities = _differenceWith(
                capabilities,
                capabilitiesAssessmentInput,
                (a, b) => a.id === b?.capability_id,
            )
                .map(capability =>
                    capability
                        ? {
                              capability_id: capability.id,
                              weight: 1,
                          }
                        : null,
                )
                .filter(Boolean);

            setFieldValue('capabilities', [...capabilitiesAssessmentInput, ...diffCapabilities]);
        }
    };

    const handleChangeWeight = (componentCapabilityId: number) => (
        event: React.ChangeEvent<{ name: string; value: string }>,
    ) => {
        const weight = Number(event.target.value);

        const idx = capabilitiesAssessmentInput.findIndex(
            capabilityInput => capabilityInput?.capability_id === componentCapabilityId,
        );

        const capabilityToUpdate = {
            weight,
            capability_id: componentCapabilityId,
        };

        if (idx === -1) {
            setFieldValue(
                'capabilities',
                ArrayHelper.add(capabilitiesAssessmentInput, capabilityToUpdate),
            );
        } else {
            setFieldValue(
                'capabilities',
                ArrayHelper.insert(capabilitiesAssessmentInput, capabilityToUpdate, idx),
            );
        }
    };

    return (
        <Table
            withSelectAll
            columns={columns}
            countSelected={capabilitiesAssessmentInputLength}
            countRows={componentCapabilitiesLength}
            onSelectAll={handleChangeCheckboxAll}
        >
            {capabilities.map(componentCapability => (
                <CapabilitiesListFormGroupItem
                    key={componentCapability.id}
                    capabilitiesAssessmentInput={capabilitiesAssessmentInput}
                    componentCapability={componentCapability}
                    onChangeCheckbox={handleChangeCheckbox(componentCapability.id)}
                    onChangeWeight={handleChangeWeight(componentCapability.id)}
                />
            ))}
        </Table>
    );
};

export { CapabilitiesListFormGroup };

import { gql } from '@apollo/client';

export const GetListRiskGroupQuery = gql`
    query listRiskGroup {
        listRiskGroup {
            id
            name
            risk_function_id
        }
    }
`;

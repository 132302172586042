import { gql } from '@apollo/client';

import { CapabilitySingleFragment } from '../fragments';

export const GetCapabilitiesQuery = gql`
    query GetCapabilities($componentId: Int, $search: String, $companyId: Int) {
        listCapabilities(component_id: $componentId, filter: $search, company_id: $companyId) {
            ...CapabilitySingle
        }
    }
    ${CapabilitySingleFragment}
`;

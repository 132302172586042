import { gql } from '@apollo/client';

import { UserFragment } from '@modules/users/graphql';
import { ComponentAssessmentFragment } from '@modules/assessment/graphql';
import { ComponentRecommendationFragment } from '@modules/roadmap/graphql';

export const GetAssignedProjectQuery = gql`
    query GetAssignedProject($id: Int!, $componentOwnerId: Int, $maturityLevelId: Int) {
        getAssignedProject(
            id: $id
            componentOwnerId: $componentOwnerId
            maturityLevelId: $maturityLevelId
        ) {
            id
            name
            description
            status
            company_id
            assessment_type_id
            is_risk_registry
            rr
            is_rr_impact
            roadmap_status
            maturity_level {
                id
                name
            }
            stakeholder {
                ...User
            }
            program_manager {
                ...User
            }
            assessments {
                ...ComponentAssessment
            }
            roadmap_status
            roadmap {
                project_id
                super_critical_recommendations {
                    ...ComponentRecommendation
                }
                critical_recommendations {
                    ...ComponentRecommendation
                }
                high_critical_recommendations {
                    ...ComponentRecommendation
                }
                high_recommendations {
                    ...ComponentRecommendation
                }
                medium_high_recommendations {
                    ...ComponentRecommendation
                }
                medium_recommendations {
                    ...ComponentRecommendation
                }
                low_medium_recommendations {
                    ...ComponentRecommendation
                }
                low_recommendations {
                    ...ComponentRecommendation
                }
                minimal_low_recommendations {
                    ...ComponentRecommendation
                }
                minimal_recommendations {
                    ...ComponentRecommendation
                }
            }
        }
    }
    ${UserFragment}
    ${ComponentAssessmentFragment}
    ${ComponentRecommendationFragment}
`;

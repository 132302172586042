import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { ComponentAssessmentEntityList } from '@modules/assessment/entities';
import { GetComponentAssessmentsQuery } from '@modules/assessment/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetComponentAssessmentsQueryType,
    GetComponentAssessmentsQueryVariables,
} from '@modules/types/graphql';

const useComponentAssessments = (
    options?: QueryHookOptions<
        GetComponentAssessmentsQueryType,
        GetComponentAssessmentsQueryVariables
    >,
) => {
    const queryResult = useQuery<
        GetComponentAssessmentsQueryType,
        GetComponentAssessmentsQueryVariables
    >(GetComponentAssessmentsQuery, options);

    const { entities: componentAssessments, count } = useEntity(
        () => new ComponentAssessmentEntityList(queryResult.data?.listComponentAssessments ?? null),
        [queryResult.data],
    );

    const result = {
        componentAssessments,
        count,
        ...queryResult,
    };

    return result;
};

export { useComponentAssessments };

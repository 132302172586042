import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { FormControlLabel, TextField } from '@material-ui/core';

import { Button, Modal } from '@modules/ui/core';
import { FormStyled } from '@modules/layout/styled';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { TableCellText, TableCheckbox } from '@modules/layout/atoms';
import { Loader, TableRow, TableCell } from '@modules/layout/moleculas';
import { ExtendedFormik, Form, Table } from '@modules/layout/organisms';
import { useGaps } from '@modules/custom-components/hooks';
import {
    GetCapabilityAssessmentQuery,
    SetCapabilityGapsMutation,
} from '@modules/assessment/graphql';

import type { ModalProps } from '@modules/ui/core';
import type { ScoredGapEntity } from '@modules/roadmap/entities';
import type {
    SetCapabilityGapsMutationType,
    SetCapabilityGapsMutationVariables,
} from '@modules/types/graphql';

import { GapUpdateRequest } from '@modules/custom-components/requests';
import { GetGapsQuery, UpdateGapMutation } from '@modules/custom-components/graphql';
import type { UpdateGapMutationType, UpdateGapMutationVariables } from '@modules/types/graphql';
import { ProjectEntity } from '@modules/projects/entities';

type AssessmentCapabilityGapsEditFormModalProps = Omit<ModalProps, 'title' | 'children'> & {
    capabilityLevelGaps: (ScoredGapEntity | null)[];
    capabilityAssessmentId: number;
    capabilityLevelId: number;
    project?: ProjectEntity;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const AssessmentCapabilityGapsEditFormModal = (
    props: AssessmentCapabilityGapsEditFormModalProps,
): React.ReactElement => {
    const {
        capabilityAssessmentId,
        capabilityLevelId,
        capabilityLevelGaps,
        project,
        onClose,
        ...otherProps
    } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { gaps, loading: gapsLoading } = useGaps({
        fetchPolicy: 'network-only',
        variables: {
            capabilityLevelId,
            companyId: project?.companyId as number,
            projectId: project?.id as number,
        },
    });

    const [gapData, setGapData] = useState<any>([]);
    useEffect(() => {
        setGapData([...gaps]);
    }, [gaps]);

    const [setCapabilityGaps] = useMutation<
        SetCapabilityGapsMutationType,
        SetCapabilityGapsMutationVariables
    >(SetCapabilityGapsMutation);

    const initialValues = {
        gaps: capabilityLevelGaps.map(scoredGap => scoredGap && scoredGap.gap.id).filter(Boolean),
    };

    const [updateGap] = useMutation<UpdateGapMutationType, UpdateGapMutationVariables>(
        UpdateGapMutation,
    );
    return (
        <Modal title='Edit Gaps' onClose={onClose} {...otherProps}>
            {gapsLoading ? (
                <Loader />
            ) : (
                <ExtendedFormik
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={yup.object({
                        gaps: yup.array().min(1, 'Required field'),
                    })}
                    initialValues={initialValues}
                    onSubmit={async values => {
                        try {
                            gapData.forEach(async function (items: any) {
                                items.gapId = items.id;
                                const variables = new GapUpdateRequest(items);
                                const { data: updateGapData } = await updateGap({
                                    variables,
                                    refetchQueries: [
                                        { query: GetGapsQuery, variables: { capabilityLevelId } },
                                    ],
                                    awaitRefetchQueries: true,
                                });
                            });
                            const { data: setCapabilityGapsData } = await setCapabilityGaps({
                                variables: {
                                    gaps: values.gaps as number[],
                                    surveyId: capabilityAssessmentId,
                                    projectId: project?.id as number,
                                },
                                refetchQueries: [
                                    {
                                        query: GetCapabilityAssessmentQuery,
                                        variables: { surveyId: capabilityAssessmentId },
                                    },
                                ],
                                awaitRefetchQueries: true,
                            });
                            if (setCapabilityGapsData?.setGaps?.length !== 0) {
                                enqueueSuccess('Capability gaps successfully saved!');
                                onClose();
                            } else {
                                enqueueError('An error occurred while saving capability gaps!');
                            }
                        } catch (e) {
                            console.log(e);
                            throw e;
                        }
                    }}
                >
                    {formikProps => {
                        const { values, setFieldValue, isSubmitting } = formikProps;

                        const handleCheckGap = (gapId: number) => (_: React.ChangeEvent<{}>) => {
                            const gapsToUpdate = [...values.gaps];
                            const idx = gapsToUpdate.findIndex(gap => gap === gapId);

                            if (idx > -1) {
                                gapsToUpdate.splice(idx, 1);
                            } else {
                                gapsToUpdate.push(gapId);
                            }
                            setFieldValue('gaps', gapsToUpdate);
                        };

                        const handleGapText = (e: any, GapId: number) => {
                            let newGapData = gaps.map((ele: any) => {
                                if (ele.id === GapId) {
                                    ele.gap = e.target.value;
                                }
                                return ele;
                            });
                            setGapData([...newGapData]);
                        };

                        return (
                            <StyledForm>
                                <Table>
                                    {gapData?.map((gap: any) => {
                                        return (
                                            gap && (
                                                <TableRow key={gap.id}>
                                                    <TableCell>
                                                        <FormControlLabel
                                                            control={
                                                                <TableCheckbox
                                                                    checked={values.gaps.includes(
                                                                        gap.id,
                                                                    )}
                                                                    onChange={handleCheckGap(
                                                                        gap.id,
                                                                    )}
                                                                />
                                                            }
                                                            label={
                                                                <TableCellText>
                                                                    <TextField
                                                                        multiline
                                                                        fullWidth
                                                                        name='gapText'
                                                                        value={gap.gap}
                                                                        onChange={e =>
                                                                            handleGapText(e, gap.id)
                                                                        }
                                                                        style={{
                                                                            minWidth: '400px',
                                                                        }}
                                                                    />
                                                                </TableCellText>
                                                            }
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        );
                                    })}
                                </Table>

                                <FormStyled.FormRowSubmit>
                                    <SubmitButton type='submit' loading={isSubmitting}>
                                        Save
                                    </SubmitButton>

                                    <SubmitButton
                                        variant='text'
                                        disabled={isSubmitting}
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </SubmitButton>
                                </FormStyled.FormRowSubmit>
                            </StyledForm>
                        );
                    }}
                </ExtendedFormik>
            )}
        </Modal>
    );
};

export { AssessmentCapabilityGapsEditFormModal };

import { gql } from '@apollo/client';

import { UserFragment } from '@modules/users/graphql';
import { ImplementationFragment } from '@modules/implementation/graphql';
import { ComponentAssessmentFragment } from '@modules/assessment/graphql';

export const GetProjectImplementationQuery = gql`
    query GetProjectImplementation($id: Int!) {
        getProject(id: $id) {
            id
            name
            description
            status
            company_id
            maturity_level {
                id
                name
            }
            stakeholder {
                ...User
            }
            implementation {
                ...Implementation
            }
            assessments {
                ...ComponentAssessment
            }
        }
    }
    ${UserFragment}
    ${ImplementationFragment}
    ${ComponentAssessmentFragment}
`;

import React from 'react';
import { FormControlLabel } from '@material-ui/core';
import { TableCheckbox, TableCellText } from '@modules/layout/atoms';
import { TableRow, TableCell } from '@modules/layout/organisms';

import type { CapabilityInput } from '@modules/types/graphql';

type CapabilitiesListFormGroupItemProps = {
    capabilitiesAssessmentInput: (CapabilityInput | null)[];
    componentCapability: any;
    onChangeCheckbox: (event: React.ChangeEvent<{ name: string; value: string }>) => void;
    onChangeWeight: (event: React.ChangeEvent<{ name: string; value: string }>) => void;
};

const AssignCapabilitiesListFormGroupItem = (
    props: CapabilitiesListFormGroupItemProps,
): React.ReactElement => {
    const { capabilitiesAssessmentInput, componentCapability, onChangeCheckbox } = props;

    const [open, setOpen] = React.useState(false);

    const handleToggleOpen = (): void => setOpen(state => !state);

    const foundCapabilityAssessmentInput =
        capabilitiesAssessmentInput.find(
            capabilityAssessmentInput =>
                componentCapability.id === capabilityAssessmentInput?.capability_id,
        ) ?? null;

    return (
        <>
            <TableRow>
                <TableCell width={'50%'}>
                    <FormControlLabel
                        control={
                            <TableCheckbox
                                name='id'
                                checked={!!foundCapabilityAssessmentInput}
                                onChange={onChangeCheckbox}
                            />
                        }
                        label={
                            <TableCellText>{componentCapability?.capability.title}</TableCellText>
                        }
                    />
                </TableCell>

                <TableCell>
                    <TableCellText>{componentCapability?.capability.keyFactors}</TableCellText>
                </TableCell>
            </TableRow>
        </>
    );
};

export { AssignCapabilitiesListFormGroupItem };

import React from 'react';
import styled from 'styled-components';
import { useNavigate } from '@reach/router';

import { Button } from '@modules/ui/core';
import { routes } from '@config/routes';

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    width: 100%;
    align-items: center;
`;

const Actions = styled.div`
    width: 100%;
`;

// const Filters = styled.div`
//     margin-left: auto;
// `;

const ButtonCreateLink = styled(Button)`
    min-width: 205px;
`;

const LearnTkoListToolbar = (): React.ReactElement => {
    const navigate = useNavigate();

    const handleClickRedirect = (): void => {
        navigate(routes.createLearnTko.path);
    };

    return (
        <Root>
            <Actions>
                <ButtonCreateLink onClick={handleClickRedirect}>Create Learn TKO</ButtonCreateLink>
            </Actions>

            {/* <Filters>
                <UsersListFilters />
            </Filters> */}
        </Root>
    );
};

export { LearnTkoListToolbar };

import { gql } from '@apollo/client';

import { CapabilitySingleFragment } from './capability-single';

export const ComponentSingleFragment = gql`
    fragment ComponentSingle on Component {
        id
        name
        capabilities {
            ...CapabilitySingle
        }
    }
    ${CapabilitySingleFragment}
`;

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Form } from 'formik';
import { useNavigate } from '@reach/router';
import { TextField, Button } from '@modules/ui/core';
import { ExtendedFormik } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';
import { InputAdornment } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { FormLabel } from '@material-ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const FormLabelStyle = styled(FormLabel)`
    display: flex;
    align-items: center;
    font-size: 32px;
    justify-content: center;
    border: 1px solid #e1e1e1;
    border-radius: 70px;
    margin-bottom: 20px;
`;
const CloudUploadIconStyle = styled(CloudUploadIcon)`
    font-size: 62px;
    color: #0057ff;
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
    margin-top: 20px;
`;
type InitialValueType = {
    menuTitle: string;
    description: string;
    imageUrl: string;
};

type LearnTkoFormProps = {
    type: string;
    initialValues: InitialValueType;
    FormWrapComponent: any;
    onSubmit: (values: any) => void;
    onClose?: () => void;
};

const LearnTkoMenuForm = (props: LearnTkoFormProps): React.ReactElement => {
    const { type, initialValues, onClose, FormWrapComponent, onSubmit } = props;
    const navigate = useNavigate();
    // const [video_File, setVideo_File] = useState(null);
    const { enqueueError } = useEnqueueStacks();
    const isEdit = type === 'edit';
    const handleClose = () => {
        navigate?.('/resources/view-learn-menu-cards');
        onClose?.();
    };

    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={() => {
                let rules: Record<string, any> = {};

                if (!isEdit) {
                    rules = {
                        ...rules,
                        menuTitle: yup
                            .string()
                            .max(30, 'Menu title length must 30 character long')
                            .min(8, 'Menu title length must be 8 character long')
                            .nullable()
                            .required('Required field'),
                        description: yup.string().required('Required field'),
                        // imageUrl: yup.string().required('Required field'),
                    };
                }

                return yup.object(rules);
            }}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {formikProps => {
                const {
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    isSubmitting,
                } = formikProps;
                // const handleFileChange = (e: any) => {
                //     const video_File = e.target.files[0];
                //     setFieldValue('video_File', video_File);
                //     // setVideo_File(video_File);
                // };

                const handleFileChange = (e: any) => {
                    // Uploaded file
                    const video_File = e.target.files[0];
                    if (video_File) {
                        const fileExtension = video_File.name.split('.').pop().toLowerCase();
                        const allowedExtensions = ['.jpg', '.jpeg', '.bmp', '.gif', '.png'];
                        if (allowedExtensions.includes('.' + fileExtension)) {
                            setFieldValue('video_File', video_File);
                        } else {
                            enqueueError('Invalid file type. Please select a valid image file.');
                        }
                    }
                };

                return (
                    <FormWrapComponent>
                        <StyledForm>
                            <TextField
                                fullWidth
                                id='menuTitle'
                                type='text'
                                label='Menu Title'
                                placeholder='Enter your Menu Title'
                                value={values?.menuTitle}
                                error={!!errors.menuTitle}
                                helperText={errors.menuTitle}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <TextField
                                fullWidth
                                id='description'
                                type='text'
                                label='Description'
                                placeholder='Enter your Description'
                                value={values?.description}
                                error={!!errors.description}
                                helperText={errors.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormLabelStyle htmlFor='imageUrl'>
                                <CloudUploadIconStyle />
                                Image Upload
                            </FormLabelStyle>
                            <TextField
                                fullWidth
                                id='imageUrl'
                                type='file'
                                // label='Image'
                                style={{ display: 'none' }}
                                placeholder='Select your Image'
                                // value={values.imageUrl}
                                // error={!!errors.imageUrl}
                                helperText={errors.imageUrl}
                                onChange={handleFileChange}
                                onBlur={handleBlur}
                            />

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    {!isEdit ? 'Save' : 'Update'}
                                </SubmitButton>

                                <SubmitButton variant='text' onClick={handleClose}>
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    </FormWrapComponent>
                );
            }}
        </ExtendedFormik>
    );
};

export { LearnTkoMenuForm };

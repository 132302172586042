import type { CreateRecommendationMutationVariables } from '@modules/types/graphql';

export type RecommendationCreateFormValues = {
    recommendation: string;
};

export type RecommendationCreateFormPayload = {
    gapId: number;
    companyId?: number;
};

class RecommendationCreateRequest {
    gapId: CreateRecommendationMutationVariables['gapId'];
    recommendation: CreateRecommendationMutationVariables['recommendation'];
    companyId: CreateRecommendationMutationVariables['companyId'];

    constructor(values: RecommendationCreateFormValues, payload: RecommendationCreateFormPayload) {
        this.recommendation = values.recommendation;
        this.gapId = payload.gapId;
        this.companyId = payload.companyId;
    }
}

export { RecommendationCreateRequest };

import { EntityList } from '@lib/entity';
import { AssessmentTypeEntity } from './assessment-type-entity';

import type { Entity } from './assessment-type-entity';

class AssessmentTypeEntityList extends EntityList<AssessmentTypeEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(AssessmentTypeEntity, items, count);
    }
}

export { AssessmentTypeEntityList };

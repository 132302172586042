import React, { useMemo } from 'react';
import { format } from 'date-fns';
import styled, { css } from 'styled-components';

import { Table, TableHead, TableBody, TableCell, TableRow, Paper, TableContainer } from '@material-ui/core';
import { GapRecommendationCountEntity } from '@modules/custom-components/entities';
import { Loader } from '@modules/layout/moleculas';
import { Check, Close } from '@material-ui/icons';


type AllProjectDetailsProps = {
    allCount: GapRecommendationCountEntity;
    gapLoading: any;
};

export const AllProjectDetails = (props: AllProjectDetailsProps): React.ReactElement => {
    const { allCount, gapLoading } = props;

    const projectDetails = useMemo<GapRecommendationCountEntity | {}>(() => {
        const companyDetails: GapRecommendationCountEntity = allCount;
        if (companyDetails?.companyId === -1)
            return [];

        return companyDetails;
    }, [allCount]);

    const isProjectDetails = (details: any): details is GapRecommendationCountEntity => {
        return (details as GapRecommendationCountEntity).companyId !== undefined;
    };

    if (gapLoading) {
        return <Loader />;
    }
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Ai Access</TableCell>
                        <TableCell>Projects Count</TableCell>
                        <TableCell>Remaining Projects</TableCell>
                        <TableCell>Manual Gaps Count</TableCell>
                        <TableCell>Ai Gaps Count</TableCell>
                        <TableCell>Manual Recomm... Count</TableCell>
                        <TableCell>Ai Recomm... Count</TableCell>
                        <TableCell>Manual Tasks Count</TableCell>
                        <TableCell>Ai Tasks Count</TableCell>
                        <TableCell>Manual SubTasks Count</TableCell>
                        <TableCell>Ai SubTasks Count</TableCell>
                        <TableCell>Ask TKO Access</TableCell>
                        <TableCell>Learn TKO Access</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            {isProjectDetails(projectDetails) ? projectDetails.companyName : 'N/A'}
                        </TableCell>
                        <TableCell>
                            {isProjectDetails(projectDetails) ? ( projectDetails.aiAccess ? <Check style={{ color: 'green', fontSize: '22px' }} /> : <Close style={{ fontSize: '22px', color: 'crimson' }} /> ) : 'N/A'}
                        </TableCell>
                        <TableCell>
                            {isProjectDetails(projectDetails) ? projectDetails.projectsCount : 'N/A'}
                        </TableCell>
                        <TableCell>
                            {isProjectDetails(projectDetails) ? projectDetails.remainingProjects : 'N/A'}
                        </TableCell>
                        <TableCell>
                            {isProjectDetails(projectDetails) ? projectDetails.gapsCount : 'N/A'}
                        </TableCell>
                        <TableCell>
                            {isProjectDetails(projectDetails) ? projectDetails.aiGapsCount : 'N/A'}
                        </TableCell>
                        <TableCell>
                            {isProjectDetails(projectDetails) ? projectDetails.recommendationsCount : 'N/A'}
                        </TableCell>
                        <TableCell>
                            {isProjectDetails(projectDetails) ? projectDetails.aiRecommendationsCount : 'N/A'}
                        </TableCell>
                        <TableCell>
                            {isProjectDetails(projectDetails) ? projectDetails.tasksCount : 'N/A'}
                        </TableCell>
                        <TableCell>
                            {isProjectDetails(projectDetails) ? projectDetails.aiTasksCount : 'N/A'}
                        </TableCell>
                        <TableCell>
                            {isProjectDetails(projectDetails) ? projectDetails.subTasksCount : 'N/A'}
                        </TableCell>
                        <TableCell>
                            {isProjectDetails(projectDetails) ? projectDetails.aiSubTasksCount : 'N/A'}
                        </TableCell>
                        <TableCell>
                            {isProjectDetails(projectDetails) ? ( projectDetails.isAskTko ? <Check style={{ color: 'green', fontSize: '22px' }} /> : <Close style={{ fontSize: '22px', color: 'crimson' }} /> ) : 'N/A'}
                        </TableCell>
                        <TableCell>
                            {isProjectDetails(projectDetails) ? ( projectDetails.isLearnTko ? <Check style={{ color: 'green', fontSize: '22px' }} /> : <Close style={{ fontSize: '22px', color: 'crimson' }} /> ) : 'N/A'}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};


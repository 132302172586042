import { QueryHookOptions, useQuery } from '@apollo/client';
import { useEntity } from '@lib/entity';
import { GetMenuCardQueryType, GetMenuCardQueryVariables } from '@modules/types/graphql';
import { AllMenuCardsEntity } from '../entities';
import { GetSingleMenuCardQuery } from '../graphql';

const useMenuCard = (
    options?: QueryHookOptions<GetMenuCardQueryType, GetMenuCardQueryVariables>,
) => {
    const queryResult = useQuery<GetMenuCardQueryType, GetMenuCardQueryVariables>(
        GetSingleMenuCardQuery,
        {
            fetchPolicy: 'network-only',
            ...options,
        },
    );

    const menuCard = useEntity(
        () => new AllMenuCardsEntity(queryResult.data?.getMenuCard ?? null),
        [queryResult.data],
    );

    const result = {
        menuCard,
        ...queryResult,
    };
    return result;
};

export { useMenuCard };

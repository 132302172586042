import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { useEnqueueStacks } from '@modules/layout/hooks';
import { RecommendationUpdateRequest } from '@modules/custom-components/requests';
import { RecommendationForm } from '@modules/custom-components/organisms';
import {
    GetRecommendationsQuery,
    UpdateRecommendationMutation,
} from '@modules/custom-components/graphql';

import type { RecommendationEntity } from '@modules/custom-components/entities';
import type { RecommendationUpdateFormValues } from '@modules/custom-components/requests';
import type {
    UpdateRecommendationMutationType,
    UpdateRecommendationMutationVariables,
} from '@modules/types/graphql';

type EditRecommendationTabProps = {
    recommendation: RecommendationEntity;
    onCancel?: () => void;
};

const FormWrap = styled.div`
    width: 100%;
`;

const EditRecommendationTab = (props: EditRecommendationTabProps) => {
    const { recommendation, onCancel } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [updateRecommendation] = useMutation<
        UpdateRecommendationMutationType,
        UpdateRecommendationMutationVariables
    >(UpdateRecommendationMutation);

    const initialValues = {
        recommendationId: recommendation.id,
        recommendation: recommendation.recommendation,
    };

    const handleUpdateRecommendationSubmit = async (values: RecommendationUpdateFormValues) => {
        try {
            const variables = new RecommendationUpdateRequest(values, {
                gapId: recommendation.gapId,
            });

            const { data: updateRecommendationData } = await updateRecommendation({
                variables,
                refetchQueries: [
                    { query: GetRecommendationsQuery, variables: { gapId: recommendation.gapId } },
                ],
                awaitRefetchQueries: true,
            });

            const recommendationId = updateRecommendationData?.updateRecommendation?.id;

            if (recommendationId) {
                enqueueSuccess('Recommendation successfully updated!');
            } else {
                enqueueError('An error occurred while updating recommendation!');
            }
        } catch (e) {
            throw e;
        }
    };

    return (
        <RecommendationForm<'edit'>
            type='edit'
            initialValues={initialValues}
            onSubmit={handleUpdateRecommendationSubmit}
            FormWrapComponent={FormWrap}
            onCancel={onCancel}
        />
    );
};

export { EditRecommendationTab };

import { EntityList } from '@lib/entity';
import { WorkForceEntity } from './workforce-entity';

class WorkForceEntityList extends EntityList<WorkForceEntity> {
    constructor(items: any[] | null, count?: number | null) {
        super(WorkForceEntity, items, count);
    }
}

export { WorkForceEntityList };

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button } from '@modules/ui/core';
import { useAssignedProject } from '@modules/projects/hooks';
import { GapCreateModal } from '@modules/custom-components/organisms';
import { AssessmentCapabilityGapsEditFormModal } from './assessment-capability-gaps-edit-form-modal';

import type { ProjectEntity } from '@modules/projects/entities';
import type { ComponentAssessmentEntity } from '@modules/assessment/entities';
import type { ScoredGapEntity } from '@modules/roadmap/entities';

type AssessmentCapabilityGapsListProps = {
    canEdit: boolean;
    capabilityAssessmentId: number;
    capabilityLevelId: number;
    project: ProjectEntity;
    componentAssessment: ComponentAssessmentEntity;
    capabilityLevelGaps: (ScoredGapEntity | null)[];
    className?: string;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 2.2rem;
`;

const List = styled.div`
    width: 100%;
    margin: 16px 0 24px 0;

    > * {
        margin-bottom: 8px;
        border-bottom: 1px solid #c4c4c4;

        &:first-child {
            border-top: 1px solid #c4c4c4;
        }
    }
`;

const GapItem = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 8px;
    font-size: 1.4rem;
`;

const EmptyGaps = styled(GapItem)`
    height: initial;
    padding: 0 4px;
    font-size: 1.6rem;
    font-weight: 700;

    && {
        border: 0;
    }
`;

const Actions = styled.div`
    width: 100%;

    > *:not(:last-child) {
        margin-right: 16px;
    }
`;

const AssessmentCapabilityGapsList = (
    props: AssessmentCapabilityGapsListProps,
): React.ReactElement | null => {
    const {
        canEdit,
        capabilityAssessmentId,
        capabilityLevelId,
        project,
        componentAssessment,
        capabilityLevelGaps,
        ...otherProps
    } = props;

    const { refetch: refetchAssignedProject } = useAssignedProject({
        skip: !project.id,
        variables: { id: project.id },
    });

    const handleGapAddingSuccess = async (): Promise<void> => {
        await refetchAssignedProject();
    };

    const [openGapEditFormModal, setOpenGapEditFormModal] = React.useState<boolean>(false);

    const handleOpenGapEditFormModal = (): void => setOpenGapEditFormModal(true);
    const handleCloseGapEditFormModal = (): void => setOpenGapEditFormModal(false);

    const [openGapCreateModal, setOpenGapCreateModal] = React.useState<boolean>(false);

    const handleOpenGapCreateModal = (): void => setOpenGapCreateModal(true);
    const handleCloseGapCreateModal = (): void => setOpenGapCreateModal(false);

    return (
        <Root {...otherProps}>
            <Title>Gaps</Title>

            <List>
                {capabilityLevelGaps.length > 0 ? (
                    (capabilityLevelGaps as ScoredGapEntity[]).map((scoredGap, index) => (
                        <GapItem key={scoredGap.id}>
                            {index + 1}- {scoredGap.gap.gap}
                        </GapItem>
                    ))
                ) : (
                    <EmptyGaps>Not found gaps for the capability</EmptyGaps>
                )}
            </List>

            {canEdit ? (
                <>
                    <Actions>
                        <Button size='small' onClick={handleOpenGapEditFormModal}>
                            Edit Gaps
                        </Button>

                        <Button size='small' variant='outlined' onClick={handleOpenGapCreateModal}>
                            + Add Gap
                        </Button>
                    </Actions>

                    <AssessmentCapabilityGapsEditFormModal
                        capabilityLevelGaps={capabilityLevelGaps}
                        project={project}
                        capabilityAssessmentId={capabilityAssessmentId}
                        capabilityLevelId={capabilityLevelId}
                        open={openGapEditFormModal}
                        onClose={handleCloseGapEditFormModal}
                    />

                    <GapCreateModal
                        project={project}
                        capabilityAssessmentId={capabilityAssessmentId}
                        capabilityLevelId={capabilityLevelId}
                        open={openGapCreateModal}
                        onClose={handleCloseGapCreateModal}
                        onSuccess={handleGapAddingSuccess}
                    />
                </>
            ) : null}
        </Root>
    );
};

export { AssessmentCapabilityGapsList };

import { EntityList } from '@lib/entity';
import type { GapRecommendationCountEntityType } from './gap-recommendation-count-entity';
import { GapRecommendationCountEntity } from './gap-recommendation-count-entity';

class GapRecommendationCountEntityList extends EntityList<GapRecommendationCountEntity> {
    constructor(items: GapRecommendationCountEntityType[] | null, count?: number | null) {
        super(GapRecommendationCountEntity, items, count);
    }
}

export { GapRecommendationCountEntityList };

import { EntityList } from '@lib/entity';
import { MaturityLevelEntity } from '@modules/shared/entities/maturity-level-entity';

class MaturityLevelsEntityList extends EntityList<MaturityLevelEntity> {
    constructor(items: any[] | null, count?: number | null) {
        super(MaturityLevelEntity, items, count);
    }
}

export { MaturityLevelsEntityList };

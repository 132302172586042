import gql from 'graphql-tag';

export const AddUsageTimeMutation = gql`
    mutation AddUsageTime($userId: Int!, $dailyUsage: Int!) {
        addUsageTime(user_id: $userId, daily_usage: $dailyUsage) {
            user_id
            daily_usage
        }
    }
`;

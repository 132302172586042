import React, { useMemo } from 'react';

import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { CapabilityAssessmentLog } from '@modules/types/graphql';
import { format } from 'date-fns';
import { ProjectEntity } from '@modules/projects/entities';

type ReportCapabilityLogProps = {
    history: CapabilityAssessmentLog[] | any;
    projectStatus?: boolean;
};

export const ReportCapabilityLog = (props: ReportCapabilityLogProps): React.ReactElement => {
    const { history, projectStatus } = props;
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>{!projectStatus ? 'Gaps' : 'Evidence'}</TableCell>
                        <TableCell>{!projectStatus ? 'Recommendations' : 'Assumptions'}</TableCell>
                        <TableCell>{!projectStatus ? 'Notes' : 'Unwanted Events'}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {history.map((log: any, index: any) => (
                        <TableRow key={index}>
                            <TableCell>
                                {format(new Date(log.action_timestamp), 'dd.MM.yyyy hh:mm')}
                            </TableCell>
                            <TableCell>
                                {log.user?.first_name} {log.user?.last_name}
                            </TableCell>
                            <TableCell>{log.gaps}</TableCell>
                            <TableCell>{log.recommendations}</TableCell>
                            <TableCell>{log.notes}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

import gql from 'graphql-tag';

export const UpdateLearnTkoCaptionMutation = gql`
    mutation updateLearnTkoCaption($id: Int!, $caption: String) {
        updateLearnTkoCaption(id: $id, caption: $caption) {
            id
            caption
        }
    }
`;

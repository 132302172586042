import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import {
    withStyles,
    makeStyles,
    Stepper,
    Step,
    StepLabel,
    StepIcon as MuiStepIcon,
} from '@material-ui/core';

import { useEnqueueStacks } from '@modules/layout/hooks';
import {
    RecommendationCreateRequest,
    RequirementCreateRequest,
} from '@modules/custom-components/requests';
import {
    RecommendationForm,
    RequirementForm,
    TasksList,
} from '@modules/custom-components/organisms';
import {
    CreateRecommendationMutation,
    CreateRequirementMutation,
    GetRecommendationsQuery,
} from '@modules/custom-components/graphql';

import type {
    RecommendationCreateFormValues,
    RequirementCreateFormValues,
} from '@modules/custom-components/requests';
import type {
    CreateRecommendationMutationType,
    CreateRecommendationMutationVariables,
    CreateRequirementMutationType,
    CreateRequirementMutationVariables,
} from '@modules/types/graphql';

type CreateRecommendationStepperProps = {
    gapId: number;
    onCancel?: () => void;
};

const StepIcon = withStyles({
    root: {
        fontSize: '1.8rem',
    },
    text: {
        paddingTop: 1,
        fontSize: '1.4rem',
    },
})(MuiStepIcon);

const useStepperStyles = makeStyles({
    root: {
        padding: 0,
    },
});

const useStepLabelStyles = makeStyles({
    label: {
        fontSize: '1.2rem',
    },
});

const Root = styled.div`
    width: 100%;
`;

const Main = styled.div`
    width: 100%;
    margin-top: 32px;
`;

const FormWrap = styled.div``;

const steps = ['Add Recommendation', 'Add Requirements', 'Add Tasks'];

const CreateRecommendationStepper = (
    props: CreateRecommendationStepperProps,
): React.ReactElement => {
    const { gapId, onCancel } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const stepperClasses = useStepperStyles();
    const stepLabelClasses = useStepLabelStyles();

    const [createdRecommendationId, setCreatedRecommendationId] = React.useState<number | null>(
        null,
    );

    const [createdRequirementId, setCreatedRequirementId] = React.useState<number | null>(null);

    const [createRecommendation] = useMutation<
        CreateRecommendationMutationType,
        CreateRecommendationMutationVariables
    >(CreateRecommendationMutation);

    const [createRequirement] = useMutation<
        CreateRequirementMutationType,
        CreateRequirementMutationVariables
    >(CreateRequirementMutation);

    const [currentStep, setCurrentStep] = React.useState<number>(0);

    const recommendationInitialValues = {
        recommendation: '',
    };

    const handleCreateRecommendationSubmit = async (values: RecommendationCreateFormValues) => {
        try {
            const variables = new RecommendationCreateRequest(values, { gapId });

            const { data: createRecommendationData } = await createRecommendation({
                variables,
                refetchQueries: [{ query: GetRecommendationsQuery, variables: { gapId } }],
                awaitRefetchQueries: true,
            });

            const recommendationId = createRecommendationData?.createRecommendation?.id;

            if (recommendationId) {
                enqueueSuccess('Recommendation successfully created!');

                setCreatedRecommendationId(recommendationId);
                if (onCancel) {
                    onCancel();
                }
                // setCurrentStep(step => step + 1);
            } else {
                enqueueError('An error occurred while creating recommendation!');
            }
        } catch (e) {
            throw e;
        }
    };

    const requirementInitialValues = {
        recommendationId: createdRecommendationId,
        requirement: '',
    };

    const handleCreateRequirementSubmit = async (values: RequirementCreateFormValues) => {
        if (!createdRecommendationId) {
            return;
        }

        try {
            const variables = new RequirementCreateRequest(values, {
                recommendationId: createdRecommendationId,
            });

            const { data: createRequirementData } = await createRequirement({
                variables,
            });

            const requirementId = createRequirementData?.createRequirement?.id;

            if (requirementId) {
                enqueueSuccess('Requirement successfully created!');

                setCreatedRequirementId(requirementId);
                setCurrentStep(step => step + 1);
            } else {
                enqueueError('An error occurred while creating requirement!');
            }
        } catch (e) {
            throw e;
        }
    };

    return (
        <Root>
            <Main>
                <RecommendationForm<'create'>
                    type='create'
                    initialValues={recommendationInitialValues}
                    onSubmit={handleCreateRecommendationSubmit}
                    FormWrapComponent={FormWrap}
                    onCancel={onCancel}
                />
            </Main>
        </Root>
    );

    // TODO: hide while not aprroved this func
    // return (
    //     <Root>
    //         <Stepper classes={stepperClasses} activeStep={currentStep}>
    //             {steps.map((label, index) => (
    //                 <Step key={index} disabled={index !== 0}>
    //                     <StepLabel classes={stepLabelClasses} StepIconComponent={StepIcon}>
    //                         {label}
    //                     </StepLabel>
    //                 </Step>
    //             ))}
    //         </Stepper>

    //         <Main>
    //             {currentStep === 0 ? (
    //                 <RecommendationForm<'create'>
    //                     type='create'
    //                     initialValues={recommendationInitialValues}
    //                     onSubmit={handleCreateRecommendationSubmit}
    //                     FormWrapComponent={FormWrap}
    //                     onCancel={onCancel}
    //                 />
    //             ) : null}

    //             {currentStep === 1 ? (
    //                 <RequirementForm<'create'>
    //                     type='create'
    //                     initialValues={requirementInitialValues}
    //                     onSubmit={handleCreateRequirementSubmit}
    //                     FormWrapComponent={FormWrap}
    //                     onCancel={onCancel}
    //                 />
    //             ) : null}

    //             {currentStep === 2 && createdRequirementId ? (
    //                 <TasksList tasks={[]} requirementId={createdRequirementId} />
    //             ) : null}
    //         </Main>
    //     </Root>
    // );
};

export { CreateRecommendationStepper };

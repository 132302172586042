import { gql } from '@apollo/client';

import { CapabilitySingleFragment } from '../fragments';

export const CreateCapabilityMutation = gql`
    mutation CreateCapability(
        $componentId: Int!
        $title: String!
        $impact: String
        $keyFactors: String
        $securityControls: String
        $companyId: Int
    ) {
        createCapability(
            component_id: $componentId
            title: $title
            impact: $impact
            key_factors: $keyFactors
            security_controls: $securityControls
            company_id: $companyId
        ) {
            ...CapabilitySingle
        }
    }
    ${CapabilitySingleFragment}
`;

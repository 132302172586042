import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { Maybe, AssessmentType } from '@modules/types/graphql';

export type Entity = Maybe<Partial<AssessmentType>>;

@DecribeEntity('AssessmentTypeEntity')
class AssessmentTypeEntity extends BaseEntity {
    id: number;
    name: string;
    isRRType: boolean;
    companyId: number;
    assessmentCategoriesId: number;

    constructor(assessmentType: Entity) {
        super(assessmentType);

        this.id = assessmentType?.id ?? -1;
        this.name = assessmentType?.name ?? '';
        this.companyId = assessmentType?.company_id ?? -1;
        this.isRRType = assessmentType?.is_rr_type ?? false;
        this.assessmentCategoriesId = assessmentType?.assessment_categories_id ?? -1;
    }
}

export { AssessmentTypeEntity };

import gql from 'graphql-tag';

export const LearnTkoDetailsFragment = gql`
    fragment LearnTkoDetails on LearnTkoDetails {
        id
        menu
        title
        description
        video_url
        created_at
    }
`;

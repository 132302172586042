import React from 'react';
import styled from 'styled-components';

import { RoundedProgressBar } from '@modules/projects/moleculas';

type ProjectListStatusProps = {
    status: string;
    progress?: number;
};

const Root = styled.div`
    display: flex;
    align-items: center;
`;

const ProjectListStatus = (props: ProjectListStatusProps): React.ReactElement => {
    const { progress, status } = props;

    return (
        <Root>
            {progress ? <RoundedProgressBar progress={progress} /> : null}
            <div>{status}</div>
        </Root>
    );
};

export { ProjectListStatus };

import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { DefaultTypeData } from '@modules/types/graphql';

export type Entity = Partial<DefaultTypeData> | null;

@DecribeEntity('DefalutTypeEntity')
class DefalutTypeEntity extends BaseEntity {
    // id: number;
    categoryId: number;
    typeIds: any;
    itemCategoryTypeId: number;

    constructor(defaultdata: Entity) {
        super(defaultdata);

        // this.id = defaultdata?.id ?? -1;
        this.categoryId = defaultdata?.category_id ?? -1;
        this.typeIds = defaultdata?.type_ids ?? -1;
        this.itemCategoryTypeId = defaultdata?.item_category_type_id ?? -1;
    }
}

export { DefalutTypeEntity };

import { EntityList } from '@lib/entity';
import { ComponentEntity } from './component-entity';

import type { Entity } from './component-entity';

class ComponentEntityList extends EntityList<ComponentEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(ComponentEntity, items, count);
    }
}

export { ComponentEntityList };

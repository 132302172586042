import React from 'react';
import styled from 'styled-components';

import { Button } from '@modules/ui/core';
import { useCurrentUser } from '@modules/auth/hooks';
import { GapEntity } from '@modules/custom-components/entities';
import { DialogGapDelete } from '@modules/custom-components/moleculas';
import { RecommendationEdit } from '@modules/custom-components/organisms';

type CapabilityLevelGapItemProps = {
    gap: GapEntity;
    loadingDelete: boolean;
    onCreateRecommendation: (id: number) => void;
    onClickEdit: (id: number) => void;
    onDelete: (id: number) => Promise<void>;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 16px 0;

    > * {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
    }
`;

const Header = styled.div`
    margin-bottom: 16px;
`;

const HeaderTitle = styled.div`
    font-size: 1.6rem;
    font-weight: 700;
`;

const HeaderActions = styled.div`
    margin-left: auto;

    > * {
        margin-left: 16px;
    }
`;

const Actions = styled.div`
    margin-top: 8px;
    margin-bottom: 8px;

    > * {
        margin-right: 16px;
    }
`;

const CapabilityLevelGapItem = (props: CapabilityLevelGapItemProps) => {
    const { gap, loadingDelete, onCreateRecommendation, onClickEdit, onDelete } = props;

    const { currentUser } = useCurrentUser();

    const handleClickCreateRecommendationAction = (): void => onCreateRecommendation(gap.id);
    const handleClickEdit = (): void => onClickEdit(gap.id);
    const handleDelete = (): Promise<void> => onDelete(gap.id);

    const canEditGap = gap.isPredefined() ? currentUser.hasSuperAdminPermission() : true;

    return (
        <Root>
            <Header>
                <HeaderTitle>{gap.gap}</HeaderTitle>

                {canEditGap ? (
                    <HeaderActions>
                        <Button size='small' onClick={handleClickEdit}>
                            Edit
                        </Button>

                        <DialogGapDelete disabled={loadingDelete} onDelete={handleDelete} />
                    </HeaderActions>
                ) : null}
            </Header>

            <RecommendationEdit gapId={gap.id} />

            {canEditGap ? (
                <Actions>
                    <Button size='small' onClick={handleClickCreateRecommendationAction}>
                        Add recommendations
                    </Button>
                </Actions>
            ) : null}
        </Root>
    );
};

export { CapabilityLevelGapItem };

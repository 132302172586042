import React from 'react';

import { ListItemActions } from '@modules/layout/moleculas';
import { DialogUserDelete } from '@modules/users/moleculas';
import { DialogAssetDelete } from '../dialog-asset-delete';

type AssetListItemActionsProps = {
    assetId: any;
    onDelete: (userId: number) => Promise<void>;
};

const AssetListItemActions = (props: AssetListItemActionsProps): React.ReactElement => {
    const { assetId, onDelete } = props;

    const handleDelete = () => onDelete(assetId);

    return (
        <ListItemActions>
            {actionsProps => {
                const { onClose } = actionsProps;

                const handleClick = (fn?: any) => (): void => {
                    if (typeof fn === 'function') {
                        fn();
                    }

                    onClose();
                };

                return (
                    <>
                        <DialogAssetDelete onDelete={handleClick(handleDelete)} />
                    </>
                );
            }}
        </ListItemActions>
    );
};

export { AssetListItemActions };

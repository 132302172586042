import { routes } from '@config/routes';
import { useNavigate } from '@reach/router';

const useLoginAttempts = () => {
    const navigate = useNavigate();

    const getLoginAttempts = () => localStorage.getItem('attempts') || 0;

    const checkLoginAttempts = async () => {
        const loginAttempts = getLoginAttempts();

        if (Number(loginAttempts) >= 4) {
            await navigate(routes.forgotPassword.path);
        }

        return loginAttempts;
    };

    const updateLoginAttempts = () => {
        const loginAttempts = getLoginAttempts();
        localStorage.setItem('attempts', (Number(loginAttempts) + 1).toString());
    };

    const clearLoginAttempts = () => {
        if (localStorage.getItem('attempts')) {
            localStorage.removeItem('attempts');
        }
    };

    return {
        getLoginAttempts,
        checkLoginAttempts,
        updateLoginAttempts,
        clearLoginAttempts,
    };
};

export { useLoginAttempts };

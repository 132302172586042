import React, {useEffect, useMemo} from 'react';

import type { ComponentReportEntity } from '@modules/assessment/entities';
import {
  recommendationLevelFieldLabelList,
  recommendationLevelFieldList
} from '@modules/assessment/entities/capability-report-entity';
import {PrioritizedRoadmapEntity, RoadmapRecommendationEntity, RoadmapReportEntity} from '@modules/roadmap/entities';
import {RecommendationViewData} from '@modules/roadmap/recommendation-by-risk';
import {RecommendationAssessment} from '@modules/roadmap/recommendation-assessment-roadmap-report';
import {GapViewData} from '@modules/assessment/gap-by-risk';
import {GapAssessment} from '@modules/assessment/gap-assessment';
import {ComponentAssessmentEntity} from '@modules/assessment/entities';
import {ImplementationStatusList} from '@modules/shared/constants/implementation-status';
import {StatusGraphDataset} from '@modules/assessment/organisms/report-components-list/report-general-chart-data';

// export function getTopList(viewData: RecommendationViewData): {allList: RecommendationAssessment[], topList: RecommendationAssessment[]} {
//   return {
//     allList: viewData.scoreList,
//     topList: viewData.scoreList.slice(0,5),
//   };
// }

// export function getOveralCount(roadmapReport: RoadmapReportEntity): number {
//   let overalCount: number = getCountByRisk(roadmapReport).reduce((count, countByRisk) => {
//     return count + countByRisk[0];
//   }, 0);
//
//   return overalCount;
// }

// export function getCountByComponent(roadmapReport: RoadmapReportEntity): Array<[number, string]> {
//   let countByComponent: Array<[number, string]> = roadmapReport.componentReports.map((componentReport) => {
//     let recommendationCount = componentReport?.capabilities.reduce((count, entity) => {
//       return recommendationLevelFields.reduce((subCount, field) => {
//         return subCount + entity[field].length;
//       }, count);
//     }, 0);
//
//     return [
//       recommendationCount ?? 0,
//       componentReport?.component.name ?? ''
//     ]
//   });
//
//   return countByComponent;
// }

export function getCountByRisk(prioritizedRoadmap: PrioritizedRoadmapEntity): Array<[number, string]> {
  let countByRisk: Array<[number, string]> = recommendationLevelFieldList.map((field, fieldIndex) => {
    return [
      prioritizedRoadmap[field]?.length ?? 0,
      recommendationLevelFieldLabelList[fieldIndex]
    ]
  });

  return countByRisk;
}

export function getStatusByComponent(componentsAssessmentList: ComponentAssessmentEntity[], recommendationViewData: RecommendationViewData): StatusGraphDataset {
  let labelList: string[] = [];
  let datasetList: Array<{label: string, data: number[]}> = ImplementationStatusList.map((key: string, index: number) => {
    return {
      label: key,
      data: []
    }
  });

  componentsAssessmentList.forEach((componentAssessment) => {
    let recommendationComponentAssessment =  recommendationViewData.componentAssessmentMap.get(componentAssessment.id);
    labelList.push(componentAssessment.component.name)

    let index = 0
    recommendationComponentAssessment?.statusCountMap.forEach((value: number, key: string) => {
      datasetList[index].data.push(value);
      index += 1;
    });
  });

  return {
    labelList,
    datasetList
  }
}

export function getRecommendationStatus(componentsAssessmentList: ComponentAssessmentEntity[], recommendationViewData: RecommendationViewData): {labelList: string[], datasetList: number[]} {
  let labelList: string[] = ImplementationStatusList.slice(1);
  let datasetList: number[] = ImplementationStatusList.map(() => 0);

  componentsAssessmentList.forEach((componentAssessment) => {
    let recommendationComponentAssessment =  recommendationViewData.componentAssessmentMap.get(componentAssessment.id);

    Array.from(recommendationComponentAssessment?.statusCountMap ?? []).forEach((statusCount: [string, number], index: number) => {
      datasetList[index] += statusCount[1];
    });
  });
  datasetList = datasetList.slice(1);

  return {
    labelList,
    datasetList
  }
}

import { gql } from '@apollo/client';

export const GetLearnTkoCaptionQuery = gql`
    query GetLearnTkoCaption($id: Int) {
        getLearnTkoCaption(id: $id) {
            id
            caption
        }
    }
`;

import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowDownIcon } from './source/arrow-down.svg';

type ArrowDownProps = {
    className?: string;
};

const Root = styled.div`
    display: inline-flex;
`;

const ArrowDown = (props: ArrowDownProps): React.ReactElement => {
    return (
        <Root {...props}>
            <ArrowDownIcon />
        </Root>
    );
};

export { ArrowDown };

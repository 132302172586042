import React, { useEffect, useMemo } from 'react';

import styled from 'styled-components';
import { ListStyled } from '@modules/layout/styled';

import { ReportComponentView } from './report-component-view';
import { ReportRiskVIew } from './report-risk-view';
import { ProjectEntity } from '@modules/projects/entities';
import { PrioritizedRoadmapEntity, RoadmapReportEntity } from '@modules/roadmap/entities';
import { ComponentAssessmentEntity } from '@modules/assessment/entities';
import {
    RecommendationByRisk,
    RecommendationComponentAssessment,
    RecommendationViewData,
} from '@modules/roadmap/recommendation-by-risk';

type ReportBodyProps = {
    project: ProjectEntity;
    prioritizedRoadmap: PrioritizedRoadmapEntity;
    recommendationViewDataRaw: RecommendationViewData;
    componentsAssessmentList: ComponentAssessmentEntity[];
    activeTab: number;
};

export const ReportBodyView = (props: ReportBodyProps): React.ReactElement => {
    return (
        <ListStyled.Container>
            {props.activeTab === 0 && (
                <ReportComponentView
                    componentsAssessmentList={props.componentsAssessmentList}
                    componentAssessmentMap={props.recommendationViewDataRaw.componentAssessmentMap}
                />
            )}
            {props.activeTab === 1 && (
                <ReportRiskVIew
                    prioritizedRoadmap={props.prioritizedRoadmap}
                    project={props.project}
                />
            )}
        </ListStyled.Container>
    );
};

import React from 'react';
import { makeStyles, TextField as MuiTextField } from '@material-ui/core';

import { ArrowDown } from '@modules/ui/icons';
import { concatenateClasses } from '@modules/ui/utils';

import type { Theme, TextFieldProps as MuiTextFieldProps } from '@material-ui/core';

type TextFieldProps = MuiTextFieldProps & {
    corner?: 'normal' | 'round';
};

const useSelectStyles = makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    icon: {
        top: 'calc(50% - 4px)',
        right: 18,
    },
    select: {
        '&:focus': {
            backgroundColor: 'transparent',
        },
    },
});

const useInputLabelStyles = makeStyles({
    outlined: {
        transform: `translate(14px, 18px) scale(1)`,
    },
});

const useInputStyles = makeStyles<
    Theme,
    { corner: NonNullable<TextFieldProps['corner']>; multiline?: boolean }
>({
    root: p => ({
        height: !!p.multiline ? 'initial' : 48,
        borderRadius: p.corner === 'round' ? 24 : 4,
    }),
});

const TextField = (props: TextFieldProps): React.ReactElement => {
    const {
        corner = 'normal',
        multiline,
        SelectProps,
        InputLabelProps,
        InputProps,
        ...otherProps
    } = props;

    const selectClasses = concatenateClasses(useSelectStyles(), SelectProps?.classes);
    const inputLabelClasses = concatenateClasses(useInputLabelStyles(), InputLabelProps?.classes);
    const inputClasses = concatenateClasses(
        useInputStyles({ corner, multiline }),
        InputProps?.classes,
    );

    return (
        <MuiTextField
            multiline={multiline}
            InputLabelProps={{
                ...InputLabelProps,
                classes: inputLabelClasses,
            }}
            InputProps={{
                ...InputProps,
                classes: inputClasses,
            }}
            SelectProps={{
                displayEmpty: true,
                IconComponent: ArrowDown,
                MenuProps: {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                },
                ...SelectProps,
                classes: selectClasses,
            }}
            {...otherProps}
        />
    );
};

export { TextField };

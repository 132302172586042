import { CapabilityCreateRequest } from './capability-create-request';

import type { UpdateCapabilityMutationVariables } from '@modules/types/graphql';
import type { CapabilityCreateFormValues } from './capability-create-request';

export type CapabilityUpdateFormValues = CapabilityCreateFormValues & {
    capabilityId: number;
};

class CapabilityUpdateRequest extends CapabilityCreateRequest {
    capabilityId: UpdateCapabilityMutationVariables['capabilityId'];

    constructor(values: CapabilityUpdateFormValues) {
        super(values);

        this.capabilityId = values.capabilityId;
    }
}

export { CapabilityUpdateRequest };

import { gql } from '@apollo/client';

import { AddAssessmentTypeFragment } from '../fragments';

export const UpdateAssessmentTypeNameMutation = gql`
    mutation UpdateAssessmentCategories(
        $id: Int!
        $name: String!
        $isRRType: Boolean!
        $assessmentCategoriesId: Int!
    ) {
        updateAssessmentType(
            id: $id
            name: $name
            is_rr_type: $isRRType
            assessment_categories_id: $assessmentCategoriesId
        ) {
            ...AssessmentType
        }
    }
    ${AddAssessmentTypeFragment}
`;

import { gql } from '@apollo/client';

import { RecommendationSingleFragment } from '../fragments';

export const CreateRecommendationMutation = gql`
    mutation CreateRecommendation($gapId: Int!, $recommendation: String!, $companyId: Int) {
        createRecommendation(
            gap_id: $gapId
            recommendation: $recommendation
            company_id: $companyId
        ) {
            ...RecommendationSingle
        }
    }
    ${RecommendationSingleFragment}
`;

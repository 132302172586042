import { gql } from '@apollo/client';

export const GetCapabilityLevelDefinitionsQuery = gql`
    query GetCapabilityLevelDefinitions {
        listCapabilityLevelDefinitions {
            id
            name
            level
        }
    }
`;

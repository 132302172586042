import gql from 'graphql-tag';
import { LearnTkoDetailsFragment } from '../fragments';

export const UpdateLearnTkoDetailsMutation = gql`
    mutation UpdateLearnTkoDetail(
        $learnTkoId: Int!
        $menu: String!
        $title: String!
        $description: String
        $videoUrl: String
    ) {
        updateLearnTkoDetail(
            learnTko_id: $learnTkoId
            menu: $menu
            title: $title
            description: $description
            video_url: $videoUrl
        ) {
            ...LearnTkoDetails
        }
    }
    ${LearnTkoDetailsFragment}
`;

import gql from 'graphql-tag';

import { UserFragment } from '../fragments';

export const CreateUserMutation = gql`
    mutation CreateUser(
        $cognitoId: String!
        $username: String!
        $email: String!
        $firstName: String
        $lastName: String
        $userRole: String
    ) {
        createUser(
            cognito_id: $cognitoId
            username: $username
            email: $email
            first_name: $firstName
            last_name: $lastName
            user_role: $userRole
        ) {
            ...User
        }
    }
    ${UserFragment}
`;

import { EntityList } from '@lib/entity';
import { ImpactCapabilityLevelEntity } from './impact-capability-level-entity';

import type { Entity } from './impact-capability-level-entity';

class ImpactCapabilityLevelEntityList extends EntityList<ImpactCapabilityLevelEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(ImpactCapabilityLevelEntity, items, count);
    }
}

export { ImpactCapabilityLevelEntityList };

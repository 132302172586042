import { createCustomError } from '@lib/errors';

import type { ComponentEntity } from '@modules/components/entities';
import type { UserListEntity } from '@modules/users/entities';
import type { ComponentInput, CapabilityInput } from '@modules/types/graphql';

export type AssessmentComponentChangesFormValues = {
    weight: number;
    component?: ComponentEntity | null;
    componentOwner?: UserListEntity | null;
    objectives?: string;
    capabilities?: (CapabilityInput | null)[];
};

export type ProjectAddComponentsFormValues = {
    components: AssessmentComponentChangesFormValues[];
};

class AssessmentComponentChangesRequest {
    component: ComponentInput;

    constructor(values: AssessmentComponentChangesFormValues) {
        if (!values.component || !values.componentOwner) {
            throw createCustomError('incorrect arguments');
        }

        this.component = {
            component_id: values.component.id,
            component_owner: values.componentOwner.id,
            weight: values.weight,
            objectives: values.objectives,
            capabilities: values.capabilities,
        };
    }
}

export { AssessmentComponentChangesRequest };

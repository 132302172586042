import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useNavigate } from '@reach/router';

import { routes } from '@config/routes';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { PageStyled } from '@modules/layout/styled';
import { SignUpUserRequest } from '@modules/users/requests';
import { UserForm } from '@modules/users/organisms';
import { SignUpUserMutation } from '@modules/users/graphql';

import type { SignUpUserFormValues } from '@modules/users/requests';
import type { Page } from '@modules/types/page';
import type { SignUpUserMutationType, SignUpUserMutationVariables } from '@modules/types/graphql';
// import { SetUserRoleMutation } from '@modules/users/graphql/mutations';
import { GetUsersDetailsQuery } from '@modules/users/graphql/queries';
import { useCompanies } from '@modules/company/hooks';
import { useCurrentUser } from '@modules/auth/hooks';
import { Loader } from '@modules/layout/moleculas';

const Main = styled(PageStyled.Main)`
    margin-top: 48px;
`;

const FormWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-width: 625px;
`;

const UsersCreatePage = (props: Page): React.ReactElement => {
    const { title = 'Create User' } = props;

    const navigate = useNavigate();

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [companyValue, setCompanyValue] = useState<any>(null);
    const { currentUser, loading: currentUserLoading } = useCurrentUser();
    const { companies, loading: companiesLoading } = useCompanies();
    console.log(companies, 'companies')
    console.log(currentUser, 'currentUser')
    useEffect(() => {
        const companyDetails: any = companies && companies[0];
        setCompanyValue(companyDetails);
    }, [companies]);

    const initialValues: SignUpUserFormValues = {
        id: 0,
        username: '',
        firstName: '',
        lastName: '',
        isAdmin: false,
        company: currentUser?.isAdmin ? companyValue : null,
        userRole: '',
        companyName: '',
        schemaName: '',
    };

    const [signUpUser] = useMutation<SignUpUserMutationType, SignUpUserMutationVariables>(
        SignUpUserMutation,
    );

    const handleSubmit = async (values: SignUpUserFormValues): Promise<void> => {
        try {
            // if (currentUser.isSuperAdmin) {
            //     if (
            //         values?.userRole?.id === 'OP' ||
            //         values?.userRole?.id === 'CO' ||
            //         values?.userRole?.id === 'ST' ||
            //         values?.userRole?.id === 'RE' ||
            //         values?.userRole?.id === 'AO'
            //     ) {
            //         values.isAdmin = false;
            //     } else if (values?.userRole?.id === 'RM' || values?.userRole?.id === 'PM') {
            //         values.isAdmin = true;
            //     } else {
            //         values.isAdmin = false;
            //     }
            // } else {
            //     if (values?.userRole?.id === 'RM' || values?.userRole?.id === 'PM') {
            //         values.isAdmin = true;
            //     } else {
            //         values.isAdmin = false;
            //     }
            // }
            const variables = new SignUpUserRequest(values);
            console.log(values, 'values')
            variables.schemaName = values?.company?.schema_name;
            const { data: signUpUserData } = await signUpUser({
                variables,
                refetchQueries: [{ query: GetUsersDetailsQuery }],
                awaitRefetchQueries: true,
            });
            console.log(variables, 'variables')
            if (signUpUserData?.signUpUser) {
                enqueueSuccess('User successfully created!');
                navigate(routes.users.path);
            } else {
                enqueueError('An error occurred while creating the user!');
            }
        } catch (e) {
            throw e;
        }
    };

    if (companiesLoading || currentUserLoading) {
        return <Loader />
    }

    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>{title}</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>

            <Main>
                <UserForm<'create'>
                    type='create'
                    initialValues={initialValues}
                    FormWrapComponent={FormWrap}
                    onSubmit={handleSubmit}
                />
            </Main>
        </>
    );
};

export { UsersCreatePage };

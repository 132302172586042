import React from 'react';
import styled from 'styled-components';

import { white, gray } from '@modules/ui/colors';
import { Logo } from '@modules/layout/icons';
import { HeaderMenu, Loader } from '@modules/layout/moleculas';
import { useCurrentUser } from '@modules/auth/hooks';
import { HeaderUserInfo } from '@modules/users/organisms';
import { Link } from '@reach/router';
import { useUserHighRole } from '@modules/assessment/hooks/use-user-high-role';

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 60px;
    position: relative;
    background-color: ${white[100]};
    border-bottom: 1px solid ${gray[40]};

    @media print {
        display: none;
    }
`;

const HeaderBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0 24px;
`;

const Logotype = styled(HeaderBlock)`
    align-items: center;

    > * {
        display: inline-flex;
    }
`;

const Title = styled.div`
    margin-left: 12px;
    font-size: 1rem;
    line-height: 12px;
`;

const HeaderBlockMenu = styled(HeaderBlock)`
    padding-left: 40px;
    padding-right: 40px;
`;

const HeaderBlockUser = styled(HeaderBlock)`
    margin-left: auto;
    padding: 0;
`;

const Header = (): React.ReactElement => {
    const { currentUser } = useCurrentUser();
    const { userHighRolesEntityList, loading: roleLoading } = useUserHighRole();
    const roleAbbrevation: string | any = roleLoading ? (
        <Loader />
    ) : (
        userHighRolesEntityList[0]?.welcomeRole === 'ST' ? 'SH' : userHighRolesEntityList[0]?.welcomeRole
    );

    return (
        <Root>
            <Logotype>
                <Link to='/welcome-page'>
                    <Logo />
                </Link>
                <Title>
                    Collaborative Risk <br /> Management Platform
                </Title>
            </Logotype>

            {currentUser.exists() ? (
                <>
                    <HeaderBlockMenu>
                        <HeaderMenu />
                    </HeaderBlockMenu>

                    <HeaderBlockUser>
                        <HeaderUserInfo
                            fullName={currentUser.getFullName()}
                            email={currentUser.email}
                            roleAbbrevation={roleAbbrevation}
                            currentUserDetail={currentUser}
                        />
                    </HeaderBlockUser>
                </>
            ) : null}
        </Root>
    );
};

export { Header };

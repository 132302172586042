import React from 'react';
import styled from 'styled-components';
import { TextField, Button, Autocomplete } from '@modules/ui/core';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import * as yup from 'yup';
import { FormStyled } from '@modules/layout/styled';

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const Title = styled.div`
    margin-bottom: 32px;
    font-size: 15px;
`;

const SubmitButton = styled(Button)`
    min-width: 100%;
`;

type ProjectFormProps<T = any> = FormProps<T> & {
    onCancel?: () => void;
};

const ContactPage = (props: ProjectFormProps): React.ReactElement => {
    let { initialValues, FormWrapComponent, onSubmit } = props;
    return (
        <ExtendedFormik
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                name: yup
                    .string()
                    .required('Required field')
                    .min(4, 'Minimum 4 characters at least')
                    .max(30, 'Maximum 15 characters'),
                email: yup.string().email('Must be a valid email').required('Required field'),
                phone: yup
                    .string()
                    .required('Required field')
                    .matches(/^\d{10,12}$/, 'Invalid phone number')
                    .matches(/^\d*$/, 'Phone number must contain only numbers'),
                description: yup.string().required('Required field'),
            })}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {formikProps => {
                const { values, errors, handleChange, handleBlur, isSubmitting } = formikProps;

                return (
                    <FormWrapComponent>
                        <Title>
                            <h3>
                                Please complete the form below and we'll get back to you as soon as
                                possible.
                            </h3>
                        </Title>
                        <StyledForm>
                            <TextField
                                fullWidth
                                id='name'
                                label='Your Full Name*'
                                value={values.name}
                                error={!!errors.name}
                                helperText={errors.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <TextField
                                fullWidth
                                id='email'
                                label='Email Address*'
                                value={values.email}
                                error={!!errors.email}
                                helperText={errors.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <TextField
                                fullWidth
                                id='phone'
                                label='Phone Number*'
                                value={values.phone}
                                error={!!errors.phone}
                                helperText={errors.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <TextField
                                fullWidth
                                multiline
                                id='description'
                                label='How can we help you? Please use this area to provide details message. Thank You!*'
                                value={values.description}
                                error={!!errors.description}
                                helperText={errors.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    Submit
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    </FormWrapComponent>
                );
            }}
        </ExtendedFormik>
    );
};

export { ContactPage };

import { useQuery } from '@apollo/client';
import type { QueryHookOptions } from '@apollo/client';
import { GetSubscriptionsQuery } from '../graphql/queries';

// const useSubscription = (options?: QueryHookOptions<GetSubscriptionsQueryType>) => {
//     const queryResult = useQuery<GetSubscriptionsQueryType>(GetSubscriptionsQuery, options);

//     const { entities: subscriptions, count } = useEntity(
//         () => new SubscriptionsTypeEntityList(queryResult.data?.listSubscriptions ?? null),
//         [queryResult.data],
//     );

//     const result = {
//         subscriptions,
//         count,
//         ...queryResult,
//     };

//     return result;
// };

const useSubscriptions = (options?: QueryHookOptions) => {
    const { data, loading } = useQuery(GetSubscriptionsQuery, options);

    const result = {
        Subscriptions: data?.listSubscriptions,
        loading,
    };

    return result;
};

export { useSubscriptions };

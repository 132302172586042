import { EntityList } from '@lib/entity';

import { PackagesEntity, PackagesTypeEntity } from './packages-type-entity';

class PackagesTypeEntityList extends EntityList<PackagesTypeEntity> {
    constructor(items: PackagesEntity[] | null, count?: number | null) {
        super(PackagesTypeEntity, items, count);
    }
}

export { PackagesTypeEntityList };

export type AcknowledgeRecommendationRequestFormValues = {
    notes: string;
};

export type AcknowledgeRecommendationRequestExtra = {
    roadmapRecommendationId: number;
};

class AcknowledgeRecommendationRequest {
    roadmapRecommendationId: number;
    notes: string;

    constructor(
        values: AcknowledgeRecommendationRequestFormValues,
        extra: AcknowledgeRecommendationRequestExtra,
    ) {
        this.roadmapRecommendationId = extra.roadmapRecommendationId;
        this.notes = values.notes;
    }
}

export { AcknowledgeRecommendationRequest };

import { gql } from '@apollo/client';

export const UpdateProjectRoleMutation = gql`
    mutation UpdateProjectRole($projectId: Int!, $userId: Int!, $roleId: String!) {
        updateProjectRole(project_id: $projectId, user_id: $userId, role_id: $roleId) {
            id
            first_name
            last_name
        }
    }
`;

import { gql } from '@apollo/client';
import { ImplementationSubTaskSingleFragment } from '../fragments';

export const CreateImplementationSubTaskMutation = gql`
    mutation CreateSubTask(
        $recommendationId: Int!
        $subTask: String!
        $companyId: Int
        $taskId: Int!
        $isAiSubTasks: Boolean!
    ) {
        createSubTask(
            recommendation_id: $recommendationId
            sub_task: $subTask
            company_id: $companyId
            task_id: $taskId
            is_ai_sub_tasks: $isAiSubTasks
        ) {
            ...SubTaskSingle
        }
    }
    ${ImplementationSubTaskSingleFragment}
`;

import { CapabilityLevelCreateRequest } from './capability-level-create-request';

import type { UpdateCapabilityLevelMutationVariables } from '@modules/types/graphql';
import type { CapabilityLevelCreateFormValues } from './capability-level-create-request';

export type CapabilityLevelUpdateFormValues = CapabilityLevelCreateFormValues & {
    capabilityLevelId: number;
};

class CapabilityLevelUpdateRequest extends CapabilityLevelCreateRequest {
    capabilityLevelId: UpdateCapabilityLevelMutationVariables['capabilityLevelId'];

    constructor(values: CapabilityLevelUpdateFormValues) {
        super(values);

        this.capabilityLevelId = values.capabilityLevelId;
    }
}

export { CapabilityLevelUpdateRequest };

import { QueryHookOptions, useQuery } from '@apollo/client';
import {
    GetLearnTkoCaptionQueryType,
    GetLearnTkoCaptionQueryVariable,
} from '@modules/types/graphql';

import { useEntity } from '@lib/entity';
import { LearnTkoCaptionEntity } from '../entities';
import { GetLearnTkoCaptionQuery } from '../graphql/queries';

const useLearnTkoCaption = (
    options?: QueryHookOptions<GetLearnTkoCaptionQueryType, GetLearnTkoCaptionQueryVariable>,
) => {
    const queryResult = useQuery<GetLearnTkoCaptionQueryType, GetLearnTkoCaptionQueryVariable>(
        GetLearnTkoCaptionQuery,
        {
            fetchPolicy: 'network-only',
            ...options,
        },
    );

    const caption = useEntity(
        () => new LearnTkoCaptionEntity(queryResult.data?.getLearnTkoCaption ?? null),
        [queryResult.data],
    );

    const result = {
        caption,
        ...queryResult,
    };
    return result;
};

export { useLearnTkoCaption };

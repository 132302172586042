import { gql } from '@apollo/client';

export const GetProjectCoUserListQuery = gql`
    query GetProjectCoUserList($project_id: Int!) {
        listProjectCoUsers(
            project_id: $project_id
        ) {
            id
            first_name
            last_name
        }
    }
`;

import type { AddUsageTimeMutationVariables } from '@modules/types/graphql';

export type AddUsageFormValues = {
    userId: number;
    dailyUsage: number;
};

class AddUsageFormRequest {
    userId: AddUsageTimeMutationVariables['userId'];
    dailyUsage: AddUsageTimeMutationVariables['dailyUsage'];

    constructor(values: AddUsageFormValues) {
        this.userId = values.userId;
        this.dailyUsage = values.dailyUsage;
    }
}

export { AddUsageFormRequest };

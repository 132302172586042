import { EntityList } from '@lib/entity';
import { ComponentReportEntity } from './component-report-entity';

import type { Entity } from './component-report-entity';

class ComponentReportEntityList extends EntityList<ComponentReportEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(ComponentReportEntity, items, count);
    }
}

export { ComponentReportEntityList };

import { gql } from '@apollo/client';

import { RoadmapRecommendationRefinementFragment } from '../fragments';

export const AcknowledgeRecommendationMutation = gql`
    mutation AcknowledgeRecommendation($roadmapRecommendationId: Int!, $notes: String) {
        acknowledgeRecommendation(
            roadmap_recommendation_id: $roadmapRecommendationId
            notes: $notes
        ) {
            ...RoadmapRecommendationRefinement
        }
    }
    ${RoadmapRecommendationRefinementFragment}
`;

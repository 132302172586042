import React from 'react';
import styled, { css } from 'styled-components';

import { white, gray } from '@modules/ui/colors';
import { ProjectMaturityLevelEnum, projectLevelColors } from '@modules/projects/constants';

type ProjectMaturityLevelProps = {
    current: ProjectMaturityLevelEnum;
};

const Root = styled.div`
    > * {
        width: 100%;
    }
`;

const List = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 6px;

    > * {
        &:not(:last-child) {
            margin-right: 4px;
        }
    }
`;

const MaturityLabel = styled.div`
    text-transform: capitalize;
`;

const Item = styled.div<{ active?: boolean; color?: string }>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    height: 32px;
    font-size: 1.2rem;
    text-transform: capitalize;
    line-height: 12px;
    background-color: #f4f2f2;
    border-radius: 4px;

    ${p =>
        !!p.active &&
        css`
            color: ${white[100]};
            background-color: ${gray[30]};
        `}
`;

const ListItem = styled(Item)`
    min-width: 21px;
    height: 8px;
    border-radius: 0px;
    background-color: #c4c4c4;

    ${p =>
        !!p.active &&
        css`
            background-color: ${p.color};
        `}
`;

const levels = Object.entries(ProjectMaturityLevelEnum);

const ProjectMaturityLevel = (props: ProjectMaturityLevelProps): React.ReactElement => {
    const { current } = props;

    const currentIdx = levels.findIndex(([_, value]) => value.toLowerCase() === current.toLowerCase());

    return (
        <Root>
            <MaturityLabel>{current}</MaturityLabel>

            <List>
                {levels.map(([key], index) => (
                    <ListItem key={key} color={projectLevelColors[current]} active={index <= currentIdx} />
                ))}
            </List>
        </Root>
    );
};

export { ProjectMaturityLevel };

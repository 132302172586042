import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { useEnqueueStacks } from '@modules/layout/hooks';
import { Loader } from '@modules/layout/moleculas';
import { useRequirements } from '@modules/custom-components/hooks';
import {
    RequirementCreateRequest,
    RequirementUpdateRequest,
} from '@modules/custom-components/requests';
import { RequirementForm } from '@modules/custom-components/organisms';
import {
    CreateRequirementMutation,
    UpdateRequirementMutation,
} from '@modules/custom-components/graphql';

import type { RecommendationEntity } from '@modules/custom-components/entities';
import type {
    RequirementCreateFormValues,
    RequirementUpdateFormValues,
} from '@modules/custom-components/requests';
import type {
    CreateRequirementMutationType,
    CreateRequirementMutationVariables,
    UpdateRequirementMutationType,
    UpdateRequirementMutationVariables,
} from '@modules/types/graphql';

type EditRecommendationTabProps = {
    recommendation: RecommendationEntity;
    onCancel?: () => void;
};

const FormWrap = styled.div`
    width: 100%;
`;

const EditRequirementTab = (props: EditRecommendationTabProps) => {
    const { recommendation, onCancel } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const {
        requirements,
        count: requirementsCount,
        loading: requirementsLoading,
    } = useRequirements({
        variables: { recommendationId: recommendation.id },
    });

    const [currentRequirement] = requirements;

    const [createRequirement] = useMutation<
        CreateRequirementMutationType,
        CreateRequirementMutationVariables
    >(CreateRequirementMutation);

    const [updateRequirement] = useMutation<
        UpdateRequirementMutationType,
        UpdateRequirementMutationVariables
    >(UpdateRequirementMutation);

    const createInitialValues = {
        requirement: '',
    };

    const handleCreateRequirementSubmit = async (values: RequirementCreateFormValues) => {
        try {
            const variables = new RequirementCreateRequest(values, {
                recommendationId: recommendation.id,
            });

            const { data: createRequirementData } = await createRequirement({
                variables,
            });

            const requirementId = createRequirementData?.createRequirement?.id;

            if (requirementId) {
                enqueueSuccess('Requirement successfully created!');
            } else {
                enqueueError('An error occurred while creating requirement!');
            }
        } catch (e) {
            throw e;
        }
    };

    const handleUpdateRequirementSubmit = async (values: RequirementUpdateFormValues) => {
        try {
            const variables = new RequirementUpdateRequest(values, {
                recommendationId: recommendation.id,
            });

            const { data: updateRequirementData } = await updateRequirement({
                variables,
            });

            const requirementId = updateRequirementData?.updateRequirement?.id;

            if (requirementId) {
                enqueueSuccess('Requirement successfully updated!');
            } else {
                enqueueError('An error occurred while updating Requirement!');
            }
        } catch (e) {
            throw e;
        }
    };

    if (requirementsLoading) {
        return <Loader />;
    }

    if (requirementsCount === 0) {
        return (
            <RequirementForm<'create'>
                type='create'
                initialValues={createInitialValues}
                recommendationId={recommendation.id}
                onSubmit={handleCreateRequirementSubmit}
                FormWrapComponent={FormWrap}
                onCancel={onCancel}
            />
        );
    }

    const updateInitialValues = {
        requirementId: currentRequirement.id,
        requirement: currentRequirement.requirement,
    };

    return (
        <RequirementForm<'edit'>
            type='edit'
            initialValues={updateInitialValues}
            onSubmit={handleUpdateRequirementSubmit}
            FormWrapComponent={FormWrap}
            onCancel={onCancel}
        />
    );
};

export { EditRequirementTab };

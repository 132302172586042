import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';

import { Button } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';
import { CreateComponentMutation } from '@modules/custom-components/graphql';

import type {
    CreateComponentMutationType,
    CreateComponentMutationVariables,
    UpdateCaptionMutationType,
    UpdateCaptionMutationVariables,
} from '@modules/types/graphql';
import { TextareaAutosize } from '@material-ui/core';
import { UpdateLearnTkoCaptionMutation } from '@modules/learn-tko/graphql/mutations';
import { GetLearnTkoCaptionQuery } from '@modules/learn-tko/graphql/queries';

type captionProps = { id: number; caption: string };

type CaptionUpdateFormProps = {
    caption: captionProps;
    onCancel?: () => void;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
    textarea {
        min-height: 160px;
        width: 100%;
        padding: 10px;
        font-size: 16px;
        color: #1e1e1e;
        overflow: hidden;
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const CaptionUpdateForm = (props: CaptionUpdateFormProps): React.ReactElement => {
    const { caption, onCancel } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [updateCaption] = useMutation<UpdateCaptionMutationType, UpdateCaptionMutationVariables>(
        UpdateLearnTkoCaptionMutation,
    );

    const initialValues = {
        caption: caption?.caption,
    };

    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                caption: yup.string().required('Required field'),
            })}
            initialValues={initialValues}
            onSubmit={async values => {
                console.log('object');
                try {
                    const { data: updateCaptionData } = await updateCaption({
                        variables: {
                            id: caption?.id,
                            caption: values.caption,
                        },
                        refetchQueries: [{ query: GetLearnTkoCaptionQuery }],
                        awaitRefetchQueries: true,
                    });
                    if (updateCaptionData?.updateLearnTkoCaption?.id) {
                        enqueueSuccess('Caption successfully updated!');
                        onCancel?.();
                    } else {
                        enqueueError('An error occurred while updating the caption card!');
                    }
                } catch (e) {
                    throw e;
                }
            }}
        >
            {formikProps => {
                const { values, errors, handleChange, handleBlur, isSubmitting } = formikProps;

                return (
                    <StyledForm>
                        <TextareaAutosize
                            required
                            id='caption'
                            value={values.caption}
                            // error={!!errors.caption}
                            // helperText={errors.caption}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <FormStyled.FormRowSubmit>
                            <SubmitButton type='submit' loading={isSubmitting}>
                                Update Caption
                            </SubmitButton>

                            <SubmitButton variant='text' disabled={isSubmitting} onClick={onCancel}>
                                Cancel
                            </SubmitButton>
                        </FormStyled.FormRowSubmit>
                    </StyledForm>
                );
            }}
        </ExtendedFormik>
    );
};

export { CaptionUpdateForm };

import { gql } from '@apollo/client';
import { ImplementationTaskSingleFragment } from '../fragments';

export const CreateImplementationTaskMutation = gql`
    mutation CreateImplementationTask(
        $recommendationId: Int!
        $task: String!
        $companyId: Int
        $isAiTasks: Boolean!
    ) {
        createImplementationTask(
            recommendation_id: $recommendationId
            task: $task
            company_id: $companyId
            is_ai_tasks: $isAiTasks
        ) {
            ...ImplementationTaskSingle
        }
    }
    ${ImplementationTaskSingleFragment}
`;

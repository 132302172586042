import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { AskTkoDetails } from '@modules/types/graphql';

export type Entity = Partial<AskTkoDetails> | null;

@DecribeEntity('AskTkoDetailsEntity')
class AskTkoDetailsEntity extends BaseEntity {
    id: number;
    title: string;
    description: string;
    userId: number;
    createdAt: string;
    parentId: number;
    queryId: string;
    isParent?: boolean;

    constructor(askTkoDetail: Entity) {
        super(askTkoDetail);

        this.id = askTkoDetail?.id ?? -1;
        this.title = askTkoDetail?.title ?? '';
        this.description = askTkoDetail?.description ?? '';
        this.userId = askTkoDetail?.user_id ?? -1;
        this.createdAt = askTkoDetail?.created_at ?? '';
        this.parentId = askTkoDetail?.parent_id ?? -1;
        this.queryId = askTkoDetail?.query_id ?? '';
        this.isParent = askTkoDetail?.is_parent ?? false;
    }
}

export { AskTkoDetailsEntity };

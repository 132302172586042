import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import { TextField, Button } from '@modules/ui/core';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';

import { Input, MenuItem } from '@material-ui/core';
import {
    OrgProfileCreateFormValues,
    OrgProfileUpdateRequestValues,
} from '@modules/components/requests';
import {
    useOrganizationLocation,
    useOrganizationType,
    useSector,
    useWorkForce,
} from '@modules/components/hooks';
import { Autocomplete } from '@material-ui/lab';
import { useCompanies, useCompany } from '@modules/company/hooks';
import { useProjectsTableFiltersQuery } from '@modules/projects/hooks';
import { CommonHelper } from '@helpers';
import { useDebounce } from 'react-use';
import { useNavigate } from '@reach/router';

type OrgProfileFormProps<T> = FormProps<T> & {
    onCancel?: () => void;
    data?: any;
    // setWorkforceData: any;
    setOrgLocationData?: any;
    orgLocationData?: any;
    companyName?: any;
};
type MultiSelectProps = {
    // workforce?: string[];
    orgLocation?: string[];
};

const StyledForm = styled(Form)`
    width: 100%;
    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const StyledForCompnay = styled.div`
    position: relative;
`;
const StyledForCompnayInput = styled(TextField)`
    position: relative;
    width: 100%;
    &:input:focus {
        outline: none;
        box-shadow: none;
    }
`;
const StyledForCompnayLable = styled.label`
    position: absolute;
    top: -7px;
    left: 10px;
    z-index: 1;
    background-color: white;
    padding: 0 5px;
    font-size: 11px;
    letter-spacing: 1px;
    font-family: 'Montserrat', Arial, sans-serif;
`;
const initialFilters = {
    // workforce: [],
    orgLocation: [],
};

const OrgProfileForm = <T extends FormSemanticType = 'create'>(
    props: OrgProfileFormProps<
        // prettier-ignore
        T extends 'create' ? OrgProfileCreateFormValues :
        T extends 'edit' ? OrgProfileUpdateRequestValues :
        OrgProfileCreateFormValues & OrgProfileUpdateRequestValues
    >,
): React.ReactElement => {
    let {
        type,
        initialValues,
        FormWrapComponent,
        onSubmit,
        onCancel,
        data,
        orgLocationData,
        setOrgLocationData,
        companyName,
    } = props;
    const isEdit = type === 'edit';
    const navigate = useNavigate();

    const { sector, loading: sectorLoading } = useSector();
    const { organization, loading: organizationLoading } = useOrganizationType();
    const { organizationLocation } = useOrganizationLocation();
    const { workforces, loading: workforceLoading } = useWorkForce();
    const { filters, setQuery } = useProjectsTableFiltersQuery();
    const defaultFilters = CommonHelper.fillDefaultValues<MultiSelectProps>(
        filters,
        initialFilters,
    );

    const [multiSelectValue, setMultiSelectValue] = React.useState<MultiSelectProps>(
        defaultFilters,
    );

    const handleChangeField = (event: React.ChangeEvent<{ name: string; value: string }>) => {
        const { name, value } = event.target;
        setMultiSelectValue(state => ({ ...state, [name]: value }));
    };
    useDebounce(
        () => {
            const updatedQuery = { ...filters, ...multiSelectValue };
            setQuery(updatedQuery);
            setOrgLocationData(multiSelectValue?.orgLocation?.map((ele: any) => ele));
        },
        500,
        [multiSelectValue],
    );

    useEffect(() => {
        const orgLocationArray = orgLocationData?.slice(1, -1)?.split(', ') || [];
        setMultiSelectValue((state: any) => ({ ...state, orgLocation: orgLocationArray }));
    }, [orgLocationData]);
    data = multiSelectValue;

    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                numberOfEmployees: yup.number().min(1).required('Required field'),
                sector: yup.string().required('Required field'),
                organizationType: yup.string().required('Required field'),
                workforce: yup.string().required('Required field'),
            })}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {formikProps => {
                const { values, errors, handleChange, isSubmitting } = formikProps;
                return (
                    <FormWrapComponent>
                        <StyledForm>
                            <StyledForCompnay>
                                <StyledForCompnayLable htmlFor='Company Name'>
                                    Company Name
                                </StyledForCompnayLable>
                                <StyledForCompnayInput
                                    type='text'
                                    value={companyName}
                                    InputProps={{ readOnly: true }}
                                />
                            </StyledForCompnay>
                            <TextField
                                fullWidth
                                rows={4}
                                id='numberOfEmployees'
                                name='numberOfEmployees'
                                label='Number of Employees'
                                type='number'
                                error={!!errors.numberOfEmployees}
                                value={values.numberOfEmployees}
                                onChange={handleChange}
                            />
                            <TextField
                                select
                                fullWidth
                                multiline
                                id='sector'
                                name='sector'
                                label='Sector'
                                error={!!errors.sector}
                                value={values.sector}
                                onChange={handleChange}
                                disabled={sectorLoading}
                            >
                                {sector &&
                                    sector.map((sector: { id: number; name: string }) => (
                                        <MenuItem key={sector.id} value={sector.name}>
                                            {sector.name}
                                        </MenuItem>
                                    ))}
                            </TextField>

                            <TextField
                                select
                                fullWidth
                                multiline
                                id='organizationType'
                                name='organizationType'
                                label='Organization Type'
                                error={!!errors.organizationType}
                                value={values.organizationType}
                                onChange={handleChange}
                                disabled={organizationLoading}
                            >
                                {organization &&
                                    organization.map(
                                        (organization: { id: number; name: string }) => (
                                            <MenuItem
                                                key={organization?.id}
                                                value={organization?.name}
                                            >
                                                {organization?.name}
                                            </MenuItem>
                                        ),
                                    )}
                            </TextField>

                            <TextField
                                select
                                fullWidth
                                multiline
                                id='orgLocation'
                                name='orgLocation'
                                label='Organization Location'
                                value={multiSelectValue?.orgLocation}
                                SelectProps={{
                                    multiple: true,
                                }}
                                onChange={handleChangeField}
                            >
                                {organizationLocation &&
                                    organizationLocation?.map(
                                        (orgLocation: { id: number; name: string }) => {
                                            return (
                                                <MenuItem
                                                    key={orgLocation?.id}
                                                    value={orgLocation?.name}
                                                >
                                                    {orgLocation?.name}
                                                </MenuItem>
                                            );
                                        },
                                    )}
                            </TextField>

                            <TextField
                                select
                                fullWidth
                                multiline
                                id='workforce'
                                name='workforce'
                                label='Workforce'
                                error={!!errors.workforce}
                                value={values?.workforce}
                                disabled={workforceLoading}
                                onChange={handleChange}
                            >
                                {workforces &&
                                    workforces?.map((workforce: { id: number; name: string }) => (
                                        <MenuItem key={workforce?.id} value={workforce?.name}>
                                            {workforce?.name}
                                        </MenuItem>
                                    ))}
                            </TextField>

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    Save
                                </SubmitButton>

                                <SubmitButton
                                    variant='text'
                                    disabled={isSubmitting}
                                    onClick={onCancel}
                                >
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    </FormWrapComponent>
                );
            }}
        </ExtendedFormik>
    );
};

export { OrgProfileForm };

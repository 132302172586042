import React, { useEffect, useMemo, useState } from 'react';

import { ListStyled } from '@modules/layout/styled';

import { ReportPrioritizedRoadmapList } from '@modules/roadmap/organisms';
import { PrioritizedRoadmapEntity } from '@modules/roadmap/entities';
import { ProjectEntity } from '@modules/projects/entities';

type ReportComponentVIewProps = {
    prioritizedRoadmap: PrioritizedRoadmapEntity;
    project: ProjectEntity;
    // assessmentMap: Map<number, ComponentAssessmentEntity>;
};

export const ReportRiskVIew = (props: ReportComponentVIewProps): React.ReactElement => {
    const { prioritizedRoadmap, project } = props;
    const [roadmapList, setRoadmapList] = useState<any>({});

    useEffect(() => {
        const newLengths: any = {};

        for (const key in prioritizedRoadmap) {
            if (Array.isArray(prioritizedRoadmap[key])) {
                const arrayLength = prioritizedRoadmap[key].length;

                if (arrayLength > 0) {
                    newLengths[key] = arrayLength;
                } else {
                    newLengths[key] = null;
                }
            }
        }
        setRoadmapList(newLengths);
    }, [prioritizedRoadmap]);

    return (
        <ListStyled.Container className={'no-top-offset'}>
            {roadmapList !== null ? (
                <ReportPrioritizedRoadmapList
                    prioritizedRoadmap={prioritizedRoadmap}
                    project={project}
                    withStatus={true}
                />
            ) : (
                <ListStyled.Empty>Not found prioritized roadmap recommendations</ListStyled.Empty>
            )}
        </ListStyled.Container>
    );
};

import { ContactMutationVariables } from '@modules/types/graphql';

export type ContactFormValues = {
    name: string;
    email: string;
    phone: string;
    description: string;
};

class ContactCreateRequest {
    name: ContactMutationVariables['name'];
    email: ContactMutationVariables['email'];
    phone: ContactMutationVariables['phone'];
    description: ContactMutationVariables['description'];

    constructor(values: ContactFormValues) {
        this.name = values.name;
        this.email = values.email;
        this.phone = values.phone;
        this.description = values.description;
    }
}

export { ContactCreateRequest };

import { BaseEntity, Entity as DecribeEntity, RelationList } from '@lib/entity';

import { GapEntityList } from '@modules/custom-components/entities';

import type { GapEntity } from '@modules/custom-components/entities';
import type { CapabilityMaturityLevelEnum } from '@modules/capabilities/constants';
import type { CapabilityLevel } from '@modules/types/graphql';

export type Entity = Partial<CapabilityLevel> | null;

@DecribeEntity('CapabilityLevelEntity')
class CapabilityLevelEntity extends BaseEntity {
    id: number;
    name: string;
    description: string;
    level: number;
    companyId: string | null;

    @RelationList(() => GapEntityList)
    gaps: (GapEntity | null)[];

    constructor(capabilityLevel: Entity) {
        super(capabilityLevel);

        this.id = capabilityLevel?.id ?? -1;
        this.name = capabilityLevel?.name ?? '';
        this.description = capabilityLevel?.description ?? '';
        this.level = capabilityLevel?.level ?? 0;
        this.companyId = capabilityLevel?.company_id ?? null;
    }

    isPredefined() {
        return this.companyId === null;
    }

    getMaturityLevel() {
        const result = this.name as CapabilityMaturityLevelEnum;

        return result;
    }

    getCapabilityLevelDefinitionId() {
        // TODO: temp solution, while backend not send definition id
        const id = this.level + 1;

        return id;
    }
}

export { CapabilityLevelEntity };

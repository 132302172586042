import { gql } from '@apollo/client';

import { TkoScopeFragment } from '../fragments';

export const GetTkoScopeQuery = gql`
    query listTkoScopes {
        listTkoScopes {
            ...TkoScope
        }
    }
    ${TkoScopeFragment}
`;

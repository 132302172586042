import React from 'react';
import styled from 'styled-components';

import { Modal } from '@modules/ui/core';
import { AssignAssessmentCapabilityToOperatorForm } from '@modules/assessment/organisms';

import type { ModalProps } from '@modules/ui/core';

type AssignAssessmentCapabilityToOperatorFormModalProps = Omit<ModalProps, 'title' | 'children'> & {
    capabilityAssessmentId: number;
    componentAssessmentId: number;
};

const FormWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-width: 625px;
    min-height: 56px;
    padding: 8px 0;
    overflow: auto;
`;

const AssignAssessmentCapabilityToOperatorFormModal = (
    props: AssignAssessmentCapabilityToOperatorFormModalProps,
): React.ReactElement => {
    const { capabilityAssessmentId, componentAssessmentId, onClose, ...otherProps } = props;

    const displayTitle = 'Assign operator to review this capability';

    return (
        <Modal title={displayTitle} onClose={onClose} {...otherProps}>
            <AssignAssessmentCapabilityToOperatorForm
                capabilityAssessmentId={capabilityAssessmentId}
                componentAssessmentId={componentAssessmentId}
                FormWrapComponent={FormWrap}
                onCancel={onClose}
            />
        </Modal>
    );
};

export { AssignAssessmentCapabilityToOperatorFormModal };

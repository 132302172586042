import styled from 'styled-components';

export const PageStyled = {
    Header: styled.div`
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        margin: 10px 0px;
    `,

    HeaderTitle: styled.div`
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        max-width: 350px;

        > h3 {
            font-size: 2.4rem;
            font-weight: 700;
        }

        > p {
            margin-top: 16px;
            font-size: 1.4rem;
            line-height: 21px;
        }
    `,

    HeaderSubtitle: styled.div`
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-left: 36px;
        max-width: 700px;

        > p {
            font-size: 1.4rem;
            line-height: 21px;
        }
    `,

    HeaderBlock: styled.div`
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    `,

    Bridge: styled.div`
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        margin-top: 28px;
    `,

    Main: styled.div`
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 28px;

        @media print {
            margin-top: 0;
        }
    `,
};

type AddPayload = {
    toHead?: boolean;
};

class ArrayHelper {
    public static insert = <T = any>(array: T[], element: T, idx: number): T[] => {
        const result = [...array.slice(0, idx), element, ...array.slice(idx + 1)];

        return result;
    };

    public static add = <T = any>(array: T[], element: T, payload?: AddPayload): T[] => {
        const toHead = !!payload?.toHead;

        const result = toHead ? [element, ...array] : [...array, element];

        return result;
    };

    public static remove = <T = any>(array: T[], condition: any, by?: string): T[] => {
        let result: T[] = [];

        if (!by) {
            result = array.filter((_, index) => index !== condition);
        } else {
            result = array.filter(item => item[by as string] !== condition);
        }

        return result;
    };
}

export { ArrayHelper };

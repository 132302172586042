import { gql } from '@apollo/client';

import { ComponentSingleFragment } from '../fragments';

export const UpdateComponentMutation = gql`
    mutation UpdateComponent($componentId: Int!, $assessmentTypeId: Int!, $name: String!) {
        updateComponent(
            component_id: $componentId
            assessment_type_id: $assessmentTypeId
            name: $name
        ) {
            ...ComponentSingle
        }
    }
    ${ComponentSingleFragment}
`;


export enum RecommendationStatus {
  Accepted = 'Accepted',
  Modified = 'Modified',
  Rejected = 'Rejected',
  Acknowledged = 'Acknowledged',
}

export const roadmapIgnoredStatusList: string[] = [RecommendationStatus.Rejected, RecommendationStatus.Acknowledged];

export function isRoadmapStatusAllowed(status?: string | RecommendationStatus): boolean {
  return Boolean(status && !roadmapIgnoredStatusList.includes(status));
}

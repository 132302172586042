import { gql } from '@apollo/client';

export const UpdateAsset = gql`
    mutation UpdateAsset($input: UpdateAssetInput!) {
        updateAsset(input: $input) {
            id
            name
        }
    }
`;

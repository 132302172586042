import React, { useMemo } from 'react';

import { ListStyled } from '@modules/layout/styled';
import { Collapse } from '@material-ui/core';
import { GapByRisk, GapCapabilityAssessment } from '@modules/assessment/gap-by-risk';
import { UserEntity } from '@modules/users/entities';
import { CapabilityAssessmentLog } from '@modules/types/graphql';
import { format } from 'date-fns';
import { ReportCapabilityLog } from '@modules/assessment/organisms/report-components-list/report-capability-log';

type ReportRiskInnerItemProps = {
    gapAssessment: GapCapabilityAssessment;
    openComponent: boolean;
    projectStatus?: boolean;
};

export const ReportRiskInnerItem = (props: ReportRiskInnerItemProps): React.ReactElement => {
    const { gapAssessment, openComponent, projectStatus } = props;
    const assessment = gapAssessment.componentAssessment;

    let lastItem: CapabilityAssessmentLog = gapAssessment.capabilityAssessment.log[0];
    let history: CapabilityAssessmentLog[] =
        gapAssessment.capabilityAssessment?.log ?? ([] as CapabilityAssessmentLog[]);

    return (
        <Collapse in={openComponent} timeout='auto' unmountOnExit>
            <ListStyled.Container>
                <div className={'container-flex'}>
                    <div className={'col-info'}>
                        {assessment.componentOwners.length ? (
                            <>
                                <h3>Component owner:</h3>
                                <p className={'text-offset'}>
                                    {assessment.componentOwnersToString()}
                                </p>
                            </>
                        ) : null}

                        {assessment.hasOperator() ? (
                            <>
                                <h3>Operator:</h3>
                                {assessment.operators.map((operator: UserEntity) => (
                                    <p className={'text-offset'}>{operator.getFullName()}</p>
                                ))}
                            </>
                        ) : null}

                        <p className={'text-offset'}>
                            <b>Status:</b> {assessment.status ?? ''}
                        </p>
                        <p className={'block-offset'}>
                            <b>Weight:</b> {assessment.weight ?? ''}
                        </p>
                    </div>

                    {lastItem && (
                        <div className={'col-table-full-width'}>
                            <p className={'block-offset'}>
                                <b>Last action:</b> {lastItem.action} at{' '}
                                {format(new Date(lastItem.action_timestamp), 'dd.MM.yyyy hh:mm')} by{' '}
                                {lastItem.user?.first_name} {lastItem.user?.last_name}
                            </p>
                            <ReportCapabilityLog
                                projectStatus={projectStatus as boolean}
                                history={history}
                            />
                        </div>
                    )}
                </div>
            </ListStyled.Container>
        </Collapse>
    );
};

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { CapabilityEntityList } from '@modules/capabilities/entities';
import { GetCapabilitiesQuery } from '@modules/custom-components/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetCapabilitiesQueryType,
    GetCapabilitiesQueryVariables,
} from '@modules/types/graphql';

const useCapabilities = (
    options?: QueryHookOptions<GetCapabilitiesQueryType, GetCapabilitiesQueryVariables>,
) => {
    const queryResult = useQuery<GetCapabilitiesQueryType, GetCapabilitiesQueryVariables>(
        GetCapabilitiesQuery,
        options,
    );

    const { entities: capabilities, count } = useEntity(
        () => new CapabilityEntityList(queryResult.data?.listCapabilities ?? null),
        [queryResult.data],
    );

    const result = {
        capabilities,
        count,
        ...queryResult,
    };

    return result;
};

export { useCapabilities };

import React from 'react';
import styled from 'styled-components';

import { Modal } from '@modules/ui/core';
import { AssignAssessmentToOperatorForm } from '@modules/assessment/organisms';

import type { ModalProps } from '@modules/ui/core';
import { ProjectEntity } from '@modules/projects/entities';

type AssignAssessmentToOperatorFormModalProps = Omit<ModalProps, 'title' | 'children'> & {
    componentAssessmentId: number;
    project: ProjectEntity;
};

const FormWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-width: 625px;
    min-height: 56px;
    padding: 8px 0;
    overflow: auto;
`;

const AssignAssessmentToOperatorFormModal = (
    props: AssignAssessmentToOperatorFormModalProps,
): React.ReactElement => {
    const { componentAssessmentId, project, onClose, ...otherProps } = props;

    const displayTitle = 'Assign operator to review this survey';

    return (
        <Modal title={displayTitle} onClose={onClose} {...otherProps}>
            <AssignAssessmentToOperatorForm
                componentAssessmentId={componentAssessmentId}
                project={project}
                FormWrapComponent={FormWrap}
                onCancel={onClose}
            />
        </Modal>
    );
};

export { AssignAssessmentToOperatorFormModal };

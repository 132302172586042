import React, { useEffect, useMemo } from 'react';

import { ListStyled } from '@modules/layout/styled';
import { Doughnut, Bar } from 'react-chartjs-2';

import type { ComponentReportEntity } from '@modules/assessment/entities';
import { useMaturityLevels } from '@modules/shared/hooks';
import styled from 'styled-components';
import {
    PrioritizedRoadmapEntity,
    RoadmapRecommendationEntity,
    RoadmapReportEntity,
} from '@modules/roadmap/entities';
import { roadmapRecommendationTitle } from '@helpers';
import { UserListCard } from '@modules/assessment/user-list-card';
import { ProjectEntity } from '@modules/projects/entities';
import {
    getCountByRisk,
    getRecommendationStatus,
    getStatusByComponent,
} from '@modules/roadmap/organisms/report-roadmap/report-general-chart-data';
import {
    getTopList,
    getComponentMaturityData,
    getCountByComponent,
    useComponentMaturityChartConfig,
    useCountByComponentConfig,
    useCountByRiskConfig,
    useMaturityLevelConfig,
    useStatusByComponentConfig,
    useRecommendationStatusConfig,
    useRecommendationCompletionConfig,
} from '@modules/assessment/organisms/report-components-list/report-general-chart-data';
import { RatingPlateCursor } from '@modules/registry/organisms/rating-card/rating-plate-cursor';
import { format } from 'date-fns';
import { ComponentAssessmentEntity } from '@modules/assessment/entities';
import {
    RecommendationByRisk,
    RecommendationCapabilityAssessment,
    RecommendationViewData,
} from '@modules/roadmap/recommendation-by-risk';
import { RecommendationAssessment } from '@modules/roadmap/recommendation-assessment-roadmap-report';
import { GapCapabilityAssessment, GapViewData } from '@modules/assessment/gap-by-risk';

type ReportComponentsListProps = {
    project: ProjectEntity;
    prioritizedRoadmap: PrioritizedRoadmapEntity;
    recommendationViewDataRaw: RecommendationViewData;
    componentsAssessmentRawList: ComponentAssessmentEntity[];
    isGeneralEditAllowed: boolean;
    activeTab: number;
};

const BarChartInfoWidth = styled(ListStyled.BarChartInfo)<{ count: number }>`
    width: ${p => p.count * 100}px;
`;

export const ReportGeneralInfo = (props: ReportComponentsListProps): React.ReactElement => {
    const {
        prioritizedRoadmap,
        project,
        recommendationViewDataRaw: recommendationViewData,
        componentsAssessmentRawList: componentsAssessmentList,
        isGeneralEditAllowed,
        activeTab,
    } = props;

    const statusByComponent = getStatusByComponent(
        componentsAssessmentList,
        recommendationViewData,
    );
    const statusByComponentConfig = useStatusByComponentConfig(statusByComponent);

    const recommendationStatus = getRecommendationStatus(
        componentsAssessmentList,
        recommendationViewData,
    );
    const recommendationStatusConfig = useRecommendationStatusConfig(recommendationStatus);

    const listResult = getTopList(recommendationViewData as any);
    const { allList, topList } = listResult as {
        allList: RecommendationAssessment[];
        topList: RecommendationAssessment[];
    };

    const overalCount: number = allList.length;
    const countByComponent: Array<[number, string]> = getCountByComponent(
        componentsAssessmentList,
        (recommendationViewData.componentAssessmentListMap as unknown) as Map<
            number,
            GapCapabilityAssessment[]
        >,
    );
    const countByRisk: Array<[number, string]> = getCountByRisk(prioritizedRoadmap);
    const countByRiskConfig = useCountByRiskConfig(countByRisk);
    const countByComponentConfig = useCountByComponentConfig(countByComponent);
    const recommendationCompletionConfig = useRecommendationCompletionConfig(recommendationStatus);
    // const maturityLevelChartConfig = useMaturityLevelConfig(project.maturityLevel);

    // const { maturityLevels, loading: isMaturityLoading } = useMaturityLevels();
    // const componentMaturityResult = getComponentMaturityData(maturityLevels, componentsAssessmentList);
    // const componentByMaturity: Array<[number, string]> = componentMaturityResult.componentByMaturity
    // const maturityMap: Map<number, string> = componentMaturityResult.maturityMap;
    // const componentMaturityBarChartConfig = useComponentMaturityChartConfig(componentByMaturity, maturityMap);

    // useEffect(() => {});

    return (
        <ListStyled.HeaderSecondary>
            <ListStyled.UserInfo>
                {project.stakeholder.exists() ? (
                    <UserListCard
                        title={'Stakeholder'}
                        projectId={project.id}
                        isEditAllowed={isGeneralEditAllowed}
                        userList={[
                            {
                                id: project?.stakeholder?.id,
                                fullName: project.stakeholder.getFullName(),
                                role: { id: 'ST', name: 'Stakeholder' },
                            },
                        ]}
                    />
                ) : null}

                {project.programManager ? (
                    <UserListCard
                        title={'Project Manager'}
                        projectId={project.id}
                        isEditAllowed={isGeneralEditAllowed}
                        userList={[
                            {
                                id: project?.programManager?.id,
                                fullName: project.programManager.getFullName(),
                                role: { id: 'PM', name: 'Project Manager' },
                            },
                        ]}
                    />
                ) : null}
            </ListStyled.UserInfo>

            {activeTab === 0 && (
                <>
                    {/*<ListStyled.PieArcChartInfo>*/}
                    {/*  <p className={'chart-title'}>Program Maturity</p>*/}
                    {/*  <Doughnut*/}
                    {/*    options={maturityLevelChartConfig.pieChartOptions}*/}
                    {/*    data={maturityLevelChartConfig.pieChartData}*/}
                    {/*    className={'chart-align'}*/}
                    {/*  />*/}
                    {/*  <RatingPlateCursor className='rating-cursor' rotateDeg={maturityLevelChartConfig.degree} />*/}
                    {/*  <div className='rating-score'>{project.maturityLevel.name}</div>*/}
                    {/*</ListStyled.PieArcChartInfo>*/}
                    {/*<BarChartInfoWidth count={componentMaturityBarChartConfig.barChartData.datasets[0].data.length}>*/}
                    {/*  <p>Component Maturity</p>*/}
                    {/*  {!isMaturityLoading && (*/}
                    {/*    <Bar*/}
                    {/*      options={componentMaturityBarChartConfig.barChartOptions}*/}
                    {/*      data={componentMaturityBarChartConfig.barChartData}*/}
                    {/*    />*/}
                    {/*  )}*/}
                    {/*</BarChartInfoWidth>*/}

                    <BarChartInfoWidth
                        count={statusByComponentConfig.barChartData.datasets[0].data.length}
                    >
                        <p>Statuses By Component</p>
                        <Bar
                            options={statusByComponentConfig.barChartOptions}
                            data={statusByComponentConfig.barChartData}
                        />
                    </BarChartInfoWidth>
                    <ListStyled.PieChartInfo>
                        <p className={'chart-title'}>Recommendations By Component</p>
                        <Doughnut
                            options={countByComponentConfig.pieChartOptions}
                            data={countByComponentConfig.pieChartData}
                        />
                    </ListStyled.PieChartInfo>
                </>
            )}

            {activeTab === 1 && (
                <>
                    <ListStyled.Top5TableBlock>
                        <p>Top Recommendations</p>
                        <ListStyled.Top5Table>
                            <div key={0} className={'top5table-header'}>
                                <ListStyled.Top5TableGap>Recommendation</ListStyled.Top5TableGap>
                                <ListStyled.Top5TableRiskLevel>
                                    Risk Level
                                </ListStyled.Top5TableRiskLevel>
                                <ListStyled.Top5TableComponent>
                                    Component
                                </ListStyled.Top5TableComponent>
                                <ListStyled.Top5TableComponentOwner>
                                    Component Owner
                                </ListStyled.Top5TableComponentOwner>
                            </div>
                            {topList.map(recommendationReport => {
                                const recommendation = recommendationReport.roadmapRecommendation as RoadmapRecommendationEntity;

                                return (
                                    <div key={recommendation.recommendation.id}>
                                        <ListStyled.Top5TableGap>
                                            {roadmapRecommendationTitle(recommendation)}
                                        </ListStyled.Top5TableGap>
                                        <ListStyled.Top5TableRiskLevel>
                                            {recommendation.gap.riskLevel}
                                        </ListStyled.Top5TableRiskLevel>
                                        <ListStyled.Top5TableComponent>
                                            {
                                                recommendationReport.componentAssessment.component
                                                    .name
                                            }
                                        </ListStyled.Top5TableComponent>
                                        <ListStyled.Top5TableComponentOwner>
                                            {recommendationReport.componentAssessment.componentOwnersToString()}
                                        </ListStyled.Top5TableComponentOwner>
                                    </div>
                                );
                            })}
                        </ListStyled.Top5Table>
                    </ListStyled.Top5TableBlock>
                    <ListStyled.PieChartInfo>
                        <p className={'chart-title'}>Recommendations By Risk Level</p>
                        <Doughnut
                            options={countByRiskConfig.pieChartOptions}
                            data={countByRiskConfig.pieChartData}
                        />
                    </ListStyled.PieChartInfo>
                </>
            )}

            <ListStyled.PieChartInfo>
                <p className={'chart-title'}>Recommendation Status</p>
                <Doughnut
                    options={recommendationStatusConfig.pieChartOptions}
                    data={recommendationStatusConfig.pieChartData}
                />
            </ListStyled.PieChartInfo>
            <ListStyled.PieArcChartInfo>
                <p className={'chart-title'}>Recommendation Completion</p>
                <Doughnut
                    options={recommendationCompletionConfig.pieChartOptions}
                    data={recommendationCompletionConfig.pieChartData}
                    className={'chart-align'}
                />
                <RatingPlateCursor
                    className='rating-cursor'
                    rotateDeg={recommendationCompletionConfig.degree}
                />
                <div className='rating-score'>
                    {recommendationCompletionConfig.score.toFixed(0)}%
                </div>
            </ListStyled.PieArcChartInfo>
            <ListStyled.TextContent>
                <p className={'text-offset font-size-normal'}>
                    <b>Number of Recommendations:</b> {overalCount}
                </p>
                <p className={'text-offset font-size-normal'}>
                    <b>Date/Time:</b> {format(new Date(), 'MM/dd/yyyy - hh:mm')}
                </p>
            </ListStyled.TextContent>
        </ListStyled.HeaderSecondary>
    );
};

import { gql } from '@apollo/client';
import { ImplementationSubTaskSingleFragment } from '../fragments';

export const UpdateImplementationSubTaskMutation = gql`
    mutation UpdateSubTask($taskId: Int!, $subTaskId: Int!, $subTask: String!) {
        updateSubTask(task_id: $taskId, sub_task_id: $subTaskId, sub_task: $subTask) {
            ...SubTaskSingle
        }
    }
    ${ImplementationSubTaskSingleFragment}
`;

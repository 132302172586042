import {
    CapabilityAssessmentEntity,
    ComponentAssessmentEntity,
} from '@modules/assessment/entities';
import { ScoredGapEntity } from '@modules/roadmap/entities';

export type GapAssessment = {
    componentAssessment: ComponentAssessmentEntity;
    capabilityAssessment: CapabilityAssessmentEntity;
    scoredGap: ScoredGapEntity;
};

export function getGapByAssessment(
    assessmentList: ComponentAssessmentEntity[],
): Map<number, GapAssessment> {
    const result: Map<number, GapAssessment> = new Map();

    assessmentList
        // add filter if needed to be align with assessment report results
        // .filter(value => value.maturityLevel)
        .forEach(componentAssessment => {
            componentAssessment.capabilities.forEach(capabilityAssessment => {
                capabilityAssessment?.scoredGaps.forEach(scoredGap => {
                    if (!scoredGap?.gap.id) {
                        return;
                    }

                    result.set(scoredGap?.gap.id ?? -1, {
                        componentAssessment,
                        capabilityAssessment,
                        scoredGap,
                    });
                });
            });
        });

    result.delete(-1);
    return result;
}

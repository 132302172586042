import { gql } from '@apollo/client';

export const AssignRecommendationToOperatorMutation = gql`
    mutation AssignRecommendationToOperator($recommendationId: Int!, $userId: Int!) {
        assignRecommendationToOperator(
            roadmap_recommendation_id: $recommendationId
            user_id: $userId
        ) {
            void_result
        }
    }
`;

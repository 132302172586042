import { gql } from '@apollo/client';

import { GapSingleFragment } from '../fragments';

export const GetGapsQuery = gql`
    query GetGaps($capabilityLevelId: Int, $search: String, $companyId: Int, $projectId: Int) {
        listGaps(
            capability_level_id: $capabilityLevelId
            filter: $search
            company_id: $companyId
            project_id: $projectId
        ) {
            ...GapSingle
        }
    }
    ${GapSingleFragment}
`;

import React from 'react';
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';

import { Dialog } from '@modules/ui/core';

type DialogUserEditProps = {
    onUpdate: (userId: any) => void;
    userId: number;
};

const StyledDialog = styled(Dialog)`
    min-height: initial;
`;

const DialogUserEdit = (props: DialogUserEditProps): React.ReactElement => {
    const { onUpdate, userId } = props;

    return (
        <StyledDialog
            title='Are you sure you want to Edit this user?'
            acceptText='Update'
            // onAccept={onDelete}
        >
            {dialogProps => {
                const { handleOpen } = dialogProps;

                return <MenuItem onClick={() => onUpdate(userId)}>Edit</MenuItem>;
            }}
        </StyledDialog>
    );
};

export { DialogUserEdit };

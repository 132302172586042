import React from 'react';
import styled from 'styled-components';
import { useMountedState } from 'react-use';
import { Auth } from 'aws-amplify';
import { useApolloClient, useMutation } from '@apollo/client';
import { useLocation, useNavigate } from '@reach/router';
import { Divider, makeStyles, Menu, MenuItem, Popover } from '@material-ui/core';

import { Button } from '@modules/ui/core';
import { gray, blue } from '@modules/ui/colors';
import { routes } from '@config/routes';
import { useAuth } from '@modules/auth/context';
import { LoginLogoutComponent } from '@helpers';
import { AddUsageTimeMutationType, AddUsageTimeMutationVariables } from '@modules/types/graphql';
import { AddUsageTimeMutation } from '@modules/users/graphql/mutations';
import { AddUsageFormRequest } from '@modules/users/requests';
import { UserEntity } from '@modules/users/entities';
import { useCompany } from '@modules/company/hooks';

type HeaderUserInfoProps = {
    fullName: string;
    email: string;
    roleAbbrevation: string;
    currentUserDetail: UserEntity;
};

const usePopoverStyles = makeStyles({
    paper: {
        position: 'absolute',
        top: '65px !important',
        maxWidth: 200,
        width: '100%',
        background: '#fff',
        maxHeight: 210,
        borderRadius: 10,
        zIndex: 999,
        transition: '0.2s cubic-bezier(0.6, -0.28, 0.74, 0.05) !important',
    },
});

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 13px 24px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: ${gray[90]};
    }
`;

const ProfileLogo = styled.div`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: ${blue[100]};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #fff;
    font-weight: 800;
    text-transform: uppercase;
`;

const Main = styled.div`
    margin-left: 12px;

    > h3 {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 16px;
    }

    > p {
        margin-top: 4px;
        font-size: 1.2rem;
        line-height: 13px;
    }
`;

const HeaderUserInfo = (props: HeaderUserInfoProps): React.ReactElement => {
    const { fullName, email, roleAbbrevation, currentUserDetail } = props;

    const apolloClient = useApolloClient();
    const isMounted = useMountedState();

    const location = useLocation();
    const navigate = useNavigate();
    const { company, loading: loadingCompany } = useCompany({
        skip: !currentUserDetail?.companyId,
        variables: { id: currentUserDetail?.companyId as number },
    });
    const userId = currentUserDetail.id;

    const popoverClasses = usePopoverStyles();

    const { setAuthenticated } = useAuth();

    const [isSubmitting, setSubmitting] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [addUsageTime] = useMutation<AddUsageTimeMutationType, AddUsageTimeMutationVariables>(
        AddUsageTimeMutation,
    );

    const handleTogglePopover = (event: React.SyntheticEvent<HTMLDivElement>): void =>
        setAnchorEl(anchorEl ? null : event.currentTarget);

    const handleClosePopover = (): void => setAnchorEl(null);

    const handleClickLogout = async (): Promise<void> => {
        setSubmitting(true);
        const totalTime = LoginLogoutComponent('logout').toFixed();
        try {
            let variables = new AddUsageFormRequest({ userId: userId, dailyUsage: totalTime });
            const { data: addUsageTimeData } = await addUsageTime({
                variables,
            });

            await Auth.signOut();

            setAuthenticated(false);
            localStorage.removeItem('modalStatus');

            await apolloClient.clearStore();
            await navigate(routes.index.path);
        } catch (e) {
            console.log('Error in signOut: ', e);
        } finally {
            if (isMounted()) {
                setSubmitting(false);
            }
        }
    };

    const findNameTitle = (name: string) => {
        let [x, y] = name?.split(' ');
        return `${x[0]}${y[0]}`;
    };

    React.useEffect(() => {
        if (anchorEl) {
            handleClosePopover();
        }
    }, [location.pathname]);

    return (
        <>
            <Root onClick={handleTogglePopover}>
                <ProfileLogo>{roleAbbrevation === 'ALL' ? '' : roleAbbrevation}</ProfileLogo>

                <Main>
                    <h3>{fullName}</h3>
                    <p>{email}</p>
                </Main>
            </Root>
            {!loadingCompany &&
            
            <Popover
                id='header-user-info'
                open={!!anchorEl}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: -15,
                    horizontal: -24,
                }}
                classes={popoverClasses}
                onClose={handleClosePopover}
            >
                <MenuItem style={{ overflow: 'hidden', whiteSpace: 'normal', textOverflow: 'ellipsis'}}>{company.name}</MenuItem>
                <Divider />
                <MenuItem>
                    <Button style={{width: '100%'}} size='extra-small' loading={isSubmitting} onClick={handleClickLogout}>
                        Logout
                    </Button>
                </MenuItem>
            </Popover>
            }
        </>
    );
};

export { HeaderUserInfo };

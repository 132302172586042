import React, {useState} from 'react';
import styled from 'styled-components';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';

import { white, gray } from '@modules/ui/colors';

import {RecommendationAssessmentRoadmapReport} from '@modules/roadmap/recommendation-assessment-roadmap-report';
import {TableBody, TableCell, TableRow} from '@material-ui/core';
import {ProjectEntity} from '@modules/projects/entities';
import {ReportPrioritizedRoadmapRow} from '@modules/roadmap/organisms/report-prioritized-roadmap-list/report-prioritized-roadmap-row';
import {ReportPrioritizedRoadmapInnerItem} from '@modules/roadmap/organisms/report-prioritized-roadmap-list/report-prioritized-roadmap-inner-item';

type ReportPrioritizedRoadmapListItemProps = {
    recommendation: RecommendationAssessmentRoadmapReport;
    project: ProjectEntity;
    colSpan: number;
    withStatus?: boolean;
    index?: number;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 16px;
    background-color: ${gray[60]};
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);
`;

const Title = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 30%;
    margin-right: 16px;
    font-size: 1.8rem;
    font-weight: 700;
`;

const Description = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(50% - 16px);
    margin-right: 16px;
    padding: 12px;
    word-break: break-word;
    background-color: ${white[100]};

    > h5 {
        width: 100%;
        color: ${gray[20]};
        font-size: 1.6rem;
        font-weight: 400;
    }
`;

const Status = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: calc(20% - 16px);
    margin-left: 16px;
    background-color: ${white[100]};

    > * {
        display: inline-flex;
        font-size: 1.6rem;

        &:not(:last-child) {
            margin-right: 6px;
        }
    }
`;

const ReportPrioritizedRoadmapListItem = (
    props: ReportPrioritizedRoadmapListItemProps,
): React.ReactElement => {
    const { recommendation: recommendationExtra, withStatus, index, colSpan, project } = props;

    let roadmapRecommendation = recommendationExtra.componentRecommendation.recommendation;
    let isImplementation = project.isImplementation();

    const [openComponent, setOpenComponent] = React.useState(false);

    function onRowToggle() {
        setOpenComponent(!openComponent);
    }

    return (
      <>
          {isImplementation
            ? (
                <>
                    <TableRow onClick={onRowToggle}
                      // key={roadmapRecommendation.id}
                              className="draggable-table-row row-expand"
                    >
                        <ReportPrioritizedRoadmapRow
                          recommendation={recommendationExtra}
                          project={project}
                          withStatus={withStatus}
                        />
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
                            <ReportPrioritizedRoadmapInnerItem
                              openComponent={openComponent}
                              recommendationExtra={recommendationExtra}
                            />
                        </TableCell>
                    </TableRow>
                </>
            )
            : (
              <Draggable index={index as number} draggableId={roadmapRecommendation?.id.toString()}>
                  {(provided, snapshot) => (
                    <>
                        <TableRow onClick={onRowToggle}
                          // key={roadmapRecommendation.id}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}

                                  className="draggable-table-row"
                        >
                            <ReportPrioritizedRoadmapRow
                              recommendation={recommendationExtra}
                              project={project}
                              withStatus={withStatus}
                            />
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
                                <ReportPrioritizedRoadmapInnerItem
                                  openComponent={openComponent}
                                  recommendationExtra={recommendationExtra}
                                />
                            </TableCell>
                        </TableRow>
                    </>
                  )}
              </Draggable>
            )
          }
      </>
    );
};

export { ReportPrioritizedRoadmapListItem };

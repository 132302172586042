import React, { useState } from 'react';
import styled from 'styled-components';
import {
    Card,
    CardContent,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
} from '@material-ui/core';
import { SortDirectionEnum, SortHelper } from '@helpers';
import {
    getRiskAssessmentLevelLabel,
    getRiskLevelLabelByScore,
} from '@modules/shared/constants/risk-level';

const StyledTableWrapper = styled.div`
    max-height: 355px;
    overflow-y: auto;
`;

const TopAssetsCard = (props: any): React.ReactElement => {
    const { title, type, filteredAssets } = props;

    function getRatingName(score: number, typeData: string) {
        let riskLabelKey = getRiskLevelLabelByScore(Number(score), typeData);
        let riskLabel;
        switch (typeData) {
            case 'risk':
                riskLabel = getRiskAssessmentLevelLabel(riskLabelKey, typeData);
                break;
            case 'impact':
                riskLabel = getRiskAssessmentLevelLabel(riskLabelKey, typeData);
                break;
            case 'vulnerability':
                riskLabel = riskLabelKey;
                break;
            case 'threat':
                riskLabel = riskLabelKey;
                break;
        }
        return riskLabel;
    }

    return (
        <Card>
            <CardContent>
                <Typography gutterBottom variant='h4'>
                    {title}
                </Typography>

                <StyledTableWrapper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Asset</TableCell>
                                <TableCell>Rating</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredAssets?.length ? (
                                SortHelper.sortBy(
                                    `${type}Score`,
                                    SortDirectionEnum.desc,
                                    filteredAssets.map((asset: any) => asset.original).slice(0, 10),
                                ).map((asset: any) => (
                                    <TableRow key={asset?.id}>
                                        <TableCell>{asset?.name}</TableCell>
                                        <TableCell>
                                            {getRatingName(Number(asset[`${type}Score`]), type)}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={2}>No assets</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </StyledTableWrapper>
            </CardContent>
        </Card>
    );
};

export { TopAssetsCard };

import React from 'react';
import styled, { css } from 'styled-components';

import { black, white } from '@modules/ui/colors';
import {
    CapabilityMaturityLevelEnum,
    capabilityLevelColors,
} from '@modules/capabilities/constants';
import { capabilityLevelProgress as levelProgress } from '@modules/shared/constants/risk-level';

type CapabilityMaturityLevelProps = {
    current: CapabilityMaturityLevelEnum;
    className?: string;
};

const Root = styled.div`
    display: flex;
    flex-flow: column nowrap;

    border: 1px solid #f2f2f2;
    border-radius: 4px;
`;

const progressWidth = 185;
const minWidth = 25;
const Progress = styled.div<{ color?: string; width?: number }>`
    ${p => css`
        color: ${((p.width ?? 0) / 100) * 185 < minWidth ? black[100] : white[100]};
    `}
    font-size: 1.4rem;
    border-radius: 4px 0 0 4px;
    min-width: ${progressWidth}px;

    .progress {
        ${p => css`
            padding: 8px 0;
            // min-width: ${minWidth}px;

            white-space: pre;
            text-align: center;
            font-weight: 600;

            width: ${p.width}%;
            background-color: ${p.color};
        `}
    }
`;

const Label = styled.div`
    padding: 8px 16px;
    text-transform: capitalize;
    text-align: center;
`;

const CapabilityMaturityLevel = (props: CapabilityMaturityLevelProps): React.ReactElement => {
    const { current, ...otherProps } = props;

    const color = capabilityLevelColors[current];
    const width = Number(levelProgress[current]?.replace('<', '')?.replace('%', '')?.trim() ?? 0);

    return (
        <Root {...otherProps}>
            <Progress color={color} width={width}>
                <div className={'progress'}>{levelProgress[current]}</div>
            </Progress>
            <Label>{current}</Label>
        </Root>
    );
};

export { CapabilityMaturityLevel };

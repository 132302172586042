import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { FormControlLabel, TextField } from '@material-ui/core';

import { Button, Modal } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { FormStyled } from '@modules/layout/styled';
import { Hint, TableCellText, TableCheckbox } from '@modules/layout/atoms';
import { Loader, TableRow, TableCell } from '@modules/layout/moleculas';
import { ExtendedFormik, Form, Table } from '@modules/layout/organisms';
import { useRecommendations } from '@modules/custom-components/hooks';
import { GetCapabilityAssessmentQuery } from '@modules/assessment/graphql';
import { SetRecommendationsMutation } from '@modules/roadmap/graphql';

import type { ModalProps } from '@modules/ui/core';
import type { ScoredGapEntity } from '@modules/roadmap/entities';
import type {
    SetRecommendationsMutationType,
    SetRecommendationsMutationVariables,
    UpdateRecommendationMutationType,
    UpdateRecommendationMutationVariables,
} from '@modules/types/graphql';
import {
    GetRecommendationsQuery,
    UpdateRecommendationMutation,
} from '@modules/custom-components/graphql';
import { RecommendationUpdateRequest } from '@modules/custom-components/requests';

type RoadmapRecommendationsEditFormModalProps = Omit<ModalProps, 'title' | 'children'> & {
    capabilityAssessmentId: number;
    scoredGap: ScoredGapEntity;
    companyId?: number;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const RoadmapRecommendationsEditFormModal = (
    props: RoadmapRecommendationsEditFormModalProps,
): React.ReactElement => {
    const { capabilityAssessmentId, companyId, scoredGap, open, onClose, ...otherProps } = props;
    const [recommendationGapData, setRecommendationGapData] = useState<any>([]);
    const { enqueueSuccess } = useEnqueueStacks();

    const { recommendations, loading: recommendationsLoading } = useRecommendations({
        skip: !open,
        variables: { gapId: scoredGap.gap.id, companyId },
    });

    const [setRecommendations] = useMutation<
        SetRecommendationsMutationType,
        SetRecommendationsMutationVariables
    >(SetRecommendationsMutation);

    const [updateRecommendation] = useMutation<
        UpdateRecommendationMutationType,
        UpdateRecommendationMutationVariables
    >(UpdateRecommendationMutation);

    const initialValues = {
        recommendations: scoredGap.roadmapRecommendations
            .map(
                roadmapRecommendation =>
                    roadmapRecommendation && roadmapRecommendation.recommendation.id,
            )
            .filter(Boolean),
    };
    useEffect(() => {
        setRecommendationGapData([...recommendations]);
    }, [recommendations]);

    return (
        <Modal
            title='Edit Recommendations'
            open={open}
            onClose={onClose}
            transitionDuration={{ enter: 300, exit: 0 }}
            {...otherProps}
        >
            {recommendationsLoading ? (
                <Loader />
            ) : (
                <ExtendedFormik
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={yup.object({
                        recommendations: yup
                            .array()
                            .min(1, 'At least one recommendation must be selected'),
                    })}
                    initialValues={initialValues}
                    onSubmit={async values => {
                        try {
                            recommendationGapData?.forEach(async (recommendationData: any) => {
                                const recommendationInitialValues = {
                                    recommendation: recommendationData.recommendation,
                                    recommendationId: recommendationData.id,
                                };
                                const RecommendationCreateFormPayload = {
                                    gapId: recommendationData.gapId,
                                    companyId: recommendationData.companyId,
                                };
                                const variables = new RecommendationUpdateRequest(
                                    recommendationInitialValues,
                                    RecommendationCreateFormPayload,
                                );

                                const { data: updateGapData } = await updateRecommendation({
                                    variables,
                                    refetchQueries: [
                                        {
                                            query: GetRecommendationsQuery,
                                            variables: { gapId: scoredGap.gap.id },
                                        },
                                    ],
                                    awaitRefetchQueries: true,
                                });
                            });
                            const { data: setRecommendationsData } = await setRecommendations({
                                variables: {
                                    scoredGapId: scoredGap.id,
                                    recommendations: values.recommendations as number[],
                                },
                                refetchQueries: [
                                    {
                                        query: GetRecommendationsQuery,
                                        variables: { surveyId: capabilityAssessmentId },
                                    },
                                ],
                                awaitRefetchQueries: true,
                            });

                            enqueueSuccess('Recommendations successfully saved!');
                            onClose();

                            // if (setCapabilityGapsData?.setGaps) {
                            //     enqueueSuccess('Capability gaps successfully saved!');
                            //     onClose();
                            // } else {
                            //     enqueueError('An error occurred while saving capability gaps!');
                            // }
                        } catch (e) {
                            console.log(e);
                            throw e;
                        }
                    }}
                >
                    {formikProps => {
                        const { values, errors, setFieldValue, isSubmitting } = formikProps;

                        const handleCheckRecommendation = (recommendationId: number) => (
                            _: React.ChangeEvent<{}>,
                        ) => {
                            const currentRecommendations = [...values.recommendations];

                            const idx = currentRecommendations.findIndex(
                                recommendation => recommendation === recommendationId,
                            );

                            if (idx > -1) {
                                currentRecommendations.splice(idx, 1);
                            } else {
                                currentRecommendations.push(recommendationId);
                            }

                            setFieldValue('recommendations', currentRecommendations);
                        };
                        const handleRecommendationGapText = (
                            recommendationGapsValue: any,
                            RecomGapId: number,
                        ) => {
                            let newGapData = recommendationGapData?.map((gapData: any) => {
                                if (gapData.id === RecomGapId) {
                                    gapData.recommendation = recommendationGapsValue.target.value;
                                }
                                return gapData;
                            });
                            setRecommendationGapData([...newGapData]);
                        };

                        return (
                            <StyledForm>
                                <Table>
                                    {recommendations.map(recommendation => {
                                        const isChecked = values.recommendations.includes(
                                            recommendation.id,
                                        );

                                        const handleChange = handleCheckRecommendation(
                                            recommendation.id,
                                        );

                                        return (
                                            <TableRow key={recommendation.id}>
                                                <TableCell>
                                                    <FormControlLabel
                                                        control={
                                                            <TableCheckbox
                                                                checked={isChecked}
                                                                onChange={handleChange}
                                                            />
                                                        }
                                                        label={
                                                            <TableCellText>
                                                                {/* {recommendation.recommendation} */}
                                                                <TextField
                                                                    multiline
                                                                    fullWidth
                                                                    name='recomGapText'
                                                                    value={
                                                                        recommendation.recommendation
                                                                    }
                                                                    onChange={e =>
                                                                        handleRecommendationGapText(
                                                                            e,
                                                                            recommendation?.id,
                                                                        )
                                                                    }
                                                                    style={{
                                                                        minWidth: '400px',
                                                                    }}
                                                                />
                                                            </TableCellText>
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </Table>

                                <FormStyled.FormRowSubmit>
                                    {errors.recommendations ? (
                                        <Hint error text={errors.recommendations.toString()} />
                                    ) : null}

                                    <SubmitButton type='submit' loading={isSubmitting}>
                                        Save
                                    </SubmitButton>

                                    <SubmitButton
                                        variant='text'
                                        disabled={isSubmitting}
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </SubmitButton>
                                </FormStyled.FormRowSubmit>
                            </StyledForm>
                        );
                    }}
                </ExtendedFormik>
            )}
        </Modal>
    );
};

export { RoadmapRecommendationsEditFormModal };

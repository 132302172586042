import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetLearnTkoDetailByIdQueryType,
    GetLearnTkoDetailByIdQueryVariables,
} from '@modules/types/graphql';
import { AllLearnTkoDetailsEntity } from '../entities';
import { GetLearnTkoByIdQuery } from '../graphql';

const useSingleLearnTko = (
    options?: QueryHookOptions<GetLearnTkoDetailByIdQueryType, GetLearnTkoDetailByIdQueryVariables>,
) => {
    const queryResult = useQuery<
        GetLearnTkoDetailByIdQueryType,
        GetLearnTkoDetailByIdQueryVariables
    >(GetLearnTkoByIdQuery, {
        ...options,
    });

    const singleLearnTko = useEntity(
        () => new AllLearnTkoDetailsEntity(queryResult.data?.getLearnTkoDetailById ?? null),
        [queryResult.data],
    );

    const result = {
        singleLearnTko,
        ...queryResult,
    };

    return result;
};

export { useSingleLearnTko };

import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { Recommendation } from '@modules/types/graphql';

export type Entity = Partial<Recommendation> | null;

@DecribeEntity('RecommendationEntity')
class RecommendationEntity extends BaseEntity {
    id: number;
    gapId: number;
    recommendation: string;
    companyId: number | null;

    constructor(recommendation: Entity) {
        super(recommendation);

        this.id = recommendation?.id ?? -1;
        this.gapId = recommendation?.gap_id ?? -1;
        this.recommendation = recommendation?.recommendation ?? '';
        this.companyId = recommendation?.company_id ?? null;
    }

    isPredefined() {
        const result = this.companyId === null;

        return result;
    }
}

export { RecommendationEntity };

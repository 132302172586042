import React from 'react';
import { useParams, Redirect } from '@reach/router';

import { routes } from '@config/routes';
import { Loader } from '@modules/layout/moleculas';
import { PageStyled } from '@modules/layout/styled';
import { ProjectHeader } from '@modules/projects/moleculas';
import { useAsessmentReport } from '@modules/assessment/hooks';
import { ReportComponentsList } from '@modules/assessment/organisms';
import { useLocationQuery } from '@modules/shared/hooks';

const ProjectAssessmentReportPage = (): React.ReactElement => {
    const params = useParams();
    const [query] = useLocationQuery();

    const projectId = params.id ? +params.id : null;
    let variables: any = {};

    variables.id = projectId as number;

    if (query?.componentOwner) {
        variables.componentOwnerId = Number(query?.componentOwner);
    }

    if (query?.maturityLevel) {
        variables.maturityLevelId = Number(query?.maturityLevel);
    }

    const { project, assessmentReport, loading: projectLoading } = useAsessmentReport({
        fetchPolicy: 'network-only',
        skip: !projectId,
        variables,
    });

    if (projectLoading) {
        return <Loader />;
    }

    if (project.isNew() || project.isAssessment()) {
        return <Redirect noThrow to={routes.projects.path} />;
    }

    return (
        <>
            <ProjectHeader
                name={project.name}
                description={project.description}
                projectStatus={project.getStatus()}
                assessmentTypeId={project.assessmentTypeId}
                isRiskRegistry={project.isRiskRegistry}
            />

            <PageStyled.Main>
                <ReportComponentsList project={project} />
            </PageStyled.Main>
        </>
    );
};

export { ProjectAssessmentReportPage };

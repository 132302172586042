import { useQuery } from '@apollo/client';
import type { QueryHookOptions } from '@apollo/client';
import { GetPackagesQuery } from '../graphql/queries';

const usePackages = (options?: QueryHookOptions) => {
    const { data, loading } = useQuery(GetPackagesQuery, options);

    const result = {
        Packages: data?.listPackages,
        loading,
    };

    return result;
};

export { usePackages };

import { EntityList } from '@lib/entity';
import { ScoredGapEntity } from './scored-gap-entity';

import type { Entity } from './scored-gap-entity';

class ScoredGapEntityList extends EntityList<ScoredGapEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(ScoredGapEntity, items, count);
    }
}

export { ScoredGapEntityList };

import { gql } from '@apollo/client';

export const GetOrganizationLocationQuery = gql`
    query listLocations {
        listLocations {
            id
            name
        }
    }
`;

import { gql } from '@apollo/client';
import { LearnTkoMenuFragment } from '../fragments';

export const GetLearnTkoMenuListQuery = gql`
    query GetLearnTkoMenuListQuery {
        getLearnTkoMenuList {
            ...LearnTkoMenu
        }
    }
    ${LearnTkoMenuFragment}
`;

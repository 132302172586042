class StringHelper {
    static capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static capitalizeLetter(string: string) {
        const words = string.split(' ');

        const capitalizeWords = words
            .map(word => StringHelper.capitalizeFirstLetter(word))
            .join(' ');

        return capitalizeWords;
    }
}

export { StringHelper };

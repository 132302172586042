import { EntityList } from '@lib/entity';
import {ImplementationStatusEntity} from '@modules/shared/entities/implementation-status-entity';

class ImplementationStatusesEntityList extends EntityList<ImplementationStatusEntity> {
    constructor(items: any[] | null, count?: number | null) {
        super(ImplementationStatusEntity, items, count);
    }
}

export { ImplementationStatusesEntityList };

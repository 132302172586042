import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { useMutation } from '@apollo/client';
import { AddAssetIncident } from '@modules/registry/graphql';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { Modal, Button, TextField } from '@modules/ui/core';
import { Table, TableHead, TableBody, TableCell, TableRow, MenuItem } from '@material-ui/core';
import type { ModalProps } from '@modules/ui/core';
import { ExtendedFormik } from '@modules/layout/organisms';
import { Asset } from '@modules/types/graphql';
import { useImpactLevels } from '@modules/shared/hooks';

const StyledModal = styled(Modal)`
    width: 1200px;

    .is-empty-results {
        td {
            padding: 20px;
            font-size: 14px;
            text-align: center;
        }
    }
`;

const initialValues = {
    created_at: format(new Date(), 'yyyy-MM-dd'),
    description: '',
    impact: '',
};


const IncidentHistoryModal = (props: ModalProps & { activeAsset: Asset }): React.ReactElement => {
    const { onClose, activeAsset, refetchQuery, ...otherProps } = props;
    const [showForm, setShowForm] = useState(false);
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [addAssetIncident] = useMutation(AddAssetIncident);
    const { ImpactLevels, loading: isImpactLoading } = useImpactLevels();
    return (
        <StyledModal title={'"' + activeAsset?.name + '" ' + activeAsset?.itemType?.name + ' - Incidents'} onClose={onClose} {...otherProps}>
            <ExtendedFormik
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={initialValues}
                onSubmit={async values => {
                    const { created_at, description, impact } = values;

                    try {
                        const { data: addAssetIncidentData } = await addAssetIncident({
                            variables: {
                                input: {
                                    asset_id: activeAsset?.id,
                                    date: new Date(created_at),
                                    description,
                                    impact,
                                },
                            },
                            refetchQueries: [{ query: refetchQuery }],
                            awaitRefetchQueries: true,
                        });

                        if (addAssetIncidentData?.addAssetIncident?.id) {
                            enqueueSuccess('Asset incident successfully added!');
                            onClose();
                            setShowForm(false);
                        } else {
                            enqueueError('An error occurred while adding asset incident!');
                            onClose();
                            setShowForm(false);
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }}
            >
                {formikProps => {
                    const {
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        setFieldValue,
                        handleSubmit,
                    } = formikProps;
                    const inputNumberMask = /^[0-9]*$/;
                    const handleChangeWithMask = (
                        mask: RegExp,
                        name: string,
                        e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                    ) => {
                        if (mask.test(e.target.value)) {
                            setFieldValue(name, e.target.value);
                        }
                    };

                    return (
                        <>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Impact</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {activeAsset?.incidents && activeAsset?.incidents.length ? (
                                        activeAsset?.incidents.map((data: any, i: number) => (
                                            <TableRow key={i}>
                                                <TableCell>
                                                    {format(
                                                        new Date(data?.date),
                                                        'dd.MM.yyyy hh:mm',
                                                    )}
                                                </TableCell>
                                                <TableCell>{data?.description}</TableCell>
                                                <TableCell>{data?.impact || 'Pending'}</TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow className='is-empty-results'>
                                            <TableCell colSpan={6}>No history</TableCell>
                                        </TableRow>
                                    )}

                                    {showForm ? (
                                        <TableRow key={'is-add-new'}>
                                            <TableCell>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    type='date'
                                                    placeholder=''
                                                    id='created_at'
                                                    label='Created date'
                                                    value={values.created_at}
                                                    error={!!errors.created_at}
                                                    helperText={errors.created_at}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id='description'
                                                    label='Description'
                                                    value={values.description}
                                                    error={!!errors.description}
                                                    helperText={errors.description}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <div style={{ display: 'flex' }}>
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        id='impact'
                                                        name='impact'
                                                        label='Impact'
                                                        className='is-select'
                                                        value={values.impact}
                                                        InputLabelProps={{ shrink: true }}
                                                        // SelectProps={{
                                                        //     multiple: true,
                                                        // }}
                                                        onChange={handleChange}
                                                        style={{ width: '170px' }}
                                                    >
                                                        {ImpactLevels ? (
                                                            ImpactLevels.map(impact => {
                                                                const { id, name } = impact;
                                                                return (
                                                                    <MenuItem key={id} value={name}>
                                                                        {name}
                                                                    </MenuItem>
                                                                );
                                                            })
                                                        ) : (
                                                            <MenuItem key={0} value={''}>
                                                                Select Impact
                                                            </MenuItem>
                                                        )}
                                                    </TextField>

                                                    <Button
                                                        type='submit'
                                                        loading={isSubmitting}
                                                        style={{ margin: '0 10px 0 10px' }}
                                                        onClick={() => handleSubmit()}
                                                    >
                                                        Save
                                                    </Button>

                                                    <Button
                                                        variant='text'
                                                        disabled={isSubmitting}
                                                        onClick={() => setShowForm(false)}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ) : null}
                                </TableBody>
                            </Table>
                            {!showForm ? (
                                <div style={{ marginTop: 20 }}>
                                    <Button onClick={() => setShowForm(true)}>+ Add</Button>
                                </div>
                            ) : null}
                        </>
                    );
                }}
            </ExtendedFormik>
        </StyledModal>
    );
};

export { IncidentHistoryModal };

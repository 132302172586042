import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import {
    FormControlLabel,
    FormGroup,
    FormHelperText,
    MenuItem,
    Radio,
    RadioGroup,
    withStyles,
} from '@material-ui/core';

import { Button, TextField } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { FormStyled } from '@modules/layout/styled';
import { Loader } from '@modules/layout/moleculas';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import type { CapabilityAssessmentSaveFormValues } from '@modules/capabilities/requests';
import { CapabilityAssessmentSaveRequest } from '@modules/capabilities/requests';
import { useCapabilityAssessment } from '@modules/assessment/hooks';
import {
    GetCapabilityAssessmentQuery,
    SaveCapabilitySurveyMutation,
} from '@modules/assessment/graphql';
import type {
    AddCustomGapMutationType,
    AddCustomGapMutationVariables,
    SaveCapabilitySurveyMutationType,
    SaveCapabilitySurveyMutationVariables,
} from '@modules/types/graphql';
import { CapabilityLevelEntity, ImpactCapabilityLevelEntity } from '@modules/capabilities/entities';
import { CapabilityCheckbox, CapabilityCheckboxLabel } from '@modules/capabilities/atoms';
import { useOrganizationDetails, useProjectDetailsQuery } from '@modules/components/hooks';
import { useCurrentUser } from '@modules/auth/hooks';
import { useParams } from '@reach/router';
import { useTkoScope } from '@modules/company/hooks';
import { useAssessmentTypes } from '@modules/shared/hooks';

//Add for Chat GPT
import { ChatGptInstance } from '@helpers';
import {
    AddCustomGapRequest,
    GapCreateRequest,
    GapUpdateRequest,
} from '@modules/custom-components/requests';
import {
    AddCustomGapMutation,
    CreateGapMutation,
    GetGapsQuery,
} from '@modules/custom-components/graphql';
import { useAssignedProject } from '@modules/projects/hooks';
import { AddImpactAssetAssessment } from '@modules/registry/graphql/mutations';
import { GetProjectQuery } from '@modules/projects/graphql';

type AssessmentSurveyFormProps = {
    capabilityAssessmentId: number;
    onCancel: () => void;
    isAIAccess?: boolean;
};

const StyledFormGroup = withStyles({
    root: {
        alignItems: 'flex-start',
    },
})(FormGroup);

const StyledFormControlLabel = withStyles({
    root: {
        alignItems: 'flex-start',
        maxWidth: 'calc(20% - 16px)',
        width: '100%',
    },
})(FormControlLabel);

const SubmitButton = withStyles({
    root: {
        minWidth: 100,
    },
})(Button);

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    > * {
        width: 100%;
    }

    > h4 {
        margin-bottom: 6px;
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 36px;
    }

    > p {
        font-size: 1.6rem;
        line-height: 24px;
    }
`;

const StyledForm = styled(Form)`
    width: 100%;
    margin-top: 40px;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }

    .MuiFormGroup-root {
        justify-content: space-between;
    }

    .MuiFormControlLabel-root {
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        height: 48px;
    }
    .MuiCheckbox-root {
        order: 1;
        margin-right: 0px;
    }
`;

const Title = styled.span`
    display: inline-block;
    max-width: 100px;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1;
    text-align: center;
`;

const FormRowColumnWrap = styled(FormStyled.FormRow)`
    align-items: flex-start;
    justify-content: space-between;

    > * {
        width: calc(50% - 16px);
    }
`;

const AssessedBy = styled.div`
    width: 100%;
    font-size: 1.4rem;
    line-height: 22px;

    > p {
        width: 100%;
    }
`;

const AssessmentSurveyForm = (props: AssessmentSurveyFormProps): React.ReactElement => {
    const { capabilityAssessmentId, onCancel, isAIAccess } = props;
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const { currentUser, loading: currentUserLoading } = useCurrentUser();
    const userId = currentUser.id;
    const params = useParams();

    const projectId: any = params.id ? Number(params.id) : null;

    const { project, loading: projectLoading } = useAssignedProject({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        skip: !projectId,
        variables: { id: projectId as number },
    });

    const [addCustomGap] = useMutation<AddCustomGapMutationType, AddCustomGapMutationVariables>(
        AddCustomGapMutation,
    );

    const { organizationDetails, loading: orgDetailLoading } = useOrganizationDetails();

    const { projectDetails, loading: projectDetailLoading } = useProjectDetailsQuery({
        skip: !projectId,
        variables: { id: projectId as number },
    });

    //Get Assessment Type Name
    const { assessmentTypes, loading: assessmentTypesLoading } = useAssessmentTypes();

    const filteredAssessmentTypes = assessmentTypes.filter(
        type => type.id === projectDetails.assessmentTypeId,
    );
    const assessmentTypeNames = filteredAssessmentTypes.map(type => type.name);
    const assessmentTypeName = assessmentTypeNames[0];
    //Get Tko Scope Name
    const { tkoscopes, loading: tkoScopeLoading } = useTkoScope();
    const filteredTkoscopes = tkoscopes.filter(type => type.id === projectDetails.tkoScopeId);
    const tkoScopeNames = filteredTkoscopes.map(type => type.name);
    const tkoScopeName = tkoScopeNames[0];
    const { capabilityAssessment, loading: capabilityLoading } = useCapabilityAssessment({
        variables: {
            surveyId: capabilityAssessmentId,
        },
    });
    console.log(capabilityAssessment, 'capabilityAssessment');
    const capabilityLevels = capabilityAssessment.capabilityLevels;
    const [saveCapability] = useMutation<
        SaveCapabilitySurveyMutationType,
        SaveCapabilitySurveyMutationVariables
    >(SaveCapabilitySurveyMutation);

    const capabilityData = project.isrrimpact
        ? capabilityAssessment?.impactCapabilityLevels
        : capabilityAssessment?.capabilityLevels;
    console.log(capabilityData, 'capabilityData');
    const initialValues: CapabilityAssessmentSaveFormValues = {
        levelId: capabilityAssessment.completionLevel.id,
        notes: capabilityAssessment.notes,
        gaps: capabilityAssessment.gaps,
        recommendations: capabilityAssessment.recommendations,
    };

    // code here
    let queryStart = `In conducting a`;
    let capLevelStr = `Assessment and applying a capability completion model that has a range of 0 to 9, with 0 being "no capability" and 9 being "full capability", what are the`;
    let comletationLevelScoreStr = `gaps for an organization that has a capability completion score of`;
    let comletationLevelScoreStrEnd = `for the following capability:`;
    let gapStr = `? The organization has the following known gaps: `;
    let measuersStr = `The organization has the following measures in place: `;
    let orgStr = `The organization is a`;
    let locationStr = `entity located in`;
    let workforceStr = `employees whose workforce is primarily a mix of remote and onsite.`;
    // saveChatGpt
    async function saveChatGpt(values: any) {
        const index = capabilityLevels.findIndex(level => level.id === values.levelId);
        let score = 0;
        if (index !== -1) {
            score = index;
        }
        const assessGapValues = values.gaps;
        const assessMeasureValues = values.notes;
        var capabilityLevelId = values.levelId;

        let chatGPTQuery = `${queryStart} ${assessmentTypeName} ${capLevelStr} ${tkoScopeName} ${comletationLevelScoreStr} ${score} ${comletationLevelScoreStrEnd} its "${
            capabilityAssessment.capability.title
        }" ${gapStr} ${assessGapValues}. ${measuersStr} ${assessMeasureValues}. ${orgStr} ${
            organizationDetails.organizationType
        } ${locationStr} ${organizationDetails?.orgLocation} in the ${
            organizationDetails?.sector
        } sector with ${organizationDetails.noOfEmployees} ${workforceStr} ${
            organizationDetails.workforce === 'Mix'
                ? 'Mix of onsite and remote'
                : organizationDetails.workforce
        }`;

        // if AI ACCESS then

        if (isAIAccess) {
            //checking for impact
            if (!project?.isrrimpact) {
                console.log('RR FALSE in ====> AI ACCESS');
                const chatGptResponse = async (prompt: string) => {
                    const completion: any = await ChatGptInstance.createChatCompletion({
                        model: 'gpt-4o',
                        messages: [{ role: 'user', content: prompt }],
                        temperature: 0.7,
                        max_tokens: 3097,
                    });
                    const dataArray: any = completion?.data?.choices[0]?.message?.content;
                    const startIndex = dataArray.indexOf('1.');
                    const lastIndex = dataArray.lastIndexOf('\n\n');
                    const result = dataArray.substring(startIndex, lastIndex);
                    const itemsArray = result.split(/\n\n+/);
                    const prevData = itemsArray?.map((ele: any) =>
                        ele?.replace(/^\d+\.\s*|\n/gm, ''),
                    );
                    return prevData.slice(0, 3);
                };
                const gapDataResponse = await chatGptResponse(chatGPTQuery);

                capabilityLevelId = values.levelId;
                const capabilityAssessmentQId = capabilityAssessmentId;

                for (let [index, items] of gapDataResponse.entries()) {
                    const item = items?.replace(/^\d+\.\s*|\n/gm, '');
                    if (index < 3) {
                        const variables = new AddCustomGapRequest({
                            capabilityLevelId: capabilityLevelId,
                            surveyId: capabilityAssessmentQId,
                            gap: item,
                            projectId: projectId,
                            isAiGaps: true,
                        });
                        setTimeout(async () => {
                            try {
                                const { data: addCustomGapData } = await addCustomGap({
                                    variables,
                                    refetchQueries: [
                                        {
                                            query: GetGapsQuery,
                                            variables: { capabilityLevelId },
                                        },
                                        {
                                            query: GetProjectQuery,
                                            variables: { id: projectId },
                                        },
                                    ],
                                    awaitRefetchQueries: true,
                                });
                            } catch (error) {
                                console.error('Error adding custom gap:', error);
                            }
                        }, 700);
                    }
                }
            }
        } else {
            const item = values && values?.gaps?.replace(/^\d+\.\s*/, '');
            const capabilityAssessmentQId = capabilityAssessmentId;

            const variables = new AddCustomGapRequest({
                capabilityLevelId: capabilityLevelId,
                surveyId: capabilityAssessmentQId,
                gap: item,
                projectId: projectId,
                isAiGaps: false,
            });
            const { data: addCustomGapData } = await addCustomGap({
                variables,
                refetchQueries: [
                    {
                        query: GetGapsQuery,
                        variables: { capabilityLevelId },
                    },
                    {
                        query: GetProjectQuery,
                        variables: { id: projectId },
                    },
                ],
                awaitRefetchQueries: true,
            });
        }

        const variables = new CapabilityAssessmentSaveRequest(values, capabilityAssessmentId);

        const { data: saveCapabilityData } = await saveCapability({
            variables,
            refetchQueries: [
                {
                    query: GetCapabilityAssessmentQuery,
                    variables: { surveyId: capabilityAssessmentId },
                },
                {
                    query: GetProjectQuery,
                    variables: { id: projectId },
                },
            ],
            awaitRefetchQueries: true,
        });

        if (saveCapabilityData?.submitCapabilitySurvey?.survey_id) {
            enqueueSuccess('Survey was successfully saved!');
            onCancel();
        } else {
            enqueueError('An error occurred while saving the survey!');
        }
    }

    if (
        capabilityLoading ||
        projectDetailLoading ||
        projectLoading ||
        tkoScopeLoading ||
        assessmentTypesLoading ||
        orgDetailLoading
    ) {
        return <Loader />;
    }

    return (
        <Root>
            <Header>
                <h4>{`${capabilityAssessment.capability.title}`}</h4>

                {capabilityAssessment.capability.keyFactors ? (
                    <p>{capabilityAssessment.capability.keyFactors}</p>
                ) : null}
            </Header>

            <ExtendedFormik
                initialValues={initialValues}
                onSubmit={async values => {
                    try {
                        await saveChatGpt(values);
                    } catch (e) {
                        throw e;
                    }
                }}
            >
                {formikProps => {
                    const {
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        isSubmitting,
                    } = formikProps;

                    const handleChangeCheckbox = (
                        event: React.ChangeEvent<{ name: string; value: string }>,
                    ) => setFieldValue(event.target.name, Number(event.target.value));

                    return (
                        <StyledForm>
                            <StyledFormGroup row>
                                {capabilityData?.map(
                                    (
                                        capabilityLevel: ImpactCapabilityLevelEntity,
                                        index: number,
                                    ) => {
                                        // TODO: output capabilityLevel.description in popup in next iteration
                                        let label = <Title>{capabilityLevel.name}</Title>;

                                        return (
                                            <FormControlLabel
                                                id='levelId'
                                                name='levelId'
                                                key={capabilityLevel.id}
                                                value={capabilityLevel.id}
                                                label={label}
                                                control={
                                                    <CapabilityCheckbox
                                                        checked={
                                                            values.levelId === capabilityLevel.id
                                                        }
                                                        onChange={handleChangeCheckbox}
                                                    />
                                                }
                                            />
                                        );
                                    },
                                )}

                                {!!errors.levelId ? (
                                    <FormHelperText error>{errors.levelId}</FormHelperText>
                                ) : null}
                            </StyledFormGroup>

                            <FormRowColumnWrap>
                                <FormStyled.FormColumn>
                                    <TextField
                                        fullWidth
                                        multiline
                                        id='gaps'
                                        name='gaps'
                                        label={project.isrrimpact ? 'Evidence' : 'Gap'}
                                        placeholder={
                                            project.isrrimpact
                                                ? 'Enter the evidence'
                                                : 'Enter the Gap'
                                        }
                                        rows={3}
                                        value={values.gaps}
                                        error={!!errors.gaps}
                                        helperText={errors.gaps}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <TextField
                                        fullWidth
                                        multiline
                                        id='recommendations'
                                        name='recommendations'
                                        label={
                                            project.isrrimpact ? 'Assumptions' : 'Recommendations'
                                        }
                                        placeholder={
                                            project.isrrimpact
                                                ? 'Enter the assumptions'
                                                : 'Enter the Recommendations'
                                        }
                                        rows={3}
                                        value={values.recommendations}
                                        error={!!errors.recommendations}
                                        helperText={errors.recommendations}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </FormStyled.FormColumn>

                                <FormStyled.FormColumn>
                                    <TextField
                                        fullWidth
                                        multiline
                                        id='notes'
                                        name='notes'
                                        label={project.isrrimpact ? 'Unwanted Events' : 'Measures'}
                                        placeholder={
                                            project.isrrimpact
                                                ? 'Enter the unwanted events'
                                                : 'Enter the Measures'
                                        }
                                        rows={3}
                                        value={values.notes}
                                        error={!!errors.notes}
                                        helperText={errors.notes}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    {capabilityAssessment.isAssess() ? (
                                        <AssessedBy>
                                            <p>
                                                {`Assessed By ${capabilityAssessment.assessedBy.getFullName()}`}
                                            </p>
                                            <p>{capabilityAssessment.getHumanAssessedAt()}</p>
                                        </AssessedBy>
                                    ) : null}
                                </FormStyled.FormColumn>
                            </FormRowColumnWrap>

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    Save
                                </SubmitButton>

                                <SubmitButton
                                    variant='text'
                                    disabled={isSubmitting}
                                    onClick={onCancel}
                                >
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    );
                }}
            </ExtendedFormik>
        </Root>
    );
};

export { AssessmentSurveyForm };

import { gql } from '@apollo/client';

import { CompanyFragment } from '../fragments';

export const GetCompaniesQuery = gql`
    query GetCompanies {
        listCompanies {
            ...Company
        }
    }
    ${CompanyFragment}
`;

import { gql } from '@apollo/client';

export const UpdateProjectMutation = gql`
    mutation UpdateProject($projectId: Int!, $stakeholderId: Int!, $companyId: Int!) {
        updateProject(
            project_id: $projectId
            stakeholder_id: $stakeholderId
            company_id: $companyId
        ) {
            id
        }
    }
`;

import { EntityList } from '@lib/entity';
import { RoadmapRecommendationRefinementEntity } from './roadmap-recommendation-refinement-entity';

import type { Entity } from './roadmap-recommendation-entity';

class RoadmapRecommendationRefinementEntityList extends EntityList<RoadmapRecommendationRefinementEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(RoadmapRecommendationRefinementEntity, items, count);
    }
}

export { RoadmapRecommendationRefinementEntityList };

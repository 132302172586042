import React from 'react';

import { Modal } from '@modules/ui/core';
import { CustomComponentCreateForm } from '@modules/custom-components/organisms';

import type { ModalProps } from '@modules/ui/core';
import { CaptionUpdateForm } from './caption-update-form';

type UpdateCaptionModalProps = Omit<ModalProps, 'title' | 'children'> & {
    caption?: string | any;
};

const UpdateCaptionModal = (props: UpdateCaptionModalProps): React.ReactElement => {
    const { caption, onClose, ...otherProps } = props;

    return (
        <Modal title='Edit Caption' onClose={onClose} {...otherProps}>
            <CaptionUpdateForm caption={caption} onCancel={onClose} />
        </Modal>
    );
};

export { UpdateCaptionModal };

import React from 'react';

import { Button, Dialog } from '@modules/ui/core';

type DialogGapDeleteProps = {
    disabled: boolean;
    onDelete: () => void;
};

const DialogGapDelete = (props: DialogGapDeleteProps): React.ReactElement => {
    const { onDelete, ...otherProps } = props;

    return (
        <Dialog
            title='Are you sure you want to delete this gap?'
            acceptText='Delete'
            onAccept={onDelete}
            {...otherProps}
        >
            {dialogProps => {
                const { handleOpen } = dialogProps;

                return (
                    <Button size='small' onClick={handleOpen}>
                        Delete
                    </Button>
                );
            }}
        </Dialog>
    );
};

export { DialogGapDelete };

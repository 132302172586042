import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { UserEntity } from '@modules/users/entities';
import { useAuth } from '@modules/auth/context';
import { GetCurrentUserQuery } from '@modules/auth/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetCurrentUserQueryType } from '@modules/types/graphql';

export const userMap = new Map<number, UserEntity>();

const useCurrentUser = (options?: QueryHookOptions<GetCurrentUserQueryType>) => {
    const { loading, authenticated } = useAuth();

    const { loading: queryLoading, ...otherQueryResult } = useQuery<GetCurrentUserQueryType>(
        GetCurrentUserQuery,
        {
            // fetchPolicy: 'network-only',
            skip: !authenticated,
            ...options,
        },
    );

    let currentUser = useEntity(
        () => new UserEntity(otherQueryResult.data?.getCurrentUser ?? null),
        [authenticated, otherQueryResult.data],
    );

    // to resolve problem with broken user request cached in Apollo side
    // may be need to disable cache at all or revisit later
    if (!userMap.has(currentUser.id)) {
        userMap.set(currentUser.id, currentUser);
    } else {
        currentUser = userMap.get(currentUser.id) as UserEntity;
    }
    if (currentUser.id !== -1 && (!currentUser.isSuperAdmin || !currentUser.isAdmin)) {
        // console.warn('User is not admin', currentUser)
    }

    const result = {
        currentUser,
        loading: loading || queryLoading,
        ...otherQueryResult,
    };

    return result;
};

export { useCurrentUser };

import { EntityList } from '@lib/entity';
import { GapEntity } from './gap-entity';

import type { Entity } from './gap-entity';

class GapEntityList extends EntityList<GapEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(GapEntity, items, count);
    }
}

export { GapEntityList };

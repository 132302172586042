import { QueryHookOptions, useQuery } from '@apollo/client';
import { GetOrganizationLocationQuery } from '../graphql/queries';
import { useEntity } from '@lib/entity';
import { OrgLocationEntityList } from '../entities';

const useOrganizationLocation = (options?: QueryHookOptions) => {
    const { data, loading } = useQuery(GetOrganizationLocationQuery, options);

    const queryResult = useQuery(GetOrganizationLocationQuery, options);
    const { entities: organizationLocation, count } = useEntity(
        () => new OrgLocationEntityList(queryResult.data?.listLocations ?? null),
        [queryResult.data],
    );

    const result = {
        organizationLocation,
        count,
        queryResult,
    };

    return result;
};

export { useOrganizationLocation };

import { gql } from '@apollo/client';

export const ImplementationSubTaskSingleFragment = gql`
    fragment SubTaskSingle on SubTask {
        id
        recommendation_id
        task_id
        sub_task
        company_id
    }
`;

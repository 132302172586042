import { gql } from '@apollo/client';

export const AddAssessmentTypeFragment = gql`
    fragment AssessmentType on AssessmentType {
        id
        name
        company_id
        is_rr_type
        assessment_categories_id
    }
`;

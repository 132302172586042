import React from 'react';
import styled from 'styled-components';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { DragIndicator } from '@material-ui/icons';

import { gray } from '@modules/ui/colors';
import { Table, TableRow, TableCell } from '@modules/layout/organisms';

import type {
    SortableContainerProps,
    SortableElementProps,
    SortStartHandler,
} from 'react-sortable-hoc';
import type { TableProps, TableRowProps, TableColumn } from '@modules/layout/organisms';

type InjectableChildrenProps = {
    SortableItem: React.ComponentType<TableRowProps & SortableElementProps>;
};

type OrderableTableProps = Omit<TableProps, 'children'> &
    SortableContainerProps & {
        children: (props: InjectableChildrenProps) => React.ReactNode;
    };

type ContainerProps = Omit<OrderableTableProps, 'children'> & { children: React.ReactNode };

const orderableColumns: TableColumn[] = [
    {
        id: 'dragHandle',
        label: '',
        width: 30,
    },
];

const DragWrap = styled.div`
    display: flex;
    cursor: pointer;
`;

const DragHandle = SortableHandle(() => (
    <DragWrap>
        <DragIndicator style={{ color: gray[30] }} />
    </DragWrap>
));

const SortableItem = SortableElement<TableRowProps>((props: TableRowProps) => {
    const { children, ...otherProps } = props;

    return (
        <TableRow {...otherProps}>
            <TableCell>
                <DragHandle />
            </TableCell>

            {children}
        </TableRow>
    );
});

const Container = SortableContainer<ContainerProps>((props: ContainerProps) => (
    <Table {...props} />
));

const helperClassName = 'sortable-helper';

const OrderableTable = (props: OrderableTableProps): React.ReactElement => {
    const { columns: columnsProps = [], children, ...otherProps } = props;

    const columns = [...orderableColumns, ...columnsProps];

    // workaroundfor corret styled tr
    // when with drag it
    const handleSortStart: SortStartHandler = options => {
        const { node } = options;

        const elements = document.getElementsByClassName(helperClassName)[0].childNodes as any;

        node.childNodes.forEach(
            (childNode: any, idx: number) =>
                (elements[idx].style.width = `${childNode.offsetWidth}px`),
        );
    };

    return (
        <Container
            columns={columns}
            helperClass={helperClassName}
            onSortStart={handleSortStart}
            {...otherProps}
        >
            {children({ SortableItem })}
        </Container>
    );
};

export { OrderableTable };

import React, { useMemo } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { RatingPlate } from './rating-plate';
import { getRiskProgramScore } from '@modules/shared/constants/risk-level';
import { UseTableRowProps } from 'react-table';
import { Asset } from '@modules/types/graphql';
import { RiskProgramType } from '@modules/shared/constants/risk-assessment-type';

const average = (arr: number[], type: string) => {
    let sum: number = 0;
    const currentLength: any = arr?.filter(rate => {
        if (type === 'impact') {
            return rate >= 5;
        } else if (type === 'vulnerability' || type === 'threat') {
            return rate >= 0.05;
        }
        return rate;
    });

    if (currentLength.length === 0) return 0;

    for (let i = 0; i < currentLength.length; i++) {
        sum += currentLength[i]; 
    }
    return sum / currentLength.length;
};

const RatingCard = (props: any): React.ReactElement => {
    const { title, type, filteredAssets, rowId } = props;
    const score = useMemo(() => {
        if (!filteredAssets) {
            return '';
        }

        if (type === RiskProgramType.risk && filteredAssets[0]?.original[`${type}Score`] === null) {
            return average(
                filteredAssets.map((asset: UseTableRowProps<Asset>) =>
                    getRiskProgramScore(
                        asset.original.vulnerabilityScore,
                        asset.original.threatScore,
                    ),
                ),
                type,
            ).toFixed(2);
        }

        let avScore = average(
            filteredAssets.map((asset: UseTableRowProps<Asset>) => asset.original[`${type}Score`]),
            type,
        ).toFixed(2);
        return avScore;
    }, [filteredAssets, type]);
    return (
        <Card>
            <CardContent>
                <Typography gutterBottom variant='h4'>
                    {title}
                </Typography>
                <RatingPlate score={score} type={type} />
            </CardContent>
        </Card>
    );
};

export { RatingCard };

import React from 'react';
import styled, { css } from 'styled-components';

type TableCellTextProps = {
    children: React.ReactNode;
    className?: string;
    variant?: 'body' | 'head';
};

const Root = styled.div<{ variant: NonNullable<TableCellTextProps['variant']> }>`
    ${p =>
        (p.variant === 'body' &&
            css`
                font-size: 1.4rem;
                font-weight: 400;
            `) ||
        (p.variant === 'head' &&
            css`
                font-size: 1.6rem;
                font-weight: 700;
            `)}
`;

const TableCellText = (props: TableCellTextProps): React.ReactElement => {
    const { variant = 'body', children, ...otherProps } = props;

    return (
        <Root {...otherProps} variant={variant}>
            {children}
        </Root>
    );
};

export { TableCellText };

import React from 'react';
import { makeStyles, TableRow as MuiTableRow } from '@material-ui/core';

import { gray } from '@modules/ui/colors';
import { concatenateClasses } from '@modules/ui/utils';

import type { Theme, TableRowProps as MuiTableRowProps } from '@material-ui/core';

export type TableRowProps = Omit<MuiTableRowProps, 'size' | 'children'> & {
    children: React.ReactNode;
    size?: 'xs' | 's' | 'm' | 'l' | 'xl'; // size - overide material ui API
};

const sizesMap = {
    xs: {
        height: 48,
    },
    s: {
        height: 56,
    },
    m: {
        height: 64,
    },
    l: {
        height: 72,
    },
    xl: {
        height: 80,
    },
};

const useTableRowStyles = makeStyles({
    root: {
        '& > td:first-of-type': {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
        },

        '& > td:last-of-type': {
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
        },

        '&:hover > td': {
            backgroundColor: gray[50],
        },
    },
});

const useTableCellStyles = makeStyles<Theme, Required<Pick<TableRowProps, 'size'>>>({
    body: p => ({
        ...sizesMap[p.size],
    }),
});

const TableRow = (props: TableRowProps): React.ReactElement => {
    const { size = 'xl', classes, children, ...otherProps } = props;

    const tableRowClasses = concatenateClasses(useTableRowStyles(), classes);
    const tableCellClasses = useTableCellStyles({ size });

    return (
        <MuiTableRow classes={tableRowClasses} {...otherProps}>
            {React.Children.map(children, (child: any) => {
                if (!child || !('props' in child)) {
                    return child;
                }

                const { classes, ...childProps } = child.props;

                const cellClasses = concatenateClasses(tableCellClasses, classes);

                return React.cloneElement(child, {
                    classes: cellClasses,
                    ...childProps,
                });
            })}
        </MuiTableRow>
    );
};

export { TableRow };

import * as React from 'react';
import {
    TableCell,
    TableBody,
    TableHead,
    TableContainer,
    TableSortLabel,
    Table as MuiTable,
    makeStyles,
    IconButton
} from '@material-ui/core';
import styled from 'styled-components';
import { TableRow } from './table-row';
import { TableCellText } from '@modules/layout/atoms';
import { Loader } from '@modules/layout/moleculas';
import { useCurrentUser } from '@modules/auth/hooks';
import { useAccordion, useDebounce } from '@helpers';
import { MoreVert, ExpandMore, KeyboardArrowLeft } from '@material-ui/icons';
import { Check, Close } from '@material-ui/icons';
import { AllProjectDetails } from '@pages/all-project-count/all-project-details';
import { useGapRecommendationsCount } from '@modules/custom-components/hooks';
const useTableStyles = makeStyles({
    root: {
        borderCollapse: 'separate',
        borderSpacing: '0 4px',
        width: '100%',
    },
});

const CompanyNameText = styled(TableCell)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const ProjectNameText = styled.h4`
    text-transform: capitalize;
    margin-bottom: 6px;
    font-size: 1.4rem;
    font-weight: 400;
`;
const TableHeadStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const TextFieldStyle = styled.div<{ isTrue?: any }>`
    display: ${props => (props.isTrue ? 'block' : 'none')};
    font-size: 13px;
    margin: 10px -6px -10px -6px;
    input {
        padding: 4px;
        border-right: transparent !important;
        border-top: transparent !important;
        border-left: transparent !important;
        border: 0.3px solid #a7a7a7;
    }

    input:focus {
        outline: none !important;
        border-bottom: 1px solid #605c5c;
    }
`;

interface Data {
    projectName: string;
    rr: string;
    description: string;
    status: string;
    maturityLevel: string;
    actions: any;
}

type Order = 'asc' | 'desc';

export type TableColumn = {
    id: string;
    label?: string;
    align?: 'left' | 'center' | 'right';
    width?: number;
};

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    columns: TableColumn[];
    setQuery: (value: any) => void;
    query: string;
    onChangeHandle: (e: React.ChangeEvent<HTMLInputElement>, columnId: any) => void;
}

interface ProjectDataTableProps {
    allProjects: any;
    projectsLoading: boolean;
    columns: TableColumn[];
}

export function EnhancedTableHead(props: EnhancedTableProps) {
    let { order, orderBy, columns, onRequestSort, setQuery, query, onChangeHandle } = props;
    const [toggleSearchField, setToggleSearchField] = React.useState<null | string>(null);

    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    const handleSearchToggle = (id: string) =>
        toggleSearchField === null ? setToggleSearchField(id) : setToggleSearchField(null);

    return (
        <TableHead style={{ boxShadow: '5px 1px 2px 1px rgba(0,0,0,0.2)' }}>
            <TableRow>
                {columns?.map((headCell, index) => {
                    return (
                        <TableCell
                            style={{
                                width: '220px',
                                textAlign: index === columns.length - 1 ? 'right' : 'left',
                            }}
                            key={headCell?.id}
                            sortDirection={orderBy === headCell?.id ? order : false}
                        >
                            {index !== columns.length - 1 ? (
                                headCell?.id === 'serial' ? ('Sr. NO')
                                    : (
                                        <>
                                            <TableHeadStyle>
                                                <TableSortLabel
                                                    active={orderBy === headCell?.id}
                                                    direction={orderBy === headCell?.id ? order : 'asc'}
                                                    onClick={createSortHandler(headCell?.id)}
                                                >
                                                    {headCell?.label}
                                                </TableSortLabel>
                                                <IconButton onClick={() => handleSearchToggle(headCell?.id)}>
                                                    <MoreVert style={{ fontSize: 18, cursor: 'pointer' }} />
                                                </IconButton>
                                            </TableHeadStyle>
                                            {toggleSearchField === headCell?.id ? (
                                                <TextFieldStyle isTrue={toggleSearchField}>
                                                    <input
                                                        type='text'
                                                        placeholder={headCell?.label}
                                                        onChange={e => onChangeHandle(e, headCell?.id)}
                                                    />
                                                </TextFieldStyle>
                                            ) : null}
                                        </>
                                    )) : ('Actions')
                            }
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}

function AllProjectDataTable(props: ProjectDataTableProps) {
    const { columns, projectsLoading, allProjects, ...otherProps } = props;

    const [order, setOrder] = React.useState<Order>('asc');
    const [query, setQuery] = React.useState<string>('');
    const debouncedQuery = useDebounce(query, 500);
    const [toggleAction, setToggleAction] = React.useState<boolean>(false);
    const [orderBy, setOrderBy] = React.useState<keyof Data>('projectName');
    const tableClasses = useTableStyles();
    const [filteredData, setFilteredData] = React.useState<any>([]);
    const { activeIndex, toggleAccordion } = useAccordion(0);
    const [schemaName, setSchemaName] = React.useState<string>('');

    function toggleRow(id: number, rowSchemaName: string) {
        toggleAccordion(id);
        setSchemaName(rowSchemaName);
        setToggleAction(true);
    }

    const { gapRecommendationCount, loading: GapRecommendationsCountLoading } = useGapRecommendationsCount({
        variables: { companyId: activeIndex, schemaName: schemaName },
    });

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    React.useEffect(() => {
        const filteredResults = allProjects.filter((item: any) => {
            if (Object.values(debouncedQuery).some(value => value !== '')) {
                return Object.keys(item).some((key: any) => {
                    const searchValue = debouncedQuery[key]?.toLowerCase();
                    if (searchValue) {
                        return (
                            item[key].toString().toLowerCase().includes(searchValue) || item[key].toString().toUpperCase().includes(searchValue)
                        );
                    }
                    return false;
                });
            } else {
                return true;
            }
        });
        setFilteredData(filteredResults);
    }, [debouncedQuery, query, allProjects]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>, columnId: string) => {
        const searchValue = (e.target as HTMLInputElement).value.toLowerCase();
        setQuery((prevSearchQueries: any) => ({
            ...prevSearchQueries,
            [columnId]: searchValue,
        }));
    };

    if (projectsLoading) {
        return <Loader />;
    }
    return (
        <div>
            <TableContainer style={{ marginTop: '20px' }}>
                <MuiTable classes={tableClasses} {...otherProps}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={allProjects.length}
                        columns={columns}
                        setQuery={setQuery}
                        query={query}
                        onChangeHandle={handleSearch}
                    />
                    <TableBody style={{ background: '#e7e7e7', cursor: 'pointer' }}>
                        {filteredData
                            ?.sort((a: any, b: any) => {
                                if (orderBy === undefined) {
                                    return 0;
                                }
                                const aValue = a[orderBy];
                                const bValue = b[orderBy];

                                if (aValue < bValue) {
                                    return order === 'asc' ? -1 : 1;
                                }
                                if (aValue > bValue) {
                                    return order === 'asc' ? 1 : -1;
                                }
                                return 0;
                            })
                            .map((ele: any, index: any) => (
                                <React.Fragment key={ele?.id}>
                                    <TableRow size='m'>
                                        <TableCell>
                                            <TableCellText>{index + 1}</TableCellText>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellText>{ele?.name}</TableCellText>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellText>{ele?.schema_name}</TableCellText>
                                        </TableCell>
                                        <TableCell
                                            onClick={() => toggleRow(ele?.id, ele?.schema_name)}
                                            style={{ textAlign: 'right' }}
                                        >
                                            <IconButton>
                                                {toggleAction && activeIndex === ele?.id ? (
                                                    <ExpandMore style={{ fontSize: 20 }} />
                                                ) : (
                                                    <KeyboardArrowLeft style={{ fontSize: 20 }} />
                                                )}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    {activeIndex === ele?.id && (
                                        <TableRow>
                                            <TableCell colSpan={columns.length + 1}>
                                                <AllProjectDetails
                                                    allCount={gapRecommendationCount}
                                                    gapLoading={GapRecommendationsCountLoading}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            ))}
                    </TableBody>
                </MuiTable>
            </TableContainer>
        </div>
        // <div>
        //     <TableContainer style={{ marginTop: ' 20px' }}>
        //         <MuiTable classes={tableClasses} {...otherProps}>
        //             <EnhancedTableHead
        //                 order={order}
        //                 orderBy={orderBy}
        //                 onRequestSort={handleRequestSort}
        //                 rowCount={allProjects.length}
        //                 columns={columns}
        //                 setQuery={setQuery}
        //                 query={query}
        //                 onChangeHandle={handleSearch}
        //             />
        //             <TableBody style={{ background: '#e7e7e7', cursor: 'pointer' }}>
        //                 {filteredData
        //                     ?.sort((a: any, b: any) => {
        //                         if (orderBy === undefined) {
        //                             return 0;
        //                         }
        //                         const aValue = a[orderBy];
        //                         const bValue = b[orderBy];
        //                         if (aValue < bValue) {
        //                             return order === 'asc' ? -1 : 1;
        //                         }
        //                         if (aValue > bValue) {
        //                             return order === 'asc' ? 1 : -1;
        //                         }
        //                         return 0;
        //                     })
        //                     .map((ele: any, index: any) => {
        //                         return (
        //                             <>
        //                                 <TableRow size='m' key={ele?.id}>
        //                                     <TableCell>
        //                                         <TableCellText>{index + 1}</TableCellText>
        //                                     </TableCell>
        //                                     <TableCell>
        //                                         <TableCellText>{ele?.name}</TableCellText>
        //                                     </TableCell>
        //                                     <TableCell>
        //                                         <TableCellText>{ele?.schema_name}</TableCellText>
        //                                     </TableCell>
        //                                     <TableCell onClick={() => toggleRow(ele?.id, ele?.schema_name)} style={{ textAlign: 'right' }}>
        //                                         {
        //                                             (toggleAction && activeIndex === ele?.id) ? (
        //                                                 <ExpandMore style={{ fontSize: 20 }} />
        //                                             ) : (
        //                                                 <KeyboardArrowLeft style={{ fontSize: 20 }} />
        //                                             )
        //                                         }
        //                                     </TableCell>
        //                                 </TableRow>
        //                                 {activeIndex === ele?.id &&
        //                                     (
        //                                         <AllProjectDetails
        //                                             allCount={gapRecommendationCount}
        //                                             gapLoading={GapRecommendationsCountLoading}
        //                                         />
        //                                     )
        //                                 }
        //                             </>
        //                         );
        //                     })}
        //             </TableBody>
        //         </MuiTable>
        //     </TableContainer>
        // </div>
    );
}
export { AllProjectDataTable };

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { CapabilityLevelDefinitionEntityList } from '@modules/custom-components/entities';
import { GetCapabilityLevelDefinitionsQuery } from '@modules/custom-components/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetCapabilityLevelDefinitionsQueryType,
    GetCapabilityLevelDefinitionsQueryVariables,
} from '@modules/types/graphql';

const useCapabilityLevelDefinitions = (
    options?: QueryHookOptions<
        GetCapabilityLevelDefinitionsQueryType,
        GetCapabilityLevelDefinitionsQueryVariables
    >,
) => {
    const queryResult = useQuery<
        GetCapabilityLevelDefinitionsQueryType,
        GetCapabilityLevelDefinitionsQueryVariables
    >(GetCapabilityLevelDefinitionsQuery, options);

    const { entities: capabilityLevelDefinitions, count } = useEntity(
        () =>
            new CapabilityLevelDefinitionEntityList(
                queryResult.data?.listCapabilityLevelDefinitions ?? null,
            ),
        [queryResult.data],
    );

    const result = {
        capabilityLevelDefinitions,
        count,
        ...queryResult,
    };

    return result;
};

export { useCapabilityLevelDefinitions };

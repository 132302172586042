import { gql } from '@apollo/client';

import { RecommendationSingleFragment } from '../fragments';

export const AddCustomRecommendationMutation = gql`
    mutation AddCustomRecommendation($scoredGapId: Int!, $recommendation: String!) {
        addCustomRecommendation(scored_gap_id: $scoredGapId, recommendation: $recommendation) {
            ...RecommendationSingle
        }
    }
    ${RecommendationSingleFragment}
`;

function LoginLogoutComponent(loginstatus: string) {
    let totalTime: any = null;

    if (loginstatus === 'login') {
        const currentLoginTime: any = new Date().getTime();
        localStorage.setItem('loginTime', currentLoginTime.toString());
    }

    const storedLoginTime: any = localStorage.getItem('loginTime');

    if (loginstatus === 'logout' && storedLoginTime) {
        const currentLogoutTime: any = new Date().getTime();

        totalTime = (currentLogoutTime - storedLoginTime) / 1000 / 60;
    }

    if (totalTime) {
        return totalTime;
    }
}

export { LoginLogoutComponent };

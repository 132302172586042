import React from 'react';
import { useNavigate } from '@reach/router';
import { makeStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';

const useButtonStyles = makeStyles({
    label: {
        fontSize: '1.2rem',
    },
});

const ButtonBack = (props: any): React.ReactElement => {
    const { href, children, ...otherProps } = props;

    const navigate = useNavigate();

    const buttonClasses = useButtonStyles();

    const handleClickButton = () => navigate(href, { replace: true });

    return (
        <Button variant='text' classes={buttonClasses} onClick={handleClickButton} {...otherProps}>
            {children}
        </Button>
    );
};

export { ButtonBack };

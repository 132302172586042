import { gql } from '@apollo/client';

export const GapSingleFragment = gql`
    fragment GapSingle on Gap {
        id
        capability_level_id
        gap
        company_id
    }
`;

import { EntityList } from '@lib/entity';
import type { CategoryDetailsEntityType as Entity } from './category-details-entity';
import { CategoryDetailsEntity } from './category-details-entity';


class CategoryDetailsEntityList extends EntityList<CategoryDetailsEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(CategoryDetailsEntity, items, count);
    }
}

export { CategoryDetailsEntityList };

import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { Button, TextField } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { FormStyled } from '@modules/layout/styled';
import { DialogTrigger, ExtendedFormik, Form } from '@modules/layout/organisms';
import { GetCapabilityAssessmentQuery } from '@modules/assessment/graphql';
import { AcknowledgeRecommendationRequest } from '@modules/roadmap/requests';
import { AcknowledgeRecommendationMutation } from '@modules/roadmap/graphql';

import type { RoadmapRecommendationEntity } from '@modules/roadmap/entities';
import type {
    AcknowledgeRecommendationMutationType,
    AcknowledgeRecommendationMutationVariables,
} from '@modules/types/graphql';
import {roadmapRecommendationTitle} from '@helpers';

type DialogAcknowledgeRecommendationProps = {
    roadmapRecommendation: RoadmapRecommendationEntity;
    capabilityAssessmentId: number;
};

const StyledForm = styled(Form)`
    width: 100%;
`;

const Info = styled.div`
    font-size: 1.4rem;
`;

const DialogAcknowledgeRecommendation = (
    props: DialogAcknowledgeRecommendationProps,
): React.ReactElement => {
    const { roadmapRecommendation, capabilityAssessmentId } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [acknowledgeRecommendation] = useMutation<
        AcknowledgeRecommendationMutationType,
        AcknowledgeRecommendationMutationVariables
    >(AcknowledgeRecommendationMutation);

    const initialValues = {
        notes: '',
    };

    const displayRecommendation = roadmapRecommendationTitle(roadmapRecommendation);

    const isAcknowledged = roadmapRecommendation.refinement.isAcknowledged();

    return (
        <>
            <DialogTrigger
                title='Acknowledge Recommendation'
                TriggerComponent={dialogTriggerProps => {
                    const { handleOpen } = dialogTriggerProps;

                    return (
                        <Button
                            variant={isAcknowledged ? 'contained' : 'outlined'}
                            disabled={isAcknowledged}
                            onClick={handleOpen}
                        >
                            Acknowledge
                        </Button>
                    );
                }}
            >
                {dialogTriggerProps => {
                    const { handleClose } = dialogTriggerProps;

                    return (
                        <ExtendedFormik
                            validateOnChange={false}
                            validateOnBlur={false}
                            initialValues={initialValues}
                            onSubmit={async values => {
                                try {
                                    const variables = new AcknowledgeRecommendationRequest(values, {
                                        roadmapRecommendationId: roadmapRecommendation.id,
                                    });

                                    const {
                                        data: acknowledgeRecommendationData,
                                    } = await acknowledgeRecommendation({
                                        variables,
                                        refetchQueries: [
                                            {
                                                query: GetCapabilityAssessmentQuery,
                                                variables: { surveyId: capabilityAssessmentId },
                                            },
                                        ],
                                        awaitRefetchQueries: true,
                                    });

                                    if (
                                        acknowledgeRecommendationData?.acknowledgeRecommendation?.id
                                    ) {
                                        enqueueSuccess('Recommendation successfully acknowledge!');
                                        handleClose();
                                    } else {
                                        enqueueError(
                                            'An error occurred when acknowledging recommendation!',
                                        );
                                    }
                                } catch (e) {
                                    throw e;
                                }
                            }}
                        >
                            {formikProps => {
                                const {
                                    values,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    isSubmitting,
                                } = formikProps;

                                return (
                                    <StyledForm>
                                        <FormStyled.FormColumn>
                                            <FormStyled.FormRow>
                                                <Info>{displayRecommendation}</Info>
                                            </FormStyled.FormRow>

                                            <FormStyled.FormRow>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    id='notes'
                                                    name='notes'
                                                    label='Notes'
                                                    placeholder='Enter the notes'
                                                    value={values.notes}
                                                    error={!!errors.notes}
                                                    helperText={errors.notes}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </FormStyled.FormRow>

                                            <FormStyled.FormRowSubmit>
                                                <Button type='submit' loading={isSubmitting}>
                                                    Acknowledge
                                                </Button>

                                                <Button
                                                    variant='outlined'
                                                    disabled={isSubmitting}
                                                    onClick={handleClose}
                                                >
                                                    Cancel
                                                </Button>
                                            </FormStyled.FormRowSubmit>
                                        </FormStyled.FormColumn>
                                    </StyledForm>
                                );
                            }}
                        </ExtendedFormik>
                    );
                }}
            </DialogTrigger>
        </>
    );
};

export { DialogAcknowledgeRecommendation };

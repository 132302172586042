import { useLocationQuery } from '@modules/shared/hooks';

import type { LocationQuery } from '@modules/shared/hooks';
import { CommonHelper } from '@helpers';

export type ProjectsTableFiltersQuery = {
    filters: {
        status?: string;
        search?: string;
        assessmentTypes?: string[];
        maturityLevelId?: string[];
    };
    query: LocationQuery[0];
    setQuery: LocationQuery[1];
};

const useProjectsTableFiltersQuery = () => {
    const [query, setQuery] = useLocationQuery();

    const result = {
        filters: {
            status: query.status ? (query.status as string) : undefined,
            search: query.search ? (query.search as string) : undefined,
            assessmentTypes: CommonHelper.multipleQueryFormatter(query.assessmentTypes as string),
            maturityLevelId: CommonHelper.multipleQueryFormatter(query.maturityLevelId as string),
        },
        query,
        setQuery,
    };

    return result;
};

export { useProjectsTableFiltersQuery };

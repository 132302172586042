import { useMutation } from '@apollo/client';
import { routes } from '@config/routes';
import { createRouteUrl } from '@lib/routing';
import { Button } from '@material-ui/core';
import { useCurrentUser } from '@modules/auth/hooks';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { Loader } from '@modules/layout/moleculas';
import { TableColumn, TableSortS3Data } from '@modules/layout/organisms/table';
import { TableSortLearnMenuCard } from '@modules/layout/organisms/table/table-sort-learn-menu-card';
import { PageStyled } from '@modules/layout/styled';
import { GetAllMenuCardQuery } from '@modules/learn-tko/graphql';
import { DeleteMenuCardMutation } from '@modules/learn-tko/graphql/mutations';
import { useAllMenuCard, useS3VideoLink } from '@modules/learn-tko/hooks';
import {
    DeleteMenuCardMutationType,
    DeleteMenuCardMutationVariables,
} from '@modules/types/graphql';
import { useNavigate } from '@reach/router';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FileUploadModal } from './file-upload-modal';

const Main = styled(PageStyled.Main)`
    margin-top: 64px;
`;
const ButtonCreateLink = styled(Button)`
    min-width: 205px;
    padding: 14px;
`;
const columns: TableColumn[] = [
    {
        id: 'key',
        label: 'Video Title',
    },
    {
        id: 'value',
        label: 'Video Link',
    },
    {
        id: 'actions',
        label: 'Actions',
    },
];

const ViewVideoLink = (): React.ReactElement => {
    const navigate = useNavigate();

    const [videoCreateModal, setCreateVideoModal] = useState<boolean>(false);

    const { s3data, loading: s3DataLoading } = useS3VideoLink();
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const { currentUser, loading: currentUserLoading } = useCurrentUser();

    const canDeleteUser = currentUser.hasSuperAdminPermission() || currentUser.hasAdminPermission();

    const [deleteMenuCard] = useMutation<
        DeleteMenuCardMutationType,
        DeleteMenuCardMutationVariables
    >(DeleteMenuCardMutation);

    const handleClickRedirect = (learnTkoId: any) => {
        navigate(createRouteUrl(routes.editLearnMenuCard, { id: learnTkoId }));
    };

    const handleDeleteVideo = async (learnTkoId: number): Promise<void> => {
        try {
            const { data: deleteLearnTkoData } = await deleteMenuCard({
                variables: { menuId: learnTkoId },
                refetchQueries: [{ query: GetAllMenuCardQuery }],
                awaitRefetchQueries: true,
            });

            if (deleteLearnTkoData?.deleteMenuCard?.id) {
                enqueueSuccess('Menu Card successfully deleted!');
            } else {
                enqueueError('An error occurred while deleting the menu card!');
            }
        } catch (e) {
            console.log(e);
            enqueueError(e);
        }
    };

    const handleVideoMenuModal = () => setCreateVideoModal(true);
    const handleCloseCreateVideoModal = () => setCreateVideoModal(false);

    if (s3DataLoading) {
        return <Loader />;
    }

    return (
        <div>
            <PageStyled.Bridge>
                <ButtonCreateLink onClick={handleVideoMenuModal}>Video Upload</ButtonCreateLink>
            </PageStyled.Bridge>
            <Main style={{ display: 'unset' }}>
                <TableSortS3Data
                    columns={columns}
                    canUserCopy={canDeleteUser}
                    handleDeleteVideo={handleDeleteVideo}
                    learnTkoAllDetails={s3data}
                    learnTkoLoading={s3DataLoading}
                    openModal={handleVideoMenuModal}
                />
            </Main>
            <FileUploadModal open={videoCreateModal} onClose={handleCloseCreateVideoModal} />
        </div>
    );
};

export { ViewVideoLink };

import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

type VideoPlayerProps = {
    srcUrl?: string;
};

const ReactPlayerStyle = styled.div`
    border-radius: 40px;
    overflow: hidden;
    .react-player {
        max-width: 740px !important;
        width: 100% !important;
        height: 100% !important;
    }
`;

const VideoPlayer: React.FC<VideoPlayerProps> = ({ srcUrl }) => {
    return (
        <ReactPlayerStyle>
            <ReactPlayer url={srcUrl} controls className='react-player' />
        </ReactPlayerStyle>
    );
};

export default VideoPlayer;

import React from 'react';
import styled, { css } from 'styled-components';

import { breakpoints } from '@modules/layout/helpers';
import { Header, Footer } from '@modules/layout/organisms';
import { useLocation } from '@reach/router';

type BaseLayoutProps = {
    children: React.ReactNode;
};

const Root = styled.div<{ invertBackground?: boolean }>`
    width: 100%;
    min-height: 100%;
    position: relative;

    * {
        font-family: 'Montserrat', Arial, sans-serif;
    }

    ${props =>
        props.invertBackground &&
        css`
            background: #f5f5f5;
            min-height: 100%;
        `}
`;

const Container = styled.div<{ askTkoBackground: boolean; learnTkoBackground: boolean }>`
    min-height: calc(100% - 120px);
    padding: ${({ askTkoBackground, learnTkoBackground }) =>
        askTkoBackground || learnTkoBackground ? '0px 0px' : '32px'};

    > * {
        height: 100%;
    }

    ${breakpoints.down('xs')} {
        height: 100%;
        margin: 12px;
    }
`;

const BaseLayout = (props: BaseLayoutProps): React.ReactElement => {
    const { children } = props;
    const location = useLocation();
    const invertBackground = location?.pathname === '/registry';
    const resourcesBackground = location?.pathname === '/welcome-page';
    const askTkoBackground = location?.pathname === '/resources/ask-tko';
    const learnTkoBackground: any = location?.pathname === '/resources/learn-tko';

    return (
        <Root invertBackground={invertBackground}>
            <Header />
            <Container
                askTkoBackground={askTkoBackground}
                learnTkoBackground={learnTkoBackground}
                id={'app-page-container'}
                style={
                    resourcesBackground
                        ? {
                              background:
                                  'linear-gradient(90deg, rgb(255, 255, 255)53%, rgb(38, 87, 226)109%)',
                          }
                        : {}
                }
            >
                {children}
            </Container>
            <Footer />
        </Root>
    );
};

export { BaseLayout };

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { CompanyDetailsEntity } from '@modules/company/entities';

import type { QueryHookOptions } from '@apollo/client';
import type { GetCompanyDetailQueryType } from '@modules/types/graphql';
import { GetCompanyDetailQuery } from '../graphql/queries';

const useCompanyDetail = (options?: QueryHookOptions<GetCompanyDetailQueryType>) => {
    const queryResult = useQuery<GetCompanyDetailQueryType>(GetCompanyDetailQuery, {
        ...options,
    });

    const companyDetail = useEntity(
        () => new CompanyDetailsEntity(queryResult.data?.getCompanyDetails ?? null),
        [queryResult.data],
    );

    const result = {
        companyDetail,
        ...queryResult,
    };

    return result;
};

export { useCompanyDetail };

import React, { useMemo } from 'react';
import { format } from 'date-fns';

import {Table, TableHead, TableBody, TableCell, TableRow, Paper, TableContainer} from '@material-ui/core';
import {TextCommendLog, User} from '@modules/types/graphql';
import {RoadmapRecommendationEntity} from '@modules/roadmap/entities';

export enum RefineLogType {
  note = 'Notes',
  dependency = 'Dependencies',
  constraint = 'Constraints',
  status = 'Status',
}

export type RefineLogItem = {
  comment: string;
  created_at: Date;
  created_by: User;
  type: RefineLogType;
}

export const RoadmapHistory = (
  props: { roadmapRecommendation: RoadmapRecommendationEntity },
): React.ReactElement => {
  const { roadmapRecommendation } = props;
  const loggedEvents = useMemo(() => {
    const { refinement } = roadmapRecommendation;
    if (refinement?.id === -1) return [];

    const notesLog = refinement.notesLog.map((note) => {
      return {
        ...note,
        type: RefineLogType.note,
        created_at: note?.created_at ? new Date(note?.created_at) : null,
      };
    });
    const dependenciesLog = refinement.dependenciesLog.map((note) => {
      return {
        ...note,
        type: RefineLogType.dependency,
        created_at: note?.created_at ? new Date(note?.created_at) : null,
      };
    });
    const constraintsLog = refinement.constraintsLog.map((note) => {
      return {
        ...note,
        type: RefineLogType.constraint,
        created_at: note?.created_at ? new Date(note?.created_at) : null,
      };
    });
    const statusLog = [
      {
        comment: refinement.status,
        created_at: refinement?.refined_at ? new Date(refinement?.refined_at) : null,
        created_by: refinement.refined_by,
        type: RefineLogType.status
      },
    ];

    const allEvents = ([] as any).concat(statusLog, notesLog, dependenciesLog, constraintsLog) as RefineLogItem[];
    allEvents.sort((a,b) => {
      if (a.created_at > b.created_at) {
        return 1;
      }
      if (a.created_at < b.created_at) {
        return -1;
      }

      return 0;
    });

    return allEvents;
  }, [roadmapRecommendation]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loggedEvents.length ? (
            loggedEvents.map((event: RefineLogItem, i: number) => (
              <TableRow key={i}>
                <TableCell>
                  {event?.created_at
                    ? format(event?.created_at, 'dd.MM.yyyy hh:mm')
                    : ''}
                </TableCell>
                <TableCell>
                  {event.created_by?.first_name} {event.created_by?.last_name}
                </TableCell>
                <TableCell>
                  <p><b>{event?.type}:</b></p>
                  <p>{event?.comment}</p>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <tr className='is-empty-results'>
              <td colSpan={6}>No history</td>
            </tr>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

import { gql } from '@apollo/client';

import { GapSingleFragment } from '../fragments';

export const CreateGapMutation = gql`
    mutation CreateGap($capabilityLevelId: Int!, $gap: String!, $companyId: Int, $projectId: Int!) {
        createGap(
            capability_level_id: $capabilityLevelId
            gap: $gap
            company_id: $companyId
            project_id: $projectId
        ) {
            ...GapSingle
        }
    }
    ${GapSingleFragment}
`;

import { useQuery } from '@apollo/client';
import type { QueryHookOptions } from '@apollo/client';
import { GetListAssetCategoryQuery } from '../graphql';
import { useEntity } from '@lib/entity';
import { AssetCategoryEntityList } from '../entities';

const useAssetCategory = (options?: QueryHookOptions) => {
    const queryResult = useQuery(GetListAssetCategoryQuery, options);

    const { entities: assetCategory, count } = useEntity(
        () => new AssetCategoryEntityList(queryResult.data?.listAssetCategory ?? null),
        [queryResult.data],
    );

    const result = {
        assetCategory,
        count,
        ...queryResult,
    };

    return result;
};

export { useAssetCategory };

import { useQuery } from '@apollo/client';

import type { QueryHookOptions } from '@apollo/client';

import { GetOrganizationDetailsQuery } from '../graphql/queries/get-organization-details';

const useOrganizationDetails = (options?: QueryHookOptions) => {
    const { data, loading } = useQuery(GetOrganizationDetailsQuery, options);

    const result = {
        organizationDetails: data?.getOrganizationDetails,
        loading,
    };

    return result;
};

export { useOrganizationDetails };

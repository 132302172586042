import { EntityList } from '@lib/entity';
import type { Entity } from './default-type-entity';
import { DefalutTypeEntity } from './default-type-entity';


class DefaultTypeEntityList extends EntityList<DefalutTypeEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(DefalutTypeEntity, items, count);
    }
}

export { DefaultTypeEntityList };

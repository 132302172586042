import { gql } from '@apollo/client';

import { ImplementationTaskSingleFragment } from '../fragments';

export const UpdateImplementationTaskMutation = gql`
    mutation UpdateImplementationTask(
        $taskId: Int!
        $recommendationId: Int!
        $task: String!
        $tFlag: Boolean!
    ) {
        updateImplementationTask(
            task_id: $taskId
            recommendation_id: $recommendationId
            task: $task
            t_flag: $tFlag
        ) {
            ...ImplementationTaskSingle
        }
    }
    ${ImplementationTaskSingleFragment}
`;

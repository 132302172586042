import { gql } from '@apollo/client';

import { ProjectListFragment } from '../fragments';

export const GetProjectListQuery = gql`
    query GetProjectAssignedUsers {
        getProjectAssignedUsers {
            ...ProjectAssignedUsers
        }
    }
    ${ProjectListFragment}
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { FilterList, MoreVert } from '@material-ui/icons';

const ColumnFilter = (props: any): React.ReactElement => {
    const { column } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton className='is-filter-button' onClick={handleClick}>
                <FilterList />
            </IconButton>

            <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                <div
                    style={{
                        padding: 10,
                        minWidth: 200,
                    }}
                >
                    <Typography variant='h5' style={{ marginBottom: 10, fontWeight: 'bold' }}>
                        Filter {column.render('Header').toLowerCase()}
                    </Typography>
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                </div>
            </Menu>
        </>
    );
};

export { ColumnFilter };

import React from 'react';

import { Loader } from '@modules/layout/moleculas';
import { useCurrentUser } from '@modules/auth/hooks';

import type { Route } from '@config/routes';

const PublicRoute = (props: Route): React.ReactElement | null => {
    const { Component, ...otherProps } = props;

    const { loading: currentUserLoading } = useCurrentUser();

    if (currentUserLoading) {
        return <Loader />;
    }

    return <Component {...otherProps} />;
};

export { PublicRoute };

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { UserDetailEntity } from '@modules/users/entities';

import type { QueryHookOptions } from '@apollo/client';
import type { GetUserDetailQueryType, GetUserDetailQueryVariables } from '@modules/types/graphql';
import { GetUserDetailQuery } from '../graphql';

const useUserDetail = (
    options?: QueryHookOptions<GetUserDetailQueryType, GetUserDetailQueryVariables>,
) => {
    const queryResult = useQuery<GetUserDetailQueryType, GetUserDetailQueryVariables>(
        GetUserDetailQuery,
        {
            fetchPolicy: 'network-only',
            ...options,
        },
    );

    const userDetail = useEntity(
        () => new UserDetailEntity(queryResult.data?.getUserDetail ?? null),
        [queryResult.data],
    );

    const result = {
        userDetail,
        ...queryResult,
    };

    return result;
};

export { useUserDetail };

import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useMutation } from '@apollo/client';

import { TextField, Button } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import {
    AddConstraintsMutation,
    AddDependenciesMutation,
    AddNotesMutation,
    GetProjectImplementationQuery,
} from '@modules/implementation/graphql';

const AddNotesForm = (props: any): React.ReactElement => {
    const { project, recommendationImplementation, type } = props;
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [note, setNote] = useState('');
    const [showForm, setShowForm] = useState(false);

    const [addNotes, { loading: addNotesLoading }] = useMutation(AddNotesMutation);
    const [addDependencies, { loading: addDependenciesLoading }] = useMutation(
        AddDependenciesMutation,
    );
    const [addConstraints, { loading: addConstraintsLoading }] = useMutation(
        AddConstraintsMutation,
    );

    const reset = () => {
        setShowForm(false);
        setNote('');
    };

    const handleSubmit = async (e: FormEvent): Promise<void> => {
        e.preventDefault();

        if (type === 'notes') {
            const { data } = await addNotes({
                variables: {
                    roadmapRecommendationId: recommendationImplementation.roadmapRecommendationId,
                    notes: note,
                },
                refetchQueries: [
                    { query: GetProjectImplementationQuery, variables: { id: project.id } },
                ],
                awaitRefetchQueries: true,
            });

            reset();

            if (data) {
                enqueueSuccess('Successfully completed!');
            } else {
                enqueueError('An error occurred!');
            }
        }

        if (type === 'dependencies') {
            const { data } = await addDependencies({
                variables: {
                    roadmapRecommendationId: recommendationImplementation.roadmapRecommendationId,
                    dependencies: note,
                },
                refetchQueries: [
                    { query: GetProjectImplementationQuery, variables: { id: project.id } },
                ],
                awaitRefetchQueries: true,
            });

            reset();

            if (data) {
                enqueueSuccess('Successfully completed!');
            } else {
                enqueueError('An error occurred!');
            }
        }

        if (type === 'constraints') {
            const { data } = await addConstraints({
                variables: {
                    roadmapRecommendationId: recommendationImplementation.roadmapRecommendationId,
                    constraints: note,
                },
                refetchQueries: [
                    { query: GetProjectImplementationQuery, variables: { id: project.id } },
                ],
                awaitRefetchQueries: true,
            });

            reset();

            if (data) {
                enqueueSuccess('Successfully completed!');
            } else {
                enqueueError('An error occurred!');
            }
        }
    };

    const handleChangeNote = (e: ChangeEvent<HTMLInputElement>) => setNote(e.target.value as any);

    return (
        <>
            {showForm ? (
                <form method='post' onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <TextField
                        style={{ marginTop: '16px' }}
                        fullWidth
                        multiline
                        rows={2}
                        id='note'
                        name='note'
                        value={note}
                        onChange={handleChangeNote}
                    />

                    <div style={{ marginTop: '16px' }}>
                        <Button
                            type='submit'
                            onSubmit={handleSubmit}
                            style={{ marginRight: '10px' }}
                        >
                            Save {type}
                        </Button>
                        <Button variant='outlined' onClick={() => setShowForm(false)}>
                            Cancel
                        </Button>
                    </div>
                </form>
            ) : (
                <div style={{ margin: '16px 0' }}>
                    <Button variant='outlined' onClick={() => setShowForm(true)}>
                        Add <span>{type}</span>
                    </Button>
                </div>
            )}
        </>
    );
};

export { AddNotesForm };

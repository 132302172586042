import { gql } from '@apollo/client';

import { RecommendationImplementationFragment } from './recommendation-implementation';

export const ImplementationFragment = gql`
    fragment Implementation on Implementation {
        project_id
        super_critical {
            ...RecommendationImplementation
        }
        critical {
            ...RecommendationImplementation
        }
        high_critical {
            ...RecommendationImplementation
        }
        high {
            ...RecommendationImplementation
        }
        medium_high {
            ...RecommendationImplementation
        }
        medium {
            ...RecommendationImplementation
        }
        low_medium {
            ...RecommendationImplementation
        }
        low {
            ...RecommendationImplementation
        }
        minimal_low {
            ...RecommendationImplementation
        }
        minimal {
            ...RecommendationImplementation
        }
    }
    ${RecommendationImplementationFragment}
`;

import React, { useEffect, useMemo } from 'react';

import { ListStyled } from '@modules/layout/styled';

import { GapByRisk, GapCapabilityAssessment } from '@modules/assessment/gap-by-risk';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { ReportRiskRow } from '@modules/assessment/organisms/report-components-list/report-risk-row';
import { ProjectEntity } from '@modules/projects/entities';

type ReportComponentVIewProps = {
    gapList: GapCapabilityAssessment[];
    project?: ProjectEntity;
};

export const ReportRiskVIew = (props: ReportComponentVIewProps): React.ReactElement => {
    const { gapList, project } = props;

    if (!gapList.length) {
        return (
            <ListStyled.Empty>
                {project?.isrrimpact ? 'Factors not found' : 'Gaps not found'}
            </ListStyled.Empty>
        );
    }

    return (
        <TableContainer component={Paper}>
            {/*<GapByRiskView*/}
            {/*  key={riskLevelKey}*/}
            {/*  riskLevelKey={riskLevelKey}*/}
            {/*  label={availableGapLevelFieldLabels[index]}*/}
            {/*  gapCapabilityList={gapByRisk[riskLevelKey]}*/}
            {/*  open={isElementOpen}*/}
            {/*  onExpand={handleExpandComponent}*/}
            {/*/>*/}
            <Table>
                {project?.isrrimpact ? (
                    <TableHead>
                        <TableRow>
                            <TableCell>Factors</TableCell>
                            <TableCell>Impact Level</TableCell>
                            <TableCell>Component</TableCell>
                        </TableRow>
                    </TableHead>
                ) : (
                    <TableHead>
                        <TableRow>
                            <TableCell>Gap</TableCell>
                            <TableCell>Risk Level</TableCell>
                            <TableCell>Component</TableCell>
                            <TableCell>Capability</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                )}

                <TableBody>
                    {gapList.map((gapAssessment: GapCapabilityAssessment, index) => {
                        return (
                            <ReportRiskRow
                                project={props?.project}
                                key={gapAssessment.scoredGap.id}
                                gapAssessment={gapAssessment}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

import { EntityList } from '@lib/entity';

import type { Entity } from './all-assessment-type-entity';
import { AllAssessmentTypeEntity } from './all-assessment-type-entity';

class AllAssessmentTypeEntityList extends EntityList<AllAssessmentTypeEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(AllAssessmentTypeEntity, items, count);
    }
}

export { AllAssessmentTypeEntityList };

import React from 'react';
import { useParams, Redirect } from '@reach/router';

import { createRouteUrl } from '@lib/routing';
import { routes } from '@config/routes';
import { Loader } from '@modules/layout/moleculas';
import { PageStyled } from '@modules/layout/styled';
import { useAssignedProject } from '@modules/projects/hooks';
import { ProjectHeader } from '@modules/projects/moleculas';
import { AssessmentComponentsList } from '@modules/assessment/organisms';
import { useLocationQuery } from '@modules/shared/hooks';
import { CommonHelper } from '@helpers';
import { useCompanyAccess } from '@modules/company/hooks';

const ProjectAssessmentPage = (): React.ReactElement => {
    const params = useParams();
    const [query] = useLocationQuery();
    const { companyAccess, loading: companyLoading } = useCompanyAccess();
    let isAIAccess: boolean = companyAccess?.isAIAccess;

    const projectId = params.id ? +params.id : null;
    let variables: any = {};

    variables.id = projectId as number;

    if (query?.componentOwner) {
        variables.componentOwnerId = Number(query?.componentOwner);
    }

    if (query?.maturityLevel) {
        variables.maturityLevelId = Number(query?.maturityLevel);
    }

    const { project, loading: projectLoading } = useAssignedProject({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        skip: !projectId,
        variables,
    });

    if (!projectId) {
        return <Redirect to={routes.projects.path} />;
    }

    if (projectLoading || companyLoading) {
        return <Loader />;
    }

    if (!project.isNew() && !project.isAssessment()) {
        return (
            <Redirect
                noThrow
                to={createRouteUrl(routes.projectAssessmentReport, { id: projectId })}
            />
        );
    }

    return (
        <>
            <ProjectHeader
                name={project.name}
                description={project.description}
                projectStatus={project.getStatus()}
                assessmentTypeId={project.assessmentTypeId}
                isRiskRegistry={project.isRiskRegistry}
            />

            <PageStyled.Main>
                <AssessmentComponentsList
                    project={project}
                    isAIAccess={isAIAccess}
                    componentsAssessmentList={project.assessments}
                />
            </PageStyled.Main>
        </>
    );
};

export { ProjectAssessmentPage };

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { UserDetailsEntityList } from '@modules/users/entities';
import type { QueryHookOptions } from '@apollo/client';
import { GetUsersDetailsQuery } from '../graphql/queries';

const useUsersDetails = (options?: QueryHookOptions) => {
    const queryResult = useQuery(GetUsersDetailsQuery, options);

    const { entities: usersDetails } = useEntity(
        () => new UserDetailsEntityList(queryResult.data?.listUserDetails ?? null),
        [queryResult.data],
    );

    const result = {
        usersDetails,
        ...queryResult,
    };

    return result;
};

export { useUsersDetails };

import React from 'react';
import { useParams, Redirect } from '@reach/router';

import { routes } from '@config/routes';
import { Loader } from '@modules/layout/moleculas';
import { PageStyled } from '@modules/layout/styled';
import { ProjectHeader } from '@modules/projects/moleculas';
import { useRoadmapReport } from '@modules/roadmap/hooks';
import { ReportRoadmap } from '@modules/roadmap/organisms';
import { useLocationQuery } from '@modules/shared/hooks';
import { useAssignedProject } from '@modules/projects/hooks';

const ProjectRoadmapReportPage = (): React.ReactElement => {
    const params = useParams();
    const [query] = useLocationQuery();

    const projectId = params.id ? +params.id : null;
    let variables: any = {};

    variables.id = projectId as number;

    if (query?.componentOwner) {
        variables.componentOwnerId = Number(query?.componentOwner);
    }

    if (query?.maturityLevel) {
        variables.maturityLevelId = Number(query?.maturityLevel);
    }

    const { project, loading: projectLoading } = useAssignedProject({
        skip: !projectId,
        variables,
    });

    if (!projectId) {
        return <Redirect to={routes.projects.path} />;
    }

    if (projectLoading) {
        return <Loader />;
    }

    if (!project.isImplementation()) {
        return <Redirect noThrow to={routes.projects.path} />;
    }

    return (
        <>
            <ProjectHeader
                name={project.name}
                description={project.description}
                projectStatus={project.getStatus()}
                assessmentTypeId={project.assessmentTypeId}
                isRiskRegistry={project.isRiskRegistry}
            />

            <PageStyled.Main>
                <ReportRoadmap project={project} />
            </PageStyled.Main>
        </>
    );
};

export { ProjectRoadmapReportPage };

import React from 'react';
import { useCompany } from '@modules/company/hooks';
import { useActiveProjectsByCompany } from '@modules/projects/hooks';
import { Alert } from '@modules/ui/core';

type AlertCompanyRunningProjectsProps = {
    companyId: number;
};

const AlertCompanyRunningProjects = (
    props: AlertCompanyRunningProjectsProps,
): React.ReactElement => {
    const { companyId } = props;

    const { company, loading: loadingCompany } = useCompany({
        skip: !companyId,
        variables: { id: companyId as number },
    });

    const { projects, count, loading: loadingProjects } = useActiveProjectsByCompany({
        skip: !companyId,
        variables: { companyId: companyId as number },
    });

    if (loadingCompany || loadingProjects) return <></>;

    return (
        <>
            {count ? (
                <Alert
                    title={`Company ${company?.name} has running ${count} projects/assessment`}
                    content={
                        <>
                            <ul>
                                {projects.map((project: Record<any, any>, i: number) => (
                                    <li key={i}>{project?.name}</li>
                                ))}
                            </ul>
                        </>
                    }
                />
            ) : (
                ''
            )}
        </>
    );
};

export { AlertCompanyRunningProjects };

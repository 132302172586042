import React from 'react';
import { makeStyles } from '@material-ui/core';

import { white, turquoise } from '@modules/ui/colors';
import { TextField } from '@modules/layout/moleculas';

import type { Theme, TextFieldProps } from '@material-ui/core';

const useTextFieldStyles = makeStyles<Theme>(theme => ({
    root: {
        minWidth: 250,

        [theme.breakpoints.only('lg')]: {
            minWidth: 175,
        },
    },
}));

const useInputLabelStyles = makeStyles({
    root: {
        color: turquoise[100],
        fontSize: '1.2rem',
        letterSpacing: 0.5,
        opacity: '.42',
    },
    outlined: {
        '&$shrink': {
            transform: 'translate(14px, -6px) scale(0)',
        },
    },
    shrink: {},
});

const useOutlinedInputStyles = makeStyles({
    root: {
        backgroundColor: white[90],
        boxShadow: '0 0 0 rgba(0,0,0,.6)',
        transition: 'box-shadow .4s',

        '&:hover': {
            boxShadow: '2px 2px 12px rgba(0,0,0,.6)',
        },

        '&$focused': {
            boxShadow: '2px 2px 6px rgba(0,0,0,.6)',
        },

        '& $notchedOutline': {
            border: 'none',
        },
    },
    input: {
        color: turquoise[100],
        fontSize: '1.2rem',
        letterSpacing: 0.5,
    },
    focused: {},
    notchedOutline: {},
});

const FilterTextField = (props: TextFieldProps): React.ReactElement => {
    const { InputLabelProps, InputProps, ...otherProps } = props;

    const textFieldClasses = useTextFieldStyles();
    const inputLabelClasses = useInputLabelStyles();
    const outlinedInputClasses = useOutlinedInputStyles();

    return (
        <TextField
            classes={textFieldClasses}
            InputLabelProps={{
                classes: inputLabelClasses,
                ...InputLabelProps,
            }}
            InputProps={{
                classes: outlinedInputClasses,
                ...InputProps,
            }}
            {...otherProps}
        />
    );
};

export { FilterTextField };

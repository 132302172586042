import gql from 'graphql-tag';

export const UserFragment = gql`
    fragment User on User {
        id
        cognito_id
        username
        email
        first_name
        last_name
        is_admin
        is_super_admin
        company_id
    }
`;

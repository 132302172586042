import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';

import { UserEntity } from '@modules/users/entities';
import { PrioritizedRoadmapEntity } from '@modules/roadmap/entities';

import type { PrioritizedRoadmapEntityType } from '@modules/roadmap/entities';
import type { AssessmentReport } from '@modules/types/graphql';

export type Entity = Partial<
    Omit<AssessmentReport, 'prioritized_roadmap'> & {
        prioritized_roadmap: PrioritizedRoadmapEntityType;
    }
> | null;

@DecribeEntity('ImplementationReportEntity')
class ImplementationReportEntity extends BaseEntity {
    @Relation(() => UserEntity)
    programManager: UserEntity;

    @Relation(() => PrioritizedRoadmapEntity)
    prioritizedRoadmap: PrioritizedRoadmapEntity;

    hasProgramManager() {
        const result = this.programManager.exists();

        return result;
    }

    programManagerToString() {
        if (!this.hasProgramManager()) {
            return '';
        }

        const result = this.programManager.getFullName();

        return result;
    }
}

export { ImplementationReportEntity };

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { ComponentEntity } from '@modules/components/entities';
import { GetComponentQuery } from '@modules/components/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetComponentQueryType, GetComponentQueryVariables } from '@modules/types/graphql';

const useComponent = (
    options?: QueryHookOptions<GetComponentQueryType, GetComponentQueryVariables>,
) => {
    const queryResult = useQuery<GetComponentQueryType, GetComponentQueryVariables>(
        GetComponentQuery,
        options,
    );

    const component = useEntity(() => new ComponentEntity(queryResult.data?.getComponent ?? null), [
        queryResult.data,
    ]);

    const result = {
        component,
        ...queryResult,
    };

    return result;
};

export { useComponent };

import { gql } from '@apollo/client';

export const GetComponentQuery = gql`
    query GetComponent($id: Int!) {
        getComponent(id: $id) {
            id
            name
            company_id
            capabilities {
                id
                title
                key_factors
            }
        }
    }
`;

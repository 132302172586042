import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useNavigate, useParams } from '@reach/router';

import { routes } from '@config/routes';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { PageStyled } from '@modules/layout/styled';
import { Loader } from '@modules/layout/moleculas';
import { useCompanyDetail } from '@modules/company/hooks';
import { CompanyForm } from '@modules/company/organisms';
import { UpdateCompanyMutation } from '@modules/company/graphql';

import type { Page } from '@modules/types/page';
import type {
    UpdateCompanyMutationType,
    UpdateCompanyMutationVariables,
} from '@modules/types/graphql';
import { CompanyUpdateRequest, CompnayUpdateFormValues } from '@modules/projects/requests';
import { GetCompanyDetailsQuery } from '@modules/company/graphql/queries';

const Main = styled(PageStyled.Main)`
    margin-top: 48px;
`;

const FormWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-width: 625px;
`;

const CompanyEditPage = (props: Page): React.ReactElement => {
    const { title = 'Edit company' } = props;

    const navigate = useNavigate();
    const params = useParams();

    const companyId = params.id ? +params.id : null;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    // const { company, loading: companyLoading } = useCompany({
    //     skip: !companyId,
    //     variables: { id: companyId as number },
    // });
    // console.log(company, "company")

    const { companyDetail, loading: companyLoading } = useCompanyDetail({
        variables: { id: companyId as number },
    });
    const [updateCompany] = useMutation<UpdateCompanyMutationType, UpdateCompanyMutationVariables>(
        UpdateCompanyMutation,
    );
    console.log(companyDetail, 'companyDetail');
    const initialValues: CompnayUpdateFormValues = {
        id: companyDetail.id,
        userId: companyDetail.userId,
        name: companyDetail.name,
        address: companyDetail.address,
        phone: companyDetail.phone,
        contactName: companyDetail.contactName,
        email: companyDetail.email,
        packageType: companyDetail.packageType,
        subscriptionType: companyDetail.subscriptionType,
        isAskTko: companyDetail?.isAskTko,
        isLearnTko: companyDetail?.isLearnTko,
        schemaName: companyDetail?.schemaName,
    };

    const handleSubmit = async (values: CompnayUpdateFormValues): Promise<void> => {
        try {
            let variables = new CompanyUpdateRequest(values);
            console.log(variables, 'companyDetail variable');
            const { data: updateCompanyDetailData } = await updateCompany({
                variables: variables,
                refetchQueries: [{ query: GetCompanyDetailsQuery }],
                awaitRefetchQueries: true,
            });

            if (updateCompanyDetailData?.updateCompany?.user_id) {
                enqueueSuccess('Company successfully updated!');
                await navigate(routes.companies.path);
            } else {
                enqueueError('An error occurred while updating the company!');
            }
        } catch (e) {
            throw e;
        }
    };
    if (companyLoading) {
        return <Loader />;
    }

    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>{title}</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>

            <Main>
                <CompanyForm<'edit'>
                    type='edit'
                    initialValues={initialValues}
                    FormWrapComponent={FormWrap}
                    onSubmit={handleSubmit}
                />
            </Main>
        </>
    );
};

export { CompanyEditPage };

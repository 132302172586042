import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { Modal } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { GapCreateRequest, AddCustomGapRequest } from '@modules/custom-components/requests';
import { GapForm } from '@modules/custom-components/organisms';
import {
    GetGapsQuery,
    CreateGapMutation,
    AddCustomGapMutation,
} from '@modules/custom-components/graphql';

import type { ModalProps } from '@modules/ui/core';
import type { GapCreateFormValues } from '@modules/custom-components/requests';
import type {
    CreateGapMutationType,
    CreateGapMutationVariables,
    AddCustomGapMutationType,
    AddCustomGapMutationVariables,
} from '@modules/types/graphql';
import { ProjectEntity } from '@modules/projects/entities';

type GapCreateFormModalProps = Omit<ModalProps, 'title' | 'children'> & {
    capabilityLevelId: number;
    capabilityAssessmentId?: number;
    project?: ProjectEntity;
    onSuccess?: () => Promise<void> | void;
};

const FormWrap = styled.div`
    width: 100%;
`;

const GapCreateModal = (props: GapCreateFormModalProps) => {
    const {
        capabilityLevelId,
        capabilityAssessmentId,
        project,
        onClose,
        onSuccess,
        ...otherProps
    } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [createGap] = useMutation<CreateGapMutationType, CreateGapMutationVariables>(
        CreateGapMutation,
    );

    const [addCustomGap] = useMutation<AddCustomGapMutationType, AddCustomGapMutationVariables>(
        AddCustomGapMutation,
    );

    const initialValues = {
        capabilityLevelId,
        companyId: project?.companyId as number,
        projectId: project?.id as number,
        gap: '',
    };

    const handleSubmit = async (values: GapCreateFormValues) => {
        try {
            let result = undefined;

            if (capabilityAssessmentId) {
                const variables = new AddCustomGapRequest({
                    gap: values.gap,
                    capabilityLevelId: values.capabilityLevelId,
                    surveyId: capabilityAssessmentId,
                    isAiGaps: false,
                    projectId: project?.id as number,
                });

                const { data: addCustomGapData } = await addCustomGap({
                    variables,
                    refetchQueries: [{ query: GetGapsQuery, variables: { capabilityLevelId } }],
                    awaitRefetchQueries: true,
                });

                result = addCustomGapData?.addCustomGap?.id;
            } else {
                const variables = new GapCreateRequest(values);

                const { data: createGapData } = await createGap({
                    variables,
                    refetchQueries: [{ query: GetGapsQuery, variables: { capabilityLevelId } }],
                    awaitRefetchQueries: true,
                });

                result = createGapData?.createGap?.id;
            }

            if (result) {
                enqueueSuccess('Gap successfully created!');

                if (onSuccess) {
                    await onSuccess();
                }

                onClose();
            } else {
                enqueueError('An error occurred while creating gap!');
            }
        } catch (e) {
            throw e;
        }
    };

    return (
        <Modal title='Create New Gap' onClose={onClose} {...otherProps}>
            <GapForm<'create'>
                type='create'
                initialValues={initialValues}
                FormWrapComponent={FormWrap}
                onSubmit={handleSubmit}
                onCancel={onClose}
            />
        </Modal>
    );
};

export { GapCreateModal };

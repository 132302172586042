import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import Auth from '@aws-amplify/auth';
import { useApolloClient } from '@apollo/client';
import { useNavigate, Link } from '@reach/router';
import { useMountedState } from 'react-use';
import { Formik, Form } from 'formik';
import { FormControl } from '@material-ui/core';

import { Button } from '@modules/ui/core';
import { white, gray } from '@modules/ui/colors';
import { routes } from '@config/routes';
import { Hint } from '@modules/layout/atoms';
import { TextField } from '@modules/layout/moleculas';
import { useAuth } from '@modules/auth/context';
import { useLoginAttempts } from '@modules/auth/hooks';

import type { CognitoUser } from 'amazon-cognito-identity-js';
import { LoginLogoutComponent } from '@helpers';

type SigninFormProps = {
    onSubmit: (user: CognitoUser | any) => void;
    className?: string;
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 450px;
    width: 100%;
    padding: 24px;
    background-color: ${white[100]};
    border: 1px solid ${gray[50]};
    border-radius: 4px;
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    > * {
        width: 100%;
        letter-spacing: 0.5px;
        text-align: center;
    }

    > h3 {
        font-size: 3rem;
        text-transform: uppercase;
    }

    > p {
        margin-top: 8px;
        font-size: 1.4rem;
    }
`;

const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 24px;
`;

const StyledFrom = styled(Form)`
    width: 100%;

    > * {
        margin-bottom: 18px;
    }
`;

const StyledForgotPassword = styled(Link)`
    font-size: 1.4rem;
    color: ${gray[10]};
    margin: 0 0 2rem;
    display: flex;
`;

const StyledHint = styled(Hint)`
    margin: 12px 0 0 0;
`;

const SigninForm = (props: SigninFormProps): React.ReactElement => {
    const { onSubmit } = props;

    const isMounted = useMountedState();
    const navigate = useNavigate();
    const { checkLoginAttempts, updateLoginAttempts } = useLoginAttempts();

    const apolloClient = useApolloClient();

    const { setAuthenticated, setUser } = useAuth();

    useEffect(() => {
        (async () => {
            await checkLoginAttempts();
        })();
    }, []);

    return (
        <Root {...props}>
            <Header>
                <h3>Welcome back</h3>
                <p>You can sign in to your account here</p>
            </Header>

            <Main>
                <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={yup.object({
                        email: yup
                            .string()
                            .required('Required field')
                            .email('Must be a valid email'),
                        password: yup
                            .string()
                            .required('Required field')
                            .min(6, payload => `Must be at least ${payload.min} characters`),
                    })}
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    initialStatus={{
                        error: '',
                    }}
                    onSubmit={async (values, helpers) => {
                        const { setStatus, setSubmitting } = helpers;

                        setSubmitting(true);
                        LoginLogoutComponent('login');

                        try {
                            const user = await Auth.signIn(values.email, values.password);
                            const accessToken = user?.signInUserSession?.accessToken ?? null;

                            if (accessToken === null) {
                                onSubmit(user);
                                return;
                            }

                            const tokenPayload = accessToken.payload;

                            setAuthenticated(true);
                            setUser(state => ({
                                ...state,
                                roles: tokenPayload['cognito:groups'],
                            }));

                            await apolloClient.resetStore();
                            await navigate(routes.welcome.path);
                        } catch (e: any) {
                            if (e.code === 'NotAuthorizedException') {
                                const loginAttempts = checkLoginAttempts();
                                updateLoginAttempts();
                            }
                            setStatus({ error: 'message' in e ? e.message : 'Unexepected error' });
                        } finally {
                            if (isMounted()) {
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {formikProps => {
                        const {
                            status,
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            isSubmitting,
                        } = formikProps;

                        return (
                            <StyledFrom>
                                <TextField
                                    fullWidth
                                    id='email'
                                    label='Email'
                                    placeholder='Enter your email'
                                    value={values.email}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <TextField
                                    fullWidth
                                    id='password'
                                    type='password'
                                    label='Password'
                                    placeholder='Enter your password'
                                    value={values.password}
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <StyledForgotPassword to={`/auth/forgot-password`}>
                                    <span>Forgot password?</span>
                                </StyledForgotPassword>

                                <FormControl fullWidth>
                                    <Button type='submit' loading={isSubmitting}>
                                        Log In
                                    </Button>

                                    {!!status.error ? (
                                        <StyledHint error text={status.error} />
                                    ) : null}
                                </FormControl>
                            </StyledFrom>
                        );
                    }}
                </Formik>
            </Main>
        </Root>
    );
};

export { SigninForm };

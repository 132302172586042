import { EntityList } from '@lib/entity';
import { RecommendationEntity } from './recommendation-entity';

import type { Entity } from './recommendation-entity';

class RecommendationEntityList extends EntityList<RecommendationEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(RecommendationEntity, items, count);
    }
}

export { RecommendationEntityList };

import { gql } from '@apollo/client';

import { CapabilityLevelSingleFragment } from '../fragments';

export const GetCapabilityLevelsQuery = gql`
    query GetCapabilityLevels($capabilityId: Int, $search: String) {
        listCapabilityLevels(capability_id: $capabilityId, filter: $search) {
            ...CapabilityLevelSingle
        }
    }
    ${CapabilityLevelSingleFragment}
`;

import React from 'react';
import styled from 'styled-components';

import { Button } from '@modules/ui/core';
import { CapabilityCreateFormModal } from '@modules/custom-components/organisms';

type CapabilityListToolbarProps = {
    componentId: number;
    canEditComponent: boolean;
    handleDeleteComponent: (componentId: number) => Promise<void>;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
`;

const Actions = styled.div``;

const ButtonCreateLink = styled(Button)`
    min-width: 205px;
    margin: 0px 4px;
`;

const CapabilityListToolbar = (props: CapabilityListToolbarProps): React.ReactElement => {
    const { componentId, canEditComponent, handleDeleteComponent } = props;

    const [openCreateModal, setOpenCreateModal] = React.useState<boolean>(false);

    const handleOpenCreateModal = (): void => setOpenCreateModal(true);
    const handleCloseCreateModal = (): void => setOpenCreateModal(false);

    const handleDeleteAssessment = (componentId: number): void => {
        handleDeleteComponent(componentId);
    };

    return (
        <Root>
            <Actions>
                <ButtonCreateLink onClick={handleOpenCreateModal}>
                    Create New Capability
                </ButtonCreateLink>
                {canEditComponent ? (
                    <ButtonCreateLink
                        variant='outlined'
                        onClick={() => handleDeleteAssessment(componentId)}
                    >
                        Delete Assessment
                    </ButtonCreateLink>
                ) : (
                    ''
                )}
                <CapabilityCreateFormModal
                    open={openCreateModal}
                    onClose={handleCloseCreateModal}
                    componentId={componentId}
                />
            </Actions>
        </Root>
    );
};

export { CapabilityListToolbar };

import { Button } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

type ActionProps = {
    handleNavigate: (link: string) => void;
};
type buttonDataProps = {
    id: number;
    btnTitle: string;
    btnLink: string;
};

const LearnTkoButtonStyle = styled.div`
    text-align: right;
`;
const ButtonCreateLink = styled(Button)`
    min-width: 205px;
    padding: 14px;
`;

const buttonData: buttonDataProps[] = [
    { id: 1, btnTitle: 'Video Link', btnLink: 'videoLink' },
    { id: 2, btnTitle: 'View Menu Card', btnLink: 'card' },
    { id: 3, btnTitle: 'View Learn TKO', btnLink: 'tko' },
];

const LearnTkoActionButtons = (props: ActionProps): React.ReactElement => {
    const { handleNavigate } = props;
    return (
        <>
            <LearnTkoButtonStyle>
                {buttonData?.map((item: buttonDataProps) => (
                    <ButtonCreateLink
                        key={item?.id}
                        onClick={() => handleNavigate(item?.btnLink)}
                        style={{ marginRight: '8px' }}
                    >
                        {item?.btnTitle}
                    </ButtonCreateLink>
                ))}
            </LearnTkoButtonStyle>
        </>
    );
};

export default LearnTkoActionButtons;

import React from 'react';

import { ListItemActions } from '@modules/layout/moleculas';
import { DialogProjectDelete } from '@modules/projects/moleculas';

type ProjectsListItemActionsProps = {
    onDelete: () => Promise<void>;
};

const ProjectsListItemActions = (props: ProjectsListItemActionsProps): React.ReactElement => {
    const { onDelete } = props;

    return (
        <ListItemActions>
            {() => {
                return <DialogProjectDelete onDelete={onDelete} />;
            }}
        </ListItemActions>
    );
};

export { ProjectsListItemActions };

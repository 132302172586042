import { gql } from '@apollo/client';
import { AskTkoDetailFragment } from '../fragments';

export const DeleteAskTkoDetailMutation = gql`
    mutation DeleteAskTkoDetail($tkoId: Int!) {
        deleteAskTkoDetail(tko_id: $tkoId) {
            ...AskTkoDetails
        }
    }
    ${AskTkoDetailFragment}
`;

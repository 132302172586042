import gql from 'graphql-tag';
import { AskTkoDetailFragment } from '../fragments';

export const UpdateAskTkoDetailMutation = gql`
    mutation UpdateAskTkoDetail($tkoId: Int!, $title: String!, $description: String) {
        updateAskTkoDetail(tko_id: $tkoId, title: $title, description: $description) {
            ...AskTkoDetails
        }
    }
    ${AskTkoDetailFragment}
`;

import { QueryHookOptions, useQuery } from '@apollo/client';
import { GetOrganizationTypeQuery } from '../graphql/queries';

const useOrganizationType = (options?: QueryHookOptions) => {
    const { data, loading } = useQuery(GetOrganizationTypeQuery, options);

    const result = {
        organization: data?.listOrganizationTypes,
        loading,
    };

    return result;
};

export { useOrganizationType };

import { gql } from '@apollo/client';

export const CreateAsset = gql`
    mutation CreateAsset(
        $name: String!, 
        $owner: Int!, 
        $pm: Int!, 
        $sh: [Int!],
        $alignmentType: String!, 
        $itemType: Int!, 
        $itemClass: Int!, 
        $riskGroup: Int!, 
        $riskFunction: Int!, 
        $assetCategory: Int!, 
        $assetType: Int!,
        $prevItemId: Int,
        $alignedAssets: [Int],
        $input: [alignmentInput!],
    ) {
        createAsset(
            name: $name,
            owner: $owner,
            pm: $pm,
            sh: $sh,
            alignment_type: $alignmentType,
            item_type_id: $itemType,
            item_class_id: $itemClass,
            risk_group_id: $riskGroup,
            risk_function_id: $riskFunction,
            asset_category_id: $assetCategory,
            asset_type_id: $assetType,
            prev_item_id: $prevItemId,
            alignedAssets: $alignedAssets,
            input: $input,
        ) {
            id
            name
        }
    }
`;

import { BaseEntity, Entity as DecribeEntity, RelationList } from '@lib/entity';
import { CapabilityEntityList } from '@modules/capabilities/entities';

import type { Component } from '@modules/types/graphql';
import type { CapabilityEntity, CapabilityEntityType } from '@modules/capabilities/entities';

export type Entity = Partial<
    Omit<Component, 'capabilities'> & {
        capabilities: CapabilityEntityType[] | null;
    }
> | null;

@DecribeEntity('ComponentEntity')
class ComponentEntity extends BaseEntity {
    id: number;
    name: string;
    companyId: number | null;
    assessmentTypeName: string;

    @RelationList(() => CapabilityEntityList)
    capabilities: CapabilityEntity[];

    constructor(component: Entity) {
        super(component);

        this.id = component?.id ?? -1;
        this.name = component?.name ?? '';
        this.companyId = component?.company_id ?? null;
        this.assessmentTypeName = component?.assessment_type_name ?? '';
    }

    isPredefined() {
        const result = this.companyId === null;

        return result;
    }
}

export { ComponentEntity };

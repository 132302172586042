import React from 'react';
import Amplify from 'aws-amplify';
import { createHistory, LocationProvider, Router } from '@reach/router';
import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core';

import { routes as configRoutes } from '@config/routes';
import { createApolloClient } from '@lib/apollo';
import { renderRoutes } from '@lib/routing';
import { ThemeProvider, GlobalStyles } from '@modules/ui/theme';
import { BaseLayout } from '@modules/layout/templates';
import { AuthProvider } from '@modules/auth/context';
import { awsConfig } from './aws-exports';

import 'chart.js/auto';
import {Chart} from 'chart.js'
import autocolors from 'chartjs-plugin-autocolors';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Amplify.configure(awsConfig);

Chart.register(autocolors);
Chart.register(ChartDataLabels);

const useSnackbarStyles = makeStyles({
    variantInfo: {
        fontSize: '1.4rem',
        whiteSpace: 'pre-line',
    },
    variantSuccess: {
        fontSize: '1.4rem',
        whiteSpace: 'pre-line',
    },
    variantWarning: {
        fontSize: '1.4rem',
        whiteSpace: 'pre-line',
    },
    variantError: {
        fontSize: '1.4rem',
        whiteSpace: 'pre-line',
    },
});

const history = createHistory(window as any);
const apolloClient = createApolloClient();

const App = (): React.ReactElement => {
    const routes = Object.values(configRoutes);

    const snackbarClasses = useSnackbarStyles();

    return (
        <LocationProvider history={history}>
            <ApolloProvider client={apolloClient}>
                <AuthProvider>
                    <ThemeProvider>
                        <SnackbarProvider
                            dense
                            maxSnack={3}
                            autoHideDuration={5000}
                            classes={snackbarClasses}
                        >
                            <GlobalStyles />
                            <BaseLayout>
                                <Router basepath='/'>{renderRoutes(routes)}</Router>
                            </BaseLayout>
                        </SnackbarProvider>
                    </ThemeProvider>
                </AuthProvider>
            </ApolloProvider>
        </LocationProvider>
    );
};

export { App };

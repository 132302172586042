import styled, { css } from 'styled-components';

import { white } from '@modules/ui/colors';
import { breakpoints } from '@modules/layout/helpers';

const CommonStyled = {
    Section: styled.section`
        width: 100%;
        position: relative;
    `,

    SectionTitle: styled.div`
        width: 100%;
        font-size: 2.2rem;
    `,

    Area: styled.div<{ spacing?: 'dense'; margin?: 'none' }>`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        background-color: ${white[100]};
        box-shadow: 0 0 6px 0 rgba(104, 115, 125, 0.15);

        ${p =>
            p.spacing === 'dense' &&
            css`
                padding: 8px;
            `}

        ${p =>
            p.margin === 'none' &&
            css`
                margin: 0;
            `}

        ${breakpoints.down('sm')} {
            height: initial;
        }
    `,

    AreaTopPanel: styled.div<{ margin?: 'none' }>`
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        margin-bottom: 24px;

        ${p =>
            p.margin === 'none' &&
            css`
                margin: 0;
            `}
    `,

    AreaBody: styled.div`
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 24px 0;
    `,
};

export { CommonStyled };

import gql from 'graphql-tag';
import { LearnCardMenuFragment } from '../fragments';

export const UpdateMenuCardMutation = gql`
    mutation UpdateMenuCard(
        $menuId: Int!
        $menuTitle: String!
        $description: String
        $imageUrl: String
    ) {
        updateMenuCard(
            menu_id: $menuId
            menu_title: $menuTitle
            description: $description
            image_url: $imageUrl
        ) {
            ...MenuCards
        }
    }
    ${LearnCardMenuFragment}
`;

import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const Loader = () => (
    <Root>
        <CircularProgress />
    </Root>
);

export { Loader };

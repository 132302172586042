import React from 'react';
import styled from 'styled-components';

import logoFooterPath from './images/itmg-logo.svg';

const Root = styled.div`
    width: 72px;

    > img {
        width: 100%;
        height: 100%;
    }
`;

const LogoFooter = (): React.ReactElement => {
    return (
        <Root>
            <img src={logoFooterPath} alt='' />
        </Root>
    );
};

export { LogoFooter };

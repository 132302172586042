import { gql } from '@apollo/client';
import { UserFragment } from '@modules/users/graphql';

export const GetRiskRegistryUsersQuery = gql`
    query listRiskRegistryUsers($roleId: [String]) {
        listRiskRegistryUsers(roleId: $roleId) {
            role {
                id
                name
            }
            user {
                ...User
            }
        }
    }
    ${UserFragment}
`;

import { gql } from '@apollo/client';

export const RoadmapRecommendationRefinementFragment = gql`
    fragment RoadmapRecommendationRefinement on RoadmapRecommendationRefinement {
        id
        status
        notes
        constraints
        dependencies
        refined_recommendation
        notesLog {
            id
            comment
            created_at
            created_by {
                id
                cognito_id
                username
                email
                first_name
                last_name
                is_admin
                is_super_admin
                company_id
            }
        }
        constraintsLog {
            id
            comment
            created_at
            created_by {
                id
                cognito_id
                username
                email
                first_name
                last_name
                is_admin
                is_super_admin
                company_id
            }
        }
        dependenciesLog {
            id
            comment
            created_at
            created_by {
                id
                cognito_id
                username
                email
                first_name
                last_name
                is_admin
                is_super_admin
                company_id
            }
        }
        refined_at
        refined_by {
            email
            first_name
            last_name
        }
    }
`;

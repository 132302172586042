import React from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { Close, KeyboardArrowDown } from '@material-ui/icons';

import { Chip } from '@modules/layout/atoms';

import type {
    AutocompleteProps as MuiAutocompleteProps,
    AutocompleteRenderInputParams,
    AutocompleteGetTagProps,
} from '@material-ui/lab';

type AutocompleteProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
> = Omit<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
    label: string;
    placeholder?: string;
    error?: boolean;
    helperText?: string | string[];
    renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
};

const useAutocompleteStyles = makeStyles({
    popupIndicator: {
        paddingTop: 4,
    },
});

const baseCloseIcon = <Close style={{ fontSize: 16 }} />;
const basePopupIcon = <KeyboardArrowDown style={{ fontSize: 16 }} />;

const Autocomplete = <
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
>(
    props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
): React.ReactElement => {
    const {
        label,
        placeholder,
        error,
        helperText,
        closeIcon,
        popupIcon,
        classes,
        getOptionLabel,
        getOptionSelected,
        renderInput,
        renderTags,
        ...otherProps
    } = props;

    const autocompleteClasses = { ...useAutocompleteStyles(), ...classes };

    const baseOptionSelected = React.useCallback(
        (option: any, value: any) => option?.id === value?.id,
        [],
    );

    const baseRenderInput = (params: AutocompleteRenderInputParams): React.ReactNode => (
        <TextField
            variant='outlined'
            label={label}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            {...params}
        />
    );

    const baseRenderTags = (value: T[], getTagProps: AutocompleteGetTagProps): React.ReactNode =>
        value.map((option, index) => {
            const tagProps = getTagProps({ index });
            const tagLabel = getOptionLabel?.(option) ?? '';

            return <Chip label={tagLabel} {...tagProps} />;
        });

    return (
        <MuiAutocomplete
            classes={autocompleteClasses}
            closeIcon={closeIcon ?? baseCloseIcon}
            popupIcon={popupIcon ?? basePopupIcon}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected ?? baseOptionSelected}
            renderInput={renderInput ?? baseRenderInput}
            renderTags={renderTags ?? baseRenderTags}
            {...otherProps}
        />
    );
};

export { Autocomplete };

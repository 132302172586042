import { gql } from '@apollo/client';

import { ImplementationTaskSingleFragment } from '../fragments';

export const GetImplementationTaskQuery = gql`
    query GetImplementationTasks($recommendationId: Int, $search: String, $companyId: Int) {
        listImplementationTask(
            recommendation_id: $recommendationId
            filter: $search
            company_id: $companyId
        ) {
            ...ImplementationTaskSingle
        }
    }
    ${ImplementationTaskSingleFragment}
`;

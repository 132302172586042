import type { CreateImplementationTaskMutationVariables } from '@modules/types/graphql';

export type ImpTaskCreateFormValues = {
    recommendationId: number;
    task: string;
    companyId?: number;
    isAiTasks: boolean;
};

class ImpTaskCreateRequest {
    recommendationId: CreateImplementationTaskMutationVariables['recommendationId'];
    task: CreateImplementationTaskMutationVariables['task'];
    companyId: CreateImplementationTaskMutationVariables['companyId'];
    isAiTasks: CreateImplementationTaskMutationVariables['isAiTasks'];

    constructor(values: ImpTaskCreateFormValues) {
        this.recommendationId = values.recommendationId;
        this.task = values.task;
        this.companyId = values.companyId;
        this.isAiTasks = values.isAiTasks;
    }
}

export { ImpTaskCreateRequest };

import {makeStyles} from '@material-ui/core';
import {gray} from '@modules/ui/colors';
import {Search} from '@material-ui/icons';
import React from 'react';

export const useBackgroundStyles = makeStyles({
  root: {
    backgroundColor: gray[60],
  },
});

export const searchIcon = <Search style={{ fontSize: 24 }} />;

export type ModifyRecommendationRequestFormValues = {
    refinedRecommendation: string;
    notes: string;
    constraints: string;
    dependencies: string;
};

export type ModifyRecommendationRequestExtra = {
    roadmapRecommendationId: number;
};

class ModifyRecommendationRequest {
    roadmapRecommendationId: number;
    refinedRecommendation: string;
    notes: string;
    constraints: string;
    dependencies: string;

    constructor(
        values: ModifyRecommendationRequestFormValues,
        extra: ModifyRecommendationRequestExtra,
    ) {
        this.roadmapRecommendationId = extra.roadmapRecommendationId;
        this.refinedRecommendation = values.refinedRecommendation;
        this.notes = values.notes;
        this.constraints = values.constraints;
        this.dependencies = values.dependencies;
    }
}

export { ModifyRecommendationRequest };

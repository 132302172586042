import React from 'react';
import styled from 'styled-components';
import { ContactPage } from './ContactPage';
import { ContactCreateRequest, ContactFormValues } from './request';
import { ContactUsDetailMutation } from './mutation';
import { ContactDetailsMutationType, ContactMutationVariables } from '@modules/types/graphql';
import { useMutation } from '@apollo/client';
import { useEnqueueStacks } from '@modules/layout/hooks';

const FormWrap = styled.div`
    // display: flex;
    // flex-wrap: wrap;
    // min-width: 625px;
    margin: 0px auto;
    max-width: 800px;
    width: 100%;
    border: 1px solid #ededed;
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.2);
`;

const TitleSize = styled.div`
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
`;

let initialValues: any = {
    name: '',
    email: '',
    phone: '',
    description: '',
};

const Contact = (): React.ReactElement => {
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [contactus] = useMutation<ContactDetailsMutationType, ContactMutationVariables>(
        ContactUsDetailMutation,
    );

    const handleSubmit = async (values: ContactFormValues): Promise<void> => {
        try {
            let variables = new ContactCreateRequest(values);
            const { data: contactUsData } = await contactus({
                variables,
                awaitRefetchQueries: true,
            });
            const contactdata = contactUsData?.contactUsDetail;
            if (contactdata?.name !== variables.name) {
                throw new Error('An error occurred while creating the project!');
            }
            enqueueSuccess(
                'Thank you, ' + contactdata.name + ', for reaching out to us. We appreciate it.',
            );
            window.location.reload();
        } catch (e) {
            throw e;
        }
    };

    return (
        <>
            <TitleSize>
                <h3>Contact Us</h3>
            </TitleSize>
            <ContactPage
                initialValues={initialValues}
                FormWrapComponent={FormWrap}
                onSubmit={handleSubmit}
            />
        </>
    );
};

export { Contact };

import { useQuery } from '@apollo/client';
import { useEntity } from '@lib/entity';
import { GetCreateUserRolesQuery } from '@modules/shared/graphql';
import type { QueryHookOptions } from '@apollo/client';
import type { GetCreateUserRolesQueryType, GetCreateUserRolesQueryVariables } from '@modules/types/graphql';
import { UserRolesEntityList } from '../entities';

const useCreateUserRoles = (options?: QueryHookOptions<GetCreateUserRolesQueryType, GetCreateUserRolesQueryVariables>) => {
    const queryResult = useQuery<GetCreateUserRolesQueryType, GetCreateUserRolesQueryVariables>(GetCreateUserRolesQuery, {
        ...options,
    });

    const { entities: createRoles, count } = useEntity(
        () => new UserRolesEntityList(queryResult.data?.getCreateUserRoles ?? null),
        [queryResult.data],
    );

    const result = {
        createRoles,
        count,
        ...queryResult,
    };

    return result;
};

export { useCreateUserRoles };

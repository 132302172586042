import { gql } from '@apollo/client';

export const GetListAssetCategoryQuery = gql`
    query listAssetCategory {
        listAssetCategory {
            id
            name
            risk_function_id
            item_id
        }
    }
`;

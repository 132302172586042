import React from 'react';
import { makeStyles, Checkbox } from '@material-ui/core';
import { RadioButtonUnchecked, RadioButtonChecked } from '@material-ui/icons';

import { gray } from '@modules/ui/colors';
import { concatenateClasses } from '@modules/ui/utils';

import type { CheckboxProps } from '@material-ui/core';

type CapabilityCheckboxProps = CheckboxProps;

const useCheckboxStyles = makeStyles({
    root: {
        padding: 0,
        marginRight: 12,
    },
    colorPrimary: {
        color: gray[10],

        '&$checked': {
            color: gray[10],
        },
    },
    checked: {},
});

const icon = <RadioButtonUnchecked style={{ fontSize: 20 }} />;
const checkedIcon = <RadioButtonChecked style={{ fontSize: 20 }} />;

const CapabilityCheckbox = (props: CapabilityCheckboxProps): React.ReactElement => {
    const { classes, ...otherProps } = props;

    const checkboxClasses = concatenateClasses(useCheckboxStyles(), classes);

    return (
        <Checkbox icon={icon} checkedIcon={checkedIcon} classes={checkboxClasses} {...otherProps} />
    );
};

export { CapabilityCheckbox };

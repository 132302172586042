import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { Modal } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import {
    ImpSubTaskCreateRequest,
    ImpSubTaskCreateFormValues,
} from '@modules/custom-components/requests';
import type { ModalProps } from '@modules/ui/core';

import { CreateImplementationSubTaskMutation } from '@modules/implementation/graphql/mutations';
import { CreateSubTaskMutationVariables } from '@modules/types/graphql';
import { CreateSubTaskMutationType } from '@modules/types/graphql';
import { GetSubTaskQuery } from '@modules/implementation/graphql';
import { ImplementationSubTaskForm } from './implementation-sub-task-form';
import { GetRecommendationImpSubTasksQuery } from '@modules/implementation/graphql/queries';

type SubTaskCreateFormModalProps = Omit<ModalProps, 'title' | 'children'> & {
    recommendationId: number;
    companyId?: number;
    taskId: number;
    onSuccess?: () => Promise<void> | void;
};

const FormWrap = styled.div`
    width: 100%;
`;

const ImplementationSubTaskCreateModal = (props: SubTaskCreateFormModalProps) => {
    const { recommendationId, companyId, taskId, onClose, onSuccess, ...otherProps } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [createSubTask] = useMutation<CreateSubTaskMutationType, CreateSubTaskMutationVariables>(
        CreateImplementationSubTaskMutation,
    );

    const initialValues = {
        recommendationId,
        companyId: 166,
        taskId: taskId,
        subTask: '',
        isAiSubTasks: true,
    };
    const handleSubmit = async (values: ImpSubTaskCreateFormValues) => {
        try {
            let result = undefined;

            if (recommendationId) {
                const variables = new ImpSubTaskCreateRequest({
                    recommendationId: recommendationId,
                    subTask: values.subTask,
                    taskId: taskId,
                    companyId: values.companyId,
                    isAiSubTasks: false,
                });

                const { data: createSubTaskData } = await createSubTask({
                    variables,
                    refetchQueries: [
                        {
                            query: GetRecommendationImpSubTasksQuery,
                            variables: { recommendationId, taskId },
                        },
                    ],
                    awaitRefetchQueries: true,
                });

                result = createSubTaskData?.createSubTask?.id;
            }

            if (result) {
                enqueueSuccess('Sub task successfully created!');

                if (onSuccess) {
                    await onSuccess();
                }

                onClose();
            } else {
                enqueueError('An error occurred while creating sub task!');
            }
        } catch (e) {
            throw e;
        }
    };

    return (
        <Modal title='Create New Sub Task' onClose={onClose} {...otherProps}>
            <ImplementationSubTaskForm<'create'>
                type='create'
                initialValues={initialValues}
                FormWrapComponent={FormWrap}
                onSubmit={handleSubmit}
                onCancel={onClose}
            />
        </Modal>
    );
};

export { ImplementationSubTaskCreateModal };

import React from 'react';
import styled from 'styled-components';

import { Button } from '@modules/ui/core';
import { gray } from '@modules/ui/colors';
import { useCurrentUser } from '@modules/auth/hooks';
import { DialogRecommendationDelete } from '@modules/custom-components/moleculas';

import type { RecommendationEntity } from '@modules/custom-components/entities';

type RecommendationListProps = {
    recommendations: RecommendationEntity[];
    loadingDelete: boolean;
    onEdit: (recommendation: RecommendationEntity) => void;
    onDelete: (id: number) => Promise<void>;
};

const Root = styled.div`
    width: 100%;
`;

const List = styled.div`
    width: 100%;

    > * {
        margin-bottom: 16px;
        border-bottom: 1px solid ${gray[50]};
    }
`;

const Item = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 8px 0;
`;

const Title = styled.div`
    font-size: 1.4rem;
`;

const Actions = styled.div`
    margin-left: auto;

    > * {
        margin-left: 8px;
    }
`;

const Empty = styled.div`
    font-size: 1.6rem;
    font-weight: 700;
`;

const RecommendationList = (props: RecommendationListProps): React.ReactElement => {
    const { recommendations, loadingDelete, onEdit, onDelete } = props;

    const { currentUser } = useCurrentUser();

    if (recommendations.length === 0) {
        return (
            <Root>
                <Empty>Recommendations not found</Empty>
            </Root>
        );
    }

    return (
        <Root>
            <List>
                {recommendations.map(recommendation => {
                    const canEditRecommendation = recommendation.isPredefined()
                        ? currentUser.isSuperAdmin
                        : true;

                    return (
                        <Item key={recommendation.id}>
                            <Title>{recommendation.recommendation}</Title>

                            {canEditRecommendation ? (
                                <Actions>
                                    <Button
                                        size='extra-small'
                                        onClick={() => onEdit(recommendation)}
                                    >
                                        Edit
                                    </Button>

                                    <DialogRecommendationDelete
                                        disabled={loadingDelete}
                                        onDelete={() => onDelete(recommendation.id)}
                                    />
                                </Actions>
                            ) : null}
                        </Item>
                    );
                })}
            </List>
        </Root>
    );
};

export { RecommendationList };

import { gql } from '@apollo/client';

export const GetSectorQuery = gql`
    query listSectors {
        listSectors {
            id
            name
        }
    }
`;

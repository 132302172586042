import { EntityList } from '@lib/entity';

import type { Entity } from './implementation-task-entity';
import { RecImpTaskEntity } from './rec-imp-task-entity';

class RecImpTaskEntityList extends EntityList<RecImpTaskEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(RecImpTaskEntity, items, count);
    }
}

export { RecImpTaskEntityList };

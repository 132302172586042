import { gql } from '@apollo/client';

export const CapabilityLevelSingleFragment = gql`
    fragment CapabilityLevelSingle on CapabilityLevel {
        id
        name
        description
        level
        company_id
    }
`;

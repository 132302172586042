import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useNavigate } from '@reach/router';

import { Button } from '@modules/ui/core';
import { createRouteUrl } from '@lib/routing';
import { routes } from '@config/routes';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { ListStyled } from '@modules/layout/styled';
import { useProjectRoles } from '@modules/projects/hooks';
import { GetProjectQuery } from '@modules/projects/graphql';
import { ApproveRoadmapByPMMutation, ApproveRoadmapMutation } from '@modules/roadmap/graphql';
import { RoadmapComponentsAssessmentListItem } from './roadmap-components-assessment-list-item';
import { ProjectAssessmentComponentsFilter } from '@modules/projects/organisms';

import { ProjectEntity } from '@modules/projects/entities';
import type { ComponentAssessmentEntity } from '@modules/assessment/entities';
import type {
    ApproveRoadmapMutationType,
    ApproveRoadmapMutationVariables,
} from '@modules/types/graphql';

import { ReportPrioritizedRoadmapList } from '@modules/roadmap/organisms';
import { useCurrentUser } from '@modules/auth/hooks';
import { UserEntity } from '@modules/users/entities';
import { ProjectAssessmentFilterValue } from '@modules/projects/organisms/project-assessment-components-filter/project-assessment-components-filter';
import {
    assessmentCollectCO,
    assessmentFilterHandler,
    roadmapCollecCO,
    roadmapFilterHandler,
} from '@modules/projects/organisms/project-assessment-components-filter/filter-handlers';
import { usePropState } from '@modules/shared/hooks/use-prop-state';
import {
    getPrioritizedRoadmapAssessmentMap,
    RecommendationAssessment,
} from '@modules/roadmap/recommendation-assessment-roadmap-report';
import { AuthContext } from '@modules/auth/context';

type RoadmapComponentsAssessmentListProps = {
    project: ProjectEntity;
    componentsAssessmentList: ComponentAssessmentEntity[];
};

const HeaderStakeholder = styled(ListStyled.HeaderInfo)`
    > p {
        padding-top: 2px;
    }
`;

const HeaderEditButton = styled(ListStyled.HeaderInfo)`
    margin-left: auto;
    margin-bottom: 10px;
`;

const Section = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 24px;
`;

const Toolbar = styled.div`
    width: 100%;
    margin-top: 24px;
`;

const RoadmapComponentsAssessmentList = (
    props: RoadmapComponentsAssessmentListProps,
): React.ReactElement => {
    const { project, componentsAssessmentList: componentsAssessmentRawList } = props;
    const recommendationAssessmentMapRaw: Map<number, RecommendationAssessment> = useMemo(
        () => getPrioritizedRoadmapAssessmentMap(project),
        [project.assessments],
    );

    const componentOwnerMap = assessmentCollectCO(componentsAssessmentRawList);
    roadmapCollecCO(componentOwnerMap, project.roadmap.dataSource);

    const componentOwnerList: UserEntity[] = Array.from(componentOwnerMap.values()).filter(
        user => user.id !== -1,
    );

    // const componentList: ComponentEntity[] = componentsAssessmentRawList.reduce((result, assessmnent) => {
    //     result.push(assessmnent.component);
    //     return result;
    // }, [] as ComponentEntity[])

    const [componentsAssessmentList, setComponentsAssessmentList] = usePropState(
        componentsAssessmentRawList,
    );
    const [prioritizedRoadmap, setPrioritizedRoadmap] = usePropState(project.roadmap);
    function onFilterChange(filterValue: ProjectAssessmentFilterValue) {
        assessmentFilterHandler(
            setComponentsAssessmentList,
            componentsAssessmentRawList,
            filterValue,
        );
        roadmapFilterHandler(
            setPrioritizedRoadmap,
            project.roadmap,
            recommendationAssessmentMapRaw,
            filterValue,
        );
    }

    const navigate = useNavigate();
    const context = useContext(AuthContext);

    const { currentUser, loading: currentUserLoading } = useCurrentUser();
    const isSuperAdmin = !!currentUser?.isSuperAdmin;
    const projectId = project.id;
    const roadmapStatus = project.roadmapStatus;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { projectRolesEntityList } = useProjectRoles({ variables: { projectId } });

    const hasPmRole = projectRolesEntityList.hasProgramManager();
    const hasStRole = projectRolesEntityList.hasStakeholder();

    const [approveRoadmap, { loading: approveRoadmapLoading }] = useMutation<
        ApproveRoadmapMutationType,
        ApproveRoadmapMutationVariables
    >(ApproveRoadmapMutation);

    const handleSubmitApproveByST = async (): Promise<void> => {
        try {
            const { data: approveRoadmapData } = await approveRoadmap({
                variables: { projectId },
                refetchQueries: [{ query: GetProjectQuery, variables: { id: projectId } }],
                awaitRefetchQueries: true,
            });
            console.log(approveRoadmapData?.approveRoadmap,'approveRoadmapData?.approveRoadmap');
            if (!approveRoadmapData?.approveRoadmap?.id) {
                enqueueSuccess('Roadmap successfully approved!');
                navigate(createRouteUrl(routes.projectImplementation, { id: projectId }));
            } else {
                enqueueError('An error occurred while approving roadmap!');
                // enqueueError('Last http errors object\n\n' + JSON.stringify((window as any).apolloLastErrors ?? ''));
            }
        } catch (e) {
            console.error(e);
            enqueueError('Unexepected error!');
        }
    };

    const [approveRoadmapByPM, { loading: approveRoadmapByPMLoading }] = useMutation(
        ApproveRoadmapByPMMutation,
    );

    const handleSubmitApproveByPM = async (): Promise<void> => {
        try {
            const { data: approveRoadmapData } = await approveRoadmapByPM({
                variables: { projectId },
                refetchQueries: [{ query: GetProjectQuery, variables: { id: projectId } }],
                awaitRefetchQueries: true,
            });

            if (approveRoadmapData?.approveRoadmapByPM?.id) {
                enqueueSuccess('Roadmap successfully approved by PM!');
                window.location.reload();
            } else {
                enqueueError('An error occurred while approving roadmap!');
                // enqueueError('Last http errors object\n\n' + JSON.stringify((window as any).apolloLastErrors ?? ''));
            }
        } catch (e) {
            console.error(e);
            enqueueError('Unexepected error!');
        }
    };

    const [expanded, setExpanded] = React.useState<number | boolean>(false);

    const handleToggleExpandItem = (panel: number) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean,
    ) => setExpanded(isExpanded ? panel : false);

    const [showEditView, setShowEditView] = React.useState<boolean>(true);

    const handleToggleEditView = (): void => setShowEditView(state => !state);

    const componentsLength = componentsAssessmentList.length;
    const hasComponents = componentsLength !== 0;

    const allComponentsApprovedByPm =
        componentsAssessmentList.filter(componentAssessment =>
            componentAssessment.isApprovedPmInRoadmap(),
        ).length === componentsLength;

    const allComponentsApprovedByStackholder =
        componentsAssessmentList.filter(componentAssessment =>
            componentAssessment.isApprovedStackholderInRoadmap(),
        ).length === componentsLength;

    const showPrioritizedView =
        (hasStRole || hasPmRole) && allComponentsApprovedByPm && roadmapStatus === 'Approved by PM';

    return (
        <ListStyled.Root>
            <ListStyled.Header>
                <h2>Recommendations</h2>

                {project.stakeholder.exists() ? (
                    <HeaderStakeholder>
                        <p>Stakeholder:</p>
                        <p>{project.stakeholder.getFullName()}</p>
                    </HeaderStakeholder>
                ) : null}

                {showPrioritizedView ? (
                    <HeaderEditButton>
                        <Button onClick={handleToggleEditView}>
                            {showEditView ? 'Hide roadmap' : 'Show roadmap'}
                        </Button>
                    </HeaderEditButton>
                ) : null}
            </ListStyled.Header>

            <ProjectAssessmentComponentsFilter
                componentOwnerList={componentOwnerList}
                hasRiskLevelFilter={true}
                onFilterChange={onFilterChange}
            />

            {showPrioritizedView && showEditView ? (
                <Section>
                    {prioritizedRoadmap.hasAnyRecommendations() ? (
                        <ReportPrioritizedRoadmapList
                            prioritizedRoadmap={prioritizedRoadmap}
                            project={project}
                        />
                    ) : (
                        <ListStyled.Empty>
                            Not found prioritized roadmap recommendations
                        </ListStyled.Empty>
                    )}
                </Section>
            ) : (
                <>
                    <ListStyled.List>
                        {hasComponents ? (
                            componentsAssessmentList.map((componentAssessment, index) => {
                                const isExpanded = componentAssessment.id === expanded;

                                return (
                                    <RoadmapComponentsAssessmentListItem
                                        key={`${componentAssessment.id}-${index}`}
                                        project={project}
                                        componentAssessment={componentAssessment}
                                        expanded={isExpanded}
                                        onExpand={handleToggleExpandItem(componentAssessment.id)}
                                    />
                                );
                            })
                        ) : (
                            <ListStyled.Empty>Not found components</ListStyled.Empty>
                        )}
                    </ListStyled.List>
                </>
            )}

            <Toolbar>
                {roadmapStatus !== 'Approved by PM' ? (
                    <>
                        {hasPmRole || isSuperAdmin ? (
                            <Button
                                loading={approveRoadmapByPMLoading}
                                onClick={handleSubmitApproveByPM}
                                disabled={context?.isFreeze ? true : !allComponentsApprovedByPm}
                            >
                                Generate Roadmap
                            </Button>
                        ) : null}
                    </>
                ) : (
                    <>
                        {/*{allComponentsApprovedByStackholder && (hasStRole || isSuperAdmin) ? (*/}
                        {hasStRole || isSuperAdmin ? (
                            <Button
                                loading={approveRoadmapLoading}
                                disabled={context?.isFreeze ? true : false}
                                onClick={handleSubmitApproveByST}
                            >
                                Approve roadmap by ST
                            </Button>
                        ) : null}
                    </>
                )}
            </Toolbar>
        </ListStyled.Root>
    );
};

export { RoadmapComponentsAssessmentList };

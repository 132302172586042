import { gql } from '@apollo/client';

import { RoadmapRecommendationFragment } from './roadmap-recommendation';

export const ComponentRecommendationFragment = gql`
    fragment ComponentRecommendation on ComponentRecommendation {
        roadmap_recommendation_id
        assessment_id
        component_id
        component {
            id
            name
        }
        recommendation {
            ...RoadmapRecommendation
        }
    }
    ${RoadmapRecommendationFragment}
`;

import { BaseEntity, Entity as DecribeEntity, RelationList } from '@lib/entity';

import type { CategoryDetails } from '@modules/types/graphql';
import type { CategoryTypeDetailsEntityType } from './category-type-details-entity';
import { CategoryTypeDetailsEntityList } from './category-type-details-entity-list';
import { CategoryTypeDetailsEntity } from './category-type-details-entity';

export type CategoryDetailsEntityType = Partial<
    Omit<CategoryDetails, 'categoryType'> & {
        categoryType: CategoryTypeDetailsEntityType[] | null;
    }
> | null;

@DecribeEntity('CategoryDetailsEntity')
class CategoryDetailsEntity extends BaseEntity {
    id: number;
    name: string;
    itemId: number;

    @RelationList(() => CategoryTypeDetailsEntityList)
    categoryType: CategoryTypeDetailsEntity[];

    constructor(categoryType: CategoryDetailsEntityType) {
        super(categoryType);

        this.id = categoryType?.id ?? -1;
        this.name = categoryType?.name ?? '';
        this.itemId = categoryType?.item_id ?? -1;
    }
}

export { CategoryDetailsEntity };

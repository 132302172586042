import { QueryHookOptions, useQuery } from '@apollo/client';
import { GetAllMenuCardQueryType } from '@modules/types/graphql';
import { GetAllMenuCardQuery } from '../graphql';
import { useEntity } from '@lib/entity';
import { AllMenuCardsEntityList } from '../entities';

const useAllMenuCard = (option?: QueryHookOptions<GetAllMenuCardQueryType>) => {
    const queryResult = useQuery<GetAllMenuCardQueryType>(GetAllMenuCardQuery, option);

    const { entities: allMenuCards, count } = useEntity(
        () => new AllMenuCardsEntityList(queryResult.data?.getAllMenuCard ?? null),
        [queryResult.data],
    );

    const result = {
        allMenuCards,
        count,
        ...queryResult,
    };
    return result;
};
export { useAllMenuCard };

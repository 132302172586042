import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { Button, TextField } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { FormStyled } from '@modules/layout/styled';
import { DialogTrigger, ExtendedFormik, Form } from '@modules/layout/organisms';
import { GetCapabilityAssessmentQuery } from '@modules/assessment/graphql';
import { ModifyRecommendationRequest } from '@modules/roadmap/requests';
import { ModifyRecommendationMutation } from '@modules/roadmap/graphql';

import type { RoadmapRecommendationEntity } from '@modules/roadmap/entities';
import type {
    ModifyRecommendationMutationType,
    ModifyRecommendationMutationVariables,
} from '@modules/types/graphql';
import {roadmapRecommendationTitle} from '@helpers';

type DialogModifyRecommendationProps = {
    roadmapRecommendation: RoadmapRecommendationEntity;
    capabilityAssessmentId: number;
};

const StyledForm = styled(Form)`
    width: 100%;
`;

const DialogModifyRecommendation = (props: DialogModifyRecommendationProps): React.ReactElement => {
    const { roadmapRecommendation, capabilityAssessmentId } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [modifyRecommendation] = useMutation<
        ModifyRecommendationMutationType,
        ModifyRecommendationMutationVariables
    >(ModifyRecommendationMutation);

    const initialValues = {
        refinedRecommendation: roadmapRecommendationTitle(roadmapRecommendation),
        constraints: roadmapRecommendation.refinement.constraints,
        dependencies: roadmapRecommendation.refinement.dependencies,
        notes: roadmapRecommendation.refinement.notes,
    };

    const isModified = roadmapRecommendation.refinement.isModified();

    return (
        <>
            <DialogTrigger
                title='Modify Recommendation'
                TriggerComponent={dialogTriggerProps => {
                    const { handleOpen } = dialogTriggerProps;

                    return (
                        <Button
                            variant={isModified ? 'contained' : 'outlined'}
                            disabled={isModified}
                            onClick={handleOpen}
                        >
                            Modify
                        </Button>
                    );
                }}
            >
                {dialogTriggerProps => {
                    const { handleClose } = dialogTriggerProps;

                    return (
                        <ExtendedFormik
                            validateOnChange={false}
                            validateOnBlur={false}
                            initialValues={initialValues}
                            onSubmit={async values => {
                                try {
                                    const variables = new ModifyRecommendationRequest(values, {
                                        roadmapRecommendationId: roadmapRecommendation.id,
                                    });

                                    const {
                                        data: modifyRecommendationData,
                                    } = await modifyRecommendation({
                                        variables,
                                        refetchQueries: [
                                            {
                                                query: GetCapabilityAssessmentQuery,
                                                variables: { surveyId: capabilityAssessmentId },
                                            },
                                        ],
                                        awaitRefetchQueries: true,
                                    });

                                    if (modifyRecommendationData?.modifyRecommendation?.id) {
                                        enqueueSuccess('Recommendation successfully modified!');
                                        handleClose();
                                    } else {
                                        enqueueError(
                                            'An error occurred when modifieing recommendation!',
                                        );
                                    }
                                } catch (e) {
                                    throw e;
                                }
                            }}
                        >
                            {formikProps => {
                                const {
                                    values,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    isSubmitting,
                                } = formikProps;

                                return (
                                    <StyledForm>
                                        <FormStyled.FormColumn>
                                            <FormStyled.FormRow>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    id='refinedRecommendation'
                                                    name='refinedRecommendation'
                                                    label='Recommendation'
                                                    placeholder='Enter the recommendation'
                                                    value={values.refinedRecommendation}
                                                    error={!!errors.refinedRecommendation}
                                                    helperText={errors.refinedRecommendation}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </FormStyled.FormRow>

                                            <FormStyled.FormRow>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    id='constraints'
                                                    name='constraints'
                                                    label='Constraints'
                                                    placeholder='Enter the constraints'
                                                    value={values.constraints}
                                                    error={!!errors.constraints}
                                                    helperText={errors.constraints}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </FormStyled.FormRow>

                                            <FormStyled.FormRow>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    id='dependencies'
                                                    name='dependencies'
                                                    label='Dependencies'
                                                    placeholder='Enter the dependencies'
                                                    value={values.dependencies}
                                                    error={!!errors.dependencies}
                                                    helperText={errors.dependencies}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </FormStyled.FormRow>

                                            <FormStyled.FormRow>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    id='notes'
                                                    name='notes'
                                                    label='Notes'
                                                    placeholder='Enter the notes'
                                                    value={values.notes}
                                                    error={!!errors.notes}
                                                    helperText={errors.notes}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </FormStyled.FormRow>

                                            <FormStyled.FormRowSubmit>
                                                <Button type='submit' loading={isSubmitting}>
                                                    Modify
                                                </Button>

                                                <Button
                                                    variant='outlined'
                                                    disabled={isSubmitting}
                                                    onClick={handleClose}
                                                >
                                                    Cancel
                                                </Button>
                                            </FormStyled.FormRowSubmit>
                                        </FormStyled.FormColumn>
                                    </StyledForm>
                                );
                            }}
                        </ExtendedFormik>
                    );
                }}
            </DialogTrigger>
        </>
    );
};

export { DialogModifyRecommendation };

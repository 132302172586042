import { gql } from '@apollo/client';

import { CompanyFragment } from '../fragments';

export const GetCompaniesCountQuery = gql`
    query GetCompaniesCount {
        listCompaniesCount {
            ...Company
        }
    }
    ${CompanyFragment}
`;

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { CapabilityEntity } from '@modules/capabilities/entities';
import { GetCapabilityQuery } from '@modules/custom-components/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetCapabilityQueryType, GetCapabilityQueryVariables } from '@modules/types/graphql';

const useCapability = (
    options?: QueryHookOptions<GetCapabilityQueryType, GetCapabilityQueryVariables>,
) => {
    const queryResult = useQuery<GetCapabilityQueryType, GetCapabilityQueryVariables>(
        GetCapabilityQuery,
        options,
    );

    const capability = useEntity(
        () => new CapabilityEntity(queryResult.data?.getCapability ?? null),
        [queryResult.data],
    );

    const result = {
        capability,
        ...queryResult,
    };

    return result;
};

export { useCapability };

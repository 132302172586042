import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';

import { TextField, Button } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';
import { DialogRequirementDelete } from '@modules/custom-components/moleculas';
import { DeleteRequirementMutation } from '@modules/custom-components/graphql';

import type {
    RequirementCreateFormValues,
    RequirementUpdateFormValues,
} from '@modules/custom-components/requests';
import type {
    DeleteRequirementMutationType,
    DeleteRequirementMutationVariables,
} from '@modules/types/graphql';

type RequirementFormProps<T> = FormProps<T> & {
    recommendationId?: number;
    withDelete?: boolean;
    onCancel?: () => void;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const RequirementForm = <T extends FormSemanticType = 'create'>(
    props: RequirementFormProps<
        // prettier-ignore
        T extends 'create' ? RequirementCreateFormValues :
        T extends 'edit' ? RequirementUpdateFormValues :
        RequirementCreateFormValues & RequirementUpdateFormValues
    >,
): React.ReactElement => {
    const {
        type,
        withDelete,
        recommendationId,
        initialValues,
        FormWrapComponent,
        onSubmit,
        onCancel,
    } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const isEdit = type === 'edit';

    const [deleteRequirement, { loading: deleteRequirementLoading }] = useMutation<
        DeleteRequirementMutationType,
        DeleteRequirementMutationVariables
    >(DeleteRequirementMutation);

    const handleClickDelete = async (): Promise<void> => {
        const editValues = initialValues as RequirementUpdateFormValues;

        if (!recommendationId || !editValues.requirementId) {
            return;
        }

        try {
            const { data: deleteRequirementData } = await deleteRequirement({
                variables: { requirementId: editValues.requirementId },
            });

            if (deleteRequirementData?.deleteRequirement) {
                enqueueSuccess('Requirement successfully deleted!');
            } else {
                enqueueError('An error occurred while deleting the requirement!');
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                requirement: yup.string().required('Required field'),
            })}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {formikProps => {
                const { values, errors, handleChange, handleBlur, isSubmitting } = formikProps;

                return (
                    <FormWrapComponent>
                        <StyledForm>
                            <TextField
                                fullWidth
                                id='requirement'
                                label='Requirement'
                                value={values.requirement}
                                error={!!errors.requirement}
                                helperText={errors.requirement}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    {isEdit ? 'Update Requirement' : 'Create Requirement'}
                                </SubmitButton>

                                {isEdit && withDelete ? (
                                    <DialogRequirementDelete
                                        disabled={deleteRequirementLoading}
                                        onDelete={handleClickDelete}
                                    />
                                ) : null}

                                <SubmitButton
                                    variant='text'
                                    disabled={isSubmitting}
                                    onClick={onCancel}
                                >
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    </FormWrapComponent>
                );
            }}
        </ExtendedFormik>
    );
};

export { RequirementForm };

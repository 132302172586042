import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';

import { Modal, Button, Autocomplete } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { useUsers } from '@modules/users/hooks';
import { useCompanies } from '@modules/company/hooks';
import { GetAssignedProjectQuery, UpdateProjectMutation } from '@modules/projects/graphql';

import type { ModalProps } from '@modules/ui/core';
import type { UserListEntity } from '@modules/users/entities';
import type { CompanyEntity } from '@modules/company/entities';
import type { ProjectEntity } from '@modules/projects/entities';
import type {
    UpdateProjectMutationType,
    UpdateProjectMutationVariables,
} from '@modules/types/graphql';

type ProjectUpdateModalProps = Omit<ModalProps, 'title' | 'children'> & {
    project: ProjectEntity;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const ProjectUpdateModal = (props: ProjectUpdateModalProps): React.ReactElement => {
    const { project, onClose, ...otherProps } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { users, loading: usersLoading } = useUsers();
    const { companies, loading: companiesLoading } = useCompanies();

    const [updateProject] = useMutation<UpdateProjectMutationType, UpdateProjectMutationVariables>(
        UpdateProjectMutation,
    );

    const getCompanyOptionLabel = React.useCallback(
        (option: CompanyEntity | null) => option?.name ?? '-',
        [],
    );

    const getUserOptionLabel = React.useCallback(
        (option: UserListEntity | null) => option?.getFullName() ?? '-',
        [],
    );

    const projectId = project.id;
    const company = companies.find(originCompany => originCompany.id === project.companyId);

    return (
        <Modal title='Update Project' onClose={onClose} {...otherProps}>
            <ExtendedFormik
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={yup.object({
                    stakeholder: yup.mixed().required('Required field'),
                    company: yup.mixed().required('Required field'),
                })}
                initialValues={{
                    projectId,
                    company,
                    stakeholder: project.stakeholder,
                }}
                onSubmit={async values => {
                    try {
                        if (!values.stakeholder || !values.company) {
                            return;
                        }

                        const { data: updateProjectData } = await updateProject({
                            variables: {
                                projectId: values.projectId,
                                stakeholderId: values.stakeholder.id,
                                companyId: values.company.id,
                            },
                            refetchQueries: [
                                {
                                    query: GetAssignedProjectQuery,
                                    variables: { id: values.projectId },
                                },
                            ],
                            awaitRefetchQueries: true,
                        });

                        if (updateProjectData?.updateProject?.id) {
                            enqueueSuccess('Project was successfully updated!');
                            onClose();
                        } else {
                            enqueueError('An error occurred while updating the project!');
                        }
                    } catch (e) {
                        throw e;
                    }
                }}
            >
                {formikProps => {
                    const { values, errors, setFieldValue, isSubmitting } = formikProps;

                    const handleChangeAutocomplete = (field: string) => (
                        _: React.ChangeEvent<{}>,
                        value: any | null,
                    ) => setFieldValue(field, value);

                    return (
                        <StyledForm>
                            <Autocomplete<CompanyEntity | null, false, false, false>
                                fullWidth
                                id='company'
                                label='Company'
                                placeholder='Choose the company'
                                options={companies}
                                value={values.company}
                                error={!!errors.company}
                                helperText={errors.company}
                                disabled={companiesLoading}
                                getOptionLabel={getCompanyOptionLabel}
                                onChange={handleChangeAutocomplete('company')}
                            />

                            <Autocomplete<UserListEntity | null, false, false, false>
                                fullWidth
                                id='stakeholder'
                                label='Stakeholder'
                                placeholder='Choose the stakeholder'
                                options={users}
                                value={values.stakeholder}
                                error={!!errors.stakeholder}
                                helperText={errors.stakeholder as string}
                                disabled={usersLoading}
                                getOptionLabel={getUserOptionLabel}
                                onChange={handleChangeAutocomplete('stakeholder')}
                            />

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    Update project
                                </SubmitButton>

                                <SubmitButton
                                    variant='text'
                                    disabled={isSubmitting}
                                    onClick={onClose}
                                >
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    );
                }}
            </ExtendedFormik>
        </Modal>
    );
};

export { ProjectUpdateModal };

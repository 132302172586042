import { gql } from '@apollo/client';

export const CompanyAccessFragment = gql`
    fragment CompanyAccess on CompanyAccess {
        id
        name
        project_count
        is_ask_tko
        is_learn_tko
        is_rr_access
        is_ai_access
        is_freeze
        is_create_project
    }
`;

import { QueryHookOptions, useQuery } from '@apollo/client';
import { GetAllLearnTkoDetailQueryType } from '@modules/types/graphql';

import { useEntity } from '@lib/entity';
import { AllLearnTkoDetailsEntityList } from '../entities';
import { GetAllLearnTkoDetailQuery } from '../graphql/queries';

const useAllLearnTkoDetails = (option?: QueryHookOptions<GetAllLearnTkoDetailQueryType>) => {
    const queryResult = useQuery<GetAllLearnTkoDetailQueryType>(GetAllLearnTkoDetailQuery, option);

    const { entities: learnTkoAllDetails, count } = useEntity(
        () => new AllLearnTkoDetailsEntityList(queryResult.data?.getAllLearnTkoDetail ?? null),
        [queryResult.data],
    );

    const result = {
        learnTkoAllDetails,
        count,
        ...queryResult,
    };
    return result;
};

export { useAllLearnTkoDetails };

import { gql } from '@apollo/client';

import { LearnTkoDetailsFragment } from '../fragments';

export const GetLearnTkoByIdQuery = gql`
    query GetLearnTkoDetailById($learnTkoId: Int!) {
        getLearnTkoDetailById(learnTko_id: $learnTkoId) {
            ...LearnTkoDetails
        }
    }
    ${LearnTkoDetailsFragment}
`;

import { Card, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

type OptionDataType = {
    id: number;
    optionIcon: string;
    title: string;
    desc: string;
};

type Props = {
    askTkoOptionData: OptionDataType[];
};

const CardImageStyle = styled.div`
    flex: 1;
    img {
        width: 60px;
        height: 60px;
    }
`;

const CardDetailStyle = styled.div`
    text-align: center;
    flex: 5;
    h1 {
        font-size: 30px;
    }
`;

const useStyles = makeStyles({
    askTkoCard: {
        background: 'linear-gradient(84deg, #10aeae, #2657e2)',
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
        color: '#fff',
        padding: '10px 15px',
        display: 'flex',
        borderRadius: '16px',
        justifyContent: 'start',
        alignItems: 'center',
        cursor: 'pointer',
        transition: 'all 0.4s',
        marginBottom: '30px',

        '&:hover': {
            background: '#2657e2d1',
        },
    },
});

const AskTkoOptionCard = (props: Props): React.ReactElement => {
    const { askTkoOptionData } = props;
    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            {askTkoOptionData?.map((ele: OptionDataType, id: number) => (
                <React.Fragment key={id}>
                    <Grid item md={4} sm={6} xs={12}>
                        <Card className={classes.askTkoCard}>
                            <CardImageStyle>
                                <img src={ele?.optionIcon || ''} alt='no images' />
                            </CardImageStyle>
                            <CardDetailStyle>
                                <h1>{ele?.title}</h1>
                            </CardDetailStyle>
                        </Card>
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    );
};

export { AskTkoOptionCard };

import { gql } from '@apollo/client';

export const GetWorkForceQuery = gql`
    query listWorkforces {
        listWorkforces {
            id
            name
        }
    }
`;

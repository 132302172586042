import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { GapRecommendationCountEntity } from '@modules/custom-components/entities';

import type { QueryHookOptions } from '@apollo/client';
import type { GetGapsRecommendationsCountQueryType, GetGapsRecommendationsCountVariables } from '@modules/types/graphql';
import { GetGapRecommendationCountQuery } from '../graphql/queries';

const useGapRecommendationsCount = (
    options?: QueryHookOptions<GetGapsRecommendationsCountQueryType, GetGapsRecommendationsCountVariables>,
) => {
    const queryResult = useQuery<GetGapsRecommendationsCountQueryType, GetGapsRecommendationsCountVariables>(
        GetGapRecommendationCountQuery,
        {
            ...options,
        }
    );

    const gapRecommendationCount = useEntity(
        () =>
            new GapRecommendationCountEntity(
                queryResult.data?.getGapsRecommendationsCount ?? null,
            ),
        [queryResult.data],
    );

    const result = {
        gapRecommendationCount,
        ...queryResult,
    };

    return result;
};

export { useGapRecommendationsCount };

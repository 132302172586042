import { gql } from '@apollo/client';

import { ComponentSingleFragment } from '../fragments';

export const CreateComponentMutation = gql`
    mutation CreateComponent($assessmentTypeId: Int!, $name: String!, $companyId: Int) {
        createComponent(
            assessment_type_id: $assessmentTypeId
            name: $name
            company_id: $companyId
        ) {
            ...ComponentSingle
        }
    }
    ${ComponentSingleFragment}
`;

import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { Modal } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { Loader } from '@modules/layout/moleculas';
import { useGap } from '@modules/custom-components/hooks';
import { GapUpdateRequest } from '@modules/custom-components/requests';
import { GapForm } from '@modules/custom-components/organisms';
import { GetGapsQuery, UpdateGapMutation } from '@modules/custom-components/graphql';

import type { ModalProps } from '@modules/ui/core';
import type { GapUpdateFormValues } from '@modules/custom-components/requests';
import type { UpdateGapMutationType, UpdateGapMutationVariables } from '@modules/types/graphql';

type GapEditFormModalProps = Omit<ModalProps, 'title' | 'children'> & {
    gapId: number;
    capabilityLevelId: number;
};

const FormWrap = styled.div`
    width: 100%;
`;

const GapEditModal = (props: GapEditFormModalProps) => {
    const { gapId, capabilityLevelId, onClose, ...otherProps } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { gap, loading: gapLoading } = useGap({
        skip: !gapId || !capabilityLevelId,
        variables: { gapId, capabilityLevelId },
    });

    const [updateGap] = useMutation<UpdateGapMutationType, UpdateGapMutationVariables>(
        UpdateGapMutation,
    );

    const initialValues = {
        gapId,
        capabilityLevelId,
        gap: gap.gap,
        projectId: 0 as number,
    };

    const handleSubmit = async (values: GapUpdateFormValues) => {
        try {
            const variables = new GapUpdateRequest(values);

            const { data: updateGapData } = await updateGap({
                variables,
                refetchQueries: [{ query: GetGapsQuery, variables: { capabilityLevelId } }],
                awaitRefetchQueries: true,
            });

            if (updateGapData?.updateGap?.id) {
                enqueueSuccess('Gap successfully updated!');
                onClose();
            } else {
                enqueueError('An error occurred while updating gap!');
            }
        } catch (e) {
            throw e;
        }
    };

    return (
        <Modal title='Update Gap' onClose={onClose} {...otherProps}>
            {gapLoading ? (
                <Loader />
            ) : (
                <GapForm<'edit'>
                    type='edit'
                    initialValues={initialValues}
                    FormWrapComponent={FormWrap}
                    onSubmit={handleSubmit}
                    onCancel={onClose}
                />
            )}
        </Modal>
    );
};

export { GapEditModal };

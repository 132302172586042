import React from 'react';

import { AssessmentComponentsListNotAssessedItem } from './assessment-components-list-not-assessed-item';
import { AssessmentComponentsListAssessedItem } from './assessment-components-list-assessed-item';

import type { ProjectEntity } from '@modules/projects/entities';
import type { ComponentAssessmentEntity } from '@modules/assessment/entities';

export type AssessmentComponentsListItemProps = {
    project: ProjectEntity;
    componentAssessment: ComponentAssessmentEntity;
    open: boolean;
    onExpand: (panel: number) => (event: React.ChangeEvent<{}>, expanded: boolean) => void;
};

const AssessmentComponentsListItem = (
    props: AssessmentComponentsListItemProps,
): React.ReactElement | null => {
    const { componentAssessment, ...otherProps } = props;

    const hasAssessCapability =
        componentAssessment.capabilities.filter(capabilityAssessment =>
            capabilityAssessment.isAssess(),
        ).length !== 0;

    if (hasAssessCapability) {
        return (
            <AssessmentComponentsListAssessedItem
                componentAssessment={componentAssessment}
                {...otherProps}
            />
        );
    }

    return (
        <AssessmentComponentsListNotAssessedItem
            componentAssessment={componentAssessment}
            {...otherProps}
        />
    );
};

export { AssessmentComponentsListItem };

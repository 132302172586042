import { gql } from '@apollo/client';

export const GetUserQuery = gql`
    query GetUser($id: Int!) {
        getUser(id: $id) {
            id
            email
            username
            first_name
            last_name
            is_admin
            is_super_admin
            company_id
        }
    }
`;

import React from 'react';
import styled from 'styled-components';
import { DocumentNode, useMutation } from '@apollo/client';

import { Modal } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';

import type {
    CreateMenuCardMutationType,
    CreateMenuCardMutationVariables,
} from '@modules/types/graphql';
import { AWS_REGION, S3_BUCKET, ACCESS_KEY_ID, SECRET_ACCESS_KEY } from '@config/environment';
import AWS from 'aws-sdk';
import { LearnTkoMenuForm } from '../learn-tko-menu-form';
import { CreateMenuCardMutation, GetAllMenuCardQuery } from '@modules/learn-tko/graphql';

type ProjectCreateModalProps = {
    refetchQuery?: DocumentNode;
    onClose: () => void;
    open: boolean;
};

const FormWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-width: 625px;
    overflow: auto;
    padding: 10px;
    width: 100%;
`;

const LearnTkoCreateModal = (props: ProjectCreateModalProps): React.ReactElement => {
    const { onClose, open, refetchQuery, ...otherProps } = props;

    const initialValues = {
        menuTitle: '',
        description: '',
        imageUrl: '',
    };
    const [progress, setProgress] = React.useState<any>(0);

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [createMenuCard] = useMutation<
        CreateMenuCardMutationType,
        CreateMenuCardMutationVariables
    >(CreateMenuCardMutation);

    const handleSubmit = async (values: any): Promise<void> => {
        try {
            AWS.config.update({
                accessKeyId: ACCESS_KEY_ID,
                secretAccessKey: SECRET_ACCESS_KEY,
            });

            const s3 = new AWS.S3({
                params: { Bucket: S3_BUCKET },
                region: AWS_REGION,
            });

            const params = {
                Bucket: S3_BUCKET,
                Key: values?.video_File?.name,
                Body: values?.video_File,
                ContentType: values?.video_File?.type,
            };

            var upload = s3
                .putObject(params)
                .on('httpUploadProgress', (evt: any) => {
                    setProgress(Math.round((evt.loaded / evt.total) * 100));
                })
                .promise();

            await upload.then(err => {
                enqueueSuccess('File uploaded successfully!');
            });
            const { data: createMenuCardData } = await createMenuCard({
                variables: {
                    menuTitle: values.menuTitle,
                    description: values.description,
                    imageUrl: values?.video_File?.name,
                },
                refetchQueries: [{ query: GetAllMenuCardQuery }],
                awaitRefetchQueries: true,
            });

            const card = createMenuCardData?.createMenuCard;

            if (!card?.id) {
                throw new Error('An error occurred while creating the card menu!');
            }
            enqueueSuccess('Card Menu is successfully created!');
            onClose();
        } catch (e) {
            throw e;
        }
    };

    return (
        <Modal title={'Create Menu Card'} open={open} onClose={onClose} {...otherProps}>
            <LearnTkoMenuForm
                type={'create'}
                initialValues={initialValues}
                onClose={onClose}
                FormWrapComponent={FormWrap}
                onSubmit={handleSubmit}
            />
        </Modal>
    );
};

export { LearnTkoCreateModal };

import { EntityList } from '@lib/entity';
import { CapabilityReportEntity } from './capability-report-entity';

import type { Entity } from './capability-report-entity';

class CapabilityReportEntityList extends EntityList<CapabilityReportEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(CapabilityReportEntity, items, count);
    }
}

export { CapabilityReportEntityList };

import { EntityList } from '@lib/entity';
import { ImplementationTaskEntity } from './implementation-task-entity';

import type { Entity } from './implementation-task-entity';

class ImplementationTaskEntityList extends EntityList<ImplementationTaskEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(ImplementationTaskEntity, items, count);
    }
}

export { ImplementationTaskEntityList };

import { gql } from '@apollo/client';

import { RecommendationImplementationFragment } from '../fragments';

export const StartImplementationMutation = gql`
    mutation StartImplementation($roadmapRecommendationId: Int!) {
        startImplementation(roadmap_recommendation_id: $roadmapRecommendationId) {
            ...RecommendationImplementation
        }
    }
    ${RecommendationImplementationFragment}
`;

import { gql } from '@apollo/client';

export const ProjectListFragment = gql`
    fragment ProjectAssignedUsers on ProjectAssignedUsers {
        id
        project_id
        user_id
        role_id
        project_name
    }
`;

import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';
import type { GapsRecommendationsCount } from '@modules/types/graphql';

export type GapRecommendationCountEntityType = Partial<GapsRecommendationsCount> | null;

@DecribeEntity('GapRecommendationCountEntity')
class GapRecommendationCountEntity extends BaseEntity {
    companyId: number;
    companyName: string;
    aiAccess: boolean;
    isAskTko: boolean;
    isLearnTko: boolean;
    projectsCount: number;
    remainingProjects: number;
    gapsCount: number;
    aiGapsCount: number;
    recommendationsCount: number;
    aiRecommendationsCount: number;
    tasksCount: number;
    aiTasksCount: number;
    subTasksCount: number;
    aiSubTasksCount: number;

    constructor(gapRecommendationCount: GapRecommendationCountEntityType) {
        super(gapRecommendationCount);

        this.companyId = gapRecommendationCount?.company_id ?? -1;
        this.companyName = gapRecommendationCount?.company_name ?? '';
        this.aiAccess = gapRecommendationCount?.ai_access ?? false;
        this.isAskTko = gapRecommendationCount?.is_ask_tko ?? false;
        this.isLearnTko = gapRecommendationCount?.is_learn_tko ?? false;
        this.projectsCount = gapRecommendationCount?.projects_count ?? -1;
        this.remainingProjects = gapRecommendationCount?.remaining_projects ?? -1;
        this.gapsCount = gapRecommendationCount?.gaps_count ?? -1;
        this.aiGapsCount = gapRecommendationCount?.ai_gaps_count ?? -1;
        this.recommendationsCount = gapRecommendationCount?.recommendations_count ?? -1;
        this.aiRecommendationsCount = gapRecommendationCount?.ai_recommendations_count ?? -1;
        this.tasksCount = gapRecommendationCount?.tasks_count ?? -1;
        this.aiTasksCount = gapRecommendationCount?.ai_tasks_count ?? -1;
        this.subTasksCount = gapRecommendationCount?.sub_tasks_count ?? -1;
        this.aiSubTasksCount = gapRecommendationCount?.ai_sub_tasks_count ?? -1;
    }
}

export { GapRecommendationCountEntity };

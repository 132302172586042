import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { RecommendationEntityList } from '@modules/custom-components/entities';
import { GetRecommendationsQuery } from '@modules/custom-components/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetRecommendationsQueryType,
    GetRecommendationsQueryVariables,
} from '@modules/types/graphql';

const useRecommendations = (
    options?: QueryHookOptions<GetRecommendationsQueryType, GetRecommendationsQueryVariables>,
) => {
    const queryResult = useQuery<GetRecommendationsQueryType, GetRecommendationsQueryVariables>(
        GetRecommendationsQuery,
        options,
    );

    const { entities: recommendations, count } = useEntity(
        () => new RecommendationEntityList(queryResult.data?.listRecommendations ?? null),
        [queryResult.data],
    );

    const result = {
        recommendations,
        count,
        ...queryResult,
    };

    return result;
};

export { useRecommendations };

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { CompanyEntityList } from '@modules/company/entities';
import { GetCompaniesQuery } from '@modules/company/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetCompaniesQueryType } from '@modules/types/graphql';

const useCompanies = (options?: QueryHookOptions<GetCompaniesQueryType>) => {
    const queryResult = useQuery<GetCompaniesQueryType>(GetCompaniesQuery, {
        ...options,
    });

    const { entities: companies, count } = useEntity(
        () => new CompanyEntityList(queryResult.data?.listCompanies ?? null),
        [queryResult.data],
    );
    console.log(companies, 'companies')
    const result = {
        companies,
        count,
        ...queryResult,
    };

    return result;
};

export { useCompanies };

import { Loader } from '@modules/layout/moleculas';
import React from 'react';
import styled from 'styled-components';
import ClearIcon from '@material-ui/icons/Clear';
import { Button } from '@modules/ui/core';

const DialogWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #7d7d7d0d;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
`;

const DialogBox = styled.div`
    padding: 20px;
    border-radius: 4px;
    box-shadow: 2px 2px 5px 4px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    color: #fff;
    background: #202123;
    font-size: 15px;
`;

const DialogHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const DialogTitle = styled.h2`
    margin: 0;
`;

const DialogContent = styled.p`
    margin: 20px 0;
`;

const DialogActions = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    button {
        padding: 8px 10px;
        border-radius: 5px;
        border: 1px solid #f0f0f0 !important;
        margin: 0px 2px;
        font-size: 16px;
        font-weight: 600;
        transition: 0.3s;
        cursor: pointer;
    }
    .delete-chat {
        &:hover {
            background: crimson;
        }
    }
`;

type DeleteDialogProps = {
    isOpen: boolean;
    chatLoading: boolean;
    chatId: number;
    title: string;
    content: string;
    onCancel: () => void;
    onDelete: (chatId: number) => Promise<void>;
};

const DeleteDialog = (props: DeleteDialogProps): React.ReactElement => {
    const { isOpen, title, content, onCancel, onDelete, chatId, chatLoading } = props;
    if (!isOpen) {
        return <></>;
    }

    return (
        <DialogWrapper>
            <DialogBox>
                <DialogHeader>
                    <DialogTitle>{title}</DialogTitle>
                    <button onClick={onCancel}>
                        <ClearIcon />
                    </button>
                </DialogHeader>
                <DialogContent>{content}</DialogContent>
                <DialogActions>
                    <button onClick={onCancel}>Cancel</button>
                    <button className='delete-chat' onClick={() => onDelete(chatId)}>
                        {chatLoading ? <Loader /> : 'Delete'}
                    </button>
                </DialogActions>
            </DialogBox>
        </DialogWrapper>
    );
};

export { DeleteDialog };

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useParams, useNavigate } from '@reach/router';

import { routes } from '@config/routes';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { PageStyled } from '@modules/layout/styled';
import type { Page } from '@modules/types/page';
import type {
    UpdateCardMenuMutationType,
    UpdateCardMenuMutationVariables,
} from '@modules/types/graphql';
import { LearnTkoMenuForm } from '@modules/learn-tko/organisms';
import { useMenuCard } from '@modules/learn-tko/hooks';
import { UpdateMenuCardMutation } from '@modules/learn-tko/graphql';
import { AWS_REGION, S3_BUCKET, ACCESS_KEY_ID, SECRET_ACCESS_KEY } from '@config/environment';
import AWS from 'aws-sdk';
import { Loader } from '@modules/layout/moleculas';

const Main = styled(PageStyled.Main)`
    margin-top: 48px;
`;

const FormWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-width: 625px;
`;

const EditLearnMenuCard = (props: Page): React.ReactElement => {
    const { title = 'Edit Learn Tko' } = props;

    const navigate = useNavigate();
    const params = useParams();
    const menuId = Number(params.id);
    const [menuCreateModal, setCreateMenuModal] = useState<boolean>(false);
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [editorDescription, setEditorDescription] = useState<string | any>('');
    const [progress, setProgress] = React.useState<any>(0);
    const handleCloseCreateMenuModal = () => setCreateMenuModal(false);

    const { menuCard, loading: menuCardLoading } = useMenuCard({
        variables: { menuId: menuId },
    });

    const [updateMenuCard] = useMutation<
        UpdateCardMenuMutationType,
        UpdateCardMenuMutationVariables
    >(UpdateMenuCardMutation);

    const initialValues: any = {
        menuTitle: menuCard?.menuTitle,
        description: menuCard?.description,
        imageUrl: '',
    };

    const handleSubmit = async (values: any): Promise<void> => {
        try {
            if (values?.video_File) {
                AWS.config.update({
                    accessKeyId: ACCESS_KEY_ID,
                    secretAccessKey: SECRET_ACCESS_KEY,
                });

                const s3 = new AWS.S3({
                    params: { Bucket: S3_BUCKET },
                    region: AWS_REGION,
                });

                const params = {
                    Bucket: S3_BUCKET,
                    Key: values?.video_File?.name,
                    Body: values?.video_File,
                    ContentType: values?.video_File?.type,
                };

                var upload = s3
                    .putObject(params)
                    .on('httpUploadProgress', (evt: any) => {
                        setProgress(Math.round((evt.loaded / evt.total) * 100));
                    })
                    .promise();

                await upload.then(err => {
                    enqueueSuccess('File uploaded successfully!');
                });
            }
            const imageURL = values.video_File ? values.video_File.name : menuCard?.imageUrl;
            const { data: updateMenuCardData } = await updateMenuCard({
                variables: {
                    menuId: menuId,
                    menuTitle: values.menuTitle,
                    description: values.description,
                    imageUrl: imageURL,
                },
            });
            if (updateMenuCardData?.updateMenuCard?.id) {
                enqueueSuccess('Learn tko menu card successfully updated!');
                navigate(routes.viewLearnMenuCards.path);
            } else {
                enqueueError('An error occurred while updating the learn tko menu card!');
            }
        } catch (e) {
            throw e;
        }
    };

    // useEffect(() => {
    //     setEditorDescription(singleLearnTko?.description)
    // }, [singleLearnTko])

    if (menuCardLoading) {
        return <Loader />;
    }

    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>{title}</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>

            <Main>
                <LearnTkoMenuForm
                    type={'edit'}
                    initialValues={initialValues}
                    onClose={handleCloseCreateMenuModal}
                    FormWrapComponent={FormWrap}
                    onSubmit={handleSubmit}
                />
            </Main>
        </>
    );
};

export { EditLearnMenuCard };

import React, { useContext } from 'react';

import { PageStyled } from '@modules/layout/styled';
import { AccordionListItem } from '@modules/layout/organisms';
import { useCurrentUser } from '@modules/auth/hooks';
import type { Page } from '@modules/types/page';

import styled from 'styled-components';
import { useAllItemTypes } from '@modules/registry/hooks';
import CategoryDataList from './category-data-list';
import { Loader } from '@modules/layout/moleculas';
import { AuthContext } from '@modules/auth/context';

const Root = styled.div`
    width: 100%;
    border-bottom: 1px solid #d3cece;
    margin-top: 20px;
    position: relative;
`;

const ItemDetailsPage = (props: Page): React.ReactElement => {
    const { title = 'Item Details' } = props;

    const { currentUser, loading: userLoading } = useCurrentUser();
    const { allItems, loading: allItemTypeLoading } = useAllItemTypes();
    const context = useContext(AuthContext);
    console.log(allItems, 'allItems');
    if (allItemTypeLoading || userLoading) {
        return <Loader />
    }
    // const handleEventData = (event: any) => {
    //     const assetCategoryId = event.target.value;
    //     if (assetCategoryId) {
    //         const assetTypeData: any = assetType.filter(asset => asset.categoryId === assetCategoryId);
    //         setAssetTypeDetails(assetTypeData);
    //     }
    // };
    return (
        <>
            {currentUser.hasSuperAdminPermission() &&
                <PageStyled.Header>
                    <PageStyled.HeaderTitle>
                        <h3>{title}</h3>
                    </PageStyled.HeaderTitle>
                    <PageStyled.HeaderTitle style={{ marginLeft: '20px' }}>
                        <a
                            href='/components'
                        >
                            <b style={{ fontSize: '2.4rem', fontWeight: 700, color: '#0057ff' }}><u>Category</u></b>
                        </a>
                    </PageStyled.HeaderTitle>
                </PageStyled.Header>
            }
            <Root>
                <span style={{ fontWeight: 700, fontSize: 'x-large' }}>Item Type</span>
                {allItems?.map(ele => {
                    return (
                        <React.Fragment key={ele.id}>
                            <AccordionListItem
                                title={
                                    ele.name
                                }
                                componentActionStatus={true}
                                subtitle=''
                                componentSearch={false}
                                SummaryInfoComponents={
                                    <></>
                                }
                                onClick={() => context?.handleSetSelectedItemId(ele?.id)}
                            >
                                <CategoryDataList categoryData={ele?.itemCategory} />
                            </AccordionListItem>
                        </React.Fragment>
                    );
                })}
            </Root >
        </>
    );
};

export { ItemDetailsPage };

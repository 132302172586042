import React from 'react';

import { Modal } from '@modules/ui/core';

import type { ModalProps } from '@modules/ui/core';

type InnerChildrenProps = {
    handleOpen: () => void;
    handleClose: () => void;
};

type DialogTriggerProps = Omit<ModalProps, 'open' | 'onClose'> & {
    children: (dialogTriggerProps: InnerChildrenProps) => React.ReactNode;
    TriggerComponent: (dialogTriggerProps: InnerChildrenProps) => React.ReactNode;
    className?: string;
};

const DialogTrigger = (props: DialogTriggerProps): React.ReactElement => {
    const { children, TriggerComponent, ...otherProps } = props;

    const [open, setOpen] = React.useState<boolean>(false);

    const handleOpen = (): void => setOpen(true);
    const handleClose = (): void => setOpen(false);

    return (
        <>
            {TriggerComponent({ handleOpen, handleClose })}

            <Modal open={open} onClose={handleClose} {...otherProps}>
                {children({ handleOpen, handleClose })}
            </Modal>
        </>
    );
};

export { DialogTrigger };

import React from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from '@reach/router';
import { useMutation } from '@apollo/client';
import { createRouteUrl } from '@lib/routing';
import { routes } from '@config/routes';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { PageStyled } from '@modules/layout/styled';
import { TableCellText } from '@modules/layout/atoms';
import { Loader, TableRow, TableCell } from '@modules/layout/moleculas';
import { Table } from '@modules/layout/organisms';
import { useCurrentUser } from '@modules/auth/hooks';
import { CompaniesListToolbar, CompaniesListItemActions } from '@modules/company/moleculas';
import { DeleteCompanyMutation } from '@modules/company/graphql';

import { TableColumn, TableSortCompany } from '@modules/layout/organisms/table';
import type { Page } from '@modules/types/page';
import { DeleteCompanyMutationType, DeleteCompanyMutationVariables } from '@modules/types/graphql';
import { useCompanyDetails } from '@modules/company/hooks/use-company-details';
import { GetCompanyDetailsQuery } from '@modules/company/graphql/queries';

const columns: TableColumn[] = [
    {
        id: 'name',
        label: 'Name',
    },
    {
        id: 'contact_name',
        label: 'Contact Name',
    },
    {
        id: 'address',
        label: 'Address',
    },
    {
        id: 'email',
        label: 'Email',
    },
    {
        id: 'phone',
        label: 'Phone',
    },
    {
        id: 'package_type',
        label: 'Packages',
    },
    {
        id: 'subscription_type',
        label: 'Subscriptions',
    },
    {
        id: 'expires_at',
        label: 'Expiration Date',
    },
    {
        id: 'is_ask_tko',
        label: 'Ask Tko',
    },
    {
        id: 'is_learn_tko',
        label: 'Learn Tko',
    },
    {
        id: 'schema_name',
        label: 'Database Name',
    },
    {
        id: 'actions',
        label: 'Actions',
    },
];

const Main = styled(PageStyled.Main)`
    margin-top: 64px;
`;

const CompaniesPage = (props: Page): React.ReactElement => {
    const { title = 'Companies' } = props;

    const { currentUser } = useCurrentUser();
    const navigate = useNavigate();

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { CompanyDetails, loading: IdCompanyLoading } = useCompanyDetails();

    const canDeleteCompany =
        currentUser.hasSuperAdminPermission() || currentUser.hasAdminPermission();

    const handleClickRedirectEdit = (id: number) => {
        navigate(createRouteUrl(routes.companyEdit, { id }));
    };

    const [deleteCompany] = useMutation<DeleteCompanyMutationType, DeleteCompanyMutationVariables>(
        DeleteCompanyMutation,
    );

    const handleDeleteCompany = async (companyId: number): Promise<void> => {
        try {
            const { data: deleteCompanyData } = await deleteCompany({
                variables: { id: companyId },
                refetchQueries: [{ query: GetCompanyDetailsQuery }],
                awaitRefetchQueries: true,
            });

            if (deleteCompanyData?.deleteCompany === null) {
                throw new Error('An error occurred while deleting the company!');
            }

            enqueueSuccess('Company successfully deleted!');
        } catch (e) {
            console.log(e);
            enqueueError(e);
        }
    };

    if (IdCompanyLoading) {
        return <Loader />;
    }

    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>{title}</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>

            <PageStyled.Bridge>
                <CompaniesListToolbar />
            </PageStyled.Bridge>

            <Main style={{ display: 'unset' }}>
                <TableSortCompany
                    columns={columns}
                    canDeleteCompany={canDeleteCompany}
                    handleDeleteCompany={handleDeleteCompany}
                    handleClickRedirectEdit={handleClickRedirectEdit}
                    CompanyDetails={CompanyDetails}
                    CompanyLoading={IdCompanyLoading}
                />
            </Main>
            {/* <Table columns={columns}>
                    {CompanyDetails?.map((ele: any) => (
                        <TableRow size='m' key={ele?.id} onClick={handleClickRedirectEdit(ele?.id)}>
                            <TableCell>
                                <TableCellText>{ele?.name}</TableCellText>
                            </TableCell>
                            <TableCell>
                                <TableCellText>{ele?.contact_name}</TableCellText>
                            </TableCell>
                            <TableCell>
                                <TableCellText>{ele?.address}</TableCellText>
                            </TableCell>
                            <TableCell>
                                <TableCellText>{ele?.email}</TableCellText>
                            </TableCell>
                            <TableCell>
                                <TableCellText>{ele?.phone}</TableCellText>
                            </TableCell>
                            <TableCell>
                                <TableCellText>{ele?.package_type}</TableCellText>
                            </TableCell>
                            <TableCell>
                                <TableCellText>{ele?.subscription_type}</TableCellText>
                            </TableCell>
                            <TableCell>
                                <TableCellText>{ele?.expires_at?.substring(0, 10)}</TableCellText>
                            </TableCell>
                            <TableCell align='right' onClick={e => e.stopPropagation()}>
                                {canDeleteCompany ? (
                                    <CompaniesListItemActions
                                        companyId={ele?.id}
                                        onDelete={handleDeleteCompany}
                                    />
                                ) : null}
                            </TableCell>
                        </TableRow>
                    ))}
                </Table> */}
        </>
    );
};

export { CompaniesPage };

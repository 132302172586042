import React from 'react';
import styled from 'styled-components';

import { Button } from '@modules/ui/core';

import { TextField } from '@modules/ui/core';
import { InputAdornment } from '@material-ui/core';
import {
    searchIcon,
    useBackgroundStyles,
} from '@modules/projects/moleculas/project-list-filters/input-box-css';
import { CapabilityCreateFormModal } from '@modules/custom-components/organisms';

type CapailitiesProps = {
    assessmentId?: number;
    componentId?: number;
    setComponentSearch: (e: any) => void;
    componentSearch?: string | any;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
`;

const Actions = styled.div``;

const ButtonCreateLink = styled(Button)`
    min-width: 205px;
    margin-right: 10px;
`;

const CustomCapabilitiesListToolbar = (props: CapailitiesProps): React.ReactElement => {
    const { componentSearch, setComponentSearch, assessmentId, componentId } = props;

    const [openCreateModal, setOpenCreateModal] = React.useState<boolean>(false);
    const backgroundClasses = useBackgroundStyles();

    const handleOpenCreateModal = (): void => {
        setOpenCreateModal(true);
    };
    const handleCloseCreateModal = (): void => {
        setOpenCreateModal(false);
    };

    return (
        <Root>
            <Actions>
                <ButtonCreateLink onClick={handleOpenCreateModal}>
                    Create New Capability
                </ButtonCreateLink>

                <TextField
                    corner='round'
                    name='search'
                    label='Search'
                    autoComplete='off'
                    value={componentSearch}
                    InputProps={{
                        classes: backgroundClasses,
                        endAdornment: <InputAdornment position='end'>{searchIcon}</InputAdornment>,
                    }}
                    onChange={e => setComponentSearch(e.target.value)}
                />
                <CapabilityCreateFormModal
                    open={openCreateModal}
                    onClose={handleCloseCreateModal}
                    componentId={componentId}
                />
            </Actions>
        </Root>
    );
};

export { CustomCapabilitiesListToolbar };

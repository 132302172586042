import * as React from 'react';
import {
    TableCell,
    TableBody,
    TableHead,
    TableContainer,
    TableSortLabel,
    Table as MuiTable,
    makeStyles,
    IconButton,
} from '@material-ui/core';
import styled from 'styled-components';
import { TableRow } from './table-row';
import { TableCellText } from '@modules/layout/atoms';
import { Loader } from '@modules/layout/moleculas';
import { useCurrentUser } from '@modules/auth/hooks';
import { useDebounce } from '@helpers';
import { ProjectListStatus } from '../project-progress';
import { ProjectMaturityLevel } from '../project-maturity-level';
import { ProjectsListItemActions } from '../projects-list-item-actions';
import { MoreVert } from '@material-ui/icons';
import { boolean } from 'yup';
import { FullLoader, ThreeDotLoader } from '@components/three-dot-loader';
const useTableStyles = makeStyles({
    root: {
        borderCollapse: 'separate',
        borderSpacing: '0 4px',
        width: '100%',
    },
});

const ProjectNameText = styled.h4`
    text-transform: capitalize;
    margin-bottom: 6px;
    font-size: 1.4rem;
    font-weight: 400;
`;
const TableHeadStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const TextFieldStyle = styled.div<{ isTrue?: any }>`
    display: ${props => (props.isTrue ? 'block' : 'none')};
    font-size: 13px;
    margin: 10px -6px -10px -6px;
    input {
        padding: 4px;
        border-right: transparent !important;
        border-top: transparent !important;
        border-left: transparent !important;
        border: 0.3px solid #a7a7a7;
    }

    input:focus {
        outline: none !important;
        border-bottom: 1px solid #605c5c;
    }
`;

interface Data {
    projectName: string;
    rr: string;
    description: string;
    status: string;
    maturityLevel: string;
    actions: any;
}

type Order = 'asc' | 'desc';

export type TableColumn = {
    id: string;
    label?: string;
    align?: 'left' | 'center' | 'right';
    width?: number;
};
type queryType = {
    value: any;
};
interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    columns: TableColumn[];
    setQuery: (value: any) => void;
    query: string;
    onChangeHandle: (e: React.ChangeEvent<HTMLInputElement>, columnId: any) => void;
}

interface ProjectDataTableProps {
    projects: any;
    projectsLoading: boolean;
    deleteProjectLoading: boolean;
    selectedProjectId: number | any;
    handleClickRedirect: (project: any) => void;
    canDeleteUser: boolean;
    columns: TableColumn[];
    handleDeleteProject: (projectId: number) => Promise<void>;
}

export function EnhancedTableHead(props: EnhancedTableProps) {
    let { order, orderBy, columns, onRequestSort, setQuery, query, onChangeHandle } = props;
    const [toggleSearchField, setToggleSearchField] = React.useState<null | string>(null);

    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    const handleSearchToggle = (id: string) =>
        toggleSearchField === null ? setToggleSearchField(id) : setToggleSearchField(null);

    return (
        <TableHead style={{ boxShadow: '5px 1px 2px 1px rgba(0,0,0,0.2)' }}>
            <TableRow>
                {columns?.map((headCell, index) => {
                    const updatedHeadCell =
                        headCell.id === 'projectName' ? { ...headCell, id: 'name' } : headCell;
                    return (
                        <TableCell
                            style={{
                                width: '220px',
                                textAlign: index === columns.length - 1 ? 'right' : 'left',
                            }}
                            key={updatedHeadCell?.id}
                            sortDirection={orderBy === updatedHeadCell?.id ? order : false}
                        >
                            {index !== columns.length - 1 ? (
                                updatedHeadCell?.id === 'maturityLevel' ? (
                                    'MaturityLevel'
                                ) : (
                                    <>
                                        <TableHeadStyle>
                                            <TableSortLabel
                                                active={orderBy === updatedHeadCell?.id}
                                                direction={
                                                    orderBy === updatedHeadCell?.id ? order : 'asc'
                                                }
                                                onClick={createSortHandler(updatedHeadCell?.id)}
                                            >
                                                {updatedHeadCell?.label}
                                            </TableSortLabel>
                                            <IconButton
                                                onClick={() =>
                                                    handleSearchToggle(updatedHeadCell?.id)
                                                }
                                            >
                                                <MoreVert
                                                    style={{ fontSize: 18, cursor: 'pointer' }}
                                                />
                                            </IconButton>
                                        </TableHeadStyle>
                                        {toggleSearchField === updatedHeadCell?.id ? (
                                            <TextFieldStyle isTrue={toggleSearchField}>
                                                <input
                                                    type='text'
                                                    placeholder={updatedHeadCell?.label}
                                                    onChange={e =>
                                                        onChangeHandle(e, updatedHeadCell?.id)
                                                    }
                                                />
                                            </TextFieldStyle>
                                        ) : null}
                                    </>
                                )
                            ) : (
                                'Actions'
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}

function ProjectDataTable(props: ProjectDataTableProps) {
    const {
        handleClickRedirect,
        canDeleteUser,
        columns,
        handleDeleteProject,
        projectsLoading,
        selectedProjectId,
        deleteProjectLoading,
        projects,
        ...otherProps
    } = props;

    const [order, setOrder] = React.useState<Order>('asc');
    const [query, setQuery] = React.useState<string>('');
    const debouncedQuery = useDebounce(query, 500);

    const [orderBy, setOrderBy] = React.useState<keyof Data>('projectName');
    const tableClasses = useTableStyles();
    const { currentUser } = useCurrentUser();
    const [filteredData, setFilteredData] = React.useState<any>([]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    React.useEffect(() => {
        const filteredResults = projects.filter((item: any) => {
            if (Object.values(debouncedQuery).some(value => value !== '')) {
                return Object.keys(item).some((key: any) => {
                    const searchValue = debouncedQuery[key]?.toLowerCase();
                    if (searchValue) {
                        return (
                            item[key].toString().toLowerCase().includes(searchValue) ||
                            item[key].toString().toUpperCase().includes(searchValue)
                        );
                    }
                    return false;
                });
            } else {
                return true;
            }
        });
        setFilteredData(filteredResults);
    }, [debouncedQuery, query, projects]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>, columnId: string) => {
        const searchValue = (e.target as HTMLInputElement).value.toLowerCase();
        setQuery((prevSearchQueries: any) => ({
            ...prevSearchQueries,
            [columnId]: searchValue,
        }));
    };

    if (projectsLoading) {
        return <Loader />;
    }
    return (
        <div>
            <TableContainer style={{ marginTop: ' 20px' }}>
                <MuiTable classes={tableClasses} {...otherProps}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={projects.length}
                        columns={columns}
                        setQuery={setQuery}
                        query={query}
                        onChangeHandle={handleSearch}
                    />
                    <TableBody
                        style={
                            deleteProjectLoading
                                ? { position: 'relative' }
                                : { background: '#e7e7e7', cursor: 'pointer' }
                        }
                    >
                        {filteredData
                            ?.sort((a: any, b: any) => {
                                if (orderBy === undefined) {
                                    return 0;
                                }
                                const aValue = a[orderBy];
                                const bValue = b[orderBy];

                                if (aValue < bValue) {
                                    return order === 'asc' ? -1 : 1;
                                }
                                if (aValue > bValue) {
                                    return order === 'asc' ? 1 : -1;
                                }
                                return 0;
                            })
                            .map((project: any) => {
                                const maturityLevel = project.getMaturityLevel();
                                return (
                                    <React.Fragment key={project.id}>
                                        {deleteProjectLoading &&
                                        selectedProjectId === project.id ? (
                                            <FullLoader />
                                        ) : (
                                            <TableRow
                                                size='m'
                                                onClick={() => handleClickRedirect(project)}
                                            >
                                                <TableCell>
                                                    <TableCellText>{project.name}</TableCellText>
                                                </TableCell>
                                                <TableCell>
                                                    <TableCellText>
                                                        {project.rr ? 'Risk Registry' : 'Project'}{' '}
                                                    </TableCellText>
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        maxWidth: '360px',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'normal',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    <TableCellText>
                                                        {project.description}
                                                    </TableCellText>
                                                </TableCell>
                                                <TableCell>
                                                    <TableCellText>
                                                        <ProjectListStatus
                                                            status={project.status}
                                                        />
                                                    </TableCellText>
                                                </TableCell>
                                                <TableCell>
                                                    {maturityLevel ? (
                                                        <ProjectMaturityLevel
                                                            current={maturityLevel}
                                                        />
                                                    ) : null}
                                                </TableCell>
                                                <TableCell
                                                    align='right'
                                                    onClick={e => e.stopPropagation()}
                                                >
                                                    <ProjectsListItemActions
                                                        onDelete={() =>
                                                            handleDeleteProject(project.id)
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                    </TableBody>
                </MuiTable>
            </TableContainer>
        </div>
    );
}
export { ProjectDataTable };

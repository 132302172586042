import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';
import type { AssessmentCategories } from '@modules/types/graphql';

export type ListAssessmentCategoriesEntityType = Partial<AssessmentCategories> | null;

@DecribeEntity('ListAssessmentCategoriesEntity')
class ListAssessmentCategoriesEntity extends BaseEntity {
    id: number;
    companyId: number;
    name: string;
    isRRType: boolean;

    constructor(listAssessmentCategories: ListAssessmentCategoriesEntityType) {
        super(listAssessmentCategories);

        this.id = listAssessmentCategories?.id ?? -1;
        this.companyId = listAssessmentCategories?.company_id ?? -1;
        this.name = listAssessmentCategories?.name ?? '';
        this.isRRType = listAssessmentCategories?.is_rr_type ?? false;
    }
}

export { ListAssessmentCategoriesEntity };

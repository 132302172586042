import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { TkoScopeEntityList } from '@modules/company/entities';

import type { QueryHookOptions } from '@apollo/client';
import type { GetTkoScopeQueryType } from '@modules/types/graphql';
import { GetTkoScopeQuery } from '../graphql/queries';

const useTkoScope = (options?: QueryHookOptions<GetTkoScopeQueryType>) => {
    const queryResult = useQuery<GetTkoScopeQueryType>(GetTkoScopeQuery, options);

    const { entities: tkoscopes, count } = useEntity(
        () => new TkoScopeEntityList(queryResult.data?.listTkoScopes ?? null),
        [queryResult.data],
    );

    const result = {
        tkoscopes,
        count,
        ...queryResult,
    };

    return result;
};

export { useTkoScope };

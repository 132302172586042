import { white } from '@modules/ui/colors';
import {
    CapabilityLevelList,
    GapRiskLevel,
    getRiskLevelLabelByScore,
    MaturityImpactLevelList,
    MaturityLevelList,
    RiskLevel10Point,
    RiskLevel10PointKeyMap,
} from './risk-level';

export const RiskLevel10PointColorMap = {
    [RiskLevel10Point.Minimal]: '#27AE60',
    [RiskLevel10Point.MinimalToLow]: '#27AE60',
    [RiskLevel10Point.Low]: '#9BAE27',
    [RiskLevel10Point.LowToMedium]: '#9BAE27',
    [RiskLevel10Point.Medium]: '#F2C94C',
    [RiskLevel10Point.MediumToHigh]: '#F2C94C',
    [RiskLevel10Point.High]: '#F2994A',
    [RiskLevel10Point.HighToCritical]: '#F2994A',
    [RiskLevel10Point.Critical]: '#EB5757',
    [RiskLevel10Point.SuperCritical]: '#EB5757',
};

export const RiskAlignedLevel10PointColorMap = {
    [RiskLevel10Point.Minimal]: '#00b050',
    [RiskLevel10Point.MinimalToLow]: '#00b050',
    [RiskLevel10Point.Low]: '#00b050',
    [RiskLevel10Point.LowToMedium]: '#00b050',
    [RiskLevel10Point.Medium]: '#00b050',
    [RiskLevel10Point.MediumToHigh]: '#ffff00',
    [RiskLevel10Point.High]: '#ffff00',
    [RiskLevel10Point.HighToCritical]: '#ffff00',
    [RiskLevel10Point.Critical]: '#ff0000',
    [RiskLevel10Point.SuperCritical]: '#ff0000',
};

export const RiskAlignedTextLevel10PointColorMap = {
    [RiskLevel10Point.Minimal]: '#ffffff',
    [RiskLevel10Point.MinimalToLow]: '#ffffff',
    [RiskLevel10Point.Low]: '#ffffff',
    [RiskLevel10Point.LowToMedium]: '#ffffff',
    [RiskLevel10Point.Medium]: '#ffffff',
    [RiskLevel10Point.MediumToHigh]: '#000000',
    [RiskLevel10Point.High]: '#000000',
    [RiskLevel10Point.HighToCritical]: '#000000',
    [RiskLevel10Point.Critical]: '#ffffff',
    [RiskLevel10Point.SuperCritical]: '#ffffff',
};

export const scoreVariantColorsMap = {
    [GapRiskLevel.SuperCritical]: {
        color: white[100],
        background: RiskLevel10PointColorMap[RiskLevel10Point.SuperCritical],
    },
    [GapRiskLevel.Critical]: {
        color: white[100],
        background: RiskLevel10PointColorMap[RiskLevel10Point.Critical],
    },
    [GapRiskLevel.HighToCritical]: {
        color: white[100],
        background: RiskLevel10PointColorMap[RiskLevel10Point.HighToCritical],
    },
    [GapRiskLevel.High]: {
        color: white[100],
        background: RiskLevel10PointColorMap[RiskLevel10Point.High],
    },
    [GapRiskLevel.MediumToHigh]: {
        color: white[100],
        background: RiskLevel10PointColorMap[RiskLevel10Point.MediumToHigh],
    },
    [GapRiskLevel.Medium]: {
        color: white[100],
        background: RiskLevel10PointColorMap[RiskLevel10Point.Medium],
    },
    [GapRiskLevel.LowToMedium]: {
        color: white[100],
        background: RiskLevel10PointColorMap[RiskLevel10Point.LowToMedium],
    },
    [GapRiskLevel.Low]: {
        color: white[100],
        background: RiskLevel10PointColorMap[RiskLevel10Point.Low],
    },
    [GapRiskLevel.MinimalToLow]: {
        color: white[100],
        background: RiskLevel10PointColorMap[RiskLevel10Point.MinimalToLow],
    },
    [GapRiskLevel.Minimal]: {
        color: white[100],
        background: RiskLevel10PointColorMap[RiskLevel10Point.Minimal],
    },
};

export const projectLevelColors: Record<string, string> = Object.values(RiskLevel10PointColorMap)
    .reverse()
    .reduce((colorObject, colorValue, index) => {
        colorObject[MaturityLevelList[index + 1]] = colorValue;
        return colorObject;
    }, {});
export const projectImpactReportLevelColors: Record<string, string> = Object.values(
    RiskLevel10PointColorMap,
)
    // .reverse()
    .reduce((colorObject, colorValue, index) => {
        colorObject[MaturityLevelList[index + 1]] = colorValue;
        return colorObject;
    }, {});

export const projectImpactLevelColors: Record<string, string> = Object.values(
    RiskLevel10PointColorMap,
)
    // .reverse()
    .reduce((colorObject, colorValue, index) => {
        colorObject[MaturityImpactLevelList[index + 1]] = colorValue;
        return colorObject;
    }, {});

export const capabilityLevelColors: Record<string, string> = Object.values(RiskLevel10PointColorMap)
    .reverse()
    .reduce((colorObject, colorValue, index) => {
        colorObject[CapabilityLevelList[index + 1]] = colorValue;
        return colorObject;
    }, {});
export const impactLevelColors: Record<string, string> = Object.values(RiskLevel10PointColorMap)
    // .reverse()
    .reduce((colorObject, colorValue, index) => {
        colorObject[CapabilityLevelList[index + 1]] = colorValue;
        return colorObject;
    }, {});

export function getColorAndTitleByScore(
    score: number,
    type?: string,
): { title: string; color: string } {
    let label: string = getRiskLevelLabelByScore(score, type);
    let levelDataKey = RiskLevel10Point[RiskLevel10PointKeyMap[label]];

    return {
        title: label,
        color: RiskLevel10PointColorMap[levelDataKey],
    };
}

export function getColorAndTitleByScoreForAligned(
    score: number,
    type?: string,
): { title: string; color: string; textcolor: string } {
    let label: string = getRiskLevelLabelByScore(score, type);
    let levelDataKey = RiskLevel10Point[RiskLevel10PointKeyMap[label]];
    let result;
    if (label === 'Minimal' || label === 'Minimal to Low' || label === 'Low' || label === 'Low to Medium' || label === 'Medium') {
        result = 'Acceptable';
    } else if (label === 'Medium to High' || label === 'High' || label === 'High to Critical') {
        result = 'Tolerable';
    } else if (label === 'Critical' || label === 'Super Critical') {
        result = 'Unacceptable';
    } else {
        result = '';
    }
    return {
        title: result,
        color: RiskAlignedLevel10PointColorMap[levelDataKey],
        textcolor: RiskAlignedTextLevel10PointColorMap[levelDataKey],
    };
}

import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
    display: flex;
    align-items: center;
`;

const Counter = styled.div`
    background-color: #2d9cdb;
    padding: 3px;
    border-radius: 50%;
    text-align: center;
    font-size: 16px;
    color: white;
    width: 26px;
    font-weight: 500;
    margin-right: 20px;
`;

const Title = styled.div`
    font-weight: 500;
    font-size: 16px;
`;

type RoundedProgressBarProps = {
    count: number;
};

const RecommendationCounter = (props: RoundedProgressBarProps): React.ReactElement => {
    const { count } = props;

    return (
        <Root>
            <Counter>{count}</Counter>
            <Title>Recommendations</Title>
        </Root>
    );
};

export { RecommendationCounter };

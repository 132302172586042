import { useQuery } from '@apollo/client';
import type { QueryHookOptions } from '@apollo/client';
import { GetPrevTypeDetailsQuery } from '../graphql';
import { useEntity } from '@lib/entity';
import { PrevTypeDetailsEntityList } from '../entities';

const usePrevTypeDetails = (options?: QueryHookOptions) => {
    const queryResult = useQuery(GetPrevTypeDetailsQuery, options);

    const { entities: prevType, count } = useEntity(
        () => new PrevTypeDetailsEntityList(queryResult.data?.getPrevTypeDetails ?? null),
        [queryResult.data],
    );


    const result = {
        prevType,
        count,
        ...queryResult,
    };

    return result;
};

export { usePrevTypeDetails };
const white = {
    100: '#fff',
    90: '#f8f9f9',
    50: '#ffffff80',
    30: '#ffffff4d',
};

const gray = {
    10: '#333',
    20: '#4F4F4F',
    30: '#828282',
    40: '#BDBDBD',
    50: '#E0E0E0',
    60: '#F2F2F2',
    90: '#eee',
};

const black = {
    100: '#000',
};

const red = {
    h: '#FF1E4B',
    100: '#eb4962',
};

const orange = {
    100: '#f97c01',
    90: '#f97c01e6',
    80: '#f97c01cc',
};

const blue = {
    h: '#01b8ff',
    100: '#0057ff',
    90: '#00719b',
    20: '#2D9CDB',
    10: '#c0e6f3',
};

const turquoise = {
    h: '#03363d',
    100: '#17494d',
    10: '#30aabc',
};

const green = {
    100: '#78a300',
};

export { white, gray, black, orange, red, blue, turquoise, green };

import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { Maybe, ImplementationTask } from '@modules/types/graphql';

export type Entity = Maybe<Partial<ImplementationTask>>;

@DecribeEntity('RecImpTaskEntity')
class RecImpTaskEntity extends BaseEntity {
    id: number;
    task: string;
    recommendationId: number;
    companyId: number | null;
    tFlag: boolean;

    constructor(implementationTask: Entity) {
        super(implementationTask);

        this.id = implementationTask?.id ?? -1;
        this.task = implementationTask?.task ?? '';
        this.recommendationId = implementationTask?.recommendation_id ?? -1;
        this.companyId = implementationTask?.company_id ?? null;
        this.tFlag = implementationTask?.t_flag ?? false;
    }

    isPredefined() {
        const result = this.companyId === null;

        return result;
    }
}

export { RecImpTaskEntity };

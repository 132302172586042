import React, { useContext, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import {
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    Chip,
    IconButton,
    Menu,
    MenuItem,
} from '@material-ui/core';
import { Assessment, MoreVert } from '@material-ui/icons';
import { routes } from '@config/routes';
import { createRouteUrl } from '@lib/routing';
import { useNavigate } from '@reach/router';

function AssessmentItem({
    onClick,
    assessment,
    activityType,
    activeAssetId,
    setActiveAssetId,
    setActivityType,
    setOpenNewAssessmentModal,
}: any) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { program_status, name, id } = assessment;
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate();

    return (
        <ListItem className='is-user' onClick={onClick}>
            <ListItemAvatar>
                <Avatar>
                    <Assessment />
                </Avatar>
            </ListItemAvatar>
            {/*<ListItemText*/}
            {/*    primary={name}*/}
            {/*    secondary={<Chip label={program_status} className='is-chip is-chip--small' />}*/}
            {/*/>*/}
            <div>
                <span>{name}</span>
                <div>
                    <div>
                        <Chip
                            label={
                                activityType === 'impact'
                                    ? program_status === 'Road Map'
                                        ? 'Assessment'
                                        : program_status
                                    : program_status
                            }
                            className='is-chip is-chip--small'
                        />
                    </div>
                </div>
            </div>
            <ListItemSecondaryAction>
                <IconButton
                    aria-label='more'
                    aria-controls='long-menu'
                    aria-haspopup='true'
                    onClick={handleClick}
                >
                    <MoreVert />
                </IconButton>
                <Menu id='menu' anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                    {/*<MenuItem key='implementation-plan' onClick={handleClose}>*/}
                    {/*    Implementation plan*/}
                    {/*</MenuItem>*/}
                    {/*<MenuItem key='roadmap' onClick={handleClose}>*/}
                    {/*    Roadmap*/}
                    {/*</MenuItem>*/}
                    <MenuItem
                        key='view-program'
                        style={{ cursor: id === 0 ? 'no-drop' : 'pointer' }}
                        onClick={async () => {
                            if (id !== 0) {
                                const redirectUrl = routes.projectAssessment;
                                await navigate(createRouteUrl(redirectUrl, { id }));
                                // handleClose()
                            }
                        }}
                    >
                        View Project
                    </MenuItem>
                    <MenuItem
                        key='new-assessment'
                        onClick={() => {
                            setOpenNewAssessmentModal(true);
                            setActiveAssetId(activeAssetId);
                            setActivityType(activityType);
                        }}
                    >
                        New Assessment
                    </MenuItem>
                </Menu>
            </ListItemSecondaryAction>
        </ListItem>
    );
}

export { AssessmentItem };

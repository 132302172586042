import React, { useMemo } from 'react';
import { Paper, List } from '@material-ui/core';
import { AssessmentItem } from '@modules/registry/organisms';
import { Button } from '@modules/ui/core';

function AssetAssessmentList({
    setOpenViewAssessmentModal,
    setOpenNewAssessmentModal,
    setActiveAssetId,
    activeAsset,
    activityType,
    setActivityType,
    setActiveAssessment,
}: any) {
    const assessment = useMemo(() => {
        if (!activeAsset || !activityType) {
            return null;
        }

        if (typeof activeAsset[`${activityType}Program`] === 'undefined') {
            return null;
        } else if(activeAsset[`${activityType}Program`] === null && activeAsset[`${activityType}Assessments`].length !== 0) {
            return {
                __typename: "Program",
                id: 0,
                company_id: 0,
                created_at: "2024-04-02T12:46:08.335Z",
                name: "Edit Score Value",
                description: "yitityi",
                program_status: "Edit Score",
                assessment_type_id: 0
            };
        }

        return activeAsset[`${activityType}Program`];
    }, [activeAsset, activityType]);

    if (!assessment) {
        return (
            <Button
                variant='outlined'
                size='small'
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    background: '#fff',
                    marginTop: 10,
                    minHeight: 50,
                }}
                onClick={() => {
                    setOpenNewAssessmentModal(true);
                    setActiveAssetId(activeAsset.id);
                    setActivityType(activityType);
                }}
            >
                + New assessment
            </Button>
        );
    }

    return (
        <>
            {assessment ? (
                <Paper
                    className='is-paper-link'
                    variant='outlined'
                    style={{ cursor: 'pointer', marginTop: 10 }}
                >
                    <List className='is-assessments-list'>
                        <AssessmentItem
                            assessment={assessment}
                            activityType={activityType}
                            activeAssetId={activeAsset.id}
                            setActiveAssetId={setActiveAssetId}
                            setActivityType={setActivityType}
                            setOpenNewAssessmentModal={setOpenNewAssessmentModal}
                            onClick={() => {
                                setOpenViewAssessmentModal(true);
                                setActiveAssetId(activeAsset.id);
                                setActivityType(activityType);
                                setActiveAssessment(assessment);
                            }}
                        />
                    </List>
                </Paper>
            ) : (
                ''
            )}
        </>
    );
}

export { AssetAssessmentList };

import React from 'react';
import styled from 'styled-components';

import { Modal } from '@modules/ui/core';
import { AssessmentSurveyForm } from './assessment-survey-form';

import type { ModalProps } from '@modules/ui/core';

type AssessmentSurveyModalProps = Omit<ModalProps, 'title' | 'children'> & {
    capabilityAssessmentId: number;
    isAIAccess?: boolean;
};

const StyledModal = styled(Modal)`
    width: 1200px;
`;

const AssessmentSurveyFormModal = (props: AssessmentSurveyModalProps): React.ReactElement => {
    const { capabilityAssessmentId, isAIAccess, onClose, ...otherProps } = props;

    return (
        <StyledModal title='Assess' onClose={onClose} {...otherProps}>
            <AssessmentSurveyForm
                isAIAccess={isAIAccess}
                capabilityAssessmentId={capabilityAssessmentId}
                onCancel={onClose}
            />
        </StyledModal>
    );
};

export { AssessmentSurveyFormModal };

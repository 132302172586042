import { format } from 'date-fns';

import { BaseEntity, Entity as DecribeEntity, Relation, RelationList } from '@lib/entity';
import type { UserEntityType } from '@modules/users/entities';
import { UserEntity } from '@modules/users/entities';
import type {
    CapabilityEntityType,
    CapabilityLevelEntityType,
} from '@modules/capabilities/entities';
import {
    CapabilityEntity,
    CapabilityLevelEntity,
    CapabilityLevelEntityList,
} from '@modules/capabilities/entities';
import type { ScoredGapEntity, ScoredGapEntityType } from '@modules/roadmap/entities';
import { ScoredGapEntityList } from '@modules/roadmap/entities';
import type {
    CapabilityAssessment,
    CapabilityAssessmentLog,
    Maybe,
    User,
} from '@modules/types/graphql';

export type Entity = Partial<
    Omit<
        CapabilityAssessment,
        | 'capability'
        | 'completion_level'
        | 'assessed_by'
        | 'capability_levels'
        | 'scored_gaps'
        | 'impact_capability_levels'
    > & {
        capability: CapabilityEntityType;
        completion_level: CapabilityLevelEntityType;
        assessed_by: UserEntityType;
        capability_levels: CapabilityLevelEntityType[] | null;
        impact_capability_levels: CapabilityLevelEntityType[] | null;
        scored_gaps: ScoredGapEntityType[] | null;
        log: CapabilityAssessmentLog[] | null;
    }
> | null;
@DecribeEntity('CapabilityAssessmentEntity')
class CapabilityAssessmentEntity extends BaseEntity {
    id: number;
    notes: string;
    gaps: string;
    recommendations: string;
    assessedAt: string;
    weight: number;
    assessed_by: User;
    operators?: Maybe<User>[];
    log: CapabilityAssessmentLog[];

    @Relation(() => CapabilityEntity)
    capability: CapabilityEntity;

    @Relation(() => CapabilityLevelEntity)
    completionLevel: CapabilityLevelEntity;

    @Relation(() => UserEntity)
    assessedBy: UserEntity;

    @RelationList(() => CapabilityLevelEntityList)
    impactCapabilityLevels: CapabilityLevelEntity[];

    @RelationList(() => CapabilityLevelEntityList)
    capabilityLevels: CapabilityLevelEntity[];

    @RelationList(() => ScoredGapEntityList)
    scoredGaps: (ScoredGapEntity | null)[];

    constructor(capabilityAssessment: Entity) {
        super(capabilityAssessment);

        this.id = capabilityAssessment?.id ?? -1;
        this.notes = capabilityAssessment?.notes ?? '';
        this.gaps = capabilityAssessment?.gaps ?? '';
        this.recommendations = capabilityAssessment?.recommendations ?? '';
        this.assessedAt = capabilityAssessment?.assessed_at ?? '';
        this.weight = capabilityAssessment?.weight ?? 0;
        this.operators = capabilityAssessment?.operators ?? [];
        this.log = capabilityAssessment?.log ?? [];
    }

    isAssess() {
        const isAssess = this.assessedAt !== '';

        return isAssess;
    }

    getHumanAssessedAt() {
        if (!this.assessedAt) {
            return '';
        }

        const assessedAt = new Date(this.assessedAt);

        const date = format(assessedAt, 'dd MMM, yyyy');
        const time = format(assessedAt, 'hh:mma').toLowerCase();

        const result = date + ' - ' + time;

        return result;
    }

    hasCapabilityLevels() {
        const result = this.capabilityLevels.length !== 0;

        return result;
    }

    hasOperators() {
        return this.operators?.length !== 0;
    }

    operatorsToString() {
        if (!this.operators) {
            return '';
        }

        if (!this.hasOperators()) {
            return '';
        }

        return this.operators
            .map(operator => `${operator?.first_name} ${operator?.last_name}`)
            .join(', ');
    }
}

export { CapabilityAssessmentEntity };

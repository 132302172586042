import { gql } from '@apollo/client';
import { LearnTkoDetailsFragment } from '../fragments';

export const DeleteLearnTkoDetailsMutation = gql`
    mutation DeleteLearnTkoDetail($learnTkoId: Int!) {
        deleteLearnTkoDetail(learnTko_id: $learnTkoId) {
            ...LearnTkoDetails
        }
    }
    ${LearnTkoDetailsFragment}
`;

import { gql } from '@apollo/client';

import { TaskSingleFragment } from '../fragments';

export const CreateTaskMutation = gql`
    mutation CreateTask(
        $requirementId: Int!
        $task: String!
        $resources: String
        $duration: String
    ) {
        createTask(
            requirement_id: $requirementId
            task: $task
            resources: $resources
            duration: $duration
        ) {
            ...TaskSingle
        }
    }
    ${TaskSingleFragment}
`;

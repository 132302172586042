import type { CreateSubTaskMutationVariables } from '@modules/types/graphql';

export type ImpSubTaskCreateFormValues = {
    recommendationId: number;
    subTask: string;
    taskId: number;
    companyId?: number;
    isAiSubTasks: boolean;
};

class ImpSubTaskCreateRequest {
    recommendationId: CreateSubTaskMutationVariables['recommendationId'];
    subTask: CreateSubTaskMutationVariables['subTask'];
    taskId: CreateSubTaskMutationVariables['taskId'];
    companyId: CreateSubTaskMutationVariables['companyId'];
    isAiSubTasks: CreateSubTaskMutationVariables['isAiSubTasks'];

    constructor(values: ImpSubTaskCreateFormValues) {
        this.recommendationId = values.recommendationId;
        this.subTask = values.subTask;
        this.taskId = values.taskId;
        this.companyId = values.companyId;
        this.isAiSubTasks = values.isAiSubTasks;
    }
}

export { ImpSubTaskCreateRequest };

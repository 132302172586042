import clsx from 'clsx';

type Classes = Record<string, any>;

const concatenateClasses = (target: Classes, source: Classes = {}): Classes => {
    const destination = {};

    Object.keys(target).forEach(key => {
        destination[key] = target[key];
    });

    Object.keys(source).forEach(key => {
        destination[key] = clsx(destination[key], source[key]);
    });

    return destination;
};

export { concatenateClasses };

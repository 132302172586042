import { addElipsisText } from '@helpers';
import { Button, Grid } from '@material-ui/core';
import { Loader } from '@modules/layout/moleculas';
import React from 'react';
import styled from 'styled-components';

type LearnTkoCardDataType = {
    id: number;
    menuTitle: string;
    description: string;
    imageUrl?: string;
}[];

type LearnTkoCardProps = {
    learnTkoCardData: LearnTkoCardDataType;
    menuLoading: boolean;
    ProjectImg?: any;
    imageData: any;
    handleExplore: (query: string) => void;
};

const LearnCardStyle = styled.div`
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    color: #000;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.4s;
    height: 100%;
`;

const CardImageStyle = styled.div`
    border-radius: 10px !important;
    transition: 0.4s;
    max-height: 300px;
    &:hover {
        opacity: 0.9;
    }
    img {
        width: 100%;
        height: 100%;
        display: block;
        transition: 0.4s;
        border-radius: 5px;
    }
`;

const CardImageDetail = styled.div`
    padding: 20px 6px;

    h1 {
        font-size: 18px;
        margin-bottom: 5px;
    }
    p {
        font-size: 15px;
        margin-bottom: 20px;
    }
    button {
        font-size: 1.5rem;
        font-weight: 600;
    }
`;

const LearnTkoTutorialCard = (props: LearnTkoCardProps) => {
    const { learnTkoCardData, imageData, handleExplore, menuLoading, ProjectImg } = props;

    const sortMenuData = (data: any) => {
        return data?.sort((a: any, b: any) => {
            const titleA = a?.id;
            const titleB = b?.id;
            if (titleA < titleB) return 1;
            if (titleA > titleB) return -1;
            return 0;
        });
    };

    if (menuLoading) {
        return <Loader />;
    }

    return (
        <Grid container spacing={6}>
            {sortMenuData(learnTkoCardData)?.map((ele: any) => (
                <React.Fragment key={ele?.id}>
                    <Grid item md={4} sm={6} xs={12}>
                        <LearnCardStyle onClick={() => handleExplore(ele?.menuTitle)}>
                            <CardImageStyle>
                                <img
                                    src={
                                        imageData
                                            .map((items: any) =>
                                                items.key === ele.imageUrl ? items.value : null,
                                            )
                                            .filter((item: any) => item !== null)[0] || ProjectImg
                                    }
                                    alt='no images'
                                />
                            </CardImageStyle>
                            <CardImageDetail>
                                <h1>{addElipsisText(ele?.menuTitle, 30)}</h1>
                                <p>{addElipsisText(ele?.description, 170)}</p>
                                <Button
                                    variant='outlined'
                                    onClick={() => handleExplore(ele?.menuTitle)}
                                >
                                    {addElipsisText(ele?.menuTitle, 20)}
                                </Button>
                            </CardImageDetail>
                        </LearnCardStyle>
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    );
};

export { LearnTkoTutorialCard };

import { gql } from '@apollo/client';

import { GapSingleFragment } from '../fragments';

export const GetGapQuery = gql`
    query GetGap($gapId: Int!, $capabilityLevelId: Int!) {
        getGap(gap_id: $gapId, capability_level_id: $capabilityLevelId) {
            ...GapSingle
        }
    }
    ${GapSingleFragment}
`;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LearnTKOContainerStyle } from '.';
import { LeftContainerStyle } from '@pages/ask-tko/ask-tko';
import { useLearnTko, useLearnTkoMenuList } from '@modules/learn-tko/hooks';
import { addElipsisText, useAccordion } from '@helpers';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Loader } from '@modules/layout/moleculas';
import { useLocation, useParams } from '@reach/router';
import { Grid, Typography } from '@material-ui/core';
import 'quill/dist/quill.snow.css';
type LearnTkoMenuListType = {
    id: number;
    menu: string;
    subMenu: string[];
};

const LeftContainers = styled.div`
    background: #fff;
    /* border-right: 1px solid #dddddd; */
    width: 100%;
    /* &:hover p{
    background: #b3b1b1;
    border-radius: 15px;
  } */
`;

const MainContainerStyle = styled.div`
    flex: 1;
    padding: 1rem 4rem;
`;

const MenuListStyle = styled.div`
    background: hsl(0deg 0% 74.12% / 71%);
    border-radius: 6px;
    padding: 4px 8px;
    color: #0057ff;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.3s;
    padding: 6px;
    h4 {
        font-size: 15px;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
            font-size: 25px;
        }
    }
`;
/* ${props => props.isActive && `
    background-color: #F0F0F0; 
`} */
const SubMenuListStyle = styled.div<{ isActive?: boolean }>`
    font-size: 14px;
    padding: 2px 5px 2px 10px;
    color: #000;
    ${props =>
        props.isActive &&
        `
    font-weight:600; 
    border-radius:28px;
    font-size:14px;
`}
    background: ${props => (props.isActive ? '#b3b1b1' : 'transparent')};
    p {
        padding: 8px 3px;
        transition: 0.5s;
        :hover {
            background: #b3b1b1;
            border-radius: 28px;
            padding: 8px 3px;
        }
    }
`;
const EditorViewContainer = styled.div`
    p:first-child {
        margin-bottom: 10px;
    }
    img {
        width: 63%;
        margin: 10px 0;
    }
    iframe.ql-video {
        width: 63%;
        margin: 10px 0;
        height: 407px;
    }
`;

const HeadingStyle = styled.h1`
    font-size: 32px;
    margin-bottom: 15px;
`;
const SubHeadingStyle = styled.h2`
    font-size: 25px;
    margin-bottom: 15px;
    color: gray;
    font-weight: 600;
`;

const LearnTkoDetails = (): React.ReactElement => {
    const [selectedData, setSelectedData] = useState<any>([{ title: '', descr: '' }]);
    const [selectedSubmenu, setSelectedSubmenu] = useState<any>(null);
    const [pageLink, setPagelink] = useState<string[]>([]);
    const { state } = useLocation();
    const stateQuery = (state as { query?: string }).query || ' ';
    const { activeIndex, toggleAccordion } = useAccordion(stateQuery);
    const { learnTko, loading: learnTkoLoading } = useLearnTko();

    const handleActiveSideBar = (title: string, data: any) => {
        const descr = data[title] ? data[title]?.description : null;
        const newData = {
            title,
            descr,
        };
        setSelectedSubmenu(title);
        setSelectedData([newData]);
    };

    useEffect(() => {
        if (activeIndex) {
            const activeMenu = learnTko.find((ele: any) => ele?.menu === stateQuery);

            if (activeMenu) {
                const menuTitle = JSON.parse(activeMenu.title);
                const firstSubMenu = menuTitle[0];
                const title = firstSubMenu.title;
                const descr = firstSubMenu[title]?.description || '';
                const newData = {
                    title,
                    descr,
                };
                setSelectedSubmenu(title);
                setSelectedData([newData]);
            }
        }
    }, [stateQuery, activeIndex, learnTko]);

    if (learnTkoLoading) {
        return <Loader />;
    }
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={2} md={2} lg={2} sm={2}>
                    <LeftContainers>
                        {learnTko &&
                            learnTko?.map((ele: any) => {
                                const menuTitle = JSON.parse(ele?.title);
                                const isMenuActive = activeIndex === ele?.menu;
                                return (
                                    <MenuListStyle key={ele?.id}>
                                        <h4 onClick={() => toggleAccordion(ele?.menu)}>
                                            {ele?.menu}{' '}
                                            {isMenuActive ? <ChevronLeft /> : <ChevronRight />}
                                        </h4>
                                        <SubMenuListStyle>
                                            {activeIndex === ele?.menu &&
                                                isMenuActive &&
                                                menuTitle?.map((list: any, index: number) => (
                                                    <SubMenuListStyle
                                                        key={list?.id}
                                                        isActive={list?.title === selectedSubmenu}
                                                        onClick={() =>
                                                            handleActiveSideBar(list?.title, list)
                                                        }
                                                    >
                                                        <p>{list?.title}</p>
                                                    </SubMenuListStyle>
                                                ))}
                                        </SubMenuListStyle>
                                    </MenuListStyle>
                                );
                            })}
                    </LeftContainers>
                </Grid>
                <Grid item xs={8}>
                    <MainContainerStyle>
                        {selectedData?.map((ele: any, id: number) => (
                            <React.Fragment key={id}>
                                <HeadingStyle>{activeIndex}</HeadingStyle>
                                <SubHeadingStyle>{ele?.title}</SubHeadingStyle>
                                <EditorViewContainer
                                    style={{ fontSize: '17px' }}
                                    dangerouslySetInnerHTML={{ __html: ele?.descr }}
                                ></EditorViewContainer>
                            </React.Fragment>
                        ))}
                    </MainContainerStyle>
                </Grid>
                <Grid item xs>
                    {' '}
                </Grid>
            </Grid>
        </>
    );
};

export { LearnTkoDetails };

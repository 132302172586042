import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { CapabilityImpactEntityList } from '@modules/custom-components/entities';
import { GetCapabilityImpactsQuery } from '@modules/custom-components/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetCapabilityImpactsQueryType } from '@modules/types/graphql';

const useCapabilityImpacts = (options?: QueryHookOptions<GetCapabilityImpactsQueryType>) => {
    const queryResult = useQuery<GetCapabilityImpactsQueryType>(GetCapabilityImpactsQuery, options);

    const { entities: capabilityImpacts } = useEntity(
        () => new CapabilityImpactEntityList(queryResult.data?.listCapabilityImpacts ?? null),
        [queryResult.data],
    );

    const result = {
        capabilityImpacts,
        ...queryResult,
    };

    return result;
};

export { useCapabilityImpacts };

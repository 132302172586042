import { EntityList } from '@lib/entity';
import { RiskAssignUserEntity } from './risk-assign-user-entity';

import type { Entity } from './risk-assign-user-entity';


class RiskAssignUserEntityList extends EntityList<RiskAssignUserEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(RiskAssignUserEntity, items, count);
    }
}

export { RiskAssignUserEntityList };

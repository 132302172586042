import React from 'react';
import styled from 'styled-components';

import { gray } from '@modules/ui/colors';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 40px;
    border-left: 1px solid ${gray[50]};

    > * {
        width: 100%;
    }
`;

const Title = styled.div`
    color: ${gray[20]};
    font-size: 1.6rem;
    line-height: 24px;
    margin-bottom: 12px;
`;

const Person = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    > * {
        width: 100%;
    }

    > h4 {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 22px;
    }

    > p {
        font-size: 1.2rem;
        line-height: 16px;
    }
`;

const EditAssignmentsLink = styled.div`
    display: inline-flex;
    color: ${gray[10]};
    font-size: 1.6rem;
    line-height: 24px;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
`;

const ProjectAssignedControl = (): React.ReactElement => {
    return (
        <Root>
            <Title>Assigned to:</Title>

            <Person>
                <h4>Jaydon Westervelt</h4>
                <p>Component owner</p>
            </Person>

            <Person>
                <h4>Kaylynn Septimus</h4>
                <p>Operator</p>
            </Person>

            <EditAssignmentsLink>Edit Assignments</EditAssignmentsLink>
        </Root>
    );
};

export { ProjectAssignedControl };

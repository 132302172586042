import { QueryHookOptions, useQuery } from '@apollo/client';
import { GetSectorQuery } from '../graphql/queries';

const useSector = (options?: QueryHookOptions) => {
    const { data, loading } = useQuery(GetSectorQuery, options);

    const result = {
        sector: data?.listSectors,
        loading,
    };

    return result;
};

export { useSector };

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { FormControlLabel, TextField } from '@material-ui/core';

import { Button, Modal } from '@modules/ui/core';
import { FormStyled } from '@modules/layout/styled';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { TableCellText, TableCheckbox } from '@modules/layout/atoms';
import { Loader, TableRow, TableCell } from '@modules/layout/moleculas';
import { ExtendedFormik, Form, Table } from '@modules/layout/organisms';

import type { ModalProps } from '@modules/ui/core';
import type {
    SetImplementationTaskMutationType,
    SetImplementationTaskMutationVariables,
    UpdateImplementaionTaskMutationType,
    UpdateImplementaionTaskMutationVariables,
} from '@modules/types/graphql';

import { ImpTaskUpdateRequest } from '@modules/custom-components/requests';
import { UpdateImplementationTaskMutation } from '@modules/implementation/graphql/mutations/update-implementation-task';
import { GetImplementationTaskQuery } from '@modules/implementation/graphql/queries/get-implementation-task';
import { SetImplementationTaskMutation } from '@modules/implementation/graphql/mutations';
import { GetRecommendationImpTasksQuery } from '@modules/implementation/graphql/queries';
import { useImplementationTasks } from '@modules/implementation/hooks/use-implementation-task';

type ImplementationTasksEditFormModalProps = Omit<ModalProps, 'title' | 'children'> & {
    impTask: any;
    recommendationId: number;
    companyId?: number;
    loadingImp: boolean;
    RecImpTask: any;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const ImplementationTasksEditFormModal = (
    props: ImplementationTasksEditFormModalProps,
): React.ReactElement => {
    const {
        recommendationId,
        impTask,
        companyId,
        RecImpTask,
        loadingImp,
        onClose,
        ...otherProps
    } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [taskData, setTaskData] = useState<any>([]);
    const { implementationTask, loading: implementationTaskLoading } = useImplementationTasks({
        fetchPolicy: 'network-only',
        variables: { recommendationId: recommendationId, companyId: companyId },
    });

    useEffect(() => {
        setTaskData([...implementationTask]);
    }, [implementationTask]);

    const [setImplementationTask] = useMutation<
        SetImplementationTaskMutationType,
        SetImplementationTaskMutationVariables
    >(SetImplementationTaskMutation);

    const initialValues = {
        tasks: RecImpTask?.map((task: any) => task && task?.id).filter(Boolean),
    };

    const [updateImplementationTask] = useMutation<
        UpdateImplementaionTaskMutationType,
        UpdateImplementaionTaskMutationVariables
    >(UpdateImplementationTaskMutation);

    if (implementationTaskLoading) {
        return <Loader />;
    }
    return (
        <Modal title='Edit Tasks ttt' onClose={onClose} {...otherProps}>
            {loadingImp ? (
                <Loader />
            ) : (
                <ExtendedFormik
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={yup.object({
                        tasks: yup.array().min(1, 'Required field'),
                    })}
                    initialValues={initialValues}
                    onSubmit={async values => {
                        try {
                            taskData?.forEach(async function (items: any) {
                                items.taskId = items.id;
                                const variables = new ImpTaskUpdateRequest(items);
                                const {
                                    data: updateImplementationTaskData,
                                } = await updateImplementationTask({
                                    variables,
                                    refetchQueries: [
                                        {
                                            query: GetImplementationTaskQuery,
                                            variables: { recommendationId, companyId: 166 },
                                        },
                                    ],
                                    awaitRefetchQueries: true,
                                });
                            });
                            const { data: setImplementationTaskData } = await setImplementationTask(
                                {
                                    variables: {
                                        taskId: values.tasks as number[],
                                        recommendationId: recommendationId,
                                    },
                                    refetchQueries: [
                                        {
                                            query: GetRecommendationImpTasksQuery,
                                            variables: { recommendationId: recommendationId },
                                        },
                                    ],
                                    awaitRefetchQueries: true,
                                },
                            );
                            if (setImplementationTaskData?.setImplementationTask?.length !== -1) {
                                enqueueSuccess('Implementation Task successfully updated!');
                                onClose();
                            } else {
                                enqueueError('An error occurred while saving Implementation Task!');
                            }
                        } catch (e) {
                            console.log(e);
                            throw e;
                        }
                    }}
                >
                    {formikProps => {
                        const { values, setFieldValue, isSubmitting } = formikProps;
                        const handleCheckGap = (taskId: number) => (_: React.ChangeEvent<{}>) => {
                            const tasksToUpdate = [...values.tasks];
                            const idx = tasksToUpdate.findIndex(task => task === taskId);

                            if (idx > -1) {
                                tasksToUpdate.splice(idx, 1);
                            } else {
                                tasksToUpdate.push(taskId);
                            }
                            setFieldValue('tasks', tasksToUpdate);
                        };

                        const handleGapText = (e: any, TaskId: number) => {
                            let newTaskData = impTask?.map((ele: any) => {
                                if (ele.id === TaskId) {
                                    ele.task = e.target.value;
                                }
                                return ele;
                            });
                            setTaskData([...newTaskData]);
                        };

                        return (
                            <StyledForm>
                                <Table>
                                    {taskData?.map((task: any) => {
                                        return (
                                            task && (
                                                <TableRow key={task.id}>
                                                    <TableCell>
                                                        <FormControlLabel
                                                            control={
                                                                <TableCheckbox
                                                                    checked={values.tasks.includes(
                                                                        task.id,
                                                                    )}
                                                                    onChange={handleCheckGap(
                                                                        task.id,
                                                                    )}
                                                                />
                                                            }
                                                            label={
                                                                <TableCellText>
                                                                    <TextField
                                                                        multiline
                                                                        fullWidth
                                                                        name='taskText'
                                                                        value={task?.task}
                                                                        onChange={e =>
                                                                            handleGapText(
                                                                                e,
                                                                                task?.id,
                                                                            )
                                                                        }
                                                                        style={{
                                                                            minWidth: '400px',
                                                                        }}
                                                                    />
                                                                </TableCellText>
                                                            }
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        );
                                    })}
                                </Table>

                                <FormStyled.FormRowSubmit>
                                    <SubmitButton type='submit' loading={isSubmitting}>
                                        Save
                                    </SubmitButton>

                                    <SubmitButton
                                        variant='text'
                                        disabled={isSubmitting}
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </SubmitButton>
                                </FormStyled.FormRowSubmit>
                            </StyledForm>
                        );
                    }}
                </ExtendedFormik>
            )}
        </Modal>
    );
};

export { ImplementationTasksEditFormModal };

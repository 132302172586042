import React, { useState } from 'react';
import styled from 'styled-components';

import { white, gray } from '@modules/ui/colors';

import { roadmapRecommendationTitle } from '@helpers';
import { RecommendationAssessmentRoadmapReport } from '@modules/roadmap/recommendation-assessment-roadmap-report';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { format } from "date-fns";
import { RoadmapNotes } from '@modules/roadmap/organisms/roadmap-recommendations-list/roadmap-notes';
import { ProjectEntity } from '@modules/projects/entities';
import { useRoles } from '@modules/assessment/hooks';
import { ListStyled } from '@modules/layout/styled';
import { AddNotesForm } from '@modules/roadmap/moleculas';

type ReportPrioritizedRoadmapRowProps = {
  recommendation: RecommendationAssessmentRoadmapReport;
  project: ProjectEntity;
  withStatus?: boolean;
};

export const ReportPrioritizedRoadmapRow = (
  props: ReportPrioritizedRoadmapRowProps,
): React.ReactElement => {
  const { recommendation: recommendationExtra, project, withStatus } = props;

  let roadmapRecommendation = recommendationExtra.componentRecommendation.recommendation;
  let history = roadmapRecommendation.refinement;
  let notes = roadmapRecommendation.refinement?.notesLog ?? [];
  const [showNotes, setShowNotes] = useState(false);

  const { rolesEntityList } = useRoles({ variables: { assessmentId: recommendationExtra.componentRecommendation.assessmentId } });
  const hasPmRole = rolesEntityList.hasProgramManager();
  const hasStRole = rolesEntityList.hasStakeholder();
  const hasCORole = rolesEntityList.hasComponentOwner();

  return (
    <>
      <TableCell>
        <p className={'text-offset'}>{roadmapRecommendationTitle(roadmapRecommendation)}</p>
        {notes.length === 0 ?
          (hasStRole || hasPmRole || hasCORole ? (
            <AddNotesForm
              project={project}
              roadmapRecommendation={roadmapRecommendation}
            // onAddNote={onAddNote}
            />
          ) : null)
          :
          (
            <>
              {/*<p className={'block-offset'}><b>{history.status} at {format(new Date(history.refined_at), 'dd.MM.yyyy hh:mm')} by {history?.refined_by?.first_name} {history?.refined_by?.last_name}</b></p>*/}
              {/*<p>Dependencies: {history.dependencies}</p>*/}
              {/*<p>Constraints: {history.constraints}</p>*/}
              <p>
                {/*<span>Notes: {history.notes} </span>*/}
                <a
                  href='./'
                  onClick={e => {
                    e?.preventDefault();
                    e?.stopPropagation();
                    setShowNotes(state => !state);
                  }}
                >
                  <strong className={'strong'}>({showNotes ? 'Hide' : 'Show'} all notes)</strong>
                </a>
              </p>
            </>
          )}
        <RoadmapNotes
          notes={notes}
          showNotes={showNotes}
          hasStRole={hasStRole}
          hasPmRole={hasPmRole}
          project={project}
          roadmapRecommendation={roadmapRecommendation}
        />
      </TableCell>
      <TableCell>{roadmapRecommendation?.gap.riskLevel ?? ''}</TableCell>
      <TableCell>{recommendationExtra.componentRecommendation.component.name}</TableCell>
      <TableCell>
        <p className={'text-offset'}>{recommendationExtra.capabilityAssessment.capability.title}</p>
        {/*<p className={'text-offset'}><b>Gap:</b> {roadmapRecommendation.gap.gap}</p>*/}
        <p><b>Security Controls:</b> {recommendationExtra.capabilityAssessment.capability.securityControls}</p>
      </TableCell>
      {/*<TableCell>{recommendationExtra.componentRecommendation.componentOwner.getFullName()}</TableCell>*/}
      {withStatus && (
        <TableCell>{roadmapRecommendation.status}</TableCell>
      )}
    </>
  );
};


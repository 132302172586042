import React from 'react';
import styled from 'styled-components';

import { white, gray } from '@modules/ui/colors';
import { InnerListStyled } from '@modules/layout/styled';
import { ReportRoadmapTopRecommendationsListItem } from './report-roadmap-top-recommendations-list-item';

import type { RoadmapRecommendationEntity } from '@modules/roadmap/entities';
import {RecommendationAssessment} from '@modules/roadmap/recommendation-assessment-roadmap-report';
import {GapByRisk, GapCapabilityAssessment} from '@modules/assessment/gap-by-risk';

type ReportRoadmapTopRecommendationsListProps = {
  recommendationList: RecommendationAssessment[]
};

const List = styled(InnerListStyled.List)`
    background-color: ${white[100]};
    border: 1px solid ${gray[60]};
`;

const ReportRoadmapTopRecommendationsList = (
    props: ReportRoadmapTopRecommendationsListProps,
): React.ReactElement => {
    const { recommendationList } = props;

    const topRecommendations = [...recommendationList];
    const hasRecommendations = topRecommendations.length !== 0;

    GapByRisk.sortGapListByScore(topRecommendations as unknown as GapCapabilityAssessment[]);
    const topRecommendationList = topRecommendations.slice(0,5);

    return (
        <InnerListStyled.Root>
            <InnerListStyled.Header>
                <h2>Top recommendations</h2>
            </InnerListStyled.Header>

            <List>
                {hasRecommendations ? (
                  topRecommendationList.map((topRecommendation: RecommendationAssessment) => {
                    const roadmapRecommendation = topRecommendation.roadmapRecommendation as RoadmapRecommendationEntity;

                    return (
                      <ReportRoadmapTopRecommendationsListItem
                        key={roadmapRecommendation.id}
                        recommendation={roadmapRecommendation}
                        topRecommendation={topRecommendation}
                      />
                    );
                  })
                ) : (
                    <InnerListStyled.Empty>Recommendations not found</InnerListStyled.Empty>
                )}
            </List>
        </InnerListStyled.Root>
    );
};

export { ReportRoadmapTopRecommendationsList };

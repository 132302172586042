import { white, black, gray, blue } from '@modules/ui/colors';

const muiTheme = {
    palette: {
        primary: {
            main: blue[100],
        },
        secondary: {
            main: blue[100],
        },
        text: {
            primary: black[100],
        },
    },

    typography: {
        allVariants: {
            fontFamily: ['"Montserrat"', 'Arial', 'sans-serif'].join(','),
        },
    },

    props: {
        MuiChip: {
            size: 'small',
        },
        MuiTextField: {
            variant: 'outlined',
            size: 'medium',
        },
        MuiCheckbox: {
            color: 'primary',
        },
        MuiButton: {
            variant: 'contained',
            color: 'primary',
            size: 'medium',
        },
    },

    overrides: {
        MuiChip: {
            labelSmall: {
                lineHeight: '25px',
            },
        },
        MuiFormHelperText: {
            root: {
                fontSize: '1.2rem',
            },
            contained: {
                marginLeft: 4,
            },
        },
        MuiButton: {
            root: {
                fontSize: '1.4rem',
                backgroundColor: gray[60],
                textTransform: 'none',
                boxShadow: 'none',
                borderRadius: '4px',
            },
            contained: {
                boxShadow: 'none',
                fontWeight: 'bold',
            },
            outlined: {
                backgroundColor: 'transparent',
                borderColor: gray[60],
                color: gray[100],
            },
        },
        MuiAutocomplete: {
            option: {
                fontSize: '1.4rem',
            },
            noOptions: {
                fontSize: '1.4rem',
            },
        },
        MuiOutlinedInput: {
            notchedOutline: {
                fontSize: '1.4rem',
            },
            input: {
                padding: '16px 14px',
                borderRadius: 4,
            },
        },
        MuiInputLabel: {
            root: {
                fontSize: '1.4rem',
            },
        },
        MuiInputBase: {
            input: {
                fontSize: '1.4rem',
                lineHeight: '21px',
            },
        },
        MuiMenu: {
            paper: {
                marginTop: 8,
            },
        },
        MuiMenuItem: {
            root: {
                fontSize: '1.4rem',
            },
        },
        MuiTooltip: {
            tooltip: {
                padding: 8,
                fontSize: '1.4rem',
                lineHeight: '21px',
                color: black[100],
                backgroundColor: white[100],
                border: `2px solid ${gray[30]}`,
                borderRadius: 0,
            },
        },
        MuiTableCell: {
            root: {
                fontSize: '1.4rem',
            },
            head: {
                fontWeight: 700,
            },
        },
        MuiFormControlLabel: {
            label: {
                fontSize: '1.4rem',
            },
        },
    },
};

export { muiTheme };

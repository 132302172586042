import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetAllItemTypeQueryType,
} from '@modules/types/graphql';
import { GetlistAllItemTypesQuery } from '../graphql';
import { AllItemTypesEntityList } from '../entities/all-item-types-entity-list';

const useAllItemTypes = (options?: QueryHookOptions<GetAllItemTypeQueryType>) => {
    const queryResult = useQuery<GetAllItemTypeQueryType>(
        GetlistAllItemTypesQuery,
        options,
    );

    const { entities: allItems, count } = useEntity(
        () => new AllItemTypesEntityList(queryResult.data?.listAllItemTypes ?? null),
        [queryResult.data],
    );

    const result = {
        allItems,
        count,
        ...queryResult,
    };
    return result;
};

export { useAllItemTypes };

import React, {ChangeEvent} from 'react';
import styled from 'styled-components';
import 'react-circular-progressbar/dist/styles.css';
import { Button } from '@modules/ui/core';
import { CloudUpload } from '@material-ui/icons';
import {AssetCreateInput, CsvReader, ParseError} from '@modules/registry/organisms/assets-datatable/CsvReader';
import {useMutation} from '@apollo/client';
import {GetAssetsQuery} from '@modules/registry/graphql';
import {useEnqueueStacks} from '@modules/layout/hooks';
import {ImportAsset} from '@modules/registry/graphql/mutations/import-asset';
import {GraphQLError} from 'graphql/error/GraphQLError';

const ActionButton = styled(Button)`
    min-width: 120px;

    svg {
        font-size: 2rem;
        bottom: -5px;
        position: relative;
    }
`;

export function AssetImportButton() {
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [importAsset] = useMutation(ImportAsset);

    const EscapedActionButton = ActionButton as any;

    async function onFileUpload(event: ChangeEvent) {
      let fileInput = event.target as HTMLInputElement;
      let csvReader = new CsvReader();
      let result: AssetCreateInput[] = [];
      try {
        let resultData = await csvReader.readFile(fileInput);
        if (resultData instanceof ParseError) {
          enqueueError(resultData.message);
          console.error(resultData);
          return ;
        } else {
          result = resultData;
        }
      } catch (error) {
        enqueueError('Unexpected file parsing error');
        console.error('Unexpected file parsing error', error);
      }

      if (!result.length) {
        console.warn('Asset list is empty but empty file checked previously');
        return ;
      }

      importCsvData(result);
    }

    async function importCsvData(result: AssetCreateInput[]) {
      const variables = {
        input: result,
      };

      try {
        const requestResult = await importAsset({
          variables,
          refetchQueries: [{ query: GetAssetsQuery }],
          awaitRefetchQueries: true,
        });
        const importAssetData = requestResult.data;
        const errors = (requestResult as any).userErrors as ReadonlyArray<GraphQLError>;

        // saved entities count should be the same as sent items count
        if (importAssetData?.importAsset?.id !== result.length) {
          const errorSmg = ['There was an error during assets import'];
          if (errors?.length) {
            errors.forEach(error => errorSmg.push(error.message));
          }
          enqueueError(errorSmg.join('\n'));
          return ;
        }

        enqueueSuccess('Assets was successfully imported!');
      } catch (e) {
        throw e;
      }
    }

    return (
      <EscapedActionButton size='medium'
                           variant='contained'
                           style={{ marginRight: 12 }}
                           component="label">
        <CloudUpload style={{ marginRight: 10 }} />
        <span>Upload</span>
        <input type="file"
               id="importDataFile"
               hidden
               style={{ display: 'none' }}
               onChange={onFileUpload}
        />
      </EscapedActionButton>
    );
}

import React from 'react';

import { PublicRoute, PrivateRoute } from '@modules/auth/moleculas';

import type { Route } from '@config/routes';

const renderRoutes = (routes: Route[]) => {
    const rendered = routes.map(route => {
        const { private: isPrivate, ...otherRoute } = route;

        const RouteComponent = isPrivate ? PrivateRoute : PublicRoute;

        return <RouteComponent key={otherRoute.name} private={isPrivate} {...otherRoute} />;
    });

    return rendered;
};

export { renderRoutes };

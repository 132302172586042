import React, { useEffect, useState } from 'react';
import { PageStyled } from '@modules/layout/styled';
import { AccordionListItem } from '@modules/layout/organisms';
import styled from 'styled-components';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { useMutation } from '@apollo/client';
import {
    DeleteComponentMutationType,
    DeleteComponentMutationVariables,
} from '@modules/types/graphql';
import { DeleteComponentMutation } from '@modules/custom-components/graphql';
import { GetAllAssessmentTypeQuery } from '@modules/components/graphql';
import { useCurrentUser } from '@modules/auth/hooks';
import { CustomTypeListToolbar } from '@modules/custom-components/moleculas/custom-items-list-toolbar';
import DefaultTypeTable from './default-type-data';

type AllComponentsProps = {
    allTypeData?: any;
    categoryId?: number;
};

const DeleteSpan = styled.div`
    color: crimson;
`;

const Root = styled.div`
    width: 100%;
    border-bottom: 1px solid #d1d2d2;
    margin-top: 20px;
    position: relative;
`;

const TypeDataList = (props: AllComponentsProps): React.ReactElement => {
    const { allTypeData, categoryId } = props;
    const [componentSearch, setComponentSearch] = useState<string>('');
    const [filterData, setFilterData] = useState<any[]>();
    const [componentId, setComponentId] = useState<number | any>(null);
    const [openEditModal, setOpenEditModal] = useState<Boolean | any>(false);
    const [selectedId, setSelectedId] = useState<number | any>(null);

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const { currentUser } = useCurrentUser();

    const handleOpenEditModal = () => setOpenEditModal(true);
    const handleCloseEditModal = () => setOpenEditModal(false);

    const componentActionStatus = true;

    const [deleteComponent, { loading: deleteComponentLoading }] = useMutation<
        DeleteComponentMutationType,
        DeleteComponentMutationVariables
    >(DeleteComponentMutation);

    const handleDeleteComponent = async (id: number): Promise<void> => {
        setSelectedId(id);
        try {
            const { data: deleteComponentData } = await deleteComponent({
                variables: { componentId: id },
                refetchQueries: [{ query: GetAllAssessmentTypeQuery }],
                awaitRefetchQueries: true,
            });

            if (deleteComponentData?.deleteComponent) {
                enqueueSuccess('Component successfully deleted!');
            } else {
                enqueueError('An error occurred while deleting the component!');
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleEditComponent = (id: number) => {
        setComponentId(id);
        handleOpenEditModal();
    };

    useEffect(() => {
        const filterComponent = allTypeData?.filter((ele: any) => {
            if (typeof ele?.name === 'string') {
                return ele?.name.toLowerCase().includes(componentSearch.toLocaleLowerCase());
            }
            return false;
        });
        setFilterData(filterComponent);
    }, [componentSearch, allTypeData]);

    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>Type</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>

            <PageStyled.Bridge>
                <CustomTypeListToolbar
                    ButtonTitleName={'Create New Type'}
                    setComponentSearch={setComponentSearch}
                    componentSearch={componentSearch}
                    assessmentId={categoryId}
                />
            </PageStyled.Bridge>
            <Root>
                {filterData?.length ? (
                    filterData?.map((component: any) => {
                        // const canEditComponent = true
                        //     ? currentUser.hasSuperAdminPermission() ||
                        //       currentUser.hasAdminPermission()
                        //     : true;
                        return (
                            <React.Fragment key={component?.id}>
                                <AccordionListItem
                                    title={
                                        selectedId === component?.id ? (
                                            deleteComponentLoading ? (
                                                <DeleteSpan>Deleting...</DeleteSpan>
                                            ) : (
                                                component?.name
                                            )
                                        ) : (
                                            component?.name
                                        )
                                    }
                                    componentActionStatus={componentActionStatus}
                                    componentSearch={componentSearch}
                                    subtitle=''
                                    SummaryInfoComponents={
                                        <></>
                                    }
                                >
                                    {/* <DefaultTypeData
                                        defaultTypeData={component?.defaultType}
                                        typeId={component?.id}
                                    /> */}
                                    <DefaultTypeTable
                                        defalutData={component?.defaultType}
                                        typeId={component.id}
                                    />
                                </AccordionListItem>
                            </React.Fragment>
                        );
                    })
                ) : (
                    <h2>No Component found</h2>
                )}

                {/* <CustomComponentEditModal
                    open={openEditModal}
                    assessmentId={assessmentId}
                    componentId={componentId}
                    GetAllAssessmentTypeQuery={GetAllAssessmentTypeQuery}
                    onClose={handleCloseEditModal}
                /> */}
            </Root>
        </>
    );
};

export { TypeDataList };

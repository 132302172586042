import { gql } from '@apollo/client';

export const GetMaturityLevelsQuery = gql`
    query getMaturityLevels {
        getMaturityLevels {
            id
            name
        }
    }
`;

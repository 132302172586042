import {ComponentRecommendationEntity, RoadmapRecommendationEntity} from '@modules/roadmap/entities';
import {ProjectEntity} from '@modules/projects/entities';
import {GapAssessment} from '@modules/assessment/gap-assessment';

export type RecommendationAssessment = {
  roadmapRecommendation: RoadmapRecommendationEntity;
} & GapAssessment

export type RecommendationAssessmentRoadmapReport = {
  componentRecommendation: ComponentRecommendationEntity;
} & RecommendationAssessment

export function getPrioritizedRoadmapAssessmentMap(project: ProjectEntity): Map<number, RecommendationAssessment> {
  let result: Map<number, RecommendationAssessment> = new Map();
  project.assessments
  // filter to be align with assessment report results
  // .filter(value => value.maturityLevel)
  .forEach((componentAssessment) => {
    componentAssessment.capabilities.forEach((capabilityAssessment) => {
      capabilityAssessment.scoredGaps.forEach((scoredGap) => {
        scoredGap?.roadmapRecommendations
        // filter to be align with assessment report roadmap results
        // .filter(value => {
        //   // if (!isRoadmapStatusAllowed(value?.refinement?.status)) {
        //   //   console.log('filter', value)
        //   // }
        //   return isRoadmapStatusAllowed(value?.refinement?.status);
        // })
        .forEach((roadmapRecommendation: RoadmapRecommendationEntity|null) => {
          result.set(roadmapRecommendation?.id ?? -1, {
            componentAssessment,
            capabilityAssessment,
            scoredGap,
            roadmapRecommendation,
          } as RecommendationAssessment)
        })
      })
    })
  });

  result.delete(-1);
  return result;
}

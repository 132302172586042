import React from 'react';

import { Modal } from '@modules/ui/core';

import type { ModalProps } from '@modules/ui/core';
import { CustomCategoryCreateForm } from '@modules/custom-components/organisms/custom-category-form';

type CustomComponentCreateModalProps = Omit<ModalProps, 'title' | 'children'> & {
    companyId?: number;
    type: string;
};

const CustomCategoryCreateModal = (props: CustomComponentCreateModalProps): React.ReactElement => {
    const { companyId, type, onClose, ...otherProps } = props;

    return (
        <Modal
            title={type === 'create' ? 'Create Category' : 'Edit Category'}
            onClose={onClose}
            {...otherProps}
        >
            <CustomCategoryCreateForm type={type} companyId={companyId} onCancel={onClose} />
        </Modal>
    );
};

export { CustomCategoryCreateModal };

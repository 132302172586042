import { gql } from '@apollo/client';

export const GetListAssetTypeQuery = gql`
    query listAssetType {
        listAssetType {
            id
            name
            category_id
        }
    }
`;

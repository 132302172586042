import { useMutation } from '@apollo/client';
import { routes } from '@config/routes';
import { createRouteUrl } from '@lib/routing';
import { useCurrentUser } from '@modules/auth/hooks';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { Loader } from '@modules/layout/moleculas';
import { TableColumn, TableSortLearnTko } from '@modules/layout/organisms/table';
import { PageStyled } from '@modules/layout/styled';
import { DeleteLearnTkoDetailsMutation } from '@modules/learn-tko/graphql/mutations';
import { GetAllLearnTkoDetailQuery } from '@modules/learn-tko/graphql/queries/get-all-learn-tko-detail';
import { useAllLearnTkoDetails } from '@modules/learn-tko/hooks';
import { LearnTkoListToolbar } from '@modules/learn-tko/organisms';
import {
    DeleteLearnTkoDetailsMutationType,
    DeleteLearnTkoDetailsMutationVariables,
} from '@modules/types/graphql';
import { useNavigate } from '@reach/router';
import React, { useState } from 'react';
import styled from 'styled-components';

const Main = styled(PageStyled.Main)`
    margin-top: 64px;
`;

const columns: TableColumn[] = [
    {
        id: 'menu',
        label: 'Menu',
    },
    {
        id: 'title',
        label: 'Title',
    },
    // {
    //     id: 'description',
    //     label: 'Description',
    // },
    {
        id: 'video_url',
        label: 'Video Url',
    },
    {
        id: 'actions',
        label: 'Actions',
        align: 'right',
    },
];

const ViewLearnTko = (): React.ReactElement => {
    const navigate = useNavigate();
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const { learnTkoAllDetails, loading: learnTkoLoading } = useAllLearnTkoDetails();
    const { currentUser, loading: currentUserLoading } = useCurrentUser();
    const [updateMenuModal, setUpdateMenuModal] = useState<boolean>(false);

    const canDeleteUser = currentUser.hasSuperAdminPermission() || currentUser.hasAdminPermission();

    const [deleteLearnTkoDetail] = useMutation<
        DeleteLearnTkoDetailsMutationType,
        DeleteLearnTkoDetailsMutationVariables
    >(DeleteLearnTkoDetailsMutation);

    const handleClickRedirect = (learnTkoId: number) => {
        navigate(createRouteUrl(routes.editLearnTko, { id: learnTkoId }));
    };

    const handleDeleteLearnTko = async (learnTkoId: number): Promise<void> => {
        try {
            const { data: deleteLearnTkoData } = await deleteLearnTkoDetail({
                variables: { learnTkoId: learnTkoId },
                refetchQueries: [{ query: GetAllLearnTkoDetailQuery }],
                awaitRefetchQueries: true,
            });

            if (deleteLearnTkoData?.deleteLearnTkoDetail?.id) {
                enqueueSuccess('User successfully deleted!');
            } else {
                enqueueError('An error occurred while deleting the learn tko!');
            }
        } catch (e) {
            console.log(e);
            enqueueError(e);
        }
    };

    const handleOpenUpdateMenuModal = () => setUpdateMenuModal(true);

    if (learnTkoLoading) {
        return <Loader />;
    }

    return (
        <div>
            <PageStyled.Bridge>
                <LearnTkoListToolbar />
            </PageStyled.Bridge>
            <Main style={{ display: 'unset' }}>
                <TableSortLearnTko
                    handleClickRedirectEdit={handleClickRedirect}
                    columns={columns}
                    canDeleteUser={canDeleteUser}
                    handleDeleteLearnTko={handleDeleteLearnTko}
                    learnTkoAllDetails={learnTkoAllDetails}
                    learnTkoLoading={learnTkoLoading}
                    openModal={handleOpenUpdateMenuModal}
                />
            </Main>
        </div>
    );
};

export { ViewLearnTko };

import { gql } from '@apollo/client';

import { SubscriptionsFragment } from '../fragments';

export const GetSubscriptionsQuery = gql`
    query listSubscriptions {
        listSubscriptions {
            ...Subscriptions
        }
    }
    ${SubscriptionsFragment}
`;

import { gql } from '@apollo/client';

import { CapabilityLevelSingleFragment } from '../fragments';

export const UpdateCapabilityLevelMutation = gql`
    mutation UpdateCapabilityLevel(
        $capabilityLevelId: Int!
        $capabilityId: Int!
        $capabilityLevelDefinitionId: Int!
        $description: String
    ) {
        updateCapabilityLevel(
            capability_level_id: $capabilityLevelId
            capability_id: $capabilityId
            capability_level_definition_id: $capabilityLevelDefinitionId
            description: $description
        ) {
            ...CapabilityLevelSingle
        }
    }
    ${CapabilityLevelSingleFragment}
`;

import type { Theme } from '@material-ui/core';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

type BreakpointProps = {
    theme: Theme;
};

const breakpoints = {
    up: (key: Breakpoint | number) => (props: BreakpointProps) => props.theme.breakpoints.up(key),
    only: (key: Breakpoint) => (props: BreakpointProps) => props.theme.breakpoints.only(key),
    width: (key: Breakpoint) => (props: BreakpointProps) => props.theme.breakpoints.width(key),
    down: (key: Breakpoint | number) => (props: BreakpointProps) =>
        props.theme.breakpoints.down(key),
    between: (start: Breakpoint | number, end: Breakpoint | number) => (props: BreakpointProps) =>
        props.theme.breakpoints.between(start, end),
};

export { breakpoints };

import { gql } from '@apollo/client';

export const GetlistAllItemTypesQuery = gql`
    query listAllItemTypes {
        listAllItemTypes  @connection(key: "AllItemType") {
            id
            name
            item_category {
                id
                name
                item_id
                category_type {
                    id
                    name
                    category_id
                    default_type {
                        category_id
                        type_ids
                        item_category_type_id
                    }
                }
            }
        }
    }
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { Modal } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';

import type { ModalProps } from '@modules/ui/core';
import type {
    CreateOrgFormMutationType,
    CreateOrgFormMutationVariables,
} from '@modules/types/graphql';

import { OrgFormCreateRequest } from '@modules/components/requests';
import { OrgProfileCreateFormValues } from '@modules/components/requests';
import { CreateOrgFormMutation } from '@modules/components/graphql';
import { OrgProfileForm } from '../org-profile-form/org-profile-form';

type OrgProfileFormModalProps = Omit<ModalProps, 'title' | 'children'> & {
    userId: number;
    username: string;
    userRoleId: string;
    companyName: any;
};

const FormWrap = styled.div`
    width: 100%;
`;

const OrgProfilesFormModal = (props: OrgProfileFormModalProps) => {
    const { userId, username, userRoleId, companyName, onClose, ...otherProps } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    // const [workforceData, setWorkforceData] = useState<any>([]);
    const [orgLocationData, setOrgLocationData] = useState<any>([]);
    const [submitOrganizationForm] = useMutation<
        CreateOrgFormMutationType,
        CreateOrgFormMutationVariables
    >(CreateOrgFormMutation);

    const initialValues = {
        userId: userId,
        username: username,
        numberOfEmployees: 0,
        sector: '',
        organizationType: '',
        // orgLocation: '',
        workforce: '',
        userRoleId: userRoleId,
    };

    var datas: any = [];

    const handleSubmit = async (values: any) => {
        try {
            let result = undefined;
            localStorage.removeItem('modalStatus');
            // values.workforce = workforceData?.map((ele: string) => ele);
            // values.orgLocation = orgLocationData?.flatMap((ele: any) => ele).toString();
            values.orgLocation = `[${orgLocationData.join(', ')}]`;
            const variables = values;
            const { data: createOrgFormData } = await submitOrganizationForm({
                variables,
                awaitRefetchQueries: true,
            });
            result = createOrgFormData?.submitOrganizationForm?.user_id;
            if (result) {
                enqueueSuccess('Organization Profile successfully created.');
                onClose();
            } else {
                enqueueError('Organization Profile has already created.');
            }
        } catch (e) {
            throw e;
        }
    };

    return (
        <Modal title='Create Organization Profile' onClose={onClose} {...otherProps}>
            <OrgProfileForm<'create'>
                type='create'
                initialValues={initialValues}
                FormWrapComponent={FormWrap}
                onSubmit={handleSubmit}
                onCancel={onClose}
                data={datas}
                companyName={companyName}
                setOrgLocationData={setOrgLocationData}
            />
        </Modal>
    );
};

export { OrgProfilesFormModal };

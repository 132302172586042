export type AcceptRecommendationRequestFormValues = {
    notes: string;
    constraints: string;
    dependencies: string;
};

export type AcceptRecommendationRequestExtra = {
    roadmapRecommendationId: number;
};

class AcceptRecommendationRequest {
    roadmapRecommendationId: number;
    notes: string;
    constraints: string;
    dependencies: string;

    constructor(
        values: AcceptRecommendationRequestFormValues,
        extra: AcceptRecommendationRequestExtra,
    ) {
        this.roadmapRecommendationId = extra.roadmapRecommendationId;
        this.notes = values.notes;
        this.constraints = values.constraints;
        this.dependencies = values.dependencies;
    }
}

export { AcceptRecommendationRequest };

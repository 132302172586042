import { gql } from '@apollo/client';

import { CompanyFragment } from '../fragments';

export const GetCompanyQuery = gql`
    query GetCompany($id: Int!) {
        getCompany(id: $id) {
            ...Company
        }
    }
    ${CompanyFragment}
`;

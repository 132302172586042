import { EntityList } from '@lib/entity';

import { AllComponentEntity } from './all-component-entity';
import type { AllComponentEntityType as Entity } from './all-component-entity';

class AllComponentEntityList extends EntityList<AllComponentEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(AllComponentEntity, items, count);
    }
}

export { AllComponentEntityList };

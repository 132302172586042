import React, { useState } from 'react';

import { ListStyled } from '@modules/layout/styled';
import styled, { css } from 'styled-components';
import { ReportComponentView } from '@modules/assessment/organisms/report-components-list/report-component-view';
import { GapCapabilityAssessment, GapViewData } from '@modules/assessment/gap-by-risk';
import { ComponentAssessmentEntity } from '@modules/assessment/entities';
import { ProjectEntity } from '@modules/projects/entities';
import { Loader } from '@modules/layout/moleculas';
import { AccordionInnerListItem } from '@modules/layout/organisms';
import { scoreVariantColorsMap } from '@modules/shared/constants';
import { ReportRiskStatusVIew } from './report-risk-status-body-view';
import { gray } from '@modules/ui/colors';

type ReportBodyProps = {
    componentsAssessmentList: ComponentAssessmentEntity[];
    componentAssessmentRawMap: Map<number, GapCapabilityAssessment[]>;
    gapViewData: GapViewData;
    activeTab: number;
    project?: ProjectEntity;
    projectLoading?: boolean;
    componentsAssessmentRawList?: any;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 16px;
    margin-bottom: 24px;
    background-color: ${gray[60]};
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;

    > * {
        width: 20%;
        font-size: 1.4rem;
    }
`;

const InnerHeaderExtra = styled.div`
    > h3 {
        margin-bottom: 6px;
        font-weight: 700;
    }
`;

const ItemTitle = styled.div<Pick<any, 'riskLevelKey'>>`
    width: 100%;

    > h3 {
        display: inline-flex;
        padding: 4px;
        font-size: 2.2rem;
        font-weight: 700;
        border-radius: 4px;

        ${p => css`
            color: ${scoreVariantColorsMap[p.riskLevelKey].color};
            background-color: ${scoreVariantColorsMap[p.riskLevelKey].background};
        `}
    }
`;

export const ReportBodyView = (props: ReportBodyProps): React.ReactElement => {
    // const gapByRisk = useMemo(() => GapByRisk.gapReportByRisk(props.componentReports, assessmentMap), [props.project.assessments]);

    const [expandedRow, setExpandedRow] = useState<string | null>(null);

    const onExpand = (riskLevel: string) => {
        if (expandedRow === riskLevel) {
            setExpandedRow(null);
        } else {
            setExpandedRow(riskLevel);
        }
    };
    const riskLevels = Array.from(new Set(props.gapViewData.riskList.map(test => test.scoredGap.riskLevel)));
    if (props.projectLoading) {
        return <Loader />;
    }
    return (
        <ListStyled.Container>
            {props.activeTab === 0 && (
                <ReportComponentView
                    project={props?.project}
                    componentsAssessmentList={props.componentsAssessmentList}
                    // by using componentAssessmentRawMap we not consider filter on gap level
                    // by using gapViewData.componentAssessmentMap we apply crossfiltering:
                    // 1 on component level (applied to componentsAssessmentList, see assessmentFilterHandeler)
                    // 2 on gap level (applied to gapViewData.componentAssessmentMap, see componentAssessmentMapFilterHandeler)
                    componentAssessmentMap={props.componentAssessmentRawMap}
                    componentsAssessmentRawList={props.componentsAssessmentRawList}
                />
            )}
            {props.activeTab === 1 &&
                riskLevels.map((riskLevel) =>
                (
                    <React.Fragment key={riskLevel}>
                        <Root>
                            <AccordionInnerListItem
                                title={''}
                                subtitle={''}
                                expanded={expandedRow === riskLevel}
                                onChange={() => onExpand(riskLevel)}
                                SummaryInfoComponents={
                                    <Header>
                                        <InnerHeaderExtra>
                                            <ItemTitle riskLevelKey={riskLevel}>
                                                <h3>{riskLevel}</h3>
                                            </ItemTitle>
                                        </InnerHeaderExtra>
                                    </Header>
                                }
                            >
                                <ReportRiskStatusVIew
                                    project={props?.project}
                                    gapList={props.gapViewData.riskList.filter((data: any) => data.scoredGap.riskLevel === riskLevel)}
                                    projectLoading={props.projectLoading}
                                />
                            </AccordionInnerListItem>
                        </Root>
                    </React.Fragment>
                )
                )
            }
        </ListStyled.Container>
    );
};

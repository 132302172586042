import React from 'react';
import styled from 'styled-components';

type ComponentsAssessmentStaffProps = {
    componentOwners: string;
    operators: string;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-top: 24px;
`;

const PeopleList = styled.div`
    > * {
        &:not(:last-of-type) {
            margin-bottom: 12px;
        }
    }
`;

const PeopleListItem = styled.div`
    font-size: 1.4rem;
    line-height: 16px;

    > * {
        display: inline-flex;
    }

    > span {
        width: 150px;
    }

    > p {
        margin-left: 8px;
    }
`;

const ComponentsAssessmentStaff = (props: ComponentsAssessmentStaffProps): React.ReactElement => {
    const { componentOwners, operators } = props;

    return (
        <Root>
            <PeopleList>
                {componentOwners ? (
                    <PeopleListItem>
                        <span>Component Owners:</span>
                        <p>{componentOwners}</p>
                    </PeopleListItem>
                ) : null}

                {operators ? (
                    <PeopleListItem>
                        <span>Operators:</span>
                        <p>{operators}</p>
                    </PeopleListItem>
                ) : null}
            </PeopleList>
        </Root>
    );
};

export { ComponentsAssessmentStaff };

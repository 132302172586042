import { gql } from '@apollo/client';

import { RequirementSingleFragment } from '../fragments';

export const CreateRequirementMutation = gql`
    mutation CreateRequirement($recommendationId: Int!, $requirement: String!) {
        createRequirement(recommendation_id: $recommendationId, requirement: $requirement) {
            ...RequirementSingle
        }
    }
    ${RequirementSingleFragment}
`;

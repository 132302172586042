import { EntityList } from '@lib/entity';
import { AssignedUserEntity } from './assigned-user-entity';

import type { Entity } from './assigned-user-entity';

class AssignedUserEntityList extends EntityList<AssignedUserEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(AssignedUserEntity, items, count);
    }
}

export { AssignedUserEntityList };

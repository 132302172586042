import React from 'react';
import styled, { css } from 'styled-components';
import {
    withStyles,
    makeStyles,
    Stepper,
    Step,
    StepConnector,
    StepLabel,
    StepIcon,
} from '@material-ui/core';

import { gray } from '@modules/ui/colors';

const steps = [
    {
        id: 1,
        name: 'Adding components',
        description: 'The project has been created. Components are being added.',
    },
    {
        id: 2,
        name: 'Assessment',
        description: 'Notifications sent. Assessment in progress.',
    },
    {
        id: 3,
        name: 'Roadmap',
        description:
            'Validates (accepts), rejects, modify, or acknowledges (already being worked). Create Requirements.',
    },
    {
        id: 4,
        name: 'Action Plan',
        description:
            'Validates (accepts), rejects, modify, or acknowledges (already being worked). Create Action plan.',
    },
    {
        id: 5,
        name: 'MVP Requirements Created',
    },
];

const useStepStyles = makeStyles({
    root: {
        maxWidth: 180,
    },
});

const useStepLabelStyles = makeStyles({
    root: {
        alignItems: 'flex-start',

        '&$disabled $alternativeLabel': {
            color: gray[30],
        },
    },

    label: {
        '&$alternativeLabel': {
            fontSize: '1.4rem',
            fontWeight: 700,
            textAlign: 'left',
        },
    },

    iconContainer: {
        '&$alternativeLabel': {
            paddingLeft: 18,
        },
    },

    alternativeLabel: {},
    disabled: {},
});

const StyledStepConnector = withStyles({
    alternativeLabel: {
        top: 12,
        left: 'calc(-80% + 30px)',
        right: 'calc(80% + 20px)',
    },
    lineHorizontal: {
        borderTopWidth: 2,
    },
})(StepConnector);

const StyledStepIcon = withStyles({
    root: {
        position: 'relative',
        fontSize: '2.4rem',
        color: gray[50],
        borderRadius: '50%',

        '&$active': {
            color: gray[10],
            border: `5px solid ${gray[50]}`,
        },
    },
    text: {
        display: 'none',
    },
    active: {},
})(StepIcon);

const Root = styled.div`
    display: flex;
    flex-direction: column;

    > * {
        width: 100%;
    }
`;

const Title = styled.div`
    padding-left: 32px;
    font-size: 1.6rem;
    font-weight: 700;
`;

const StepDescription = styled.div<{ disabled?: boolean }>`
    margin-top: 4px;
    padding-right: 12px;
    font-size: 1.2rem;
    line-height: 14px;

    ${p =>
        p.disabled &&
        css`
            color: ${gray[30]};
        `}
`;

const ProjectProgressBar = (): React.ReactElement => {
    const stepClasses = useStepStyles();
    const stepLabelClasses = useStepLabelStyles();

    return (
        <Root>
            <Title>Project Progress</Title>
            <Stepper alternativeLabel connector={<StyledStepConnector />}>
                {steps.map(step => (
                    <Step key={step.id} classes={stepClasses}>
                        <StepLabel classes={stepLabelClasses} StepIconComponent={StyledStepIcon}>
                            {step.name}
                        </StepLabel>

                        {step.description ? (
                            <StepDescription>{step.description}</StepDescription>
                        ) : null}
                    </Step>
                ))}
            </Stepper>
        </Root>
    );
};

export { ProjectProgressBar };

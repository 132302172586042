import { EntityList } from '@lib/entity';
import type { Entity } from './ask-tko-detials-entity';
import { AskTkoDetailsEntity } from './ask-tko-detials-entity';

class AskTkoDetailsEntityList extends EntityList<AskTkoDetailsEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(AskTkoDetailsEntity, items, count);
    }
}

export { AskTkoDetailsEntityList };

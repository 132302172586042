import type { ProjectsTableFiltersQuery } from '@modules/projects/hooks';
import type { GetProjectsQueryVariables } from '@modules/types/graphql';

class ProjectListRequest {
    filter?: GetProjectsQueryVariables['filter'];
    status?: GetProjectsQueryVariables['status'];
    assessmentTypes?: (number | null)[] | null | undefined;
    maturityLevelId?: (number | null)[] | null | undefined;

    constructor(values: ProjectsTableFiltersQuery['filters']) {
        if (values.search) {
            this.filter = values.search;
        }

        if (values.status) {
            this.status = values.status;
        }

        if (values.assessmentTypes) {
            this.assessmentTypes = values.assessmentTypes.map((value) => Number(value));
        }

        if (values.maturityLevelId) {
            this.maturityLevelId = values.maturityLevelId.map((value) => Number(value));
        }
    }
}

export { ProjectListRequest };

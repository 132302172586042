import React from 'react';
import styled, { css } from 'styled-components';

import { gray } from '@modules/ui/colors';
import { scoreVariantColorsMap } from '@modules/shared/constants';

import type { RoadmapRecommendationEntity } from '@modules/roadmap/entities';
import {roadmapRecommendationTitle} from '@helpers';

type ReportRoadmapCapabilityRecommendationsListProps = {
    variant: keyof typeof scoreVariantColorsMap;
    title: string;
    recommendations: RoadmapRecommendationEntity[];
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
`;

const Title = styled.div`
    width: 100%;
    font-size: 1.6rem;
    font-weight: 700;
`;

const TitleInner = styled.div<Pick<ReportRoadmapCapabilityRecommendationsListProps, 'variant'>>`
    display: inline-flex;
    padding: 4px 8px;
    border: 1px solid ${gray[50]};
    border-radius: 4px;

    ${p => css`
        color: ${scoreVariantColorsMap[p.variant].color};
        background-color: ${scoreVariantColorsMap[p.variant].background};
    `}
`;

const List = styled.div`
    width: 100%;
    margin-top: 12px;
    padding: 0 8px;

    > *:not(:last-child) {
        margin-bottom: 6px;
        border-bottom: 1px solid ${gray[50]};
    }
`;

const Item = styled.div`
    padding: 8px 0;
    font-size: 1.4rem;
`;

const ReportRoadmapCapabilityRecommendationsList = (
    props: ReportRoadmapCapabilityRecommendationsListProps,
): React.ReactElement => {
    const { variant, title, recommendations } = props;

    return (
        <Root>
            <Title>
                <TitleInner variant={variant}>{title}</TitleInner>
            </Title>

            <List>
                {recommendations.map(recommendation => {
                    const displayRecommendation = roadmapRecommendationTitle(recommendation);

                    return <Item key={recommendation.id}>{displayRecommendation}</Item>;
                })}
            </List>
        </Root>
    );
};

export { ReportRoadmapCapabilityRecommendationsList };

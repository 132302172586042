import React, {useMemo} from 'react';
import styled from 'styled-components';

import { Button, Modal, TextField } from '@modules/ui/core';
import type { ModalProps } from '@modules/ui/core';
import * as yup from 'yup';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { MenuItem, makeStyles } from '@material-ui/core';
import { gray } from '@modules/ui/colors';
import { useUsers } from '@modules/users/hooks';
import { useMutation } from '@apollo/client';
import { useEnqueueStacks } from '@modules/layout/hooks';
import {UpdateProjectRoleMutation} from '@modules/projects/graphql';
import {Role, User} from '@modules/types/graphql';

const StyledModal = styled(Modal)`
    max-width: 600px;
`;

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }

    .is-select {
        width: 536px;
        background-color: ${gray[90]};

        .MuiInputBase-root > div {
            display: initial;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

export const ChangeAssessmentUserModal = (
    props: ModalProps & { title: string; onUserChange: (user: User) => void; initialValues: {projectId: number, roleId: string; riskExecutives: number[]} },
): React.ReactElement => {
    const { onClose, title, onUserChange, initialValues: initialValuesRaw, ...otherProps } = props;
    const { users, loading: usersLoading } = useUsers();
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [updateProjectRole] = useMutation(UpdateProjectRoleMutation);

    const isMultiple = Array.isArray(initialValuesRaw.riskExecutives) && initialValuesRaw.riskExecutives.length > 1;
    const riskExecutivesInitial = isMultiple ? initialValuesRaw.riskExecutives : initialValuesRaw.riskExecutives[0];
    let initialValues = {riskExecutives: riskExecutivesInitial};
    let selectedValues: any = [];

    async function onSubmit(values: any): Promise<void> {
        selectedValues = values?.riskExecutives ? values.riskExecutives : selectedValues;
        if (selectedValues === riskExecutivesInitial) {
            return ;
        }

        try {
            const result = await updateProjectRole({
                variables: {
                    projectId: initialValuesRaw.projectId,
                    roleId: initialValuesRaw.roleId,
                    userId: Array.isArray(selectedValues) ? selectedValues[0] : selectedValues
                },
                // refetchQueries: [{ query: GetRiskRegistryUsersQuery }],
                // awaitRefetchQueries: true,
            });

            if (result?.data?.updateProjectRole) {
                onUserChange(result?.data?.updateProjectRole)
                enqueueSuccess(`${title} successfully updated!`);
                onClose();
            } else {
                enqueueError(`An error occurred while updating ${title.toLowerCase()}!`);
                onClose();
            }
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <StyledModal title={title} onClose={onClose} {...otherProps}>
            <ExtendedFormik
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                // validationSchema={yup.object({
                //     riskExecutives: yup.array().required('Required field'),
                // })}
                initialValues={initialValues}
                onSubmit={values => onSubmit(values)}
            >
                {formikProps => {
                    const { values, errors, handleChange, handleBlur, isSubmitting } = formikProps;

                    return (
                        <StyledForm>
                            <TextField
                                select
                                name='riskExecutives'
                                label={title}
                                className='is-select'
                                value={values.riskExecutives}
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{
                                    multiple: isMultiple,
                                    renderValue: (valueRaw: any) => {
                                        selectedValues = valueRaw;

                                        let value = valueRaw as number[] | number;
                                        let valueList = users.filter(user => {
                                            return value === user?.id || (Array.isArray(value) && value.includes(user?.id))
                                          }
                                        );

                                        const selectedView = valueList
                                            .map(user => user?.firstName + ' ' + user?.lastName)
                                            .join(', ');

                                        return selectedView;
                                    },
                                }}
                                onChange={handleChange}
                            >
                                {users ? (
                                    users.map(user => {
                                        const { firstName, id, lastName } = user;
                                        return (
                                            <MenuItem key={id} value={id}>
                                                {firstName} {lastName}
                                            </MenuItem>
                                        );
                                    })
                                ) : (
                                    <MenuItem key={0} value={''}>
                                        Select {title}
                                    </MenuItem>
                                )}
                            </TextField>

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit'
                                              loading={isSubmitting}
                                              // onClick={onSubmit}
                                >
                                    Save
                                </SubmitButton>

                                <SubmitButton
                                    variant='text'
                                    disabled={isSubmitting}
                                    onClick={onClose}
                                >
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    );
                }}
            </ExtendedFormik>
        </StyledModal>
    );
};

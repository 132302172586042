import { gql } from '@apollo/client';
import { GapRecommendationCountFragment } from '../fragments';

export const GetGapRecommendationCountQuery = gql`
    query GetGapsRecommendationsCount($companyId: Int!, $schemaName: String!) {
        getGapsRecommendationsCount(company_id: $companyId, schema_name: $schemaName) {
            ...GapsRecommendationsCount
        }
    }
    ${GapRecommendationCountFragment}
`;

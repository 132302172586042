import React from 'react';

import { Modal } from '@modules/ui/core';

import type { ModalProps } from '@modules/ui/core';

import { CustomComponentEditForm } from '@modules/custom-components/organisms/custom-component-create-form';
import { GetAllAssessmentTypeQuery } from '@modules/components/graphql';

type CustomComponentEditModalProps = Omit<ModalProps, 'title' | 'children'> & {
    componentId?: any;
    GetAllAssessmentTypeQuery?: any;
    assessmentId?: number;
};

const CustomComponentEditModal = (props: CustomComponentEditModalProps): React.ReactElement => {
    const { GetAllAssessmentTypeQuery, componentId, assessmentId, onClose, ...otherProps } = props;

    return (
        <Modal title='Edit Component' onClose={onClose} {...otherProps}>
            <CustomComponentEditForm
                GetAllAssessmentTypeQuery={GetAllAssessmentTypeQuery}
                assessmentId={assessmentId}
                componentId={componentId}
                onCancel={onClose}
            />
        </Modal>
    );
};

export { CustomComponentEditModal };

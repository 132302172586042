import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { GapEntity } from '@modules/custom-components/entities';
import { GetGapQuery } from '@modules/custom-components/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetGapQueryType, GetGapQueryVariables } from '@modules/types/graphql';

const useGap = (options?: QueryHookOptions<GetGapQueryType, GetGapQueryVariables>) => {
    const queryResult = useQuery<GetGapQueryType, GetGapQueryVariables>(GetGapQuery, options);

    const gap = useEntity(() => new GapEntity(queryResult.data?.getGap ?? null), [
        queryResult.data,
    ]);

    const result = {
        gap,
        ...queryResult,
    };

    return result;
};

export { useGap };

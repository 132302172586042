import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';

import { Button, Autocomplete } from '@modules/ui/core';
import { FormStyled } from '@modules/layout/styled';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { Form, ExtendedFormik } from '@modules/layout/organisms';
import { useUsers } from '@modules/users/hooks';
import { useCapabilityAssessment } from '@modules/assessment/hooks';
import {
    GetComponentAssessmentQuery,
    AssignAssessmentCapabilityToOperatorMutation,
} from '@modules/assessment/graphql';

import type { UserListEntity } from '@modules/users/entities';

const StyledForm = styled(Form)`
    width: 100%;

    > *:not(:last-of-type) {
        margin-bottom: 24px;
    }
`;

const AssignAssessmentCapabilityToOperatorForm = (props: any) => {
    const { capabilityAssessmentId, componentAssessmentId, FormWrapComponent, onCancel } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { users, loading: usersLoading } = useUsers();

    const { capabilityAssessment, loading: capabilityAssessmentLoading } = useCapabilityAssessment({
        variables: { surveyId: capabilityAssessmentId },
    });

    const [assignSurveyToOperator] = useMutation(AssignAssessmentCapabilityToOperatorMutation);

    const assignedOperatorId =
        capabilityAssessment.operators && Array.isArray(capabilityAssessment.operators)
            ? capabilityAssessment.operators[0]?.id
            : null;

    const assignedOperator = capabilityAssessment.operators?.length
        ? users.filter(user => user.id === assignedOperatorId)[0]
        : null;

    const initialValues: any = {
        capabilityAssessmentId,
        operator: assignedOperator,
    };

    const getOperatorOptionLabel = React.useCallback(option => option?.getFullName() ?? '-', []);

    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                operator: yup.mixed().required('Required field'),
            })}
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const variables = {
                        assessmentId: capabilityAssessmentId,
                        userId: values.operator?.id,
                    };
                    const { data: assignOperatorData } = await assignSurveyToOperator({
                        variables,
                        refetchQueries: [
                            {
                                query: GetComponentAssessmentQuery,
                                variables: { id: componentAssessmentId },
                            },
                        ],
                        awaitRefetchQueries: true,
                    });

                    if (assignOperatorData?.assignSurveyToOperator?.id) {
                        enqueueSuccess('Operator successfully assigned!');
                        onCancel();
                    } else {
                        enqueueError('An error occurred while assigning the operator!');
                    }
                } catch (e) {
                    console.log('Assign assessment to operator form [Error]:', e);
                    throw e;
                }
            }}
        >
            {formikProps => {
                const { values, errors, setFieldValue, isSubmitting } = formikProps;

                const handleChangeOperator = (
                    _: React.ChangeEvent<{}>,
                    value: UserListEntity | null,
                ) => setFieldValue('operator', value);

                return (
                    <FormWrapComponent>
                        <StyledForm>
                            <FormStyled.FormRow>
                                <Autocomplete<UserListEntity | null, false, false, false>
                                    fullWidth
                                    id='operator'
                                    label='Operator'
                                    placeholder='Choose the operator'
                                    options={users}
                                    value={values.operator}
                                    error={!!errors.operator}
                                    helperText={errors.operator as string}
                                    disabled={usersLoading || capabilityAssessmentLoading}
                                    getOptionLabel={getOperatorOptionLabel}
                                    onChange={handleChangeOperator}
                                />
                            </FormStyled.FormRow>

                            <FormStyled.FormRowSubmit>
                                <Button type='submit' loading={isSubmitting}>
                                    {capabilityAssessment.hasOperators()
                                        ? 'Reassign operator'
                                        : 'Assign operator'}
                                </Button>

                                <Button variant='outlined' onClick={onCancel}>
                                    Cancel
                                </Button>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    </FormWrapComponent>
                );
            }}
        </ExtendedFormik>
    );
};

export { AssignAssessmentCapabilityToOperatorForm };

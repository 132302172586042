import { createCustomError } from '@lib/errors';
import type { CreateCompanyDetailsMutationVariables } from '@modules/types/graphql';

export type CompnayCreateFormValues = {
    userId: number;
    name: string;
    phone: string;
    address: string;
    email: string;
    contactName: string;
    packageType: string;
    subscriptionType: string;
    isAskTko: boolean;
    isLearnTko: boolean;
    isSchema: boolean;
};

class CompanyCreateRequest {
    userId: CreateCompanyDetailsMutationVariables['userId'];
    name: CreateCompanyDetailsMutationVariables['name'];
    phone: CreateCompanyDetailsMutationVariables['phone'];
    address: CreateCompanyDetailsMutationVariables['address'];
    email: CreateCompanyDetailsMutationVariables['email'];
    contactName: CreateCompanyDetailsMutationVariables['contactName'];
    packageType: CreateCompanyDetailsMutationVariables['packageType'];
    subscriptionType: CreateCompanyDetailsMutationVariables['subscriptionType'];
    isAskTko: CreateCompanyDetailsMutationVariables['isAskTko'];
    isLearnTko: CreateCompanyDetailsMutationVariables['isLearnTko'];
    isSchema: CreateCompanyDetailsMutationVariables['isSchema'];

    constructor(values: CompnayCreateFormValues) {
        if (!values.userId) {
            throw createCustomError('incorrect user id');
        }

        // if (!values.packageType) {
        //     throw createCustomError('incorrect packageType');
        // }
        // if (!values.subscriptionType) {
        //     throw createCustomError('incorrect subscriptionType');
        // }
        this.name = values.name;
        this.userId = values.userId;
        this.phone = values.phone;
        this.address = values.address;
        this.email = values.email;
        this.contactName = values.contactName;
        this.packageType = values.packageType;
        this.subscriptionType = values.subscriptionType;
        this.isAskTko = values.isAskTko;
        this.isLearnTko = values.isLearnTko;
        this.isSchema = values.isSchema;
    }
}

export { CompanyCreateRequest };

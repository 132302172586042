import { gql } from '@apollo/client';

export const GetRiskGroupQuery = gql`
    query getRiskGroup {
        getRiskGroup {
            id
            name
            risk_function_id
        }
    }
`;

import React from 'react';
import styled from 'styled-components';

import { gray } from '@modules/ui/colors';

type TableCellTextProps = {
    children: React.ReactNode;
};

const Root = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;

    > img {
        display: flex;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: ${gray[40]};
    }
`;

const TableCellImage = (props: TableCellTextProps): React.ReactElement => {
    const { children } = props;

    return <Root>{children}</Root>;
};

export { TableCellImage };

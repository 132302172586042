import { gql } from '@apollo/client';
import { LearnTkoFragment } from '../fragments';

export const GetLearnTkoQuery = gql`
    query GetLearnTko {
        getLearnTko {
            ...LearnTko
        }
    }
    ${LearnTkoFragment}
`;

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';

import { GetDistinctRolesQuery } from '@modules/shared/graphql';
import type { QueryHookOptions } from '@apollo/client';
import type { GetDistinctRolesQueryType } from '@modules/types/graphql';
import { UserRolesEntityList } from '../entities';

const useUserRoles = (options?: QueryHookOptions<GetDistinctRolesQueryType>) => {
    const queryResult = useQuery<GetDistinctRolesQueryType>(GetDistinctRolesQuery, options);

    const { entities: userRoles, count } = useEntity(
        () => new UserRolesEntityList(queryResult.data?.getDistinctRoles ?? null),
        [queryResult.data],
    );

    const result = {
        userRoles,
        count,
        ...queryResult,
    };

    return result;
};

export { useUserRoles };

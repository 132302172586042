import { EntityList } from '@lib/entity';

import { SubscriptionTypeEntity, SubscriptionsEntity } from './subscriptions-type-entity';

class SubscriptionsTypeEntityList extends EntityList<SubscriptionTypeEntity> {
    constructor(items: SubscriptionsEntity[] | null, count?: number | null) {
        super(SubscriptionTypeEntity, items, count);
    }
}

export { SubscriptionsTypeEntityList };

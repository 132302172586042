class UsersListRequest {
    projects?: string | string[];
    roles?: string | string[];

    constructor(values: Record<string, any>) {
        if (values?.role) {
            this.roles = values.role;
        }

        if (values?.project) {
            this.projects = values.project.map((project: string) => Number(project));
        }
    }
}

export { UsersListRequest };

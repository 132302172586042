import { EntityList } from '@lib/entity';
import type { Entity } from './learn-tko-caption-entity';
import { LearnTkoCaptionEntity } from './learn-tko-caption-entity';

class LearnTkoCaptionEntityList extends EntityList<LearnTkoCaptionEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(LearnTkoCaptionEntity, items, count);
    }
}

export { LearnTkoCaptionEntityList };

import { gql } from '@apollo/client';
import { LearnTkoMenuFragment } from '../fragments';

export const GetLearnTkoMenuQuery = gql`
    query GetLearnTkoMenuQuery {
        getLearnTkoMenu {
            ...LearnTkoMenu
        }
    }
    ${LearnTkoMenuFragment}
`;

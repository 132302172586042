import { IndexPage } from '@pages/index';
import { LoginPage } from '@pages/auth/login';
import { SetNewPasswordPage } from '@pages/auth/set-new-password';
import { ForgotPasswordPage } from '@pages/auth/forgot-password';
import { ProjectsPage } from '@pages/projects';
import { WelcomePage } from '@pages/projects/welcome';
import { ProjectAssessmentPage } from '@pages/projects/assessment';
import { ProjectAssessmentReportPage } from '@pages/projects/assessment/report';
import { ProjectRoadmapPage } from '@pages/projects/roadmap';
import { ProjectRoadmapReportPage } from '@pages/projects/roadmap/report';
import { ProjectImplementationPage } from '@pages/projects/implementation';
import { ProjectImplementationReportPage } from '@pages/projects/implementation/report';
import { RegistryPage } from '@pages/registry';
import { UsersPage } from '@pages/users';
import { UsersCreatePage } from '@pages/users/create';
import { UsersEditPage } from '@pages/users/edit';
import { CompaniesPage } from '@pages/companies';
import { CompanyCreatePage } from '@pages/companies/create';
import { CompanyEditPage } from '@pages/companies/edit';
import { ComponentsPage } from '@pages/components';
import { ComponentEditPage } from '@pages/components/edit';
import { ErrorPage } from '@pages/error';

import type { RedirectProps } from '@reach/router';
import { CreateLearnTko } from '@pages/learn-tko/create-learn-tko';
import { EditLearnTko } from '@pages/learn-tko/edit-learn-tko';
import { ViewLearnTko } from '@pages/learn-tko/view-learn-tko';
import { ViewLearnMenuCard } from '@pages/learn-tko/view-learn-menu-cards';
import { EditLearnMenuCard } from '@pages/learn-tko/edit-learn-menu-card';
import { LearnTKO } from '@pages/learn-tko';
import { Contact } from '@pages/contact';
import { LearnTkoDetails } from '@pages/learn-tko/learn-tko-details';
import { AskTKO } from '@pages/ask-tko/ask-tko';
import { ViewVideoLink } from '@pages/learn-tko/view-video-link';
import { OrganizationProfileEditPage } from '@pages/organization';
import { AllProjectCount } from '@pages/all-project-count/all-project-count';
import { ItemDetailsPage } from '@pages/item-details';

export enum RouteMenuLocationEnum {
    header = 'header',
    sidebar = 'sidebar',
    assessment = 'assessment',
    impementation = 'impementation',
}

export type Route = {
    name: string;
    title: string;
    path: string;
    private: boolean;
    Component: React.FunctionComponent<any>;
    roles?: string[];
    forAdmin?: boolean;
    OP?: boolean;
    CO?: boolean;
    forSuperAdmin?: boolean;
    default?: boolean;
    menuLocations?: RouteMenuLocationEnum[];
};

export type RedirectRoute = RedirectProps<{}> & {
    name: string;
};

export type ConfigRoute = Record<string, Route>;

export type CreateRoutePayload = Record<string, any>;

const routes: ConfigRoute = {
    index: {
        name: 'index',
        title: 'Index',
        path: '/',
        private: true,
        Component: IndexPage,
    },
    login: {
        name: 'auth.login',
        title: 'Login',
        path: '/auth/login',
        private: false,
        Component: LoginPage,
    },
    setPassword: {
        name: 'auth.setPassword',
        title: 'Set password',
        path: '/auth/set-password',
        private: false,
        Component: SetNewPasswordPage,
    },
    forgotPassword: {
        name: 'auth.forgotPassword',
        title: 'Forgot password',
        path: '/auth/forgot-password',
        private: false,
        Component: ForgotPasswordPage,
    },

    projects: {
        name: 'projects.index',
        title: 'Projects',
        path: '/projects',
        private: true,
        Component: ProjectsPage,
        menuLocations: [RouteMenuLocationEnum.header],
    },
    welcome: {
        name: 'welcome.index',
        title: 'Welcome Page',
        path: '/welcome-page',
        private: true,
        Component: WelcomePage,
    },
    askTko: {
        name: 'asktko.index',
        title: 'Ask TKO',
        path: '/resources/ask-tko',
        private: true,
        Component: AskTKO,
    },
    learnTko: {
        name: 'learnTko.index',
        title: 'Learn TKO',
        path: '/resources/learn-tko',
        private: true,
        Component: LearnTKO,
    },
    learnTkoDetails: {
        name: 'learnTkoDetails.index',
        title: 'Learn TKO Details',
        path: '/resources/learn-tko/:query',
        private: true,
        Component: LearnTkoDetails,
    },
    createLearnTko: {
        name: 'CreatelearnTko.create',
        title: 'Create Learn TKO',
        path: '/resources/create-learn-tko',
        private: true,
        Component: CreateLearnTko,
    },
    editLearnTko: {
        name: 'EditlearnTko.edit',
        title: 'Edit Learn TKO',
        path: '/resources/edit-learn-tko/:id',
        private: true,
        Component: EditLearnTko,
    },
    viewLearnTko: {
        name: 'ViewlearnTko.index',
        title: 'View Learn TKO',
        path: '/resources/view-learn-tko',
        private: true,
        Component: ViewLearnTko,
    },
    viewVideoLink: {
        name: 'ViewlearnTko.index',
        title: 'View All Video Link',
        path: '/resources/view-video-link',
        private: true,
        Component: ViewVideoLink,
    },
    viewLearnMenuCards: {
        name: 'ViewLearnMenuCards.index',
        title: 'View Learn Menu Cards',
        path: '/resources/view-learn-menu-cards',
        private: true,
        Component: ViewLearnMenuCard,
    },
    editLearnMenuCard: {
        name: 'EditLearnMenuCard.edit',
        title: 'Edit Learn Menu Card',
        path: '/resources/edit-learn-menu-card/:id',
        private: true,
        Component: EditLearnMenuCard,
    },
    organizationProfileEditPage: {
        name: 'OrganizationProfileEditPage.edit',
        title: 'Edit Organization Profile',
        path: '/organization-profile-edit-page/:id',
        private: true,
        Component: OrganizationProfileEditPage,
    },
    projectAssessment: {
        name: 'projects.assessment',
        title: 'Assessment',
        path: '/projects/:id/assessment',
        private: true,
        Component: ProjectAssessmentPage,
        menuLocations: [RouteMenuLocationEnum.assessment],
    },
    projectAssessmentReport: {
        name: 'projects.assessment.report',
        title: 'Assessment Report',
        path: '/projects/:id/assessment/report',
        private: true,
        Component: ProjectAssessmentReportPage,
    },
    projectRoadmap: {
        name: 'projects.roadmap',
        title: 'Road Map',
        path: '/projects/:id/roadmap',
        private: true,
        Component: ProjectRoadmapPage,
        menuLocations: [RouteMenuLocationEnum.assessment],
    },
    projectRoadmapReport: {
        name: 'projects.roadmap.report',
        title: 'Road Map Report',
        path: '/projects/:id/roadmap/report',
        private: true,
        Component: ProjectRoadmapReportPage,
    },
    projectImplementation: {
        name: 'projects.implementation',
        title: 'Implementation',
        path: '/projects/:id/implementation',
        private: true,
        Component: ProjectImplementationPage,
        menuLocations: [RouteMenuLocationEnum.assessment],
    },
    projectImplementationReport: {
        name: 'projects.implementation.report',
        title: 'Implementation Report',
        path: '/projects/:id/implementation/report',
        private: true,
        Component: ProjectImplementationReportPage,
    },
    registry: {
        name: 'registry.index',
        title: 'Risk Registry',
        path: '/registry',
        OP: false,
        CO: false,
        private: true,
        Component: RegistryPage,
        menuLocations: [RouteMenuLocationEnum.header],
    },
    users: {
        name: 'users.index',
        title: 'Users',
        path: '/users',
        private: true,
        menuLocations: [RouteMenuLocationEnum.header],
        Component: UsersPage,
    },
    usersCreate: {
        name: 'users.create',
        title: 'Create New User',
        path: '/users/create',
        private: true,
        forAdmin: true,
        Component: UsersCreatePage,
    },
    usersEdit: {
        name: 'users.edit',
        title: 'Edit user',
        path: '/users/:id/edit',
        private: true,
        forAdmin: true,
        Component: UsersEditPage,
    },

    companies: {
        name: 'companies.index',
        title: 'Companies',
        path: '/companies',
        private: true,
        forAdmin: false,
        forSuperAdmin: true,
        menuLocations: [RouteMenuLocationEnum.header],
        Component: CompaniesPage,
    },
    allProjectCount: {
        name: 'projects.allprojects',
        title: 'All Project',
        path: '/all-project',
        private: true,
        forAdmin: false,
        forSuperAdmin: true,
        menuLocations: [RouteMenuLocationEnum.header],
        Component: AllProjectCount,
    },
    companyCreate: {
        name: 'companies.create',
        title: 'Create new company',
        path: '/companies/create',
        private: true,
        forAdmin: false,
        forSuperAdmin: true,
        Component: CompanyCreatePage,
    },
    companyEdit: {
        name: 'companies.edit',
        title: 'Edit company',
        path: '/companies/:id/edit',
        private: true,
        forAdmin: false,
        forSuperAdmin: true,
        Component: CompanyEditPage,
    },

    components: {
        name: 'components.index',
        title: 'Components',
        path: '/components',
        private: true,
        forAdmin: true,
        menuLocations: [RouteMenuLocationEnum.header],
        Component: ComponentsPage,
    },

    itemDetails: {
        name: 'itemDetails.index',
        title: 'ItemDetails',
        path: '/itemDetails',
        private: true,
        forAdmin: true,
        // menuLocations: [RouteMenuLocationEnum.header],
        Component: ItemDetailsPage,
    },
    
    resources: {
        name: 'resources.index',
        title: 'Resources',
        path: '/resources',
        private: true,
        forAdmin: false,
        menuLocations: [RouteMenuLocationEnum.header],
        Component: WelcomePage,
    },
    contact: {
        name: 'contact.index',
        title: 'Contact',
        path: '/contact',
        private: true,
        forAdmin: false,
        menuLocations: [RouteMenuLocationEnum.header],
        Component: Contact,
    },
    componentEdit: {
        name: 'components.edit',
        title: 'Component Edit',
        path: '/components/:id/edit',
        private: true,
        forAdmin: true,
        Component: ComponentEditPage,
    },

    error: {
        default: true,
        name: 'error',
        title: 'Error Page',
        path: '/error',
        private: false,
        Component: ErrorPage,
    },
};

const redirects: RedirectRoute[] = [];

export { routes, redirects };

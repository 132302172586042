import { EntityList } from '@lib/entity';
import { ProjectListNameEntity } from './project-list-name-entity';

import type { Entity } from './project-list-name-entity';

class ProjectListNameEntityList extends EntityList<ProjectListNameEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(ProjectListNameEntity, items, count);
    }
}

export { ProjectListNameEntityList };

import { gql } from '@apollo/client';

import { RecommendationSingleFragment } from '../fragments';

export const GetRecommendationsQuery = gql`
    query GetRecommendations($gapId: Int, $search: String, $companyId: Int) {
        listRecommendations(gap_id: $gapId, filter: $search, company_id: $companyId) {
            ...RecommendationSingle
        }
    }
    ${RecommendationSingleFragment}
`;

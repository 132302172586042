import React from 'react';
import { useParams, useMatch, Redirect } from '@reach/router';

import { routes } from '@config/routes';
import { Loader } from '@modules/layout/moleculas';
import { useCurrentUser } from '@modules/auth/hooks';
import { useProjectRoles } from '@modules/projects/hooks';

import type { Route } from '@config/routes';

const PrivateRoute = (props: Route): React.ReactElement | null => {
    const { forAdmin, Component, ...otherProps } = props;

    const match = useMatch('/projects/:id/*');
    const isMatched = match !== null;

    const params = useParams();
    const projectId = params.id ? +params.id : null;

    const { currentUser, loading: currentUserLoading } = useCurrentUser();
    const { projectRolesEntityList, loading: projectRolesEntityListLoading } = useProjectRoles({
        skip: !isMatched,
        variables: { projectId: projectId as number },
    });

    const hasStRole = projectRolesEntityList.hasStakeholder();

    if (currentUserLoading || projectRolesEntityListLoading) {
        return <Loader />;
    }

    if (!currentUser.exists()) {
        return <Redirect noThrow to={routes.login.path} />;
    }

    if (forAdmin && !(currentUser.hasAdminPermission() || hasStRole)) {
        return <Redirect noThrow to={routes.projects.path} />;
    }

    return <Component {...otherProps} />;
};

export { PrivateRoute };

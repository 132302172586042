import { gql } from '@apollo/client';

import { RecommendationSingleFragment } from '../fragments';

export const UpdateRecommendationMutation = gql`
    mutation UpdateRecommendation($recommendationId: Int!, $gapId: Int!, $recommendation: String!) {
        updateRecommendation(
            recommendation_id: $recommendationId
            gap_id: $gapId
            recommendation: $recommendation
        ) {
            ...RecommendationSingle
        }
    }
    ${RecommendationSingleFragment}
`;

import { EntityList } from '@lib/entity';
import { AllMenuCardsEntity } from './all-menu-cards-entity';
import type { Entity } from './all-menu-cards-entity';

class AllMenuCardsEntityList extends EntityList<AllMenuCardsEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(AllMenuCardsEntity, items, count);
    }
}
export { AllMenuCardsEntityList };

import { createCustomError } from '@lib/errors';
import type { UpdateCompanyMutationVariables } from '@modules/types/graphql';

export type CompnayUpdateFormValues = {
    id: number;
    userId: number;
    name: string;
    phone: string;
    address: string;
    email: string;
    contactName: string;
    packageType: string;
    subscriptionType: string;
    schemaName: string;
    isAskTko: boolean;
    isLearnTko: boolean;
};

class CompanyUpdateRequest {
    id: UpdateCompanyMutationVariables['id'];
    userId: UpdateCompanyMutationVariables['userId'];
    name: UpdateCompanyMutationVariables['name'];
    phone: UpdateCompanyMutationVariables['phone'];
    address: UpdateCompanyMutationVariables['address'];
    email: UpdateCompanyMutationVariables['email'];
    contactName: UpdateCompanyMutationVariables['contactName'];
    packageType: UpdateCompanyMutationVariables['packageType'];
    subscriptionType: UpdateCompanyMutationVariables['subscriptionType'];
    schemaName: UpdateCompanyMutationVariables['schemaName'];
    isAskTko: UpdateCompanyMutationVariables['isAskTko'];
    isLearnTko: UpdateCompanyMutationVariables['isLearnTko'];

    constructor(values: CompnayUpdateFormValues) {
        if (!values.userId) {
            throw createCustomError('incorrect user id');
        }
        this.id = values.id;
        this.userId = values.userId;
        this.name = values.name;
        this.phone = values.phone;
        this.address = values.address;
        this.email = values.email;
        this.contactName = values.contactName;
        this.packageType = values.packageType;
        this.subscriptionType = values.subscriptionType;
        this.schemaName = values.schemaName;
        this.isAskTko = values.isAskTko;
        this.isLearnTko = values.isLearnTko;
    }
}

export { CompanyUpdateRequest };

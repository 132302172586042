import { gql } from '@apollo/client';

import { AddAssessmentCategoriesFragment } from '../fragments';

export const AddAssessmentCategoriesMutation = gql`
    mutation addAssessmentCategories($name: String!, $isRRType: Boolean!) {
        addAssessmentCategories(name: $name, is_rr_type: $isRRType) {
            ...AssessmentCategories
        }
    }
    ${AddAssessmentCategoriesFragment}
`;

import { gql } from '@apollo/client';

import { GapSingleFragment } from '@modules/custom-components/graphql';
import { RoadmapRecommendationRefinementFragment } from '@modules/roadmap/graphql';

export const RecommendationImplementationFragment = gql`
    fragment RecommendationImplementation on RecommendationImplementation {
        roadmap_recommendation_id
        recommendation_id
        component_id
        assessment_id
        gap_id
        status
        started_at
        completed_at
        completed_in_days
        component {
            id
            name
        }
        gap {
            ...GapSingle
        }
        recommendation_refinement {
            ...RoadmapRecommendationRefinement
        }
        operators {
            id
            first_name
            last_name
        }
    }
    ${GapSingleFragment}
    ${RoadmapRecommendationRefinementFragment}
`;

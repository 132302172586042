import type { UpdateImplementaionTaskMutationVariables } from '@modules/types/graphql';
import { ImpTaskCreateFormValues, ImpTaskCreateRequest } from './imp-task-create-request';

export type ImpTaskUpdateFormValues = ImpTaskCreateFormValues & {
    taskId: number;
    tFlag: boolean;
};

class ImpTaskUpdateRequest extends ImpTaskCreateRequest {
    taskId: UpdateImplementaionTaskMutationVariables['taskId'];
    tFlag: UpdateImplementaionTaskMutationVariables['tFlag'];

    constructor(values: ImpTaskUpdateFormValues) {
        super(values);

        this.taskId = values.taskId;
        this.tFlag = values.tFlag;
    }
}

export { ImpTaskUpdateRequest };

import { gql } from '@apollo/client';

import { CompanyDetailsFragment } from '../fragments';

export const UpdateCompanyMutation = gql`
    mutation UpdateCompany(
        $id: Int!
        $userId: Int!
        $name: String!
        $address: String!
        $phone: String!
        $email: String!
        $contactName: String!
        $packageType: String!
        $subscriptionType: String!
        $schemaName: String
        $isAskTko: Boolean
        $isLearnTko: Boolean
    ) {
        updateCompany(
            id: $id
            name: $name
            user_id: $userId
            address: $address
            email: $email
            phone: $phone
            contact_name: $contactName
            package_type: $packageType
            subscription_type: $subscriptionType
            schema_name: $schemaName
            is_ask_tko: $isAskTko
            is_learn_tko: $isLearnTko
        ) {
            ...CompanyDetails
        }
    }
    ${CompanyDetailsFragment}
`;

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import type { QueryHookOptions } from '@apollo/client';
import type {
    GetRecommendationImpSubTasksQueryType,
    GetRecommendationImpSubTasksQueryVariables,
} from '@modules/types/graphql';
import { SubTaskEntityList } from '../entities';
import { GetRecommendationImpSubTasksQuery } from '../graphql/queries';

const useRecommendationImpSubTasks = (
    options?: QueryHookOptions<
        GetRecommendationImpSubTasksQueryType,
        GetRecommendationImpSubTasksQueryVariables
    >,
) => {
    const queryResult = useQuery<
        GetRecommendationImpSubTasksQueryType,
        GetRecommendationImpSubTasksQueryVariables
    >(GetRecommendationImpSubTasksQuery, options);

    const { entities: RecImpSubTask, count } = useEntity(
        () => new SubTaskEntityList(queryResult.data?.getRecommendationImpSubTasks ?? null),
        [queryResult.data],
    );

    const result = {
        RecImpSubTask,
        count,
        ...queryResult,
    };

    return result;
};

export { useRecommendationImpSubTasks };

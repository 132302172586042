import { gql } from '@apollo/client';

import { OrgFormFragment } from '../fragments';

export const UpdateOrgProfileMutation = gql`
    mutation UpdateOrgProfile(
        $userId: Int!
        $numberOfEmployees: Int
        $sector: String
        $organizationType: String
        $orgLocation: String
        $workforce: String
    ) {
        updateOrganizationProfile(
            user_id: $userId
            number_of_employees: $numberOfEmployees
            sector: $sector
            organization_type: $organizationType
            organization_location: $orgLocation
            workforce: $workforce
        ) {
            ...OrgSingle
        }
    }
    ${OrgFormFragment}
`;

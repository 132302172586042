import React from 'react';
import styled from 'styled-components';

import { PageStyled } from '@modules/layout/styled';
import { AssessmentFlowMenu } from '@modules/projects/moleculas';

import type { ProjectStatusEnum } from '@modules/projects/constants';
import { Link } from '@reach/router';

type ProjectHeaderProps = {
    name: string;
    description: string;
    projectStatus: ProjectStatusEnum;
    assessmentTypeId?: number;
    isRiskRegistry?: boolean;
};

const PageHeader = styled(PageStyled.Header)`
    align-items: center;
    padding-bottom: 24px;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const HeaderSubtitle = styled(PageStyled.HeaderSubtitle)`
    margin-left: 0px;
`;

const ProjectHeader = (props: ProjectHeaderProps): React.ReactElement => {
    const { projectStatus, name, description, assessmentTypeId, isRiskRegistry } = props;

    return (
        <PageHeader>
            <div>
                {isRiskRegistry ? 
                    <Link to='/registry'>
                        <h1>{name}</h1>
                    </Link>
                    :
                    <Link to='/projects'>
                        <h1>{name}</h1>
                    </Link>
                }
                <HeaderSubtitle>
                    <p>{description}</p>
                </HeaderSubtitle>
            </div>

            <AssessmentFlowMenu
                status={projectStatus}
                assessmentTypeId={assessmentTypeId}
                isRiskRegistry={isRiskRegistry}
            />
        </PageHeader>
    );
};

export { ProjectHeader };

import React from 'react';
import styled from 'styled-components';

import { white, blue } from '@modules/ui/colors';

type CounterHintProps = {
    label: string;
    count: number;
};

const Root = styled.div`
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
`;

const Counter = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    color: ${white[100]};
    font-size: 1.6rem;
    background-color: ${blue[20]};
    border-radius: 50%;
`;

const Label = styled.div`
    margin-left: 8px;
    font-size: 1.6rem;
`;

const CounterHint = (props: CounterHintProps) => {
    const { label, count } = props;

    // const displayCount = count > 9 ? `${count}+` : count;

    return (
        <Root>
            <Counter>{count}</Counter>
            <Label>{label}</Label>
        </Root>
    );
};

export { CounterHint };

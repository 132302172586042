import React from 'react';
import styled from 'styled-components';

import { Chip } from '@modules/layout/atoms';

import type { ChipProps } from '@material-ui/core';

type ChipListProps = {
    items: (string | number | null)[];
    color?: ChipProps['color'];
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > * {
        margin: 2px 4px 2px 0;
    }
`;

const ChipList = (props: ChipListProps): React.ReactElement => {
    const { items, color } = props;

    if (items.length === 0) {
        return <Root>-</Root>;
    }

    return (
        <Root>
            {items.map(
                (item, key) => item && <Chip key={`${item}-${key}`} label={item} color={color} />,
            )}
        </Root>
    );
};

export { ChipList };

import { EntityList } from '@lib/entity';

import type { Entity } from './user-roles-entity';
import { UserRolesEntity } from './user-roles-entity';

class UserRolesEntityList extends EntityList<UserRolesEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(UserRolesEntity, items, count);
    }
}

export { UserRolesEntityList };

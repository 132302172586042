import React, {Dispatch, SetStateAction, useEffect} from 'react';

export function usePropState<T>(initialState: T): [T, Dispatch<SetStateAction<T>>] {
    const [data, setData] = React.useState(initialState);
    useEffect(() => {
        if (data !== initialState) {
            setData(initialState);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialState]);

    return [data, setData];
}

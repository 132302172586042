import { gql } from '@apollo/client';

import { RoadmapRecommendationRefinementFragment } from '../fragments';

export const AcceptRecommendationMutation = gql`
    mutation AcceptRecommendation(
        $roadmapRecommendationId: Int!
        $notes: String
        $constraints: String
        $dependencies: String
    ) {
        acceptRecommendation(
            roadmap_recommendation_id: $roadmapRecommendationId
            notes: $notes
            constraints: $constraints
            dependencies: $dependencies
        ) {
            ...RoadmapRecommendationRefinement
        }
    }
    ${RoadmapRecommendationRefinementFragment}
`;

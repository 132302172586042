import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type {RoadmapRecommendationRefinement, TextCommendLog} from '@modules/types/graphql';
import {Maybe, User} from '@modules/types/graphql';

export type Entity = Partial<RoadmapRecommendationRefinement> | null;

@DecribeEntity('RoadmapRecommendationRefinementEntity')
class RoadmapRecommendationRefinementEntity extends BaseEntity {
    id: number;
    status: string;
    notes: string;
    constraints: string;
    dependencies: string;
    refinedRecommendation: string;
    notesLog: (TextCommendLog | null)[];
    constraintsLog: (TextCommendLog | null)[];
    dependenciesLog: (TextCommendLog | null)[];
    refined_at: string;
    refined_by?: Maybe<User>;

    constructor(roadmapRecommendationRefinement: Entity) {
        super(roadmapRecommendationRefinement);

        this.id = roadmapRecommendationRefinement?.id ?? -1;
        this.status = roadmapRecommendationRefinement?.status ?? '';
        this.notes = roadmapRecommendationRefinement?.notes ?? '';
        this.constraints = roadmapRecommendationRefinement?.constraints ?? '';
        this.dependencies = roadmapRecommendationRefinement?.dependencies ?? '';
        this.refinedRecommendation = roadmapRecommendationRefinement?.refined_recommendation ?? '';
        this.notesLog = roadmapRecommendationRefinement?.notesLog ?? [];
        this.constraintsLog = roadmapRecommendationRefinement?.constraintsLog ?? [];
        this.dependenciesLog = roadmapRecommendationRefinement?.dependenciesLog ?? [];
        this.refined_by = roadmapRecommendationRefinement?.refined_by;
        this.refined_at = roadmapRecommendationRefinement?.refined_at ?? '';
    }

    isAccepted() {
        const result = this.status.toLowerCase() === 'accepted';

        return result;
    }

    isAcknowledged() {
        const result = this.status.toLowerCase() === 'acknowledged';

        return result;
    }

    isModified() {
        const result = this.status.toLowerCase() === 'modified';

        return result;
    }

    isRejected() {
        const result = this.status.toLowerCase() === 'rejected';

        return result;
    }
}

export { RoadmapRecommendationRefinementEntity };

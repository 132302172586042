import { gql } from '@apollo/client';
import { PackagesFragment } from '../fragments';

export const GetPackagesQuery = gql`
    query listPackages {
        listPackages {
            ...Packages
        }
    }
    ${PackagesFragment}
`;

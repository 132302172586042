import { createCustomError } from '@lib/errors';

import type { UserEntity } from '@modules/users/entities';
import type { CompanyEntity } from '@modules/company/entities';
import type { CreateProjectMutationVariables } from '@modules/types/graphql';
import { AssessmentTypeEntity } from '@modules/shared/entities';
import { TkoScopeEntity } from '@modules/company/entities/tko-scope-entity';

export type ProjectCreateFormValues = {
    name: string;
    description: string;
    company: CompanyEntity | null;
    tkoscope: TkoScopeEntity | null;
    stakeholder: UserEntity | null;
    assessmentCategoriesId: AssessmentTypeEntity | null;
    assessmentTypeId: AssessmentTypeEntity | null;
};

class ProjectCreateRequest {
    name: CreateProjectMutationVariables['name'];
    description: CreateProjectMutationVariables['description'];
    companyId: CreateProjectMutationVariables['companyId'];
    tkoscopeId: CreateProjectMutationVariables['tkoscopeId'];
    stakeholderId: CreateProjectMutationVariables['stakeholderId'];
    assessmentTypeId: CreateProjectMutationVariables['assessmentTypeId'];

    constructor(values: ProjectCreateFormValues) {
        if (!values.company) {
            throw createCustomError('incorrect company');
        }
        if (!values.stakeholder) {
            throw createCustomError('incorrect stakeholder');
        }

        this.name = values.name;
        this.description = values.description;
        this.companyId = values.company.id | (values.company! as any);
        this.tkoscopeId = values.tkoscope === null ? 1 : values.tkoscope.id;
        this.stakeholderId = values.stakeholder.id;
        this.assessmentTypeId = Number(values.assessmentTypeId?.id);
    }
}

export { ProjectCreateRequest };

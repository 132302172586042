import { gql } from '@apollo/client';

import { CapabilityLevelSingleFragment } from '../fragments';

export const GetCapabilityLevelQuery = gql`
    query GetCapabilityLevel($capabilityLevelId: Int!) {
        getCapabilityLevel(capability_level_id: $capabilityLevelId) {
            ...CapabilityLevelSingle
        }
    }
    ${CapabilityLevelSingleFragment}
`;

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { CapabilityAssessmentEntity } from '@modules/assessment/entities';
import { GetCapabilityAssessmentQuery } from '@modules/assessment/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetCapabilityAssessmentQueryType,
    GetCapabilityAssessmentQueryVariables,
} from '@modules/types/graphql';

const useCapabilityAssessment = (
    options?: QueryHookOptions<
        GetCapabilityAssessmentQueryType,
        GetCapabilityAssessmentQueryVariables
    >,
) => {
    const queryResult = useQuery<
        GetCapabilityAssessmentQueryType,
        GetCapabilityAssessmentQueryVariables
    >(GetCapabilityAssessmentQuery, {
        ...options,
    });

    const capabilityAssessment = useEntity(
        () =>
            new CapabilityAssessmentEntity(
                (queryResult.data?.getCapabilityAssessment as never) ?? null,
            ),
        [queryResult.data],
    );

    const result = {
        capabilityAssessment,
        ...queryResult,
    };

    return result;
};

export { useCapabilityAssessment };

import { EntityList } from '@lib/entity';
import { PrevTypeDetailsEntity } from './perv-type-details-entity';

import type { Entity } from './perv-type-details-entity';


class PrevTypeDetailsEntityList extends EntityList<PrevTypeDetailsEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(PrevTypeDetailsEntity, items, count);
    }
}

export { PrevTypeDetailsEntityList };

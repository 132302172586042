import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { AssetCategory } from '@modules/types/graphql';

export type Entity = Partial<AssetCategory>;

@DecribeEntity('RiskGroupEntity')
class AssetCategoryEntity extends BaseEntity {
    id: number;
    name: string;
    riskFunctionId: number;
    itemId: number;

    constructor(assetCategory: Entity) {
        super(assetCategory);

        this.id = assetCategory?.id ?? -1;
        this.name = assetCategory?.name ?? '';
        this.riskFunctionId = assetCategory?.risk_function_id ?? -1;
        this.itemId = assetCategory?.item_id ?? -1;
    }
}

export { AssetCategoryEntity };

import { gql } from '@apollo/client';

import { RequirementSingleFragment } from '../fragments';

export const UpdateRequirementMutation = gql`
    mutation UpdateRequirement(
        $recommendationId: Int!
        $requirementId: Int!
        $requirement: String!
    ) {
        updateRequirement(
            recommendation_id: $recommendationId
            requirement_id: $requirementId
            requirement: $requirement
        ) {
            ...RequirementSingle
        }
    }
    ${RequirementSingleFragment}
`;

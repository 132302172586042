import { useMutation } from '@apollo/client';
import { routes } from '@config/routes';
import { createRouteUrl } from '@lib/routing';
import { Button } from '@material-ui/core';
import { useCurrentUser } from '@modules/auth/hooks';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { Loader } from '@modules/layout/moleculas';
import { TableColumn } from '@modules/layout/organisms/table';
import { TableSortLearnMenuCard } from '@modules/layout/organisms/table/table-sort-learn-menu-card';
import { PageStyled } from '@modules/layout/styled';
import { GetAllMenuCardQuery } from '@modules/learn-tko/graphql';
import { DeleteMenuCardMutation } from '@modules/learn-tko/graphql/mutations';
import { useAllMenuCard } from '@modules/learn-tko/hooks';
import { LearnTkoCreateModal } from '@modules/learn-tko/organisms';
import {
    DeleteMenuCardMutationType,
    DeleteMenuCardMutationVariables,
} from '@modules/types/graphql';
import { useNavigate } from '@reach/router';
import React, { useState } from 'react';
import styled from 'styled-components';

const Main = styled(PageStyled.Main)`
    margin-top: 64px;
`;
const ButtonCreateLink = styled(Button)`
    min-width: 205px;
    padding: 14px;
`;
const columns: TableColumn[] = [
    {
        id: 'menuTitle',
        label: 'Menu Title',
    },
    {
        id: 'description',
        label: 'Description',
    },
    {
        id: 'imageUrl',
        label: 'Image Url',
    },
    {
        id: 'actions',
        label: 'Actions',
        align: 'right',
    },
];

const ViewLearnMenuCard = (): React.ReactElement => {
    const navigate = useNavigate();
    const [menuCreateModal, setCreateMenuModal] = useState<boolean>(false);
    const [updateMenuModal, setUpdateMenuModal] = useState<boolean>(false);

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const { allMenuCards, loading: cardLoading } = useAllMenuCard();
    const { currentUser, loading: currentUserLoading } = useCurrentUser();

    const canDeleteUser = currentUser.hasSuperAdminPermission() || currentUser.hasAdminPermission();

    const [deleteMenuCard] = useMutation<
        DeleteMenuCardMutationType,
        DeleteMenuCardMutationVariables
    >(DeleteMenuCardMutation);

    const handleClickRedirect = (learnTkoId: any) => {
        navigate(createRouteUrl(routes.editLearnMenuCard, { id: learnTkoId }));
    };

    const handleDeleteMenuCard = async (menuId: number): Promise<void> => {
        try {
            const { data: deleteLearnTkoData } = await deleteMenuCard({
                variables: { menuId: menuId },
                refetchQueries: [{ query: GetAllMenuCardQuery }],
                awaitRefetchQueries: true,
            });

            if (deleteLearnTkoData?.deleteMenuCard?.id) {
                enqueueSuccess('Menu Card successfully deleted!');
            } else {
                enqueueError('An error occurred while deleting the menu card!');
            }
        } catch (e) {
            console.log(e);
            enqueueError(e);
        }
    };

    const handleOpenMenuModal = () => setCreateMenuModal(true);
    const handleCloseCreateMenuModal = () => setCreateMenuModal(false);
    const handleOpenUpdateMenuModal = () => setUpdateMenuModal(true);
    const handleCloseUpdateMenuModal = () => setUpdateMenuModal(false);

    if (cardLoading) {
        return <Loader />;
    }

    return (
        <div>
            <PageStyled.Bridge>
                <ButtonCreateLink onClick={handleOpenMenuModal}>Create Menu Card</ButtonCreateLink>
            </PageStyled.Bridge>
            <Main style={{ display: 'unset' }}>
                <TableSortLearnMenuCard
                    columns={columns}
                    openModal={handleOpenUpdateMenuModal}
                    canDeleteUser={canDeleteUser}
                    handleDelete={handleDeleteMenuCard}
                    data={allMenuCards}
                    dataLoading={cardLoading}
                    handleClickRedirectEdit={handleClickRedirect}
                />
            </Main>
            {/* open learn tko card menu modal */}
            <LearnTkoCreateModal
                refetchQuery={GetAllMenuCardQuery}
                open={menuCreateModal}
                onClose={handleCloseCreateMenuModal}
            />
            {/* open learn tko card menu modal */}
            {/* <LearnTkoUpdateModal
                refetchQuery={GetAllMenuCardQuery}
                open={updateMenuModal}
                onClose={handleCloseUpdateMenuModal}
            /> */}
        </div>
    );
};

export { ViewLearnMenuCard };

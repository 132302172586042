import React from 'react';
import styled from 'styled-components';

import { Button } from '@modules/ui/core';
import { TextField } from '@modules/ui/core';
import { InputAdornment } from '@material-ui/core';
import {
    searchIcon,
    useBackgroundStyles,
} from '@modules/projects/moleculas/project-list-filters/input-box-css';
import { CustomCategoryCreateModal } from '../component-page-modal';

type ComponentProps = {
    setComponentSearch: (e: any) => void;
    componentSearch: string | any;
    ButtonTitleName?: string;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
`;

const Actions = styled.div``;

const ButtonCreateLink = styled(Button)`
    min-width: 205px;
    margin-right: 10px;
`;

const CustomComponentsListToolbar = (props: ComponentProps): React.ReactElement => {
    const { componentSearch, setComponentSearch, ButtonTitleName } = props;
    const [openCreateModal, setOpenCreateModal] = React.useState<boolean>(false);

    const backgroundClasses = useBackgroundStyles();

    const handleOpenCreateModal = (): void => {
        setOpenCreateModal(true);
    };
    const handleCloseCreateModal = (): void => {
        setOpenCreateModal(false);
    };

    return (
        <Root>
            <Actions>
                <ButtonCreateLink onClick={handleOpenCreateModal}>
                    {ButtonTitleName}
                </ButtonCreateLink>

                <TextField
                    corner='round'
                    name='search'
                    label='Search'
                    type='text'
                    autoComplete='off'
                    value={componentSearch}
                    InputProps={{
                        classes: backgroundClasses,
                        endAdornment: <InputAdornment position='end'>{searchIcon}</InputAdornment>,
                    }}
                    onChange={e => setComponentSearch(e.target.value)}
                />

                <CustomCategoryCreateModal
                    type='create'
                    open={openCreateModal}
                    onClose={handleCloseCreateModal}
                />
            </Actions>
        </Root>
    );
};

export { CustomComponentsListToolbar };

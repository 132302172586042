import { gql } from '@apollo/client';

export const CapabilitySingleFragment = gql`
    fragment CapabilitySingle on Capability {
        id
        title
        impact
        key_factors
        security_controls
        company_id
    }
`;

import React from 'react';
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';

import { Dialog } from '@modules/ui/core';

type DialogLearnEditProps = {
    onUpdate: (learnTkoId: any) => void;
    learnTkoId: number;
};

const StyledDialog = styled(Dialog)`
    min-height: initial;
`;

const DialogLearnEdit = (props: DialogLearnEditProps): React.ReactElement => {
    const { onUpdate, learnTkoId } = props;

    return (
        <>
            <StyledDialog
                title='Are you sure you want to Update it?'
                acceptText='Update'
                // onAccept={}
            >
                {dialogProps => {
                    const { handleOpen } = dialogProps;

                    return <MenuItem onClick={() => onUpdate(learnTkoId)}>Edit</MenuItem>;
                }}
            </StyledDialog>
        </>
    );
};

export { DialogLearnEdit };

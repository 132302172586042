import React, { useState, useMemo } from 'react';
import { Chip, Typography, Paper } from '@material-ui/core';
import { getRiskLevelLabelByScore } from '@modules/shared/constants/risk-level';

function ActivityButton({ onClick, activeAsset, activityType }: any) {
    const lastActivityLevel = useMemo(() => {
        if (!activeAsset || !activityType) {
            return '';
        }

        return getRiskLevelLabelByScore(
            activeAsset[`${activityType}Activities`][
                activeAsset[`${activityType}Activities`].length - 1
            ]?.score,
            activityType,
        );
    }, [activeAsset, activityType]);

    return (
        <Paper
            className='is-paper-link'
            variant='outlined'
            style={{ marginTop: 10, cursor: 'pointer' }}
            onClick={onClick}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    minHeight: 50,
                    padding: '0 10px',
                }}
            >
                <Typography variant='h5'>
                    {activityType === 'impact' ? 'Value' : 'Activity'}
                </Typography>
                <Chip
                    label={lastActivityLevel}
                    style={{ marginTop: 0, marginBottom: 0 }}
                    className='is-chip is-chip--small'
                />
            </div>
        </Paper>
    );
}

export { ActivityButton };

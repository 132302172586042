import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { ProjectEntity } from '@modules/projects/entities';
import { GetAssignedProjectQuery } from '@modules/projects/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetAssignedProjectQueryType,
    GetAssignedProjectQueryVariables,
} from '@modules/types/graphql';

const useAssignedProject = (
    options?: QueryHookOptions<GetAssignedProjectQueryType, GetAssignedProjectQueryVariables>,
) => {
    const queryResult = useQuery<GetAssignedProjectQueryType, GetAssignedProjectQueryVariables>(
        GetAssignedProjectQuery,
        options,
    );

    const project = useEntity(
        () => new ProjectEntity(queryResult.data?.getAssignedProject ?? null),
        [queryResult.data],
    );

    const result = {
        project,
        ...queryResult,
    };

    return result;
};

export { useAssignedProject };

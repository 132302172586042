import { gql } from '@apollo/client';

export const GetUsersQuery = gql`
    query GetUsers($filter: String, $projects: [Int], $roles: [String]) {
        listUsers(filter: $filter, projects: $projects, roles: $roles) {
            id
            first_name
            last_name
        }
    }
`;

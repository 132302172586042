import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { ItemClass } from '@modules/types/graphql';

export type Entity = Partial<ItemClass>;

@DecribeEntity('ItemClassEntity')
class ItemClassEntity extends BaseEntity {
    id: number;
    name: string;

    constructor(itemClass: Entity) {
        super(itemClass);

        this.id = itemClass?.id ?? -1;
        this.name = itemClass?.name ?? '';
    }
}

export { ItemClassEntity };

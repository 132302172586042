import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { ComponentEntityList } from '@modules/components/entities';
import { GetComponentsQuery } from '@modules/components/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetComponentsQueryType, GetComponentsQueryVariables } from '@modules/types/graphql';

const useComponents = (
    options?: QueryHookOptions<GetComponentsQueryType, GetComponentsQueryVariables>,
) => {
    const queryResult = useQuery<GetComponentsQueryType, GetComponentsQueryVariables>(
        GetComponentsQuery,
        options,
    );

    const { entities: components, count } = useEntity(
        () => new ComponentEntityList(queryResult.data?.listComponents ?? null),
        [queryResult.data],
    );

    const result = {
        components,
        count,
        ...queryResult,
    };

    return result;
};

export { useComponents };

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { ProjectEntityList } from '@modules/projects/entities';
import { GetAssignedProjectsQuery } from '@modules/projects/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetAssignedProjectsQueryType,
    GetAssignedProjectsQueryVariables,
} from '@modules/types/graphql';

const useAssignedProjects = (
    options?: QueryHookOptions<GetAssignedProjectsQueryType, GetAssignedProjectsQueryVariables>,
) => {
    const queryResult = useQuery<GetAssignedProjectsQueryType, GetAssignedProjectsQueryVariables>(
        GetAssignedProjectsQuery,
        options,
    );

    const { entities: projects, count } = useEntity(
        () => new ProjectEntityList(queryResult.data?.listAssignedProjects ?? null),
        [queryResult.data],
    );

    const result = {
        projects,
        count,
        ...queryResult,
    };

    return result;
};

export { useAssignedProjects };

import { gql } from '@apollo/client';

import { TaskSingleFragment } from '../fragments';

export const UpdateTaskMutation = gql`
    mutation UpdateTask($taskId: Int!, $task: String!, $resources: String, $duration: String) {
        updateTask(task_id: $taskId, task: $task, resources: $resources, duration: $duration) {
            ...TaskSingle
        }
    }
    ${TaskSingleFragment}
`;

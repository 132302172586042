import { useQuery } from '@apollo/client';
import type { QueryHookOptions } from '@apollo/client';
import { GetListItemClassQuery } from '../graphql';
import { useEntity } from '@lib/entity';
import { ItemClassEntityList } from '../entities';

const useItemClass = (options?: QueryHookOptions) => {
    const queryResult = useQuery(GetListItemClassQuery, options);

    const { entities: itemClass, count } = useEntity(
        () => new ItemClassEntityList(queryResult.data?.listItemClass ?? null),
        [queryResult.data],
    );

    const result = {
        itemClass,
        count,
        ...queryResult,
    };

    return result;
};

export { useItemClass };
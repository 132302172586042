import {
    URL,
    AWS_REGION,
    AWS_USER_POOL_ID,
    AWS_USER_POOL_WEB_CLIENT_ID,
    SECURE,
} from '@config/environment';

const awsConfig = {
    Auth: {
        region: AWS_REGION,
        userPoolId: AWS_USER_POOL_ID,
        userPoolWebClientId: AWS_USER_POOL_WEB_CLIENT_ID,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        cookieStorage: {
            domain: URL,
            path: '/',
            expires: 365,
            sameSite: 'strict',
            secure: SECURE,
        },
    },
};

export { awsConfig };

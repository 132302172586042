import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { Capability } from '@modules/types/graphql';

export type Entity = Partial<Capability> | null;

@DecribeEntity('CapabilityEntity')
class CapabilityEntity extends BaseEntity {
    id: number;
    title: string;
    impact: string;
    keyFactors: string;
    securityControls: string;
    companyId: string | null;

    constructor(capability: Entity) {
        super(capability);

        this.id = capability?.id ?? -1;
        this.title = capability?.title ?? '';
        this.impact = capability?.impact ?? '';
        this.keyFactors = capability?.key_factors ?? '';
        this.securityControls = capability?.security_controls ?? '';
        this.companyId = capability?.company_id ?? null;
    }

    isPredefined() {
        const result = this.companyId === null;

        return result;
    }
}

export { CapabilityEntity };

import { EntityList } from '@lib/entity';
import type { Entity } from './learn-tko-entity';
import { LearnTkoMenuEntity } from './learn-tko-entity';

class LearnTkoMenuEntityList extends EntityList<LearnTkoMenuEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(LearnTkoMenuEntity, items, count);
    }
}

export { LearnTkoMenuEntityList };

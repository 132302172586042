import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { CapabilityLevelEntityList } from '@modules/custom-components/entities';
import { GetCapabilityLevelsQuery } from '@modules/custom-components/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetCapabilityLevelsQueryType,
    GetCapabilityLevelsQueryVariables,
} from '@modules/types/graphql';

const useCapabilityLevels = (
    options?: QueryHookOptions<GetCapabilityLevelsQueryType, GetCapabilityLevelsQueryVariables>,
) => {
    const queryResult = useQuery<GetCapabilityLevelsQueryType, GetCapabilityLevelsQueryVariables>(
        GetCapabilityLevelsQuery,
        options,
    );

    const { entities: capabilityLevels, count } = useEntity(
        () => new CapabilityLevelEntityList(queryResult.data?.listCapabilityLevels ?? null),
        [queryResult.data],
    );

    const result = {
        capabilityLevels,
        count,
        ...queryResult,
    };

    return result;
};

export { useCapabilityLevels };

import { QueryHookOptions, useQuery } from '@apollo/client';
import { GetAskAllTkoDetailsQueryType } from '@modules/types/graphql';

import { useEntity } from '@lib/entity';
import { GetAllAskTkoDetailsQuery } from '../graphql';
import { AskTkoDetailsEntityList } from '../entities';

const useAllAskTkoDetails = (option?: QueryHookOptions<GetAskAllTkoDetailsQueryType>) => {
    const queryResult = useQuery<GetAskAllTkoDetailsQueryType>(GetAllAskTkoDetailsQuery, option);

    const { entities: askTkoAllDetails, count } = useEntity(
        () => new AskTkoDetailsEntityList(queryResult.data?.getAllAskTkoDetails ?? null),
        [queryResult.data],
    );

    const result = {
        askTkoAllDetails,
        count,
        ...queryResult,
    };
    return result;
};

export { useAllAskTkoDetails };

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { CompanyEntity } from '@modules/company/entities';
import { GetCompanyQuery } from '@modules/company/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetCompanyQueryType } from '@modules/types/graphql';

const useCompany = (options?: QueryHookOptions<GetCompanyQueryType>) => {
    const queryResult = useQuery<GetCompanyQueryType>(GetCompanyQuery, {
        ...options,
    });

    const company = useEntity(() => new CompanyEntity(queryResult.data?.getCompany ?? null), [
        queryResult.data,
    ]);

    const result = {
        company,
        ...queryResult,
    };

    return result;
};

export { useCompany };

import { gql } from '@apollo/client';
import { LearnCardMenuFragment } from '../fragments';

export const GetAllMenuCardQuery = gql`
    query GetAllMenuCard {
        getAllMenuCard {
            ...MenuCards
        }
    }
    ${LearnCardMenuFragment}
`;

import React from 'react';
import styled from 'styled-components';
import { Info } from '@material-ui/icons';

import { Modal, Button } from '@modules/ui/core';
import { black, gray } from '@modules/ui/colors';

type InjectableChildrenProps = {
    open: boolean;
    handleOpen: () => void;
};

export type DialogProps = {
    title: string;
    children: (props: InjectableChildrenProps) => React.ReactNode;
    content?: React.ReactNode;
    disabled?: boolean;
    cancelText?: string;
    acceptText?: string;
    hideCancel?: boolean;
    className?: string;
    onAccept?: (event?: React.MouseEvent<{}>) => void | Promise<void>;
    onCancel?: () => void;
};

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding-top: 16px;
    line-height: 22px;
`;

const Icon = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    color: ${gray[30]};
`;

const Title = styled.div`
    width: calc(100% - 32px);
    margin-left: 8px;
    padding-top: 2px;
    color: ${black[100]};
    font-size: 1.6rem;
`;

const Body = styled.div`
    width: 100%;
    margin-top: 8px;
    padding-left: 32px;
    font-size: 1.4rem;
    line-height: 22px;
`;

const Actions = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 24px;

    > * {
        &:not(:last-of-type) {
            margin-right: 8px;
        }
    }
`;

const infoIcon = <Info style={{ fontSize: 24 }} />;

const Dialog = (props: DialogProps): React.ReactElement => {
    const {
        title,
        content,
        disabled,
        cancelText = 'Cancel',
        acceptText = 'Remove',
        hideCancel,
        onAccept,
        onCancel,
        children,
        ...otherProps
    } = props;

    const [open, setOpen] = React.useState(false);

    const handleOpen = (): void => setOpen(true);

    const handleClose = (): void => setOpen(false);

    const handleClickCancel = (): void => {
        handleClose();
        onCancel?.();
    };

    const handleClickAccept = async (event: React.MouseEvent<{}>): Promise<void> => {
        await onAccept?.(event);
        handleClose();
    };

    return (
        <>
            {children({ open, handleOpen })}

            <Modal hideCloseIcon open={open} onClose={handleClose} {...otherProps}>
                <Header>
                    <Icon>{infoIcon}</Icon>
                    <Title>{title}</Title>
                </Header>

                {content ? <Body>{content}</Body> : null}

                <Actions>
                    {hideCancel ? null : (
                        <Button variant='outlined' disabled={disabled} onClick={handleClickCancel}>
                            {cancelText}
                        </Button>
                    )}

                    <Button disabled={disabled} onClick={handleClickAccept}>
                        {acceptText}
                    </Button>
                </Actions>
            </Modal>
        </>
    );
};

export { Dialog };

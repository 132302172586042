import { gql } from '@apollo/client';

export const GapRecommendationCountFragment = gql`
    fragment GapsRecommendationsCount on GapsRecommendationsCount {
        company_id
        company_name
        ai_access
        is_ask_tko
        is_learn_tko
        projects_count
        remaining_projects
        gaps_count
        ai_gaps_count
        recommendations_count
        ai_recommendations_count
        tasks_count
        ai_tasks_count
        sub_tasks_count
        ai_sub_tasks_count
    }
`;

import { gql } from '@apollo/client';

import { ListS3DataFragment } from '../fragments';

export const GetS3DataQuery = gql`
    query listS3Data {
        listS3Data {
            ...ListData
        }
    }
    ${ListS3DataFragment}
`;

import gql from 'graphql-tag';

export const LearnTkoFragment = gql`
    fragment LearnTko on LearnTko {
        id
        menu
        title
        # description
        # video_url
        # sub_title_link
        # created_at
    }
`;

import { gql } from '@apollo/client';

export const GetListRiskFunctionQuery = gql`
    query listRiskFunction {
        listRiskFunction {
            id
            name
        }
    }
`;

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { UserListEntityList } from '@modules/users/entities';
import { GetUsersQuery } from '@modules/users/graphql';
import type { QueryHookOptions } from '@apollo/client';

const useUsers = (options?: QueryHookOptions) => {
    const queryResult = useQuery(GetUsersQuery, options);

    const { entities: users } = useEntity(
        () => new UserListEntityList(queryResult.data?.listUsers ?? null),
        [queryResult.data],
    );

    const result = {
        users,
        ...queryResult,
    };

    return result;
};

export { useUsers };

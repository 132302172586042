import { gql } from '@apollo/client';

export const GetAssessmentTypesQuery = gql`
    query GetAssessmentTypes($search: String, $onlyRrTypes: Boolean) {
        listAssessmentTypes(filter: $search, onlyRrTypes: $onlyRrTypes) {
            id
            name
            company_id
            is_rr_type
            assessment_categories_id
        }
    }
`;

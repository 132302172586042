import React from 'react';
import { ListItemActions } from '@modules/layout/moleculas';
import { DialogDelete } from '../dialog-delete';
import { DialogEdit } from '../dialog-edit';

type CategoryActionsProps = {
    id: number;
    onDelete: (id: number) => Promise<void>;
    onUpdate: (id: any) => void;
};

const CategoryActions = (props: CategoryActionsProps): React.ReactElement => {
    const { id, onDelete, onUpdate } = props;

    const handleDelete = () => onDelete(id);
    const handleUpdate = () => onUpdate(id);

    return (
        <ListItemActions>
            {actionsProps => {
                const { onClose } = actionsProps;

                const handleClick = (fn?: any) => (): void => {
                    if (typeof fn === 'function') {
                        fn();
                    }

                    onClose();
                };

                return (
                    <>
                        <DialogDelete onDelete={handleClick(handleDelete)} />
                        <DialogEdit id={id} onUpdate={handleClick(handleUpdate)} />
                    </>
                );
            }}
        </ListItemActions>
    );
};

export { CategoryActions };

import React, { useState } from 'react';
import styled from 'styled-components';
import type { ModalProps } from '@modules/ui/core';
import { Button, Modal, TextField } from '@modules/ui/core';
import * as yup from 'yup';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { Grid, MenuItem } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { gray } from '@modules/ui/colors';
import { AssignRiskUser, GetRiskAssignUserQuery } from '@modules/registry/graphql';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { useMutation } from '@apollo/client';
import { useAssetCategory, useAssetType } from '@modules/registry/hooks';
import { AssetTypeEntity } from '@modules/registry/entities';

const StyledModal = styled(Modal)`
    max-width: 800px;
    min-width: 700px;
`;

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }

    .is-select {
        width: 536px;
        background-color: ${gray[90]};

        .MuiInputBase-root > div {
            display: initial;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const AssignRiskAlignmentModal = (
    props: ModalProps & { initialValues: Record<string, any> },
): React.ReactElement => {
    const { onClose, initialValues, ...otherProps } = props;
    const { assetCategory, loading: assetCategoryLoading } = useAssetCategory();
    const { assetTypeData, loading: assetTypeLoading } = useAssetType();
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [rows, setRows] = useState([{ assetCategory: '', assetType: [], assetTypeDetails: [] }]);

    const addRow = () => {
        setRows([...rows, { assetCategory: '', assetType: [], assetTypeDetails: [] }]);
    };

    const removeRow = (index: number) => {
        setRows(rows.filter((_, i) => i !== index));
    };

    const handleAssetCategoryChange = (event: any, index: number) => {
        const assetCategoryId = event.target.value;
        const assetTypeDataValue = assetTypeData.filter(asset => asset.categoryId === assetCategoryId);

        const newRows: any = rows.map((row, i) => 
            i === index ? { ...row, assetCategory: assetCategoryId, assetTypeDetails: assetTypeDataValue, assetType: [] } : row
        );
        setRows(newRows);
    };

    const handleAssetTypeChange = (event: any, index: number) => {
        const { value } = event.target;
        const newRows = rows.map((row, i) => 
            i === index ? { ...row, assetType: value } : row
        );
        setRows(newRows);
    };

    return (
        <StyledModal title='Risk Alignment' onClose={onClose} {...otherProps}>
            <ExtendedFormik
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={yup.object({
                    riskGroup: yup.array().required('Required field'),
                })}
                initialValues={initialValues}
                onSubmit={async values => {
                    try {
                        console.log(values, 'values');
                        // const { data: assignRiskuserData } = await assignRiskUser({
                        //     variables: { userId: values.user_id, riskGroupIds: values?.riskGroup },
                        //     refetchQueries: [{ query: GetRiskAssignUserQuery }],
                        //     awaitRefetchQueries: true,
                        // });

                        if (true) {
                            enqueueSuccess('Risk Group Assign successfully!');
                            onClose();
                        } else {
                            enqueueError('An error occurred while Assigning Risk Group!');
                            onClose();
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }}
            >
                {formikProps => {
                    const { values, errors, handleChange, handleBlur, isSubmitting } = formikProps;

                    return (
                        <StyledForm>
                            {rows.map((row, index) => (
                                <Grid container spacing={2} key={index}>
                                    <Grid item xs={4}>
                                        <TextField
                                            select
                                            fullWidth
                                            name={`assetCategory-${index}`}
                                            label='Asset category'
                                            error={!!errors.assetCategory}
                                            helperText={errors.assetCategory}
                                            value={row.assetCategory}
                                            disabled={assetCategoryLoading}
                                            onChange={(event) => handleAssetCategoryChange(event, index)}
                                        >
                                            {assetCategory &&
                                                assetCategory?.map((item: { id: number; name: string }) => (
                                                    <MenuItem key={item?.id} value={item?.id}>
                                                        {item?.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            select
                                            fullWidth
                                            name={`assetType-${index}`}
                                            label='Asset type'
                                            error={!!errors.assetType}
                                            helperText={errors.assetType}
                                            value={row.assetType}
                                            disabled={!row.assetTypeDetails.length}
                                            SelectProps={{
                                                multiple: true,
                                                renderValue: (selected: any) => {
                                                    const selectedTypes = row.assetTypeDetails.filter((asset: any) => selected.includes(asset?.id));
                                                    return selectedTypes.map((asset: any) => asset?.name).join(', ');
                                                },
                                            }}
                                            onChange={(event) => handleAssetTypeChange(event, index)}
                                        >
                                            {row.assetTypeDetails &&
                                                row.assetTypeDetails?.map((item: { id: number; name: string }) => (
                                                    <MenuItem key={item?.id} value={item?.id}>
                                                        {item?.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                                        <Button
                                            style={{ margin: '3px' }}
                                            variant="outlined"
                                            size="small"
                                            onClick={addRow}
                                        >
                                            <Add style={{ marginTop: 5 }} />
                                        </Button>

                                        <Button
                                            style={{ margin: '3px' }}
                                            variant="outlined"
                                            size="small"
                                            onClick={() => removeRow(index)}
                                        >
                                            <Delete style={{ marginTop: 5 }} />
                                        </Button>
                                    </Grid>
                                </Grid>
                            ))}

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    Save
                                </SubmitButton>

                                <SubmitButton
                                    variant='text'
                                    disabled={isSubmitting}
                                    onClick={onClose}
                                >
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    );
                }}
            </ExtendedFormik>
        </StyledModal>
    );
};

export { AssignRiskAlignmentModal };

import React from 'react';
import styled from 'styled-components';

import { InnerListItemStyled } from '@modules/layout/styled';
import { ReportCapabilityGapsList } from '@modules/assessment/moleculas';
import { CapabilityMaturityLevel } from '@modules/capabilities/moleculas';

import type { CapabilityReportEntity } from '@modules/assessment/entities';
import type { GapEntity } from '@modules/custom-components/entities';
import {
  gapLevelFieldList,
  GapRiskLevel,
  GapRiskLevelField,
  RiskLevel10PointKeyList,
} from '@modules/shared/constants/risk-level';

type ReportComponentCapabilitiesListItemProps = {
    capabilityReport: CapabilityReportEntity;
};

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    > * {
        min-width: calc(20% - 16px);
        font-size: 1.6rem;
report-roadmap-capabilities-list-item.tsx
        &:not(:last-child) {
            margin-right: 16px;
        }
    }
`;

const HeaderTitle = styled(InnerListItemStyled.Title)`
    max-width: 50%;
    width: initial;
`;

const HeaderExtra = styled.div`
    font-size: 1.4rem;
`;

const StyledCapabilityMaturityLevel = styled(CapabilityMaturityLevel)`
    width: max-content;
`;

const GapsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    margin-top: 32px;

    > * {
        min-width: calc(20% - 16px);

        &:not(:last-child) {
            margin-right: 16px;
        }
    }
`;

const Empty = styled.div`
    width: 100%;
    font-size: 1.4rem;
`;

const ReportComponentCapabilitiesListItem = (
    props: ReportComponentCapabilitiesListItemProps,
): React.ReactElement => {
    const { capabilityReport } = props;

    return (
        <InnerListItemStyled.Root>
            <Header>
                <HeaderTitle>{capabilityReport.capability.title}</HeaderTitle>

                <HeaderExtra>
                    <StyledCapabilityMaturityLevel
                        current={capabilityReport.completionLevel.getMaturityLevel()}
                    />
                </HeaderExtra>
            </Header>

            <GapsList>
                {capabilityReport.hasAnyGaps() ? (
                    <>
                      {RiskLevel10PointKeyList.map((key: string) => {
                        let typeKey = GapRiskLevelField[key];

                        if (!capabilityReport[typeKey]?.length) {
                          return null;
                        }

                        return (<ReportCapabilityGapsList
                          key={typeKey}
                          variant={GapRiskLevel[key]}
                          title={GapRiskLevel[typeKey] + ' Gaps'}
                          gaps={capabilityReport[typeKey] as GapEntity[]}
                        />)
                      })}
                    </>
                ) : null}
            </GapsList>
        </InnerListItemStyled.Root>
    );
};

export { ReportComponentCapabilitiesListItem };

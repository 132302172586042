import type { GapEntity } from '@modules/custom-components/entities';
import {
    CapabilityAssessmentEntity,
    ComponentAssessmentEntity,
    ComponentReportEntity,
} from '@modules/assessment/entities';
import { gapLevelFieldList } from '@modules/assessment/entities/capability-report-entity';
import { RoadmapRecommendationEntity, ScoredGapEntity } from '@modules/roadmap/entities';
import {
    GapRiskLevelField,
    GapRiskLevelMap,
    RiskLevel10PointKeyMap,
} from '@modules/shared/constants/risk-level';
import { ImplementationStatus } from '@modules/shared/constants/implementation-status';
import { GapAssessment } from '@modules/assessment/gap-assessment';

export type GapCapabilityAssessment = {
    implementationStatus: string;
    canShowCapability?: boolean;
    isEmptyRecord?: boolean;
} & GapAssessment;

export type GapViewData = {
    gapByRisk: GapByRisk;
    scoreList: GapCapabilityAssessment[];
    riskList: GapCapabilityAssessment[];
    componentAssessmentMap: Map<number, GapCapabilityAssessment[]>;
};

export class GapByRisk {
    superCriticalGaps: GapCapabilityAssessment[] = [];
    criticalGaps: GapCapabilityAssessment[] = [];
    highCriticalGaps: GapCapabilityAssessment[] = [];
    highGaps: GapCapabilityAssessment[] = [];
    mediumHighGaps: GapCapabilityAssessment[] = [];
    mediumGaps: GapCapabilityAssessment[] = [];
    lowMediumGaps: GapCapabilityAssessment[] = [];
    lowGaps: GapCapabilityAssessment[] = [];
    minimalLowGaps: GapCapabilityAssessment[] = [];
    minimalGaps: GapCapabilityAssessment[] = [];

    static sortGapListByScore(recordList: GapCapabilityAssessment[]): void {
        recordList.sort((a, b) => {
            return (b.scoredGap.score ?? 0) - (a.scoredGap.score ?? 0);
        });
    }

    static sortGapListByRiskLevel(recordList: GapCapabilityAssessment[]): void {
        recordList.sort((a, b) => {
            let aLevel = GapRiskLevelMap.get(a.scoredGap.riskLevel) ?? 0;
            let bLevel = GapRiskLevelMap.get(b.scoredGap.riskLevel) ?? 0;

            return bLevel - aLevel;
        });
    }

    static calcImplStatus(
        roadmapRecommendationsRaw?: (RoadmapRecommendationEntity | null)[],
    ): string {
        let roadmapRecommendations: RoadmapRecommendationEntity[] = (roadmapRecommendationsRaw?.filter(
            value => Boolean(value),
        ) ?? []) as RoadmapRecommendationEntity[];
        if (!roadmapRecommendations.length) {
            return ImplementationStatus.Pending;
        }

        const statusMap = new Map<string, boolean>();
        let hasInProgress = false;
        let hasPendingDone = false;
        for (let record of roadmapRecommendations) {
            statusMap.set(record.status, true);
            hasPendingDone = Boolean(
                statusMap.get(ImplementationStatus.Pending) &&
                    statusMap.get(ImplementationStatus.Done),
            );
            if (record.status === ImplementationStatus.InProgress || hasPendingDone) {
                hasInProgress = true;
                break;
            }
        }

        if (hasInProgress) {
            return ImplementationStatus.InProgress;
        }
        if (statusMap.get(ImplementationStatus.Pending)) {
            return ImplementationStatus.Pending;
        }
        if (statusMap.get(ImplementationStatus.Done)) {
            return ImplementationStatus.Done;
        }

        return ImplementationStatus.Pending;
    }

    static assessmentGapList(
        componentAssessment: ComponentAssessmentEntity,
    ): GapCapabilityAssessment[] {
        
        let capabilityAssessmentPrev: CapabilityAssessmentEntity | null = null;
        let implementationStatus: string;
        let canShowCapability = true;
        return (
            componentAssessment?.capabilities
                .map((capabilityAssessment: CapabilityAssessmentEntity) => {
                    return (
                        (capabilityAssessment?.scoredGaps.filter(
                            value => value,
                        ) as ScoredGapEntity[]).map((scoredGap: ScoredGapEntity) => {
                            canShowCapability = capabilityAssessmentPrev !== capabilityAssessment;
                            capabilityAssessmentPrev = capabilityAssessment;
                            implementationStatus = GapByRisk.calcImplStatus(
                                scoredGap.roadmapRecommendations,
                            );
                            return {
                                componentAssessment,
                                capabilityAssessment,
                                scoredGap,
                                canShowCapability,
                                implementationStatus,
                            };
                        }) ?? []
                    );
                })
                .flat() ?? []
        );
    }

    static getGapEmptyAssessmentList(
        componentAssessment: ComponentAssessmentEntity,
    ): GapCapabilityAssessment[] {
        let gapCapabilityList: GapCapabilityAssessment[] = [];
        componentAssessment.capabilities?.forEach(
            (capabilityAssessment: CapabilityAssessmentEntity) => {
                if (Boolean(capabilityAssessment?.scoredGaps?.length)) {
                    return;
                }

                gapCapabilityList.push({
                    componentAssessment,
                    capabilityAssessment,
                    scoredGap: (null as any) as ScoredGapEntity,
                    implementationStatus: '',
                    canShowCapability: true,
                    isEmptyRecord: true,
                });
            },
        );

        return gapCapabilityList;
    }

    static createGapByRiskFromList(recordRiskList: GapCapabilityAssessment[]) {
        const gapByRisk = new GapByRisk();
        gapLevelFieldList.forEach(riskLevelKey => {
            recordRiskList
                .filter((recordData: GapCapabilityAssessment) => {
                    return (
                        GapRiskLevelField[
                            RiskLevel10PointKeyMap[recordData.scoredGap.riskLevel]
                        ] === riskLevelKey
                    );
                })
                .forEach((recordData: GapCapabilityAssessment) => {
                    gapByRisk[riskLevelKey].push(recordData);
                });
        });

        return gapByRisk;
    }

    static gapAssessmentByRisk(assessments: ComponentAssessmentEntity[]): GapViewData {
        const componentAssessmentMap = GapByRisk.gapAssessmentByComponentMap(assessments);
        return GapByRisk.gapAssessmentFromPreparedMap(componentAssessmentMap);
    }

    static gapAssessmentByComponentMap(
        assessments: ComponentAssessmentEntity[],
    ): Map<number, GapCapabilityAssessment[]> {
        const componentAssessmentMap = new Map<number, GapCapabilityAssessment[]>();
        assessments
            // .filter(value => value.maturityLevel)
            .forEach((componentAssessment: ComponentAssessmentEntity) => {
                let recordList = GapByRisk.assessmentGapList(componentAssessment);
                componentAssessmentMap.set(componentAssessment.id, recordList);
            });

        return componentAssessmentMap;
    }

    static gapAssessmentFromPreparedMap(
        componentAssessmentMap: Map<number, GapCapabilityAssessment[]>,
    ): GapViewData {
        const scoreList: GapCapabilityAssessment[] = Array.from(
            componentAssessmentMap.values(),
        ).flat();
        GapByRisk.sortGapListByScore(scoreList);

        const riskList: GapCapabilityAssessment[] = [...scoreList];
        GapByRisk.sortGapListByRiskLevel(riskList);

        const gapByRisk = GapByRisk.createGapByRiskFromList(riskList);

        return { gapByRisk, scoreList, riskList, componentAssessmentMap };
    }

    static gapReportByRisk(
        componentReports: (ComponentReportEntity | null)[],
        assessmentMap: Map<number, ComponentAssessmentEntity>,
    ): GapByRisk {
        const gapByRisk = new GapByRisk();
        componentReports.forEach(componentReport => {
            let componentAssessment = assessmentMap.get(componentReport?.assessmentId as number);
            let capabilityAssessmentMap: Map<number, CapabilityAssessmentEntity> = new Map(
                componentAssessment?.capabilities.map(
                    (capabilityAssessment: CapabilityAssessmentEntity) => {
                        return [capabilityAssessment.id, capabilityAssessment];
                    },
                ) ?? [],
            );

            componentReport?.capabilities.forEach(capability => {
                let capabilityAssessment = capabilityAssessmentMap.get(
                    capability.capabilityAssessmentId,
                );
                gapLevelFieldList.forEach(riskLevelKey => {
                    capability[riskLevelKey].forEach((gap: GapEntity) => {
                        gapByRisk[riskLevelKey].push({
                            componentReport,
                            capability,
                            capabilityAssessment,
                            gap,
                        });
                    });
                });
            });
        });

        return gapByRisk;
    }

    hasGaps(): boolean {
        return gapLevelFieldList.some(riskLevelKey => Boolean(this[riskLevelKey].length));
    }
}

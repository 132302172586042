import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';

import { TextField, Button } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';
import { GetAllAssessmentTypeQuery } from '@modules/components/graphql';
import { CustomAssessmentTypeCreateRequest } from '@modules/custom-components/requests';
import { AddAssessmentTypeMutation } from '@modules/custom-components/graphql';

import type { CustomTypeCreateFormValues } from '@modules/custom-components/requests';
import type {
    AddAssessmentTypeMutationType,
    AddAssessmentTypeMutationVariables,
} from '@modules/types/graphql';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Autocomplete } from '@modules/ui/core';
import { ListAssessmentCategoriesEntity } from '@modules/custom-components/entities';
import { useListAssessmentCategories } from '@modules/custom-components/hooks';
import { Loader } from '@modules/layout/moleculas';

type CustomCategoryCreateFormProps = {
    assessmentId?: number;
    onCancel?: () => void;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const CustomAssessmentTypeCreateForm = (
    props: CustomCategoryCreateFormProps,
): React.ReactElement => {
    const { onCancel, assessmentId } = props;
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const { listAssessmentCategories, loading: categoryLoading } = useListAssessmentCategories();
    const assessmentCategor: any = listAssessmentCategories?.map((ele: any) => ele?.id);

    const getCategoryOptionLabel = React.useCallback(
        (option: ListAssessmentCategoriesEntity | null) => option?.name ?? '-',
        [],
    );
    const [addAssessmentType, { loading: assessmentLoading }] = useMutation<
        AddAssessmentTypeMutationType,
        AddAssessmentTypeMutationVariables
    >(AddAssessmentTypeMutation);

    const initialValues: CustomTypeCreateFormValues = {
        name: '',
        assessmentCategoriesId: assessmentId,
        isRRType: false,
    };

    if (categoryLoading || assessmentLoading) {
        return <Loader />;
    }
    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                // assessmentCategoriesId: yup.object().required('Required field'),
                name: yup.string().required('Required field'),
            })}
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const variables = new CustomAssessmentTypeCreateRequest(values);
                    variables.assessmentCategoriesId = assessmentId as number;
                    const { data: createAssessmentData } = await addAssessmentType({
                        variables,
                        refetchQueries: [{ query: GetAllAssessmentTypeQuery }],
                        awaitRefetchQueries: true,
                    });

                    const assessmentIds = createAssessmentData?.addAssessmentType?.id;

                    if (assessmentIds) {
                        enqueueSuccess('Assessment type successfully created!');
                        onCancel?.();
                    } else {
                        enqueueError('An error occurred while creating Assessment type!');
                    }
                } catch (e) {
                    throw e;
                }
            }}
        >
            {formikProps => {
                const {
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                    setFieldValue,
                } = formikProps;
                const handleChangeAutocomplete = (field: any) => (
                    _: React.ChangeEvent<{}>,
                    value: any | null,
                ) => {
                    setFieldValue(field, value);
                };
                return (
                    <StyledForm>
                        <TextField
                            required
                            fullWidth
                            id='name'
                            label='Assessment Type Name'
                            value={values.name}
                            error={!!errors.name}
                            helperText={errors.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values?.isRRType}
                                    value={values?.isRRType}
                                    onChange={handleChange}
                                    name='isRRType'
                                />
                            }
                            label='Risk Registry Category'
                        />

                        <FormStyled.FormRowSubmit>
                            <SubmitButton type='submit' loading={isSubmitting}>
                                Create Assessment Type
                            </SubmitButton>

                            <SubmitButton variant='text' disabled={isSubmitting} onClick={onCancel}>
                                Cancel
                            </SubmitButton>
                        </FormStyled.FormRowSubmit>
                    </StyledForm>
                );
            }}
        </ExtendedFormik>
    );
};

export { CustomAssessmentTypeCreateForm };

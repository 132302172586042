import React from 'react';
import styled from 'styled-components';

import { Button } from '@modules/ui/core';
import { useAssignedProject } from '@modules/projects/hooks';
import { GapCreateModal } from '@modules/custom-components/organisms';
// import { AssessmentCapabilityGapsEditFormModal } from './assessment-capability-gaps-edit-form-modal';

import type { ProjectEntity } from '@modules/projects/entities';
import type { ComponentAssessmentEntity } from '@modules/assessment/entities';
import type { ScoredGapEntity } from '@modules/roadmap/entities';
import { RemoveRedEyeRounded } from '@material-ui/icons';
import { ImplementationTasksEditFormModal } from './implementation-tasks-edit-form-modal';
import { ImplementationTaskListItem } from './implementation-task-list-item';
import { ImplementationTaskCreateModal } from './implementation-task-create-modal';
import { useRecommendationImpTasks } from '@modules/implementation/hooks';
import { Loader } from '@modules/layout/moleculas';

type ImplementationTaskListProps = {
    canEdit: boolean;
    recommendationId: number;
    project: ProjectEntity;
    impTask: any;
    className?: string;
    loadingImp: boolean;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 2.2rem;
`;

const List = styled.div`
    width: 100%;
    margin-top: 24px;

    > * {
        margin-bottom: 12px;
    }
`;

const TaskItem = styled.div`
    width: 100%;
    padding: 12px;
    font-size: 1.7rem;
`;

const EmptyGaps = styled(TaskItem)`
    height: initial;
    padding: 0 4px;
    font-size: 1.6rem;
    font-weight: 700;

    && {
        border: 0;
    }
`;
const Actions = styled.div`
    width: 100%;

    > *:not(:last-child) {
        margin-right: 16px;
    }
`;
const ImplementationTask = styled.div`
    font-size: 14px;
    padding: 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h4 {
        margin: 10px 0px;
        margin: 10px 0px;
    }
    & svg:hover {
        color: #d4d4d4;
    }
    > h4 svg {
        font-size: 2rem;
    }
`;
const EyeInfo = styled.div`
    > svg {
        font-size: 3rem;
        text-align: center;
        cursor: pointer;
        margin-right: 35px;
    }
    &:hover {
        color: #5a5a5a;
    }
`;

const ImplementationTaskList = (props: ImplementationTaskListProps): React.ReactElement | null => {
    const { canEdit, project, recommendationId, impTask, loadingImp, ...otherProps } = props;

    const { refetch: refetchAssignedProject } = useAssignedProject({
        skip: !project.id,
        variables: { id: project.id },
    });

    const handleGapAddingSuccess = async (): Promise<void> => {
        await refetchAssignedProject();
    };
    const [openImpTaskEditFormModal, setOpenImpTaskEditFormModal] = React.useState<boolean>(false);

    const handleOpenImpTaskEditFormModal = (): void => setOpenImpTaskEditFormModal(true);
    const handleCloseImpTaskEditFormModal = (): void => setOpenImpTaskEditFormModal(false);

    const [openImpTaskCreateModal, setOpenImpTaskCreateModal] = React.useState<boolean>(false);

    const handleOpenImpTaskCreateModal = (): void => setOpenImpTaskCreateModal(true);
    const handleCloseGapCreateModal = (): void => setOpenImpTaskCreateModal(false);

    const { RecImpTask, loading: RecImpTaskLoading } = useRecommendationImpTasks({
        variables: { recommendationId: recommendationId },
    });

    const hasTasks = RecImpTask?.length !== 0;
    if (loadingImp || RecImpTaskLoading) {
        return <Loader />;
    }

    return (
        <div {...otherProps}>
            <List>
                {!hasTasks ? (
                    <EmptyGaps>Not found tasks for the recommendation</EmptyGaps>
                ) : (
                    (RecImpTask && RecImpTask)?.map((task: any, id: number) => (
                        <ImplementationTaskListItem
                            key={`${task?.id} -${id}`}
                            project={project}
                            impTask={task}
                            RecImpTaskLoading={RecImpTaskLoading}
                            recommendationId={recommendationId}
                        />
                    ))
                )}
            </List>

            {hasTasks ? (
                <>
                    <Actions>
                        <Button size='small' onClick={handleOpenImpTaskEditFormModal}>
                            Edit Task
                        </Button>

                        <Button
                            size='small'
                            variant='outlined'
                            onClick={handleOpenImpTaskCreateModal}
                        >
                            + Add Task
                        </Button>
                    </Actions>

                    <ImplementationTasksEditFormModal
                        impTask={impTask}
                        companyId={project.companyId}
                        loadingImp={loadingImp}
                        RecImpTask={RecImpTask}
                        recommendationId={recommendationId}
                        open={openImpTaskEditFormModal}
                        onClose={handleCloseImpTaskEditFormModal}
                    />

                    <ImplementationTaskCreateModal
                        companyId={project.companyId}
                        recommendationId={recommendationId}
                        open={openImpTaskCreateModal}
                        onClose={handleCloseGapCreateModal}
                        onSuccess={handleGapAddingSuccess}
                    />
                </>
            ) : null}
        </div>
    );
};

export { ImplementationTaskList };

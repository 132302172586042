import React from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from '@reach/router';

import { Button } from '@modules/ui/core';
import { PageStyled } from '@modules/layout/styled';
import { Loader } from '@modules/layout/moleculas';
import { useComponent } from '@modules/components/hooks';
import { useCapabilities } from '@modules/custom-components/hooks';
import { CapabilityListToolbar, CapabilityList } from '@modules/custom-components/organisms';
import { useAccordion } from '@helpers';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useCurrentUser } from '@modules/auth/hooks';
import { useMutation } from '@apollo/client';
import {
    DeleteComponentMutationType,
    DeleteComponentMutationVariables,
} from '@modules/types/graphql';
import { DeleteComponentMutation } from '@modules/custom-components/graphql';
import { GetComponentsQuery } from '@modules/components/graphql';
import { useEnqueueStacks } from '@modules/layout/hooks';

const ListRoot = styled.div`
    width: 100%;
`;

const ListRootFooter = styled.div`
    margin-top: 48px;
`;
const EditDetailPage = styled.div`
    background: #d8d8d8;
    padding: 10px;
    /* margin-bottom: 5px; */
    border-bottom: 2px solid #fcfcfc;
`;
const PageHeaderStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    h2 {
        font-size: 19px !important;
    }
    svg {
        font-size: 26px;
        color: #757575;
    }
`;

type ComponentEditProps = {
    AssessmentTypeName: any;
    componentId: number;
};

const ComponentEditPage = (props: ComponentEditProps): React.ReactElement => {
    const { AssessmentTypeName, componentId } = props;
    const params = useParams();
    const navigate = useNavigate();

    const [deleteComponent] = useMutation<
        DeleteComponentMutationType,
        DeleteComponentMutationVariables
    >(DeleteComponentMutation);
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    // const componentId = params.id;
    const { activeIndex, toggleAccordion } = useAccordion('Access Control');

    const { component, loading: componentLoading } = useComponent({
        skip: !componentId,
        variables: { id: componentId },
    });
    const { currentUser } = useCurrentUser();

    const { capabilities, loading: capabilitiesLoading } = useCapabilities({
        skip: !componentId,
        variables: { componentId },
        fetchPolicy: 'network-only',
    });

    const handleDeleteComponent = async (componentId: number): Promise<void> => {
        try {
            const { data: deleteComponentData } = await deleteComponent({
                variables: { componentId },
                refetchQueries: [{ query: GetComponentsQuery }],
                awaitRefetchQueries: true,
            });

            if (deleteComponentData?.deleteComponent) {
                enqueueSuccess('Component successfully deleted!');
            } else {
                enqueueError('An error occurred while deleting the component!');
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleClickReturn = () => {
        navigate('../');
    };

    if (componentLoading || capabilitiesLoading) {
        return <Loader />;
    }

    const canEditComponent = component.isPredefined()
        ? currentUser.hasSuperAdminPermission()
        : true;

    return (
        <EditDetailPage>
            <PageHeaderStyle onClick={() => toggleAccordion(AssessmentTypeName?.name)}>
                <h2>{component.name} </h2>
                {activeIndex === AssessmentTypeName?.name ? (
                    <ExpandMoreIcon />
                ) : (
                    <KeyboardArrowLeftIcon />
                )}
            </PageHeaderStyle>
            {activeIndex === AssessmentTypeName?.name && (
                <>
                    <PageStyled.Bridge>
                        <CapabilityListToolbar
                            componentId={component.id}
                            canEditComponent={canEditComponent}
                            handleDeleteComponent={handleDeleteComponent}
                        />
                    </PageStyled.Bridge>

                    <PageStyled.Main>
                        <ListRoot>
                            <CapabilityList
                                capabilities={capabilities}
                                componentId={component.id}
                            />

                            <ListRootFooter>
                                <Button variant='outlined' onClick={handleClickReturn}>
                                    Return to the component list
                                </Button>
                            </ListRootFooter>
                        </ListRoot>
                    </PageStyled.Main>
                </>
            )}
        </EditDetailPage>
    );
};

export { ComponentEditPage };

import React from 'react';
import { ListItemActions } from '@modules/layout/moleculas';
import { DialogLearnTkoDelete } from '../dialog-learn-tko-delete';
import { DialogLearnEdit } from '../dialog-learn-edit';
import styled from 'styled-components';
import FileCopyIcon from '@material-ui/icons/FileCopy';

type LearnTkoItemActionsProps = {
    learnTkoId: number;
    learnTkoCopy?: string;
    type?: boolean;
    onDelete: (learnTkoId: number) => Promise<void>;
    onUpdate: (learnTkoValue: any) => void;
};

const FileCopyIconStyle = styled(FileCopyIcon)`
    font-size: 17px;
`;
const FileCopyStyle = styled.div`
    text-align: center;
    cursor: pointer;
    padding: 6px 0px;
    &:hover {
        background: #e7e7e7e6;
    }
`;

const LearnTkoItemActions = (props: LearnTkoItemActionsProps): React.ReactElement => {
    const { learnTkoId, onDelete, onUpdate, learnTkoCopy, type } = props;

    const handleDelete = () => onDelete(learnTkoId);
    const handleUpdate = () => onUpdate(learnTkoCopy);

    return (
        <ListItemActions>
            {actionsProps => {
                const { onClose } = actionsProps;

                const handleClick = (fn?: any) => (): void => {
                    if (typeof fn === 'function') {
                        fn();
                    }

                    onClose();
                };

                return (
                    <>
                        <DialogLearnTkoDelete onDelete={handleClick(handleDelete)} />
                        {type ? (
                            <FileCopyStyle>
                                <FileCopyIconStyle onClick={handleClick(handleUpdate)} />
                            </FileCopyStyle>
                        ) : (
                            <DialogLearnEdit
                                learnTkoId={learnTkoId}
                                onUpdate={handleClick(handleUpdate)}
                            />
                        )}
                    </>
                );
            }}
        </ListItemActions>
    );
};

export { LearnTkoItemActions };

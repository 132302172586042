import React from 'react';
import styled from 'styled-components';

import { InnerListItemStyled } from '@modules/layout/styled';
import { CapabilityMaturityLevel } from '@modules/capabilities/moleculas';
import { ReportRoadmapCapabilityRecommendationsList } from '@modules/roadmap/moleculas';

import type { CapabilityReportEntity } from '@modules/assessment/entities';
import type { RoadmapRecommendationEntity } from '@modules/roadmap/entities';
import {GapRiskLevel, GapRiskLevelField, RecommendationRiskLevelField, RiskLevel10PointKeyList} from '@modules/shared/constants/risk-level';
import {GapEntity} from '@modules/custom-components/entities';

type ReportRoadmapCapabilitiesListItemProps = {
    capabilityReport: CapabilityReportEntity;
};

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    > * {
        min-width: calc(20% - 16px);
        font-size: 1.6rem;

        &:not(:last-child) {
            margin-right: 16px;
        }
    }
`;

const HeaderTitle = styled(InnerListItemStyled.Title)`
    max-width: 50%;
    width: initial;
`;

const HeaderExtra = styled.div`
    font-size: 1.4rem;
`;

const StyledCapabilityMaturityLevel = styled(CapabilityMaturityLevel)`
    width: max-content;
`;

const GapsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    margin-top: 32px;

    > * {
        min-width: calc(20% - 16px);

        &:not(:last-child) {
            margin-right: 16px;
        }
    }
`;

const Empty = styled.div`
    width: 100%;
    font-size: 1.4rem;
`;

const ReportRoadmapCapabilitiesListItem = (
    props: ReportRoadmapCapabilitiesListItemProps,
): React.ReactElement => {
    const { capabilityReport } = props;

    return (
        <InnerListItemStyled.Root>
            <Header>
                <HeaderTitle>{capabilityReport.capability.title}</HeaderTitle>

                <HeaderExtra>
                    <StyledCapabilityMaturityLevel
                        current={capabilityReport.completionLevel.getMaturityLevel()}
                    />
                </HeaderExtra>
            </Header>

            <GapsList>
                {capabilityReport.hasAnyRecommendations() ? (
                    <>
                      {RiskLevel10PointKeyList.map((key: string) => {
                        let typeKey = RecommendationRiskLevelField[key];

                        if (!capabilityReport[typeKey]?.length) {
                          return null;
                        }

                        return (<ReportRoadmapCapabilityRecommendationsList
                          key={typeKey}
                          variant={GapRiskLevel[key]}
                          title={GapRiskLevel[typeKey] + ' Recommendations'}
                          recommendations={
                            capabilityReport[typeKey] as RoadmapRecommendationEntity[]
                          }
                        />)
                      })}
                    </>
                ) : (
                    <Empty>Recommendations not found</Empty>
                )}
            </GapsList>
        </InnerListItemStyled.Root>
    );
};

export { ReportRoadmapCapabilitiesListItem };

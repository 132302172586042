import { gql } from '@apollo/client';

import { UserFragment } from '@modules/users/graphql';
import { RoadmapRecommendationFragment, ComponentRecommendationFragment } from '../fragments';
import {GapSingleFragment} from '@modules/custom-components/graphql';

/**
 * Todo: replace assessment data with ComponentAssessmentFragment
 * Have to copy ComponentAssessmentFragment here to prevent GraphQL error regarding undefined
 * The root cause highly likely
 * https://github.com/apollographql/graphql-tag/issues/262
 */
export const GetRoadmapReportQuery = gql`
    query GetRoadmapReport($id: Int!) {
        getAssignedProject(id: $id) {
            id
            name
            description
            status
            company_id
            assessment_type_id
            is_risk_registry
            roadmap_status
            maturity_level {
                id
                name
            }
            stakeholder {
                ...User
            }
            program_manager {
                ...User
            }
            assessments {
                id
                status
                roadmap_status
                weight
                objectives
                maturity_level {
                    id
                    name
                }
                component {
                    id
                    name
                }
                component_owners {
                    id
                    first_name
                    last_name
                }
                operators {
                    id
                    first_name
                    last_name
                }
                capabilities {
                    id
                    weight
                    notes
                    gaps
                    recommendations
                    assessed_at
                    assessed_by {
                        ...User
                    }
                    operators {
                        id
                        first_name
                        last_name
                    }
                    capability {
                        id
                        title
                        key_factors
                        security_controls
                    }
                    completion_level {
                        id
                        name
                        description
                        level
                        gaps {
                            ...GapSingle
                        }
                    }
                    capability_levels {
                        id
                        name
                        description
                        level
                    }
                    scored_gaps {
                        id
                        score
                        risk_level
                        gap {
                            ...GapSingle
                        }
                        roadmap_recommendations {
                            ...RoadmapRecommendation
                        }
                    }
                    log {
                        id
                        action
                        action_timestamp
                        user {
                            email
                            first_name
                            last_name
                        }
                        notes
                        gaps
                        recommendations
                    }
                }
            }
        }

        getAssessmentReport(project_id: $id) {
            program_manager {
                id
                first_name
                last_name
            }
            component_reports {
                assessment_id
                component_weight
                objectives
                maturity_level {
                    id
                    name
                }
                component_owner {
                    id
                    first_name
                    last_name
                }
                component {
                    id
                    name
                }
                top_recommendations {
                    ...RoadmapRecommendation
                }

                capabilities {
                    capability_assessment_id
                    capability {
                        id
                        title
                        security_controls
                    }
                    completion_level {
                        id
                        name
                        level
                    }
                    super_critical_recommendations {
                        ...RoadmapRecommendation
                    }
                    critical_recommendations {
                        ...RoadmapRecommendation
                    }
                    high_critical_recommendations {
                        ...RoadmapRecommendation
                    }
                    high_recommendations {
                        ...RoadmapRecommendation
                    }
                    medium_high_recommendations {
                        ...RoadmapRecommendation
                    }
                    medium_recommendations {
                        ...RoadmapRecommendation
                    }
                    low_medium_recommendations {
                        ...RoadmapRecommendation
                    }                
                    low_recommendations {
                        ...RoadmapRecommendation
                    }
                    minimal_low_recommendations {
                        ...RoadmapRecommendation
                    }
                    minimal_recommendations {
                        ...RoadmapRecommendation
                    }
                }
                top_recommendations {
                    ...RoadmapRecommendation
                }
            }
            prioritized_roadmap {
                project_id
                super_critical_recommendations {
                    ...ComponentRecommendation
                }
                critical_recommendations {
                    ...ComponentRecommendation
                }
                high_critical_recommendations {
                    ...ComponentRecommendation
                }
                high_recommendations {
                    ...ComponentRecommendation
                }
                medium_high_recommendations {
                    ...ComponentRecommendation
                }
                medium_recommendations {
                    ...ComponentRecommendation
                }
                low_medium_recommendations {
                    ...ComponentRecommendation
                }                
                low_recommendations {
                    ...ComponentRecommendation
                }
                minimal_low_recommendations {
                    ...ComponentRecommendation
                }
                minimal_recommendations {
                    ...ComponentRecommendation
                }
            }
        }
    }
    ${UserFragment}
    ${GapSingleFragment}
    ${RoadmapRecommendationFragment}
    ${ComponentRecommendationFragment}
`;

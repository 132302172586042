import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { CompanyEntityList } from '@modules/company/entities';
import { GetCompaniesCountQuery } from '@modules/company/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetListCompaniesCountType } from '@modules/types/graphql';

const useCompaniesCount = (options?: QueryHookOptions<GetListCompaniesCountType>) => {
    const queryResult = useQuery<GetListCompaniesCountType>(GetCompaniesCountQuery, options);

    const { entities: companiesCount, count } = useEntity(
        () => new CompanyEntityList(queryResult.data?.listCompaniesCount ?? null),
        [queryResult.data],
    );

    const result = {
        companiesCount,
        count,
        ...queryResult,
    };

    return result;
};

export { useCompaniesCount };

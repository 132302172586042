import React from 'react';

import { Modal } from '@modules/ui/core';
import { CustomComponentCreateForm } from '@modules/custom-components/organisms';

import type { ModalProps } from '@modules/ui/core';

type CustomComponentCreateModalProps = Omit<ModalProps, 'title' | 'children'> & {
    companyId?: number;
    assessmentId?: number;
};

const CustomComponentCreateModal = (props: CustomComponentCreateModalProps): React.ReactElement => {
    const { companyId, assessmentId, onClose, ...otherProps } = props;

    return (
        <Modal title='Create Component' onClose={onClose} {...otherProps}>
            <CustomComponentCreateForm
                assessmentId={assessmentId}
                companyId={companyId}
                onCancel={onClose}
            />
        </Modal>
    );
};

export { CustomComponentCreateModal };

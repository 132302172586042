import { EntityList } from '@lib/entity';

import type { ListDataEntity } from './all-s3-data-entity';
import { S3DataEntity } from './all-s3-data-entity';

class S3DataEntityList extends EntityList<S3DataEntity> {
    constructor(items: ListDataEntity[] | null, count?: number | null) {
        super(S3DataEntity, items, count);
    }
}

export { S3DataEntityList };

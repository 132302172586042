import { useQuery } from '@apollo/client';
import type { QueryHookOptions } from '@apollo/client';
import { GetListItemTypeQuery } from '../graphql';
import { useEntity } from '@lib/entity';
import { ItemTypeEntityList } from '../entities';

const useItemType = (options?: QueryHookOptions) => {
    const queryResult = useQuery(GetListItemTypeQuery, options);

    const { entities: itemType, count } = useEntity(
        () => new ItemTypeEntityList(queryResult.data?.listItemType ?? null),
        [queryResult.data],
    );


    const result = {
        itemType,
        count,
        ...queryResult,
    };

    return result;
};

export { useItemType };
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MenuItem, makeStyles } from '@material-ui/core';
import { TextField } from '@modules/ui/core';
import { gray } from '@modules/ui/colors';

import { useLocationQuery, useDistinctRoles } from '@modules/shared/hooks';
import { useAssignedProjects } from '@modules/projects/hooks';
import { useDebounce } from 'react-use';
import { CommonHelper } from '@helpers';
import { useCompanyDetails } from '@modules/company/hooks';
import { useCurrentUser } from '@modules/auth/hooks';

type UsersListFiltersValues = {
    role?: string[];
    project?: string[];
};
type CompnayDetailProps = {
    address?: string;
    contact_name?: string;
    email?: string;
    expires_at?: string;
    id?: number;
    package_type: string;
    phone: string;
    subscription_type: string;
    user_id: number;
    project_count: number;
    remaining_projects: number;
};

const useBackgroundStyles = makeStyles({
    root: {
        backgroundColor: gray[60],
    },
});

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > * {
        width: 350px;

        &:not(:last-of-type) {
            margin-right: 32px;
        }
    }
`;

const initialFilters: UsersListFiltersValues = {
    role: [],
    project: [],
};

const UsersListFilters = (): React.ReactElement => {
    const [query, setQuery] = useLocationQuery();
    const backgroundClasses = useBackgroundStyles();
    const { projects } = useAssignedProjects();
    const { roles } = useDistinctRoles();

    const { CompanyDetails } = useCompanyDetails();

    const filters = {
        role: CommonHelper.multipleValuesFormatter(query.role as string),
        project: CommonHelper.multipleValuesFormatter(query.project as string),
    };
    const { currentUser } = useCurrentUser();
    const defaultFilters = CommonHelper.fillDefaultValues(filters, initialFilters);

    const [values, setValues] = React.useState(defaultFilters);

    const handleChangeField = (event: React.ChangeEvent<{ name: string; value: string }>) => {
        const name = event.target.name;
        const value = event.target.value;

        setValues(state => ({ ...state, [name]: value }));
    };

    useDebounce(
        () => {
            const updatedQuery = { ...filters, ...values };
            setQuery(updatedQuery);
        },
        500,
        [values],
    );

    const calculateTotalRemainingDays = (expiry: any) => {
        const expiryDate: any = new Date(expiry);
        const currentTime: any = new Date();
        const timeDifference = expiryDate - currentTime;
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        return ` ${days} days`;
    };

    return (
        <Root>
            {currentUser.isAdmin && !currentUser.isSuperAdmin ? (
                CompanyDetails?.map((ele: CompnayDetailProps) => {
                    return (
                        <React.Fragment key={ele.id}>
                            <TextField
                                name='package'
                                label='Package'
                                classes={backgroundClasses}
                                value={ele?.package_type}
                                inputProps={{
                                    style: { color: '#000', fontWeight: 'bold' },
                                    disabled: true,
                                }}
                            ></TextField>
                            <TextField
                                name='licenses'
                                label='Project Remaining'
                                classes={backgroundClasses}
                                // value={calculateTotalRemainingDays(
                                //     ele?.expires_at?.substring(0, 10),
                                // )}
                                value={ele?.remaining_projects}
                                inputProps={{
                                    style: { color: '#000', fontWeight: 'bold' },
                                    disabled: true,
                                }}
                            ></TextField>
                            <TextField
                                name='expiry'
                                label='Expiration Date'
                                classes={backgroundClasses}
                                value={ele?.expires_at?.substring(0, 10)}
                                inputProps={{
                                    style: { color: '#000', fontWeight: 'bold' },
                                    disabled: true,
                                }}
                            ></TextField>
                        </React.Fragment>
                    );
                })
            ) : (
                <>
                    <TextField
                        select
                        name='role'
                        label='Role'
                        classes={backgroundClasses}
                        value={values.role}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                            multiple: true,
                        }}
                        onChange={handleChangeField}
                    >
                        {roles &&
                            roles.map((role: { id: number; name: string }) => (
                                <MenuItem key={role.id} value={role.id}>
                                    {role.name}
                                </MenuItem>
                            ))}
                    </TextField>

                    <TextField
                        select
                        name='project'
                        label='Project'
                        classes={backgroundClasses}
                        value={values.project}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                            multiple: true,
                        }}
                        onChange={handleChangeField}
                    >
                        {projects ? (
                            projects.map(project => (
                                <MenuItem key={project?.id} value={project?.id.toString()}>
                                    {project?.name}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem key={0} value={''}>
                                Select project
                            </MenuItem>
                        )}
                    </TextField>
                </>
            )}
        </Root>
    );
};

export { UsersListFilters };

import type { CreateCapabilityMutationVariables } from '@modules/types/graphql';

export type CapabilityCreateFormValues = {
    componentId: number;
    title: string;
    impact: string;
    keyFactors: string;
    securityControls: string;
    companyId?: number;
};

class CapabilityCreateRequest {
    componentId: CreateCapabilityMutationVariables['componentId'];
    title: CreateCapabilityMutationVariables['title'];
    impact: CreateCapabilityMutationVariables['impact'];
    keyFactors: CreateCapabilityMutationVariables['keyFactors'];
    securityControls: CreateCapabilityMutationVariables['securityControls'];
    companyId: CreateCapabilityMutationVariables['companyId'];

    constructor(values: CapabilityCreateFormValues) {
        this.componentId = values.componentId;
        this.title = values.title;
        this.impact = values.impact;
        this.keyFactors = values.keyFactors;
        this.securityControls = values.securityControls;
        this.companyId = values.companyId;
    }
}

export { CapabilityCreateRequest };

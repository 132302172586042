import React from 'react';
import styled from 'styled-components';
import {
    makeStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { gray } from '@modules/ui/colors';

import type { AccordionProps } from '@material-ui/core';

type AccordtionListItemProps = AccordionProps & {
    title: string | any;
    subtitle?: string;
    className?: string;
    SummaryInfoComponents?: React.ReactNode;
    componentActionStatus?: boolean;
    componentSearch?: any;
};

const useAccordionStyles = makeStyles({
    root: {
        width: '100%',
        backgroundColor: gray[60],
    },
});

const useAccordionSummaryStyles = makeStyles({
    // root: {
    //     flexWrap: 'wrap',
    // },
    content: {
        position: 'relative',
        alignItems: 'center',
        minHeight: 60,
        margin: 0,
        justifyContent: 'space-between',
        padding: '10px 0',

        '&$expanded': {
            margin: '5px 0',
        },
    },
    expanded: {},
});

const useAccordionDetailsStyles = makeStyles({
    root: {
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
});

const Description = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 60%;
`;

const Title = styled.div`
    font-size: 2.2rem;
    font-weight: 600;
`;

const Subtitle = styled.div`
    margin-top: 12px;
    width: 100%;
    font-size: 1.6rem;
    font-weight: 400;
`;

const ThreeDots = styled(IconButton)`
    position: absolute;
    right: 5px;
    top: 6px;
    z-index: 999;
    svg {
        font-size: 25px;
    }
`;

const AccordionListItem = (props: AccordtionListItemProps): React.ReactElement => {
    const {
        title,
        subtitle,
        componentSearch,
        componentActionStatus,
        children,
        SummaryInfoComponents,
        ...otherProps
    } = props;

    const accordionClasses = useAccordionStyles();
    const accordionSummaryClasses = useAccordionSummaryStyles();
    const accordionDetailsClasses = useAccordionDetailsStyles();

    const highlightText = (text: string, query: string) => {
        if (!text || !query) {
            return <span>{text}</span>;
        }

        const parts = text?.split(new RegExp(`(${query})`, 'gi'));
        return (
            <span>
                {parts?.map((part, index) =>
                    part?.toLowerCase() === query?.toLowerCase() ? (
                        <span key={index} style={{ background: '#ffae33' }}>
                            {part}
                        </span>
                    ) : (
                        part
                    ),
                )}
            </span>
        );
    };
    return (
        <Accordion classes={accordionClasses} {...otherProps}>
            <AccordionSummary
                classes={accordionSummaryClasses}
                expandIcon={!componentActionStatus && <ExpandMore />}
            >
                <Description>
                    <Title>{highlightText(title, componentSearch)}</Title>
                    {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
                </Description>
                {SummaryInfoComponents ?? null}
            </AccordionSummary>

            <AccordionDetails classes={accordionDetailsClasses}>{children}</AccordionDetails>
        </Accordion>
    );
};

export { AccordionListItem };

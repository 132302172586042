import { gql } from '@apollo/client';

export const SaveCapabilitySurveyMutation = gql`
    mutation SaveCapabilitySurvey(
        $capabilityLevelId: Int!
        $surveyId: Int!
        $notes: String
        $gaps: String
        $recommendations: String
    ) {
        submitCapabilitySurvey(
            capability_level_id: $capabilityLevelId
            survey_id: $surveyId
            notes: $notes
            gaps: $gaps
            recommendations: $recommendations
        ) {
            survey_id
            capability_level_id
        }
    }
`;

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { CompanyAccessEntity } from '@modules/company/entities';

import type { QueryHookOptions } from '@apollo/client';
import type { GetCompanyAccessQueryType } from '@modules/types/graphql';
import { GetCompanyAccessQuery } from '../graphql/queries';

const useCompanyAccess = (options?: QueryHookOptions<GetCompanyAccessQueryType>) => {
    const queryResult = useQuery<GetCompanyAccessQueryType>(GetCompanyAccessQuery, {
        ...options,
    });

    const companyAccess = useEntity(
        () => new CompanyAccessEntity(queryResult.data?.getCompanyAccess ?? null),
        [queryResult.data],
    );

    const result = {
        companyAccess,
        ...queryResult,
    };

    return result;
};

export { useCompanyAccess };

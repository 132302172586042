import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Modal } from '@modules/ui/core';
import type { ModalProps } from '@modules/ui/core';
import {RoadmapRecommendationEntity} from '@modules/roadmap/entities';
import {RoadmapHistory} from '@modules/roadmap/organisms/roadmap-recommendations-list/roadmap-history';

const StyledModal = styled(Modal)`
    width: 1200px;

    .is-empty-results {
        font-size: 14px;
        text-align: center;

        td {
            padding: 20px;
        }
    }
`;

const RoadmapHistoryModal = (
    props: ModalProps & { roadmapRecommendation: RoadmapRecommendationEntity },
): React.ReactElement => {
    const { roadmapRecommendation, onClose, ...otherProps } = props;

    return (
        <StyledModal title='Roadmap history' onClose={onClose} {...otherProps}>
            <RoadmapHistory roadmapRecommendation={roadmapRecommendation} />
        </StyledModal>
    );
};

export { RoadmapHistoryModal };

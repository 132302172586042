import { EntityList } from '@lib/entity';
import { ComponentRecommendationEntity } from './component-recommendation-entity';

import type { Entity } from './component-recommendation-entity';

class ComponentRecommendationEntityList extends EntityList<ComponentRecommendationEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(ComponentRecommendationEntity, items, count);
    }
}

export { ComponentRecommendationEntityList };

import { gql } from '@apollo/client';

export const GetListItemTypeQuery = gql`
    query listItemType {
        listItemType {
            id
            name
        }
    }
`;

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { GetMaturityLevelsQuery } from '@modules/shared/graphql';
import { MaturityLevelsEntityList } from '@modules/shared/entities/maturity-levels-entity-list';

import type { QueryHookOptions } from '@apollo/client';

const useMaturityLevels = (options?: QueryHookOptions) => {
    const queryResult = useQuery(GetMaturityLevelsQuery, options);

    const { entities: maturityLevels, count } = useEntity(
        () => new MaturityLevelsEntityList(queryResult.data?.getMaturityLevels ?? null),
        [queryResult.data],
    );

    const result = {
        maturityLevels,
        count,
        ...queryResult,
    };

    return result;
};

export { useMaturityLevels };

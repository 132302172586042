import React from 'react';
import { makeStyles, Checkbox } from '@material-ui/core';
import { IndeterminateCheckBox, CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';

import { blue, gray, turquoise } from '@modules/ui/colors';

import type { CheckboxProps, Theme } from '@material-ui/core';

type TableCheckboxProps = CheckboxProps & {
    variant?: 'head' | 'body';
};

const useCheckboxStyles = makeStyles<Theme, Pick<TableCheckboxProps, 'variant'>>({
    root: p => ({
        color: p.variant === 'head' ? blue[10] : turquoise[100],
        '&$indeterminate': {
            color: gray[30],
        },
    }),
    colorPrimary: p => ({
        '&$checked': {
            color: p.variant === 'head' ? blue[10] : turquoise[100],
        },
    }),
    checked: () => ({}),
    indeterminate: () => ({}),
});

const icon = <CheckBoxOutlineBlank style={{ fontSize: 20 }} />;
const indeterminateIcon = <IndeterminateCheckBox style={{ fontSize: 20 }} />;
const checkedIcon = <CheckBox style={{ fontSize: 20 }} />;

const TableCheckbox = (props: TableCheckboxProps): React.ReactElement => {
    const { variant = 'body' } = props;

    const checkboxClasses = useCheckboxStyles({ variant });

    return (
        <Checkbox
            icon={icon}
            indeterminateIcon={indeterminateIcon}
            checkedIcon={checkedIcon}
            classes={checkboxClasses}
            {...props}
        />
    );
};

export { TableCheckbox };

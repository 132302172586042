import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Container, TextareaAutosize } from '@material-ui/core';
import { ChatBubbleOutline, DeleteOutline, Edit, PersonOutline, Send } from '@material-ui/icons';
import { addElipsisText, useChatBotTko, useDebounce } from '@helpers';
import { Loader } from '@modules/layout/moleculas';
import { AskTkoOptionCard, AskTkoQueryCard, DeleteDialog } from '@components/ask-tko-card';
import AnalyzeIcon from '../../modules/layout/icons/images/analyze-icon.png';
import ConsultIcon from '../../modules/layout/icons/images/consult-icon.png';
import SupportIcon from '../../modules/layout/icons/images/support-icon.png';
import ASKTKO from '../../modules/layout/icons/images/ask-tko.png';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { useCurrentUser } from '@modules/auth/hooks';
import { useAllAskTkoDetails } from '@modules/ask-tko/hooks';
import { useMutation } from '@apollo/client';
import {
    CreateAskTkoDetailMutationType,
    CreateAskTkoDetailMutationVariables,
    DeleteAskTkoDetailMutationType,
    DeleteAskTkoDetailMutationVariables,
} from '@modules/types/graphql';
import {
    CreateAskTkoMutation,
    DeleteAskTkoDetailMutation,
    GetAllAskTkoDetailsQuery,
} from '@modules/ask-tko/graphql';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { v4 as uuidv4 } from 'uuid';
import { ThreeDotLoader } from '@components/three-dot-loader';
import { useMountedState } from 'react-use';
interface TKOContainerProps {
    isVisible: boolean;
}

const TKOContainerStyle = styled.div<TKOContainerProps>`
    display: flex;
`;
export const LeftContainerStyle = styled.div`
    width: 230px;
    background: #bdbdbd57;
    min-height: 80vh;
    transition: width 0.3s ease-in-out;
    padding: 10px;
`;
export const commonStyles = `
    background: #bdbdbd;
    display: flex;
    align-items: center;
    background: hsl(0deg 0% 74.12% / 71%);
    border-radius: 10px;
    padding: 4px 8px;
    color: #0057ff;
    margin-bottom:10px;
    cursor:pointer;
    transition:0.3s;
    :hover{
        background:hsl(0deg 0% 64.12% / 71%)
    }
    h3 {
        margin-left: 7px;
        font-size: 16px;
        color:#000;
    }
    svg {
        font-size: 25px;
    }
`;
const UserProfileStyle = styled.div`
    ${commonStyles}
`;

const SideBarListStyle = styled.div`
    position: relative;
    z-index: 1;
    padding: 8px 2px !important;
    min-width: 220px;
    width: 95% !important;
    border-radius: 0px !important;
    ${commonStyles}
    h3 {
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        margin-right: 4px;
    }
`;
const PreviousChatStyle = styled.div`
    font-size: 14px;
    border-bottom: 0.5px solid hsl(0deg 0% 74.12% / 71%);
    margin-bottom: 10px;
`;

const MainContainerStyle = styled.div`
    flex: 1;
`;

const ChatBotSection = styled.div`
    padding: 30px;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 24px;
    filter: blur(0.5px);
    border-radius: 24px;
    background: linear-gradient(286deg, rgb(54 69 134 / 52%), #b0b5ef 58.85%);
`;

const ChatBotHeader = styled.div`
    margin-bottom: 20px;
    text-align: center;
    img {
        height: 100%;
        max-width: 400px;
        width: 100%;
    }
`;
const ChatDeleteButton = styled.span`
    position: absolute;
    right: 0px;
    top: 5px;
`;
const ChatBotFormSection = styled.form`
    width: 100%;
    position: realtive;
    textarea {
        width: 100%;
        outline: none;
        padding: 10px;
        font-size: 20px;
        border: 2px solid #2657e2;
        border-radius: 16px;
        resize: none;
    }
    svg {
        position: absolute;
        top: 6px;
        color: #2657e2;
        right: 15px;
        font-size: 34px;
        cursor: pointer;
    }
`;
const ChatInputStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* padding-bottom: 20px; */
    position: relative;
    textarea {
        flex-grow: 1;
        padding: 10px 50px 10px 10px;
    }
`;
const ChatBotResponseSection = styled.div`
    transition: 0.5s;
    border-radius: 0px 0px 10px 10px;
    min-height: 300px;
    height: 100% !important;
    padding: 20px;
    p {
        color: #000;
        font-size: 16px;
        line-height: 28px;
    }
`;
const RegenerateBtnStyle = styled.div`
    text-align: center;
    span {
        border: 0.3px solid rgb(140, 141, 149);
        padding: 10px 20px;
        border-radius: 18px;
        font-size: 18px;
        cursor: pointer;
        font-weight: 400;
        background: #2657e2;
        color: #fff;
        display: inline-block;
        margin: 20px;
    }
`;
const ChatTextStyle = styled.p`
    transition: opacity 0.3s ease-in-out;
    background: #f9f9f9;
    padding: 10px;
`;
const Sidebarcontainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    position: relative;

    span {
        position: absolute;
        top: 0;
        right: -7px;
        opacity: 0;
        z-index: 999999;
        background-color: #bbbbbbba;
        padding: 9.5px 4px;
        margin-bottom: 10px;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        cursor: pointer;
        box-shadow: -7px 1px 8px 2px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        svg {
            font-size: 19px;
        }
        &:hover svg {
            fill: crimson;
        }
    }
    &:hover span {
        opacity: 1;
    }
`;

const ChatGptQueryHeading = styled.h2`
    padding: 20px 10px;
    text-transform: capitalize;
    background: #c5c5c5b9;
    margin-bottom: 1px;
    border-radius: 15px 15px 0px 0px;
    border-bottom: 2px solid #a8a8a8;
    font-size: 16px;
    color: #323232;
`;
const ConversationSection = styled.div`
    margin-bottom: 20px;
    border-radius: 15px 15px 0px 0px;
`;

type QueryDataType = string[];
type OptionDataType = {
    id: number;
    optionIcon: string;
    title: string;
    desc: string;
};
type PreviousItemProps = {
    id: number;
    title: string;
    description?: string;
    userId?: number;
    createdAt?: string;
    parentId: number;
    queryId: string;
    isParent?: boolean;
};

const AskTKO = (): React.ReactElement => {
    const [query, setQuery] = useState<string>('');
    const [previousQuery, setPreviousQuery] = useState<any>('');
    const [title, setTitle] = useState<string>('');
    const [queryIds, setQueryIds] = useState<string>('');
    const [askTkoData, setAskTkoData] = useState<string[] | any>([]);
    const [showResponse, setShowResponse] = useState<boolean>(false);
    const [isParent, setIsParent] = useState<boolean>(true);
    const [deleteChatLoading, setDeleteChatLoading] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [logicExecuted, setLogicExecuted] = useState<boolean>(false);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const debounceQuery = useDebounce(query, 500);
    const { currentUser } = useCurrentUser();
    const userName = currentUser?.getFullName();
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const queryId = uuidv4();
    const isMounted = useMountedState();

    const askTkoOptionData: OptionDataType[] = [
        {
            id: 1,
            optionIcon: AnalyzeIcon,
            title: 'Assess',
            desc: '​',
        },
        {
            id: 2,
            optionIcon: ConsultIcon,
            title: 'Detect',
            desc: '​',
        },
        {
            id: 3,
            optionIcon: SupportIcon,
            title: 'Mitigate',
            desc: '​',
        },
    ];
    const askTkoCardQueryData: QueryDataType = [
        '“What are the best practices for conducting a NIST CSF assessment?”',
        '“What are the top five detection rules that should be created in Splunk to detect exfiltration of sensitive data?”',
        '“What are some best practices for remediating a compromise of sensitive information?”',
        '“What are some common cybersecurity assessment models?”​',
        '“What are the best practices for detecting privileged escalation in a distributed network”​',
        '“What steps should be taken to mitigate the threat of an employee taking sensitive data upon termination?”​',
        '“What are the common compliance requirements for public companies?”',
        '“Who are the top SIEM vendors?”',
        '“What are the current SEC requirements for breach notifications?”',
    ];

    const [response, loading, error, fetchChatTko] = useChatBotTko();
    const { askTkoAllDetails, loading: askTkoDetailLoading } = useAllAskTkoDetails();
    const [createAskTkoDetail] = useMutation<
        CreateAskTkoDetailMutationType,
        CreateAskTkoDetailMutationVariables
    >(CreateAskTkoMutation);
    const [deleteAskTkoDetail] = useMutation<
        DeleteAskTkoDetailMutationType,
        DeleteAskTkoDetailMutationVariables
    >(DeleteAskTkoDetailMutation);

    /**
     * @author : Zahid khan
     * @param chatId : by chatId delete the  previuos chat
     */

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleDeletePrevChat = async (chatId: number): Promise<void> => {
        setDeleteChatLoading(true);
        try {
            const { data: deleteAskData } = await deleteAskTkoDetail({
                variables: { tkoId: chatId },
                refetchQueries: [{ query: GetAllAskTkoDetailsQuery }],
                awaitRefetchQueries: true,
            });

            if (deleteAskData?.deleteAskTkoDetail?.id) {
                enqueueSuccess('Chat successfully deleted!');
                handleCloseDialog();
                setDeleteChatLoading(false);
            } else {
                enqueueError('An error occurred while deleting the chat!');
                setDeleteChatLoading(false);
            }
        } catch (error) {
            console.log(error);
        } finally {
            if (isMounted()) {
                setDeleteChatLoading(false);
            }
        }
    };

    /**
     * @author : Zahid Khan
     * @param queryId : by querId get again query in input field
     */

    const getQueryData = (queryId: number) => {
        let query: any = askTkoCardQueryData?.find((ele: any, id: number) => id === queryId);
        setQuery(query);
    };

    const handleNewChat = () => {
        setAskTkoData([]);
        setQuery('');
        localStorage.removeItem('parentId');
        setIsParent(true);
    };

    /**
     * @author : Zahid Khan
     * @param data : get data and handle with database
     */

    const handleSearchPreviousQuery = (isParent: boolean | any, parentId: any) => {
        const newData = askTkoAllDetails
            ?.filter((item: any) => item?.id === parentId || item.parentId === parentId)
            .map((ele: any) => ele);

        setAskTkoData(newData);
        setTitle(title);
        setQuery('');
        setIsParent(false);
        setShowResponse(true);
        localStorage.removeItem('parentId');
        localStorage.setItem('parentId', String(isParent ? parentId : 0));
        let newPrompt: any = '';
        newPrompt = newData.map((ele: any) => '\n' + ele.description.replace(/\[|\]/g, ''));
        setPreviousQuery(newPrompt);
    };

    /**
     * @author : Zahid khan
     * @param event : Form event
     * @async : promises
     * @HandleSubmit : Generate Chat Gpt Response
     */

    const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event?.preventDefault();
        await fetchChatTko(previousQuery === '' ? debounceQuery : debounceQuery + previousQuery);
        setQuery('');
        setTitle(query);
    };

    const onEnterPress = (e: KeyboardEvent | any) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            handleSubmit();
        }
    };

    /**
     * @author : Zahid Khan
     * @function : regenerate chat gpt response
     */

    async function handleRegenerate() {
        await handleSubmit();
    }

    const storedValue = localStorage.getItem('parentId');
    const parentId: number = storedValue ? parseInt(storedValue, 10) : 0;

    useEffect(() => {
        if (response && response.length > 0 && !logicExecuted) {
            setShowResponse(true);
            const description = response.map((ele: any) => ele);
            (async () => {
                const { data: createAskTkoDetailData } = await createAskTkoDetail({
                    variables: {
                        queryId: queryId,
                        title: query,
                        parentId: parentId || 0,
                        isParent: isParent,
                        description: description,
                    },
                    refetchQueries: [{ query: GetAllAskTkoDetailsQuery }],
                    awaitRefetchQueries: true,
                });
                const dataRes = createAskTkoDetailData?.createAskTkoDetail;

                setAskTkoData([...askTkoData, dataRes]);
                if (dataRes?.id) {
                    let newPrompt: any = '';
                    newPrompt = dataRes?.description;
                    setPreviousQuery(newPrompt);
                    setIsParent(false);
                    setLogicExecuted(false);
                    localStorage.setItem(
                        'parentId',
                        String(dataRes.is_parent ? dataRes?.id : dataRes?.parent_id),
                    );
                }
            })();
        }
    }, [response, logicExecuted]);

    useEffect(() => {
        const removeLocalStorageItem = () => {
            localStorage.removeItem('parentId');
        };

        window.addEventListener('beforeunload', removeLocalStorageItem);

        return () => {
            window.removeEventListener('beforeunload', removeLocalStorageItem);
        };
    }, [response]);

    if (askTkoDetailLoading) {
        return <Loader />;
    }

    return (
        <>
            <TKOContainerStyle isVisible={isVisible}>
                {isVisible && (
                    <LeftContainerStyle>
                        <UserProfileStyle>
                            <PersonOutline /> <h3>{userName}</h3>
                        </UserProfileStyle>
                        <UserProfileStyle onClick={() => handleNewChat()}>
                            <Edit /> <h3>New Chat</h3>
                        </UserProfileStyle>
                        <PreviousChatStyle>Previous Chats</PreviousChatStyle>
                        {askTkoAllDetails &&
                            askTkoAllDetails?.map((ele: PreviousItemProps) =>
                                ele?.isParent ? (
                                    <Sidebarcontainer key={ele?.id}>
                                        <SideBarListStyle
                                            onClick={() =>
                                                handleSearchPreviousQuery(ele?.isParent, ele?.id)
                                            }
                                        >
                                            <ChatBubbleOutline /> <h3>{ele?.title}</h3>
                                        </SideBarListStyle>
                                        <span onClick={() => handleOpenDialog()}>
                                            <DeleteOutline />
                                        </span>
                                        <DeleteDialog
                                            isOpen={isDialogOpen}
                                            chatId={ele?.id}
                                            chatLoading={deleteChatLoading}
                                            title='Delete chat?'
                                            content={addElipsisText(ele?.title, 80)}
                                            onCancel={handleCloseDialog}
                                            onDelete={handleDeletePrevChat}
                                        />
                                    </Sidebarcontainer>
                                ) : (
                                    ''
                                ),
                            )}
                    </LeftContainerStyle>
                )}
                <MainContainerStyle>
                    <Button onClick={() => setIsVisible(!isVisible)}>
                        {isVisible ? <ArrowForwardIos /> : <ArrowBackIos />}
                    </Button>
                    <Container>
                        <ChatBotHeader>
                            <img src={ASKTKO} alt='no images' />
                        </ChatBotHeader>

                        {askTkoData.length > 0 ? (
                            <>
                                <ChatBotResponseSection>
                                    <div>
                                        {showResponse &&
                                            askTkoData?.map((ele: any, id: number) => {
                                                const dataArray = ele?.description
                                                    .substring(1, ele?.description.length - 1)
                                                    .split(', , ');
                                                return (
                                                    <ConversationSection key={id}>
                                                        <ChatGptQueryHeading>
                                                            {ele?.title}
                                                        </ChatGptQueryHeading>
                                                        {dataArray?.map((desc: any, id: number) => (
                                                            <ChatTextStyle key={id}>
                                                                {desc}
                                                            </ChatTextStyle>
                                                        ))}
                                                    </ConversationSection>
                                                );
                                            })}
                                    </div>
                                </ChatBotResponseSection>
                            </>
                        ) : (
                            <>
                                <AskTkoOptionCard askTkoOptionData={askTkoOptionData} />
                                <AskTkoQueryCard
                                    askTkoCardQueryData={askTkoCardQueryData}
                                    handleQuery={getQueryData}
                                />
                            </>
                        )}
                        <ChatBotSection>
                            <ChatBotFormSection onSubmit={handleSubmit}>
                                <ChatInputStyle>
                                    <TextareaAutosize
                                        color='neutral'
                                        placeholder='Search...'
                                        value={query}
                                        onKeyDown={onEnterPress}
                                        onChange={e => setQuery(e.target.value)}
                                    />
                                    {loading ? (
                                        <ThreeDotLoader />
                                    ) : query.trim() !== '' ? (
                                        <span onClick={(e: any) => handleSubmit(e)}>
                                            <Send />
                                        </span>
                                    ) : (
                                        <Send style={{ color: '#4c5f97' }} />
                                    )}
                                </ChatInputStyle>
                            </ChatBotFormSection>
                        </ChatBotSection>
                    </Container>
                </MainContainerStyle>
            </TKOContainerStyle>
        </>
    );
};

export { AskTKO };

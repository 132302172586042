import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Form } from 'formik';
import { useNavigate } from '@reach/router';

import { Autocomplete, TextField, Button } from '@modules/ui/core';
import { routes } from '@config/routes';
import { ExtendedFormik } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';
import { useLearnTkoMenuList } from '@modules/learn-tko/hooks';
import { TextEditor } from '@components/text-editor';
import { Modal } from '@material-ui/core';
import { Loader } from '@modules/layout/moleculas';

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
    margin-top: 20px;
`;

type LearnTkoFormProps = {
    type: string;
    initialValues: any;
    FormWrapComponent: any;
    setEditorDescription: React.Dispatch<React.SetStateAction<string>>;
    onSubmit: (values: any) => void;
    editorDescription: string;
    loading: boolean;
};

const LearnTkoForm = (props: LearnTkoFormProps): React.ReactElement => {
    const {
        type,
        initialValues,
        FormWrapComponent,
        editorDescription,
        onSubmit,
        setEditorDescription,
        loading,
    } = props;
    const [toggleEditor, setToggleEditor] = useState<boolean>(false);
    const navigate = useNavigate();

    const { learnTkoMenuList, loading: learnTkoMenuLoading } = useLearnTkoMenuList();

    const handleClickCancel = (): void => {
        navigate(routes.viewLearnTko.path);
    };
    const isEdit = type === 'edit';

    const menuOptions = learnTkoMenuList.map(item => item.menu);

    if (loading || learnTkoMenuLoading) {
        return <Loader />;
    }

    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={() => {
                let rules: Record<string, any> = {};

                if (!isEdit) {
                    rules = {
                        ...rules,
                        menu: yup.string().nullable().required('Required field'),
                        title: yup.string().required('Required field'),
                        // description: yup.string().required('Required field'),
                        // video_url: yup.string().required('Required field'),
                    };
                }

                return yup.object(rules);
            }}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {formikProps => {
                const {
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    isSubmitting,
                } = formikProps;
                const handleChangeAutocomplete = (field: string) => (
                    _: React.ChangeEvent<{}>,
                    value: any | null,
                ) => setFieldValue(field, value);

                return (
                    <FormWrapComponent>
                        <StyledForm>
                            <Autocomplete
                                fullWidth
                                id='menu'
                                label='Menu'
                                options={menuOptions}
                                value={values.menu}
                                getOptionLabel={(option: any) => option}
                                onChange={(event, value) =>
                                    handleChangeAutocomplete('menu')(event, value)
                                }
                                renderOption={option => (
                                    <React.Fragment>
                                        <span>{option}</span>
                                    </React.Fragment>
                                )}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label='Menu'
                                        error={!!errors.menu}
                                        helperText={errors.menu}
                                        placeholder='Select a menu'
                                    />
                                )}
                            />
                            <TextField
                                fullWidth
                                id='title'
                                name='title'
                                type='text'
                                label='Title'
                                placeholder='Enter your Title'
                                value={values.title}
                                error={!!errors.title}
                                helperText={errors.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <Button
                                variant={editorDescription.length > 0 ? 'contained' : 'outlined'}
                                onClick={() => setToggleEditor((prevState: boolean) => !prevState)}
                            >
                                {!isEdit ? `Create` : 'Edit'} Content
                            </Button>

                            <Modal
                                title={'Content'}
                                open={toggleEditor}
                                onClose={() => setToggleEditor((prevState: boolean) => !prevState)}
                            >
                                <TextEditor
                                    setEditorDescription={setEditorDescription}
                                    editorDescription={editorDescription}
                                    handleClose={() =>
                                        setToggleEditor((prevState: boolean) => !prevState)
                                    }
                                />
                            </Modal>

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    {!isEdit ? 'Create Learn Tko' : 'Update Learn Tko'}
                                </SubmitButton>

                                <SubmitButton variant='text' onClick={handleClickCancel}>
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    </FormWrapComponent>
                );
            }}
        </ExtendedFormik>
    );
};

export { LearnTkoForm };

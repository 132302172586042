import type { AddCustomGapMutationVariables } from '@modules/types/graphql';

export type Values = {
    gap: string;
    capabilityLevelId: number;
    surveyId: number;
    isAiGaps: boolean;
    projectId: number;
};

class AddCustomGapRequest {
    capabilityLevelId: AddCustomGapMutationVariables['capabilityLevelId'];
    gap: AddCustomGapMutationVariables['gap'];
    surveyId: AddCustomGapMutationVariables['surveyId'];
    isAiGaps: AddCustomGapMutationVariables['isAiGaps'];
    projectId: AddCustomGapMutationVariables['projectId'];

    constructor(values: Values) {
        this.gap = values.gap;
        this.capabilityLevelId = values.capabilityLevelId;
        this.surveyId = values.surveyId;
        this.projectId = values.projectId;
        this.isAiGaps = values.isAiGaps;
    }
}

export { AddCustomGapRequest };

import { EntityList } from '@lib/entity';

import type { Entity } from './user-high-role-entity';
import { UserHighRoleEntity } from './user-high-role-entity';

class UserHighRoleEntityList extends EntityList<UserHighRoleEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(UserHighRoleEntity, items, count);
    }

    // getRoles() {
    //     return this.entities;
    // }

    // hasRoles() {
    //     const result = this.count !== 0;

    //     return result;
    // }
}

export { UserHighRoleEntityList };

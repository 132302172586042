import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Card,
  CardHeader,
  CardActions,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@material-ui/core';
import { Button } from '@modules/ui/core';
import { Person, Edit } from '@material-ui/icons';
import {ChangeAssessmentUserModal} from '@modules/assessment/change-assessment-user-modal';
import {Role, User} from '@modules/types/graphql';
import {usePropState} from '@modules/shared/hooks/use-prop-state';

const Main = styled.div`
    & > div {
      width: 100%;
    }

    h4 {
        font-weight: 600;
        font-size: 18px;
    }

    .is-user {
        p,
        span {
            font-size: 14px;
        }

        svg {
            font-size: 26px;
        }
    }

    .is-users-list {
        max-height: 145px;
        overflow-y: auto;
    }
`;


export type UserListCardProps = {
  title: string;
  projectId: number;
  isEditAllowed: boolean;
  userList: {id: number; fullName: string; role: Role}[],
};

export const UserListCard = (props: UserListCardProps): React.ReactElement => {
  const { title, projectId, isEditAllowed, userList: userListRaw } = props;
  const [openRiskExecutivesModal, setOpenRiskExecutivesModal] = useState<boolean>(false);

  const [userList, setUserList] = usePropState(userListRaw);

  function onUserChange(user: User): void {
    userList[0] = {
      id: user.id,
      fullName: (user.first_name + ' ' + user.last_name).trim(),
      role: userList[0].role
    };
    setUserList([...userList]);
  }

  return (
    <>
      <Main>
        <Card>
          <CardHeader
            style={{ paddingBottom: 0 }}
            title={
              <Typography variant='h4' style={{ marginTop: -10 }}>
                {title}
              </Typography>
            }
            action={
              <CardActions>
                <Button
                  size='extra-small'
                  variant='outlined'
                  onClick={() => setOpenRiskExecutivesModal(true)}
                >
                  <Edit style={{ margin: '5px 5px 0 0' }} />
                  Edit
                </Button>
              </CardActions>
            }
          />
          <List className='is-users-list'>
            {userList.map((executive) => (
              <ListItem key={executive?.id} className='is-user'>
                <ListItemAvatar>
                  <Avatar>
                    <Person />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    executive?.fullName
                  }
                  secondary={executive?.role.name}
                />
              </ListItem>
            ))}
          </List>
        </Card>
      </Main>
      {isEditAllowed && (
        <ChangeAssessmentUserModal
          title={title}
          open={openRiskExecutivesModal}
          onClose={() => setOpenRiskExecutivesModal(false)}
          onUserChange={onUserChange}
          initialValues={{
            projectId: projectId,
            roleId: userList[0]?.role?.id,
            riskExecutives: [userList[0]?.id],
          }}
        >
          <></>
        </ChangeAssessmentUserModal>
      )}
    </>
  );
};

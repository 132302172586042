import React, {useMemo} from 'react';

import { ListStyled } from '@modules/layout/styled';
import {
  Collapse,
} from '@material-ui/core';
import {UserEntity} from '@modules/users/entities';
import {RecommendationAssessment, RecommendationAssessmentRoadmapReport} from '@modules/roadmap/recommendation-assessment-roadmap-report';
import {RoadmapRecommendationEntity} from '@modules/roadmap/entities';
import {RoadmapHistory} from '@modules/roadmap/organisms/roadmap-recommendations-list/roadmap-history';


type ReportComponentInnerItemProps = {
  recommendationExtra: RecommendationAssessmentRoadmapReport;
  openComponent: boolean;
};

export const ReportPrioritizedRoadmapInnerItem = (props: ReportComponentInnerItemProps): React.ReactElement => {
  const { recommendationExtra, openComponent } = props;
  const assessment = recommendationExtra.componentAssessment;

  return (
    <Collapse in={openComponent} timeout="auto" unmountOnExit>
      <ListStyled.Container>
        <div className={'container-flex'}>
          <div className={'col-info'}>
            {assessment.componentOwners.length ? (
              <>
                <h3>Component owner:</h3>
                <p className={'text-offset'}>{assessment.componentOwnersToString()}</p>
              </>
            ) : null}

            {assessment.hasOperator() ? (
              <>
                <h3>Operator:</h3>
                {assessment.operators.map((operator: UserEntity) => (
                  <p className={'text-offset'}>{operator.getFullName()}</p>
                ))}
              </>
            ) : null}

            <p className={'text-offset'}><b>Status:</b> {assessment.status ?? ''}</p>
            <p className={'block-offset'}><b>Weight:</b> {assessment.weight ?? ''}</p>
          </div>

          <RoadmapHistory roadmapRecommendation={recommendationExtra.componentRecommendation.recommendation} />
        </div>
      </ListStyled.Container>
    </Collapse>
  );
};

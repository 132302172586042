import { gql } from '@apollo/client';

export const GetCreateUserRolesQuery = gql`
    query GetCreateUserRoles($role: String) {
        getCreateUserRoles(role: $role) {
            id
            name
        }
    }
`;

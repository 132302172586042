import React, { useContext } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { Button } from '@modules/ui/core';
import { ListItemStyled } from '@modules/layout/styled';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { AccordionListItem } from '@modules/layout/organisms';
import { useProjectRoles } from '@modules/projects/hooks';
import { ProjectMaturityLevel } from '@modules/projects/moleculas';
import { AssessmentCapabilitiesList } from '@modules/assessment/organisms';
import {
    GetComponentAssessmentQuery,
    ApproveComponentAssessmentMutation,
} from '@modules/assessment/graphql';

import type {
    ApproveComponentAssessmentMutationType,
    ApproveComponentAssessmentMutationVariables,
} from '@modules/types/graphql';
import type { AssessmentComponentsListItemProps } from './assessment-components-list-item';
import { ProjectImpactMaturityLevel } from '@modules/projects/moleculas/project-maturity-level';
import { AuthContext } from '@modules/auth/context';

const StyledAssessmentCapabilitiesList = styled(AssessmentCapabilitiesList)`
    margin-top: 24px;
`;

const SummaryActions = styled(ListItemStyled.SummaryInfo)`
    min-width: 135px;
    text-align: right;
`;
const AssessmentComponentsListAssessedItem = (
    props: AssessmentComponentsListItemProps,
): React.ReactElement => {
    const { project, componentAssessment, open, onExpand } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { projectRolesEntityList } = useProjectRoles({ variables: { projectId: project.id } });

    const hasPmRole = projectRolesEntityList.hasProgramManager();
    const context = useContext(AuthContext);

    const [
        approveComponentAssessment,
        { loading: approveComponentAssessmentLoading },
    ] = useMutation<
        ApproveComponentAssessmentMutationType,
        ApproveComponentAssessmentMutationVariables
    >(ApproveComponentAssessmentMutation);

    const handleSubmitApproveComponentAssessment = async (
        componentAssessmentData: any,
    ): Promise<void> => {
        let componentAssessmentGapsCheck = componentAssessmentData.capabilities;
        let filterComponentAssessmentGaps = componentAssessmentGapsCheck.filter(
            (withDate: any) => withDate.assessedAt !== '',
        );
        let gapsLength = filterComponentAssessmentGaps.map(
            (gapsLength: any) => gapsLength.completionLevel?.gaps.length,
        );

        const isGapsAvalible = (gapsLengthValue: number) => gapsLengthValue > 0;

        let gapCountStatus = gapsLength.some(isGapsAvalible);

        if (project?.isrrimpact || gapCountStatus === true) {
            const { data: approveComponentAssessmentData } = await approveComponentAssessment({
                variables: { assessmentId: componentAssessment.id },
                refetchQueries: [
                    {
                        query: GetComponentAssessmentQuery,
                        variables: { id: componentAssessment.id },
                    },
                ],
                awaitRefetchQueries: true,
            });
            if (approveComponentAssessmentData?.approveComponentAssessment?.id) {
                enqueueSuccess('Component successfully approved!');
            } else {
                enqueueError('An error occurred while approving the component!');
            }
        } else {
            enqueueError('Add minimum one gap before they can approve.');
        }
    };

    const hasOperator = componentAssessment.hasOperator();
    const projectInProgress = project.isAssessment();
    const maturityLevel = componentAssessment.getMaturityLevel();
    const impactmaturityLevel = componentAssessment.getImpactMaturityLevel();

    return (
        <AccordionListItem
            title={componentAssessment.component.name}
            subtitle={componentAssessment.objectives}
            expanded={open}
            onChange={onExpand(componentAssessment.id)}
            SummaryInfoComponents={
                <>
                    <ListItemStyled.SummaryInfo>
                        Weight: {componentAssessment.weight}
                    </ListItemStyled.SummaryInfo>

                    <ListItemStyled.SummaryInfo>
                        {project?.isrrimpact ? 'Factors' : 'Capabilities'} :{' '}
                        {componentAssessment.getAccessesCapabilities()}
                    </ListItemStyled.SummaryInfo>

                    <SummaryActions>
                        {maturityLevel ? (
                            project?.isrrimpact ? (
                                <ProjectImpactMaturityLevel current={impactmaturityLevel} />
                            ) : (
                                <ProjectMaturityLevel current={maturityLevel} />
                            )
                        ) : null}
                    </SummaryActions>
                </>
            }
        >
            <ListItemStyled.Details>
                {hasOperator ? (
                    <ListItemStyled.DetailsInfo>
                        <strong>Operators:</strong> {componentAssessment.operatorsToString()}
                    </ListItemStyled.DetailsInfo>
                ) : null}

                <ListItemStyled.DetailsInfo>
                    Component owner: {componentAssessment.componentOwnersToString()}
                </ListItemStyled.DetailsInfo>
            </ListItemStyled.Details>

            <StyledAssessmentCapabilitiesList
                project={project}
                componentAssessment={componentAssessment}
            />

            {hasPmRole && !componentAssessment.isApproved() && projectInProgress ? (
                <ListItemStyled.DetailsToolbar>
                    <Button
                        loading={approveComponentAssessmentLoading}
                        disabled={context?.isFreeze}
                        onClick={() => handleSubmitApproveComponentAssessment(componentAssessment)}
                    >
                        Approve component assessment
                    </Button>
                </ListItemStyled.DetailsToolbar>
            ) : null}
        </AccordionListItem>
    );
};

export { AssessmentComponentsListAssessedItem };

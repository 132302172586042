import { gql } from '@apollo/client';
import { AskTkoDetailFragment } from '../fragments';

export const GetAskTkoDetailQuery = gql`
    query GetAskTkoDetail($tkoId: Int!) {
        getAskTkoDetail(tko_id: $tkoId) {
            ...AskTkoDetails
        }
    }
    ${AskTkoDetailFragment}
`;

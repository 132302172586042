import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Link } from '@reach/router';

import { turquoise } from '@modules/ui/colors';
import { getMenuRoutes } from '@lib/routing';
import { RouteMenuLocationEnum } from '@config/routes';
import { useCurrentUser } from '@modules/auth/hooks';
import { Sidebar } from '@modules/layout/organisms';

import type { LinkGetProps } from '@reach/router';
import type { SidebarProps } from '@modules/layout/organisms';

type SidebarMenuProps = Omit<SidebarProps, 'direction'>;

const listItemActiveStyles = {
    backgroundColor: `${rgba(turquoise[100], 0.1)}`,
    pointerEvents: 'none',
    cursor: 'default',
};

const Title = styled.div`
    padding: 0 30px;
    color: ${turquoise[100]};
    font-size: 2.8rem;
    font-weight: 700;
`;

const List = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 64px;
`;

const ListItem = styled(Link)`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 8px 24px;
    color: ${turquoise[100]};
    background-color: transparent;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        background-color: ${rgba(turquoise[100], 0.1)};
    }
` as Link<{}>;

const ListItemContent = styled.p`
    width: calc(100% - 48px);
    margin-left: 16px;
    font-size: 1.4rem;
`;

const SidebarMenu = (props: SidebarMenuProps): React.ReactElement => {
    const { onClose, ...otherProps } = props;

    const { currentUser } = useCurrentUser();

    const menuRoutes = getMenuRoutes(RouteMenuLocationEnum.sidebar, {
        isAdmin: currentUser.isAdmin,
    });

    const isCurrent = React.useCallback(
        (linkProps: LinkGetProps) => ({ style: linkProps.isCurrent ? listItemActiveStyles : {} }),
        [],
    );

    const handleClickLink = (event: React.BaseSyntheticEvent): void => onClose(event);

    return (
        <Sidebar direction='left' onClose={onClose} {...otherProps}>
            <Title>zendesk</Title>

            {menuRoutes.length ? (
                <List>
                    {menuRoutes.map(menuRoute => {
                        return (
                            <ListItem
                                key={menuRoute.name}
                                to={menuRoute.path}
                                getProps={isCurrent}
                                onClick={handleClickLink}
                            >
                                <ListItemContent>{menuRoute.title}</ListItemContent>
                            </ListItem>
                        );
                    })}
                </List>
            ) : null}
        </Sidebar>
    );
};

export { SidebarMenu };

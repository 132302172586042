import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { RiskGroup } from '@modules/types/graphql';

export type Entity = Partial<RiskGroup>;

@DecribeEntity('RiskGroupEntity')
class RiskGroupEntity extends BaseEntity {
    id: number;
    name: string;
    risk_function_id: number;

    constructor(riskGroup: Entity) {
        super(riskGroup);

        this.id = riskGroup?.id ?? -1;
        this.name = riskGroup?.name ?? '';
        this.risk_function_id = riskGroup?.risk_function_id ?? -1;
    }
}

export { RiskGroupEntity };

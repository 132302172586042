import { CapabilityReportEntity, ComponentReportEntity } from '@modules/assessment/entities';
import { InnerListStyled, ListStyled } from '@modules/layout/styled';
import React, { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
    gapLevelFieldLabelList,
    gapLevelFieldList,
} from '@modules/assessment/entities/capability-report-entity';
import { GapByRisk, GapCapabilityAssessment } from '@modules/assessment/gap-by-risk';
import { useComponentGapPieChartOptions } from '@modules/assessment/organisms/report-components-list/report-general-chart-data';
import {
    RecommendationByRisk,
    RecommendationCapabilityAssessment,
} from '@modules/roadmap/recommendation-by-risk';
import { projectLevelColors } from '@modules/projects/constants';
import { ProjectEntity } from '@modules/projects/entities';

type ReportComponentCapabilitiesListProps = {
    componentReport?: ComponentReportEntity;
    recordCapabilityList?: GapCapabilityAssessment[] | RecommendationCapabilityAssessment[];
    levelFields: string[];
    levelFieldLabels: string[];
    mode: 'Gap' | 'Recommendation';
    project?: ProjectEntity;
};

export const ReportComponentDataGraph = (
    props: ReportComponentCapabilitiesListProps,
): React.ReactElement => {
    const {
        componentReport,
        recordCapabilityList,
        levelFields,
        levelFieldLabels,
        mode,
        project,
    } = props;

    let capabilityList: GapByRisk[] | RecommendationByRisk[] = [];
    if (recordCapabilityList && recordCapabilityList.length) {
        capabilityList =
            mode === 'Gap'
                ? [
                      GapByRisk.createGapByRiskFromList(
                          recordCapabilityList as GapCapabilityAssessment[],
                      ),
                  ]
                : [
                      RecommendationByRisk.createRecommendationByRiskFromList(
                          recordCapabilityList as RecommendationCapabilityAssessment[],
                      ),
                  ];
    } else if (componentReport && componentReport?.capabilities.length) {
        capabilityList = (componentReport.capabilities as unknown) as GapByRisk[];
    }

    let hasData = false;
    const gapCountByRisk: Array<[number, string]> = levelFields.map((field, fieldIndex) => {
        let gapCount =
            (capabilityList as any).reduce((subCount: number, entity: any): number => {
                return subCount + entity[field].length;
            }, 0) ?? 0;

        hasData = hasData || Boolean(gapCount);

        return [gapCount, levelFieldLabels[fieldIndex]];
    });

    let backgroundColor = Object.values(projectLevelColors);
    const pieChartData = useMemo(
        () => ({
            labels: gapCountByRisk.map(([gapCount, label]) => label),
            datasets: [
                {
                    hoverOffset: 4,
                    data: gapCountByRisk.map(([gapCount, label], index) => gapCount),
                    labels: gapCountByRisk.map(([gapCount, label]) => label),
                    backgroundColor: backgroundColor,
                    borderColor: backgroundColor,
                },
            ],
        }),
        undefined,
    );

    const pieChartOptions: any = useComponentGapPieChartOptions();

    return (
        <>
            {hasData && (
                <ListStyled.PieChartComponentInfo>
                    {project?.isrrimpact ? (
                        <h3>Component Factors by Impact Level</h3>
                    ) : (
                        <h3>Component {mode}s by Risk Level</h3>
                    )}
                    <Doughnut options={pieChartOptions} data={pieChartData} />
                </ListStyled.PieChartComponentInfo>
            )}
        </>
    );
};

import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';

import { Button, Autocomplete } from '@modules/ui/core';
import { FormStyled } from '@modules/layout/styled';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { Form, ExtendedFormik } from '@modules/layout/organisms';
import { useUsers } from '@modules/users/hooks';
import { useComponentAssessment } from '@modules/assessment/hooks';
import { AssignAssessmentToOperatorRequest } from '@modules/assessment/requests';
import {
    GetComponentAssessmentQuery,
    AssignAssessmentCapabilityToOperatorMutation,
} from '@modules/assessment/graphql';

import type { UserListEntity } from '@modules/users/entities';
import type { AssignAssessmentToOperatorFormValues } from '@modules/assessment/requests';
import type {
    AssignAssessmentToOperatorMutationType,
    AssignAssessmentToOperatorMutationVariables,
} from '@modules/types/graphql';
import { AssignCapabilitiesListFormGroup } from '@modules/assessment/organisms/assign-assessment-to-operator-form/assign-capabilities-list-form-group';
import { ProjectEntity } from '@modules/projects/entities';

type AssignAssessmentToOperatorFormProps = Pick<FormProps, 'FormWrapComponent'> & {
    componentAssessmentId: number;
    project: ProjectEntity;
    onCancel: () => void;
};

const StyledForm = styled(Form)`
    width: 100%;

    > *:not(:last-of-type) {
        margin-bottom: 24px;
    }
`;

const AssignAssessmentToOperatorForm = (props: AssignAssessmentToOperatorFormProps) => {
    const { componentAssessmentId, project, FormWrapComponent, onCancel } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { users, loading: usersLoading } = useUsers();

    const { componentAssessment, loading: componentAssessmentLoading } = useComponentAssessment({
        variables: { id: componentAssessmentId },
    });

    const [assignSurveyToOperator] = useMutation(AssignAssessmentCapabilityToOperatorMutation);

    const initialValues: AssignAssessmentToOperatorFormValues = {
        componentAssessmentId,
        component: componentAssessment,
        operator:
            componentAssessment.operators.length !== 0 ? componentAssessment.operators[0] : null,
        capabilities: [],
    };

    const getOperatorOptionLabel = React.useCallback(option => option?.getFullName() ?? '-', []);
    const hasOperator = componentAssessment.hasOperator();

    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                operator: yup.mixed().required('Required field'),
            })}
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    if (!values?.capabilities?.length) {
                        return;
                    }

                    await Promise.all(
                        values?.capabilities?.map(async (capability: any, i) => {
                            const { data } = await assignSurveyToOperator({
                                variables: {
                                    assessmentId: capability.capability_id,
                                    userId: values.operator?.id,
                                },
                                refetchQueries: [
                                    {
                                        query: GetComponentAssessmentQuery,
                                        variables: { id: componentAssessmentId },
                                    },
                                ],
                                awaitRefetchQueries: true,
                            });

                            if (data?.assignSurveyToOperator?.id) {
                                if (values?.capabilities?.length === i + 1) {
                                    enqueueSuccess('Operator successfully assigned!');
                                    onCancel();
                                }
                            } else {
                                enqueueError('An error occurred while assigning the operator!');
                            }
                        }),
                    );
                } catch (e) {
                    console.log('Assign assessment to operator form [Error]:', e);
                    throw e;
                }
            }}
        >
            {formikProps => {
                const { values, errors, setFieldValue, isSubmitting } = formikProps;

                const handleChangeOperator = (
                    _: React.ChangeEvent<{}>,
                    value: UserListEntity | null,
                ) => setFieldValue('operator', value);

                return (
                    <FormWrapComponent>
                        <StyledForm>
                            <FormStyled.FormRow>
                                <Autocomplete<UserListEntity | null, false, false, false>
                                    fullWidth
                                    id='operator'
                                    label='Operator'
                                    placeholder='Choose the operator'
                                    options={users}
                                    value={values.operator}
                                    error={!!errors.operator}
                                    helperText={errors.operator}
                                    disabled={usersLoading || componentAssessmentLoading}
                                    getOptionLabel={getOperatorOptionLabel}
                                    onChange={handleChangeOperator}
                                />
                            </FormStyled.FormRow>

                            <FormStyled.FormRow>
                                <h2>Capabilities</h2>
                                <AssignCapabilitiesListFormGroup project={project} />
                            </FormStyled.FormRow>

                            <FormStyled.FormRowSubmit>
                                <Button type='submit' loading={isSubmitting}>
                                    {hasOperator ? 'Reassign operator' : 'Assign operator'}
                                </Button>

                                <Button variant='outlined' onClick={onCancel}>
                                    Cancel
                                </Button>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    </FormWrapComponent>
                );
            }}
        </ExtendedFormik>
    );
};

export { AssignAssessmentToOperatorForm };

import * as React from 'react';
import {
    TableCell,
    TableBody,
    TableHead,
    TableContainer,
    TableSortLabel,
    Table as MuiTable,
    makeStyles,
} from '@material-ui/core';
import styled from 'styled-components';
import { TableCellText } from '@modules/layout/atoms';
import { Loader } from '@modules/layout/moleculas';
import { addElipsisText, useDebounce } from '@helpers';
import { TableRow } from './table-row';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { LearnTkoItemActions } from '@modules/learn-tko/organisms';
const useTableStyles = makeStyles({
    root: {
        borderCollapse: 'separate',
        borderSpacing: '0 4px',
        width: '100%',
    },
});

const FileCopyIconStyle = styled(FileCopyIcon)`
    font-size: 17px;
`;

const TextFieldStyle = styled.div`
    font-size: 13px;
    margin: 10px -6px -10px -6px;
    input {
        padding: 0px 4px;
        border-right: transparent !important;
        border-top: transparent !important;
        border-left: transparent !important;
        border: 0.3px solid #a7a7a7;
    }

    input:focus {
        outline: none !important;
        border-bottom: 1px solid #605c5c;
    }
`;

interface Data {
    key: string;
    value: string;
}

type Order = 'asc' | 'desc';

export type TableColumn = {
    id: string;
    label?: string;
    align?: 'left' | 'center' | 'right';
    width?: number;
};

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    columns: TableColumn[];
    onChangeHandle: (e: React.ChangeEvent<HTMLInputElement>, columnId: any) => void;
}

interface handleClickProps {
    learnTkoAllDetails: any;
    learnTkoLoading: boolean;
    canUserCopy: boolean;
    openModal: () => void;
    handleDeleteVideo: (learnTkoId: number) => Promise<void>;
    columns: TableColumn[];
}

export function EnhancedTableHead(props: EnhancedTableProps) {
    let { order, orderBy, columns, onRequestSort, onChangeHandle } = props;
    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                {columns?.map((headCell, index) => {
                    return (
                        <TableCell
                            style={{ textAlign: index === columns.length - 1 ? 'right' : 'left' }}
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {index !== columns.length - 1 ? (
                                <>
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                    </TableSortLabel>
                                    <TextFieldStyle>
                                        <input
                                            type='text'
                                            placeholder={headCell.label}
                                            onChange={e => onChangeHandle(e, headCell.id)}
                                        />
                                    </TextFieldStyle>
                                </>
                            ) : (
                                'Actions'
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}

function TableSortS3Data(props: handleClickProps) {
    const {
        learnTkoLoading,
        openModal,
        canUserCopy,
        columns,
        learnTkoAllDetails,
        handleDeleteVideo,
        ...otherProps
    } = props;

    const [order, setOrder] = React.useState<Order>('asc');
    const [query, setQuery] = React.useState<string>('');
    // const [copyData, setCopyData] = React.useState<string>('');

    const [orderBy, setOrderBy] = React.useState<keyof Data>('key');
    const tableClasses = useTableStyles();
    const [filteredData, setFilteredData] = React.useState<any>([]);
    const debouncedQuery = useDebounce(query, 500);
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    React.useEffect(() => {
        const filteredResults = learnTkoAllDetails?.filter((item: any) => {
            return Object.entries(query).every(([key, searchValue]) => {
                if (searchValue !== '') {
                    return (
                        item[key]?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                        item[key]?.toString().toUpperCase().includes(searchValue.toUpperCase())
                    );
                }
                return true;
            });
        });
        setFilteredData(filteredResults);
    }, [debouncedQuery, query, learnTkoAllDetails]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>, columnId: string) => {
        const searchValue = (e.target as HTMLInputElement).value.toLowerCase();
        setQuery((prevSearchQueries: any) => ({
            ...prevSearchQueries,
            [columnId]: searchValue,
        }));
    };

    const datas = [
        {
            key: 'video1',
            value: 'videoLink',
        },
    ];
    // copy url code

    const copyUrlToClipboard = (videoUrl: any) => {
        navigator.clipboard
            .writeText(videoUrl)
            .then(() => {
                enqueueSuccess('Video Link copied to clipboard:', videoUrl);
            })
            .catch(error => {
                enqueueError('Error copying video link to clipboard:', error);
            });
    };

    if (learnTkoLoading) {
        return <Loader />;
    }
    return (
        <div>
            <TableContainer style={{ marginTop: ' 20px' }}>
                <MuiTable classes={tableClasses} {...otherProps}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        columns={columns}
                        onChangeHandle={handleSearch}
                    />
                    <TableBody style={{ background: '#e7e7e7' }}>
                        {datas
                            ?.sort((a: any, b: any) => {
                                if (orderBy === undefined) {
                                    return 0;
                                }
                                const aValue = a[orderBy];
                                const bValue = b[orderBy];

                                if (aValue < bValue) {
                                    return order === 'asc' ? -1 : 1;
                                }
                                if (aValue > bValue) {
                                    return order === 'asc' ? 1 : -1;
                                }
                                return 0;
                            })
                            .map((ele: any, id: number) => (
                                <TableRow
                                    size='m'
                                    key={id}
                                    style={{
                                        maxWidth: '230px',
                                        overflow: 'hidden',
                                        whiteSpace: 'normal',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    <TableCell>
                                        <TableCellText>
                                            {addElipsisText(ele?.key, 60)}
                                        </TableCellText>
                                    </TableCell>
                                    <TableCell>
                                        <TableCellText>
                                            {addElipsisText(ele?.value, 60)}
                                        </TableCellText>
                                    </TableCell>
                                    <TableCell align='right' onClick={e => e.stopPropagation()}>
                                        {canUserCopy ? (
                                            <LearnTkoItemActions
                                                onUpdate={copyUrlToClipboard}
                                                learnTkoId={ele?.id}
                                                type={true}
                                                learnTkoCopy={ele.value}
                                                onDelete={handleDeleteVideo}
                                            />
                                        ) : null}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </MuiTable>
            </TableContainer>
        </div>
    );
}
export { TableSortS3Data };

import React from 'react';

import { InnerListStyled } from '@modules/layout/styled';
import { AssessmentCapabilitiesListItem } from './assessment-capabilities-list-item';

import type { ProjectEntity } from '@modules/projects/entities';
import type { ComponentAssessmentEntity } from '@modules/assessment/entities';
import { useCompanyAccess } from '@modules/company/hooks';
import { Loader } from '@modules/layout/moleculas';

type AssessmentCapabilitiesListProps = {
    project: ProjectEntity;
    componentAssessment: ComponentAssessmentEntity;
    className?: string;
};

const AssessmentCapabilitiesList = (props: AssessmentCapabilitiesListProps): React.ReactElement => {
    const { componentAssessment, project, ...otherProps } = props;
    const { companyAccess, loading: companyLoading } = useCompanyAccess();
    let isAIAccess: boolean = companyAccess?.isAIAccess;

    const capabilitiesCount = componentAssessment.capabilities.length;

    if (capabilitiesCount === 0) {
        return <InnerListStyled.Root />;
    }
    if (companyLoading) {
        return <Loader />;
    }

    return (
        <InnerListStyled.Root {...otherProps}>
            <InnerListStyled.Header>
                <h2>{project?.isrrimpact ? 'Impact Factors' : 'Capabilities'}</h2>
            </InnerListStyled.Header>

            <InnerListStyled.List>
                {componentAssessment.capabilities.map(capabilityAssessment => (
                    <AssessmentCapabilitiesListItem
                        isAIAccess={isAIAccess}
                        key={capabilityAssessment.id}
                        project={project}
                        componentAssessment={componentAssessment}
                        capabilityAssessment={capabilityAssessment}
                    />
                ))}
            </InnerListStyled.List>
        </InnerListStyled.Root>
    );
};

export { AssessmentCapabilitiesList };

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import {ListImplementationStatusesQuery} from '@modules/shared/graphql/queries';
import {ImplementationStatusesEntityList} from '@modules/shared/entities';

import type { QueryHookOptions } from '@apollo/client';

const useImplementationStatuses = (options?: QueryHookOptions) => {
    const queryResult = useQuery(ListImplementationStatusesQuery, options);

    const { entities: implementationStatusList, count } = useEntity(
        () => new ImplementationStatusesEntityList(queryResult.data?.listImplementationStatuses ?? null),
        [queryResult.data],
    );

    const result = {
        implementationStatusList,
        count,
        ...queryResult,
    };

    return result;
};

export { useImplementationStatuses };

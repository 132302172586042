import React, { useEffect, useMemo, useState } from 'react';

import type { ComponentReportEntity } from '@modules/assessment/entities';
import { ListStyled } from '@modules/layout/styled';
import {
    CapabilityAssessmentEntity,
    ComponentAssessmentEntity,
} from '@modules/assessment/entities';
import {
    Collapse,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { AccordionInnerListItem, AccordionListItem } from '@modules/layout/organisms';
import { ReportComponentCapabilityTableItem } from '@modules/assessment/organisms/report-components-list/report-component-capability-table-item';
import { UserEntity } from '@modules/users/entities';
import { ReportComponentDataGraph } from '@modules/assessment/organisms/report-components-list/report-component-data-graph';
import {
    recommendationLevelFieldLabelList,
    recommendationLevelFieldList,
} from '@modules/assessment/entities/capability-report-entity';
import {
    RecommendationCapabilityAssessment,
    RecommendationComponentAssessment,
} from '@modules/roadmap/recommendation-by-risk';
import { ReportRoadmapTopRecommendationsList } from '@modules/roadmap/organisms';
import { ReportRoadmapComponentCapabilityTableItem } from '@modules/roadmap/organisms/report-roadmap/report-roadmap-component-capability-table-item';

type ReportComponentInnerItemProps = {
    // componentReport?: ComponentReportEntity;
    assessment: ComponentAssessmentEntity;
    componentAssessmentMap: Map<number, RecommendationComponentAssessment>;
    openComponent: boolean;
    recordCount: number;
};

export const ReportRoadmapComponentInnerItem = (
    props: ReportComponentInnerItemProps,
): React.ReactElement => {
    const { assessment, recordCount, openComponent, componentAssessmentMap } = props;
    let recommendationCapabilityList: RecommendationCapabilityAssessment[] =
        componentAssessmentMap.get(assessment.id)?.recommendationCapabilityList ?? [];

    const [recommendationIdCount, setRecommendationIdCount] = useState<any>(null);

    useEffect(() => {
        const capabilityIdCount = recommendationCapabilityList.reduce(
            (recCapabilityList, objCapabilityList) => {
                if (
                    objCapabilityList.capabilityAssessment &&
                    objCapabilityList.capabilityAssessment.id
                ) {
                    const id = objCapabilityList.capabilityAssessment.id;
                    if (!recCapabilityList[id]) {
                        recCapabilityList[id] = { id, count: 0, done: 0 };
                    }
                    recCapabilityList[id].count++;
                    if (objCapabilityList.roadmapRecommendation.status === 'Done') {
                        recCapabilityList[id].done++;
                    }
                }
                return recCapabilityList;
            },
            {},
        );

        setRecommendationIdCount(capabilityIdCount);
    }, [recommendationCapabilityList]);

    const [expanded, setExpanded] = React.useState<boolean>(false);
    const handleExpandComponent = (event: React.ChangeEvent<{}>, isExpanded: boolean) =>
        setExpanded(isExpanded);

    return (
        <Collapse in={openComponent} timeout='auto' unmountOnExit>
            <ListStyled.Container>
                {Boolean(recordCount) && (
                    <AccordionListItem
                        className={'block-offset'}
                        title={''}
                        subtitle={'Top 5 Recommendations'}
                        expanded={expanded}
                        onChange={handleExpandComponent}
                        SummaryInfoComponents={<></>}
                    >
                        <ReportRoadmapTopRecommendationsList
                            recommendationList={recommendationCapabilityList}
                        />
                    </AccordionListItem>
                )}
                {/*<ReportComponentCapabilitiesList capabilitiesReportList={componentReport.capabilities} />*/}

                <div className={'container-flex'}>
                    <div className={'col-info'}>
                        {assessment.componentOwners.length ? (
                            <>
                                <h3>Component owner:</h3>
                                <p className={'text-offset'}>
                                    {assessment.componentOwnersToString()}
                                </p>
                            </>
                        ) : null}

                        {assessment.hasOperator() ? (
                            <>
                                <h3>Operator:</h3>
                                {assessment.operators.map((operator: UserEntity) => (
                                    <p className={'text-offset'}>{operator.getFullName()}</p>
                                ))}
                            </>
                        ) : null}

                        <p className={'text-offset'}>
                            <b>Status:</b> {assessment.status ?? ''}
                        </p>
                        <p className={'block-offset'}>
                            <b>Weight:</b> {assessment.weight ?? ''}
                        </p>

                        {Boolean(recordCount) && (
                            <ReportComponentDataGraph
                                mode={'Recommendation'}
                                recordCapabilityList={recommendationCapabilityList}
                                levelFields={recommendationLevelFieldList}
                                levelFieldLabels={recommendationLevelFieldLabelList}
                            />
                        )}
                    </div>

                    <TableContainer component={Paper} className={'col-table'}>
                        {/*style={{ width: "auto", tableLayout: "auto" }}*/}
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Capability</TableCell>
                                    <TableCell>Completion Level</TableCell>
                                    <TableCell>Risk Level</TableCell>
                                    <TableCell>Recommendations</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>History</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {recommendationCapabilityList.map(
                                    (
                                        recommendationCapability: RecommendationCapabilityAssessment,
                                        index,
                                    ) => {
                                        return (
                                            <ReportRoadmapComponentCapabilityTableItem
                                                key={`${recommendationCapability.roadmapRecommendation?.id}-${index}`}
                                                recommendationCapability={recommendationCapability}
                                                recommendationIdCount={recommendationIdCount}
                                            />
                                        );
                                    },
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </ListStyled.Container>
        </Collapse>
    );
};

import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { TkoScope } from '@modules/types/graphql';

export type TkoEntity = Partial<TkoScope> | null;

@DecribeEntity('TkoScopeEntity')
class TkoScopeEntity extends BaseEntity {
    id: number;
    name: string;

    constructor(tkoscope: TkoEntity) {
        super(tkoscope);

        this.id = tkoscope?.id ?? -1;
        this.name = tkoscope?.name ?? '';
    }
}

export { TkoScopeEntity };

import * as React from 'react';
import {
    TableCell,
    TableBody,
    TableHead,
    TableContainer,
    TableSortLabel,
    Table as MuiTable,
    makeStyles,
} from '@material-ui/core';
import styled from 'styled-components';
import { TableRow } from './table-row';
import { useProjectList } from '@modules/users/hooks';
import { TableCellText } from '@modules/layout/atoms';
import { UsersListItemActions } from '@modules/users/moleculas';
import { Loader } from '@modules/layout/moleculas';
import { useCurrentUser } from '@modules/auth/hooks';
import { ProjectListNameEntity, UserDetailsEntity } from '@modules/users/entities';
import { CompaniesListItemActions } from '@modules/company/moleculas';
import { useDebounce } from '@helpers';

const useTableStyles = makeStyles({
    root: {
        borderCollapse: 'separate',
        borderSpacing: '0 4px',
        width: '100%',
    },
    isAccess: {
        color: `#40ca0c`,
        fontSize: '16px !important',
        fontWeight: 'bolder',
    },
});

const ProjectNameText = styled.h4`
    text-transform: capitalize;
    margin-bottom: 6px;
    font-size: 1.4rem;
    font-weight: 400;
`;
const TextFieldStyle = styled.div`
    font-size: 13px;
    margin: 10px -6px -10px -6px;
    input {
        padding: 4px;
        border-right: transparent !important;
        border-top: transparent !important;
        border-left: transparent !important;
        border: 0.3px solid #a7a7a7;
    }

    input:focus {
        outline: none !important;
        border-bottom: 1px solid #605c5c;
    }
`;

interface Data {
    name: string;
    contact_name: string;
    address: string;
    email: string;
    phone: string;
    package_type: string;
    subscription_type: string;
    expires_at: string;
}

type Order = 'asc' | 'desc';

export type TableColumn = {
    id: string;
    label?: string;
    align?: 'left' | 'center' | 'right';
    width?: number;
};
type queryType = {
    value: any;
};
interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    columns: TableColumn[];
    onChangeHandle: (e: React.ChangeEvent<HTMLInputElement>, columnId: any) => void;
}

interface handleClickProps {
    CompanyDetails: any;
    CompanyLoading: boolean;
    handleClickRedirectEdit: (id: number) => void;
    canDeleteCompany: boolean;
    columns: TableColumn[];
    handleDeleteCompany: (companyId: number) => Promise<void>;
}

export function EnhancedTableHead(props: EnhancedTableProps) {
    let { order, orderBy, columns, onRequestSort, onChangeHandle } = props;
    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {columns?.map((headCell, index) => (
                    <TableCell
                        style={{ textAlign: index === columns.length - 1 ? 'right' : 'left' }}
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {index !== columns.length - 1 ? (
                            <>
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                </TableSortLabel>
                                <TextFieldStyle>
                                    <input
                                        type='text'
                                        placeholder={headCell.label}
                                        onChange={e => onChangeHandle(e, headCell.id)}
                                    />
                                </TextFieldStyle>
                            </>
                        ) : (
                            'Actions'
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function TableSortCompany(props: handleClickProps) {
    const {
        handleClickRedirectEdit,
        canDeleteCompany,
        columns,
        handleDeleteCompany,
        CompanyLoading,
        CompanyDetails,
        ...otherProps
    } = props;

    const [order, setOrder] = React.useState<Order>('asc');
    const [query, setQuery] = React.useState<string>('');

    const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
    const tableClasses = useTableStyles();
    const [filteredData, setFilteredData] = React.useState<any>([]);
    const debouncedQuery = useDebounce(query, 500);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    React.useEffect(() => {
        const filteredResults =
            CompanyDetails &&
            CompanyDetails?.filter((item: any) => {
                if (item === null) {
                    return;
                }
                if (Object.values(debouncedQuery)?.some(value => value !== '')) {
                    return Object.keys(item)?.some((key: any) => {
                        const searchValue = debouncedQuery[key]?.toLowerCase();
                        if (searchValue) {
                            return (
                                item[key]?.toString().toLowerCase().includes(searchValue) ||
                                item[key]?.toString().toUpperCase().includes(searchValue)
                            );
                        }
                    });
                } else {
                    return true;
                }
            });
        setFilteredData(filteredResults);
    }, [debouncedQuery, query, CompanyDetails]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>, columnId: string) => {
        const searchValue = (e.target as HTMLInputElement).value.toLowerCase();
        setQuery((prevSearchQueries: any) => ({
            ...prevSearchQueries,
            [columnId]: searchValue,
        }));
    };

    if (CompanyLoading) {
        return <Loader />;
    }
    return (
        <div>
            <TableContainer style={{ marginTop: ' 20px' }}>
                <MuiTable classes={tableClasses} {...otherProps}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        columns={columns}
                        onChangeHandle={handleSearch}
                    />
                    <TableBody style={{ background: '#e7e7e7' }}>
                        {filteredData &&
                            filteredData
                                ?.sort((a: any, b: any) => {
                                    if (a === null || b === null) {
                                        return 0;
                                    }
                                    if (orderBy === undefined) {
                                        return 0;
                                    }
                                    const aValue = a[orderBy];
                                    const bValue = b[orderBy];

                                    if (aValue < bValue) {
                                        return order === 'asc' ? -1 : 1;
                                    }
                                    if (aValue > bValue) {
                                        return order === 'asc' ? 1 : -1;
                                    }
                                    return 0;
                                })
                                .map((ele: any) => {
                                    if (ele == null) return;
                                    return (
                                        <TableRow
                                            size='m'
                                            key={ele?.id}
                                            onClick={() => handleClickRedirectEdit(ele?.id)}
                                        >
                                            <TableCell>
                                                <TableCellText>{ele?.name}</TableCellText>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellText>{ele?.contact_name}</TableCellText>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellText>{ele?.address}</TableCellText>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellText>{ele?.email}</TableCellText>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellText>{ele?.phone}</TableCellText>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellText>{ele?.package_type}</TableCellText>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellText>
                                                    {ele?.subscription_type}
                                                </TableCellText>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellText>
                                                    {ele?.expires_at?.substring(0, 10)}
                                                </TableCellText>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellText
                                                    className={
                                                        ele?.is_ask_tko ? tableClasses.isAccess : ''
                                                    }
                                                >
                                                    {ele?.is_ask_tko ? 'Access' : 'N/A'}
                                                </TableCellText>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellText
                                                    className={
                                                        ele?.is_learn_tko
                                                            ? tableClasses.isAccess
                                                            : ''
                                                    }
                                                >
                                                    {ele?.is_learn_tko ? 'Access' : 'N/A'}
                                                </TableCellText>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellText>{ele?.schema_name}</TableCellText>
                                            </TableCell>
                                            <TableCell
                                                align='right'
                                                onClick={e => e.stopPropagation()}
                                            >
                                                {canDeleteCompany ? (
                                                    <CompaniesListItemActions
                                                        companyId={ele?.id}
                                                        onDelete={handleDeleteCompany}
                                                    />
                                                ) : null}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                    </TableBody>
                </MuiTable>
            </TableContainer>
        </div>
    );
}
export { TableSortCompany };

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { RoleEntityList } from '@modules/assessment/entities';
import { GetProjectRolesQuery } from '@modules/projects/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetProjectRolesQueryType,
    GetProjectRolesQueryVariables,
} from '@modules/types/graphql';

const useProjectRoles = (
    options?: QueryHookOptions<GetProjectRolesQueryType, GetProjectRolesQueryVariables>,
) => {
    const queryResult = useQuery<GetProjectRolesQueryType, GetProjectRolesQueryVariables>(
        GetProjectRolesQuery,
        options,
    );

    const projectRolesEntityList = useEntity(
        () => new RoleEntityList(queryResult.data?.getProjectRoles ?? null),
        [queryResult.data],
    );

    const result = { projectRolesEntityList, ...queryResult };

    return result;
};

export { useProjectRoles };

import type { SaveCapabilitySurveyMutationVariables } from '@modules/types/graphql';

export type CapabilityAssessmentSaveFormValues = {
    levelId: number;
    notes: string;
    gaps: string;
    recommendations: string;
};

class CapabilityAssessmentSaveRequest {
    surveyId: SaveCapabilitySurveyMutationVariables['surveyId'];
    capabilityLevelId: SaveCapabilitySurveyMutationVariables['capabilityLevelId'];
    notes: SaveCapabilitySurveyMutationVariables['notes'];
    gaps: SaveCapabilitySurveyMutationVariables['gaps'];
    recommendations: SaveCapabilitySurveyMutationVariables['recommendations'];

    constructor(values: CapabilityAssessmentSaveFormValues, surveyId: number) {
        this.capabilityLevelId = values.levelId;
        this.notes = values.notes;
        this.gaps = values.gaps;
        this.recommendations = values.recommendations;
        this.surveyId = surveyId;
    }
}

export { CapabilityAssessmentSaveRequest };

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button } from '@modules/ui/core';
import { useAssignedProject } from '@modules/projects/hooks';

import type { ProjectEntity } from '@modules/projects/entities';

import { gray } from '@modules/ui/colors';
import { CircularProgress, LinearProgress, Toolbar } from '@material-ui/core';
import { ImplementationSubTasksEditFormModal } from './implementation-sub-tasks-edit-form-modal';
import { ImplementationSubTaskCreateModal } from './implementation-sub-task-create-modal';
import { useSubTasks } from '@modules/implementation/hooks/use-sub-task';
import { Loader } from '@modules/layout/moleculas';
import { useRecommendationImpSubTasks } from '@modules/implementation/hooks';
type ImplementationTaskListProps = {
    recommendationId: number;
    project: ProjectEntity;
    className?: string;
    taskId: number;
    isGenerate: boolean;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 2.2rem;
`;

const List = styled.div`
    width: 100%;
    margin-top: 24px;

    > * {
        margin-bottom: 12px;
    }
`;

const TaskItem = styled.div`
    width: 100%;
    padding: 12px;
    font-size: 1.7rem;
`;

const EmptyGaps = styled(TaskItem)`
    height: initial;
    padding: 0 4px;
    font-size: 1.6rem;
    font-weight: 700;

    && {
        border: 0;
    }
`;
const Actions = styled.div`
    width: 100%;

    > *:not(:last-child) {
        margin-right: 16px;
    }
`;
const ImplementationTask = styled.div`
    font-size: 14px;
    padding: 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h4 {
        margin: 10px 0px;
        margin: 10px 0px;
    }
    & svg:hover {
        color: #d4d4d4;
    }
    > h4 svg {
        font-size: 2rem;
    }
`;
const EyeInfo = styled.div`
    > svg {
        font-size: 3rem;
        text-align: center;
        cursor: pointer;
        margin-right: 35px;
    }
    &:hover {
        color: #5a5a5a;
    }
`;
const Description = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    word-break: break-word;

    > h5 {
        width: 100%;
        color: ${gray[20]};
        font-size: 1.4rem;
        font-weight: 400;
    }
`;

const ImplementationSubTaskList = (
    props: ImplementationTaskListProps,
): React.ReactElement | null => {
    const { project, recommendationId, taskId, isGenerate, ...otherProps } = props;

    const { subTask, loading: subTaskLoading } = useSubTasks({
        variables: {
            companyId: project?.companyId,
            recommendationId: recommendationId,
            taskId: taskId,
        },
    });
    const { RecImpSubTask, loading: recSubTaskLoading } = useRecommendationImpSubTasks({
        variables: { taskId: taskId },
    });
    const { refetch: refetchAssignedProject } = useAssignedProject({
        skip: !project.id,
        variables: { id: project.id },
    });

    const handleGapAddingSuccess = async (): Promise<void> => {
        await refetchAssignedProject();
    };

    const [openImpSubTaskEditFormModal, setOpenImpSubTaskEditFormModal] = React.useState<boolean>(
        false,
    );

    const handleOpenImpSubTaskEditModal = (): void => setOpenImpSubTaskEditFormModal(true);
    const handleCloseImpSubTaskEditFormModal = (): void => setOpenImpSubTaskEditFormModal(false);

    const [
        openImpSubTaskCreateFormModal,
        setCloseImpSubTaskCreateFormModal,
    ] = React.useState<boolean>(false);

    const handleOpenImpSubTaskCreateModal = (): void => setCloseImpSubTaskCreateFormModal(true);
    const handleCloseImpSubTaskCreateModal = (): void => setCloseImpSubTaskCreateFormModal(false);

    const hasTasks = RecImpSubTask?.length !== 0;

    if (subTaskLoading || recSubTaskLoading) {
        return <Loader />;
    }
    return (
        <div {...otherProps}>
            {RecImpSubTask &&
                RecImpSubTask?.map((ele: any, index: number) => {
                    return (
                        <React.Fragment key={ele?.id}>
                            <h2 style={{ marginBottom: '15px' }}>
                                {index + 1}- {ele?.subTask}
                            </h2>
                        </React.Fragment>
                    );
                })}
            <Toolbar>
                <Button
                    size='small'
                    style={{ marginRight: '10px' }}
                    onClick={handleOpenImpSubTaskEditModal}
                >
                    Edit Sub Tasks
                </Button>

                <Button size='small' variant='outlined' onClick={handleOpenImpSubTaskCreateModal}>
                    + Add Sub Task
                </Button>
            </Toolbar>

            <ImplementationSubTasksEditFormModal
                impSubTask={subTask}
                RecImpSubTask={RecImpSubTask}
                companyId={project.companyId}
                recommendationId={recommendationId}
                taskId={taskId}
                subTaskLoading={recSubTaskLoading}
                open={openImpSubTaskEditFormModal}
                onClose={handleCloseImpSubTaskEditFormModal}
            />

            <ImplementationSubTaskCreateModal
                companyId={project.companyId}
                recommendationId={recommendationId}
                taskId={taskId}
                open={openImpSubTaskCreateFormModal}
                onClose={handleCloseImpSubTaskCreateModal}
                onSuccess={handleGapAddingSuccess}
            />
        </div>
    );
};

export { ImplementationSubTaskList };

import { EntityList } from '@lib/entity';
import type { Entity } from './learn-tko-details-entity';
import { LearnTkoMenuEntity } from './learn-tko-entity';
import { LearnTkoMenuDetailsEntity } from './learn-tko-details-entity';

class LearnTkoMenuDetailsEntityList extends EntityList<LearnTkoMenuDetailsEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(LearnTkoMenuEntity, items, count);
    }
}

export { LearnTkoMenuDetailsEntityList };

import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Form } from 'formik';
import { useMutation } from '@apollo/client';
import { withStyles, makeStyles, MenuItem, FormHelperText } from '@material-ui/core';

import { Autocomplete, TextField, Button } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik } from '@modules/layout/organisms';
import { useUsers } from '@modules/users/hooks';
import { GetProjectQuery } from '@modules/projects/graphql';
import { weights } from '@modules/components/constants';
import { useComponents } from '@modules/components/hooks';
import { useComponentAssessments } from '@modules/assessment/hooks';
import { AssessmentComponentChangesRequest } from '@modules/assessment/requests';
import { useCapabilities } from '@modules/custom-components/hooks';
import { CapabilityCreateFormModal } from '@modules/custom-components/organisms';
import {
    GetComponentAssessmentQuery,
    AddProjectComponentMutation,
    UpdateProjectComponentMutation,
} from '@modules/assessment/graphql';
import { CapabilitiesListFormGroup } from './capabilities-list-form-group';

import type { UserListEntity } from '@modules/users/entities';
import type { ProjectEntity } from '@modules/projects/entities';
import type { ComponentEntity } from '@modules/components/entities';
import type { AssessmentComponentChangesFormValues } from '@modules/assessment/requests';
import type {
    UpdateProjectComponentMutationType,
    UpdateProjectComponentMutationVariables,
    AddProjectComponentMutationType,
    AddProjectComponentMutationVariables,
} from '@modules/types/graphql';

type AssessmentComponentFormProps = {
    type: 'add' | 'edit';
    project: ProjectEntity;
    initialValues: AssessmentComponentChangesFormValues;
    onCancel: () => void;
    componentAssessmentId?: number;
};

const useFormStyles = makeStyles({
    componentOwner: {
        flex: 1,
        marginRight: 16,
    },
    componentWeightInput: {
        minHeight: '53.65px',
    },
});

const StyledFormHelperText = withStyles({
    root: {
        width: '100%',
        marginTop: 4,
        fontSize: '1.4rem',
    },
})(FormHelperText);

const StyledForm = styled(Form)`
    width: 1024px;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 24px;
        }
    }
`;

const MutlipleChildsFormRow = styled(FormStyled.FormRow)`
    align-items: flex-start;
    justify-content: space-between;
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const AssessmentComponentForm = (props: AssessmentComponentFormProps): React.ReactElement => {
    const { type, initialValues, project, componentAssessmentId, onCancel } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const formClasses = useFormStyles();

    const projectId = project.id;
    const isAdd = type === 'add';

    const [
        openCapabilityCreateFormModal,
        setOpenCapabilityCreateFormModal,
    ] = React.useState<boolean>(false);

    const handleOpenCapabilityCreateFormModal = (): void => setOpenCapabilityCreateFormModal(true);

    const handleCloseCapabilityCreateFormModal = (): void =>
        setOpenCapabilityCreateFormModal(false);

    const { refetch: capabilitiesRefetch } = useCapabilities({
        skip: true,
        variables: {
            componentId: initialValues.component?.id as number,
            companyId: project.companyId,
        },
    });

    const handleSuccessCreatedCapability = async (): Promise<void> => {
        await capabilitiesRefetch();
    };

    const { componentAssessments, loading: componentAssessmentsLoading } = useComponentAssessments({
        skip: !isAdd,
        variables: { projectId },
    });

    const { components, loading: componentsLoading } = useComponents({
        skip: !isAdd,
        variables: {
            companyId: project.companyId,
            assessmentTypeId: project.assessmentTypeId,
        },
    });

    const { users, loading: usersLoading } = useUsers();

    const [addComponent] = useMutation<
        AddProjectComponentMutationType,
        AddProjectComponentMutationVariables
    >(AddProjectComponentMutation);

    const [updateComponent] = useMutation<
        UpdateProjectComponentMutationType,
        UpdateProjectComponentMutationVariables
    >(UpdateProjectComponentMutation);

    const isComponentExists = React.useCallback(
        (component: ComponentEntity | null) =>
            componentAssessments.filter(
                componentAssessment => componentAssessment.component.id === component?.id,
            ).length !== 0,
        [componentAssessments],
    );

    const getComponentOptionLabel = React.useCallback(
        (option: ComponentEntity | null) => option?.name ?? '-',
        [],
    );

    const getUserOptionLabel = React.useCallback(
        (option: UserListEntity | null) => option?.getFullName() ?? '-',
        [],
    );

    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                componentOwner: yup.mixed().required('Required field'),
                weight: yup.number().min(1, 'Required field'),
            })}
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const variables = new AssessmentComponentChangesRequest(values);
                    if (isAdd) {
                        const { data: addComponentData } = await addComponent({
                            variables: { ...variables, projectId },
                            refetchQueries: [
                                { query: GetProjectQuery, variables: { id: projectId } },
                            ],
                            awaitRefetchQueries: true,
                        });

                        if (addComponentData?.addComponentToProgram?.id) {
                            enqueueSuccess('Component successfully added to assessment!');
                            onCancel();
                        } else {
                            enqueueError('An error occurred while adding the component!');
                        }
                    } else {
                        if (!componentAssessmentId) {
                            return;
                        }

                        const { data: updateComponentData } = await updateComponent({
                            variables: { ...variables, assessmentId: componentAssessmentId },
                            refetchQueries: [
                                {
                                    query: GetComponentAssessmentQuery,
                                    variables: { id: componentAssessmentId },
                                },
                            ],
                            awaitRefetchQueries: true,
                        });

                        if (updateComponentData?.updateComponentForProgram) {
                            enqueueSuccess('Component successfully updated!');
                            onCancel();
                        } else {
                            enqueueError('An error occurred while updating the component!');
                        }
                    }
                } catch (e) {
                    throw e;
                }
            }}
        >
            {formikProps => {
                const {
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    isSubmitting,
                } = formikProps;

                const handleChangeSelect = (
                    event: React.ChangeEvent<{ name: string; value: string }>,
                ) => setFieldValue(event.target.name, event.target.value);

                const handleChangeAutocomplete = (id: string) => (
                    _: React.ChangeEvent<{}>,
                    value: any,
                ) => setFieldValue(id, value);

                return (
                    <StyledForm>
                        {isAdd ? (
                            <Autocomplete<ComponentEntity | null, false, false, false>
                                id='component'
                                label='Component'
                                placeholder='Choose the component'
                                options={components}
                                value={values.component}
                                error={!!errors.component}
                                disabled={componentsLoading || componentAssessmentsLoading}
                                getOptionLabel={getComponentOptionLabel}
                                getOptionDisabled={isComponentExists}
                                onChange={handleChangeAutocomplete('component')}
                            />
                        ) : null}

                        <TextField
                            fullWidth
                            multiline
                            rows={2}
                            id='objectives'
                            name='objectives'
                            label='Objectives'
                            value={values.objectives}
                            error={!!errors.objectives}
                            helperText={errors.objectives}
                            onChange={handleChange}
                        />

                        <MutlipleChildsFormRow>
                            <Autocomplete<UserListEntity | null, false, false, false>
                                id='componentOwner'
                                label='Component Owner'
                                placeholder='Choose the owner'
                                className={formClasses.componentOwner}
                                options={users}
                                value={values.componentOwner}
                                error={!!errors.componentOwner}
                                disabled={usersLoading}
                                getOptionLabel={getUserOptionLabel}
                                onChange={handleChangeAutocomplete('componentOwner')}
                            />

                            <TextField
                                select
                                id='weight'
                                name='weight'
                                label='Weight'
                                value={values.weight}
                                error={!!errors.weight}
                                helperText={errors.weight}
                                onChange={handleChangeSelect}
                                onBlur={handleBlur}
                                InputProps={{ className: formClasses.componentWeightInput }}
                            >
                                <MenuItem disabled>Pick</MenuItem>

                                {weights.map(weight => (
                                    <MenuItem key={weight} value={weight}>
                                        {weight}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </MutlipleChildsFormRow>

                        <FormStyled.FormRow>
                            <CapabilitiesListFormGroup project={project} />
                        </FormStyled.FormRow>

                        {!isAdd && values.component ? (
                            <FormStyled.FormRow>
                                <Button
                                    size='small'
                                    variant='outlined'
                                    onClick={handleOpenCapabilityCreateFormModal}
                                >
                                    Create capability
                                </Button>

                                <StyledFormHelperText>
                                    After creating capability level, you need to create capability
                                    level descriptions before starting the assessment
                                </StyledFormHelperText>

                                <CapabilityCreateFormModal
                                    companyId={project.companyId}
                                    componentId={values.component.id}
                                    open={openCapabilityCreateFormModal}
                                    onClose={handleCloseCapabilityCreateFormModal}
                                    onSuccess={handleSuccessCreatedCapability}
                                />
                            </FormStyled.FormRow>
                        ) : null}

                        <FormStyled.FormRowSubmit>
                            <SubmitButton type='submit' loading={isSubmitting}>
                                {isAdd ? 'Add Component' : 'Update Component'}
                            </SubmitButton>

                            <SubmitButton variant='text' disabled={isSubmitting} onClick={onCancel}>
                                Cancel
                            </SubmitButton>
                        </FormStyled.FormRowSubmit>
                    </StyledForm>
                );
            }}
        </ExtendedFormik>
    );
};

export { AssessmentComponentForm };

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { gray } from '@modules/ui/colors';
import {
    DialogAcceptRecommendation,
    DialogModifyRecommendation,
    DialogAcknowledgeRecommendation,
    DialogRejectRecommendation,
    RoadmapHistoryModal,
} from '@modules/roadmap/organisms';

import type { RoadmapRecommendationEntity } from '@modules/roadmap/entities';
import { Button } from '@modules/ui/core';
import { AddNotesForm } from '@modules/roadmap/moleculas';
import { ProjectEntity } from '@modules/projects/entities';
import Tooltip from '@material-ui/core/Tooltip';
import { HistoryOutlined } from '@material-ui/icons';
import { AssessmentCapabilityHistoryModal } from '@modules/assessment/organisms';
import { roadmapRecommendationTitle } from '@helpers';
import { RoadmapNotes } from '@modules/roadmap/organisms/roadmap-recommendations-list/roadmap-notes';

type RoadmapRecommendationItemProps = {
    canRefine: boolean;
    capabilityAssessmentId: number;
    roadmapRecommendation: RoadmapRecommendationEntity;
    project: ProjectEntity;
    hasStRole?: boolean;
    hasPmRole?: boolean;
    hasCoRole?: boolean;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    min-height: 56px;
    padding: 12px 0;
`;

const Description = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    word-break: break-word;

    > h5 {
        width: 100%;
        color: ${gray[20]};
        font-size: 1.4rem;
        font-weight: 400;
    }
`;

const DecriptionBlock = styled.div`
    width: calc(100% / 3 - 16px);
    margin-top: 16px;
    margin-right: 16px;

    > * {
        width: 100%;
        font-size: 1.4rem;
    }

    > h5 {
        font-weight: 700;
    }

    > p {
        margin-top: 8px;
    }
`;

const Actions = styled.div`
    margin-left: auto;

    > *:not(:last-child) {
        margin-right: 8px;
    }
`;

const StyledHistoryBlock = styled.div`
    margin-right: 30px;

    span {
        line-height: 1;
    }
`;

const HistoryIcon = <HistoryOutlined style={{ fontSize: 24 }} />;

const RoadmapRecommendationItem = (props: RoadmapRecommendationItemProps): React.ReactElement => {
    const {
        canRefine,
        roadmapRecommendation,
        capabilityAssessmentId,
        project,
        hasStRole,
        hasPmRole,
        hasCoRole,
    } = props;

    const [showNotes, setShowNotes] = useState(false);
    const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false);
    const notes = roadmapRecommendation.refinement?.notesLog;
    const displayRecommendation = roadmapRecommendationTitle(roadmapRecommendation);

    const renderedDecription = React.useMemo(() => {
        const isAcknowledged = roadmapRecommendation.refinement.isAcknowledged();
        const isRejected = roadmapRecommendation.refinement.isRejected();

        if (isAcknowledged) {
            return (
                <DecriptionBlock>
                    <h5>Acknowledge Comment</h5>
                    <p>{roadmapRecommendation.refinement.notes}</p>
                </DecriptionBlock>
            );
        }

        if (isRejected) {
            return (
                <DecriptionBlock>
                    <h5>Rejected Comment</h5>
                    <p>{roadmapRecommendation.refinement.notes}</p>
                </DecriptionBlock>
            );
        }

        return (
            <>
                {
                    !showNotes ?
                        <>
                            {roadmapRecommendation.refinement.constraints ? (
                                <DecriptionBlock>
                                    <h5>Constraints</h5>
                                    <p>{roadmapRecommendation.refinement.constraints}</p>
                                </DecriptionBlock>
                            ) : null}

                            {roadmapRecommendation.refinement.dependencies ? (
                                <DecriptionBlock>
                                    <h5>Dependencies</h5>
                                    <p>{roadmapRecommendation.refinement.dependencies}</p>
                                </DecriptionBlock>
                            ) : null}

                            {roadmapRecommendation.refinement.notes ? (
                                <DecriptionBlock>
                                    <h5>Notes</h5>
                                    <p> {roadmapRecommendation.refinement.notes}</p>
                                </DecriptionBlock>
                            ) : null}
                        </>
                        :
                        ''
                }

                <DecriptionBlock>
                    <StyledHistoryBlock>
                        <Tooltip title='Show history'>
                            <Button
                                variant='text'
                                size='small'
                                onClick={() => setOpenHistoryModal(true)}
                            >
                                {HistoryIcon}
                            </Button>
                        </Tooltip>
                    </StyledHistoryBlock>
                </DecriptionBlock>

                {roadmapRecommendation.refinement.notes ? (
                    <DecriptionBlock style={{ paddingRight: 5 }}>
                        <a
                            href='./'
                            onClick={e => {
                                e.preventDefault();
                                setShowNotes(state => !state);
                            }}
                        >
                            <b style={{fontSize: 16 }}>({showNotes ? 'Hide' : 'Show'} all notes)</b>
                        </a>
                    </DecriptionBlock>
                ) : null}
            </>
        );
    }, [roadmapRecommendation, showNotes]);

    return (
        <Root key={roadmapRecommendation.id}>
            <Description>
                <h5>{displayRecommendation}</h5>
                {renderedDecription}
            </Description>

            <RoadmapNotes
                notes={notes}
                showNotes={showNotes}
                hasStRole={hasStRole}
                hasPmRole={hasPmRole}
                hasCoRole={hasCoRole}
                project={project}
                roadmapRecommendation={roadmapRecommendation}
            />

            <RoadmapHistoryModal
                open={openHistoryModal}
                roadmapRecommendation={roadmapRecommendation}
                onClose={() => setOpenHistoryModal(false)}
            >
                <></>
            </RoadmapHistoryModal>

            {canRefine ? (
                <Actions>
                    <DialogAcceptRecommendation
                        roadmapRecommendation={roadmapRecommendation}
                        capabilityAssessmentId={capabilityAssessmentId}
                    />
                    <DialogModifyRecommendation
                        roadmapRecommendation={roadmapRecommendation}
                        capabilityAssessmentId={capabilityAssessmentId}
                    />
                    <DialogAcknowledgeRecommendation
                        roadmapRecommendation={roadmapRecommendation}
                        capabilityAssessmentId={capabilityAssessmentId}
                    />
                    <DialogRejectRecommendation
                        roadmapRecommendation={roadmapRecommendation}
                        capabilityAssessmentId={capabilityAssessmentId}
                    />
                </Actions>
            ) : null}
        </Root>
    );
};

export { RoadmapRecommendationItem };

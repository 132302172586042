import { EntityList } from '@lib/entity';
import { ImpactLevelEntity } from '@modules/shared/entities/impact-level-entity';

class ImpactLevelsEntityList extends EntityList<ImpactLevelEntity> {
    constructor(items: any[] | null, count?: number | null) {
        super(ImpactLevelEntity, items, count);
    }
}

export { ImpactLevelsEntityList };

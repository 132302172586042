import { EntityList } from '@lib/entity';
import { RequirementEntity } from './requirement-entity';

import type { Entity } from './requirement-entity';

class RequirementEntityList extends EntityList<RequirementEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(RequirementEntity, items, count);
    }
}

export { RequirementEntityList };

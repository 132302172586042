import gql from 'graphql-tag';
import { AskTkoDetailFragment } from '../fragments';

export const CreateAskTkoMutation = gql`
    mutation CreateAskTkoDetail(
        $title: String!
        $description: String!
        $parentId: Int!
        $queryId: String!
        $isParent: Boolean
    ) {
        createAskTkoDetail(
            title: $title
            description: $description
            parent_id: $parentId
            query_id: $queryId
            is_parent: $isParent
        ) {
            ...AskTkoDetails
        }
    }
    ${AskTkoDetailFragment}
`;

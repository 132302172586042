import React from 'react';
import styled from 'styled-components';

import { routes } from '@config/routes';
import { CommonStyled } from '@modules/layout/styled';
import { ButtonBack } from '@modules/layout/atoms';

import type { Page } from '@modules/types/page';

const Body = styled(CommonStyled.AreaBody)`
    padding: 0 8px;

    > * {
        width: 100%;
        text-align: center;
    }

    h3 {
        font-size: 4rem;
    }

    p {
        margin-top: 8px;
        font-size: 2rem;
    }
`;

const ErrorPage = (props: Page): React.ReactElement => {
    return (
        <CommonStyled.Section>
            <CommonStyled.Area spacing='dense'>
                <Body>
                    <h3>404</h3>
                    <p>Resource not found</p>
                </Body>

                <CommonStyled.AreaTopPanel margin='none'>
                    <ButtonBack href={routes.index.path}>On main page</ButtonBack>
                </CommonStyled.AreaTopPanel>
            </CommonStyled.Area>
        </CommonStyled.Section>
    );
};

export { ErrorPage };

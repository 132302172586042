import React from 'react';
import styled, { css } from 'styled-components';
import { gray } from '@modules/ui/colors';
import { black, white } from '@modules/ui/colors';
import { InnerListItemStyled, ListStyled } from '@modules/layout/styled';
import { Collapse, TableCell, TableRow, Typography } from '@material-ui/core';
import { CapabilityMaturityLevel } from '@modules/capabilities/moleculas';
import { CapabilityAssessmentLog } from '@modules/types/graphql';
import { ReportCapabilityLog } from '@modules/assessment/organisms/report-components-list/report-capability-log';
import { format } from 'date-fns';
import { RecommendationCapabilityAssessment } from '@modules/roadmap/recommendation-by-risk';
import {
    RoadmapRecommendationEntity,
    RoadmapRecommendationRefinementEntity,
} from '@modules/roadmap/entities';
import { roadmapRecommendationTitle } from '@helpers';
import { RoadmapHistory } from '@modules/roadmap/organisms/roadmap-recommendations-list/roadmap-history';
import {
    getMaturityLevelName,
    capabilityLevelProgress as levelProgress,
} from '@modules/shared/constants/risk-level';
import {
    CapabilityMaturityLevelEnum,
    capabilityLevelColors,
} from '@modules/capabilities/constants';

type ReportComponentCapabilityTableItemProps = {
    recommendationCapability: RecommendationCapabilityAssessment;
    recommendationIdCount: any;
};

const StyledCapabilityMaturityLevel = styled(CapabilityMaturityLevel)`
    width: max-content;
`;

const Root = styled.div`
    display: flex;
    flex-flow: column nowrap;

    border: 1px solid #f2f2f2;
    border-radius: 4px;
`;

const progressWidth = 185;
const minWidth = 25;
const Progress = styled.div<{ color?: string; width?: number }>`
    ${p => css`
        color: ${((p.width ?? 0) / 100) * 185 < minWidth ? black[100] : white[100]};
    `}
    font-size: 1.4rem;
    border-radius: 4px 0 0 4px;
    min-width: ${progressWidth}px;

    .progress {
        ${p => css`
            padding: 8px 0;
            // min-width: ${minWidth}px;

            white-space: pre;
            text-align: center;
            font-weight: 600;

            width: ${p.width}%;
            background-color: ${p.color};
        `}
    }
`;

const Label = styled.div`
    padding: 8px 16px;
    text-transform: capitalize;
    text-align: center;
`;

export const ReportRoadmapComponentCapabilityTableItem = (
    props: ReportComponentCapabilityTableItemProps,
): React.ReactElement => {
    const { recommendationCapability, recommendationIdCount } = props;
    const [openHistory, setOpenHistory] = React.useState(false);
    const history: RoadmapRecommendationRefinementEntity | undefined =
        recommendationCapability.roadmapRecommendation?.refinement;

    let completionLevel: number = Number(
        levelProgress[
            recommendationCapability.capabilityAssessment.completionLevel.getMaturityLevel()
        ]
            ?.replace('<', '')
            ?.replace('%', '')
            ?.trim() ?? 0,
    );
    const recommendationIdLevel = recommendationCapability.capabilityAssessment.id;
    const recommendationStatusLength = recommendationIdCount[recommendationIdLevel].count;
    const recommendationStatusDone = recommendationIdCount[recommendationIdLevel].done;

    const restCompletionLevel = 100 - completionLevel;
    const recommendationCompletationLevel = restCompletionLevel / recommendationStatusLength;
    const totalRecommendationComletationLevel =
        recommendationCompletationLevel * recommendationStatusDone + completionLevel;

    const maturityLevelName: any = getMaturityLevelName(
        totalRecommendationComletationLevel,
    ) as CapabilityMaturityLevelEnum;

    function onHistoryOpen() {
        setOpenHistory(!openHistory);
    }

    const color = capabilityLevelColors[maturityLevelName];
    const width = Number(
        levelProgress[maturityLevelName]?.replace('<', '')?.replace('%', '')?.trim() ?? 0,
    );
    return (
        <>
            <TableRow key={recommendationCapability.roadmapRecommendation?.id}>
                <TableCell>
                    {recommendationCapability.canShowCapability && (
                        <>
                            <p className={'text-offset'}>
                                {recommendationCapability.capabilityAssessment.capability.title}
                            </p>
                            <p>
                                <b>Security Controls:</b>
                            </p>
                            <p>
                                {
                                    recommendationCapability.capabilityAssessment.capability
                                        .securityControls
                                }
                            </p>
                        </>
                    )}
                </TableCell>
                <TableCell>
                    {recommendationCapability.canShowCapability && (
                        // <StyledCapabilityMaturityLevel
                        //     current={maturityLevelName}
                        // />
                        <Root>
                            <Progress color={color} width={width}>
                                <div className={'progress'}>
                                    {totalRecommendationComletationLevel.toFixed()}%
                                </div>
                            </Progress>
                            <Label>{maturityLevelName}</Label>
                        </Root>
                    )}
                </TableCell>
                <TableCell>
                    {recommendationCapability.canShowCapability
                        ? recommendationCapability?.scoredGap?.riskLevel
                        : ''}
                </TableCell>
                <TableCell>
                    {recommendationCapability.roadmapRecommendation
                        ? roadmapRecommendationTitle(recommendationCapability.roadmapRecommendation)
                        : ''}
                </TableCell>
                <TableCell>
                    {recommendationCapability.roadmapRecommendation?.status === 'Done'
                        ? 'Completed'
                        : recommendationCapability.roadmapRecommendation?.status ?? ''}
                </TableCell>
                <TableCell>
                    {history && (
                        <>
                            <p className={'text-offset'}>
                                {history.status} at{' '}
                                {format(new Date(history.refined_at), 'dd.MM.yyyy hh:mm')} by{' '}
                                {history?.refined_by?.first_name} {history?.refined_by?.last_name}
                            </p>
                            {/*<p>Dependencies: {history.dependencies}</p>*/}
                            {/*<p>Constraints: {history.constraints}</p>*/}
                            {/*<p className={'text-offset'}>Notes: {history.notes}</p>*/}
                            <p className={'collapsible-trigger'} onClick={onHistoryOpen}>
                                <strong>{openHistory ? 'Hide' : 'Show'} History</strong>
                            </p>
                        </>
                    )}
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={openHistory} timeout='auto' unmountOnExit>
                        <RoadmapHistory
                            roadmapRecommendation={
                                recommendationCapability.roadmapRecommendation as RoadmapRecommendationEntity
                            }
                        />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

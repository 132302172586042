import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Form } from 'formik';
import { useNavigate } from '@reach/router';
import { TextField, Button } from '@modules/ui/core';
import { ExtendedFormik } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { FormLabel } from '@material-ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
    margin-top: 20px;
`;
const FormLabelStyle = styled(FormLabel)`
    display: flex;
    align-items: center;
    font-size: 32px;
    justify-content: center;
    border: 1px solid #e1e1e1;
    border-radius: 70px;
    margin-bottom: 20px;
`;
const CloudUploadIconStyle = styled(CloudUploadIcon)`
    font-size: 62px;
    color: #0057ff;
`;
type InitialValueType = {
    imageUrl: any;
};

type LearnTkoFormProps = {
    type: string;
    initialValues: InitialValueType;
    FormWrapComponent: any;
    onSubmit: (values: any) => void;
    onClose: () => void;
};

const FileUploadForm = (props: LearnTkoFormProps): React.ReactElement => {
    const { type, initialValues, onClose, FormWrapComponent, onSubmit } = props;
    const navigate = useNavigate();
    const { enqueueError } = useEnqueueStacks();
    const isEdit = type === 'edit';
    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={() => {
                let rules: Record<string, any> = {};

                if (!isEdit) {
                    rules = {
                        ...rules,
                        // imageUrl: yup.addMethod.required('Required field')
                    };
                }

                return yup.object(rules);
            }}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {formikProps => {
                const {
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    isSubmitting,
                } = formikProps;

                // const handleFileChange = (e: any) => {
                //     // Uploaded file
                //     const video_File = e.target.files[0];
                //     // Changing file state
                //     setFieldValue('video_File', video_File);
                // };

                const handleFileChange = (e: any) => {
                    // Uploaded file
                    const video_File = e.target.files[0];
                    if (video_File) {
                        const fileExtension = video_File.name.split('.').pop().toLowerCase();
                        const allowedVideoExtensions = ['.mp4', '.avi', '.mov', '.mkv', '.wmv'];
                        if (allowedVideoExtensions.includes('.' + fileExtension)) {
                            setFieldValue('video_File', video_File);
                        } else {
                            enqueueError('Invalid file type. Please select a valid Video file.');
                        }
                    }
                };

                return (
                    <FormWrapComponent>
                        <StyledForm>
                            <FormLabelStyle htmlFor='imageUrl'>
                                <CloudUploadIconStyle />
                                Video Upload
                            </FormLabelStyle>
                            <TextField
                                fullWidth
                                id='imageUrl'
                                type='file'
                                // label='Image'
                                style={{ display: 'none' }}
                                placeholder='Select your Image'
                                value={values.imageUrl}
                                error={!!errors.imageUrl}
                                helperText={errors.imageUrl}
                                onChange={handleFileChange}
                                onBlur={handleBlur}
                            />

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    {!isEdit ? 'Save' : 'Update'}
                                </SubmitButton>

                                <SubmitButton variant='text' onClick={onClose}>
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    </FormWrapComponent>
                );
            }}
        </ExtendedFormik>
    );
};

export { FileUploadForm };

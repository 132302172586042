import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';

import { TextField, Button } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';

import type { CustomTypeCreateFormValues } from '@modules/custom-components/requests';
import type {
    UpdateAssessmentTypeMutationType,
    UpdateAssessmentTypeMutationVariables,
} from '@modules/types/graphql';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import { Loader } from '@modules/layout/moleculas';
import { useGetAssessmentTypeById } from '@modules/custom-components/hooks/use-get-assessment-type';
import { UpdateAssessmentTypeNameMutation } from '@modules/custom-components/graphql/mutations';
import { GetAllAssessmentTypeQuery } from '@modules/components/graphql';

type CustomAssessmentEditFormProps = {
    companyId?: number;
    onCancel?: () => void;
    type: string;
    assessmentId?: number;
    GetAssessmentTypesQuery: any;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const CustomAssessmentTypeEditForm = (props: CustomAssessmentEditFormProps): React.ReactElement => {
    const { onCancel, type, assessmentId, GetAssessmentTypesQuery } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [updateAssessmentTypeName, { loading: updateAssessmentLoading }] = useMutation<
        UpdateAssessmentTypeMutationType,
        UpdateAssessmentTypeMutationVariables
    >(UpdateAssessmentTypeNameMutation);

    const { getAsseessmentTypeById, loading: asseessmentTypeLoading } = useGetAssessmentTypeById({
        variables: { id: assessmentId as number },
    });

    const initialValues: CustomTypeCreateFormValues = {
        name: getAsseessmentTypeById?.name,
        assessmentCategoriesId: getAsseessmentTypeById?.assessmentCategoriesId,
        isRRType: getAsseessmentTypeById?.isRRType,
    };

    if (asseessmentTypeLoading || updateAssessmentLoading) {
        return <Loader />;
    }

    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                name: yup.string().required('Required field'),
            })}
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    // const variables = new CustomAssessmentTypeCreateRequest(values);
                    const { data: updateAssessmentName } = await updateAssessmentTypeName({
                        variables: {
                            id: assessmentId as number,
                            name: values?.name,
                            isRRType: values?.isRRType,
                            assessmentCategoriesId: getAsseessmentTypeById?.assessmentCategoriesId,
                        },
                        refetchQueries: [{ query: GetAllAssessmentTypeQuery }],
                        awaitRefetchQueries: true,
                    });

                    const assessmentIds = updateAssessmentName?.updateAssessmentType?.id;

                    if (assessmentIds) {
                        enqueueSuccess('Assessment type successfully updated!');
                        onCancel?.();
                    } else {
                        enqueueError('An error occurred while updating Assessment type!');
                    }
                } catch (e) {
                    throw e;
                }
            }}
        >
            {formikProps => {
                const {
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                    setFieldValue,
                } = formikProps;
                const handleChangeAutocomplete = (field: any) => (
                    _: React.ChangeEvent<{}>,
                    value: any | null,
                ) => {
                    setFieldValue(field, value);
                };
                return (
                    <StyledForm>
                        <TextField
                            required
                            fullWidth
                            id='name'
                            label='Assessment Type Name'
                            value={values.name}
                            error={!!errors.name}
                            helperText={errors.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values?.isRRType}
                                    value={values?.isRRType}
                                    onChange={handleChange}
                                    name='isRRType'
                                />
                            }
                            label='Risk Registry Category'
                        />

                        <FormStyled.FormRowSubmit>
                            <SubmitButton type='submit' loading={isSubmitting}>
                                Update Assessment Type Name
                            </SubmitButton>

                            <SubmitButton variant='text' disabled={isSubmitting} onClick={onCancel}>
                                Cancel
                            </SubmitButton>
                        </FormStyled.FormRowSubmit>
                    </StyledForm>
                );
            }}
        </ExtendedFormik>
    );
};

export { CustomAssessmentTypeEditForm };

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { ProjectEntity } from '@modules/projects/entities';
import { ImplementationReportEntity } from '@modules/implementation/entities';
import { GetImplementationReportQuery } from '@modules/implementation/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetImplementationReportQueryType,
    GetImplementationReportQueryVariables,
} from '@modules/types/graphql';

const useImplementationReport = (
    options?: QueryHookOptions<
        GetImplementationReportQueryType,
        GetImplementationReportQueryVariables
    >,
) => {
    const queryResult = useQuery<
        GetImplementationReportQueryType,
        GetImplementationReportQueryVariables
    >(GetImplementationReportQuery, options);

    const { project, implementationReport } = useEntity(() => {
        const project = new ProjectEntity(queryResult.data?.getAssignedProject ?? null);

        const implementationReport = new ImplementationReportEntity(
            queryResult.data?.getAssessmentReport ?? null,
        );

        return {
            project,
            implementationReport,
        };
    }, [queryResult.data]);

    const result = {
        project,
        implementationReport,
        ...queryResult,
    };

    return result;
};

export { useImplementationReport };

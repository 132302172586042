import { routes, RouteMenuLocationEnum } from '@config/routes';

import type { Route } from '@config/routes';

type GetMenuRoutesPayload = {
    isAdmin?: boolean;
    isSuperAdmin?: boolean;
    CO?: boolean;
    OP?: boolean;
};

export const getMenuRoutes = (
    location: RouteMenuLocationEnum,
    payload?: GetMenuRoutesPayload,
): Route[] => {
    const isAdmin = payload?.isAdmin ?? false;
    const isSuperAdmin = payload?.isSuperAdmin ?? false;
    const CO = payload?.CO ?? false;
    const OP = payload?.OP ?? false;

    const menuRoutes = Object.values(routes)
        .filter(
            route =>
                typeof route.menuLocations !== 'undefined' &&
                route.menuLocations.includes(location),
        )
        .filter(route => {
            if (route.forAdmin) {
                return isAdmin;
            }

            return true;
        })
        .filter(route => {
            if (route.forSuperAdmin) {
                return isSuperAdmin;
            }

            return true;
        })
        .filter(route => {
            if (route.name === 'registry.index' && (CO || OP)) {
                return false;
            }
            if (CO && route.CO) {
                return false;
            }
            if (OP && route.OP) {
                return false;
            }
            return true;
        });

    return menuRoutes;
};

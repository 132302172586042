import React, { useContext } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { Button } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { useRoles } from '@modules/assessment/hooks';
import { GetComponentAssessmentQuery } from '@modules/assessment/graphql';
import {
    SubmitRecommendationsToCOMutation,
    SubmitRecommendationsToPMMutation,
    ApproveComponentRoadmapMutation,
    ApproveComponentRoadmapByStackholderMutation,
} from '@modules/roadmap/graphql';

import type { ComponentAssessmentEntity } from '@modules/assessment/entities';
import type {
    SubmitRecommendationsToCoMutationType,
    SubmitRecommendationsToCoMutationVariables,
    SubmitRecommendationsToPmMutationType,
    SubmitRecommendationsToPmMutationVariables,
    ApproveComponentRoadmapMutationType,
    ApproveComponentRoadmapMutationVariables,
    ApproveComponentRoadmapByStackholderMutationType,
    ApproveComponentRoadmapByStackholderMutationVariables,
} from '@modules/types/graphql';
import { AuthContext } from '@modules/auth/context';

type RoadmapComponentAssessmentListItemActionsProps = {
    componentAssessment: ComponentAssessmentEntity;
};

const Root = styled.div`
    width: 100%;
    margin-top: 12px;
`;

const RoadmapComponentAssessmentListItemActions = (
    props: RoadmapComponentAssessmentListItemActionsProps,
): React.ReactElement | null => {
    const { componentAssessment } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { rolesEntityList } = useRoles({ variables: { assessmentId: componentAssessment.id } });
    const context = useContext(AuthContext);
    const hasPmRole = rolesEntityList.hasProgramManager();
    const hasStRole = rolesEntityList.hasStakeholder();
    const hasCoRole = rolesEntityList.hasComponentOwner();
    const hasOpRole = rolesEntityList.hasOperator();

    const componentAssessmentId = componentAssessment.id;

    const isGenerated = componentAssessment.isGeneratedInRoadmap();
    const isReviewed = componentAssessment.isReviewedInRoadmap();
    const isRefined = componentAssessment.isRefinedInRoadmap();
    const isApprovedPm = componentAssessment.isApprovedPmInRoadmap();
    const isApprovedStackholder = componentAssessment.isApprovedStackholderInRoadmap();

    const [submitToCo, { loading: submitToCoLoading }] = useMutation<
        SubmitRecommendationsToCoMutationType,
        SubmitRecommendationsToCoMutationVariables
    >(SubmitRecommendationsToCOMutation);

    const [submitToPm, { loading: submitToPmLoading }] = useMutation<
        SubmitRecommendationsToPmMutationType,
        SubmitRecommendationsToPmMutationVariables
    >(SubmitRecommendationsToPMMutation);

    const [approveByPm, { loading: approveByPmLoading }] = useMutation<
        ApproveComponentRoadmapMutationType,
        ApproveComponentRoadmapMutationVariables
    >(ApproveComponentRoadmapMutation);

    const [approveByStackholder, { loading: approveByStackholderLoading }] = useMutation<
        ApproveComponentRoadmapByStackholderMutationType,
        ApproveComponentRoadmapByStackholderMutationVariables
    >(ApproveComponentRoadmapByStackholderMutation);

    const handleSubmit = async (): Promise<void> => {
        try {
            if (isGenerated) {
                const { data: submitToCoData } = await submitToCo({
                    variables: { assessmentId: componentAssessmentId },
                    refetchQueries: [
                        {
                            query: GetComponentAssessmentQuery,
                            variables: { id: componentAssessmentId },
                        },
                    ],
                    awaitRefetchQueries: true,
                });

                if (submitToCoData?.submitRecommendationsToCO?.id !== 0) {
                    enqueueSuccess('Component successfully reviewed!');
                } else {
                    enqueueError('An error occurred while reviewing component!');
                }
            } else if (isReviewed) {
                const { data: submitToPmData } = await submitToPm({
                    variables: { assessmentId: componentAssessmentId },
                    refetchQueries: [
                        {
                            query: GetComponentAssessmentQuery,
                            variables: { id: componentAssessmentId },
                        },
                    ],
                    awaitRefetchQueries: true,
                });

                if (submitToPmData?.submitRecommendationsToPM?.id !== 0) {
                    enqueueSuccess('Component successfully refined!');
                } else {
                    enqueueError('An error occurred while refining component!');
                }
            } else if (isRefined) {
                const { data: approveByPmData } = await approveByPm({
                    variables: { assessmentId: componentAssessmentId },
                    refetchQueries: [
                        {
                            query: GetComponentAssessmentQuery,
                            variables: { id: componentAssessmentId },
                        },
                    ],
                    awaitRefetchQueries: true,
                });

                if (approveByPmData?.approveComponentRoadmap?.id !== 0) {
                    enqueueSuccess('Component successfully approved by pm!');
                } else {
                    enqueueError('An error occurred while approving component!');
                }
            } else if (isApprovedPm) {
                const { data: approveByStackholderData } = await approveByStackholder({
                    variables: { assessmentId: componentAssessmentId },
                    refetchQueries: [
                        {
                            query: GetComponentAssessmentQuery,
                            variables: { id: componentAssessmentId },
                        },
                    ],
                    awaitRefetchQueries: true,
                });

                if (approveByStackholderData?.approveComponentRoadmapByStackholder?.id !== 0) {
                    enqueueSuccess('Component successfully approved by stackholder!');
                } else {
                    enqueueError('An error occurred while approving component!');
                }
            }
        } catch (e) {
            console.log(e);
            enqueueError('Unexpected error!');
        }
    };

    const isLoading =
        submitToCoLoading || submitToPmLoading || approveByPmLoading || approveByStackholderLoading;

    if (isApprovedStackholder) {
        return null;
    }

    if ((hasCoRole || hasOpRole || hasPmRole) && isReviewed) {
        const canRefine = componentAssessment.capabilities.every(capability =>
            capability.scoredGaps.every(scoredGap => {
                const roadmapRecommendations = scoredGap?.roadmapRecommendations ?? [];

                return (
                    roadmapRecommendations.length !== 0 &&
                    roadmapRecommendations.every(roadmapRecommendation =>
                        roadmapRecommendation?.isRefined(),
                    )
                );
            }),
        );

        return (
            <Root>
                <Button
                    role='refine-by-coop'
                    loading={isLoading}
                    disabled={context?.isFreeze ? true : !canRefine}
                    onClick={handleSubmit}
                >
                    Validate
                </Button>
            </Root>
        );
    }

    if (hasPmRole && (isGenerated || isRefined)) {
        // prettier-ignore
        const label =
            isGenerated ? 'Complete the review' :
                isRefined ? 'Approve by PM' :
                    '';

        const canComplete = componentAssessment.capabilities.every(capability =>
            capability.scoredGaps.every(scoredGap => {
                const roadmapRecommendations = scoredGap?.roadmapRecommendations ?? [];

                return roadmapRecommendations.length !== 0;
            }),
        );

        return (
            <Root>
                <Button
                    role='complete-or-approve-review-by-pm'
                    loading={isLoading}
                    disabled={context?.isFreeze ? true : !canComplete}
                    onClick={handleSubmit}
                >
                    {label}
                </Button>
            </Root>
        );
    }

    // if (hasStRole) {
    //     if (!isApprovedPm) {
    //         return null;
    //     }
    //
    //     return (
    //         <Root>
    //             <Button role='approve-bt-st' loading={isLoading} onClick={handleSubmit}>
    //                 Approve by Stackholder
    //             </Button>
    //         </Root>
    //     );
    // }

    return null;
};

export { RoadmapComponentAssessmentListItemActions };

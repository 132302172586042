import React from 'react';
import styled from 'styled-components';
import { DocumentNode } from '@apollo/client';

import { Modal } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { AWS_REGION, S3_BUCKET, ACCESS_KEY_ID, SECRET_ACCESS_KEY } from '@config/environment';
import AWS from 'aws-sdk';
import { FileUploadForm } from './file-upload-form';

type ProjectCreateModalProps = {
    refetchQuery?: DocumentNode;
    onClose: () => void;
    open: boolean;
};

const FormWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-width: 625px;
    overflow: auto;
    padding: 10px;
    width: 100%;
`;

const FileUploadModal = (props: ProjectCreateModalProps): React.ReactElement => {
    const { onClose, open, refetchQuery, ...otherProps } = props;

    const initialValues = {
        imageUrl: null,
        vidoe_File: null,
    };
    const [progress, setProgress] = React.useState(0);
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const handleSubmit = async (values: any): Promise<void> => {
        try {
            AWS.config.update({
                accessKeyId: ACCESS_KEY_ID,
                secretAccessKey: SECRET_ACCESS_KEY,
            });

            const s3 = new AWS.S3({
                params: { Bucket: S3_BUCKET },
                region: AWS_REGION,
            });

            const params = {
                Bucket: S3_BUCKET,
                Key: values?.video_File?.name,
                Body: values?.video_File,
                ContentType: values?.video_File?.type,
            };

            var upload = s3
                .putObject(params)
                .on('httpUploadProgress', (evt: any) => {
                    setProgress(Math.round((evt.loaded / evt.total) * 100));
                })
                .promise();

            await upload.then(err => {
                alert('File uploaded successfully.');
            });

            const card = values?.video_File?.name;

            if (!card) {
                throw new Error('An error occurred while Uploading the video!');
            }
            enqueueSuccess('File Upload successfully created!');
            onClose();
        } catch (e) {
            throw e;
        }
    };

    return (
        <Modal title={'Video upload in S3 Buckets'} open={open} onClose={onClose} {...otherProps}>
            <FileUploadForm
                type={'create'}
                initialValues={initialValues}
                onClose={onClose}
                FormWrapComponent={FormWrap}
                onSubmit={handleSubmit}
            />
        </Modal>
    );
};

export { FileUploadModal };

import { gql } from '@apollo/client';

import { CapabilityLevelSingleFragment } from '../fragments';

export const CreateCapabilityLevelMutation = gql`
    mutation CreateCapabilityLevel(
        $capabilityId: Int!
        $capabilityLevelDefinitionId: Int!
        $description: String
    ) {
        createCapabilityLevel(
            capability_id: $capabilityId
            capability_level_definition_id: $capabilityLevelDefinitionId
            description: $description
        ) {
            ...CapabilityLevelSingle
        }
    }
    ${CapabilityLevelSingleFragment}
`;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from '@reach/router';
import { useMutation } from '@apollo/client';
import { routes } from '@config/routes';
import { createRouteUrl } from '@lib/routing';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { PageStyled } from '@modules/layout/styled';
import { Loader } from '@modules/layout/moleculas';
import { useCurrentUser } from '@modules/auth/hooks';
import { useAssignedProjects, useProjectsTableFiltersQuery } from '@modules/projects/hooks';
import { ProjectListRequest } from '@modules/projects/requests';
import { ProjectDataTable } from '@modules/projects/moleculas';
import { ProjectListToolbar } from '@modules/projects/organisms';
import { GetAssignedProjectsQuery, DeleteProjectMutation } from '@modules/projects/graphql';

import type { TableColumn } from '@modules/layout/organisms';
import type { Page } from '@modules/types/page';
import type {
    DeleteProjectMutationType,
    DeleteProjectMutationVariables,
} from '@modules/types/graphql';
import { OrgProfilesFormModal } from '@modules/custom-components/organisms';
import { useOrganizationDetails } from '@modules/components/hooks';
import { useUserHighRole } from '@modules/assessment/hooks/use-user-high-role';
import { useCompany, useCompanyAccess } from '@modules/company/hooks';

const columns: TableColumn[] = [
    {
        id: 'projectName',
        label: 'Project name',
    },
    {
        id: 'rr',
        label: 'Module',
    },
    {
        id: 'description',
        label: 'Description',
    },
    {
        id: 'status',
        label: 'Status',
    },
    {
        id: 'maturityLevel',
        label: 'Maturity Level',
    },
    {
        id: 'actions',
        label: '',
    },
];

var isOrgFormFilled: any;

const ProjectsPage = (props: Page): React.ReactElement => {
    const { title = 'Projects' } = props;
    const navigate = useNavigate();
    const params = useParams();

    const proId = params.id ? +params.id : null;
    let variablesId: any = {};

    variablesId.id = proId as number;

    const [handleModal, setHandleModal] = React.useState<boolean>(false);
    const [selectedProjectId, setSelectedProjectId] = React.useState<number | any>(null);

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { filters } = useProjectsTableFiltersQuery();
    const { userHighRolesEntityList, loading: userLoding } = useUserHighRole();

    const variables = new ProjectListRequest(filters);

    const { currentUser, loading: currentUserLoading } = useCurrentUser();

    const { company, loading: loadingCompany } = useCompany({
        skip: !currentUser?.companyId,
        variables: { id: currentUser?.companyId as number },
    });

    const userId = currentUser.id;
    const userName = currentUser?.getFullName();
    const isPm = currentUser?.isAdmin;
    const isSuperAdmin = currentUser?.isSuperAdmin;
    const canDeleteUser = currentUser.hasSuperAdminPermission() || currentUser.hasAdminPermission();

    const { organizationDetails, loading: orgDetailLoading } = useOrganizationDetails();
    const userRoleId: any = userHighRolesEntityList[0]?.welcomeRole;
    const onHandleOrgModal = (): void => {
        setHandleModal(!handleModal);
        localStorage.setItem('modalStatus', 'false');
    };
    const modalStatus: any = localStorage.getItem('modalStatus');

    const { projects, loading: projectsLoading } = useAssignedProjects({
        fetchPolicy: 'network-only',
        variables,
    });

    const { companyAccess, loading: companyLoading } = useCompanyAccess();
    const [deleteProject, { loading: deleteProjectLoading }] = useMutation<
        DeleteProjectMutationType,
        DeleteProjectMutationVariables
    >(DeleteProjectMutation);
    
    const isCreateProject = companyAccess?.isCreateProject;
    const isFreeze = companyAccess?.isFreeze;

    const handleOpenProject = (project: any) => {
        let redirectUrl = routes.projectAssessment;

        if (project.isRoadMap()) {
            redirectUrl = routes.projectRoadmap;
        }

        if (project.isImplementation()) {
            redirectUrl = routes.projectImplementation;
        }

        navigate(createRouteUrl(redirectUrl, { id: project.id }));
    };

    const handleDeleteProject = async (projectId: number): Promise<void> => {
        setSelectedProjectId(projectId);
        try {
            const { data: deleteProjectData } = await deleteProject({
                variables: { id: projectId },
                refetchQueries: [{ query: GetAssignedProjectsQuery }],
                awaitRefetchQueries: true,
            });

            if (deleteProjectData?.deleteProject?.id) {
                enqueueSuccess('Project successfully deleted!');
            } else {
                enqueueError('An error occurred while deleting the project!');
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        isOrgFormFilled = organizationDetails?.user_id ? true : false;
        setHandleModal(true);
        if (isSuperAdmin && isOrgFormFilled) {
            setHandleModal(false);
            localStorage.setItem('modalStatus', 'false');
        } else if (isPm && isOrgFormFilled) {
            setHandleModal(false);
            localStorage.setItem('modalStatus', 'false');
        } else if (isPm || isSuperAdmin) {
            setHandleModal(true);
        } else {
            setHandleModal(false);
        }
        if (modalStatus) {
            setHandleModal(false);
            localStorage.setItem('modalStatus', 'false');
        }
    }, [organizationDetails, isOrgFormFilled]);

    if (orgDetailLoading || loadingCompany || companyLoading) {
        return <Loader />;
    }

    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>{title}</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>

            <PageStyled.Bridge>
                <ProjectListToolbar isFreeze={isFreeze} isCreateProject={isCreateProject} />
            </PageStyled.Bridge>

            <ProjectDataTable
                handleDeleteProject={handleDeleteProject}
                handleClickRedirect={handleOpenProject}
                deleteProjectLoading={deleteProjectLoading}
                selectedProjectId={selectedProjectId}
                canDeleteUser={canDeleteUser}
                columns={columns}
                projectsLoading={projectsLoading}
                projects={projects}
            />

            <OrgProfilesFormModal
                userId={userId}
                username={userName}
                userRoleId={userRoleId}
                open={handleModal}
                companyName={company?.name}
                onClose={onHandleOrgModal}
            />
        </>
    );
};

export { ProjectsPage };

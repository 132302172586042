import { RiskProgramType } from '@modules/shared/constants/risk-assessment-type';
import { CapabilityMaturityLevelEnum } from '@modules/capabilities/constants';

export enum RiskLevel10PointReverse {
    SuperCritical = 'Super Critical',
    Critical = 'Critical',
    HighToCritical = 'High to Critical',
    High = 'High',
    MediumToHigh = 'Medium to High',
    Medium = 'Medium',
    LowToMedium = 'Low to Medium',
    Low = 'Low',
    MinimalToLow = 'Minimal to Low',
    Minimal = 'Minimal',
}

export enum RiskLevel10Point {
    Minimal = 'Minimal',
    MinimalToLow = 'Minimal to Low',
    Low = 'Low',
    LowToMedium = 'Low to Medium',
    Medium = 'Medium',
    MediumToHigh = 'Medium to High',
    High = 'High',
    HighToCritical = 'High to Critical',
    Critical = 'Critical',
    SuperCritical = 'Super Critical',
}

export enum CapabilityLevel {
    NoCapability = 'No Capability',
    Minimal = 'Minimal',
    MinimalToLimited = 'Minimal to Limited',
    Limited = 'Limited',
    LimitedToPartial = 'Limited to Partial',
    Partial = 'Partial',
    PartialToSubstantial = 'Partial to Substantial',
    Substantial = 'Substantial',
    SubstantialToFull = 'Substantial to Full',
    Full = 'Full',
}

export enum ProjectMaturityLevel {
    PreNascent = 'Pre-Nascent',
    Nascent = 'Nascent',
    NascentToLimited = 'Nascent to Limited',
    Limited = 'Limited',
    LimitedToFunctional = 'Limited to Functional',
    Functional = 'Functional',
    FunctionalToOperational = 'Functional to Operational',
    Operational = 'Operational',
    OperationalToMature = 'Operational to Mature',
    Mature = 'Mature',
}

/**
 * Enums/List just for reference as is from DB (including ordering and naming)
 *
 * !!!NA status is UI only, added to be compatible  with DB ID, which in range 1..X
 */

export const RiskLevelList: string[] = ['N/A', ...Object.values(RiskLevel10Point)];
export const CapabilityLevelList: string[] = ['N/A', ...Object.values(CapabilityLevel)];
export const MaturityLevelList: string[] = ['N/A', ...Object.values(ProjectMaturityLevel)];
export const ImpactMaturityLevelList: string[] = ['N/A', ...Object.values(RiskLevel10Point)];
export const MaturityImpactLevelList: string[] = ['N/A', ...Object.values(RiskLevel10Point)];
/**
 * END OF Enums/List
 */

export const RiskLevel10PointKeyList: string[] = Object.keys(RiskLevel10Point);
export const RiskLevel10PointKeyReverseList: string[] = Object.keys(RiskLevel10PointReverse);
// 'Minimal to low' = MinimalToLow
export const RiskLevel10PointKeyMap: Record<string, string> = RiskLevel10PointKeyList.reduce(
    (store: Record<string, string>, key: string) => {
        store[RiskLevel10Point[key]] = key;
        return store;
    },
    {} as Record<string, string>,
);

export const RiskAssessmentLevelMap = new Map<RiskLevel10Point, CapabilityLevel>(
    Object.values(RiskLevel10Point)
        .reverse()
        .map((key: string, index: number) => {
            return [key as RiskLevel10Point, CapabilityLevelList[index + 1] as CapabilityLevel];
        }),
);

export const GapRiskLevel = RiskLevel10Point;

export const GapRiskLevelList = Object.values(GapRiskLevel);
export const GapRiskLevelMap: Map<string, number> = new Map(
    GapRiskLevelList.map((key: string, index: number) => [key, index]),
);
export const riskLevelGeneralFieldList = [
    'minimal',
    'minimalLow',
    'low',
    'lowMedium',
    'medium',
    'mediumHigh',
    'high',
    'highCritical',
    'critical',
    'superCritical',
];

// In DB stored in opposite direction
export const implementationLevelFieldsReversed = [...riskLevelGeneralFieldList];
export const recommendationLevelFieldListReversed = implementationLevelFieldsReversed.map(
    (key: string) => `${key}Recommendations`,
);
export const gapLevelFieldListReversed = implementationLevelFieldsReversed.map(
    (key: string) => `${key}Gaps`,
);

export const implementationLevelFields = [...implementationLevelFieldsReversed].reverse();
export const recommendationLevelFieldList = [...recommendationLevelFieldListReversed].reverse();
export const gapLevelFieldList = [...gapLevelFieldListReversed].reverse();

export const recommendationLevelFieldLabelList = [...GapRiskLevelList].reverse();
export const gapLevelFieldLabelList = [...GapRiskLevelList].reverse();

// Low = lowGaps
export const GapRiskLevelField: Record<string, string> = Object.keys(GapRiskLevel).reduce(
    (data, label, index) => {
        data[label] = gapLevelFieldListReversed[index];
        return data;
    },
    {},
);

// Low = lowRecommendations
export const RecommendationRiskLevelField: Record<string, string> = Object.keys(
    GapRiskLevel,
).reduce((data, label, index) => {
    data[label] = recommendationLevelFieldListReversed[index];
    return data;
}, {});

// Low = low
export const ImplementationRiskLevelField: Record<string, string> = Object.keys(
    GapRiskLevel,
).reduce((data, label, index) => {
    data[label] = implementationLevelFieldsReversed[index];
    return data;
}, {});

// lowGaps = Low
// export const RiskLevelGapFieldMap: Record<string, string> = gapLevelFieldListReversed.reduce((data, key, index) => {
//   data[key] = RiskLevel10PointKeyList[index];
//   return data;
// }, {});

export const scoreRiskMap = {
    0: RiskLevel10Point.Minimal,
    1: RiskLevel10Point.MinimalToLow,
    2: RiskLevel10Point.Low,
    3: RiskLevel10Point.LowToMedium,
    5: RiskLevel10Point.Medium,
    9: RiskLevel10Point.MediumToHigh,
    20: RiskLevel10Point.High,
    40: RiskLevel10Point.HighToCritical,
    50: RiskLevel10Point.Critical,
    80: RiskLevel10Point.SuperCritical,
};
export const scoreImpactMap = {
    5: RiskLevel10Point.Minimal,
    15: RiskLevel10Point.MinimalToLow,
    25: RiskLevel10Point.Low,
    35: RiskLevel10Point.LowToMedium,
    45: RiskLevel10Point.Medium,
    55: RiskLevel10Point.MediumToHigh,
    65: RiskLevel10Point.High,
    75: RiskLevel10Point.HighToCritical,
    85: RiskLevel10Point.Critical,
    95: RiskLevel10Point.SuperCritical,
};
export const scoreGeneralMap = {
    0.05: RiskLevel10Point.Minimal,
    0.14: RiskLevel10Point.MinimalToLow,
    0.23: RiskLevel10Point.Low,
    0.32: RiskLevel10Point.LowToMedium,
    0.41: RiskLevel10Point.Medium,
    0.59: RiskLevel10Point.MediumToHigh,
    0.68: RiskLevel10Point.High,
    0.77: RiskLevel10Point.HighToCritical,
    0.86: RiskLevel10Point.Critical,
    0.95: RiskLevel10Point.SuperCritical,
};

export const capabilityLevelProgress = {
    [CapabilityMaturityLevelEnum.NoCapability]: ' 0%',
    [CapabilityMaturityLevelEnum.Minimal]: ' 5%',
    [CapabilityMaturityLevelEnum.MinimalToLimited]: ' 15%',
    [CapabilityMaturityLevelEnum.Limited]: '25%',
    [CapabilityMaturityLevelEnum.LimitedToPartial]: '38%',
    [CapabilityMaturityLevelEnum.Partial]: '50%',
    [CapabilityMaturityLevelEnum.PartialToSubstantial]: '63%',
    [CapabilityMaturityLevelEnum.Substantial]: '75%',
    [CapabilityMaturityLevelEnum.SubstantialToFull]: '88%',
    [CapabilityMaturityLevelEnum.Full]: '100%',
};

export const impactCapabilityLevelProgress = {
    [CapabilityMaturityLevelEnum.NoCapability]: ' 5%',
    [CapabilityMaturityLevelEnum.Minimal]: ' 15%',
    [CapabilityMaturityLevelEnum.MinimalToLimited]: ' 25%',
    [CapabilityMaturityLevelEnum.Limited]: '35%',
    [CapabilityMaturityLevelEnum.LimitedToPartial]: '45%',
    [CapabilityMaturityLevelEnum.Partial]: '55%',
    [CapabilityMaturityLevelEnum.PartialToSubstantial]: '65%',
    [CapabilityMaturityLevelEnum.Substantial]: '75%',
    [CapabilityMaturityLevelEnum.SubstantialToFull]: '85%',
    [CapabilityMaturityLevelEnum.Full]: '95%',
};

export const scoreIntervalRiskMap = {
    [RiskLevel10Point.Minimal]: {
        min: 0,
        max: 1,
    },
    [RiskLevel10Point.MinimalToLow]: {
        min: 1,
        max: 2,
    },
    [RiskLevel10Point.Low]: {
        min: 2,
        max: 3,
    },
    [RiskLevel10Point.LowToMedium]: {
        min: 3,
        max: 5,
    },
    [RiskLevel10Point.Medium]: {
        min: 5,
        max: 9,
    },
    [RiskLevel10Point.MediumToHigh]: {
        min: 9,
        max: 20,
    },
    [RiskLevel10Point.High]: {
        min: 20,
        max: 40,
    },
    [RiskLevel10Point.HighToCritical]: {
        min: 40,
        max: 50,
    },
    [RiskLevel10Point.Critical]: {
        min: 50,
        max: 80,
    },
    [RiskLevel10Point.SuperCritical]: {
        min: 80,
        max: 100,
    },
};
export const scoreIntervalImpactMap = {
    [RiskLevel10Point.Minimal]: {
        min: 0,
        max: 15,
    },
    [RiskLevel10Point.MinimalToLow]: {
        min: 15,
        max: 25,
    },
    [RiskLevel10Point.Low]: {
        min: 25,
        max: 35,
    },
    [RiskLevel10Point.LowToMedium]: {
        min: 35,
        max: 45,
    },
    [RiskLevel10Point.Medium]: {
        min: 45,
        max: 55,
    },
    [RiskLevel10Point.MediumToHigh]: {
        min: 55,
        max: 65,
    },
    [RiskLevel10Point.High]: {
        min: 65,
        max: 75,
    },
    [RiskLevel10Point.HighToCritical]: {
        min: 75,
        max: 85,
    },
    [RiskLevel10Point.Critical]: {
        min: 85,
        max: 95,
    },
    [RiskLevel10Point.SuperCritical]: {
        min: 95,
        max: 100,
    },
};
export const scoreIntervalGeneralMap = {
    [RiskLevel10Point.Minimal]: {
        min: 0.05,
        max: 0.14,
    },
    [RiskLevel10Point.MinimalToLow]: {
        min: 0.14,
        max: 0.23,
    },
    [RiskLevel10Point.Low]: {
        min: 0.23,
        max: 0.32,
    },
    [RiskLevel10Point.LowToMedium]: {
        min: 0.32,
        max: 0.41,
    },
    [RiskLevel10Point.Medium]: {
        min: 0.41,
        max: 0.59,
    },
    [RiskLevel10Point.MediumToHigh]: {
        min: 0.59,
        max: 0.68,
    },
    [RiskLevel10Point.High]: {
        min: 0.68,
        max: 0.77,
    },
    [RiskLevel10Point.HighToCritical]: {
        min: 0.77,
        max: 0.86,
    },
    [RiskLevel10Point.Critical]: {
        min: 0.86,
        max: 0.95,
    },
    [RiskLevel10Point.SuperCritical]: {
        min: 0.95,
        max: 1,
    },
};

export const getRiskAssessmentLevelLabel = (label: RiskLevel10Point, activityType?: string) => {
    if (activityType !== RiskProgramType.impact && activityType !== RiskProgramType.risk) {
        return RiskAssessmentLevelMap.get(label);
    }

    return label;
};
export const getVulThreatAssessmentLevelLabel = (
    label: RiskLevel10Point,
    activityType?: string,
) => {
    if (activityType !== RiskProgramType.threat && activityType !== RiskProgramType.vulnerability) {
        return RiskAssessmentLevelMap.get(label);
    }

    return label;
};

export function getScoreMap(activityType?: string): Record<string, RiskLevel10Point> {
    if (activityType === RiskProgramType.impact) {
        return scoreImpactMap;
    }
    if (activityType === RiskProgramType.risk) {
        return scoreRiskMap;
    }
    // if (activityType === RiskProgramType.threat) {
    //     return scoreGeneralMap;
    // }
    return scoreGeneralMap;
}

export function getScoreIntervalMap(activityType?: string) {
    if (activityType === RiskProgramType.impact + 'Score') {
        return scoreIntervalImpactMap;
    }
    if (activityType === RiskProgramType.risk + 'Score') {
        return scoreIntervalRiskMap;
    }
    return scoreIntervalGeneralMap;
}

export const scoreImpactList: string[] = Object.keys(scoreImpactMap);
export const labelImpactMap: Record<RiskLevel10Point, number> = scoreImpactList.reduce(
    (store: Record<RiskLevel10Point, number>, key: string) => {
        store[scoreImpactMap[key]] = Number(key);
        return store;
    },
    {} as Record<RiskLevel10Point, number>,
);

export const scoreRiskList: string[] = Object.keys(scoreRiskMap);
export const labelRiskMap: Record<RiskLevel10Point, number> = scoreRiskList.reduce(
    (store: Record<RiskLevel10Point, number>, key: string) => {
        store[scoreRiskMap[key]] = Number(key);
        return store;
    },
    {} as Record<RiskLevel10Point, number>,
);

export const scoreGeneralList: string[] = Object.keys(scoreGeneralMap);
export const labelGeneralMap: Record<RiskLevel10Point, number> = scoreGeneralList.reduce(
    (store: Record<RiskLevel10Point, number>, key: string) => {
        store[scoreGeneralMap[key]] = Number(key);
        return store;
    },
    {} as Record<RiskLevel10Point, number>,
);

export function getLabelMap(activityType?: string): Record<RiskLevel10Point, number> {
    if (activityType === RiskProgramType.impact) {
        return labelImpactMap;
    }
    if (activityType === RiskProgramType.risk) {
        return labelRiskMap;
    }
    return labelGeneralMap;
}

export function getRiskLevelLabelByScore(score: number, activityType?: string): RiskLevel10Point {
    const levels = getScoreMap(activityType);
    if (typeof levels[score] !== 'undefined') {
        return levels[score];
    }

    let valueList = Object.keys(levels);
    // if (activityType !== RiskProgramType.impact && activityType !== RiskProgramType.risk) {
    //   valueList = valueList.reverse();
    // }

    let highLimitIndex: number = valueList.findIndex((num: string) => score < Number(num));
    if (highLimitIndex === -1) {
        return levels[valueList[valueList.length - 1]];
    }
    if (highLimitIndex === 0) {
        return levels[valueList[highLimitIndex]];
    }

    return levels[valueList[highLimitIndex - 1]];

    // let lowLimitIndex: number = highLimitIndex - 1;
    // let averageIndex: number = Number(valueList[highLimitIndex]) - score < score - Number(valueList[lowLimitIndex]) ? highLimitIndex : lowLimitIndex;
    // if (levels[valueList[averageIndex]]) {
    //   return levels[valueList[averageIndex]];
    // }

    // return RiskLevel10Point.Medium;
}

export function ratingNum(score: string | number, type?: string): string | number {
    if (Number.isNaN(Number(score))) {
        return score;
    }

    if (type === RiskProgramType.impact || type === RiskProgramType.risk) {
        return Math.round(Number(score));
    }

    return Math.round(Number(score) * 100);
}

export function getScoreByLevelLabel(level: string, activityType?: string): number {
    const levels = getLabelMap(activityType);

    if (typeof levels[level] !== 'undefined') {
        return levels[level];
    }

    return 55;
}

export function getRiskProgramScore(
    vulnerabilityScore?: number | null,
    threatScore?: number | null,
) {
    return Math.round((vulnerabilityScore ?? 0) * (threatScore ?? 0) * 100);
}

export function getMaturityLevelName(completionLevel: number) {
    if (completionLevel >= 0 && completionLevel < 5) {
        return 'No Capability';
    } else if (completionLevel >= 5 && completionLevel < 15) {
        return 'Minimal';
    } else if (completionLevel >= 15 && completionLevel < 25) {
        return 'Minimal to Limited';
    } else if (completionLevel >= 25 && completionLevel < 38) {
        return 'Limited';
    } else if (completionLevel >= 38 && completionLevel < 50) {
        return 'Limited to Partial';
    } else if (completionLevel >= 50 && completionLevel < 63) {
        return 'Partial';
    } else if (completionLevel >= 63 && completionLevel < 75) {
        return 'Partial to Substantial';
    } else if (completionLevel >= 75 && completionLevel < 88) {
        return 'Substantial';
    } else if (completionLevel >= 88 && completionLevel < 100) {
        return 'Substantial to Full';
    } else {
        return 'Full';
    }
}

export function getImpactLevel(impactLevel: any) {
    let impactmaturitylevel: any;
    switch (impactLevel) {
        case 'No Capability':
            impactmaturitylevel = 'Minimal';
            break;
        case 'Minimal':
            impactmaturitylevel = 'Minimal to Low';
            break;
        case 'Minimal to Limited':
            impactmaturitylevel = 'Low';
            break;
        case 'Limited':
            impactmaturitylevel = 'Low to Medium';
            break;
        case 'Limited to Partial':
            impactmaturitylevel = 'Medium';
            break;
        case 'Partial':
            impactmaturitylevel = 'Medium to High';
            break;
        case 'Partial to Substantial':
            impactmaturitylevel = 'High';
            break;
        case 'Substantial':
            impactmaturitylevel = 'High to Critical';
            break;
        case 'Substantial to Full':
            impactmaturitylevel = 'Critical';
            break;
        case 'Full':
            impactmaturitylevel = 'Super Critical';
            break;
        default:
            impactmaturitylevel = 'Minimal';
    }
    return impactmaturitylevel;
}

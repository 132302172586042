import React, { useContext } from 'react';
import styled from 'styled-components';
import { useDebounce } from 'react-use';
import { useLocationQuery, useMaturityLevels } from '@modules/shared/hooks';
import { TextField } from '@modules/ui/core';
import { InputAdornment, makeStyles, MenuItem } from '@material-ui/core';
import { UserEntity } from '@modules/users/entities';
import { ComponentEntity } from '@modules/components/entities';
import {
    searchIcon,
    useBackgroundStyles,
} from '@modules/projects/moleculas/project-list-filters/input-box-css';
import { GapRiskLevel } from '@modules/shared/constants/risk-level';
import { useImplementationStatuses } from '@modules/shared/hooks/use-implementation-statuses';
import { AuthContext } from '@modules/auth/context';
import { Loader } from '@modules/layout/moleculas';
import { ProjectEntity } from '@modules/projects/entities';

export type ProjectAssessmentFilterValue = {
    componentName: string;
    componentId: number[];
    componentOwnerId: number[];
    maturityLevelId: number[];
    riskLevelId: string[];
    implementationStatus: string[];
};

type SelectboxFilterOption = {
    id: number;
    name: string;
    getFullName?: () => string;
} & Record<string, string | Function>;

type SelectboxFilter = {
    name: string;
    label: string;
    options: SelectboxFilterOption[];
    getOptionLabel: (option: SelectboxFilterOption) => string;
};

type FilterProps = {
    componentOwnerList: UserEntity[];
    componentList?: ComponentEntity[];
    hasRiskLevelFilter?: boolean;
    hasImplementationStatusFilter?: boolean;
    project?: ProjectEntity;
    // projectLoading?: boolean;
    onFilterChange: (filterValues: ProjectAssessmentFilterValue) => void;
};

const Filters = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    // width: 100%;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 20px;

    > * {
        width: 250px;

        &:not(:last-of-type) {
            margin-right: 32px;
        }
    }
`;

const ProjectAssessmentComponentsFilter = (props: FilterProps): React.ReactElement => {
    const {
        componentOwnerList,
        componentList,
        onFilterChange,
        hasRiskLevelFilter,
        hasImplementationStatusFilter,
        project,
    } = props;
    const { maturityLevels: maturityLevelList } = useMaturityLevels();
    const { implementationStatusList } = useImplementationStatuses();
    const authContext = useContext(AuthContext);

    const riskLevelList = Object.keys(GapRiskLevel).map(key => {
        return {
            id: GapRiskLevel[key],
            name: GapRiskLevel[key],
        };
    });
    const [filters, setFilters] = React.useState({
        componentName: '',
        componentId: [],
        componentOwnerId: [],
        maturityLevelId: [],
        riskLevelId: [],
        implementationStatus: [],
    });

    const backgroundClasses = useBackgroundStyles();

    const selectboxFilterList: SelectboxFilter[] = [
        // {
        //   name:'componentId',
        //   label:'Component Name',
        //   options: componentList as any as SelectboxFilterOption[],
        //   getOptionLabel: (option: any) => option?.name
        // },
        {
            name: 'componentOwnerId',
            label: 'Component Owner',
            options: (componentOwnerList as any) as SelectboxFilterOption[],
            getOptionLabel: (option: any) => option?.getFullName(),
        },
        {
            name: 'maturityLevelId',
            label: 'Maturity Level',
            options: (maturityLevelList as any) as SelectboxFilterOption[],
            getOptionLabel: (option: any) => option?.name,
        },
    ].concat(
        hasRiskLevelFilter
            ? [
                  {
                      name: 'riskLevelId',
                      label: project?.isrrimpact ? 'Impact Level' : 'Risk Level',
                      options: (riskLevelList as any) as SelectboxFilterOption[],
                      getOptionLabel: (option: any) => option?.name,
                  },
              ]
            : [],
        !project?.isrrimpact
            ? hasImplementationStatusFilter
                ? [
                      {
                          name: 'implementationStatus',
                          label: 'implementation Status',
                          options: (implementationStatusList as any) as SelectboxFilterOption[],
                          getOptionLabel: (option: any) => option?.name,
                      },
                  ]
                : []
            : [],
    );

    const onOptionSelected = (event: React.ChangeEvent<{ name: string; value: string }>) => {
        const name = event.target.name;
        const value = ((event.target.value as any) as number[]).filter(val => val !== -1);

        setFilters(state => ({ ...state, [name]: value }));
    };

    const onInputChanged = (event: React.ChangeEvent<{ name: string; value: string }>) => {
        const name = event.target.name;
        const value = event.target.value;

        setFilters(state => ({ ...state, [name]: value }));
    };

    useDebounce(
        () => {
            onFilterChange(filters);
        },
        500,
        [filters],
    );
    // if (projectLoading) {
    //   return <Loader />
    // }

    return (
        <Filters>
            {selectboxFilterList.map((field, index) => {
                return project?.isrrimpact && field?.name === 'maturityLevelId' ? (
                    ''
                ) : (
                    <TextField
                        select
                        key={index}
                        name={field.name}
                        label={field.label}
                        value={filters[field.name].length ? filters[field.name] : [-1]}
                        InputLabelProps={{ shrink: true }}
                        // classes={backgroundClasses}
                        SelectProps={{
                            multiple: true,
                        }}
                        onChange={onOptionSelected}
                    >
                        {!filters[field.name].length && (
                            <MenuItem key={-1} value={-1}>
                                All
                            </MenuItem>
                        )}
                        {field.options.length &&
                            field.options.map(option => (
                                <MenuItem key={option?.id} value={option?.id}>
                                    {field.getOptionLabel(option)}
                                </MenuItem>
                            ))}
                    </TextField>
                );
            })}

            <TextField
                corner='round'
                name='componentName'
                label='Component Name'
                value={filters.componentName}
                InputProps={{
                    // classes: backgroundClasses,
                    endAdornment: <InputAdornment position='end'>{searchIcon}</InputAdornment>,
                }}
                onChange={onInputChanged}
            />
        </Filters>
    );
};

export { ProjectAssessmentComponentsFilter };

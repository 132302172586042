import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { User } from '@modules/types/graphql';

export type Entity = Partial<User> | null;

@DecribeEntity('UserEntity')
class UserEntity extends BaseEntity {
    id: number;
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    cognitoId: string;
    isAdmin: boolean;
    isSuperAdmin: boolean;
    companyId: number;
    userRole: string;

    constructor(user: Entity) {
        super(user);

        this.id = user?.id ?? -1;
        this.username = user?.username ?? '';
        this.email = user?.email ?? '';
        this.firstName = user?.first_name ?? '';
        this.lastName = user?.last_name ?? '';
        this.cognitoId = user?.cognito_id ?? '';
        this.isAdmin = user?.is_admin ?? false;
        this.isSuperAdmin = user?.is_super_admin ?? false;
        this.companyId = user?.company_id ?? -1;
        this.userRole = user?.user_role ?? '';
    }

    exists() {
        const isAuth = this.id > 0;

        return isAuth;
    }

    hasAdminPermission() {
        const result = this.isAdmin === true;

        return result;
    }

    hasSuperAdminPermission() {
        const result = this.isSuperAdmin === true;

        return result;
    }

    getFullName() {
        const fullName = (this.firstName + ' ' + this.lastName).trim();

        return fullName;
    }

    hasCreateProject() {
        const result = (this.userRole === 'Project Admin' || this.userRole === 'Program Manager');

        return result;
    }

    hasCreateRisk() {
        const result = (this.userRole === 'Risk Registry Admin' || this.userRole === 'Risk Manager');

        return result;
    }
}

export { UserEntity };

import React from 'react';
import styled from 'styled-components';
import { Avatar, makeStyles } from '@material-ui/core';

import { TableCellText } from '@modules/layout/atoms';

const Root = styled.div`
    display: flex;
    flex-wrap: no-wrap;
    align-items: center;
    justify-content: start;
`;

// const useAvatarStyles = makeStyles({
//     root: {
//         marginRight: '24px',
//         width: 40,
//         height: 40,
//     },
// });

type ProjectNameProps = {
    name: string;
};

const ProjectName = (props: ProjectNameProps): React.ReactElement => {
    const { name } = props;

    // const avatarClasses = useAvatarStyles();

    return (
        <Root>
            {/* <Avatar classes={avatarClasses} alt={name} src="https://i.pinimg.com/originals/a8/c1/54/a8c15493781667d05e628e2f76d65b3a.jpg" /> */}
            <TableCellText variant='head'>{name}</TableCellText>
        </Root>
    );
};

export { ProjectName };

import React, { useContext } from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core';

import { Button } from '@modules/ui/core';
import { white, gray } from '@modules/ui/colors';
import { ListItemStyled } from '@modules/layout/styled';
import { AccordionListItem } from '@modules/layout/organisms';
import { useProjectRoles } from '@modules/projects/hooks';
import { useRoles } from '@modules/assessment/hooks';
import {
    AssessmentComponentFormModal,
    AssessmentCapabilitiesList,
    AssignAssessmentToOperatorFormModal,
} from '@modules/assessment/organisms';

import type { AssessmentComponentsListItemProps } from './assessment-components-list-item';
import { AuthContext } from '@modules/auth/context';

const StyledButton = withStyles({
    root: {
        color: gray[10],
        backgroundColor: white[100],
    },

    label: {
        padding: 0,
    },
})(Button);

const SummaryActions = styled(ListItemStyled.SummaryInfo)`
    min-width: 135px;
    text-align: right;
`;

const StyledAssessmentCapabilitiesList = styled(AssessmentCapabilitiesList)`
    margin-top: 24px;
`;

const AssessmentComponentsListNotAssessedItem = (
    props: AssessmentComponentsListItemProps,
): React.ReactElement => {
    const { project, componentAssessment, open, onExpand } = props;
    const context = useContext(AuthContext);
    const { projectRolesEntityList } = useProjectRoles({ variables: { projectId: project.id } });
    const { rolesEntityList } = useRoles({ variables: { assessmentId: componentAssessment.id } });

    const [openAssignOperatorModal, setOpenAssignOperatorModal] = React.useState<boolean>(false);

    const handleOpenAssignOperatorModal = (): void => setOpenAssignOperatorModal(true);
    const handleCloseAssignOperatorModal = (): void => setOpenAssignOperatorModal(false);

    const [choosenComponentAssessmentId, setChoosenComponentAssessmentId] = React.useState<
        number | undefined
    >(undefined);

    const [openComponentFormModal, setOpenComponentFormModal] = React.useState<boolean>(false);

    const handleOpenComponentFormModal = (): void => setOpenComponentFormModal(true);

    const handleOpenEditComponent = (componentAssessmentId: number) => (
        event: React.MouseEvent<{}>,
    ) => {
        event.stopPropagation();

        setChoosenComponentAssessmentId(componentAssessmentId);
        handleOpenComponentFormModal();
    };

    const handleCloseComponentFormModal = (): void => {
        setChoosenComponentAssessmentId(undefined);
        setOpenComponentFormModal(false);
    };

    const hasOperator = componentAssessment.hasOperator();
    const projectInProgress = project.isAssessment();

    const hasPmRole = projectRolesEntityList.hasProgramManager();
    const hasCoRole = rolesEntityList.hasComponentOwner();

    return (
        <>
            <AccordionListItem
                title={componentAssessment.component.name}
                subtitle={componentAssessment.objectives}
                expanded={open}
                onChange={onExpand(componentAssessment.id)}
                SummaryInfoComponents={
                    <>
                        <ListItemStyled.SummaryInfo>
                            Weight: {componentAssessment.weight}
                        </ListItemStyled.SummaryInfo>

                        <ListItemStyled.SummaryInfo>
                            Capabilities: {componentAssessment.getAccessesCapabilities()}
                        </ListItemStyled.SummaryInfo>

                        {hasPmRole ? (
                            <SummaryActions>
                                {!projectInProgress ? (
                                    <StyledButton
                                        size='small'
                                        variant='outlined'
                                        onClick={handleOpenEditComponent(componentAssessment.id)}
                                    >
                                        Edit component
                                    </StyledButton>
                                ) : null}
                            </SummaryActions>
                        ) : null}
                    </>
                }
            >
                <ListItemStyled.Details>
                    {hasOperator ? (
                        <ListItemStyled.DetailsInfo>
                            Operators: {componentAssessment.operatorsToString()}
                        </ListItemStyled.DetailsInfo>
                    ) : null}

                    <ListItemStyled.DetailsInfo>
                        Component owner: {componentAssessment.componentOwnersToString()}
                    </ListItemStyled.DetailsInfo>
                </ListItemStyled.Details>

                <StyledAssessmentCapabilitiesList
                    project={project}
                    componentAssessment={componentAssessment}
                />

                {hasPmRole || hasCoRole ? (
                    <ListItemStyled.DetailsToolbar>
                        {projectInProgress ? (
                            <Button
                                disabled={context?.isFreeze}
                                onClick={handleOpenAssignOperatorModal}
                            >
                                {hasOperator
                                    ? 'Reassign operator to review this survey'
                                    : 'Assign operator to review this survey'}
                            </Button>
                        ) : null}
                    </ListItemStyled.DetailsToolbar>
                ) : null}
            </AccordionListItem>

            {hasPmRole ? (
                <>
                    <AssessmentComponentFormModal
                        type='edit'
                        project={project}
                        componentAssessmentId={choosenComponentAssessmentId}
                        open={openComponentFormModal}
                        onClose={handleCloseComponentFormModal}
                    />
                </>
            ) : null}

            {(hasPmRole || hasCoRole) && projectInProgress ? (
                <AssignAssessmentToOperatorFormModal
                    componentAssessmentId={componentAssessment.id}
                    project={project}
                    open={openAssignOperatorModal}
                    onClose={handleCloseAssignOperatorModal}
                />
            ) : null}
        </>
    );
};

export { AssessmentComponentsListNotAssessedItem };

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import type { QueryHookOptions } from '@apollo/client';
import type {
    GetRecommendationImpTasksQueryType,
    GetRecommendationImpTasksQueryVariables,
} from '@modules/types/graphql';
import { ImplementationTaskEntityList } from '../entities/implementation-task-entity-list';
import { GetRecommendationImpTasksQuery } from '../graphql';

const useRecommendationImpTasks = (
    options?: QueryHookOptions<
        GetRecommendationImpTasksQueryType,
        GetRecommendationImpTasksQueryVariables
    >,
) => {
    const queryResult = useQuery<
        GetRecommendationImpTasksQueryType,
        GetRecommendationImpTasksQueryVariables
    >(GetRecommendationImpTasksQuery, options);

    const { entities: RecImpTask, count } = useEntity(
        () => new ImplementationTaskEntityList(queryResult.data?.getRecommendationImpTasks ?? null),
        [queryResult.data],
    );

    const result = {
        RecImpTask,
        count,
        ...queryResult,
    };

    return result;
};

export { useRecommendationImpTasks };

import { GapCreateRequest } from './gap-create-request';

import type { UpdateGapMutationVariables } from '@modules/types/graphql';
import type { GapCreateFormValues } from './gap-create-request';

export type GapUpdateFormValues = GapCreateFormValues & {
    gapId: number;
};

class GapUpdateRequest extends GapCreateRequest {
    gapId: UpdateGapMutationVariables['gapId'];

    constructor(values: GapUpdateFormValues) {
        super(values);

        this.gapId = values.gapId;
    }
}

export { GapUpdateRequest };

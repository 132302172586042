import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';
import type { LearnTkoCaption } from '@modules/types/graphql';

export type Entity = Partial<LearnTkoCaption> | null;

@DecribeEntity('LearnTkoCaptionEntity')
class LearnTkoCaptionEntity extends BaseEntity {
    id: number;
    caption: string;

    constructor(caption: Entity) {
        super(caption);

        this.id = caption?.id ?? -1;
        this.caption = caption?.caption ?? '';
    }
}

export { LearnTkoCaptionEntity };

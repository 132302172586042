import { gql } from '@apollo/client';

import { CapabilityAssessmentFragment } from '../fragments';

export const GetCapabilityAssessmentsQuery = gql`
    query GetCapabilityAssessments($assessmentId: Int) {
        listCapabilityAssessments(assessment_id: $assessmentId) {
            ...CapabilityAssessment
        }
    }
    ${CapabilityAssessmentFragment}
`;

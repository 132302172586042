import React from 'react';

import { InnerListItemStyled } from '@modules/layout/styled';

import {CapabilityAssessmentLog} from '@modules/types/graphql';
import {AccordionInnerListItem} from '@modules/layout/organisms';
import styled from 'styled-components';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {format} from "date-fns";
import {GapCapabilityAssessment} from '@modules/assessment/gap-by-risk';

type ReportComponentGapsListItemProps = {
  topGap: GapCapabilityAssessment
};

const HistorySection = styled.div`
    width: 100%;

    > * {
        font-size: 1.4rem;
    }
`;

const ReportComponentGapsListItem = (
    props: ReportComponentGapsListItemProps,
): React.ReactElement => {
    const { scoredGap, capabilityAssessment} = props.topGap;
    const history: CapabilityAssessmentLog[] = capabilityAssessment?.log ?? [] as CapabilityAssessmentLog[];
    const lastItem: CapabilityAssessmentLog = history[0];

  const [expanded, setExpanded] = React.useState<boolean>(false);
  const handleExpandComponent = (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ) => setExpanded(isExpanded);

    return (
        <InnerListItemStyled.Root>
            <InnerListItemStyled.Title className={'text-offset'}>{scoredGap.gap.gap}</InnerListItemStyled.Title>
            <InnerListItemStyled.TextSection className={'text-offset'}><b>Risk Level:</b> {scoredGap.riskLevel}</InnerListItemStyled.TextSection>
            <InnerListItemStyled.TextSection className={'text-offset'}><b>Capability:</b> {capabilityAssessment.capability.title}</InnerListItemStyled.TextSection>
            {history.length ? (
              <>
                <InnerListItemStyled.TextSection className={'text-offset'}><b>{lastItem.action} at {format(new Date(lastItem.action_timestamp), 'dd.MM.yyyy hh:mm')} by {lastItem.user?.first_name} {lastItem.user?.last_name}</b></InnerListItemStyled.TextSection>
                <InnerListItemStyled.TextSection>Gaps: {lastItem.gaps}</InnerListItemStyled.TextSection>
                <InnerListItemStyled.TextSection>Recommendations: {lastItem.recommendations}</InnerListItemStyled.TextSection>
                <InnerListItemStyled.TextSection>Notes: {lastItem.notes}</InnerListItemStyled.TextSection>

                <HistorySection>
                  <AccordionInnerListItem
                    title={'History'}
                    subtitle={''}
                    expanded={expanded}
                    onChange={handleExpandComponent}
                    SummaryInfoComponents={
                      <></>
                    }
                  >

                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell>Gaps</TableCell>
                            <TableCell>Recommendations</TableCell>
                            <TableCell>Notes</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {history.map((log, i) => (
                            <TableRow key={i}>
                              <TableCell>
                                {format(new Date(log.action_timestamp), 'dd.MM.yyyy hh:mm')}
                              </TableCell>
                              <TableCell>
                                {log.user?.first_name} {log.user?.last_name}
                              </TableCell>
                              <TableCell>{log.gaps}</TableCell>
                              <TableCell>{log.recommendations}</TableCell>
                              <TableCell>{log.notes}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                  </AccordionInnerListItem>
                </HistorySection>
              </>
            ) : (
              <InnerListItemStyled.TextSection>No history exist yet</InnerListItemStyled.TextSection>
            )}
        </InnerListItemStyled.Root>
    );
};

export { ReportComponentGapsListItem };

import { BaseEntity, Entity as DecribeEntity, RelationList } from '@lib/entity';

import type { AssessmentDetails } from '@modules/types/graphql';
import type { AllComponentEntityType } from './all-component-entity';
import { AllComponentEntity } from './all-component-entity';
import { AllComponentEntityList } from './all-component-entity-list';

export type AssessmentDetailsEntityType = Partial<
    Omit<AssessmentDetails, 'component'> & {
        component: AllComponentEntityType[] | null;
    }
> | null;

@DecribeEntity('AssessmentDetailsEntity')
class AssessmentDetailsEntity extends BaseEntity {
    id: number;
    name: string;
    companyId: number | null;

    @RelationList(() => AllComponentEntityList)
    component: AllComponentEntity[];

    constructor(details: AssessmentDetailsEntityType) {
        super(details);

        this.id = details?.id ?? -1;
        this.name = details?.name ?? '';
        this.companyId = details?.company_id ?? null;
    }

    isPredefined() {
        const result = this.companyId === null;

        return result;
    }
}

export { AssessmentDetailsEntity };

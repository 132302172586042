import React, { ChangeEvent, useEffect, useMemo } from 'react';

import { ListItemStyled, ListStyled } from '@modules/layout/styled';
import { ProjectAssessmentComponentsFilter } from '@modules/projects/organisms';

import type { ProjectEntity } from '@modules/projects/entities';
import { UserEntity } from '@modules/users/entities';
import { ProjectAssessmentFilterValue } from '@modules/projects/organisms/project-assessment-components-filter/project-assessment-components-filter';
import {
    assessmentCollectCO,
    assessmentFilterHandler,
    componentAssessmentMapFilterHandeler,
} from '@modules/projects/organisms/project-assessment-components-filter/filter-handlers';
import { usePropState } from '@modules/shared/hooks/use-prop-state';
import { ReportGeneralInfo } from '@modules/assessment/organisms/report-components-list/report-general-info';
import { ReportBodyView } from '@modules/assessment/organisms/report-components-list/report-body-view';
import { ExportButton } from '@modules/ui/core/button/exportButton';
import { useGeneralRoles } from '@modules/shared/hooks/use-general-roles';
import { Tab, Tabs } from '@material-ui/core';
import { GapByRisk, GapCapabilityAssessment, GapViewData } from '@modules/assessment/gap-by-risk';
import { ComponentAssessmentEntity } from '@modules/assessment/entities';

type ReportComponentsListProps = {
    project: ProjectEntity;
};

const ReportComponentsList = (props: ReportComponentsListProps): React.ReactElement => {
    const { project } = props;
    // filter to be align with assessment report results

    const componentsAssessmentRawList: ComponentAssessmentEntity[] = useMemo(
        () => project.assessments.filter(value => value.maturityLevel),
        [project.assessments],
    );

    // componentsAssessmentRawList.sort((a, b) => b.id - a.id);
    const componentAssessmentRawMap: Map<number, GapCapabilityAssessment[]> = useMemo(
        () => GapByRisk.gapAssessmentByComponentMap(componentsAssessmentRawList),
        [componentsAssessmentRawList],
    );
    
    const componentOwnerList: UserEntity[] = Array.from(
        assessmentCollectCO(componentsAssessmentRawList).values(),
    );

    const [componentsAssessmentList, setComponentsAssessmentList] = usePropState(
        componentsAssessmentRawList,
    );
    const [componentAssessmentMap, setComponentAssessmentMap] = usePropState(
        componentAssessmentRawMap,
    );
    function onFilterChange(filterValue: ProjectAssessmentFilterValue) {
        assessmentFilterHandler(
            setComponentsAssessmentList,
            componentsAssessmentRawList,
            filterValue,
        );
        componentAssessmentMapFilterHandeler(
            setComponentAssessmentMap,
            componentAssessmentRawMap,
            filterValue,
        );
    }

    const gapViewData: GapViewData = GapByRisk.gapAssessmentFromPreparedMap(componentAssessmentMap);

    const { isGeneralEditAllowed } = useGeneralRoles(project.id);

    const [activeTab, setActiveTab] = React.useState(0);
    const handleChangeTab = (e: ChangeEvent<{}>, newValue: any) => {
        setActiveTab(newValue);
    };

    const tabLable = project?.isrrimpact ? 'Factors' : 'Gaps';
    return (
        <ListStyled.Root>
            <ListStyled.Header>
                <h2>Assessment Report</h2>

                <ListStyled.HeaderInfo>
                    <ExportButton size='small' exportfilename='components-list-report' />
                </ListStyled.HeaderInfo>
            </ListStyled.Header>

            {componentsAssessmentRawList?.length && (
                <ReportGeneralInfo
                    project={project}
                    // projectLoading={projectLoading}
                    // projectAssign={projectAssign}
                    componentsAssessmentRawList={componentsAssessmentRawList}
                    componentAssessmentRawMap={componentAssessmentRawMap}
                    isGeneralEditAllowed={isGeneralEditAllowed}
                    activeTab={activeTab}
                />
            )}

            <ListStyled.HeaderToolbar>
                <Tabs
                    value={activeTab}
                    onChange={handleChangeTab as any}
                    indicatorColor='primary'
                    textColor='primary'
                    className='tab-header'
                >
                    <Tab label='Components' value={0} />
                    <Tab label={tabLable} value={1} />
                </Tabs>
                <ProjectAssessmentComponentsFilter
                    componentOwnerList={componentOwnerList}
                    project={project}
                    hasRiskLevelFilter={true}
                    hasImplementationStatusFilter={true}
                    onFilterChange={onFilterChange}
                />
            </ListStyled.HeaderToolbar>

            {componentsAssessmentRawList?.length && (
                <ReportBodyView
                    componentsAssessmentList={componentsAssessmentList}
                    componentAssessmentRawMap={componentAssessmentRawMap}
                    componentsAssessmentRawList={componentsAssessmentRawList}
                    gapViewData={gapViewData}
                    project={project}
                    activeTab={activeTab}
                />
            )}
        </ListStyled.Root>
    );
};

export { ReportComponentsList };

import { EntityList } from '@lib/entity';
import { CompanyEntity } from './company-entity';

import type { Entity } from './company-entity';

class CompanyEntityList extends EntityList<CompanyEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(CompanyEntity, items, count);
    }
}

export { CompanyEntityList };

import { gql } from '@apollo/client';
export const GetOrganizationDetailsQuery = gql`
    query GetOrganizationDetails {
        getOrganizationDetails {
            user_id
            username
            number_of_employees
            sector
            organization_type
            organization_location
            workforce
            user_role_id
        }
    }
`;

import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { RiskAssignUser, RiskGroup } from '@modules/types/graphql';

export type Entity = Partial<RiskAssignUser>;

@DecribeEntity('RiskAssignUserEntity')
class RiskAssignUserEntity extends BaseEntity {
    userId: number;
    riskGroups: RiskGroup;
    
    constructor(risk: Entity) {
        super(risk);
        this.userId = risk.user_id ?? -1;
        this.riskGroups = risk?.riskGroups ?? {
            id: -1,
            name: '',
            risk_function_id: -1,
        };
    }
}

export { RiskAssignUserEntity };

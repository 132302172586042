import React from 'react';
import styled from 'styled-components';

import { white, gray } from '@modules/ui/colors';
import { InnerListStyled } from '@modules/layout/styled';
import { ReportComponentGapsListItem } from './report-component-gaps-list-item';

import {GapByRisk, GapCapabilityAssessment} from '@modules/assessment/gap-by-risk';

type ReportComponentGapsListProps = {
  gapCapabilityList: GapCapabilityAssessment[];
};

const List = styled(InnerListStyled.List)`
    background-color: ${white[100]};
    border: 1px solid ${gray[60]};
`;

const ReportComponentTopGapsList = (props: ReportComponentGapsListProps): React.ReactElement => {
    const { gapCapabilityList } = props;
    let topGapList: GapCapabilityAssessment[] = [...gapCapabilityList];
    const hasGaps = topGapList.length !== 0;

    GapByRisk.sortGapListByScore(topGapList);
    topGapList = topGapList.slice(0, 5);

    return (
        <InnerListStyled.Root>
            <InnerListStyled.Header>
                {/*<h2>Top Gaps</h2>*/}
            </InnerListStyled.Header>

            <List>
                {hasGaps ? (
                  topGapList.map(topGap => (
                        <ReportComponentGapsListItem key={topGap.scoredGap.id} topGap={topGap} />
                    ))
                ) : (
                    <InnerListStyled.Empty>Gaps not found</InnerListStyled.Empty>
                )}
            </List>
        </InnerListStyled.Root>
    );
};

export { ReportComponentTopGapsList };

import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { AssetType } from '@modules/types/graphql';

export type Entity = Partial<AssetType>;

@DecribeEntity('AssetTypeEntity')
class AssetTypeEntity extends BaseEntity {
    id: number;
    name: string;
    categoryId: number;

    constructor(assetType: Entity) {
        super(assetType);

        this.id = assetType?.id ?? -1;
        this.name = assetType?.name ?? '';
        this.categoryId = assetType.category_id ?? -1;
    }
}

export { AssetTypeEntity };

import { EntityList } from '@lib/entity';
import { RoadmapRecommendationEntity } from './roadmap-recommendation-entity';

import type { Entity } from './roadmap-recommendation-entity';

class RoadmapRecommendationEntityList extends EntityList<RoadmapRecommendationEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(RoadmapRecommendationEntity, items, count);
    }
}

export { RoadmapRecommendationEntityList };

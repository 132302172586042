import React, { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { TableCell, Typography, Paper, List, TableRow, IconButton, Menu } from '@material-ui/core';
import { Edit, MoreVert } from '@material-ui/icons';
import { Button } from '@modules/ui/core';
import { AssetScoreLabel, AssetRiskLabel, ActivityButton, AssetAssessmentList } from '@modules/registry/organisms';
import {
    Asset,
    DeleteAssetMutationType,
    DeleteAssetMutationVariables,
} from '@modules/types/graphql';
import { getRiskProgramScore } from '@modules/shared/constants/risk-level';
import styled from 'styled-components';
import { DeleteAssetMutation } from '@modules/custom-components/graphql';
import { useMutation } from '@apollo/client';
import { GetAssetsQuery } from '@modules/registry/graphql';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { AssetListItemActions } from '@modules/projects/moleculas/asset-list-item-actions';
import { Loader } from '@modules/layout/moleculas';
import { useUserHighRole } from '@modules/assessment/hooks/use-user-high-role';
import { AuthContext } from '@modules/auth/context';

const AssetAreaBox = styled.div<any>`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

function AssetTableRow({
    row,
    refetchQuery,
    setActiveAssetId,
    setOpenIncidentHistoryModal,
    setOpenViewActivityModal,
    setOpenEditUsersModal,
    setOpenAssetManagementModal,
    setOpenEditAssetModal,
    setOpenNewAssessmentModal,
    setOpenViewAssessmentModal,
    setActivityType,
    setActiveAssessment,
}: any) {
    let {
        id,
        name,
        riskScore,
        impactProgram,
        vulnerabilityProgram,
        threatProgram,
        owner,
        pm,
        stakeholders,
        total_number_of_users_to,
        impactActivities,
        vulnerabilityActivities,
        threatActivities,
        impactScore,
        vulnerabilityScore,
        threatScore,
    } = row.original as Asset;
    
    const [isDeleteAsset, setIsDeleteAsset] = useState<null>(null);
    const open: any = Boolean(isDeleteAsset);
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const { user, loading: userLoading } = React.useContext(AuthContext) || {};
    const [deleteAsset, { loading: deleteAssetLoading }] = useMutation<
        DeleteAssetMutationType,
        DeleteAssetMutationVariables
    >(DeleteAssetMutation);

    const handleOpenDeleteModel = (event: any) => {
        setIsDeleteAsset(event.currentTarget);
    };
    const handleClose = () => {
        setIsDeleteAsset(null);
    };

    const { userHighRolesEntityList, loading: userRoleLoading } = useUserHighRole();
    const userHighRole: string = userHighRolesEntityList[0]?.welcomeRole;

    const handleDeleteAssetRow = async (assetId: any): Promise<void> => {
        try {
            const { data: deleteAssetData } = await deleteAsset({
                variables: { assetId: assetId },
                refetchQueries: [{ query: refetchQuery }],
                awaitRefetchQueries: true,
            });

            if (deleteAssetData?.deleteAsset) {
                enqueueSuccess('Asset successfully deleted!');
                handleClose();
            } else {
                enqueueError('An error occurred while deleting the asset!');
            }
        } catch (e) {
            console.log(e);
        }
    };

    const isRMAndPMRole = (role: string) => {
        return (role === 'RM' || role === 'RA' || user?.roles[0] === 'risk_admin' || user?.roles[0] === 'risk_manager') ? true : false;
    };
    if (!riskScore && riskScore !== 0) {
        riskScore = getRiskProgramScore(vulnerabilityScore, threatScore);
    }
    if (deleteAssetLoading) {
        return <Loader />;
    }

    return (
        <TableRow {...row.getRowProps()} className='is-active-row'>
            <TableCell {...row.cells[0].getCellProps()}>
                <AssetAreaBox>
                    <Typography gutterBottom variant='h4'>
                        {name}
                    </Typography>
                    {isRMAndPMRole(userHighRole) ? (
                        <AssetListItemActions onDelete={handleDeleteAssetRow} assetId={id} />
                    ) : (
                        <MoreVert />
                    )}
                </AssetAreaBox>

                <ul>
                    <li>
                        <strong>Asset owner: </strong>
                        <span>
                            {owner?.first_name} {owner?.last_name}
                        </span>
                    </li>
                    <li>
                        <strong>Project manager: </strong>
                        <span>
                            {pm?.first_name} {pm?.last_name}
                        </span>
                    </li>
                    <li>
                        <strong>Stakeholders: </strong>
                        <span>
                            {stakeholders
                                ?.map((sh: any) => {
                                    return sh?.first_name + ' ' + sh?.last_name;
                                })
                                .join(', ')}
                        </span>
                    </li>
                </ul>

                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gap: '10px',
                        margin: '15px 0',
                    }}
                >
                    <Button
                        size='small'
                        variant='outlined'
                        fullWidth
                        disabled={!isRMAndPMRole(userHighRole)}
                        onClick={() => {
                            setOpenEditAssetModal(true);
                            setActiveAssetId(id);
                        }}
                    >
                        <Edit style={{ marginTop: 5 }} />
                        <span> Edit</span>
                    </Button>

                    <Button
                        size='small'
                        variant='outlined'
                        fullWidth
                        disabled={!isRMAndPMRole(userHighRole)}
                        onClick={() => {
                            setOpenEditUsersModal(true);
                            setActiveAssetId(id);
                        }}
                    >
                        {total_number_of_users_to ? 'Users: ' + total_number_of_users_to : 'Users'}
                    </Button>
                </div>

                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gap: '10px',
                        margin: '10px 0 15px',
                    }}
                >
                    <Button
                        style={{ marginBottom: 10 }}
                        fullWidth
                        size='small'
                        disabled={!isRMAndPMRole(userHighRole)}
                        variant='outlined'
                        onClick={() => {
                            setOpenAssetManagementModal(true);
                            setActiveAssetId(id);
                        }}
                    >
                        Management
                    </Button>
                    <Button
                        fullWidth
                        size='small'
                        variant='outlined'
                        disabled={!isRMAndPMRole(userHighRole)}
                        onClick={() => {
                            setOpenIncidentHistoryModal(true);
                            setActiveAssetId(id);
                        }}
                    >
                        Incidents
                    </Button>
                </div>
            </TableCell>

            <TableCell {...row.cells[1].getCellProps()}>
                {!riskScore ? (
                    <div className='is-status'>Pending</div>
                ) : (
                    <>
                        <div className='is-status-donut'>
                            <AssetScoreLabel score={riskScore} type='risk' />
                        </div>
                        <div className='is-status-donut'>
                            <AssetRiskLabel score={riskScore} type='risk' />
                        </div>
                    </>
                )}
            </TableCell>

            <TableCell {...row.cells[2].getCellProps()}>
                {impactScore === null || !impactScore ? (
                    <div className='is-status'>Pending</div>
                ) : (
                    <div className='is-status-donut'>
                        <AssetScoreLabel score={impactScore} type='impact' />
                    </div>
                )}

                <AssetAssessmentList
                    setOpenViewAssessmentModal={setOpenViewAssessmentModal}
                    setOpenNewAssessmentModal={setOpenNewAssessmentModal}
                    setActiveAssetId={setActiveAssetId}
                    activeAsset={row?.original}
                    activityType='impact'
                    setActivityType={setActivityType}
                    setActiveAssessment={setActiveAssessment}
                />

                {impactActivities && impactActivities.length ? (
                    <ActivityButton
                        onClick={() => {
                            setOpenViewActivityModal(true);
                            setActiveAssetId(id);
                            setActivityType('impact');
                        }}
                        activeAsset={row?.original}
                        activityType='impact'
                    />
                ) : (
                    <Button
                        variant='outlined'
                        size='extra-small'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            background: '#fff',
                            marginTop: 10,
                            minHeight: 50,
                        }}
                        onClick={() => {
                            setOpenViewActivityModal(true);
                            setActiveAssetId(id);
                            setActivityType('impact');
                        }}
                    >
                        + New Value
                    </Button>
                )}
            </TableCell>

            <TableCell {...row.cells[3].getCellProps()}>
                {vulnerabilityScore === null || !vulnerabilityScore ? (
                    <div className='is-status'>Pending</div>
                ) : (
                    <div className='is-status-donut'>
                        <AssetScoreLabel score={vulnerabilityScore} type='vulnerability' />
                    </div>
                )}

                <AssetAssessmentList
                    setOpenViewAssessmentModal={setOpenViewAssessmentModal}
                    setOpenNewAssessmentModal={setOpenNewAssessmentModal}
                    setActiveAssetId={setActiveAssetId}
                    activeAsset={row?.original}
                    activityType='vulnerability'
                    setActivityType={setActivityType}
                    setActiveAssessment={setActiveAssessment}
                />

                {vulnerabilityActivities && vulnerabilityActivities.length ? (
                    <ActivityButton
                        onClick={() => {
                            setOpenViewActivityModal(true);
                            setActiveAssetId(id);
                            setActivityType('vulnerability');
                        }}
                        activeAsset={row?.original}
                        activityType='vulnerability'
                    />
                ) : (
                    <Button
                        variant='outlined'
                        size='extra-small'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            background: '#fff',
                            marginTop: 10,
                            minHeight: 50,
                        }}
                        onClick={() => {
                            setOpenViewActivityModal(true);
                            setActiveAssetId(id);
                            setActivityType('vulnerability');
                        }}
                    >
                        + New activity
                    </Button>
                )}
            </TableCell>

            <TableCell {...row.cells[4].getCellProps()}>
                {threatScore === null || !threatScore ? (
                    <div className='is-status'>Pending</div>
                ) : (
                    <div className='is-status-donut'>
                        <AssetScoreLabel score={threatScore} type='threat' />
                    </div>
                )}

                <AssetAssessmentList
                    setOpenViewAssessmentModal={setOpenViewAssessmentModal}
                    setOpenNewAssessmentModal={setOpenNewAssessmentModal}
                    setActiveAssetId={setActiveAssetId}
                    activeAsset={row?.original}
                    activityType='threat'
                    setActivityType={setActivityType}
                    setActiveAssessment={setActiveAssessment}
                />

                {threatActivities && threatActivities.length ? (
                    <ActivityButton
                        onClick={() => {
                            setOpenViewActivityModal(true);
                            setActiveAssetId(id);
                            setActivityType('threat');
                        }}
                        activeAsset={row?.original}
                        activityType='threat'
                    />
                ) : (
                    <Button
                        variant='outlined'
                        size='extra-small'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            background: '#fff',
                            marginTop: 10,
                            minHeight: 50,
                        }}
                        onClick={() => {
                            setOpenViewActivityModal(true);
                            setActiveAssetId(id);
                            setActivityType('threat');
                        }}
                    >
                        + New activity
                    </Button>
                )}
            </TableCell>
        </TableRow>
    );
}

export { AssetTableRow };

import { uniqBy } from 'lodash';
import { BaseEntity, Entity as DecribeEntity, Relation, RelationList } from '@lib/entity';

import type { UserEntity, UserEntityType } from '@modules/users/entities';
import { UserEntityList } from '@modules/users/entities';
import type { ComponentEntityType } from '@modules/components/entities';
import { ComponentEntity } from '@modules/components/entities';
import { CapabilityAssessmentEntityList } from './capability-assessment-entity-list';
import type { ProjectMaturityLevelEnum } from '@modules/projects/constants';
import type { ComponentAssessment, MaturityLevel } from '@modules/types/graphql';
import type {
    CapabilityAssessmentEntity,
    Entity as CapabilityAssessmentEntityType,
} from './capability-assessment-entity';

export type Entity = Partial<
    Omit<ComponentAssessment, 'capabilities' | 'component_owners' | 'operators' | 'component'> & {
        capabilities: CapabilityAssessmentEntityType[] | null;
        component_owners: UserEntityType[] | null;
        operators: UserEntityType[] | null;
        component: ComponentEntityType;
    }
> | null;

@DecribeEntity('ComponentAssessmentEntity')
class ComponentAssessmentEntity extends BaseEntity {
    id: number;
    status: string;
    roadmapStatus: string;
    objectives: string;
    weight: number;
    maturityLevel: MaturityLevel;

    // TODO: need add alias options to Relation and Relation List
    @RelationList(() => CapabilityAssessmentEntityList)
    capabilities: CapabilityAssessmentEntity[];

    @RelationList(() => UserEntityList)
    componentOwners: UserEntity[];

    @RelationList(() => UserEntityList)
    operators: UserEntity[];

    @Relation(() => ComponentEntity)
    component: ComponentEntity;

    constructor(assessment: Entity) {
        super(assessment);

        this.id = assessment?.id ?? -1;
        this.roadmapStatus = assessment?.roadmap_status ?? '';
        this.status = assessment?.status ?? '';
        this.weight = assessment?.weight ?? 0;
        this.objectives = assessment?.objectives ?? '';
        this.maturityLevel = assessment?.maturity_level ?? {
            id: -1,
            name: '',
        };
    }

    isApprovedStackholderInRoadmap() {
        const result = this.roadmapStatus.toLowerCase() === 'approved by stakeholder';

        return result;
    }

    isApprovedPmInRoadmap() {
        const result = this.roadmapStatus.toLowerCase() === 'approved by pm';

        return result;
    }

    isRefinedInRoadmap() {
        const result = this.roadmapStatus.toLowerCase() === 'refined';

        return result;
    }

    isReviewedInRoadmap() {
        const result = this.roadmapStatus.toLowerCase() === 'reviewed';

        return result;
    }

    isGeneratedInRoadmap() {
        const result = this.roadmapStatus.toLowerCase() === 'generated';

        return result;
    }

    getMaturityLevel() {
        if (this.maturityLevel.id <= 0 || !this.maturityLevel.name) {
            return null;
        }

        const maturityLevel = this.maturityLevel.name;

        return maturityLevel as ProjectMaturityLevelEnum;
    }

    getImpactMaturityLevel() {
        if (this.maturityLevel.id <= 0 || !this.maturityLevel.name) {
            return null;
        }

        const maturityLevel = this.maturityLevel.name;
        let impactmaturitylevel: any;
        switch (maturityLevel) {
            case 'Pre-Nascent':
                impactmaturitylevel = 'Minimal';
                break;
            case 'Nascent':
                impactmaturitylevel = 'Minimal to Low';
                break;
            case 'Nascent to Limited':
                impactmaturitylevel = 'Low';
                break;
            case 'Limited':
                impactmaturitylevel = 'Low to Medium';
                break;
            case 'Limited to Functional':
                impactmaturitylevel = 'Medium';
                break;
            case 'Functional':
                impactmaturitylevel = 'Medium to High';
                break;
            case 'Functional to Operational':
                impactmaturitylevel = 'High';
                break;
            case 'Operational':
                impactmaturitylevel = 'High to Critical';
                break;
            case 'Operational to Mature':
                impactmaturitylevel = 'Critical';
                break;
            case 'Mature':
                impactmaturitylevel = 'Super Critical';
                break;
            default:
                impactmaturitylevel = 'Minimal';
        }
        return impactmaturitylevel;
    }

    getCapabilitiesInput() {
        const capabilitiesInput = this.capabilities.map(capabilityAssessment =>
            capabilityAssessment
                ? {
                      capability_id: capabilityAssessment.capability.id,
                      weight: capabilityAssessment.weight ?? 1,
                  }
                : null,
        );

        return capabilitiesInput;
    }

    hasComponent() {
        const result = this.component.id > 0;

        return result;
    }

    hasCapabilities() {
        const result = this.capabilities.length > 0;

        return result;
    }

    isAssess() {
        const isAssess = this.status.toLowerCase() === 'accessed';

        return isAssess;
    }

    isApproved() {
        const isApproved = this.status.toLowerCase() === 'approved';

        return isApproved;
    }

    getAccessesCapabilities() {
        const accessed = this.capabilities.filter(
            capabilityAssessment => !!capabilityAssessment.assessedAt,
        ).length;

        const total = this.capabilities.length;

        if (total === 0) {
            return '0';
        }

        const result = accessed + ' of ' + total;

        return result;
    }

    isAllCapabilitiesAssessed() {
        const accessed = this.capabilities.filter(
            capabilityAssessment => !!capabilityAssessment.assessedAt,
        ).length;

        const total = this.capabilities.length;

        const result = accessed === total;

        return result;
    }

    componentOwnersToString() {
        if (this.componentOwners.length === 0) {
            return '--';
        }

        const result = uniqBy(this.componentOwners, 'id')
            .map(owner => owner.getFullName())
            .join(', ');

        return result;
    }

    hasOperator() {
        const hasOperator = this.operators.length !== 0;

        return hasOperator;
    }

    operatorsToString() {
        if (!this.hasOperator()) {
            return '';
        }

        return uniqBy(this.operators, 'id')
            .map(operator => operator.getFullName())
            .join(', ');
    }
}

export { ComponentAssessmentEntity };

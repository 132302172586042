import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetAssessmentTypeByIdQueryType,
    GetAssessmentTypeByIdVariables,
} from '@modules/types/graphql';
import { GetAssessmentTypeByIdQuery } from '../graphql/queries';
import { AssessmentTypeEntity } from '@modules/shared/entities';

const useGetAssessmentTypeById = (
    options?: QueryHookOptions<GetAssessmentTypeByIdQueryType, GetAssessmentTypeByIdVariables>,
) => {
    const queryResult = useQuery<GetAssessmentTypeByIdQueryType, GetAssessmentTypeByIdVariables>(
        GetAssessmentTypeByIdQuery,
        {
            ...options,
        },
    );

    const getAsseessmentTypeById = useEntity(
        () => new AssessmentTypeEntity(queryResult.data?.getAssessmentType ?? null),
        [queryResult.data],
    );

    const result = {
        getAsseessmentTypeById,
        ...queryResult,
    };

    return result;
};

export { useGetAssessmentTypeById };

import { QueryHookOptions, useQuery } from '@apollo/client';
import { GetAskAllTkoDetailsQueryType, GetLearnTkoMenuListQueryType } from '@modules/types/graphql';
import { useEntity } from '@lib/entity';
import { GetLearnTkoMenuListQuery } from '../graphql';
import { LearnTkoMenuDetailsEntityList } from '../entities';

const useLearnTkoMenuList = (option?: QueryHookOptions<GetLearnTkoMenuListQueryType>) => {
    const queryResult = useQuery<GetLearnTkoMenuListQueryType>(GetLearnTkoMenuListQuery, option);

    const { entities: learnTkoMenuList, count } = useEntity(
        () => new LearnTkoMenuDetailsEntityList(queryResult.data?.getLearnTkoMenuList ?? null),
        [queryResult.data],
    );

    const result = {
        learnTkoMenuList,
        count,
        ...queryResult,
    };
    return result;
};

export { useLearnTkoMenuList };

import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { ProjectForQuery } from '@modules/types/graphql';

export type Entity = Partial<ProjectForQuery> | null;

@DecribeEntity('ProjectDetailQueryEntity')
class ProjectDetailQueryEntity extends BaseEntity {
    id: number;
    name: string;
    description: string | null;
    companyId: number | null;
    programStatus: string | null;
    assessmentTypeId: number;
    tkoScopeId: number;

    constructor(project: Entity) {
        super(project);

        this.id = project?.id ?? -1;
        this.name = project?.name ?? '';
        this.description = project?.description ?? '';
        this.companyId = project?.company_id ?? -1;
        this.programStatus = project?.program_status ?? '';
        this.assessmentTypeId = project?.assessment_type_id ?? -1;
        this.tkoScopeId = project?.tko_scpoe_type ?? -1;
    }
}

export { ProjectDetailQueryEntity };

export type RejectRecommendationRequestFormValues = {
    notes: string;
};

export type RejectRecommendationRequestExtra = {
    roadmapRecommendationId: number;
};

class RejectRecommendationRequest {
    roadmapRecommendationId: number;
    notes: string;

    constructor(
        values: RejectRecommendationRequestFormValues,
        extra: RejectRecommendationRequestExtra,
    ) {
        this.roadmapRecommendationId = extra.roadmapRecommendationId;
        this.notes = values.notes;
    }
}

export { RejectRecommendationRequest };

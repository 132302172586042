import React from 'react';
import styled from 'styled-components';

import logoPath from './images/risktko-logo.svg';

const Root = styled.div`
    width: 91px;

    > img {
        width: 100%;
        height: 100%;
    }
`;

const Logo = (): React.ReactElement => {
    return (
        <Root>
            <img src={logoPath} alt='' />
        </Root>
    );
};

export { Logo };

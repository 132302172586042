import { gql } from '@apollo/client';

import { UserFragment } from '@modules/users/graphql';
import { ComponentAssessmentFragment } from '@modules/assessment/graphql';
import { ComponentRecommendationFragment } from '@modules/roadmap/graphql';

export const GetProjectQuery = gql`
    query GetProject($id: Int!) {
        getProject(id: $id) {
            id
            name
            description
            status
            company_id
            stakeholder {
                ...User
            }
            maturity_level {
                id
                name
            }
            assessments {
                ...ComponentAssessment
            }
            roadmap {
                project_id
                super_critical_recommendations {
                    ...ComponentRecommendation
                }
                critical_recommendations {
                    ...ComponentRecommendation
                }
                high_critical_recommendations {
                    ...ComponentRecommendation
                }
                high_recommendations {
                    ...ComponentRecommendation
                }
                medium_high_recommendations {
                    ...ComponentRecommendation
                }
                medium_recommendations {
                    ...ComponentRecommendation
                }
                low_medium_recommendations {
                    ...ComponentRecommendation
                }                
                low_recommendations {
                    ...ComponentRecommendation
                }
                minimal_low_recommendations {
                    ...ComponentRecommendation
                }
                minimal_recommendations {
                    ...ComponentRecommendation
                }
            }
        }
    }
    ${UserFragment}
    ${ComponentAssessmentFragment}
    ${ComponentRecommendationFragment}
`;

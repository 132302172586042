import { gql } from '@apollo/client';
import { LearnCardMenuFragment } from '../fragments';

export const GetSingleMenuCardQuery = gql`
    query GetMenuCard($menuId: Int!) {
        getMenuCard(menu_id: $menuId) {
            ...MenuCards
        }
    }
    ${LearnCardMenuFragment}
`;

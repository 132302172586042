import { EntityList } from '@lib/entity';
import { CapabilityLevelDefinitionEntity } from './capability-level-definition-entity';

import type { Entity } from './capability-level-definition-entity';

class CapabilityLevelDefinitionEntityList extends EntityList<CapabilityLevelDefinitionEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(CapabilityLevelDefinitionEntity, items, count);
    }
}

export { CapabilityLevelDefinitionEntityList };

import { EntityList } from '@lib/entity';
import { RecommendationImplementationEntity } from './recommendation-implementation-entity';

import type { Entity } from './recommendation-implementation-entity';

class RecommendationImplementationEntityList extends EntityList<RecommendationImplementationEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(RecommendationImplementationEntity, items, count);
    }
}

export { RecommendationImplementationEntityList };

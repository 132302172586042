import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { Modal } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { ImpTaskCreateRequest, ImpTaskCreateFormValues } from '@modules/custom-components/requests';

import type { ModalProps } from '@modules/ui/core';
import type {
    CreateImplementationTaskMutationType,
    CreateImplementationTaskMutationVariables,
} from '@modules/types/graphql';
import { ImplementationTaskForm } from './implementation-task-form';
import { CreateImplementationTaskMutation } from '@modules/implementation/graphql/mutations';
import { GetRecommendationImpTasksQuery } from '@modules/implementation/graphql';

type ImplementationTaskCreateFormModalProps = Omit<ModalProps, 'title' | 'children'> & {
    recommendationId: number;
    companyId?: number;
    onSuccess?: () => Promise<void> | void;
};

const FormWrap = styled.div`
    width: 100%;
`;

const ImplementationTaskCreateModal = (props: ImplementationTaskCreateFormModalProps) => {
    const { recommendationId, companyId, onClose, onSuccess, ...otherProps } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [createImplementationTask] = useMutation<
        CreateImplementationTaskMutationType,
        CreateImplementationTaskMutationVariables
    >(CreateImplementationTaskMutation);

    const initialValues = {
        recommendationId,
        companyId,
        task: '',
        isAiTasks: true,
    };

    const handleSubmit = async (values: ImpTaskCreateFormValues) => {
        try {
            let result = undefined;

            if (recommendationId) {
                const variables = new ImpTaskCreateRequest({
                    task: values.task,
                    companyId: values.companyId,
                    recommendationId: recommendationId,
                    isAiTasks: false,
                });

                const { data: createTaskData } = await createImplementationTask({
                    variables,
                    refetchQueries: [
                        {
                            query: GetRecommendationImpTasksQuery,
                            variables: { recommendationId },
                        },
                    ],
                    awaitRefetchQueries: true,
                });

                result = createTaskData?.createImplementationTask?.id;
            }

            if (result) {
                enqueueSuccess('Task successfully created!');

                if (onSuccess) {
                    await onSuccess();
                }

                onClose();
            } else {
                enqueueError('An error occurred while creating Task!');
            }
        } catch (e) {
            throw e;
        }
    };

    return (
        <Modal title='Create New Task' onClose={onClose} {...otherProps}>
            <ImplementationTaskForm<'create'>
                type='create'
                initialValues={initialValues}
                FormWrapComponent={FormWrap}
                onSubmit={handleSubmit}
                onCancel={onClose}
            />
        </Modal>
    );
};

export { ImplementationTaskCreateModal };

import { Card, Container, Grid, makeStyles } from '@material-ui/core';
import React from 'react';

type AskTkoCardProps = {
    askTkoCardQueryData: string[];
    handleQuery: (query: number) => void;
};

const useStyles = makeStyles({
    askTkoCard: {
        background: '#fff',
        color: '#000',
        padding: '30px 20px',
        fontSize: '16px',
        borderRadius: '16px',
        fontWeight: 'bold',
        cursor: 'pointer',
        minHeight: '100px',
        border: '2px solid #2657e2',
        transition: '0.4s',
        marginBottom: '20px',
        textAlign: 'center',
        '&:hover': {
            background: '#e9e9e9',
        },
    },
});

const AskTkoQueryCard = (props: AskTkoCardProps): React.ReactElement => {
    const { askTkoCardQueryData, handleQuery } = props;
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            {askTkoCardQueryData &&
                askTkoCardQueryData?.map((ele: string, id: number) => (
                    <React.Fragment key={id}>
                        <Grid item md={4} sm={6} xs={12}>
                            <Card className={classes.askTkoCard} onClick={() => handleQuery(id)}>
                                <p>{ele}</p>
                            </Card>
                        </Grid>
                    </React.Fragment>
                ))}
        </Grid>
    );
};

export { AskTkoQueryCard };

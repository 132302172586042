import { GapCapabilityAssessment } from '@modules/assessment/gap-by-risk';

export enum ImplementationStatus {
    // NA status is UI only, added to be compatible  with DB ID, which in range Pending = 1 .. Done = 3
    NA = 'N/A',

    Pending = 'Pending',
    InProgress = 'In progress',
    Done = 'Done',
}

export const ImplementationStatusList: string[] = [...Object.values(ImplementationStatus)];
export const ImplementationStatusMap: Map<string, number> = new Map(
    ImplementationStatusList.map((key: string, index: number) => [key, index]),
);

function getMitigationMultiplyFactor(ImplementationStatusId: number) {
    return ImplementationStatusId < 2 ? 0 : ImplementationStatusId - 1;
}

export function calcGapMitigationLevel(
    recordCount: number,
    gapList: GapCapabilityAssessment[],
): number {
    if (!recordCount) {
        return 0;
    }

    const statusCountMap: Map<string, number> = new Map(
        ImplementationStatusList.map((key: string, index: number) => [key, 0]),
    );

    gapList.forEach((gap: GapCapabilityAssessment) => {
        statusCountMap.set(
            gap.implementationStatus,
            (statusCountMap.get(gap.implementationStatus) ?? 0) + 1,
        );
    });
    let countDone: number =
        recordCount *
        getMitigationMultiplyFactor(ImplementationStatusMap.get(ImplementationStatus.Done) ?? 0);
    let currentScore = Array.from(ImplementationStatusMap).reduce(
        (count, implementationData: [string, number]) => {
            //return count + getMitigationMultiplyFactor(implementationData[1]) * (statusCountMap.get(implementationData[0]) ?? 0);
            return (
                getMitigationMultiplyFactor(implementationData[1]) *
                (statusCountMap.get(implementationData[0]) ?? 0)
            );
        },
        0,
    );
    return Math.round((currentScore * 100) / countDone);
}

export function calcRecommendationStatusScore(
    recordCount: number,
    statusCountMap?: Map<string, number>,
): number[] {
    if (!recordCount || !statusCountMap || !statusCountMap.size) {
        return [0, 0, 0];
    }

    let result: number[] = [
        Math.round(((statusCountMap.get(ImplementationStatus.Pending) ?? 0) * 100) / recordCount),
        Math.round(
            ((statusCountMap.get(ImplementationStatus.InProgress) ?? 0) * 100) / recordCount,
        ),
    ];
    result[2] = 100 - result[0] - result[1];

    return result;
}

import { gql } from '@apollo/client';

import { CapabilitySingleFragment } from '../fragments';

export const UpdateCapabilityMutation = gql`
    mutation UpdateCapability(
        $capabilityId: Int!
        $componentId: Int!
        $title: String!
        $impact: String
        $keyFactors: String
        $securityControls: String
    ) {
        updateCapability(
            capability_id: $capabilityId
            component_id: $componentId
            title: $title
            impact: $impact
            key_factors: $keyFactors
            security_controls: $securityControls
        ) {
            ...CapabilitySingle
        }
    }
    ${CapabilitySingleFragment}
`;

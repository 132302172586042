import styled from 'styled-components';

import { gray } from '@modules/ui/colors';

const InnerListStyled = {
    Root: styled.div`
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    `,

    Header: styled.div`
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;

        > h2 {
            font-size: 2rem;
            font-weight: 700;
        }
    `,

    List: styled.div`
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        margin-top: 12px;

        > *:not(:last-child) {
            border-bottom: 1px solid ${gray[50]};
        }
    `,

    Empty: styled.div`
        width: 100%;
        padding: 16px;
        font-size: 1.4rem;
    `,
};

const InnerListItemStyled = {
    Root: styled.div`
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        padding: 16px;
    `,

    Title: styled.div`
        width: 100%;
        font-size: 1.6rem;
        
        &.text-offset {
            margin-bottom: 10px;
        }
    `,

    TextSection: styled.div`
        width: 100%;
        font-size: 1.4rem;
        
        &.text-offset {
            margin-bottom: 10px;
        }        
    `,

    SummaryInfo: styled.div`
        font-size: 1.4rem;
    `,

    Details: styled.div`
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
        margin-top: 8px;
    `,

    DetailInfo: styled.div`
        max-width: 30%;
        width: 100%;
        margin-right: 16px;

        > p:first-child {
            width: 100%;
            font-size: 1.4rem;
            font-weight: 600;
        }

        > p:last-child {
            width: 100%;
            margin-top: 6px;
            margin-right: 8px;
            font-size: 1.2rem;
        }
    `,
};

export { InnerListStyled, InnerListItemStyled };

import { gql } from '@apollo/client';

export const CompanyDetailsFragment = gql`
    fragment CompanyDetails on CompanyDetails {
        id
        user_id
        name
        address
        email
        phone
        contact_name
        package_type
        subscription_type
        project_count
        remaining_projects
        is_ask_tko
        is_learn_tko
        schema_name
        expires_at
    }
`;

import React, { useMemo } from 'react';
import styled from 'styled-components';

import { gray } from '@modules/ui/colors';
import { ListStyled } from '@modules/layout/styled';
import { ProjectMaturityLevel } from '@modules/projects/moleculas';
import type { ComponentReportEntity } from '@modules/assessment/entities';
import { ComponentAssessmentEntity } from '@modules/assessment/entities';
import { TableCell, TableRow } from '@material-ui/core';
import { ReportComponentInnerItem } from '@modules/assessment/organisms/report-components-list/report-component-inner-item';
import { GapCapabilityAssessment } from '@modules/assessment/gap-by-risk';
import { calcGapMitigationLevel } from '@modules/shared/constants/implementation-status';
import { ProjectEntity } from '@modules/projects/entities';
import { ProjectImpactMaturityLevel } from '@modules/projects/moleculas/project-maturity-level';

type ReportComponentListItemProps = {
    componentReport?: ComponentReportEntity;
    assessment: ComponentAssessmentEntity;
    componentAssessmentMap: Map<number, GapCapabilityAssessment[]>;
    project?: ProjectEntity;
    componentsAssessmentRawList?: any;
};

export const ReportComponentListItem = (
    props: ReportComponentListItemProps,
): React.ReactElement => {
    const { assessment, componentAssessmentMap, project, componentsAssessmentRawList } = props;
    const maturityLevel = assessment.getMaturityLevel();
    const [openComponent, setOpenComponent] = React.useState(false);
    const impactmaturityLevel = assessment.getImpactMaturityLevel();
    // alternatively use getCountByComponentSingle
    const gapCount: number = componentAssessmentMap.get(assessment.id)?.length ?? 0;
    // const [level, levelName] = getCapabilityLevelByComponent(componentReport);
    const level: number = assessment.maturityLevel.id ?? 0;
    const componentCapabilityLevel = calcGapMitigationLevel(
        gapCount,
        componentAssessmentMap?.get(assessment?.id) as GapCapabilityAssessment[],
    );
    // const componentCapabilityLevel = Math.round(level / (CapabilityLevelList.length - 1) * 100);

    function onRowToggle() {
        setOpenComponent(!openComponent);
    }

    return (
        <>
            {project?.isrrimpact ? (
                <>
                    <TableRow onClick={onRowToggle} className={'row-expand'}>
                        <TableCell component='th' scope='row'>
                            {assessment.component.name}
                            <br />
                            {assessment.objectives && <sup>{assessment.objectives}</sup>}
                        </TableCell>
                        <TableCell>
                            {impactmaturityLevel ? (
                                <ProjectImpactMaturityLevel current={impactmaturityLevel} />
                            ) : (
                                'N/A'
                            )}
                        </TableCell>
                        <TableCell>{assessment?.capabilities?.length ?? 0}</TableCell>
                    </TableRow>
                </>
            ) : (
                <>
                    <TableRow onClick={onRowToggle} className={'row-expand'}>
                        <TableCell component='th' scope='row'>
                            {assessment.component.name}
                            <br />
                            {assessment.objectives && <sup>{assessment.objectives}</sup>}
                        </TableCell>
                        <TableCell>
                            {maturityLevel ? (
                                <ProjectMaturityLevel current={maturityLevel} />
                            ) : (
                                'N/A'
                            )}
                        </TableCell>
                        <TableCell>{assessment?.capabilities?.length ?? 0}</TableCell>
                        <TableCell>{gapCount}</TableCell>
                        <TableCell>{componentCapabilityLevel}%</TableCell>
                    </TableRow>
                </>
            )}
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                    <ReportComponentInnerItem
                        project={project}
                        assessment={assessment}
                        componentsAssessmentRawList={componentsAssessmentRawList}
                        recordCount={gapCount}
                        openComponent={openComponent}
                        componentAssessmentMap={componentAssessmentMap}
                    />
                </TableCell>
            </TableRow>
        </>
    );
};

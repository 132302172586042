import React from 'react';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import { Modal, Backdrop, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { white } from '@modules/ui/colors';

import type { TransitionStatus } from 'react-transition-group/Transition';

type SidebarDirection = 'left' | 'right';

export type SidebarProps = {
    onClose: (event: React.BaseSyntheticEvent) => void;
    direction: SidebarDirection;
    open?: boolean;
    children?: React.ReactElement | React.ReactElement[] | React.ReactNode | null;
};

const ANIMATE_TIMEOUT = 300;

const directionTransitionTranslates = {
    left: {
        entering: '0',
        entered: '0',
        exiting: '-100%',
        exited: '-100%',
    },
    right: {
        entering: '0',
        entered: '0',
        exiting: '100%',
        exited: '100%',
    },
};

const Root = styled.div<Pick<SidebarProps, 'direction'> & { state: TransitionStatus }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: ${p => (p.direction === 'left' ? 0 : 'initial')};
    right: ${p => (p.direction === 'right' ? 0 : 'initial')};
    width: 100%;
    height: 100vh;
    max-width: 400px;
    padding: 12px 0;
    background: ${white[100]};
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
        0px 8px 10px rgba(0, 0, 0, 0.14);
    will-change: transform;
    transition: transform 125ms cubic-bezier(0.42, 0, 1, 1) 0ms;
    transform: translateX(${p => directionTransitionTranslates[p.direction][p.state]});
    outline: none;
`;

const CloseBtn = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    padding: 0 12px;
    cursor: pointer;
`;

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 100%;
    width: 100%;
`;

const closeIcon = <Close style={{ fontSize: 24 }} />;

const Sidebar = (props: SidebarProps): React.ReactElement => {
    const { direction, open = false, onClose, children } = props;

    return (
        <Modal
            disablePortal
            closeAfterTransition
            open={open}
            onClose={onClose}
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: ANIMATE_TIMEOUT }}
        >
            <Transition appear in={open} timeout={ANIMATE_TIMEOUT}>
                {state => (
                    <Root tabIndex={1} state={state} direction={direction}>
                        <CloseBtn onClick={onClose}>
                            <IconButton>{closeIcon}</IconButton>
                        </CloseBtn>
                        <Inner>{children}</Inner>
                    </Root>
                )}
            </Transition>
        </Modal>
    );
};

export { Sidebar };

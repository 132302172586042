import { gql } from '@apollo/client';

import { ComponentAssessmentFragment } from '../fragments';

export const GetComponentAssessmentQuery = gql`
    query GetComponentAssessment($id: Int!) {
        getComponentAssessment(id: $id) {
            ...ComponentAssessment
        }
    }
    ${ComponentAssessmentFragment}
`;

import React from 'react';
import { makeStyles, TextField as MuiTextField } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

import type { TextFieldProps } from '@material-ui/core';

const useSelectStyles = makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    icon: {
        top: 'calc(50% - 8px)',
        fontSize: 16,
    },
});

const useInputLabelStyles = makeStyles({
    outlined: {
        transform: `translate(14px, 18px) scale(1)`,
    },
});

const useInputStyles = makeStyles({
    root: {
        height: 48,
    },
});

const TextField = (props: TextFieldProps): React.ReactElement => {
    const { SelectProps, InputLabelProps, InputProps, ...otherProps } = props;

    const selectClasses = useSelectStyles();
    const inputLabelClasses = useInputLabelStyles();
    const inputClasses = useInputStyles();

    return (
        <MuiTextField
            InputLabelProps={{
                classes: inputLabelClasses,
                ...InputLabelProps,
            }}
            InputProps={{
                classes: inputClasses,
                ...InputProps,
            }}
            SelectProps={{
                displayEmpty: true,
                classes: selectClasses,
                IconComponent: KeyboardArrowDown,
                MenuProps: {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                },
                ...SelectProps,
            }}
            {...otherProps}
        />
    );
};

export { TextField };

import { createGlobalStyle } from 'styled-components';

import { black, white, gray } from '@modules/ui/colors';

const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
    }

    *, *::before, *::after {
        box-sizing: border-box;
    }

    html {
        width: 100%;
        height: 100%;
        font-size: 10px;
        font-family: 'Roboto', sans-serif;
    }

    body {
        width: 100%;
        height: 100%;
        color: ${black[100]};
        font-size: 1rem;
        font-weight: 400;
        background-color: ${white[100]};
        overflow: auto;

        &.modal-lock {
            padding-right: 16px;
            overflow: hidden;
        }
    }

    #root {
        width: 100%;
        height: 100%;
    }
    
    a {
        color: inherit;
        text-decoration: none;
        transition: all .2s linear;
    }

    ul, ol {
        list-style: none;
    }
    
    .draggable-table-row {
        background-color: ${white[100]};
        border-radius: 4px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);
        
        td {
          min-width: 200px;
        }
    }
`;

export { GlobalStyles };

import { EntityList } from '@lib/entity';
import { TaskEntity } from './task-entity';

import type { Entity } from './task-entity';

class TaskEntityList extends EntityList<TaskEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(TaskEntity, items, count);
    }
}

export { TaskEntityList };

import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { useEnqueueStacks } from '@modules/layout/hooks';
import {
    AddCustomRecommendationRequest,
    RecommendationCreateRequest,
} from '@modules/custom-components/requests';
import { RecommendationForm } from '@modules/custom-components/organisms';
import {
    AddCustomRecommendationMutation,
    CreateRecommendationMutation,
    GetRecommendationsQuery,
} from '@modules/custom-components/graphql';

import type { RecommendationCreateFormValues } from '@modules/custom-components/requests';
import type {
    CreateRecommendationMutationType,
    CreateRecommendationMutationVariables,
    AddCustomRecommendationMutationType,
    AddCustomRecommendationMutationVariables,
} from '@modules/types/graphql';

type RecommendationCreateFormProps = {
    gapId: number;
    scoredGapId?: number;
    companyId?: number;
    onCancel?: () => void;
    onSuccess?: () => Promise<void> | void;
};

const Root = styled.div`
    width: 100%;
`;

const FormWrap = styled.div``;

const RecommendationCreateForm = (props: RecommendationCreateFormProps): React.ReactElement => {
    const { scoredGapId, gapId, companyId, onSuccess, onCancel } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [createRecommendation] = useMutation<
        CreateRecommendationMutationType,
        CreateRecommendationMutationVariables
    >(CreateRecommendationMutation);

    const [addCustomRecommendation] = useMutation<
        AddCustomRecommendationMutationType,
        AddCustomRecommendationMutationVariables
    >(AddCustomRecommendationMutation);

    const recommendationInitialValues = {
        recommendation: '',
    };

    const handleCreateRecommendationSubmit = async (values: RecommendationCreateFormValues) => {
        try {
            let result = undefined;

            if (scoredGapId) {
                const variables = new AddCustomRecommendationRequest({
                    scoredGapId,
                    recommendation: values.recommendation,
                });

                const { data: addCustomRecommendationData } = await addCustomRecommendation({
                    variables,
                    refetchQueries: [
                        { query: GetRecommendationsQuery, variables: { gapId, companyId } },
                    ],
                    awaitRefetchQueries: true,
                });

                result = addCustomRecommendationData?.addCustomRecommendation?.id;
            } else {
                const variables = new RecommendationCreateRequest(values, { gapId, companyId });

                const { data: createRecommendationData } = await createRecommendation({
                    variables,
                    refetchQueries: [
                        { query: GetRecommendationsQuery, variables: { gapId, companyId } },
                    ],
                    awaitRefetchQueries: true,
                });

                result = createRecommendationData?.createRecommendation?.id;
            }

            if (result) {
                enqueueSuccess('Recommendation successfully created!');

                await onSuccess?.();

                onCancel?.();
            } else {
                enqueueError('An error occurred while creating recommendation!');
            }
        } catch (e) {
            throw e;
        }
    };

    return (
        <Root>
            <RecommendationForm<'create'>
                type='create'
                initialValues={recommendationInitialValues}
                onSubmit={handleCreateRecommendationSubmit}
                FormWrapComponent={FormWrap}
                onCancel={onCancel}
            />
        </Root>
    );
};

export { RecommendationCreateForm };

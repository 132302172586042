import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { Button } from '@modules/ui/core';
import { white } from '@modules/ui/colors';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { Loader } from '@modules/layout/moleculas';
import { useCurrentUser } from '@modules/auth/hooks';
import { useCapabilityLevel, useGaps } from '@modules/custom-components/hooks';
import { RecommendationCreateModal } from '@modules/custom-components/moleculas';
import {
    GapCreateModal,
    GapEditModal,
    RecommendationEditModal,
} from '@modules/custom-components/organisms';
import { GetGapsQuery, DeleteGapMutation } from '@modules/custom-components/graphql';
import { CapabilityLevelGapItem } from './capability-level-gap-item';

import { DeleteGapMutationType, DeleteGapMutationVariables } from '@modules/types/graphql';

type CapabilityLevelGapListProps = {
    capabilityLevelId: number;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 24px 0;
    padding: 16px 24px;
    background-color: ${white[100]};
`;

const Toolbar = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
`;

const List = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

const CapabilityLevelGapList = (props: CapabilityLevelGapListProps): React.ReactElement => {
    const { capabilityLevelId } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const { currentUser } = useCurrentUser();
    const isAdmin = currentUser.hasSuperAdminPermission();

    const { capabilityLevel, loading: capabilityLevelLoading } = useCapabilityLevel({
        variables: { capabilityLevelId },
    });

    const { gaps, loading: gapsLoading } = useGaps({
        variables: { capabilityLevelId },
    });

    const [deleteGap, { loading: deleteGapLoading }] = useMutation<
        DeleteGapMutationType,
        DeleteGapMutationVariables
    >(DeleteGapMutation);

    const handleDeleteGap = async (gapId: number): Promise<void> => {
        try {
            const { data: deleteGapData } = await deleteGap({
                variables: { gapId, capabilityLevelId },
                refetchQueries: [{ query: GetGapsQuery, variables: { capabilityLevelId } }],
                awaitRefetchQueries: true,
            });

            if (deleteGapData?.deleteGap) {
                enqueueSuccess('Gap successfully deleted!');
            } else {
                enqueueError('An error occurred while deleting the gap!');
            }
        } catch (e) {
            console.log(e);
        }
    };

    const [openCreateGapModal, setOpenCreateGapModal] = React.useState<boolean>(false);

    const handleOpenCreateGapModal = (): void => setOpenCreateGapModal(true);
    const handleCloseCreateGapModal = (): void => setOpenCreateGapModal(false);

    const [editableGapId, setEditableGapId] = React.useState<number | null>(null);

    const handleSetEditableGapId = (id: number): void => setEditableGapId(id);
    const handleResetEditableGapId = (): void => setEditableGapId(null);

    const [gapIdForCreateRecommendation, setGapIdForCreateRecommendation] = React.useState<
        number | null
    >(null);

    const handleSetGapIdForCreateRecommendation = (id: number): void =>
        setGapIdForCreateRecommendation(id);

    const handleResetGapIdForCreateRecommendation = (): void =>
        setGapIdForCreateRecommendation(null);

    const [gapIdForEditRecommendation, setGapIdForEditRecommendation] = React.useState<
        number | null
    >(null);

    const handleResetGapIdForEditRecommendation = (): void => setGapIdForEditRecommendation(null);

    if (capabilityLevelLoading || gapsLoading) {
        return (
            <Root>
                <Loader />
            </Root>
        );
    }

    const canAddGap = capabilityLevel.isPredefined() ? isAdmin : true;

    return (
        <Root>
            {canAddGap ? (
                <Toolbar>
                    <Button size='small' onClick={handleOpenCreateGapModal}>
                        Add gap
                    </Button>
                </Toolbar>
            ) : null}

            <List>
                {gaps.map(gap => (
                    <CapabilityLevelGapItem
                        key={gap.id}
                        gap={gap}
                        loadingDelete={deleteGapLoading}
                        onCreateRecommendation={handleSetGapIdForCreateRecommendation}
                        onClickEdit={handleSetEditableGapId}
                        onDelete={handleDeleteGap}
                    />
                ))}
            </List>

            <GapCreateModal
                capabilityLevelId={capabilityLevelId}
                open={openCreateGapModal}
                onClose={handleCloseCreateGapModal}
            />

            {editableGapId ? (
                <GapEditModal
                    open
                    gapId={editableGapId}
                    capabilityLevelId={capabilityLevelId}
                    onClose={handleResetEditableGapId}
                />
            ) : null}

            {gapIdForCreateRecommendation ? (
                <RecommendationCreateModal
                    disableBackdropClick
                    open
                    gapId={gapIdForCreateRecommendation}
                    onClose={handleResetGapIdForCreateRecommendation}
                />
            ) : null}

            {gapIdForEditRecommendation ? (
                <RecommendationEditModal
                    disableBackdropClick
                    open
                    gapId={gapIdForEditRecommendation}
                    onClose={handleResetGapIdForEditRecommendation}
                />
            ) : null}
        </Root>
    );
};

export { CapabilityLevelGapList };

import type { CreateGapMutationVariables } from '@modules/types/graphql';

export type GapCreateFormValues = {
    capabilityLevelId: number;
    gap: string;
    companyId?: number;
    projectId: number;
};

class GapCreateRequest {
    capabilityLevelId: CreateGapMutationVariables['capabilityLevelId'];
    gap: CreateGapMutationVariables['gap'];
    companyId: CreateGapMutationVariables['companyId'];
    projectId: CreateGapMutationVariables['projectId'];

    constructor(values: GapCreateFormValues) {
        this.capabilityLevelId = values.capabilityLevelId;
        this.gap = values.gap;
        this.companyId = values.companyId;
        this.projectId = values.projectId;
    }
}

export { GapCreateRequest };

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { UserHighRoleEntityList } from '@modules/assessment/entities';
import { GetUserHighRoleQuery } from '@modules/assessment/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetUserHighestRoleQueryType } from '@modules/types/graphql';
import { useAuth } from '@modules/auth/context';

const useUserHighRole = (options?: QueryHookOptions<GetUserHighestRoleQueryType>) => {
    const { loading, authenticated } = useAuth();

    const { loading: queryLoading, ...queryResult } = useQuery<GetUserHighestRoleQueryType>(
        GetUserHighRoleQuery, {
            skip: !authenticated,
            ...options,
        } );

    const { entities: userHighRolesEntityList, count } = useEntity(
        () => new UserHighRoleEntityList(queryResult?.data?.getUserHighestRole ?? null),
        [authenticated, queryResult.data],
    );

    const result = { 
        userHighRolesEntityList,
        loading: loading || queryLoading,
        count,
        ...queryResult
    };

    return result;
};

export { useUserHighRole };

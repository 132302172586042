import { gql } from '@apollo/client';

import { CapabilityAssessmentFragment } from '../fragments';

export const GetCapabilityAssessmentQuery = gql`
    query GetCapabilityAssessment($surveyId: Int!) {
        getCapabilityAssessment(survey_id: $surveyId) {
            ...CapabilityAssessment
        }
    }
    ${CapabilityAssessmentFragment}
`;

import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Form } from 'formik';
import { useNavigate } from '@reach/router';

import { routes } from '@config/routes';
import { ExtendedFormik } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';
import { TextField, Button, Autocomplete } from '@modules/ui/core';
import { FormControlLabel, MenuItem } from '@material-ui/core';
import { usePackages, useSubscriptions } from '@modules/company/hooks';
import { CompnayCreateFormValues } from '@modules/projects/requests';
import { Checkbox } from '@material-ui/core';
import { TableCellText, TableCheckbox } from '@modules/layout/atoms';
import { Label } from '@material-ui/icons';
type CompanyFormProps<T = CompnayCreateFormValues> = FormProps<T> & {
    onCancel?: () => void;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;
const CheckBoxField = styled.div`
    display: flex;
    align-items: center;
    font-size: 18px;
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;
// UpdateCompanyFormValues;
const CompanyForm: any =
    // <T extends FormSemanticType = 'create'>UpdateCompanyFormValues
    (
        props: CompanyFormProps,
        // <T>,
    ): React.ReactElement => {
        let { initialValues, FormWrapComponent, onSubmit, type } = props;

        const navigate = useNavigate();
        const { Packages, loading: packageDetailLoading } = usePackages();
        const { Subscriptions, loading: subscriptionsDetailLoading } = useSubscriptions();

        const handleClickCancel = (): Promise<void> => navigate(routes.companies.path);

        const isCreate = type === 'create' ? 'Create a company' : 'Update Company';
        const isEdit = type === 'edit';

        return (
            <ExtendedFormik
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={yup.object({
                    name: yup.string().required('Required field'),
                    address: yup.string().required('Required field'),
                    contactName: yup.string().required('Required field'),
                    email: yup.string().email().required('Required field'),
                    phone: yup
                        .string()
                        .required('Phone number is required')
                        .matches(/^\d{10,12}$/, 'Invalid phone number')
                        .matches(/^\d*$/, 'Phone number must contain only numbers'),
                    packageType: yup.mixed().required('Required field'),
                    subscriptionType: yup.mixed().required('Required field'),
                })}
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                {(formikProps: {
                    values: any;
                    errors: any;
                    handleChange: any;
                    handleBlur: any;
                    isSubmitting: any;
                }) => {
                    const { values, errors, handleChange, handleBlur, isSubmitting } = formikProps;

                    return (
                        <FormWrapComponent>
                            <StyledForm>
                                <TextField
                                    fullWidth
                                    id='name'
                                    label='Company name'
                                    value={values.name}
                                    error={!!errors.name}
                                    helperText={errors.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <TextField
                                    fullWidth
                                    id='contactName'
                                    label='Contact Name'
                                    value={values.contactName}
                                    error={!!errors.contactName}
                                    helperText={errors.contactName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <TextField
                                    fullWidth
                                    id='address'
                                    label='Address'
                                    value={values.address}
                                    error={!!errors.address}
                                    helperText={errors.address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <TextField
                                    fullWidth
                                    id='email'
                                    label='Email'
                                    value={values.email}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <TextField
                                    fullWidth
                                    id='phone'
                                    name='phone'
                                    label='Phone'
                                    value={values.phone}
                                    error={!!errors.phone}
                                    onBlur={handleBlur}
                                    helperText={errors.phone}
                                    onChange={handleChange}
                                />
                                <TextField
                                    select
                                    fullWidth
                                    multiline
                                    name='packageType'
                                    label='Packages'
                                    error={!!errors.packageType}
                                    helperText={errors.packageType}
                                    value={values?.packageType}
                                    disabled={packageDetailLoading}
                                    onChange={handleChange}
                                >
                                    {Packages &&
                                        Packages?.map((Packages: { id: number; name: string }) => (
                                            <MenuItem key={Packages?.id} value={Packages?.name}>
                                                {Packages?.name}
                                            </MenuItem>
                                        ))}
                                </TextField>

                                <TextField
                                    select
                                    fullWidth
                                    multiline
                                    name='subscriptionType'
                                    label='Subscriptions'
                                    error={!!errors.subscriptionType}
                                    helperText={errors.subscriptionType}
                                    value={values?.subscriptionType}
                                    disabled={subscriptionsDetailLoading}
                                    onChange={handleChange}
                                >
                                    {Subscriptions &&
                                        Subscriptions?.map(
                                            (Subscriptions: { id: number; name: string }) => (
                                                <MenuItem
                                                    key={Subscriptions?.id}
                                                    value={Subscriptions?.name}
                                                >
                                                    {Subscriptions?.name}
                                                </MenuItem>
                                            ),
                                        )}
                                </TextField>
                                <CheckBoxField>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values?.isAskTko}
                                                value={values?.isAskTko}
                                                onChange={handleChange}
                                                name='isAskTko'
                                            />
                                        }
                                        label='Ask Tko'
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values?.isLearnTko}
                                                value={values?.isLearnTko}
                                                onChange={handleChange}
                                                name='isLearnTko'
                                            />
                                        }
                                        label='Learn Tko'
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values?.isSchema}
                                                value={values?.isSchema}
                                                onChange={handleChange}
                                                name='isSchema'
                                                disabled={isEdit}
                                            />
                                        }
                                        label='Create DataBase'
                                    />
                                </CheckBoxField>

                                <FormStyled.FormRowSubmit>
                                    <SubmitButton type='submit' loading={isSubmitting}>
                                        {isCreate}
                                    </SubmitButton>

                                    <SubmitButton variant='text' onClick={handleClickCancel}>
                                        Cancel
                                    </SubmitButton>
                                </FormStyled.FormRowSubmit>
                            </StyledForm>
                        </FormWrapComponent>
                    );
                }}
            </ExtendedFormik>
        );
    };

export { CompanyForm };

import React from 'react';
import styled from 'styled-components';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

import { Button } from '@modules/ui/core';
import { TableCellText } from '@modules/layout/atoms';
import {
    RecommendationFormModal,
    RecommendationAcceptFormModal,
    RecommendationModifyFormModal,
    RecommendationAcknowledgeFormModal,
    RecommendationRejectFormModal,
} from '@modules/components/organisms';
import { ProjectMaturityLevelEnum } from '@modules/projects/constants';
import { MaturityLevel, RecommendationCounter } from '@modules/projects/moleculas';
import { CreateRecommendationModal } from '@modules/custom-components/organisms';

const useStyles = makeStyles({
    hide: {
        opacity: 0,
        transition: 'opacity 0.5s linear',
    },
    show: {
        opacity: 1,
        transition: 'opacity 0.5s linear',
    },
    accordionSummaryContent: {
        alignItems: 'center',
        padding: '10px',
        justifyContent: 'space-between',
    },
});

const Root = styled.div`
    width: 100%;
`;

const Recommendation = styled.div``;

const RecommendationText = styled.div`
    flex-basis: 60%;
    display: flex;
    align-items: center;
`;

const RecommendationControls = styled.div`
    display: flex;
    justify-content: flex-end;

    > * {
        margin: 0 5px;
    }
`;

const RecommendationTitle = styled.div`
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 13px;
`;

const RecommendationList = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    border-top: 1px solid #c4c4c4;

    > * {
        display: flex;
        justify-content: space-between;
        slign-items: center;
        padding: 16px 5px;
        border-bottom: 1px solid #c4c4c4;
        color: #4f4f4f;
        font-size: 14px;
    }
`;

const CapabilityHead = styled.h2`
    margin: 30px 0 10px 0;
    font-weight: 700;
    font-size: 18px;
`;

const List = styled.div`
    width: 100%;
    margin: 32px 0;

    > * {
        margin-bottom: 8px;
    }
`;

const ComponentToolbar = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 25px;
    padding-left: 20px;

    > :first-child {
        margin-right: 10px;
    }
`;

const LevelTitle = styled.div`
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 30px;
`;

const AccordionDetailsStyled = styled(AccordionDetails)`
    flex-direction: column;
    justify-content: space-between;
    padding: 0 30px;
`;

const ActionButton = styled(Button)`
    color: #333333;
    margin: 0px;
    padding: 0 20px;
    background-color: #ffffff;

    > .MuiButton-label {
        padding: 0;
    }
`;

const AccordionStyled = styled(Accordion)`
    background-color: #ffffff;
`;

const TableCellTextRow = styled(TableCellText)`
    flex-shrink: 0;
`;

const TableCellTextTitle = styled(TableCellTextRow)`
    font-size: 16px;
    font-weight: 500;
`;

const mock = [0, 1];

const CompletionLevels = (): React.ReactElement => {
    const [expanded, setExpanded] = React.useState<number | boolean>(false);

    const classes = useStyles();
    const [openGapFormModal, setOpenGapFormModal] = React.useState<boolean>(false);
    const [
        openAcceptRecommendationFormModal,
        setOpenAcceptRecommendationFormModal,
    ] = React.useState<boolean>(false);
    const [
        openModifyRecommendationFormModal,
        setOpenModifyRecommendationFormModal,
    ] = React.useState<boolean>(false);
    const [
        openAcknowledgeRecommendationFormModal,
        setOpenAcknowledgeRecommendationFormModal,
    ] = React.useState<boolean>(false);
    const [
        openRejectRecommendationFormModal,
        setOpenRejectRecommendationFormModal,
    ] = React.useState<boolean>(false);
    const [
        openCreateRecommendationModal,
        setOpenCreateRecommendationModal,
    ] = React.useState<boolean>(false);

    const handleExpandComponent = (panel: number) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean,
    ) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Root>
            <CapabilityHead>Completion Levels</CapabilityHead>

            <List>
                {mock.map(id => (
                    <AccordionStyled
                        expanded={expanded === id}
                        key={id}
                        onChange={handleExpandComponent(id)}
                    >
                        <AccordionSummary
                            classes={{
                                content: classes.accordionSummaryContent,
                            }}
                            expandIcon={<ExpandMore />}
                            aria-controls='panel1bh-content'
                            id='panel1bh-header'
                        >
                            <TableCellTextTitle variant='head'>
                                A formal insider risk management governance structure is
                                established.
                            </TableCellTextTitle>
                            <TableCellTextRow>
                                <MaturityLevel current={ProjectMaturityLevelEnum.Mature} />
                            </TableCellTextRow>
                            <TableCellTextRow>
                                <RecommendationCounter count={2} />
                            </TableCellTextRow>
                        </AccordionSummary>
                        <AccordionDetailsStyled>
                            <LevelTitle>Recommendations</LevelTitle>
                            {mock.map(id => (
                                <Recommendation key={id}>
                                    <RecommendationTitle>
                                        No insider threat/risk management structure (Gap #1)
                                    </RecommendationTitle>
                                    <RecommendationList>
                                        <div>
                                            <RecommendationText>
                                                Define and capture how the Program will be managed
                                            </RecommendationText>
                                            <RecommendationControls>
                                                <div>
                                                    <ActionButton
                                                        size='small'
                                                        onClick={() =>
                                                            setOpenAcceptRecommendationFormModal(
                                                                true,
                                                            )
                                                        }
                                                        variant='outlined'
                                                    >
                                                        Accept
                                                    </ActionButton>
                                                </div>
                                                <div>
                                                    <ActionButton
                                                        size='small'
                                                        onClick={() =>
                                                            setOpenModifyRecommendationFormModal(
                                                                true,
                                                            )
                                                        }
                                                        variant='outlined'
                                                    >
                                                        Modify
                                                    </ActionButton>
                                                </div>
                                                <div>
                                                    <ActionButton
                                                        size='small'
                                                        onClick={() =>
                                                            setOpenAcknowledgeRecommendationFormModal(
                                                                true,
                                                            )
                                                        }
                                                        variant='outlined'
                                                    >
                                                        Acknowledge
                                                    </ActionButton>
                                                </div>
                                                <div>
                                                    <ActionButton
                                                        size='small'
                                                        onClick={() =>
                                                            setOpenRejectRecommendationFormModal(
                                                                true,
                                                            )
                                                        }
                                                        variant='outlined'
                                                    >
                                                        Reject
                                                    </ActionButton>
                                                </div>
                                            </RecommendationControls>
                                        </div>
                                    </RecommendationList>
                                    <ComponentToolbar>
                                        <ActionButton
                                            size='small'
                                            onClick={() => setOpenGapFormModal(true)}
                                            variant='outlined'
                                        >
                                            Edit Recommendations
                                        </ActionButton>
                                        <ActionButton
                                            size='small'
                                            onClick={() => setOpenCreateRecommendationModal(true)}
                                            variant='outlined'
                                        >
                                            + Add Recommendation
                                        </ActionButton>
                                    </ComponentToolbar>
                                </Recommendation>
                            ))}
                        </AccordionDetailsStyled>
                    </AccordionStyled>
                ))}
            </List>
            <RecommendationFormModal
                open={openGapFormModal}
                onClose={() => setOpenGapFormModal(false)}
            />
            <RecommendationAcceptFormModal
                open={openAcceptRecommendationFormModal}
                onClose={() => setOpenAcceptRecommendationFormModal(false)}
            />
            <RecommendationModifyFormModal
                open={openModifyRecommendationFormModal}
                onClose={() => setOpenModifyRecommendationFormModal(false)}
            />
            <RecommendationAcknowledgeFormModal
                open={openAcknowledgeRecommendationFormModal}
                onClose={() => setOpenAcknowledgeRecommendationFormModal(false)}
            />
            <RecommendationRejectFormModal
                open={openRejectRecommendationFormModal}
                onClose={() => setOpenRejectRecommendationFormModal(false)}
            />
            <CreateRecommendationModal
                gapId={1}
                open={openCreateRecommendationModal}
                onClose={() => setOpenCreateRecommendationModal(false)}
            />
        </Root>
    );
};

export { CompletionLevels };

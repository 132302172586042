import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import { TextField, Button } from '@modules/ui/core';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';

import type {
    ImpTaskCreateFormValues,
    ImpTaskUpdateFormValues,
} from '@modules/custom-components/requests';

type ImpTaskFormProps<T> = FormProps<T> & {
    onCancel?: () => void;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const ImplementationTaskForm = <T extends FormSemanticType = 'create'>(
    props: ImpTaskFormProps<
        // prettier-ignore
        T extends 'create' ? ImpTaskCreateFormValues :
        T extends 'edit' ? ImpTaskUpdateFormValues :
        ImpTaskCreateFormValues & ImpTaskUpdateFormValues
    >,
): React.ReactElement => {
    const { type, initialValues, FormWrapComponent, onSubmit, onCancel } = props;

    const isEdit = type === 'edit';

    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                task: yup.string().required('Required field'),
            })}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {formikProps => {
                const { values, errors, handleChange, handleBlur, isSubmitting } = formikProps;

                return (
                    <FormWrapComponent>
                        <StyledForm>
                            <TextField
                                fullWidth
                                multiline
                                id='task'
                                label='Task'
                                rows='4'
                                value={values.task}
                                error={!!errors.task}
                                helperText={errors.task}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    {isEdit ? 'Update Task' : 'Create Task'}
                                </SubmitButton>

                                <SubmitButton
                                    variant='text'
                                    disabled={isSubmitting}
                                    onClick={onCancel}
                                >
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    </FormWrapComponent>
                );
            }}
        </ExtendedFormik>
    );
};

export { ImplementationTaskForm };

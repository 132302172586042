import type { UpdateImpSubTaskMutationVariables } from '@modules/types/graphql';
import { ImpSubTaskCreateFormValues, ImpSubTaskCreateRequest } from './imp-sub-task-create-request';

export type ImpSubTaskUpdateFormValues = ImpSubTaskCreateFormValues & {
    subTaskId: number;
};

class ImpSubTaskUpdateRequest extends ImpSubTaskCreateRequest {
    subTaskId: UpdateImpSubTaskMutationVariables['subTaskId'];

    constructor(values: ImpSubTaskUpdateFormValues) {
        super(values);

        this.subTaskId = values.subTaskId;
    }
}

export { ImpSubTaskUpdateRequest };

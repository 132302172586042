import React from 'react';
import styled, { css } from 'styled-components';

import { ImplementationRecommendationsListItem } from './implementation-recommendations-list-item';
import { scoreVariantColorsMap } from '@modules/shared/constants';
import type { ProjectEntity } from '@modules/projects/entities';
import type { RecommendationImplementationEntity } from '@modules/implementation/entities';
import { gray } from '@modules/ui/colors';
import { AccordionInnerListItem } from '@modules/layout/organisms';
import { ImplementationRecommendationsStatusSummary } from './implementation-recommendations-status-summary';

type ImplementationRecommendationsSummaryProps = {
    title: string;
    project: ProjectEntity;
    recommendationsImplementation: (RecommendationImplementationEntity | never)[];
    riskLevelKey: string;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 16px;
    background-color: ${gray[60]};
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);
`;

const List = styled.div`
    width: 100%;
    margin-top: 24px;

    > * {
        margin-bottom: 12px;
    }
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;

    > * {
        width: 20%;
        font-size: 1.4rem;
    }
`;

const InnerHeaderExtra = styled.div`
    > h3 {
        display: inline-flex;
        font-size: 2.2rem;
        font-weight: 700;
    }
`;

const ItemTitle = styled.div<Pick<ImplementationRecommendationsSummaryProps, 'riskLevelKey'>>`
    width: 100%;

    > h3 {
        display: inline-flex;
        padding: 4px;
        font-size: 2.2rem;
        font-weight: 700;
        border-radius: 4px;

        ${p => css`
            color: ${scoreVariantColorsMap[p.riskLevelKey].color};
            background-color: ${scoreVariantColorsMap[p.riskLevelKey].background};
        `}
    }
`;

const ImplementationRecommendationsSummary = (
    props: ImplementationRecommendationsSummaryProps,
): React.ReactElement => {
    const { project, title, recommendationsImplementation, riskLevelKey } = props;
    const [expanded, setExpanded] = React.useState<boolean>(false);
    let onExpand = (event: React.ChangeEvent<{}>, isExpanded: boolean) => setExpanded(!expanded);

    const recommendationsCount = recommendationsImplementation.length;
    const recommendationsDoneCount = recommendationsImplementation.filter(recommendation =>
        recommendation.isDone(),
    ).length;

    const displayTitle = `${title} - ( ${recommendationsDoneCount} of ${recommendationsCount} )`;

    return (
        <Root>
            <AccordionInnerListItem
                title={''}
                subtitle={''}
                expanded={expanded}
                onChange={onExpand}
                SummaryInfoComponents={
                    <Header>
                        <InnerHeaderExtra>
                            <ItemTitle riskLevelKey={title}>
                                <h3>{displayTitle}</h3>
                            </ItemTitle>
                        </InnerHeaderExtra>
                    </Header>
                }
            >
                <ImplementationRecommendationsStatusSummary
                    title={title}
                    project={project}
                    recommendationsImplementation={recommendationsImplementation}
                    riskLevelKey={riskLevelKey}
                />
            </AccordionInnerListItem>
        </Root>
    );
};

export { ImplementationRecommendationsSummary };

import React from 'react';
import styled, { css } from 'styled-components';
import { Info } from '@material-ui/icons';

import { red, blue, turquoise } from '@modules/ui/colors';

type HintProps = {
    text: string;
    size: 's' | 'm' | 'l';
    as?: any;
    margin?: 'none';
    success?: boolean;
    error?: boolean;
    centered?: boolean;
};

const Root = styled.span<Pick<HintProps, 'success' | 'error'>>`
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin: 8px 0;

    ${p =>
        (p.success &&
            css`
                color: ${blue.h};

                > ${Icon} {
                    color: ${blue.h};
                }
            `) ||
        (p.error &&
            css`
                color: ${red.h};

                > ${Icon} {
                    color: ${red.h};
                }
            `)}
`;

const Icon = styled.span`
    display: flex;
    flex-wrap: wrap;
    color: ${turquoise.h};
`;

const Content = styled.span<Pick<HintProps, 'size'>>`
    display: inline-flex;
    max-width: calc(100% - 36px);
    margin-left: 8px;
    padding-top: 1px;
    letter-spacing: 0.5px;

    ${p =>
        (p.size === 's' &&
            css`
                font-size: 1.2rem;
            `) ||
        (p.size === 'm' &&
            css`
                font-size: 1.4rem;
            `) ||
        (p.size === 'l' &&
            css`
                font-size: 1.6rem;
            `)}
`;

const infoIcon = <Info style={{ fontSize: 20 }} />;

const Hint = (props: HintProps): React.ReactElement => {
    const { text, size, ...otherProps } = props;

    return (
        <Root {...otherProps}>
            <Icon>{infoIcon}</Icon>
            <Content size={size}>{text}</Content>
        </Root>
    );
};

Hint.defaultProps = {
    size: 's',
};

export { Hint };

import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { GapEntityList } from '@modules/custom-components/entities';
import { GetGapsQuery } from '@modules/custom-components/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetGapsQueryType, GetGapsQueryVariables } from '@modules/types/graphql';

const useGaps = (options?: QueryHookOptions<GetGapsQueryType, GetGapsQueryVariables>) => {
    const queryResult = useQuery<GetGapsQueryType, GetGapsQueryVariables>(GetGapsQuery, options);

    const { entities: gaps, count } = useEntity(
        () => new GapEntityList(queryResult.data?.listGaps ?? null),
        [queryResult.data],
    );

    const result = {
        gaps,
        count,
        ...queryResult,
    };

    return result;
};

export { useGaps };

import React, { useState } from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import { ReportPrioritizedRoadmapListItem } from './report-prioritized-roadmap-list-item';
import { AccordionInnerListItem, TableColumn } from '@modules/layout/organisms';
import { gray } from '@modules/ui/colors';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { RecommendationAssessmentRoadmapReport } from '@modules/roadmap/recommendation-assessment-roadmap-report';
import { ListStyled } from '@modules/layout/styled';
import { ProjectEntity } from '@modules/projects/entities';

type ReportPrioritizedRoadmapListSummaryProps = {
    recommendations: RecommendationAssessmentRoadmapReport[];
    project: ProjectEntity;
    withStatus?: boolean;
    riskLevelKey: string;
    label: string;
    open: boolean;
    onExpand?: (panel: string) => (event: React.ChangeEvent<{}>, expanded: boolean) => void;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 16px;
    margin: 5px;
    background-color: ${gray[60]};
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);
`;

export const ReportPrioritizedRoadmapStatusListSummary = (
    props: ReportPrioritizedRoadmapListSummaryProps,
): React.ReactElement => {
    const { riskLevelKey, recommendations, project, withStatus } = props;
    const [expandedRow, setExpandedRow] = useState<string | null>(null);

    const onExpand = (riskLevel: string) => {
        if (expandedRow === riskLevel) {
            setExpandedRow(null);
        } else {
            setExpandedRow(riskLevel);
        }
    };
    let colSpan = withStatus ? 5 : 4;
    const accordionTitle: TableColumn[] = [
        {
            id: 'pending',
            label: 'Pending',
        },
        {
            id: 'inProgress',
            label: 'In progress',
        },
        {
            id: 'done',
            label: 'Done',
        },
    ];

    return (
        <React.Fragment>
            {accordionTitle.map((title: any) => (
                <Root key={title.id}>
                    <AccordionInnerListItem
                        title={title.label}
                        subtitle={''}
                        expanded={expandedRow === title.id}
                        onChange={() => onExpand(title.id)}
                        SummaryInfoComponents={<></>}
                    >
                        <ListStyled.Container className={'no-top-offset'}>
                            <Droppable droppableId={riskLevelKey as string}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        // style={{ backgroundColor: snapshot.isDraggingOver ? 'blue' : 'grey' }}
                                        {...provided.droppableProps}
                                    >
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Recommendation</TableCell>
                                                    <TableCell>Risk Level</TableCell>
                                                    <TableCell>Component</TableCell>
                                                    <TableCell>Capability</TableCell>
                                                    {withStatus && <TableCell>Status</TableCell>}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {recommendations.filter(recommendation => recommendation.componentRecommendation.recommendation.status === title.label).length > 0 ? (
                                                    recommendations.filter(recommendation => recommendation.componentRecommendation.recommendation.status === title.label)
                                                        .map((recommendation, i) => (
                                                            <ReportPrioritizedRoadmapListItem
                                                                index={i}
                                                                key={`${recommendation.componentRecommendation.componentId}-${recommendation.componentRecommendation.roadmapRecommendationId}`}
                                                                recommendation={recommendation}
                                                                project={project}
                                                                colSpan={colSpan}
                                                                withStatus={withStatus}
                                                            />
                                                        ))
                                                ) : (
                                                    <TableRow>
                                                        <TableCell colSpan={colSpan}>
                                                            Recommendations not found, but drag and drop
                                                            available for putting recommendations here from
                                                            another list
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                                }
                                                {provided.placeholder}
                                            </TableBody>
                                        </Table>
                                    </div>
                                )}
                            </Droppable>
                        </ListStyled.Container>
                    </AccordionInnerListItem>
                </Root>
            ))}
        </React.Fragment>
    );
};

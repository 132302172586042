import React, { useContext } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import {
    withStyles,
    MenuItem,
    FormHelperText,
    FormControlLabel,
    makeStyles,
} from '@material-ui/core';

import { TextField, Button, Autocomplete } from '@modules/ui/core';
import { white } from '@modules/ui/colors';
import { TableCheckbox, TableCellText } from '@modules/layout/atoms';
import { Loader, TableRow, TableCell } from '@modules/layout/moleculas';
import { Table, ExtendedFormik, Form } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';
import { useUsers } from '@modules/users/hooks';
import { weights } from '@modules/components/constants';
import { useComponents } from '@modules/components/hooks';
import { ComponentEntity } from '@modules/components/entities';

import type { TableColumn } from '@modules/layout/organisms';
import type { UserListEntity } from '@modules/users/entities';
import type {
    ComponentAddFormValues,
    ProjectAddComponentsFormValues,
} from '@modules/components/requests';

type ProjectComponentsFormProps<T> = FormProps<T> & {
    onCancel?: () => void;
};

const columns: TableColumn[] = [
    {
        id: 'name',
        label: 'Component Name',
    },
    {
        id: 'description',
        label: 'Objectives',
    },
    {
        id: 'weight',
        label: 'Weight',
    },
    {
        id: 'componentOwner',
        label: 'Component Owner',
    },
];

const useFormStyles = makeStyles({
    owner: {
        background: white[100],
    },
});

const StyledFormControlText = withStyles({
    root: {
        fontSize: '1.4rem',
    },
})(FormHelperText);

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const Hint = styled.div`
    font-size: 14px;
`;

const TextFieldWeight = styled(TextField)`
    background-color: ${white[100]};
`;

const ProjectComponentsForm = (
    props: ProjectComponentsFormProps<ProjectAddComponentsFormValues>,
): React.ReactElement => {
    const { initialValues, FormWrapComponent, onSubmit, onCancel } = props;

    const { components, loading: componentsLoading } = useComponents({
        fetchPolicy: 'network-only',
        variables: {
            companyId: initialValues.companyId,
            assessmentTypeId: initialValues.assessmentTypeId,
        },
    });

    const { users, loading: usersLoading } = useUsers();

    const formClasses = useFormStyles();
    const getOptionLabel = React.useCallback(
        (option: UserListEntity | null) => option?.getFullName() ?? '-',
        [],
    );

    return (
        <ExtendedFormik
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                components: yup
                    .mixed()
                    .test(
                        'has components and co for each of this',
                        'You need to choose CO for your components',
                        (components: any) => {
                            const hasComponents = components.length !== 0;

                            if (!hasComponents) {
                                return true;
                            }

                            const hasComponentOwners =
                                (components as ComponentAddFormValues[]).filter(
                                    component => !!component.componentOwner,
                                ).length !== 0;

                            return hasComponentOwners;
                        },
                    ),
            })}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {formikProps => {
                const { values, errors, setFieldValue, handleBlur, isSubmitting } = formikProps;

                const handleChangeCheckbox = (id: number) => (_: React.ChangeEvent<{}>) => {
                    const components = [...values.components];

                    const idx = components.findIndex(component => component.component?.id === id);

                    if (idx === -1) {
                        components.push({
                            component: new ComponentEntity({ id }),
                            weight: 1,
                        });
                    } else {
                        components.splice(idx, 1);
                    }

                    setFieldValue('components', components);
                };

                const handleChangeValue = (id: number) => (
                    event: React.ChangeEvent<{ name: string; value: string }>,
                ) => {
                    const components = [...values.components];

                    const idx = components.findIndex(component => component.component?.id === id);

                    if (idx === -1) {
                        const componentData = {
                            component: new ComponentEntity({ id }),
                            componentOwner: null,
                            weight: 1,
                            objectives: '',
                        };

                        componentData[event.target.name] = event.target.value;

                        components.push(componentData);
                    } else {
                        components[idx][event.target.name] = event.target.value;
                    }

                    setFieldValue('components', components);
                };

                const handleChangeOwner = (id: number) => (
                    event: React.ChangeEvent<{}>,
                    value: UserListEntity | null,
                ) => {
                    const components = [...values.components];

                    const idx = components.findIndex(component => component.component?.id === id);

                    if (idx === -1) {
                        components.push({
                            component: new ComponentEntity({ id }),
                            componentOwner: value,
                            weight: 0,
                        });
                    } else {
                        components[idx].componentOwner = value;
                    }

                    setFieldValue('components', components);
                };

                return (
                    <FormWrapComponent>
                        <StyledForm>
                            <Hint>Project has created. Add Components</Hint>

                            {componentsLoading ? (
                                <Loader />
                            ) : (
                                <Table columns={columns}>
                                    {components.map(component => {
                                        const foundComponent = values.components.find(
                                            valueComponent =>
                                                component.id === valueComponent.component?.id,
                                        );

                                        return (
                                            <TableRow key={component.id}>
                                                <TableCell>
                                                    <FormControlLabel
                                                        control={
                                                            <TableCheckbox
                                                                name='id'
                                                                checked={!!foundComponent}
                                                                onChange={handleChangeCheckbox(
                                                                    component.id,
                                                                )}
                                                            />
                                                        }
                                                        label={
                                                            <TableCellText>
                                                                {component.name}
                                                            </TableCellText>
                                                        }
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <TableCellText>
                                                        <TextField
                                                            fullWidth
                                                            multiline
                                                            id='objectives'
                                                            name='objectives'
                                                            label='Objectives'
                                                            rows='2'
                                                            className={formClasses.owner}
                                                            value={foundComponent?.objectives ?? ''}
                                                            onChange={handleChangeValue(
                                                                component.id,
                                                            )}
                                                            onBlur={handleBlur}
                                                        />
                                                    </TableCellText>
                                                </TableCell>

                                                <TableCell>
                                                    <TextFieldWeight
                                                        fullWidth
                                                        select
                                                        variant='outlined'
                                                        id='weight'
                                                        name='weight'
                                                        value={foundComponent?.weight ?? 0}
                                                        onChange={handleChangeValue(component.id)}
                                                        onBlur={handleBlur}
                                                    >
                                                        <MenuItem disabled>
                                                            Pick the weight
                                                        </MenuItem>

                                                        {weights.map(weight => (
                                                            <MenuItem key={weight} value={weight}>
                                                                {weight}
                                                            </MenuItem>
                                                        ))}
                                                    </TextFieldWeight>
                                                </TableCell>

                                                <TableCell>
                                                    <Autocomplete<
                                                        UserListEntity | null,
                                                        false,
                                                        false,
                                                        false
                                                    >
                                                        id='componentOwner'
                                                        label='Owner'
                                                        placeholder='Choose the owner'
                                                        className={formClasses.owner}
                                                        options={users}
                                                        value={
                                                            foundComponent?.componentOwner ?? null
                                                        }
                                                        getOptionLabel={getOptionLabel}
                                                        disabled={usersLoading}
                                                        onChange={handleChangeOwner(component.id)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </Table>
                            )}

                            {!!errors.components ? (
                                <FormStyled.FormRow>
                                    <StyledFormControlText error>
                                        {errors.components}
                                    </StyledFormControlText>
                                </FormStyled.FormRow>
                            ) : null}

                            <FormStyled.FormRowSubmit>
                                <SubmitButton
                                    type='submit'
                                    disabled={values.components.length === 0}
                                    loading={isSubmitting}
                                >
                                    Add Components to the Project
                                </SubmitButton>

                                <SubmitButton
                                    variant='text'
                                    disabled={isSubmitting}
                                    onClick={onCancel}
                                >
                                    Skip
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    </FormWrapComponent>
                );
            }}
        </ExtendedFormik>
    );
};

export { ProjectComponentsForm };

import React from 'react';
import styled from 'styled-components';
import {
    withStyles,
    makeStyles,
    Stepper,
    Step,
    StepLabel,
    StepIcon as MuiStepIcon,
} from '@material-ui/core';

import { EditRecommendationTab } from './edit-recommendation-tab';
import { EditRequirementTab } from './edit-requirement-tab';
import { EditTasksTab } from './edit-tasks-tab';

import type { RecommendationEntity } from '@modules/custom-components/entities';

type CreateRecommendationStepperProps = {
    recommendation: RecommendationEntity;
    gapId: number;
    onCancel?: () => void;
};

const StepIcon = withStyles({
    root: {
        fontSize: '1.8rem',
    },
    text: {
        paddingTop: 1,
        fontSize: '1.4rem',
    },
})(MuiStepIcon);

const useStepperStyles = makeStyles({
    root: {
        padding: 0,
    },
});

const useStepLabelStyles = makeStyles({
    root: {
        cursor: 'pointer',
    },
    label: {
        fontSize: '1.2rem',
    },
});

const Root = styled.div`
    width: 100%;
    margin-top: 16px;
`;

const Main = styled.div`
    width: 100%;
    margin-top: 32px;
`;

const steps = ['Edit Recommendation', 'Edit Requirements', 'Edit Tasks'];

const EditRecommendationStepper = (props: CreateRecommendationStepperProps): React.ReactElement => {
    const { recommendation, onCancel } = props;

    const stepperClasses = useStepperStyles();
    const stepLabelClasses = useStepLabelStyles();

    const [currentStep, setCurrentStep] = React.useState<number>(0);

    return (
        <Root>
            <Main>
                <EditRecommendationTab recommendation={recommendation} onCancel={onCancel} />;
            </Main>
        </Root>
    );

    // TODO: hide while not approved this func
    // return (
    //     <Root>
    //         <Stepper nonLinear classes={stepperClasses} activeStep={currentStep}>
    //             {steps.map((label, index) => (
    //                 <Step key={index}>
    //                     <StepLabel
    //                         classes={stepLabelClasses}
    //                         StepIconComponent={StepIcon}
    //                         onClick={() => setCurrentStep(index)}
    //                     >
    //                         {label}
    //                     </StepLabel>
    //                 </Step>
    //             ))}
    //         </Stepper>

    //         <Main>
    //             {currentStep === 0 ? (
    //                 <EditRecommendationTab recommendation={recommendation} onCancel={onCancel} />
    //             ) : null}

    //             {currentStep === 1 ? (
    //                 <EditRequirementTab recommendation={recommendation} onCancel={onCancel} />
    //             ) : null}

    //             {currentStep === 2 ? (
    //                 <EditTasksTab recommendation={recommendation} onCancel={onCancel} />
    //             ) : null}
    //         </Main>
    //     </Root>
    // );
};

export { EditRecommendationStepper };

import gql from 'graphql-tag';
import { LearnTkoDetailsFragment } from '../fragments';

export const CreateLearnTkoMutation = gql`
    mutation CreateLearnTkoDetail(
        $menu: String!
        $title: String!
        $description: String
        $video_url: String
    ) {
        createLearnTkoDetail(
            menu: $menu
            title: $title
            description: $description
            video_url: $video_url
        ) {
            ...LearnTkoDetails
        }
    }
    ${LearnTkoDetailsFragment}
`;

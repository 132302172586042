import { EntityList } from '@lib/entity';
import { AssetTypeEntity } from './asset-type-entity';

import type { Entity } from './asset-type-entity';


class AssetTypeEntityList extends EntityList<AssetTypeEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(AssetTypeEntity, items, count);
    }
}

export { AssetTypeEntityList };

import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useNavigate } from '@reach/router';

import { routes } from '@config/routes';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { PageStyled } from '@modules/layout/styled';
import { CompanyForm } from '@modules/company/organisms';

import type { Page } from '@modules/types/page';
import type {
    CreateCompanyDetailsMutationType,
    CreateCompanyDetailsMutationVariables,
} from '@modules/types/graphql';
import { CreateCompanyDetailsMutation } from '@modules/company/graphql/mutations';
import { CompanyCreateRequest, CompnayCreateFormValues } from '@modules/projects/requests';
import { useCurrentUser } from '@modules/auth/hooks';
import { Loader } from '@modules/layout/moleculas';
import { GetCompanyDetailsQuery } from '@modules/company/graphql/queries';

const Main = styled(PageStyled.Main)`
    margin-top: 48px;
`;

const FormWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-width: 625px;
`;

const CompanyCreatePage = (props: Page): React.ReactElement => {
    const { title = 'Create company' } = props;

    const navigate = useNavigate();

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const { currentUser, loading: currentUserLoading } = useCurrentUser();
    const userId = currentUser.id;

    const initialValues: CompnayCreateFormValues = {
        userId: userId,
        name: '',
        address: '',
        phone: '',
        contactName: '',
        email: '',
        packageType: '',
        subscriptionType: '',
        isAskTko: false,
        isLearnTko: false,
        isSchema: false,
    };

    const [createCompanyDetails] = useMutation<
        CreateCompanyDetailsMutationType,
        CreateCompanyDetailsMutationVariables
    >(CreateCompanyDetailsMutation);

    const handleSubmit = async (values: CompnayCreateFormValues): Promise<void> => {
        try {
            let result = undefined;
            let variables = new CompanyCreateRequest(values);
            const { data: createCompanyDetailsData } = await createCompanyDetails({
                variables: variables,
                refetchQueries: [{ query: GetCompanyDetailsQuery }],
                awaitRefetchQueries: true,
            });
            result = createCompanyDetailsData?.createCompanyDetails?.user_id;
            if (createCompanyDetailsData?.createCompanyDetails?.user_id) {
                enqueueSuccess('Company successfully created!');
                await navigate(routes.companies.path);
            } else {
                enqueueError('An error occurred while creating the company!');
            }
        } catch (e) {
            throw e;
        }
    };
    if (currentUserLoading) {
        return <Loader />;
    }
    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>{title}</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>

            <Main>
                <CompanyForm
                    type='create'
                    initialValues={initialValues}
                    FormWrapComponent={FormWrap}
                    onSubmit={handleSubmit}
                />
            </Main>
        </>
    );
};
export { CompanyCreatePage };

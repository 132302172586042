import { gql } from '@apollo/client';

export const GetProjectsQuery = gql`
    query GetProjects($filter: String, $status: String) {
        listProjects(filter: $filter, filterStatus: $status) @connection(key: "listProjects") {
            id
            name
            description
            status
            maturity_level {
                id
                name
            }
        }
    }
`;

import styled from 'styled-components';

import { turquoise } from '@modules/ui/colors';

const SidebarStyled = {
    Title: styled.div`
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: ${turquoise[100]};
        font-size: 2.4rem;
        font-weight: 700;
    `,

    Content: styled.div`
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 24px;
        overflow-y: auto;
    `,

    Hint: styled.div`
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 72px;
    `,

    HintIcon: styled.div``,
    HintText: styled.div`
        margin-top: 16px;
        font-size: 1.8rem;
    `,
};

export { SidebarStyled };

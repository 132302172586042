import { gql } from '@apollo/client';

export const GetCurrentUserQuery = gql`
    query GetCurrentUser {
        getCurrentUser {
            id
            company_id
            email
            first_name
            last_name
            is_admin
            is_super_admin
            user_role
        }
    }
`;

import { gql } from '@apollo/client';

export const AddDependenciesMutation = gql`
    mutation AddDependencies($roadmapRecommendationId: Int!, $dependencies: String) {
        addDependencies(
            roadmap_recommendation_id: $roadmapRecommendationId
            dependencies: $dependencies
        ) {
            status
        }
    }
`;

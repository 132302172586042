import { EntityList } from '@lib/entity';

import { Entity, UserDetailEntity } from './user-detail-entity';

class UserDetailEntityList extends EntityList<UserDetailEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(UserDetailEntity, items, count);
    }
}

export { UserDetailEntityList };

import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import Auth from '@aws-amplify/auth';
import { useMountedState } from 'react-use';
import { Formik, Form } from 'formik';
import { FormControl } from '@material-ui/core';
import { useNavigate } from '@reach/router';

import { routes } from '@config/routes';
import { Button, Alert } from '@modules/ui/core';
import { white } from '@modules/ui/colors';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { Hint } from '@modules/layout/atoms';
import { TextField } from '@modules/layout/moleculas';
import { useLoginAttempts } from '@modules/auth/hooks';

type ForgotPasswordFormProps = {
    onHide: (props: { email?: string }) => void;
    className?: string;
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 450px;
    width: 100%;
    padding: 24px;
    background-color: ${white[100]};
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    > * {
        width: 100%;
        letter-spacing: 0.5px;
        text-align: center;
    }

    > h3 {
        font-size: 3rem;
        text-transform: uppercase;
    }

    > p {
        margin-top: 8px;
        font-size: 1.4rem;
    }
`;

const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 24px;
`;

const StyledFrom = styled(Form)`
    width: 100%;

    > * {
        margin-bottom: 18px;
    }
`;

const StyledHint = styled(Hint)`
    margin: 12px 0 0 0;
`;

const FormControlSubmit = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-bottom: 4px;

    > * {
        flex: 1;

        &:not(:last-of-type) {
            margin-right: 12px;
        }
    }
`;

const ForgotPasswordForm = (props: ForgotPasswordFormProps): React.ReactElement => {
    const { onHide, ...otherProps } = props;

    const isMounted = useMountedState();
    const navigate = useNavigate();
    const { getLoginAttempts } = useLoginAttempts();
    const isLoginBlocked = !!getLoginAttempts();

    const { enqueueSuccess } = useEnqueueStacks();

    return (
        <Root {...otherProps}>
            <Header>
                <h3>Forgot Password</h3>
            </Header>

            <Main>
                {isLoginBlocked ? (
                    <Alert
                        title=''
                        content='Due to unsuccessful authorization attempts, the user account was temporarily blocked. Change your password or try logging in later.'
                    />
                ) : (
                    ''
                )}
                <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={yup.object({
                        email: yup
                            .string()
                            .required('Required field')
                            .email('Must be a valid email'),
                    })}
                    initialValues={{
                        email: '',
                    }}
                    initialStatus={{
                        error: '',
                    }}
                    onSubmit={async (values, helpers) => {
                        const { setStatus, setSubmitting } = helpers;

                        setSubmitting(true);

                        try {
                            const resetPassword = await Auth.forgotPassword(values.email);

                            if (resetPassword) {
                                enqueueSuccess(
                                    `Password recovery link has been successfully sent to ${values.email}. Please check your mailbox.`,
                                );
                                onHide({ email: values.email });
                            }
                        } catch (e) {
                            setStatus({ error: 'message' in e ? e.message : 'Unexepected error' });
                        } finally {
                            if (isMounted()) {
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {formikProps => {
                        const {
                            status,
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            isSubmitting,
                        } = formikProps;

                        return (
                            <StyledFrom>
                                <TextField
                                    fullWidth
                                    id='email'
                                    label='Email'
                                    placeholder='Enter your email'
                                    value={values.email}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <FormControl fullWidth>
                                    <FormControlSubmit>
                                        <Button
                                            variant='outlined'
                                            disabled={isSubmitting}
                                            onClick={() => navigate(routes.login.path)}
                                        >
                                            Back
                                        </Button>

                                        <Button type='submit' loading={isSubmitting}>
                                            Reset Password
                                        </Button>
                                    </FormControlSubmit>

                                    {!!status.error ? (
                                        <StyledHint error text={status.error} />
                                    ) : null}
                                </FormControl>
                            </StyledFrom>
                        );
                    }}
                </Formik>
            </Main>
        </Root>
    );
};

export { ForgotPasswordForm };
